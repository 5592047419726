import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Text } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-text',
  templateUrl: './text.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TextComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-text-field';
  @HostBinding('innerHTML') htmlText = '';
  @Input() text: Text;

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.htmlText = this.getText();
  }

  getText(): string {
    return this.text.text;
  }

  getCss(): string {
    return this.text.cssClass;
  }
}
