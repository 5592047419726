<div *ngIf="playlistData"
     [ngClass]="{'amg-live-player': isLive(), 'amg-vod-player': videoPlayerService.isOnDemand()}"
     class="amg-sidebar-wrapper">
  <!-- Sidebar header - BEGIN -->
  <div class="amg-sidebar-header">
    <span>
    <div [innerHTML]="isLive()?'Live now':title"
         class="amg-title"></div>
    <div [innerHTML]="isLive() ? subTitle : 'RELATED VIDEOS'"
         class="amg-subtitle"></div>
    </span>
    <span class="amg-autoplay-wrapper">
      <ng-template [ngIf]="!isLive()">
    <span class="amg-autoplay">Autoplay</span>
      <label [ngClass]="{'toggledOn': autoplay}"
             class="amg-toggler switch">
      <input (change)="toggleAutoplay()"
             [checked]="autoplay"
             type="checkbox">
      <span class="slider round"></span>
    </label>
        </ng-template>
    </span>
    <ng-template [ngIf]="isMobile">
    <span (click)="toggleCollapse()"
          [ngClass]="{'rotate45cw': !collapsePlaylist}"
          class="amg-collapse-toggler">+</span>
    </ng-template>
  </div>

  <!-- Sidebar header - END -->
  <div class='amg-sidebar'
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollUpDistance]="2"
      [infiniteScrollThrottle]="500"
      [scrollWindow]="false"
      (scrolled)="onScroll($event)"
  >
    <!-- Playlist wrapper - BEGIN -->
    <div class="amg-playlist-wrapper">
      <div [ngClass]="{'collapse': collapsePlaylist}"
           class="amg-playlist-items">
        <ng-template [ngIf]="playlistData">
          <ng-template [ngIfElse]="anywherePlaylist"
                       [ngIf]="isLive()">
            <div class="amg-playlist-item">
              <amg-playlist-card
                [cardData]="getLiveItem()"
                class="active"></amg-playlist-card>
            </div>
          </ng-template>
          <ng-template #anywherePlaylist>
            <div *ngFor="let item of playlistData" class="amg-playlist-item">
              <amg-playlist-card
                (click)="changeMedia(item);"
                [cardData]="item"
                [ngClass]="{'active': isActiveEntry(item)}"></amg-playlist-card>
            </div>
            <div class="more-items-loader" [class.collapse]="!hasMoreItems()"></div>
          </ng-template>
        </ng-template>
        <ng-template [ngIf]="!playlistData">Playlist empty...</ng-template>
      </div>
    </div>
    <!-- Playlist wrapper - END -->
  </div>
</div>
