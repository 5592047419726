import {ModuleWithProviders, NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {StreamPaymentScriptsConfig} from './shared/data/stream-payments-scripts.model';
import { FormBuilderComponent } from './shared/form-builder/form-builder.component';
import { TemplateBuilderComponent } from './shared/template-builder/template-builder.component';
import { LoginBtnComponent } from './sign-up-buttons/login-btn/login-btn.component';
import { LogoutBtnComponent } from './sign-up-buttons/logout-btn/logout-btn.component';
import { RegisterBtnComponent } from './sign-up-buttons/register-btn/register-btn.component';
import { SubscribeBtnComponent } from './sign-up-buttons/subscribe-btn/subscribe-btn.component';
import { SubscriptionPackagesComponent } from './subscription-packages/subscription-packages.component';
import { SubscriptionPackageComponent } from './subscription-packages/subscription-package/subscription-package.component';
import { UserWelcomeComponent } from './user-welcome/user-welcome.component';
import { FormHeaderComponent } from './shared/template-builder/templates/form-header/form-header.component';
import { CallToActionComponent } from './shared/template-builder/templates/call-to-action/call-to-action.component';
import { DropdownDirective } from './shared/_directives/dropdown.directive';
import { UserAccountControlComponent } from './user-account-control/user-account-control.component';
import { SignInComponent } from './sign-in/sign-in.component';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    FormBuilderComponent,
    TemplateBuilderComponent,
    LoginBtnComponent,
    LogoutBtnComponent,
    RegisterBtnComponent,
    SubscribeBtnComponent,
    SubscriptionPackagesComponent,
    SubscriptionPackageComponent,
    UserWelcomeComponent,
    FormHeaderComponent,
    CallToActionComponent,
    DropdownDirective,
    UserAccountControlComponent,
    SignInComponent
  ],
  exports: [
    LoginBtnComponent,
    LogoutBtnComponent,
    RegisterBtnComponent,
    SubscribeBtnComponent,
    FormBuilderComponent,
    TemplateBuilderComponent,
    SubscriptionPackagesComponent,
    SubscriptionPackageComponent,
    UserWelcomeComponent,
    UserAccountControlComponent,
    SignInComponent
  ],
  providers: [
    CommonModule,
    FormBuilder
  ],
  entryComponents: [
    TemplateBuilderComponent
  ]
})
export class UserAuthModule {
  // static forRoot(config: StreamPaymentScriptsConfig | any): ModuleWithProviders {
  //   if(config) {
  //     return {
  //       ngModule: UserAuthModule,
  //       providers: [{provide: 'config', useValue: config}]
  //     }
  //   } else {
  //     return null;
  //   }
  // }
}
