import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CLIENT_OPTIONS, ClientOptions } from './config/client-options';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule
  ],
  exports: []
})
export class WordpressAdapterModule {
  static forRoot(clientOptions: ClientOptions): ModuleWithProviders {
    return {
      ngModule: WordpressAdapterModule,
      providers: [
        { provide: CLIENT_OPTIONS, useValue: clientOptions }
      ]
    };
  }
}
