import * as tslib_1 from "tslib";
var CarouselResponsivePropertiesView = /** @class */ (function () {
    function CarouselResponsivePropertiesView() {
        this.itemElement = 'div';
    }
    CarouselResponsivePropertiesView.defaultValues = function (input) {
        var toReturn;
        if (input === undefined) {
            toReturn = new CarouselResponsivePropertiesView();
        }
        else {
            toReturn = input;
        }
        toReturn = tslib_1.__assign({}, toReturn, {
            limitViewableItems: 3,
            visibleItemsOnHover: 3,
            rightMarginOnItems: 0,
            imitateInfinityLoop: false,
            enableMouseDrag: true,
            enableMouseTouchDrag: true,
            pullDrag: true,
            freeDrag: true,
            merge: false,
            mergeFit: true,
            autoWidth: false,
            startPosition: 0,
            URLhashListener: false,
            showButtons: false,
            rewind: true,
            navText: ['next', 'prev'],
            navElement: 'div',
            iterationLimit: 1,
            slideTransition: '',
            showDots: true,
            dotsEach: 0,
            lazyLoad: false,
            lazyLoadEager: 0,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: false,
            // smartSpeed: 250,
            // fluidSpeed: 0,
            autoplaySpeed: 0,
            hoverSpeed: 0,
            navigationSpeed: 0,
            paginationSpeed: 0,
            dragEndSpeed: 0,
            callbacks: true,
            refreshRate: 200,
            responsiveBaseElement: {},
            video: false,
            videoHeight: 0,
            videoWidth: 0,
            animateOut: '',
            animateIn: '',
            info: {},
            nestedItemSelector: '',
            itemElement: 'div',
            stageElement: 'div',
            navContainer: '',
            dotsContainer: '',
            checkVisible: true,
            cardView: {},
        });
        return toReturn;
    };
    return CarouselResponsivePropertiesView;
}());
export { CarouselResponsivePropertiesView };
