import {
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Subscription} from 'rxjs';
import { UrlUtils } from 'amg-fe-utils';
import {
  AnywhereData,
  AnywhereItemData,
  EntryAndKs,
  EntryAndUiConf,
  KalturaPlayerConfig,
  KalturaPlayerEvents, kWidgetEmbed,
  LiveLoginOverlay, PlayerUrlData,
  VideoPlayerConfig,
  VideoSignUpConfig
} from '../_data/video-player.model';
import {UserAuthService} from 'user-auth';

import {GenericPaymentResponse} from '../_data/user-auth.model';
import {PlayerDimensions} from '../_shared/_data/player-dimensions';
import {VideoPlayerService} from '../video-player.service';

declare var window: any;

@Component({
  selector: 'amg-video-player',
  templateUrl: 'video-player.component.html',
  encapsulation: ViewEncapsulation.None
})

export class VideoPlayerComponent implements OnInit, OnDestroy {
  private static readonly autoplayStorageKey = 'AMG-AUTOPLAY';
  public lockPlayer: boolean;
  public allowAutoHeight: boolean;
  public playlistDisabled = false;
  autoplay: boolean;
  playerDimensionsRef: PlayerDimensions;
  kalturaPlayerConfig: KalturaPlayerConfig;
  localAnywhereMediaData: AnywhereItemData;
  kalturaScriptLoaded = false;
  localOverlayData: LiveLoginOverlay;
  playlistDataRef: AnywhereData;
  userDataLocalRef: GenericPaymentResponse;
  kalturaConfigClean;
  entryAndKSRef: EntryAndKs;
  showThumbnail: boolean = true;
  pageRoute: string;
  @ViewChild('kdp')
  kdp: any;
  @Input()
  videoSignUpConfig: VideoSignUpConfig;
  @Input()
  playlistUrl: string;
  @Input()
  playlistData: AnywhereData | EntryAndUiConf;
  entryAndKsSubscription: Subscription;
  videoPlayerStateBSubscription: Subscription;
  @Output() triggerPlaylistReady: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() triggerEntryChange: EventEmitter<string> = new EventEmitter<string>();
  private LEEDS_DRM_ENTRY_ID = '0_nirm0trd';
  private LEEDS_DRM_UI_CONF = '30027332';
  private FULHAM_DRM_ENTRY_ID = '0_p0qmnyl5';
  private PLAYER_ID = `kaltura_player_default`;
  private playerEmbeded = false;
  private _apiConfigsLoaded = false;
  private _entryId: string = '';
  private currentPlaylistSubscription: Subscription = null;

  constructor(public videoPlayerService: VideoPlayerService,
              private renderer: Renderer2,
              private userAuthService: UserAuthService,
              private changeDetectorRef: ChangeDetectorRef) {
    //console.log('Video player initialized ');
  }

  @Input()
  set playerLock(lock: boolean) {
    this.lockPlayer = lock;
    this.allowAutoHeight = (!lock);
    this.changeDetectorRef.detectChanges();
  }

  @Input()
  set setAllowAutoHeight(allow: boolean) {
    this.allowAutoHeight = allow;
  }

  /**
   * Provide Anywhere Api Configuration data and dispatch to observer;
   * Set kaltura player configuration for local use within component;
   */

  @Input()
  set apiConfigs(configs: VideoPlayerConfig) {
    if (!this.playerEmbeded && !this._apiConfigsLoaded) {
      if (configs) {
        if (configs.kalturaPlayerConfig) {
          this.kalturaPlayerConfig = configs.kalturaPlayerConfig;
          this.kalturaConfigClean = configs.kalturaPlayerConfig;
          this.findEntryToPlay();
          if (configs.kalturaPlayerConfig.entryId) {
            this.initializePlayer();
          }
        }
        this._apiConfigsLoaded = true;
      }
    }
  }

  @Input()
  set entryId(entryId: string) {
    const entryFromUrl = this.findEntryInURL();
    entryId = (entryId)
      // from input
      ? entryId
      : (entryFromUrl)
        // from url
        ? entryFromUrl
        : this.kalturaPlayerConfig.entryId
          // from service player config inject
          ? this.kalturaPlayerConfig.entryId
          : null;
    if (entryId) {
      this.videoPlayerService.setEntryAndKSBSubject(entryId);
    }
  }

  @Input()
  set liveLoginOverlay(overlay: LiveLoginOverlay) {
    if (overlay) {
      this.videoPlayerService.liveLoginOverlayBSubject.next(overlay);
      this.localOverlayData = overlay;
    }
  }

  /**
   * Fetch scripts required to run video player if not already Initialized;
   *
   */

  @Input()
  set anywhereData(anywhereData: AnywhereItemData) {
    if (anywhereData) {
      this.videoPlayerService.amgLogger('***set AnywhereData: ', anywhereData);
      this.findPlaylist(anywhereData);
      if (!this.playlistUrl) {
        this.setAnywhereData(anywhereData);
      } else {
        anywhereData.playlist_url = this.playlistUrl;
      }
    }
  }

  ngOnInit() {

    this.collectUrlData();

    this.allowAutoHeight = true;
    this.playlistDisabled = true;
    this.playerLock = window.location.href.includes('/vod');

    this.autoplay = JSON.parse(localStorage.getItem(VideoPlayerComponent.autoplayStorageKey));

    this.videoPlayerService.userData
      .subscribe(userData => {
        if (userData) {
          this.userDataLocalRef = userData;
        }
      });

    /**
     * Watch and dispatch Kaltura player Events;
     */
    this.videoPlayerStateBSubscription = this.videoPlayerService.getVideoPlayerStateBSubject()
      .subscribe((videoPlayerState: { event: any; data: KalturaPlayerEvents }) => {
          const _this = this;
          if (videoPlayerState) {
            switch (videoPlayerState.event) {
              case 'onChangeMedia':
                this.videoPlayerService.allowChangeMediaBSubject.next(false);
                this.getKDP().setKDPAttribute('servicesProxy.kalturaClient', 'ks', this.videoPlayerService.entryObjLocalRef.ks);
                setTimeout(() => {
                  _this.videoPlayerService.allowChangeMediaBSubject.next(true);
                }, 3000);
                break;
              case 'playerPlayEnd':
                if (this.autoplay) {
                  this.setAnywhereData(this.videoPlayerService.findAnywhereItems(this.playlistDataRef)[this.findNextToPlay()]);
                }
                break;
              case 'playerReady' :
                if (this.kalturaPlayerConfig.entryId
                  && ((this.FULHAM_DRM_ENTRY_ID === this.kalturaPlayerConfig.entryId)
                    || (this.LEEDS_DRM_ENTRY_ID === this.kalturaPlayerConfig.entryId))) {
                  this.sendKDPNotification(this.kdp, 'izsession', this.getCustomerId());
                }

                this.toggleThumbnail(false);
                this.videoPlayerService.allowChangeMediaBSubject.next(true);
                break;
              default:
                break;
            }
          }
        }
      );

    if (this.playlistUrl) {
      this.currentPlaylistSubscription = this.videoPlayerService
        .fetchPlaylistData(this.playlistUrl)
        .subscribe(res => {
          if (res) {
            this.playlistDataRef = res;
            const matchedEntry = this.matchEntryToPlaylist();
            this.currentPlaylistSubscription.unsubscribe();

            if (matchedEntry) {
              const selectedEntryId: string = matchedEntry.data.mediaData.entryId;
              if (this._entryId.localeCompare(selectedEntryId) !== 0) {
                this.setAnywhereData(matchedEntry.data);
              }
            } else if (this.videoPlayerService.entryObjLocalRef.entryId) {
              const separator = this.playlistUrl.indexOf('?') === -1 ? '?' : '&';
              const url = `${this.playlistUrl}${separator}query=(mediaData.entryId:(${this.videoPlayerService.entryObjLocalRef.entryId}))`;
              this.currentPlaylistSubscription = this.videoPlayerService
                .fetchPlaylistData(url)
                .subscribe(res2 => {
                  if (res2) {
                    const item = this.videoPlayerService.findAnywhereItems(res2);
                    this.setAnywhereData(item[0]);
                    this.currentPlaylistSubscription.unsubscribe();
                  }
                });
            }
          }
        });
    } else if (this.playlistData) {
      // @ts-ignore
      this.playlistDataRef = JSON.parse(this.playlistData);
    }

    this.videoPlayerService
      .autoplayBSubject
      .subscribe(val => {
        this.autoplay = val;
      });

  }

  fetchNextPlaylistPage() {
    if (this.playlistUrl && this.playlistDataRef && this.currentPlaylistSubscription == null) {
      const currentSection = this.playlistDataRef.sections[0] || this.playlistDataRef;
      const currentPagingData = currentSection.pagingData;
      const nextPageIndex: number = currentPagingData.pageIndex + 1;
      const parameters: {[key: string]: string} = UrlUtils.extractUrlParamsFromExistingUrl(this.playlistUrl);
      parameters.pageIndex = nextPageIndex.toString();
      const playlistUrl: string = UrlUtils.removeQueryParameters(this.playlistUrl) + '?' + UrlUtils.generateUrlParameters(parameters);

      this.currentPlaylistSubscription = this.videoPlayerService
        .fetchPlaylistData(playlistUrl)
        .subscribe(res => {
          if (res) {
            const newSection = res.sections[0] || res;
            const pagingData = newSection.pagingData;
            const pageIndex: number = pagingData.pageIndex;
            const pageSize: number = pagingData.pageSize;
            const base: number = pageIndex * pageSize;

            newSection.itemData
              .forEach((item, i: number) => {
                currentSection.itemData[base + i] = item;
              });

            currentPagingData.pageIndex = (currentSection.itemData.length / pageSize) - 1;
            this.playlistDataRef = {...this.playlistDataRef};
            this.currentPlaylistSubscription.unsubscribe();
            this.currentPlaylistSubscription = null;
          }
        });
    }
  }

  collectUrlData(): PlayerUrlData {
    const url = window.location.href;
    const pageRegex = /\/(.*?)((#)|(\?)|(\/)|($))/g;
    const urlData: PlayerUrlData = {
      pageRoute: url.match(pageRegex)
    };

    return urlData;
  }

  findEntryToPlay() {
    const entry = this.findEntryInURL();
    if (entry) {
      if (entry === this.LEEDS_DRM_ENTRY_ID) {
        this.kalturaPlayerConfig.uiConfId = this.LEEDS_DRM_UI_CONF;
      } else {
        this.kalturaPlayerConfig = this.kalturaConfigClean;
      }
    }
  }

  onKalturaScriptReady(script) {
    this.entryAndKsSubscription = this.videoPlayerService
      .getEntryAndKSBSubject()
      .subscribe(entryAndKs => {
        if (entryAndKs && entryAndKs.entryId) {
          if (entryAndKs.ks) {
            this.videoPlayerService.amgLogger('onKalturaScriptReady -> getEntryAndKSBSubject: ', entryAndKs);
            this.entryAndKSRef = entryAndKs;
            if (this.kdp && this.playerEmbeded) {
              if (entryAndKs.entryId && entryAndKs.ks) {
                this.initiateChangeMedia(entryAndKs.entryId);
              } else if (this.videoPlayerService.entryObjLocalRef && this.videoPlayerService.entryObjLocalRef.entryId) {
                this.initiateChangeMedia(this.videoPlayerService.entryObjLocalRef.entryId);
              }
            } else if (script && script.loaded && !this.playerEmbeded) {
              this.embedVideoPlayer();
            }
          } else {
            this.toggleThumbnail(true);
            this.sendKDPNotification(this.kdp, 'doPause');
            this.entryAndKSRef = {entryId: entryAndKs.entryId, ks: null};
          }
        }
      });
  }

  findEntryInURL() {
    const url = window.location.href;
    const entryStartIndex = url.indexOf('entry=');
    let entryId = '';
    if (entryStartIndex !== -1) {
      const entry = url.slice(entryStartIndex + 6, entryStartIndex + 16);
      if (entry) {
        entryId = entry;
      }
    }
    return entryId;
  }

  ngOnDestroy(): void {
    this.clearUiData();
    if (this.currentPlaylistSubscription) {
      this.currentPlaylistSubscription.unsubscribe();
    }
  }

  findPlaylist(data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === 'playlist_url') {
          this.playlistUrl = data[key];
        } else if (typeof data[key] === 'string') {
        } else if (data[key] !== null && data[key] !== '') {
          this.findPlaylist(data[key]);
        } else {
          return false;
        }
      }
    }
  }

  matchEntryToPlaylist(): { index: number, data: AnywhereItemData } {
    const itemData = this.videoPlayerService.findAnywhereItems(this.playlistDataRef);
    let entryData = null;
    if (itemData) {
      if (this.videoPlayerService.entryObjLocalRef && !this.videoPlayerService.entryObjLocalRef.entryId) {
        entryData = {index: 0, data: itemData[0]};
      }
      itemData.forEach((videoItem, index) => {
        const foundEntry = this.videoPlayerService.findEntryInMediaData(null, videoItem.mediaData);
        if (foundEntry && this.videoPlayerService.entryObjLocalRef.entryId === foundEntry.entryId) {
          entryData = {index, data: videoItem};
        }
      });
    }

    return entryData;
  }

  clickEntry(data: AnywhereItemData) {
    this.triggerEntryChange.emit(data ? data.mediaData.entryId : '');
    this.setAnywhereData(data);
    this.videoPlayerService.changeMediaMessage(data);
    this.kdp.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  }

  setAnywhereData(anywhereData: AnywhereItemData) {
    if (anywhereData) {
      if (!this.playerEmbeded) {
        this.initializePlayer();
      }
      this.setUiData(anywhereData);
    }
  }

  setUiData(anywhereData: AnywhereItemData) {
    if (anywhereData) {
      this.toggleThumbnail(true);
      this.kdp = this.getKDP();
      this.videoPlayerService.setAnywhereMediaDataBSubject(anywhereData);
      this.localAnywhereMediaData = anywhereData;
    } else {
      console.warn('Anywhere media data is not Provided: @Input() public set anywhereData');
    }
  }

  clearUiData() {
    this.changeDetectorRef.detach();
    if (this.entryAndKsSubscription) {
      this.entryAndKsSubscription.unsubscribe();
    }
    if (this.videoPlayerStateBSubscription) {
      this.videoPlayerStateBSubscription.unsubscribe();
    }
    this.videoPlayerService.entryObjLocalRef = {entryId: null, ks: null};
    this.localAnywhereMediaData = null;
    this.playlistDataRef = null;
    this.playerEmbeded = false;
  }

  /**
   * For not free entries:
   * click on play
   */
  onClickThumbnail(e) {
    e.preventDefault();
    if (this.videoPlayerService.canUserPlayMedia()) {
      this.sendKDPNotification(this.kdp, 'doPlay');
    }
  }

  sendKDPNotification(kdp, eventName, data?) {
    this.videoPlayerService.amgLogger('sendKDPNotification: ', data);
    if (!kdp) {
      kdp = this.kdp;
    }
    if (kdp && kdp.sendNotification) {
      kdp.sendNotification(eventName, data);
    }
  }

  getCustomerId() {
    const data = this.userDataLocalRef;
    if (data) {
      const session = data.CurrentCustomerSession;
      return (data && session && session.CustomerId) ? session.CustomerId : false;
    }

    return false;
  }

  playlistReady(value: boolean) {
    this.triggerPlaylistReady.emit(value);
  }

  /**
   * Set player ID;
   * Fetch Kaltura video player entry script;
   */

  private initializePlayer() {
    const _this = this;
    const playerConfig = this.kalturaPlayerConfig;
    if (!playerConfig.playerId) {
      playerConfig.playerId = this.PLAYER_ID;
    }

    this.videoPlayerService.getKalturaApi(playerConfig);
    if (!_this.kalturaScriptLoaded) {
      this.videoPlayerService.load('kalturaApi')
        .then(data => {
          this.videoPlayerService.amgLogger('load kalturaApi: switchScreens: ', _this.kalturaScriptLoaded);
          _this.kalturaScriptLoaded = true;
          this.onKalturaScriptReady(data[0]);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  private initiateChangeMedia(entryId: string | boolean) {
    this.videoPlayerService.amgLogger('initiateChangeMedia: ', entryId);
    this.kdp = this.getKDP();
    this.sendKDPNotification(this.kdp, 'changeMedia', {entryId});
  }

  private embedVideoPlayer() {
    const mediaObject = this.buildMediaEmbedObject();
    if (this.findEntryInURL() === this.LEEDS_DRM_ENTRY_ID) {
      const customerId = this.getCustomerId();

      mediaObject.uiconf_id = this.LEEDS_DRM_UI_CONF;

      if (customerId) {
        mediaObject.flashvars = {
          ...mediaObject.flashvars,
          izsession: this.getCustomerId() as string
        };
      }
    }
    if (this.entryAndKSRef && this.entryAndKSRef.ks) {
      mediaObject.flashvars.ks = this.entryAndKSRef.ks;
      if (!mediaObject.entry_id) {
        delete mediaObject.entry_id;
      }
      window.kWidget.embed(mediaObject);
      this.playerReady();
      this.playerEmbeded = true;
    }
  }

  private buildMediaEmbedObject(): kWidgetEmbed {
    const playerConfig = this.kalturaPlayerConfig;
    return {
      targetId: playerConfig.playerId,
      wid: '_' + playerConfig.partnerId,
      uiconf_id: playerConfig.uiConfId,
      entry_id: this.entryAndKSRef.entryId || playerConfig.entryId,
      flashvars: (playerConfig.flashvars) ? playerConfig.flashvars : {}
    };
  }

  private playerReady() {
    const _this = this;
    window.kWidget.addReadyCallback((playerId) => {
      _this.kdp = _this.getKDP();
      _this.initializePlayerEventListeners();
    });
  }

  private findNextToPlay(): number {
    const entryMatch = this.matchEntryToPlaylist();

    if (!entryMatch) {
      return 0;
    }

    return (entryMatch.index || entryMatch.index === 0) ? entryMatch.index + 1 : 0;
  }

  private getKDP(): any {
    const playerId = this.kalturaPlayerConfig.playerId;
    return document.getElementById(playerId ? playerId : this.PLAYER_ID);
  }

  private initializePlayerEventListeners() {
    const _this = this;

    if (this.kdp.addJsListener) {
      _this.kdp.addJsListener('doPlay', (res) => {
        _this.toggleThumbnail(false);
      });
      for (const key of Object.keys(KalturaPlayerEvents)) {
        this.kdp.kBind(KalturaPlayerEvents[key], (res) => {
          const data = {event: KalturaPlayerEvents[key], data: res};
          this.videoPlayerService.setVideoPlayerStateBSubject(data);
        });
      }
    }
  }

  private toggleThumbnail(show) {
    this.showThumbnail = show;
    this.changeDetectorRef.detectChanges();
  }

}
