import { OnInit } from '@angular/core';
import { CountdownResponsiveProperties, CountdownStaticProperties } from 'countdown';
import { Countdown as WpCountdown } from 'wordpress-adapter';
var CountdownComponent = /** @class */ (function () {
    function CountdownComponent() {
        this.class = 'amg-countdown';
    }
    CountdownComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.countdownData = [this.countdown.endTime];
        this.countdownResponsiveProperties = new CountdownResponsiveProperties(this.countdown.countdownConfiguration);
        this.countdownStaticProperties = new CountdownStaticProperties();
    };
    CountdownComponent.prototype.getCss = function () {
        return this.countdown.cssClass;
    };
    return CountdownComponent;
}());
export { CountdownComponent };
