import {
  Component,
  OnInit,
  Input,
  QueryList,
  ViewContainerRef,
  OnDestroy
} from '@angular/core';

import { Subscription, Observable } from 'rxjs';

import {
  AmgComponent,
  BootstrapViewportEnum,
  BootstrapViewportService,
} from 'amg';

import { CardProfile } from './card.profile';
import { CardProfileResponsiveProperties } from './card.profile.responsive.properties';
import { CardProfileResponsivePropertiesView } from './card.profile.responsive.properties.view';
import { CardProfileStaticProperties } from './card.profile.static.properties';

@Component({
  selector: 'amg-card-profile',
  templateUrl: 'card-profile.component.html',
  styles: [],
})
export class CardProfileComponent implements OnInit, OnDestroy, AmgComponent {
  @Input() componentData: CardProfile;
  @Input() responsiveProperties: CardProfileResponsiveProperties = new CardProfileResponsiveProperties();
  @Input() staticProperties: CardProfileStaticProperties = new CardProfileStaticProperties();
  
  @Input() componentChildren: QueryList<ViewContainerRef>;

  componentViewportSubscription: Subscription;

  componentCurrentView: CardProfileResponsivePropertiesView;
  
  responsiveTimeout: any;

  constructor(
    private bootstrapViewportService: BootstrapViewportService
  ) { }

  ngOnInit() {
    let viewportSubscriber:Observable<BootstrapViewportEnum> = this.bootstrapViewportService
      .getViewportSubscriber();

    let viewport: BootstrapViewportEnum = this.bootstrapViewportService.getViewport();
    this.componentCurrentView = this.responsiveProperties[viewport] as CardProfileResponsivePropertiesView;
    
    this.componentViewportSubscription = viewportSubscriber
      .subscribe(newBreakpoint => {
        if (this.responsiveTimeout) {
          clearTimeout(this.responsiveTimeout);
          this.responsiveTimeout = null;
        }

        this.responsiveTimeout = setTimeout(() => {
          this.componentCurrentView = this.responsiveProperties[newBreakpoint] as CardProfileResponsivePropertiesView;
        }, this.componentCurrentView.refreshRate);
      });
  }
  ngOnDestroy() {
    this.componentViewportSubscription.unsubscribe();
  }

  getThumbnailAsBackground() {
    return `url(${this.componentData.imageUrl})`;
  }

}
