/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../components/countdown/src/lib/countdown.component.ngfactory";
import * as i2 from "../../../../../../../../../components/countdown/src/lib/countdown.component";
import * as i3 from "../../../../../../../../../components/amg/src/lib/bootstrap.viewport.service";
import * as i4 from "./countdown.component";
var styles_CountdownComponent = [];
var RenderType_CountdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CountdownComponent, data: {} });
export { RenderType_CountdownComponent as RenderType_CountdownComponent };
export function View_CountdownComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-countdown", [], null, null, null, i1.View_CountdownComponent_0, i1.RenderType_CountdownComponent)), i0.ɵdid(1, 245760, null, 0, i2.CountdownComponent, [i3.BootstrapViewportService], { componentData: [0, "componentData"], responsiveProperties: [1, "responsiveProperties"], staticProperties: [2, "staticProperties"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.countdownData; var currVal_1 = _co.countdownResponsiveProperties; var currVal_2 = _co.countdownStaticProperties; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CountdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-countdown", [], [[1, "class", 0]], null, null, View_CountdownComponent_0, RenderType_CountdownComponent)), i0.ɵdid(1, 114688, null, 0, i4.CountdownComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var CountdownComponentNgFactory = i0.ɵccf("amg-wp-countdown", i4.CountdownComponent, View_CountdownComponent_Host_0, { countdown: "countdown" }, {}, []);
export { CountdownComponentNgFactory as CountdownComponentNgFactory };
