import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {VideoPlayerService} from '../../video-player.service';
import {AnywhereItemData} from '../../_data/video-player.model';

@Component({
  selector: 'amg-video-thumbnail',
  templateUrl: './video-thumbnail.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VideoThumbnailComponent implements OnInit, OnDestroy, AfterViewChecked {
  private _currentMediaData: AnywhereItemData;
  private _showThumbnail: boolean;

  @Input()
  set currentMedia(anywhereItemData: AnywhereItemData) {
    this._currentMediaData = anywhereItemData;
  }

  @Input()
  set showThumbnail(show: boolean) {
    this._showThumbnail = show;
  }

  get currentMedia(): AnywhereItemData {
    return this._currentMediaData;
  }

  get showThumbnail() {
    return this._showThumbnail;
  }

  constructor(public videoPlayerService: VideoPlayerService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this._showThumbnail = true;
    this._currentMediaData = null;
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.changeDetectorRef.detach();
  }

  getThumbnailImg() {
    if (this.currentMedia) {
      return this.currentMedia.mediaData.thumbnailUrl + '/width/' + ((window.innerWidth !== 0) ? window.innerWidth : 600).toString();
    }
  }

  isThumbnailAvailable(): boolean {
    const currentMedia = this.currentMedia;
    return currentMedia && currentMedia.mediaData && currentMedia.mediaData.thumbnailUrl;
  }

  public showLoginOverlay() {
    return !this.videoPlayerService.canUserPlayMedia();
  }
}



