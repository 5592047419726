import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AmgFixtureMediaData } from 'amg/lib/amg.fixture/amg.fixture.mediadata';
import {LiveButtonClick} from '../../types/LiveButtonClick';

@Component({
  selector: 'amg-live-buttons',
  templateUrl: './live-buttons.component.html'
})
export class LiveButtonsComponent implements OnInit {
  @Input() fixtureMediaData: { video: AmgFixtureMediaData[]; audio: AmgFixtureMediaData[]; };
  @Output() triggerClick: EventEmitter<LiveButtonClick>;

  // Component has been limited to 2 buttons for now.
  public mandatoryButtons: { video: AmgFixtureMediaData; audio: AmgFixtureMediaData; };

  constructor() {
    this.mandatoryButtons = {
      video: null,
      audio: null
    };

    this.triggerClick = new EventEmitter<LiveButtonClick>();
  }

  ngOnInit() {
    // At the moment only 2 buttons will be supported. But if you would like to generate more,
    //  Then iterate through the array.

    this.mandatoryButtons.video = this.fixtureMediaData.video.length > 0
      ? this.fixtureMediaData.video[0]
      : null;

    this.mandatoryButtons.audio = this.fixtureMediaData.audio.length > 0
      ? this.fixtureMediaData.audio[0]
      : null;
  }

  onButtonClick(type: 'video'|'audio', entry: AmgFixtureMediaData): void {
    this.triggerClick.emit({ type, entry });
  }

  isButtonDisabled(buttonData: AmgFixtureMediaData): boolean {
    return !(buttonData && buttonData.isEntryCurrentlyLive);
  }
}
