import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgCircleProgressModule } from 'ng-circle-progress';

import { CountdownComponent } from './countdown.component';

@NgModule({
  declarations: [
    CountdownComponent
  ],
  imports: [
    RouterModule,
    NgCircleProgressModule.forRoot({}),
  ],
  exports: [
    CountdownComponent
  ]
})
export class CountdownModule { }
