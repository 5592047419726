import * as tslib_1 from "tslib";
import { decrypt } from 'caesar-shift';
import { environment } from '../../../environments/environment';
import { configs } from '../configs';
var SportsAllianceUtil = /** @class */ (function () {
    function SportsAllianceUtil() {
    }
    SportsAllianceUtil.generateCookieString = function (payload, expiryDate) {
        return SportsAllianceUtil.cookieKey + "=" + encodeURIComponent(payload) + "; expires=" + expiryDate.toUTCString() + "; path=/; domain=" + environment.sso.cookieDomain;
    };
    SportsAllianceUtil.unObfuscatePayload = function (payload) {
        var randomEntropyRemoved = SportsAllianceUtil.removeRandomEntropy(payload, SportsAllianceUtil.entropyLength, SportsAllianceUtil.entropyIndex);
        var caesarShifted = SportsAllianceUtil.caesarShift(randomEntropyRemoved, SportsAllianceUtil.caesarShiftAmount);
        return atob(caesarShifted);
    };
    SportsAllianceUtil.generateFullhamSignInUrl = function (currentUrl) {
        var urlParameters = SportsAllianceUtil.generateReturnUrlParam(currentUrl);
        return "" + environment.sso.baseUrl + configs.sso.paths.login + "?" + urlParameters;
    };
    SportsAllianceUtil.generateFullhamSignOutUrl = function (currentUrl) {
        var newCurrentUrl = new URL(currentUrl);
        newCurrentUrl.searchParams.append('logout', '1');
        var urlParameters = SportsAllianceUtil.generateReturnUrlParam(newCurrentUrl.href);
        return "" + environment.sso.baseUrl + configs.sso.paths.logout + "?" + urlParameters;
    };
    SportsAllianceUtil.isTokenValid = function (token) {
        return token.access_token_expires_utc > new Date();
    };
    SportsAllianceUtil.isRefreshTokenValid = function (token) {
        return token.refresh_token_expires_utc > new Date();
    };
    SportsAllianceUtil.parseSsoData = function (token) {
        var ssoData = token
            .split('&')
            .map(function (data) {
            var _a;
            var values = data.split('=');
            var key = values[0];
            var value = key.endsWith('_utc')
                ? new Date(values[1])
                : values[1];
            return _a = {}, _a[key] = value, _a;
        }).reduce(function (previousValue, currentValue) {
            return tslib_1.__assign({}, previousValue, currentValue);
        });
        return ssoData;
    };
    SportsAllianceUtil.getPayloadAndUrl = function (url) {
        var index = url.indexOf(SportsAllianceUtil.fulhamPayloadQueryParam);
        if (index !== -1) {
            var newUrl = url.substr(0, index - 1);
            var payload = url.substr((index + SportsAllianceUtil.fulhamPayloadQueryParam.length + 1), url.length);
            var fragmentIndex = payload.indexOf('#');
            if (index !== -1) {
                newUrl = newUrl + payload.substr(fragmentIndex);
                payload = payload.substr(0, fragmentIndex);
            }
            return {
                payload: payload,
                url: newUrl
            };
        }
        return null;
    };
    SportsAllianceUtil.generateReturnUrlParam = function (currentUrl) {
        return [
            "successredirecturl=" + encodeURIComponent(currentUrl),
            "returnvisitorurl=" + encodeURIComponent(currentUrl),
            "tenantid=" + environment.sso.tenantId,
        ].join('&');
    };
    SportsAllianceUtil.removeRandomEntropy = function (payload, entropyLength, index) {
        return payload.substr(0, index) + payload.substr(index + entropyLength);
    };
    SportsAllianceUtil.caesarShift = function (text, shift) {
        return decrypt(shift, text);
    };
    SportsAllianceUtil.fulhamSsoTokenKey = 'ftoken';
    SportsAllianceUtil.cookieKey = '_sa_sso_upid_amg';
    SportsAllianceUtil.entropyLength = 22;
    SportsAllianceUtil.entropyIndex = 22;
    SportsAllianceUtil.caesarShiftAmount = 11;
    SportsAllianceUtil.fulhamPayloadQueryParam = 'tokenpayload';
    return SportsAllianceUtil;
}());
export { SportsAllianceUtil };
