var CollapsibleClick = /** @class */ (function () {
    /**
     * Click handler class for collapsible
     */
    function CollapsibleClick(_clickEvent) {
        this._clickEvent = _clickEvent;
        this._enableSlideClick = true;
    }
    /**
     * Enable clicking
     * @param source
     */
    CollapsibleClick.prototype.enableSlideClick = function (source) {
        if (source === void 0) { source = 'unknown'; }
        this._enableSlideClick = true;
    };
    /**
     * Disable clicking
     * @param source
     */
    CollapsibleClick.prototype.disableSlideClick = function (source) {
        if (source === void 0) { source = 'unknown'; }
        this._enableSlideClick = false;
    };
    /**
     * Click on slide
     * @param source
     * @param item item clicked on
     * @param type type of slide
     */
    CollapsibleClick.prototype.slideClick = function (source, item) {
        if (source === void 0) { source = 'unknown'; }
        if (this._enableSlideClick) {
            if (this._lastClicked !== item) {
                this._lastClicked = item;
                this._clickEvent.emit(item);
            }
        }
    };
    return CollapsibleClick;
}());
export { CollapsibleClick };
