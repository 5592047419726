import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {VideoPlayerService} from '../../video-player.service';
import {AnywhereItemData} from '../../_data/video-player.model';

@Component({
  selector: 'amg-video-details',
  templateUrl: './video-details.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VideoDetailsComponent implements OnInit {

  localAnywhereMediaData: AnywhereItemData;
  title: string | boolean;
  category: string[] | boolean;
  titleDate: any;
  titleDescription: string | boolean;
  isLiveContent: boolean;
  duration: any;

  private static padValue(value: string): string {
    return (value.length === 1)
      ? `0${value}`
      : value;
  }

  constructor(public videoPlayerService: VideoPlayerService) {
  }

  ngOnInit() {

    this.videoPlayerService.getAnywhereMediaDataBSubject()
      .subscribe(videoMediaData => {
        if (videoMediaData) {
          this.localAnywhereMediaData = videoMediaData;
          this.title = this.getTitle();
          this.category = this.getCategory();
          this.titleDate = this.getDate();
          this.titleDescription = this.getDescription();
          this.isLiveContent = this.videoPlayerService.isLive();
          this.duration = this.generateRuntime(this.getDuration());
        }
      });

  }

  public getDuration() {
    const data = this.localAnywhereMediaData;
    if (data) {
      return (data.metaData && data.metaData.VideoDuration)
        ? data.metaData.VideoDuration
        : 0;
    }

    return 0;
  }

  public getCategory() {
    const data = this.localAnywhereMediaData;
    if (data) {
      return (data.metaData && data.metaData.category)
        ? data.metaData.category
        : (data.metaData && data.metaData.categories)
          ? data.metaData.categories
          : false;
    }
    return false;
  }

  public getTitle() {
    const data = this.localAnywhereMediaData;
    if (data) {
      return (data.metaData && data.metaData.title)
        ? data.metaData.title
        : data.name
          ? data.name
          : false;
    }
    return false;
  }

  public getDate(): { day, month, year, hour, minute } | boolean {
    const data = this.localAnywhereMediaData;
    if (data) {
      const pubData = (data.publicationData) ? data.publicationData : (data) ? data : false;
      return (pubData && pubData.releaseFrom)
        ? pubData.releaseFrom
        : (pubData && pubData.startDate)
          ? pubData.startDate
          : false;
    }
    return false;
  }

  public getDescription() {
    const data = this.localAnywhereMediaData;
    if (data && data.metaData) {
      return (data.metaData.body)
        ? data.metaData.body
        : false;
    }
    return false;
  }

  public getTeam() {
    const data = this.localAnywhereMediaData;
    if (data) {
      return (data.metaData && data.metaData.team) ? data.metaData.team : false;
    }
    return false;
  }

  private generateRuntime(duration: number): any {
    const runtime = new Date((duration || 0) * 1000);

    return {
      seconds: {value: runtime.getUTCSeconds().toString(), paddedValue: VideoDetailsComponent.padValue(runtime.getUTCSeconds().toString())},
      minutes: {value: runtime.getUTCMinutes().toString(), paddedValue: VideoDetailsComponent.padValue(runtime.getUTCMinutes().toString())},
      hours: {value: runtime.getUTCHours().toString(), paddedValue: VideoDetailsComponent.padValue(runtime.getUTCHours().toString())}
    };
  }

}
