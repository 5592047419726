<div
  class="card amg-collapsible"
  [ngClass]="collapseClass()"
  >
  <div class="card-header">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <div class="d-flex flex-column amg-collapsible-titles">
        <h2 class="amg-collapsible-title">{{ getTitleText() }}</h2>
        <h3 class="amg-collapsible-subtitle">{{ getSubTitleText() }}</h3>
      </div>
      <button
        class="btn"
        [hidden]="!showToggle()"
        (click)="toggleCollapse()"
      >{{ getToggleText() }}</button>
    </div>
  </div>
  <div
    class="card-body collapse"
    [ngClass]="{ show: !isCollapsed() }"
  >
    <div
    *ngFor="let item of componentData; index as i;"
    (click)="onSlideClicked('click', item)"
    >
      <ng-template
        #componentChild
      ></ng-template>
    </div>
  </div>
</div>
