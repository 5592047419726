import * as tslib_1 from "tslib";
import { CardUtils } from '../../../../../util/CardUtils';
import { NgObject } from './NgObject';
var CarouselFlickity = /** @class */ (function (_super) {
    tslib_1.__extends(CarouselFlickity, _super);
    function CarouselFlickity(data, objectType) {
        if (objectType === void 0) { objectType = 'CarouselFlickity'; }
        var _this = _super.call(this, objectType, data) || this;
        _this._carousalAnywhereApiUrl = data.anywhere_api_url;
        _this._carouselConfiguration = tslib_1.__assign({ carouselType: data.carouselType, showNextPrevButtons: data.showNextPrevButtons, cardDisplayKeys: CardUtils.generateCardDisplayKeys(data.cardDisplayKeys) }, (data.triggerLoadMoreOnEndOfCarousel && data.triggerLoadMoreOnEndOfCarousel !== '0'
            ? { triggerLoadMoreOnEndOfCarousel: data.triggerLoadMoreOnEndOfCarousel }
            : {}));
        _this._carouselStaticData = {
            skipFirstEntry: data.skipFirstEntry || false
        };
        return _this;
    }
    Object.defineProperty(CarouselFlickity.prototype, "carouselStaticData", {
        get: function () {
            return this._carouselStaticData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CarouselFlickity.prototype, "carouselConfiguration", {
        get: function () {
            return this._carouselConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CarouselFlickity.prototype, "carousalAnywhereApiUrl", {
        get: function () {
            return this._carousalAnywhereApiUrl;
        },
        enumerable: true,
        configurable: true
    });
    return CarouselFlickity;
}(NgObject));
export { CarouselFlickity };
