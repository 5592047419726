<div class="amg-card-video card card-video">
  <div class="card-img-top" [style.backgroundImage]="'url(' + getThumbnail() + ')'">
    <img [src]="getThumbnail()" [alt]="getTitle()" />
  </div>
  <div class="card-body">
    <div class="categories">
      <span class="category" *ngFor="let category of getCategories()">{{ category }}</span>
    </div>
    <div class="entitlement">
      <span class="entitlement" [ngClass]="'entitlement-' + getEntitlement()">{{ getEntitlement() }}</span>
    </div>
    <div class="runtime">
      <span class="runtime">
        <span class="hours" [attr.data-hours]="getRuntime().hours.paddedValue">{{ getRuntime().hours.value }}</span>
        <span class="minutes" [attr.data-minutes]="getRuntime().minutes.paddedValue">{{ getRuntime().minutes.value }}</span>
        <span class="seconds" [attr.data-seconds]="getRuntime().seconds.paddedValue">{{ getRuntime().seconds.value }}</span>
      </span>
    </div>
    <div class="title">
      <span class="title">{{ getTitle() }}</span>
    </div>
    <div class="released">
      <span class="seconds"
            [attr.data-seconds]="getReleased().seconds.paddedValue">
        {{ getReleased().seconds.value }}
      </span>
      <span class="minutes"
            [attr.data-minutes]="getReleased().minutes.paddedValue">
        {{ getReleased().minutes.value }}
      </span>
      <span class="hours"
            [attr.data-hours]="getReleased().hours.paddedValue">
        {{ getReleased().hours.value }}
      </span>
      <span class="days"
            [attr.data-days]="getReleased().days.paddedValue"
            [attr.data-day-of-week]="getReleased().days.dayOfWeek">
        {{ getReleased().days.value }}
      </span>
      <span class="months"
            [attr.data-months]="getReleased().months.paddedValue"
            [attr.data-month-name]="getReleased().months.month">
        {{ getReleased().months.value }}
      </span>
      <span class="years"
            [attr.data-years-short]="getReleased().years.shortValue">
        {{ getReleased().years.value }}
      </span>
    </div>
  </div>
</div>
