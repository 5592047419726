import { BaseObject } from '../../../BaseObject';
import { RestApiConfiguration } from '../../../config/RestApiConfiguration';
import { Button } from '../html-elements/Button';
import { InputField } from '../html-elements/InputField';

export class InputFormGroup extends BaseObject {
  private readonly _inputFields: InputField[];
  private readonly _submitButton: Button;
  private readonly _restApiConfiguration: RestApiConfiguration;

  constructor(data: any) {
    super('InputFormGroup', data);

    this._inputFields = data.input_fields || [];
    this._submitButton = data.submit_button;
    this._restApiConfiguration = data.rest_api_configuration;
  }

  get inputFields(): InputField[] {
    return this._inputFields;
  }

  get restApiConfiguration(): RestApiConfiguration {
    return this._restApiConfiguration;
  }

  get submitButton(): Button {
    return this._submitButton;
  }
}
