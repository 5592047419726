import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateFilterProperties, GridFilterGroup, SearchBarProperties } from '../../types/GridStaticProperties';
import { FilterByText, SearchTrigger } from '../../types/SearchTrigger';
import {Observable, Subscription} from 'rxjs';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'amg-filter-bar',
  templateUrl: './filter-bar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FilterBarComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-filter-bar search-container';
  @Input() searchBarProperties: SearchBarProperties;
  @Input() searchObservable: Observable<string>;
  @Input() resetFilterBarObservable: Observable<boolean>;
  @Input() searchValue: string;
  @Output() triggerSearch: EventEmitter<SearchTrigger>;
  @Output() resetFilterTriggerEmitter: EventEmitter<boolean>;

  public filterBarId: string;

  form: FormGroup = new FormGroup({
    search: new FormControl(),
  });

  private resetFilterTriggerEmitterInternal: EventEmitter<boolean>;

  private searchSubscription: Subscription;
  private resetFilterBarSubscription: Subscription;

  private static generateFilterByText(inputValue: string, key?: string): FilterByText {
    return {
      value: inputValue,
      ...(key ? {key} : {})
    };
  }

  constructor() {
    this.filterBarId = uuid();
    this.triggerSearch = new EventEmitter();
    this.resetFilterTriggerEmitter = new EventEmitter();
    this.resetFilterTriggerEmitterInternal = new EventEmitter<boolean>();
  }

  ngOnInit() {
    if (this.searchObservable) {
      this.searchSubscription = this.searchObservable.subscribe(value => {
        if (typeof value === 'string') {
          this.form.get('search').setValue(value || this.searchValue || '');
        }
      });
    }

    if (this.resetFilterBarObservable) {
      this.resetFilterBarSubscription = this.resetFilterBarObservable.subscribe(value => {
        if (value) {
          this.resetFilterTriggerEmitterInternal.emit(value);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    if (this.resetFilterBarSubscription) {
      this.resetFilterBarSubscription.unsubscribe();
    }
  }

  isFilterBarEnabled(): boolean {
    return !!this.searchBarProperties;
  }

  isSearchEnabled(): boolean {
    return this.isFilterBarEnabled() && !!this.searchBarProperties.searchField;
  }

  isFilteringDropdownEnabled(): boolean {
    return this.isFilterBarEnabled() && !!this.searchBarProperties.searchFilter;
  }

  isFilteringDateEnabled(): boolean {
    return this.isFilterBarEnabled() && !!this.searchBarProperties.dateFilter;
  }

  isResetAllEnabled(): boolean {
    return this.searchBarProperties.resetAll || false;
  }

  getFilterGroups(): GridFilterGroup[] {
    return this.searchBarProperties.searchFilter.filters;
  }

  getDateFilterProperties(): DateFilterProperties {
    return this.searchBarProperties.dateFilter;
  }

  getSearchLabel(): string {
    return this.searchBarProperties.searchField.label;
  }

  getSearchPlaceholder(): string {
    return this.searchBarProperties.searchField.placeholder;
  }

  clearInputField(searchInput: HTMLInputElement, event?: KeyboardEvent): void {
    if (!event || event.key === 'Enter') {
      this.form.get('search').setValue('');
      this.submitSearch();
      searchInput.focus();
    }
  }

  onFilterChange(value: SearchTrigger): void {
    this.triggerSearch.emit(value);
  }

  resetFilters(): void {
    this.form.get('search').setValue('');
    this.resetFilterTriggerEmitter.emit(true);
  }

  getFilterSubjectAsObservable(): Observable<boolean> {
    return this.resetFilterTriggerEmitter.asObservable();
  }

  getFilterInternalEmitterAsObservable(): Observable<boolean> {
    return this.resetFilterTriggerEmitterInternal.asObservable();
  }

  submitSearch(event?: any) {
    if (event) {
      event.preventDefault();
    }

    const search = this.form.get('search').value;
    this.triggerSearch.emit({
      type: 'search',
      searchData: FilterBarComponent.generateFilterByText(search, this.searchBarProperties.searchField.key)
    });
  }
}
