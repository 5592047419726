import {
  AmgComponentResponsiveProperties,
  BootstrapViewportEnum,
} from 'amg';

import { CalendarResponsivePropertiesView } from './calendar.responsive.properties.view';

export class CalendarResponsiveProperties implements AmgComponentResponsiveProperties {
  private static keys: Array<string> = Object.keys(BootstrapViewportEnum);

  xs?: CalendarResponsivePropertiesView;
  sm?: CalendarResponsivePropertiesView;
  md?: CalendarResponsivePropertiesView;
  lg?: CalendarResponsivePropertiesView;
  xl?: CalendarResponsivePropertiesView;
  HD?: CalendarResponsivePropertiesView;
  FullHd?: CalendarResponsivePropertiesView;
  FourK?: CalendarResponsivePropertiesView;

  constructor(
    params?: any,
  ) {
    if (params === undefined) {
      params = {};
    }
    this.xs = params.xs || null;
    this.sm = params.sm || null;
    this.md = params.md || null;
    this.lg = params.lg || null;
    this.xl = params.xl || null;
    this.HD = params.HD || null;
    this.FullHd = params.FullHd || null;
    this.FourK = params.FourK || null;
    this.synchroniseProperties();
  }

  private synchroniseProperties(overrideResponsive?: CalendarResponsivePropertiesView) {
    if (overrideResponsive === undefined) {
      overrideResponsive = {} as CalendarResponsivePropertiesView;
    }
    let previousView: CalendarResponsivePropertiesView = CalendarResponsivePropertiesView.defaultValues();
    CalendarResponsiveProperties.keys.forEach((viewPort) => {
      let currentView: CalendarResponsivePropertiesView = this[viewPort];
      let currentValues: CalendarResponsivePropertiesView = overrideResponsive[viewPort];
      currentView = {...previousView, ...currentView, ...currentValues};
      this[viewPort] = currentView;
      previousView = currentView;
    });
  }
}
