import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var Advertisement = /** @class */ (function (_super) {
    tslib_1.__extends(Advertisement, _super);
    function Advertisement(data) {
        return _super.call(this, 'Advertisement', data) || this;
    }
    return Advertisement;
}(BaseObject));
export { Advertisement };
