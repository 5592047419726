import { HttpClient } from '@angular/common/http';
import { ClientRequestAdapter } from './adapter/ClientRequestAdapter';
import { ClientRequest } from './api/client/ClientRequest';
import { ClientOptions } from './config/client-options';
import * as i0 from "@angular/core";
import * as i1 from "./config/client-options";
import * as i2 from "@angular/common/http";
var WordpressAdapterService = /** @class */ (function () {
    function WordpressAdapterService(clientOptions, http) {
        this.clientOptions = clientOptions;
        this.http = http;
    }
    WordpressAdapterService.prototype.request = function (request, endpoint) {
        if (request instanceof ClientRequest) {
            return new ClientRequestAdapter(this.http).transmit(request, this.clientOptions);
        }
        else {
            return new ClientRequestAdapter(this.http).transmit(ClientRequest.generateClientRequest(request, endpoint), this.clientOptions);
        }
    };
    WordpressAdapterService.ngInjectableDef = i0.defineInjectable({ factory: function WordpressAdapterService_Factory() { return new WordpressAdapterService(i0.inject(i1.CLIENT_OPTIONS, 2), i0.inject(i2.HttpClient)); }, token: WordpressAdapterService, providedIn: "root" });
    return WordpressAdapterService;
}());
export { WordpressAdapterService };
