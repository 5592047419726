import { OnInit, QueryList, ViewContainerRef, OnDestroy } from '@angular/core';
import { BootstrapViewportService, } from 'amg';
import { CardProfileResponsiveProperties } from './card.profile.responsive.properties';
import { CardProfileStaticProperties } from './card.profile.static.properties';
var CardProfileComponent = /** @class */ (function () {
    function CardProfileComponent(bootstrapViewportService) {
        this.bootstrapViewportService = bootstrapViewportService;
        this.responsiveProperties = new CardProfileResponsiveProperties();
        this.staticProperties = new CardProfileStaticProperties();
    }
    CardProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        var viewportSubscriber = this.bootstrapViewportService
            .getViewportSubscriber();
        var viewport = this.bootstrapViewportService.getViewport();
        this.componentCurrentView = this.responsiveProperties[viewport];
        this.componentViewportSubscription = viewportSubscriber
            .subscribe(function (newBreakpoint) {
            if (_this.responsiveTimeout) {
                clearTimeout(_this.responsiveTimeout);
                _this.responsiveTimeout = null;
            }
            _this.responsiveTimeout = setTimeout(function () {
                _this.componentCurrentView = _this.responsiveProperties[newBreakpoint];
            }, _this.componentCurrentView.refreshRate);
        });
    };
    CardProfileComponent.prototype.ngOnDestroy = function () {
        this.componentViewportSubscription.unsubscribe();
    };
    CardProfileComponent.prototype.getThumbnailAsBackground = function () {
        return "url(" + this.componentData.imageUrl + ")";
    };
    return CardProfileComponent;
}());
export { CardProfileComponent };
