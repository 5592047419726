<ng-container *ngIf="canInitialiseVideoPlayer; else disabledView">
  <amg-video-player *ngIf="isInitialised"
                    (triggerPlaylistReady)="onPlaylistReady()"
                    (triggerEntryChange)="onEntryChange($event)"
                    [apiConfigs]="getConfiguration()"
                    [anywhereData]="getVideoPlayerData()"
                    [playlistUrl]="getPlaylistUrl()"
                    [entryId]="getEntryId()"
                    [liveLoginOverlay]="getLiveLoginOverlay()"
                    [videoSignUpConfig]="getPlayButtonConfig()">
  </amg-video-player>
</ng-container>

<ng-template #disabledView>
  <div class="amg-message-container drm-message">
    <span class="message">
      <span class="text"
            [innerHTML]="getDrmMessage()"></span>
    </span>
  </div>
</ng-template>
