import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { BoxsetCardComponent } from './boxset-card.component';

@NgModule({
  declarations: [BoxsetCardComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [BoxsetCardComponent]
})
export class BoxsetCardModule { }
