import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var SocialLinks = /** @class */ (function (_super) {
    tslib_1.__extends(SocialLinks, _super);
    function SocialLinks(data) {
        var _this = _super.call(this, 'SocialLinks', data) || this;
        _this._socialLinks = data.social_links;
        return _this;
    }
    Object.defineProperty(SocialLinks.prototype, "socialLinks", {
        get: function () {
            return this._socialLinks;
        },
        enumerable: true,
        configurable: true
    });
    return SocialLinks;
}(BaseObject));
export { SocialLinks };
