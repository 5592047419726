/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./call-to-action.component";
var styles_CallToActionComponent = [];
var RenderType_CallToActionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CallToActionComponent, data: {} });
export { RenderType_CallToActionComponent as RenderType_CallToActionComponent };
function View_CallToActionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "amg-cta-description"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); }); }
function View_CallToActionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "amg-cta-btn-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "a", [["class", "amg-cta-btn"]], [[8, "href", 4], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.forgotPassword.external_link; var currVal_1 = _co.forgotPassword.label; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CallToActionComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "amg-cta-btn-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "a", [["class", "amg-cta-btn"]], [[8, "href", 4], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkUrl; var currVal_1 = _co.label; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_CallToActionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CallToActionComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CallToActionComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CallToActionComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.forgotPassword; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.linkUrl; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_CallToActionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-call-to-action", [], null, null, null, View_CallToActionComponent_0, RenderType_CallToActionComponent)), i0.ɵdid(1, 114688, null, 0, i2.CallToActionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CallToActionComponentNgFactory = i0.ɵccf("amg-call-to-action", i2.CallToActionComponent, View_CallToActionComponent_Host_0, { callToAction: "callToAction" }, {}, []);
export { CallToActionComponentNgFactory as CallToActionComponentNgFactory };
