import { OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Calendar as WpCalendar } from 'wordpress-adapter';
import { CalendarResponsiveProperties } from 'calendar';
import { ApiService } from '../../../../../shared/api/api.service';
import { DataService } from '../../../../../shared/data/data.service';
var CalendarComponent = /** @class */ (function () {
    function CalendarComponent(apiService, dataService, router) {
        this.apiService = apiService;
        this.dataService = dataService;
        this.router = router;
        this.class = 'amg-calendar';
    }
    CalendarComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.initialiseCalendar();
        this.triggerDataRetrieval();
    };
    CalendarComponent.prototype.ngOnDestroy = function () {
        if (this.apiDataObservable) {
            this.apiDataObservable.unsubscribe();
        }
    };
    CalendarComponent.prototype.onFixtureClick = function (data) {
        if (this.calendar.onClickNavigate) {
            this.dataService.userInteractedObject = {
                fixtureUrl: this.calendar.restApiConfiguration.baseUrl,
                data: data.entry
            };
            this.router.navigate([this.calendar.onClickNavigate], {
                queryParams: {
                    fixture: this.dataService.encodeUrl(this.calendar.restApiConfiguration.baseUrl),
                    entry: data.entry.entryId
                },
                preserveQueryParams: false
            });
        }
    };
    CalendarComponent.prototype.getCss = function () {
        return this.calendar.cssClass;
    };
    CalendarComponent.prototype.initialiseCalendar = function () {
        this.calendarResponsiveProperties = new CalendarResponsiveProperties(this.calendar.calendarConfiguration);
        this.calendarStaticProperties = {
            _tabs: undefined,
            showTabsAsText: true,
            cardFixtureStaticProperties: {
                buttonType: 'btn',
                audioButtonTitle: this.calendar.staticProperties.audioButtonTitle,
                videoButtonTitle: this.calendar.staticProperties.videoButtonTitle
            }
        };
        this.calendarData = [];
    };
    CalendarComponent.prototype.triggerDataRetrieval = function () {
        var _this = this;
        this.apiDataObservable = this.apiService.getApiData(this.calendar.restApiConfiguration).subscribe(function (data) {
            if (data) {
                _this.calendarData = _this.getFixtureData(data);
            }
        });
    };
    CalendarComponent.prototype.getFixtureData = function (data) {
        if (data) {
            if (data.fixtures && data.fixtures.length) {
                return data.fixtures;
            }
        }
        return [];
    };
    return CalendarComponent;
}());
export { CalendarComponent };
