import { BaseObject } from '../../../BaseObject';

export class Text extends BaseObject {
  private readonly _text: string;

  constructor(data: any) {
    super('Text', data);

    this._text = data.text;
  }

  get text(): string {
    return this._text;
  }
}
