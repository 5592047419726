<div *ngIf="isTypeLink()"
     class="amg-link-container">
  <ng-container *ngIf="isInternalLink(); else externalLink">
    <a class="nav-link amg-nav-link"
       routerLinkActive="active"
       [innerHTML]="getLabel()"
       [routerLink]="getPath()"
       [fragment]="getFragment()"></a>
  </ng-container>
</div>

<div *ngIf="isTypeButton()"
     class="amg-button-container">
  <ng-container *ngIf="isInternalLink(); else externalButton">
    <button type="button"
            class="btn amg-button"
            routerLinkActive="active"
            [innerHTML]="getLabel()"
            [routerLink]="getPath()"
            [fragment]="getFragment()"></button>
  </ng-container>
</div>

<ng-template #externalLink>
  <a class="nav-link amg-nav-link"
     [innerHTML]="getLabel()"
     tabindex="0"
     [href]="getExternalLink()"></a>
</ng-template>

<ng-template #externalButton>
  <button type="button"
          class="btn amg-button"
          [innerHTML]="getLabel()"
          (click)="triggerExternalRedirect()"></button>
</ng-template>
