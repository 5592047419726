import { Pipe, PipeTransform } from '@angular/core';

import { CardFixture } from 'card-fixture';

import { CalendarTabData } from './calendar.tab.data';

@Pipe({
  name: 'relevantfixtures'
})
export class RelevantFixturesPipe implements PipeTransform {

  /**
   * Basic filtering of fixtures based on selected fixture day/month/year
   * @param fixtures an array that contains the fixture data as Array<AmgFixture>
   * @param args a CalendarTabData describing the current filter 
   */
  transform(fixtures: Array<CardFixture>, args: CalendarTabData = {}): any {
    let filteredFixtures: Array<CardFixture> = [];
    if (args.day !== undefined) {

      const day: number = Number(args.day);
      const month: number = Number(args.month);
      const year: number = Number(args.year);
      fixtures.forEach((fixture: CardFixture) => {
        if (
          (day === fixture.fixtureDate.day) &&
          (month === fixture.fixtureDate.month) &&
          (year === fixture.fixtureDate.year)
        ) {
          filteredFixtures.push(fixture);
        }
      });

    } else if (args.month !== undefined) {

      const month: number = Number(args.month);
      const year: number = Number(args.year);
      fixtures.forEach((fixture: CardFixture) => {
        if (
          (month === fixture.fixtureDate.month) &&
          (year === fixture.fixtureDate.year)
        ) {
          filteredFixtures.push(fixture);
        }
      });

    } else if (args.year !== undefined) {

      const year: number = Number(args.year);
      fixtures.forEach((fixture: CardFixture) => {
        if (
          (year === fixture.fixtureDate.year)
        ) {
          filteredFixtures.push(fixture);
        }
      });

    } else {
      filteredFixtures = fixtures;
    }

    return filteredFixtures;
  }

}
