import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Boxset as WpBoxset } from 'wordpress-adapter';
import { ApiService } from '../../../../../shared/api/api.service';
import { DataService } from '../../../../../shared/data/data.service';

@Component({
  selector: 'amg-wp-boxset',
  templateUrl: './boxset.component.html',
  encapsulation: ViewEncapsulation.None
})
export class BoxsetComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-boxset';
  @Input() boxset: WpBoxset;

  boxsetData: any;
  boxsetResponsiveProperties: any;
  boxsetStaticProperties: any;

  private apiDataObservable: Subscription;

  constructor(private apiService: ApiService,
              private dataService: DataService,
              private router: Router) {
  }

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.initialiseBoxset();
  }

  getCss(): string {
    return this.boxset.cssClass;
  }

  onCardClick(data: any) {
    if (this.boxset.onClickNavigate) {
      this.dataService.userInteractedObject = data;
      this.router.navigate([this.boxset.onClickNavigate]);
    }
  }

  private initialiseBoxset(): void {
    this.boxsetData = [];

    if (this.boxset.restApiConfiguration) {
      this.apiDataObservable = this.apiService.getApiData(this.boxset.restApiConfiguration).subscribe(data => {
        if (data) {
          this.boxset = this.getData(data);
        }
      });
    }

    this.boxsetResponsiveProperties = this.boxset.carouselConfiguration;
  }

  private getData(data: any): any {
    // TODO mappings

    // https://63r7ry8te4.execute-api.eu-west-1.amazonaws.com/staging/fixtures/football/p/3000021
    return {
    };
  }
}
