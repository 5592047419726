import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  private leftPadSingleDigit(digit: number): string {
    return digit < 10
      ? `0${digit.toString()}`
      : digit.toString();
  }

  transform(dateTime: string, arg?: 'hour' | 'minute' | 'day' | 'month' | 'year'): any {
    let released = null;
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    if (!dateTime.toUpperCase().endsWith('Z')) {
      dateTime += 'Z';
    }
    const date = new Date(dateTime);
    released = {
      hour: this.leftPadSingleDigit(date.getHours()),
      minute: this.leftPadSingleDigit(date.getMinutes()),
      day: this.leftPadSingleDigit(date.getDate()),
      month: monthNames[(date.getMonth())],
      year: date.getFullYear().toString()
    };

    return released[arg];
  }

}
