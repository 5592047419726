import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {OtherContent} from 'wordpress-adapter';
import {DataService} from './core/service/data/data.service';
import {WordpressService} from './core/service/wordpress/wordpress.service';
import {Location} from '@angular/common';
import {SsoService} from './core/service/sso-service/sso.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'FFCtv';

  public otherContent: OtherContent;
  public isOnVodRoute: boolean;

  private otherContentSubscription: Subscription;
  private routerSubscription: Subscription;

  private static getRouteFromUrl(url: string): string {
    return url.split('?')[0];
  }

  constructor(private titleService: Title,
              private wordpressService: WordpressService,
              private router: Router,
              private dataService: DataService,
              @Inject('Window') private window: Window,
              // private ssoService: SsoServiceOld,
              private ssoService: SsoService,
              protected location: Location) {
    this.setIsOnVodPage(false);
    this.titleService.setTitle(this.title);
    this.isOnVodRoute = false;
  }

  private setIsOnVodPage(value: boolean) {
    const stringValue = value ? 'true' : 'false';
    this.window.document.body.setAttribute('data-is-vod', stringValue);
  }

  ngOnInit(): void {

    this.ssoService.init();

    this.setIsOnVod(AppComponent.getRouteFromUrl(this.router.url));

    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {

        const currentRoute = event.urlAfterRedirects.split('?')[0];

        this.setIsOnVod(AppComponent.getRouteFromUrl(currentRoute));
      });

    this.otherContentSubscription = this.wordpressService.getOtherContent().subscribe(response => {
      this.otherContent = response;

      if (response.footer) {
        this.dataService.setFooterData(response.footer);
      }
    });
    // }
  }

  ngOnDestroy(): void {
    this.otherContentSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  private setIsOnVod(route: string): void {
    this.isOnVodRoute = (route === '/vod');
    this.setIsOnVodPage(this.isOnVodRoute);
  }
}
