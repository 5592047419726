import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
var FormHeaderComponent = /** @class */ (function () {
    function FormHeaderComponent() {
    }
    FormHeaderComponent.prototype.ngOnInit = function () {
    };
    return FormHeaderComponent;
}());
export { FormHeaderComponent };
