import { OnDestroy, OnInit } from '@angular/core';
import { Fixture as WpFixture } from 'wordpress-adapter';
import { ApiService } from '../../../../../shared/api/api.service';
var FixtureComponent = /** @class */ (function () {
    function FixtureComponent(api) {
        this.api = api;
        this.class = 'amg-fixture';
        this.isLoadingData = false;
        this.isIndividualFixture = false;
    }
    FixtureComponent.getFirstRecordFromData = function (data) {
        return (data && data.fixtures && data.fixtures.length)
            ? data.fixtures[0]
            : null;
    };
    FixtureComponent.prototype.ngOnInit = function () {
        this.initializeFixture();
    };
    FixtureComponent.prototype.ngOnDestroy = function () {
        if (this.apiDataObservable) {
            this.apiDataObservable.unsubscribe();
        }
    };
    FixtureComponent.prototype.getCss = function () {
        return this.fixture
            ? this.fixture.cssClass
            : '';
    };
    FixtureComponent.prototype.isOfTypeFixture = function (fixture) {
        return fixture.type === 'fixture';
    };
    FixtureComponent.prototype.isFixtureCalendar = function () {
        return this.isIndividualFixture
            ? (this.fixture && this.fixture.fixtureCardType === FixtureComponent.CARD_TYPE_CALENDAR)
            : (this.apiFixtureData && this.apiFixtureData.fixtureCardType === FixtureComponent.CARD_TYPE_CALENDAR);
    };
    FixtureComponent.prototype.initializeFixture = function () {
        if (this.fixture) {
            this.initializeIndividualFixture();
        }
        else {
            this.initializeFixtureComingFromFixtures();
        }
        this.isDataAvailable = !!this.fixtureData;
    };
    FixtureComponent.prototype.initializeFixtureComingFromFixtures = function () {
        this.fixtureData = Array.isArray(this.apiFixtureData.data)
            ? this.apiFixtureData.data[0]
            : this.apiFixtureData.data;
        this.fixtureOnClickNavRoute = this.apiFixtureData.onClickNavRoute;
        this.fixtureApiUrl = this.apiFixtureData.fixtureApiUrl;
        this.fixtureSendEntryDataOnNavigation = this.apiFixtureData.sendEntryDataOnNavigation;
    };
    FixtureComponent.prototype.initializeIndividualFixture = function () {
        var _this = this;
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.isIndividualFixture = true;
        this.isLoadingData = true;
        this.fixtureOnClickNavRoute = this.fixture.onClickNavigate;
        this.fixtureSendEntryDataOnNavigation = this.fixture.sendEntryDataOnNavigation;
        this.fixtureApiUrl = this.fixture.restApiConfiguration.baseUrl;
        if (this.fixture.restApiConfiguration) {
            this.apiDataObservable = this.api.getApiData(this.fixture.restApiConfiguration).subscribe(function (data) {
                if (data) {
                    _this.fixtureData = FixtureComponent.getFirstRecordFromData(data);
                }
                _this.isLoadingData = false;
            });
        }
    };
    FixtureComponent.CARD_TYPE_CALENDAR = 'calendar';
    return FixtureComponent;
}());
export { FixtureComponent };
