var CalendarHover = /** @class */ (function () {
    /**
     * Hover handler class for calendar
     */
    function CalendarHover(_hoverEvent) {
        this._hoverEvent = _hoverEvent;
        this._enableSlideHover = true;
    }
    /**
     * Get index of slide being hovered
     * @param source
     */
    CalendarHover.prototype.getCurrentSlideHover = function (source) {
        if (source === void 0) { source = 'unknown'; }
        if (this._enableSlideHover) {
            return this._currentSlideHovered;
        }
        return -1;
    };
    /**
     * Enable hovering behaviour
     * @param source
     */
    CalendarHover.prototype.enableSlideHover = function (source) {
        if (source === void 0) { source = 'unknown'; }
        this._enableSlideHover = true;
    };
    /**
     * Disable hovering behaviour
     */
    CalendarHover.prototype.disableSlideHover = function (source) {
        if (source === void 0) { source = 'unknown'; }
        this._enableSlideHover = false;
    };
    /**
     * Set slide as being hovered
     * @param source
     * @param slide
     * @param slide data
     */
    CalendarHover.prototype.slideHover = function (source, slide, item) {
        if (source === void 0) { source = 'unknown'; }
        if (this._currentSlideHovered !== slide) {
            if (this._enableSlideHover) {
                this._currentSlideHovered = slide;
                this._hoverEvent.emit(item);
            }
        }
    };
    /**
     * Set slide as not being hovered
     * @param source
     * @param slide
     */
    CalendarHover.prototype.slideHoverOut = function (source, slide) {
        if (source === void 0) { source = 'unknown'; }
        if (this._currentSlideHovered === slide) {
            if (this._enableSlideHover) {
                this._currentSlideHovered = -1;
                this._hoverEvent.emit(undefined);
            }
        }
    };
    return CalendarHover;
}());
export { CalendarHover };
