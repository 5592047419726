<ng-template [ngIf]="dataObj" let-modal>
  <!-- Template -BEGIN -->

  <div class="amg-template">

    <amg-form-header
      [formHeader]="logo"
      [modalRef]="modalRef">
    </amg-form-header>

    <amg-form-builder
      [formData]="form">
    </amg-form-builder>

    <amg-call-to-action
      [callToAction]="dataObj">
    </amg-call-to-action>

    <ng-template [ngIf]="links">
      <amg-call-to-action
        *ngFor="let obj of links"
        [callToAction]="obj">
      </amg-call-to-action>
    </ng-template>

  </div>

  <!-- Template -END -->
</ng-template>
