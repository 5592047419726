import {ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AnywhereItemData} from '../../../../_data/video-player.model';

@Component({
  selector: 'amg-thumbnail',
  templateUrl: './thumbnail.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ThumbnailComponent implements OnInit {
  thumbnailUrl: string;
  audioIcon: boolean;
  videoIcon: boolean;
  private STREAM_AMG_IMAGE_SRC = 'open.http.mp.streamamg';

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  private _cardData: AnywhereItemData;

  get cardData(): AnywhereItemData {
    return this._cardData;
  }

  @Input() set cardData(cardData: AnywhereItemData) {
    this._cardData = cardData;
  };

  ngOnInit(): void {
    this.thumbnailUrl = '';
    this.audioIcon = false;
    this.videoIcon = false;
    if (this.cardData) {
      this.thumbnailUrl = this.getThumbnail(this.cardData.mediaData);
      this.changeDetectorRef.detectChanges();
    }
  }

  playIcon() {
    switch (this.cardData.mediaData.mediaType) {
      case 'OnDemand':
      case 'video':
        this.videoIcon = true;
        return true;
      case 'audio':
        this.audioIcon = true;
        return true;
      default:
        return false;
    }
  }

  getThumbnail(mediaData) {
    const thumbnail = (mediaData.thumbnail)
      ? mediaData.thumbnail
      : (mediaData.thumbnailUrl)
        ? mediaData.thumbnailUrl
        : null;

    return (thumbnail && thumbnail.includes(this.STREAM_AMG_IMAGE_SRC))
      ? thumbnail + 'width/300'
      : (thumbnail)
        ? thumbnail
        : null;
  }


  secondsToHms(d: number) {
    const hours = Math.floor(d / 3600),
      minutes = Math.floor(d % 3600 / 60),
      seconds = Math.floor(d % 3600 % 60);

    const hDisplay = (hours > 0)
      ? (hours >= 10)
        ? `${hours}:`
        : `0${hours}:`
      : '00:';

    const mDisplay = (minutes > 0)
      ? (minutes >= 10)
        ? `${minutes}:`
        : `0${minutes}:`
      : '00:';

    const sDisplay = (seconds > 0)
      ? (seconds >= 10)
        ? `${seconds}`
        : `0${seconds}`
      : '00';

    return hDisplay + mDisplay + sDisplay;
  }

}
