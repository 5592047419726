import { AfterViewChecked, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { VideoPlayerService } from '../../video-player.service';
var VideoThumbnailComponent = /** @class */ (function () {
    function VideoThumbnailComponent(videoPlayerService, changeDetectorRef) {
        this.videoPlayerService = videoPlayerService;
        this.changeDetectorRef = changeDetectorRef;
    }
    Object.defineProperty(VideoThumbnailComponent.prototype, "currentMedia", {
        get: function () {
            return this._currentMediaData;
        },
        set: function (anywhereItemData) {
            this._currentMediaData = anywhereItemData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoThumbnailComponent.prototype, "showThumbnail", {
        get: function () {
            return this._showThumbnail;
        },
        set: function (show) {
            this._showThumbnail = show;
        },
        enumerable: true,
        configurable: true
    });
    VideoThumbnailComponent.prototype.ngOnInit = function () {
        this._showThumbnail = true;
        this._currentMediaData = null;
    };
    VideoThumbnailComponent.prototype.ngAfterViewChecked = function () {
        this.changeDetectorRef.detectChanges();
    };
    VideoThumbnailComponent.prototype.ngOnDestroy = function () {
        this.changeDetectorRef.detach();
    };
    VideoThumbnailComponent.prototype.getThumbnailImg = function () {
        if (this.currentMedia) {
            return this.currentMedia.mediaData.thumbnailUrl + '/width/' + ((window.innerWidth !== 0) ? window.innerWidth : 600).toString();
        }
    };
    VideoThumbnailComponent.prototype.isThumbnailAvailable = function () {
        var currentMedia = this.currentMedia;
        return currentMedia && currentMedia.mediaData && currentMedia.mediaData.thumbnailUrl;
    };
    VideoThumbnailComponent.prototype.showLoginOverlay = function () {
        return !this.videoPlayerService.canUserPlayMedia();
    };
    return VideoThumbnailComponent;
}());
export { VideoThumbnailComponent };
