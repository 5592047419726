import { OnInit } from '@angular/core';
import { DateObject } from './date';
var DateComponent = /** @class */ (function () {
    function DateComponent() {
    }
    DateComponent.leftPadSingleDigit = function (digit) {
        return digit < 10
            ? "0" + digit.toString()
            : digit.toString();
    };
    DateComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(DateComponent.prototype, "hour", {
        get: function () {
            return DateComponent.leftPadSingleDigit(this.fixtureDate.hour);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "minute", {
        get: function () {
            return DateComponent.leftPadSingleDigit(this.fixtureDate.minute);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "day", {
        get: function () {
            return DateComponent.leftPadSingleDigit(this.fixtureDate.day);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "month", {
        get: function () {
            return DateComponent.leftPadSingleDigit(this.fixtureDate.month);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateComponent.prototype, "year", {
        get: function () {
            return this.fixtureDate.year.toString();
        },
        enumerable: true,
        configurable: true
    });
    return DateComponent;
}());
export { DateComponent };
