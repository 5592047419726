import { BaseObject } from '../BaseObject';

export class KalturaConfiguration extends BaseObject {
  private _apiUrl: string;
  private _playerId: string;
  private _partnerId: string;
  private _uiConfigId: string;
  private _entryId: string;
  private _ks: string;
  private _forcePlayerEmbed: boolean;
  private _flashVars: { [key: string]: { [key: string]: (string|number|boolean) } };

  private static convertFlashVars(data: { flashVar: string; data: any }[]): { [key: string]: { [key: string]: (string|number|boolean) } } {
    const flashVars: { [key: string]: { [key: string]: (string|number|boolean) } } = {};

    data.forEach(flashVar => {
      const key = flashVar.flashVar,
        valueData = flashVar.data;

      let flashVarValue = null;

      if (Array.isArray(valueData)) {
        flashVarValue = {};

        valueData.forEach(dataObject => {
          const dataObjectKeys = Object.keys(dataObject);

          if (dataObjectKeys.length > 0) {
            const dataObjectKey = dataObjectKeys[0];
            flashVarValue[dataObjectKey] = dataObject[dataObjectKey];
          }
        });
      } else if (valueData && typeof valueData === 'object') {
        flashVarValue = {};
        console.error('Object is not yet supported!');
      } else {
        flashVarValue = valueData;
      }

      flashVars[key] = flashVarValue;
    });

    return flashVars;
  }

  constructor(data: any) {
    super('KalturaConfiguration', data);

    if (data.kaltura_player_configuration) {
      (data.kaltura_player_configuration as any[]).forEach(config => {
        if (config.api_url) {
          this._apiUrl = config.api_url;
        } else if (config.player_id) {
          this._playerId = config.player_id;
        } else if (config.partner_id) {
          this._partnerId = config.partner_id;
        } else if (config.ui_config_id) {
          this._uiConfigId = config.ui_config_id;
        } else if (config.entry_id) {
          this._entryId = config.entry_id;
        } else if (config.key_session) {
          this._ks = config.key_session;
        } else if (config.force_player_embed) {
          this._forcePlayerEmbed = config.force_player_embed;
        } else if (config.flashVars) {
          this._flashVars = KalturaConfiguration.convertFlashVars(config.flashVars);
        }
      });
    }
  }

  get forcePlayerEmbed(): boolean {
    return this._forcePlayerEmbed;
  }

  get flashVars(): { [p: string]: { [p: string]: string | number | boolean } } {
    return this._flashVars;
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  get playerId(): string {
    return this._playerId;
  }

  get partnerId(): string {
    return this._partnerId;
  }

  get uiConfigId(): string {
    return this._uiConfigId;
  }

  get entryId(): string {
    return this._entryId;
  }

  get ks(): string {
    return this._ks;
  }
}
