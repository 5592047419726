<form class="amg-form"
      method="post"
      [action]="getFormActionUrl()"
      (submit)="onSubmit($event)">
  <div *ngFor="let inputField of getInputFields()" class="amg-form-input-group">
    <amg-wp-input-field [inputField]="inputField"
                        [inputClearObservable]="getInputClearAsObservable()"
                        (triggerChange)="triggerFieldChange($event)">
    </amg-wp-input-field>
  </div>

  <div class="amg-form-message" *ngIf="(data.isFormSubmitted && !data.formError) || data.formError">
    <span *ngIf="data.isFormSubmitted && !data.formError"
          class="message-success"></span>
    <span *ngIf="data.formError"
          class="message-error"></span>
  </div>

  <div class="amg-form-button-container">
    <button class="btn amg-button amg-form-button"
            type="submit"
            [ngClass]="getButtonCssClass()"
            [innerHTML]="getButtonText()"></button>
  </div>
</form>
