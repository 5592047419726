import {
  ViewContainerRef,
  ComponentFactoryResolver,
  QueryList,
} from '@angular/core';

import {
  AmgComponent,
  AmgComponentResponsiveProperties,
} from 'amg';

import {
  CardRegistry,
  CardResponsiveInstantiate,
} from 'card';

import { GridResponsiveProperties } from '../grid.responsive.properties';
import { GridStaticProperties } from '../types/GridStaticProperties';

export class GridOnChildrenAdded {
  private cardResponsive: AmgComponentResponsiveProperties;
  private componentFactory;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private componentData: Array<any>,
    private responsiveProperties: GridResponsiveProperties,
    private staticProperties: GridStaticProperties,
    private componentChildren: QueryList<ViewContainerRef>,
  ) {
    this.updateFactory();
  }

  private updateFactory() {
    const component = CardRegistry.CardComponentRegistry.get(`Card${this.staticProperties.cardType}Component`);
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    this.cardResponsive = CardResponsiveInstantiate.instantiate(
      this.staticProperties.cardType,
      {
        xs: this.responsiveProperties.xs.cardView,
        sm: this.responsiveProperties.sm.cardView,
        md: this.responsiveProperties.md.cardView,
        lg: this.responsiveProperties.lg.cardView,
        xl: this.responsiveProperties.xl.cardView,
      }
    );
  }

  private cast<T>(o: T): T {
    return o;
  }

  private runUpdate() {
    this.updateFactory();
    this.componentChildren.forEach((viewContainerRef: ViewContainerRef, i: number) => {
      if (viewContainerRef.length === 0) {
        const componentRef = viewContainerRef.createComponent(this.componentFactory);

        const componentRefInstance = (<  AmgComponent>componentRef.instance);
        componentRefInstance.componentData = this.componentData[i];
        componentRefInstance.responsiveProperties = this.cardResponsive;
        // componentRefInstance.staticProperties = this.staticProperties.cardStatic;
        componentRefInstance.staticProperties = undefined;

        componentRef.changeDetectorRef.detectChanges();
      }
    });
  }

  ngAfterViewInit(
  ) {
    this.runUpdate();
  }

  onChildrenAdded(
  ) {
    this.runUpdate();
  }

  ngOnChanges(
    componentData: Array<any>,
    responsiveProperties: GridResponsiveProperties,
    staticProperties: GridStaticProperties,
    componentChildren: QueryList<ViewContainerRef>,
  ) {
    this.componentData = componentData;
    this.responsiveProperties = responsiveProperties;
    this.staticProperties = staticProperties;
    this.componentChildren = componentChildren;

    this.runUpdate();
  }
}
