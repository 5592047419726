import * as tslib_1 from "tslib";
var CardVideoResponsivePropertiesView = /** @class */ (function () {
    function CardVideoResponsivePropertiesView() {
    }
    CardVideoResponsivePropertiesView.defaultValues = function (input) {
        var toReturn;
        if (input === undefined) {
            toReturn = new CardVideoResponsivePropertiesView();
        }
        else {
            toReturn = input;
        }
        toReturn = tslib_1.__assign({}, toReturn, {
            refreshRate: 200,
        });
        return toReturn;
    };
    return CardVideoResponsivePropertiesView;
}());
export { CardVideoResponsivePropertiesView };
