import { HttpClient } from '@angular/common/http';
import {Inject, Injectable, isDevMode} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  LoginDataJson,
  ScriptData,
  ScriptDataInternal,
  StreamPaymentScriptsConfig
} from './shared/data/stream-payments-scripts.model';
import {
  Init,
  StreamPayments,
  StreamPaymentsData,
  StreamPaymentsHooks,
  UserAuthentificationData
} from './shared/data/stream-payments.model';
import { UserState } from './shared/data/user-auth.model';
import { TemplateBuilderComponent } from './shared/template-builder/template-builder.component';

declare var document: any;
declare var streamPayments: StreamPayments;

export const ScriptStore: ScriptDataInternal[] = [];

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  private static readonly jwtTokenKey = 'stream-token';
  // Vars
  public streamPaymentsInitialized: boolean = false;
  public modalRef: NgbModalRef;
  public authTokenRef: string;
  // private observables
  private _userDataBSubject: BehaviorSubject<UserAuthentificationData> = new BehaviorSubject<UserAuthentificationData>(null);
  private scripts: any = {};
  // Observables
  private streamPaymentsSessionState: BehaviorSubject<UserAuthentificationData> = new BehaviorSubject<UserAuthentificationData>(null);
  private userLoginState: BehaviorSubject<UserState> = new BehaviorSubject<UserState>(null);
  private userSubscriptionState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private modalData: BehaviorSubject<LoginDataJson> = new BehaviorSubject<LoginDataJson>(null);
  private streamPaymentsSubscriptionPackage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private formSubmissionResponse: BehaviorSubject<UserAuthentificationData> = new BehaviorSubject<UserAuthentificationData>(null);
  private userLoginDetailsSubject: BehaviorSubject<{ name: string; lastName: string; isUserLoggedIn: boolean }> =
    new BehaviorSubject(null);

  private paymentsData: Init;

  private getJwtToken(): string {
    const token = window.localStorage.getItem(UserAuthService.jwtTokenKey);
    return token
      ? token
      : '';
  }

  private getConfig(): Init {
    const language = 'en';

    if (this.config.isSso) {
      const jwtToken = this.getJwtToken();

      return { jwtToken, language };
    }

    return { language };
  }

  constructor(@Inject('videoPlayerModuleConfig') private config: StreamPaymentScriptsConfig,
              private modalService: NgbModal,
              private http: HttpClient) {

    this.paymentsData = this.getConfig();

    const sessionDataRaw = localStorage.getItem('StreamPaymentSessionData');

    if (sessionDataRaw) {
      const sessionData = JSON.parse(sessionDataRaw);

      if (sessionData && sessionData.UpdatedAt) {
        // set auth cookie
        if (sessionData.AuthenticationToken) {
          document.cookie = (sessionData.AuthenticationToken)
            ? '.auth = ' + sessionData.AuthenticationToken + `/domain=${this.getStreamPaymentsUrl()}`
            : '';
        }

        const dateNow = new Date();
        const updatedAt = new Date(sessionData.UpdatedAt);
        const diffInMins = (dateNow.getUTCHours() * 60 - updatedAt.getUTCHours() * 60
          + dateNow.getUTCDay() * 60 * 24 - updatedAt.getUTCDay() * 60 * 24
          + dateNow.getUTCMinutes() - updatedAt.getUTCMinutes());

        if (diffInMins >= 24 * 60) {
          this.hookStreamPayments(StreamPaymentsHooks.doLogout, null);
        } else {
          this.setUserSessionState(sessionData);
        }
      }
    }

    this.getPaymentsSession().subscribe(res => {
      this.customerSessionData = res;
    });

    this.initStreamPayments(this.paymentsData, () => {
      if (this.config && this.config.loginFormJson) {
        this.setModalData(this.config.loginFormJson);
      }

      if (config.isSso) {
        this.triggerPaymentsOnTokenUpdate();
      }
    });

    localStorage.removeItem('StreamPaymentLoginCallback');
  }

  public triggerPaymentsOnTokenUpdate(): void {
    const config = this.getConfig();
    this.checkForChangesAndInitPayments(config);

    if (config.jwtToken) {
      streamPayments.getSessionState(null, (sessionData) => {
        if (sessionData) {
          this.setUserSessionState(sessionData);
        }

        if (isDevMode()) {
          console.log('[SSO Stream Payments Session check]', sessionData);
        }
      });
    }
  }

  public get customerSessionData(): Observable<UserAuthentificationData> | any {
    return this._userDataBSubject.asObservable();
  }

  public set customerSessionData(userData: UserAuthentificationData | any) {
    if (userData && userData.CurrentCustomerSession !== null) {
      this.setUserState(UserState.loggedIn);
      this._userDataBSubject.next(userData);
    }
  }

  public initStreamPayments(data: Init, callback) {
    if (!this.streamPaymentsInitialized) {
      return this.loadDependencies(this.config.streamPayments, (res) => {
        if (res) {
          streamPayments.init(data);
          if (callback) {
            this.streamPaymentsInitialized = true;
            callback(res);
          }
        } else {
          this.streamPaymentsInitialized = false;
          console.warn('Stream Payments script could not be successfully loaded');
        }
      });
    } else {
      this.checkForChangesAndInitPayments(data);
      callback(true);
    }
  }

  private checkForChangesAndInitPayments(data: Init): void {
    if (this.config.isSso
      && data.jwtToken !== this.paymentsData.jwtToken) {
      // re-init with token
      streamPayments.init(data);
      this.paymentsData = data;
    }
  }

  public getFormSubmissionResponse(): Observable<UserAuthentificationData> {
    return this.formSubmissionResponse.asObservable();
  }

  public setFormSubmissionResponse(data: UserAuthentificationData) {
    this.amgLogger('setFormSubmissionResponse', data);
    this.formSubmissionResponse.next(data);
    if (!data.ModelErrors) {
      this.modalRef.close();
    }
  }

  public getUserSessionState(): Observable<UserAuthentificationData> {
    return this.streamPaymentsSessionState.asObservable();
  }

  public setUserSessionState(userData: any) {
    if (userData) {
      this.streamPaymentsSessionState.next(userData);
      if (userData.JwtTokenCacheKey) {
        this.paymentsData = {
          ...this.paymentsData,
          jwtToken: userData.JwtTokenCacheKey
        };
      }
      if (userData.CurrentCustomerSession && userData.CurrentCustomerSession.Id) {
        this.authTokenRef = userData.CurrentCustomerSession.Id;
      }
      if (userData.CurrentCustomerSession !== null) {
        this.customerSessionData = userData;
        this.setUserLoginDetails({
          name: userData.CurrentCustomerSession.CustomerFirstName || '',
          lastName: userData.CurrentCustomerSession.CustomerLastName || '',
          isUserLoggedIn: true
        });

      }
    }
  }

  public getUserLoginDetailsObservable(): Observable<{ name: string; lastName: string; isUserLoggedIn: boolean }> {
    return this.userLoginDetailsSubject.asObservable();
  }

  public setUserSubscriptionState(state: boolean) {
    this.userSubscriptionState.next(state);
  }

  public getUserSubscriptionState(): Observable<boolean> {
    return this.userSubscriptionState.asObservable();
  }

  public setUserState(state: UserState) {
    this.userLoginState.next(state);
  }

  public getUserState(): Observable<UserState> {
    return this.userLoginState.asObservable();
  }

  public setPackageData(data) {
    this.streamPaymentsSubscriptionPackage.next(data);
  }

  public setModalData(data) {
    this.modalData.next(data);
  }

  public getModalData(): Observable<any> {
    return this.modalData.asObservable();
  }

  /**
   * Stream Payment script wrapper;
   * @param functionName
   * @param data
   */
  public hookStreamPayments(functionName: StreamPaymentsHooks, data: StreamPaymentsData): Promise<UserAuthentificationData> {
    let _this = this;
    return new Promise((resolve, reject) => {
        this.initStreamPayments(this.getConfig(), () => {
          if (data && this.authTokenRef && functionName !== 'doLogin' && this.isSafariBrowser()) {
            data['apisessionid'] = this.authTokenRef;
          }
          streamPayments[functionName](<any> data, (res) => {
            _this.handleResponse(res, resolve, reject, functionName);
            if (functionName === 'doLogout') {
              this.clearUserData();
              location.reload();
            }
          });
        });
      }
    );
  }

  // StreamPayments specific - BEGIN

  public loadExternalApiScript(scriptConf, callback) {
    this.enqueueScript(scriptConf)
      .then((res) => {
        callback(res);
      });
  }

  public openModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
    this.modalRef = this.modalService.open(TemplateBuilderComponent, {
      keyboard: true,
      backdrop: 'static'
    });
    if (!this.modalRef.componentInstance.dataObj) {
      this.getModalData()
        .subscribe((modalData) => {
          if (modalData) {
            if (this.modalRef.componentInstance) {
              this.modalRef.componentInstance.dataObj = modalData;
              this.modalRef.componentInstance.modalRef = this.modalRef;
            }
          }
        });
    }

  }

  public amgLogger(functionName, data) {
    // console.log('~~~~ Function Name: ', functionName);
    // console.log('~~~~ Data: ', data);
  }

  public getPaymentsSession(): Observable<any> {
    return this.http.get(`${this.getStreamPaymentsUrl(true)}/api/v1/session/prescence`);
  }

  private getStreamPaymentsUrl(generateProtocol?: boolean): string {
    const pathArray = this.config.streamPayments.src.split('/');
    const protocol = pathArray[0];
    const host = pathArray[2];

    return generateProtocol
      ? protocol + '//' + host
      : host;
  }

  private setUserLoginDetails(data: { name: string; lastName: string; isUserLoggedIn: boolean }): void {
    this.userLoginDetailsSubject.next(data);
  }

  private isSafariBrowser() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        return false;
      } else {
        return true;
      }
    }
  }

  /**
   * Intercept response to update session and user states when necessary;
   * @param res - response from stream payments;
   * @param resolve - resolves promise with data retrieved from stream payments;
   * @param reject - reject promise in case stream payments returns error;
   * @param functionName - stream payments function name as os in stream-payments.model;
   */
  private handleResponse(res, resolve, reject, functionName) {
    const errors = this.outputError(res.ModelErrors);
    if (errors) {
      reject(errors);
    } else {
      if (res) {
        if (functionName === 'doLogin' ||
          functionName === 'getUserSessionState') {
          this.setUserSessionState(res);
          if (res.PageUrl) {
            window.location.href = res.PageUrl;
          }
        }
      }
      resolve(res);
    }
  }

  // StreamPayments specific - END

  private outputError(err) {
    let error = false;
    if (err) {
      if (err.emailaddress) {
        error = err.emailaddress;
      }
      if (err.password) {
        error = err.password;
      }
    }
    return error;
  }

  private clearUserData() {
    this.customerSessionData = null;
    this.userLoginDetailsSubject.next(null);
    localStorage.removeItem('StreamPaymentSessionData');
    localStorage.removeItem(UserAuthService.jwtTokenKey);
    this.clearAllCookies();
  }

  private clearAllCookies() {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  /**
   * Load required external Apis;
   * @param scriptConfig
   * @param callback
   */
  private loadDependencies(scriptConfig: ScriptData, callback) {
    // load dependencies first
    if (scriptConfig.dependencies) {
      scriptConfig.dependencies.forEach((dep, index) => {
        this.enqueueScript(dep)
          .then((depRes) => {
            if (depRes) {
              if (scriptConfig.dependencies.length - 1 === index) {
                this.loadExternalApiScript(scriptConfig, (res) => {
                  callback(res);
                });
              }
            }
          });
      });
    } else {
      this.loadExternalApiScript(scriptConfig, (res) => {
        callback(res);
      });
    }
  }

  private load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      }
    });
  }

  /**
   * Load external dependencies
   * @param script
   */
  private enqueueScript(script: ScriptDataInternal) {
    ScriptStore.push(script);
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
    return this.load(script.name);
  }

}
