import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {
  ContactSubmissionData,
  ContactSubmissionForm,
  LoginSubmissionForm,
  StreamPaymentsHooks
} from '../../shared/data/stream-payments.model';
import {LoginDataJson} from '../../shared/data/stream-payments-scripts.model';
import {FormBuilder, FormControl} from '@angular/forms';
import {UserAuthService} from '../../user-auth.service';

@Component({
  selector: 'amg-form-builder',
  templateUrl: './form-builder.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FormBuilderComponent implements OnInit {
  @Input() formData: ContactSubmissionData | LoginDataJson;

  public formRef = this.fb.group({});
  public localFormRef: FormControl[] = [];
  public formOutput;

  public formSubmissionMessageResponse = {
    success: '',
    error: ''
  };

  constructor(private fb: FormBuilder,
              private userAuthService: UserAuthService) {
  }

  ngOnInit() {
    if (this.formData) {
      if (Array.isArray(this.formData)) {
        this.loopFormControlArray(this.formData);
      } else {
        this.loopFormControlObjects(this.formData);
      }
    }

    this.userAuthService.getFormSubmissionResponse()
      .subscribe(formResponse => {
        if (formResponse && formResponse.ModelErrors) {
          const formError = formResponse.ModelErrors[Object.keys(formResponse.ModelErrors)[0]];
          this.formSubmissionMessageResponse.error = formError ? formError : 'The details provided are incorrect, please try again.';
        }
      });
  }

  public isTextInput(formField) {
    return !formField.input_type || (formField.input_type === 'text') || (formField.input_type === 'password');
  }

  public isPasswordInput(formField) {
    return formField.input_type === 'password';
  }

  public isSelectInput(formField) {
    return formField.input_type === 'select';
  }

  public isSubmitButton(formField) {
    return formField.input_type === 'button' || formField.input_type === 'submit' || formField.input_type === 'submit_button';
  }

  public getPlaceholder(formField) {
    return (formField.placeholder) ? formField.placeholder : '';
  }

  public getInputType(formField) {
    return formField.input_type;
  }

  public getFormControlName(formField) {
    return formField.controlName;
  }

  public formSubmitType() {
    const loginFormFields = this.formTemplates();
    let submitType;
    let matchingResults = [];
    loginFormFields
      .forEach(loginField => {
        Object.keys(this.formRef.controls)
          .forEach((formKey) => {
            if (formKey === loginField) {
              matchingResults.push(true);
            }
          });
      });

    if (this.reduceBooleanArray(matchingResults) && matchingResults.length === loginFormFields.length) {
      submitType = StreamPaymentsHooks.doLogin;
    } else {
      submitType = StreamPaymentsHooks.doContactSubmission;
    }
    return submitType;
  }

  private formTemplates() {
    return ['emailaddress', 'password'];
  }

  private reduceBooleanArray(array) {
    const reducer = (accumulator, currentValue) => {
      return accumulator || currentValue;
    };
    return array.reduce(reducer);
  }

  private loopFormControlObjects(formObj) {
    const form = (formObj.form) ? formObj.form : formObj;
    if (form) {
      Object.keys(form)
        .forEach(objKey => {
          const currentObj = form[objKey];
          const controlVal = (currentObj.value) ? currentObj.value : (currentObj.values) ? currentObj.values : '';
          if (!currentObj.input_type) {
            currentObj.input_type = objKey;
          }
          this.createControl(`${currentObj.internal_reference_name}`, controlVal, currentObj);
        });
    }
  }

  private loopFormControlArray(formArray) {
    formArray.forEach((formObj, index) => {
      this.createControl(`${formObj.internal_reference_name}`, formObj.value, formObj);
    });
  }

  private createControl(controlName, controlValues, localRefData) {
    localRefData.controlName = controlName;
    if (controlName) {
      this.formRef.addControl(controlName, new FormControl((controlValues) ? controlValues : ''));
    }
    this.localFormRef.push(localRefData);
  }

  private formatFormOutput(streamPaymentsHook: StreamPaymentsHooks): ContactSubmissionForm | LoginSubmissionForm {
    if (streamPaymentsHook === StreamPaymentsHooks.doContactSubmission) {
      return {
        body: this.formRef.value.body,
        emailaddress: this.formRef.value.emailaddress,
        name: this.formRef.value.name,
        subject: this.formRef.value.subject
      };
    }
    if (streamPaymentsHook === StreamPaymentsHooks.doLogin) {
      return {
        emailaddress: this.formRef.value.emailaddress,
        password: this.formRef.value.password
      };
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.formOutput = this.formSubmitType();
    const dataOutput = this.formatFormOutput(this.formOutput);
    const doSubmit = this.userAuthService.hookStreamPayments(this.formOutput, dataOutput);
    doSubmit.then(res => {
      this.userAuthService.setFormSubmissionResponse(res);
    }).catch(err => {
      console.log('catch: ', err);
      this.formSubmissionMessageResponse.error = err;
    });
  }


}
