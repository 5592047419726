import { NgObject } from './NgObject';

export class LiveFixtureCard extends NgObject {
  private readonly _fixtureApiUrl: string;

  constructor(data: any, objectType: string = 'LiveFixtureCard') {
    super(objectType, data);

    this._fixtureApiUrl = data.fixture_api_url;
  }

  get fixtureApiUrl(): string {
    return this._fixtureApiUrl;
  }
}
