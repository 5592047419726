import * as tslib_1 from "tslib";
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ApiException } from '../api/ApiException';
var ClientRequestAdapter = /** @class */ (function () {
    function ClientRequestAdapter(http) {
        this.http = http;
    }
    ClientRequestAdapter.prototype.transmit = function (request, clientOptions) {
        var _this = this;
        return this.http
            .get(clientOptions.wordpressApiUrl + "/" + request.endpoint)
            .pipe(catchError(function (error) {
            var errorMessage = error instanceof HttpErrorResponse
                ? error.message
                : error;
            throw new ApiException(errorMessage);
        }), map(function (result) {
            return _this.unwrapResponse(result);
        }), map(function (result) {
            return request.handleResponse(result);
        }));
    };
    ClientRequestAdapter.prototype.unwrapResponse = function (response) {
        if (Array.isArray(response)) {
            var pageData_1 = {};
            response.forEach(function (pageSection) {
                if (pageSection.post_type === 'shared_section') {
                    if (pageSection.post_name === 'header') {
                        pageData_1.otherContent = tslib_1.__assign({}, pageData_1.otherContent, { header: pageSection.acf });
                    }
                    else if (pageSection.post_name === 'footer') {
                        pageData_1.otherContent = tslib_1.__assign({}, pageData_1.otherContent, { footer: pageSection.acf });
                    }
                    else if (pageSection.post_name === 'overlay') {
                        pageData_1.otherContent = tslib_1.__assign({}, pageData_1.otherContent, { overlay: pageSection.acf });
                    }
                }
                else if (pageSection.post_type === 'page') {
                    pageData_1.page = pageSection.acf;
                }
            });
            return pageData_1;
        }
        return null;
    };
    return ClientRequestAdapter;
}());
export { ClientRequestAdapter };
