import { BaseObject } from '../../../BaseObject';
import { Button } from '../html-elements/Button';

export class PaymentLoginButtonGroup extends BaseObject {
  private _logOutButton: Button;
  private _registerButton: Button;
  private _loginButton: Button;
  private _subscribeButton: Button;

  constructor(data: any) {
    super('PaymentLoginButtonGroup', data);

    if (data.button_group && data.button_group.length > 0) {
      (data.button_group as Button[]).forEach(button => {
        switch (button.flexibleContentId) {
          case 'register_button':
            this._registerButton = button;
            break;
          case 'log_in_button':
            this._loginButton = button;
            break;
          case 'subscribe_button':
            this._subscribeButton = button;
            break;
          case 'log_out_button':
            this._logOutButton = button;
            break;
        }
      });
    }
  }

  get logOutButton(): Button {
    return this._logOutButton;
  }

  get registerButton(): Button {
    return this._registerButton;
  }

  get loginButton(): Button {
    return this._loginButton;
  }

  get subscribeButton(): Button {
    return this._subscribeButton;
  }
}
