import { HammerGestureConfig } from '@angular/platform-browser';

declare var Hammer: any;

export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      pan: { direction: Hammer.DIRECTION_HORIZONTAL },
      swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    });
    return mc;
  }
}
