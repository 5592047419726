<!-- Contact-submission template -BEGIN -->
<ng-template [ngIf]="localFormRef">

  <form *ngIf="formRef"
        [formGroup]="formRef"
        (ngSubmit)="onSubmit($event)">

    <div *ngFor="let formField of localFormRef">
      <!-- Text Input -BEGIN -->
      <ng-template
        [ngIf]="isTextInput( formField ) && !isPasswordInput( formField )">
        <input
          [formControlName]="getFormControlName(formField)"
          [type]="getInputType(formField)"
          [placeholder]="getPlaceholder(formField)"
          class="amg-form-field">
      </ng-template>
      <!-- Text Input -END -->

      <!-- Password -BEGIN -->
      <ng-template
        [ngIf]="isPasswordInput( formField )">
        <input
          [formControlName]="getFormControlName(formField)"
          type="password"
          [placeholder]="getPlaceholder(formField)"
          class="amg-form-field">
      </ng-template>
      <!-- Password -END -->

      <!-- Select Input -BEGIN -->
      <ng-template
        [ngIf]="isSelectInput(formField)">
        <select [formControlName]="getFormControlName(formField)"
                class="amg-form-field amg-select">
          <option *ngFor="let opt of formField.values">{{opt}}</option>
        </select>
      </ng-template>
      <!-- Select Input -END -->

      <!-- Button -BEGIN -->
      <ng-template
        [ngIf]="isSubmitButton(formField)">
        <button type="submit">{{formField.label}}</button>
      </ng-template>
      <!-- Button -BEGIN -->
    </div>

  </form>

</ng-template>
<!-- Contact-submission template -END -->

<!-- Error output template -BEGIN -->
<ng-template [ngIf]="formSubmissionMessageResponse && formSubmissionMessageResponse.error">
  <div class="amg-form-error">{{formSubmissionMessageResponse.error}}</div>
</ng-template>
<!-- Error output template -END -->
