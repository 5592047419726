import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { CarouselConfiguration } from '../../../types/ng-objects/CarouselConfiguration';
import { ScreenConfiguration } from '../../../types/ng-objects/ScreenConfiguration';
import { NgObject } from './NgObject';

export class Carousel extends NgObject {
  private readonly _carouselType: string;
  private readonly _carouselSideBar: string[];
  private readonly _useAnywhereApiConfiguration: boolean;
  private readonly _carouselConfiguration: ScreenConfiguration<CarouselConfiguration>;

  constructor(data: any, objectType: string = 'Carousel') {
    super(objectType, data);

    this._carouselType = data.type;
    this._useAnywhereApiConfiguration = !!data.use_anywhere_configuration;
    if (data.interaction && data.interaction.carouselSideBar) {
      this._carouselSideBar = (data.interaction.carouselSideBar as { object_property: string }[])
        .map<string>( object => object.object_property);
    } else {
      this._carouselSideBar = [];
    }

    this._carouselConfiguration = CollectionUtils
      .flattenArrayOfResponsiveConfiguration<CarouselConfiguration>(data.ui_conf, Carousel.carouselConfigurationMapping);
  }

  get useAnywhereApiConfiguration(): boolean {
    return this._useAnywhereApiConfiguration;
  }

  get carouselType(): string {
    return this._carouselType;
  }

  get carouselSideBar(): string[] {
    return this._carouselSideBar;
  }

  get carouselConfiguration(): ScreenConfiguration<CarouselConfiguration> {
    return this._carouselConfiguration;
  }

  private static carouselConfigurationMapping(data: any): CarouselConfiguration {
    const carouselConfiguration: CarouselConfiguration = {
      limitViewableItems: CollectionUtils.parseNumber(data.items),
      visibleItemsOnHover: CollectionUtils.parseNumber(data.itemsWhenHovered),
      rightMarginOnItems: CollectionUtils.parseNumber(data.margin),
      imitateInfinityLoop: data.loop,
      centerItems: data.center,
      enableMouseDrag: data.mouseDrag,
      enableMouseTouchDrag: data.touchDrag,
      startPosition: CollectionUtils.parseNumber(data.startPosition),
      showButtons: data.nav,
      iterationLimit: CollectionUtils.parseNumber(data.slideBy),
      showDots: data.dots,
      hoverSpeed: CollectionUtils.parseNumber(data.hoverSpeed),
      navigationSpeed: CollectionUtils.parseNumber(data.navSpeed),
      paginationSpeed: CollectionUtils.parseNumber(data.dotSpeed),
      dragEndSpeed: CollectionUtils.parseNumber(data.dragEndSpeed),
      refreshRate: CollectionUtils.parseNumber(data.responsiveRefreshRate),
      buttonText: {
        nextButton: data.navText.next,
        previousButton: data.navText.prev
      }
    };

    return carouselConfiguration;
  }
}
