import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { PaymentFieldsGroup, PaymentLoginButtonGroup, PaymentUserSignInPage, PaymentVideoPlayer,
  PaymentSubscribePackages } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-payment-fields-group',
  templateUrl: './payment-fields-group.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PaymentFieldsGroupComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-payment-fields-group';
  @Input() paymentFieldsGroup: PaymentFieldsGroup;
  @Input() videoPlayerConfiguration: { drmEntries: string[]; isDesktopDevice: boolean; };
  @Output() triggerChange: EventEmitter<string>;
  @Output() triggerDisabledByDrm: EventEmitter<boolean>;

  constructor() {
    this.triggerChange = new EventEmitter<string>();
    this.triggerDisabledByDrm = new EventEmitter<boolean>();
  }


  ngOnInit(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }
  }

  getCss(): string {
    return this.paymentFieldsGroup.cssClass;
  }

  getLoginButtonGroup(): PaymentLoginButtonGroup {
    return this.paymentFieldsGroup.loginButtonGroup;
  }

  getUserSignInPage(): PaymentUserSignInPage {
    return this.paymentFieldsGroup.userAuthorizationPage;
  }

  getVideoPlayer(): PaymentVideoPlayer {
    return this.paymentFieldsGroup.videoPlayer;
  }

  getSubscribePackages(): PaymentSubscribePackages {
    return this.paymentFieldsGroup.subscribePackages;
  }

  onChange(value: string): void {
    this.triggerChange.emit(value);
  }

  onDrmStateChange(value: boolean): void {
    this.triggerDisabledByDrm.emit(value);
  }
}
