import {
  AmgComponentStaticProperties,
} from 'amg';

import { CardType } from 'card';

export class CollapsibleStaticProperties implements AmgComponentStaticProperties {
  collapsibleTile: CardType;
  title?: string = '';
  subtitle?: string = '';

  cardStaticProperties?: AmgComponentStaticProperties;
}
