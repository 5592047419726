import * as tslib_1 from "tslib";
import { BaseObject } from '../BaseObject';
var RestApiConfiguration = /** @class */ (function (_super) {
    tslib_1.__extends(RestApiConfiguration, _super);
    function RestApiConfiguration(data) {
        var _this = _super.call(this, 'RestApiConfiguration', data) || this;
        _this._headerData = data.header_config || [];
        _this._baseUrl = data.base_url;
        _this._apiRequestMethod = data.request_method;
        return _this;
    }
    RestApiConfiguration.merge = function (parentConfig, childConfig) {
        return new RestApiConfiguration({
            base_url: (childConfig && childConfig.baseUrl) ? childConfig.baseUrl : parentConfig.baseUrl,
            request_method: (childConfig && childConfig.apiRequestMethod) ? childConfig.apiRequestMethod : parentConfig.apiRequestMethod,
            header_config: (childConfig && childConfig.headerData) ? childConfig.headerData : parentConfig.headerData
        });
    };
    Object.defineProperty(RestApiConfiguration.prototype, "baseUrl", {
        get: function () {
            return this._baseUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RestApiConfiguration.prototype, "apiRequestMethod", {
        get: function () {
            return this._apiRequestMethod;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RestApiConfiguration.prototype, "headerData", {
        get: function () {
            return this._headerData;
        },
        enumerable: true,
        configurable: true
    });
    return RestApiConfiguration;
}(BaseObject));
export { RestApiConfiguration };
