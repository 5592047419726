/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../projects/wordpress/wordpress-components/src/lib/web-components/layout/overlay/overlay.component.ngfactory";
import * as i2 from "../../../../../projects/wordpress/wordpress-components/src/lib/web-components/layout/overlay/overlay.component";
import * as i3 from "../../../../../projects/wordpress/wordpress-components/src/lib/shared/api/api.service";
import * as i4 from "@angular/common";
import * as i5 from "./overlay.component";
var styles_OverlayComponent = [];
var RenderType_OverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OverlayComponent, data: {} });
export { RenderType_OverlayComponent as RenderType_OverlayComponent };
function View_OverlayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-overlay", [], [[1, "class", 0]], null, null, i1.View_OverlayComponent_0, i1.RenderType_OverlayComponent)), i0.ɵdid(1, 49152, null, 0, i2.OverlayComponent, [i3.ApiService], { overlay: [0, "overlay"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.overlay; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
export function View_OverlayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverlayComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.overlay; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-overlay", [], null, null, null, View_OverlayComponent_0, RenderType_OverlayComponent)), i0.ɵdid(1, 49152, null, 0, i5.OverlayComponent, [], null, null)], null, null); }
var OverlayComponentNgFactory = i0.ɵccf("app-overlay", i5.OverlayComponent, View_OverlayComponent_Host_0, { overlay: "overlay" }, {}, []);
export { OverlayComponentNgFactory as OverlayComponentNgFactory };
