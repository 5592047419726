<div class="amg-carousel-calendar">
  <div class="amg-carousel-calendar-tabs">
    <amg-carousel
      [componentData]="tabComponentData"
      [responsiveProperties]="tabComponentResponsive"
      [staticProperties]="tabComponentStatic"
      (cardClicked)="onDateClicked('bubble', $event)"
      (cardHovered)="onDateHovered('bubble', $event)"
      (cardSelected)="onDateSelected('bubble', $event)"
    ></amg-carousel>
  </div>
  <div class="amg-carousel-calendar-fixtures">
    <amg-card-calendar-fixture
      *ngFor="let fixtureData of fixtureComponentData | relevantfixtures:  selectedDate; index as i;"
      [fixtureData]="fixtureData"
      (buttonClicked)="onFixtureClicked($event)"
    ></amg-card-calendar-fixture>
  </div>
  <div
    class="card amg-carousel-calendar-empty"
    [hidden]="fixtureComponentData.length > 0"
  >
    <div class="card-body">
      Sorry, no fixtures found
    </div>
  </div>
</div>
