/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./subscription-package.component";
var styles_SubscriptionPackageComponent = [];
var RenderType_SubscriptionPackageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubscriptionPackageComponent, data: {} });
export { RenderType_SubscriptionPackageComponent as RenderType_SubscriptionPackageComponent };
function View_SubscriptionPackageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "amg-title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.package.Title; _ck(_v, 0, 0, currVal_0); }); }
function View_SubscriptionPackageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "amg-body"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.package.Body; _ck(_v, 0, 0, currVal_0); }); }
function View_SubscriptionPackageComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "amg-amount"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formattedPrice; _ck(_v, 0, 0, currVal_0); }); }
function View_SubscriptionPackageComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "amg-interval-number"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.intervalData.number; _ck(_v, 0, 0, currVal_0); }); }
function View_SubscriptionPackageComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "amg-interval-text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.intervalData.text; _ck(_v, 0, 0, currVal_0); }); }
function View_SubscriptionPackageComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "amg-interval-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPackageComponent_5)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPackageComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.intervalData.number; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.intervalData.text; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SubscriptionPackageComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn amg-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["JOIN NOW"]))], null, null); }
export function View_SubscriptionPackageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "amg-icon-placeholder"]], [[1, "has-free-trail", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "amg-subscription-package-inner"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPackageComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPackageComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPackageComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPackageComponent_4)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPackageComponent_7)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.package.Title; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.package.Body; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.formattedPrice; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.intervalData; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.package.PurchaseUrl; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.package.HasFreeTrail; _ck(_v, 0, 0, currVal_0); }); }
export function View_SubscriptionPackageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-subscription-package", [], [[1, "class", 0]], null, null, View_SubscriptionPackageComponent_0, RenderType_SubscriptionPackageComponent)), i0.ɵdid(1, 114688, null, 0, i2.SubscriptionPackageComponent, [i1.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var SubscriptionPackageComponentNgFactory = i0.ɵccf("amg-subscription-package", i2.SubscriptionPackageComponent, View_SubscriptionPackageComponent_Host_0, { package: "package" }, {}, []);
export { SubscriptionPackageComponentNgFactory as SubscriptionPackageComponentNgFactory };
