import { CardCalendarFixtureComponent, CardEventComponent, CardFixtureComponent, } from 'card-fixture';
import { CardImageComponent } from 'card-image';
import { CardTextComponent } from 'card-text';
import { CardProfileComponent } from 'card-profile';
import { CardVideoComponent } from 'card-video';
var CardRegistry = /** @class */ (function () {
    function CardRegistry() {
    }
    CardRegistry.CardComponentRegistry = new Map([
        ['CardCalendarFixtureComponent', CardCalendarFixtureComponent],
        ['CardEventComponent', CardEventComponent],
        ['CardFixtureComponent', CardFixtureComponent],
        ['CardImageComponent', CardImageComponent],
        ['CardProfileComponent', CardProfileComponent],
        ['CardTextComponent', CardTextComponent],
        ['CardVideoComponent', CardVideoComponent],
    ]);
    return CardRegistry;
}());
export { CardRegistry };
