import * as tslib_1 from "tslib";
import { CardUtils } from '../../../../../util/CardUtils';
import { NgObject } from './NgObject';
var AnywhereVideoCard = /** @class */ (function (_super) {
    tslib_1.__extends(AnywhereVideoCard, _super);
    function AnywhereVideoCard(data, objectType) {
        if (objectType === void 0) { objectType = 'AnywhereVideoCard'; }
        var _this = _super.call(this, objectType, data) || this;
        _this._anywhereApiUrl = data.anywhere_api_url;
        _this._anywhereDisplayKeys = {
            displayKeys: CardUtils.generateCardDisplayKeys(data.cardDisplayKeys)
        };
        return _this;
    }
    Object.defineProperty(AnywhereVideoCard.prototype, "anywhereDisplayKeys", {
        get: function () {
            return this._anywhereDisplayKeys;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnywhereVideoCard.prototype, "anywhereApiUrl", {
        get: function () {
            return this._anywhereApiUrl;
        },
        enumerable: true,
        configurable: true
    });
    return AnywhereVideoCard;
}(NgObject));
export { AnywhereVideoCard };
