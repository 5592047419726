import { BaseObject } from '../../../BaseObject';

export class Link extends BaseObject {
  private readonly _label: string;
  private readonly _page: string;
  private readonly _externalLink: string;
  private readonly _path: string;

  constructor(data: any) {
    super('Link', data);

    this._label = data.label;
    this._page = data.page;
    this._externalLink = data.external_link;
    this._path = data.relative_path
      ? `/${data.relative_path}`
      : data.relative_path;
  }

  get label(): string {
    return this._label;
  }

  get externalLink(): string {
    return this._externalLink;
  }

  get path(): string {
    return this._path;
  }

  get page(): string {
    return this._page;
  }
}
