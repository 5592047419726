import { OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DataService } from './core/service/data/data.service';
import { WordpressService } from './core/service/wordpress/wordpress.service';
import { Location } from '@angular/common';
import { SsoService } from './core/service/sso-service/sso.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(titleService, wordpressService, router, dataService, window, 
    // private ssoService: SsoServiceOld,
    ssoService, location) {
        this.titleService = titleService;
        this.wordpressService = wordpressService;
        this.router = router;
        this.dataService = dataService;
        this.window = window;
        this.ssoService = ssoService;
        this.location = location;
        this.title = 'FFCtv';
        this.setIsOnVodPage(false);
        this.titleService.setTitle(this.title);
        this.isOnVodRoute = false;
    }
    AppComponent.getRouteFromUrl = function (url) {
        return url.split('?')[0];
    };
    AppComponent.prototype.setIsOnVodPage = function (value) {
        var stringValue = value ? 'true' : 'false';
        this.window.document.body.setAttribute('data-is-vod', stringValue);
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ssoService.init();
        this.setIsOnVod(AppComponent.getRouteFromUrl(this.router.url));
        this.routerSubscription = this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            var currentRoute = event.urlAfterRedirects.split('?')[0];
            _this.setIsOnVod(AppComponent.getRouteFromUrl(currentRoute));
        });
        this.otherContentSubscription = this.wordpressService.getOtherContent().subscribe(function (response) {
            _this.otherContent = response;
            if (response.footer) {
                _this.dataService.setFooterData(response.footer);
            }
        });
        // }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.otherContentSubscription.unsubscribe();
        this.routerSubscription.unsubscribe();
    };
    AppComponent.prototype.setIsOnVod = function (route) {
        this.isOnVodRoute = (route === '/vod');
        this.setIsOnVodPage(this.isOnVodRoute);
    };
    return AppComponent;
}());
export { AppComponent };
