/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./subscribe-btn.component";
import * as i3 from "../../user-auth.service";
var styles_SubscribeBtnComponent = [];
var RenderType_SubscribeBtnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubscribeBtnComponent, data: {} });
export { RenderType_SubscribeBtnComponent as RenderType_SubscribeBtnComponent };
function View_SubscribeBtnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn amg-register-btn"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn amg-register-btn"; var currVal_2 = _co.subscribeBtn.cssClass; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subscribeBtn.label; _ck(_v, 0, 0, currVal_0); }); }
export function View_SubscribeBtnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscribeBtnComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.subscribeBtn && _co.show); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SubscribeBtnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-subscribe-btn", [], null, null, null, View_SubscribeBtnComponent_0, RenderType_SubscribeBtnComponent)), i0.ɵdid(1, 114688, null, 0, i2.SubscribeBtnComponent, [i3.UserAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubscribeBtnComponentNgFactory = i0.ɵccf("amg-subscribe-btn", i2.SubscribeBtnComponent, View_SubscribeBtnComponent_Host_0, { subscribeBtn: "subscribeBtn" }, {}, []);
export { SubscribeBtnComponentNgFactory as SubscribeBtnComponentNgFactory };
