import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var PaymentLoginButtonGroup = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentLoginButtonGroup, _super);
    function PaymentLoginButtonGroup(data) {
        var _this = _super.call(this, 'PaymentLoginButtonGroup', data) || this;
        if (data.button_group && data.button_group.length > 0) {
            data.button_group.forEach(function (button) {
                switch (button.flexibleContentId) {
                    case 'register_button':
                        _this._registerButton = button;
                        break;
                    case 'log_in_button':
                        _this._loginButton = button;
                        break;
                    case 'subscribe_button':
                        _this._subscribeButton = button;
                        break;
                    case 'log_out_button':
                        _this._logOutButton = button;
                        break;
                }
            });
        }
        return _this;
    }
    Object.defineProperty(PaymentLoginButtonGroup.prototype, "logOutButton", {
        get: function () {
            return this._logOutButton;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentLoginButtonGroup.prototype, "registerButton", {
        get: function () {
            return this._registerButton;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentLoginButtonGroup.prototype, "loginButton", {
        get: function () {
            return this._loginButton;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentLoginButtonGroup.prototype, "subscribeButton", {
        get: function () {
            return this._subscribeButton;
        },
        enumerable: true,
        configurable: true
    });
    return PaymentLoginButtonGroup;
}(BaseObject));
export { PaymentLoginButtonGroup };
