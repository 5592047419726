import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Grid as WpGrid } from 'wordpress-adapter';
import { GridResponsiveProperties, GridSource } from 'grid';
import { CardType } from 'card';
import { DataService } from '../../../../../shared/data/data.service';
var GridComponent = /** @class */ (function () {
    function GridComponent(dataService, router, activatedRoute) {
        this.dataService = dataService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.class = 'amg-grid';
        this.triggerChange = new EventEmitter();
        this.searchSubject = new BehaviorSubject('');
    }
    GridComponent.getGridCardType = function (cardType) {
        switch (cardType) {
            case 'Video':
                return CardType.Video;
            case 'Image':
                return CardType.Image;
            case 'Fixture':
                return CardType.Fixture;
            default:
                return CardType.Text;
        }
    };
    GridComponent.prototype.ngOnDestroy = function () {
        this.searchSubject.complete();
    };
    GridComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.currentSearchValue = this.activatedRoute.snapshot.queryParams.search || '';
        this.searchSubject.next(this.currentSearchValue);
        this.gridData = [];
        this.gridResponsiveProperties = new GridResponsiveProperties(this.grid.gridConfiguration);
        this.gridStaticProperties = this.grid.gridStaticData;
        this.routerObservable = this.router.events.pipe(filter(function (event) { return event instanceof ActivationEnd; })).subscribe(function (event) {
            var search = event.snapshot.queryParams.search;
            if (search && (_this.currentSearchValue !== search)) {
                _this.currentSearchValue = search;
                _this.searchSubject.next(search);
            }
        });
    };
    GridComponent.prototype.onGridReRender = function () {
        this.triggerChange.emit('grid');
    };
    GridComponent.prototype.getSearchInputAsObservable = function () {
        return this.searchSubject.asObservable();
    };
    GridComponent.prototype.getCss = function () {
        return this.grid.cssClass;
    };
    GridComponent.prototype.onGridSearch = function (searchValue) {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                search: searchValue
            }
        });
    };
    GridComponent.prototype.onUpdateSearchUrl = function (update) {
        if ((update) && (update.url)) {
            this.gridUrl = update.url;
        }
        else {
            delete this.gridUrl;
        }
    };
    GridComponent.prototype.onCardClick = function (data) {
        if (this.grid.onClickNavigate) {
            var url = void 0, entryId = void 0;
            if (this.grid.gridStaticData.pageSource === GridSource.Wordpress) {
                url = data.playlist_url || data.raw.playlist_url;
                entryId = null;
            }
            else {
                url = this.gridUrl || this.grid.gridStaticData.pageUrl;
                entryId = (data && data.mediaData && data.mediaData.entryId)
                    ? data.mediaData.entryId
                    : null;
            }
            this.dataService.userInteractedObject = this.dataService.userInteractedObject = {
                playlistUrl: url,
                data: data
            };
            this.router.navigate([this.grid.onClickNavigate], {
                queryParams: tslib_1.__assign({ playlist: this.dataService.encodeUrl(url) }, (entryId ? { entry: entryId } : {})),
                preserveQueryParams: false
            });
        }
    };
    GridComponent.prototype.getCurrentSearchValue = function () {
        return this.currentSearchValue;
    };
    return GridComponent;
}());
export { GridComponent };
