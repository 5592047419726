import { BaseObject } from '../../../BaseObject';
import { ActionLink } from '../html-elements/ActionLink';
import { Button } from '../html-elements/Button';
import { Icon } from '../html-elements/Icon';
import { InputField } from '../html-elements/InputField';

export class PaymentUserSignInPage extends BaseObject {
  private readonly _logo: Icon;
  private readonly _loginFormGroup: { emailAddress: InputField; password: InputField; loginButton: Button; };
  private readonly _forgotPasswordLink: ActionLink;
  private readonly _textLinks: { title: string; textLinkButton: ActionLink; }[];

  constructor(data: any) {
    super('PaymentUserSignInPage', data);

    this._logo = data.logo;
    this._loginFormGroup = {
      emailAddress: data.login_form_group.emailAddress,
      password: data.login_form_group.password,
      loginButton: data.login_form_group.loginButton
    };
    this._forgotPasswordLink = data.forgot_password;

    if (data.other_links) {
      this._textLinks = (data.other_links as { title: string; textLinkButton: ActionLink; objectType: string; }[]).map(value => {
        return {
          title: value.title,
          textLinkButton: value.textLinkButton
        };
      });
    } else {
      this._textLinks = [];
    }
  }

  get logo(): Icon {
    return this._logo;
  }

  get loginFormGroup(): { emailAddress: InputField; password: InputField; loginButton: Button; } {
    return this._loginFormGroup;
  }

  get forgotPasswordLink(): ActionLink {
    return this._forgotPasswordLink;
  }

  get textLinks(): { title: string; textLinkButton: ActionLink; }[] {
    return this._textLinks;
  }
}
