import {
  Component,
  OnInit,
  Input,
  QueryList,
  ViewContainerRef,
  OnDestroy
} from '@angular/core';

import {Subscription, Observable} from 'rxjs';

import {
  AmgComponent,
  BootstrapViewportEnum,
  BootstrapViewportService,
} from 'amg';

import {CardTextStaticProperties} from './card.text.static.properties';
import {CardTextResponsiveProperties} from './card.text.responsive.properties';
import {CardTextResponsivePropertiesView} from './card.text.responsive.properties.view';

@Component({
  selector: 'amg-card-text',
  templateUrl: 'card-text.component.html',
  styles: []
})
export class CardTextComponent implements OnInit, OnDestroy, AmgComponent {
  @Input() componentData: any;
  @Input() responsiveProperties: CardTextResponsiveProperties;
  @Input() staticProperties: CardTextStaticProperties;

  @Input() componentChildren: QueryList<ViewContainerRef>;

  componentViewportSubscription: Subscription;

  componentCurrentView: CardTextResponsivePropertiesView = {} as CardTextResponsivePropertiesView;

  responsiveTimeout: any;

  constructor(
    private bootstrapViewportService: BootstrapViewportService
  ) {
  }

  ngOnInit() {
    if (this.staticProperties === undefined) {
      this.staticProperties = new CardTextStaticProperties();
    }
    const viewportSubscriber: Observable<BootstrapViewportEnum> = this.bootstrapViewportService.getViewportSubscriber();
    const viewport: BootstrapViewportEnum = this.bootstrapViewportService.getViewport();
    this.componentCurrentView = this.responsiveProperties[viewport] as CardTextResponsivePropertiesView;
    this.componentViewportSubscription = viewportSubscriber.subscribe(newBreakpoint => {

      if (this.responsiveTimeout) {
        clearTimeout(this.responsiveTimeout);
        this.responsiveTimeout = null;
      }

      this.responsiveTimeout = setTimeout(() => {
        this.componentCurrentView = this.responsiveProperties[newBreakpoint] as CardTextResponsivePropertiesView;
      }, this.componentCurrentView.refreshRate);
    });
  }

  ngOnDestroy() {
    this.componentViewportSubscription.unsubscribe();
  }

  getText(key: string): string {
    if (this.componentData) {
      if (this.componentData[key]) {
        return this.componentData[key];
      }
    }
    return '';
  }

}
