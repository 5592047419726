import * as tslib_1 from "tslib";
import { BaseObject } from '../BaseObject';
var Overlay = /** @class */ (function (_super) {
    tslib_1.__extends(Overlay, _super);
    function Overlay(data) {
        var _this = _super.call(this, 'Overlay', data) || this;
        _this._overlayFields = data.overlay_fields || [];
        return _this;
    }
    Object.defineProperty(Overlay.prototype, "overlayFields", {
        get: function () {
            return this._overlayFields;
        },
        enumerable: true,
        configurable: true
    });
    return Overlay;
}(BaseObject));
export { Overlay };
