import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private _userInteractedObject: { fixtureUrl?: string; playlistUrl?: string; data: any; };
  private mediaEntrySubject: BehaviorSubject<any>;

  constructor() {
    this.mediaEntrySubject = new BehaviorSubject(null);
  }

  setEntryObject(mediaEntry: any): void {
    this.mediaEntrySubject.next(mediaEntry);
  }

  getMediaEntryAsObservable(): Observable<any> {
    return this.mediaEntrySubject.asObservable();
  }

  public encodeUrl(url: string): string {
    return btoa(url);
  }

  public decodeUrl(url: string): string {
    return atob(url);
  }

  get userInteractedObject(): { fixtureUrl?: string; playlistUrl?: string; data: any; } {
    return this.clearAndGetCurrentObject();
  }

  set userInteractedObject(value: { fixtureUrl?: string; playlistUrl?: string; data: any; }) {
    this._userInteractedObject = value;
  }

  private clearAndGetCurrentObject(): { fixtureUrl?: string; playlistUrl?: string; data: any; } {
    const interactedObject = this._userInteractedObject;

    this._userInteractedObject = null;

    return interactedObject;
  }
}
