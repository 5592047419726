<div
  class="amg-carousel-outer"
  [ngClass]="(getVisibleSlideCount() < 2) ? 'vertical' : 'horizontal'"
  >
  <div class="col amg-side-details" [ngStyle]="getDetails()" *ngIf="sideBarIsActive() && componentData.length > 0">
    <div>
      <div [ngClass]="property" *ngFor="let property of staticProperties.carouselSidebar">
        <span [ngClass]="property">{{ getCurrentSlideProperty(property) }}</span>
      </div>
    </div>
  </div>
  <div class="amg-carousel" *ngIf="componentData.length > 0">
    <div
      #widgetsContent
      class="amg-carousel-container"

      [attr.data-slide-current]="getSlideNumber()"
      [attr.data-slide-hovered]="getSlideHover() - getSlideNumber()"
      [attr.data-slide-visible]="getVisibleSlideCount()"
      [attr.data-slide-visible-hovered]="getVisibleSlideHoveredCount()"
      [attr.data-slide-selected]="getCurrentSelectedSlide()"
    >
      <div
        class="col"
        *ngFor="let item of componentData; index as i;"
        [ngStyle]="getCol(i)"
        [ngClass]="{
          active: slideIsActive(i),
          selected: isSlideSelected(i),
          'first-slide': i === 0,
          'last-slide': i === componentData.length - 1
        }"
        (click)="slideClick('click', item, i)"
      >
        <div
          (mouseover)="slideHover('hover', i)"
          (mouseleave)="slideHoverOut('hover', i)"
        >
          <ng-template #componentChild></ng-template>
        </div>
      </div>
      <div
        class="col"
        *ngFor="let i of getExtraColumns()"
        [ngStyle]="getDetails()"
      ></div>
    </div>
    <div class="amg-carousel-dots-container" *ngIf="getCurrentView().showDots">
      <ul class="amg-carousel-dots">
        <li *ngFor="let item of componentData; index as i;" [ngClass]="{ active: dotIsActive(i) }">
          <button (click)="slideTo('dot', i)">•</button>
        </li>
      </ul>
    </div>
    <div class="amg-carousel-nav-container" *ngIf="getCurrentView().showButtons">
      <div class="amg-carousel-nav">
        <button class="prev" (click)="onClickPrevious('nav')" [innerHtml]="getCurrentView().navText[1]" [hidden]="!showPrev()"></button>
        <button class="next" (click)="onClickNext('nav')" [innerHtml]="getCurrentView().navText[0]" [hidden]="!showNext()"></button>
      </div>
    </div>
  </div>
</div>
