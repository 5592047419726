var DateAndTimeComponent = /** @class */ (function () {
    function DateAndTimeComponent() {
    }
    Object.defineProperty(DateAndTimeComponent.prototype, "cardData", {
        set: function (cardData) {
            if (cardData) {
                this.setDate(cardData);
            }
        },
        enumerable: true,
        configurable: true
    });
    DateAndTimeComponent.prototype.setDate = function (cardData) {
        var fixtureDate = cardData.startDate;
        var vodData = (cardData.publicationData)
            ? cardData.publicationData.releaseFrom
            : null;
        this.date = (fixtureDate)
            ? fixtureDate
            : vodData
                ? vodData
                : null;
    };
    return DateAndTimeComponent;
}());
export { DateAndTimeComponent };
