import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { NgObject } from './NgObject';

export class Fixtures extends NgObject {
  private readonly _fixtureStaticProperties;
  private readonly _fixtureConfiguration;
  private readonly _fixtureCardType: ('calendar'|'fixture');
  private readonly _isPaginationEnabled: boolean;
  private readonly _limitNumberOfItems: number;

  constructor(data: any) {
    super('Fixtures', data);

    this._isPaginationEnabled = !!data.enable_pagination;

    const limitedNumberOfItemsDefaultValue = this.isPaginationEnabled ? 5 : 0;
    // If pagination is enabled, default to 5 items not 0.
    this._limitNumberOfItems =  data.number_of_items
      ? Number.parseInt(data.number_of_items, 10)
      : limitedNumberOfItemsDefaultValue;

    this._fixtureCardType = data.card_type;
    this._fixtureStaticProperties = data.static_data;
    this._fixtureConfiguration = CollectionUtils
      .flattenArrayOfResponsiveConfiguration<any>(data.ui_conf, Fixtures.fixturesConfigurationMapping);
  }

  get isPaginationEnabled(): boolean {
    return this._isPaginationEnabled;
  }

  get fixtureStaticProperties() {
    return this._fixtureStaticProperties;
  }

  get fixtureConfiguration() {
    return this._fixtureConfiguration;
  }

  get limitNumberOfItems(): number {
    return this._limitNumberOfItems;
  }

  get fixtureCardType(): ('calendar' | 'fixture') {
    return this._fixtureCardType;
  }

  private static fixturesConfigurationMapping(data: any): any {
    const fixturesConfiguration: any = {
      fixtureTeamText: data.fixtureTeamsText,
      responsiveRefreshRate: CollectionUtils.parseNumber(data.responsiveRefreshRate)
    };

    return fixturesConfiguration;
  }
}
