<div id="root-container">
  <ng-container *ngIf="otherContent && otherContent.header">
    <app-header *ngIf="!isOnVodRoute"
                [header]="otherContent.header"></app-header>
  </ng-container>

  <ng-container *ngIf="otherContent">
    <router-outlet [class.page-container]></router-outlet>
  </ng-container>

  <ng-container *ngIf="otherContent && otherContent.overlay">
    <app-overlay [overlay]="otherContent.overlay"></app-overlay>
  </ng-container>
</div>
