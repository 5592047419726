import { NavigationUtils } from '../../../../../util/NavigationUtils';
import { BaseObject } from '../../../BaseObject';

export class ActionLink extends BaseObject {
  private readonly _label: string;
  private readonly _externalLink: string;
  private readonly _path: string;
  private readonly _fragment: string;
  private readonly _type: string;

  constructor(data: any) {
    super('ActionLink', data);

    this._label = data.label;
    this._externalLink = data.external_link;
    if (data.relative_path) {
      const pathData = NavigationUtils.getUrlFragments(data.relative_path);

      this._path = (pathData.url).charAt(0) === '/'
        ? pathData.url
        : `/${pathData.url}`;
      this._fragment = pathData.fragment || null;
    } else {
      this._path = data.relative_path;
      this._fragment = null;
    }

    this._type = Array.isArray(data.type)
      ? data.type[0]
      : data.type;
  }

  get fragment(): string {
    return this._fragment;
  }

  get label(): string {
    return this._label;
  }

  get externalLink(): string {
    return this._externalLink;
  }

  get path(): string {
    return this._path;
  }

  get type(): string {
    return this._type;
  }
}
