import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { CountdownConfiguration } from '../../../types/ng-objects/CountdownConfiguration';
import { ScreenConfiguration } from '../../../types/ng-objects/ScreenConfiguration';
import { NgObject } from './NgObject';

export class Countdown extends NgObject {
  private readonly _endTime: Date;
  private readonly _countdownConfiguration: ScreenConfiguration<CountdownConfiguration>;

  constructor(data: any) {
    super('Countdown', data);

    this._endTime = Countdown.parseInvariantCultureDateTime(data.end);
    this._countdownConfiguration = CollectionUtils
      .flattenArrayOfResponsiveConfiguration<CountdownConfiguration>(data.ui_conf, Countdown.countdownConfigurationMapping);
  }

  get countdownConfiguration(): ScreenConfiguration<CountdownConfiguration> {
    return this._countdownConfiguration;
  }

  get endTime(): Date {
    return this._endTime;
  }

  private static parseInvariantCultureDateTime(date: string): Date {
    // Date Format: DD-MM-YYYY HH:MM XX
    // XX is equivalent to 'am' or 'pm'.
    if (date) {
      const dateTimeParts = date.split(' '),
        dateParts = dateTimeParts[0].split('/'),
        time = dateTimeParts[1].split(':'),
        timeIdentifier = dateTimeParts[2];

      const hours = timeIdentifier === 'pm'
        ? Number.parseInt(time[0], 10) + 12
        : Number.parseInt(time[0], 10);
      const minutes = Number.parseInt(time[1], 10),
        days = Number.parseInt(dateParts[0], 10),
        months = Number.parseInt(dateParts[1], 10),
        years = Number.parseInt(dateParts[2], 10);

      return new Date(years, months, days, hours, minutes);
    }

    return new Date();
  }

  private static countdownConfigurationMapping(data: any): CountdownConfiguration {
    const countdownConfiguration: CountdownConfiguration = {
      captions: {
        daysText: data.days,
        hoursText: data.hours,
        minutesText: data.minutes,
        secondsText: data.seconds,
        buttonText: data.ctaText,
        titleText: data.title,
        units: data.units
      },
      circleRadius: CollectionUtils.parseNumber(data.radiusOfCircle),
      isClockwiseRotation: data.clockwise,
      startFromZero: data.startFromZero,
      isCircleResponsive: data.responsive,
      showUnits: data.showUnits,
      showImage: data.showImage,
      refreshRate: CollectionUtils.parseNumber(data.responsiveRefreshRate),
      spaceBetweenCircles: CollectionUtils.parseNumber(data.space),
      circleStrokeWidth: CollectionUtils.parseNumber(data.outerStrokeWidth),
      outerStrokeGradient: data.outerStrokeGradient,
      outerStrokeColor: data.outerStrokeColor,
      outerStrokeGradientStopColor: data.outerStrokeGradientStopColor,
      innerStrokeColor: data.innerStrokeColor,
      innerStrokeWidth: CollectionUtils.parseNumber(data.innerStrokeWidth),
      animateTitle: data.animateTitle,
      animationDuration: CollectionUtils.parseNumber(data.animationDuration),
      percent: CollectionUtils.parseNumber(data.percent),
      maxPercent: CollectionUtils.parseNumber(data.maxPercent),
      showZeroOuterStroke: data.showZeroOuterStroke,
      showTitle: data.showTitle,
      showSubtitle: data.showSubtitle,
      showBackground: data.showBackground,
      showInnerStroke: data.showInnerStroke,
      backgroundStroke: data.backgroundStroke,
      backgroundStrokeWidth: CollectionUtils.parseNumber(data.backgroundStrokeWidth),
      backgroundPadding: CollectionUtils.parseNumber(data.backgroundPadding),
      isBackgroundGradient: data.backgroundGradient,
      backgroundColor: data.backgroundColor,
      backgroundGradientStopColor: data.backgroundGradientStopColor,
      backgroundOpacity: CollectionUtils.parseNumber(data.backgroundOpacity),
      toFixed: CollectionUtils.parseNumber(data.toFixed),
      renderOnClick: data.renderOnClick,
      unitsFontSize: CollectionUtils.parseNumber(data.unitsFontSize)
    };

    return countdownConfiguration;
  }
}
