/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../login-overlay/login-overlay.component.ngfactory";
import * as i2 from "../login-overlay/login-overlay.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../video-player.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../user-auth/src/lib/user-auth.service";
import * as i7 from "@angular/common";
import * as i8 from "./video-thumbnail.component";
var styles_VideoThumbnailComponent = [];
var RenderType_VideoThumbnailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VideoThumbnailComponent, data: {} });
export { RenderType_VideoThumbnailComponent as RenderType_VideoThumbnailComponent };
function View_VideoThumbnailComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "Video thumbnail"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getThumbnailImg(); _ck(_v, 0, 0, currVal_0); }); }
function View_VideoThumbnailComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-login-overlay", [], null, null, null, i1.View_LoginOverlayComponent_0, i1.RenderType_LoginOverlayComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoginOverlayComponent, [i3.NgbModal, i4.VideoPlayerService, i5.Router, i6.UserAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_VideoThumbnailComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoThumbnailComponent_2)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoThumbnailComponent_3)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isThumbnailAvailable(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showLoginOverlay(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_VideoThumbnailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoThumbnailComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showThumbnail; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_VideoThumbnailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-video-thumbnail", [], null, null, null, View_VideoThumbnailComponent_0, RenderType_VideoThumbnailComponent)), i0.ɵdid(1, 8634368, null, 0, i8.VideoThumbnailComponent, [i4.VideoPlayerService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoThumbnailComponentNgFactory = i0.ɵccf("amg-video-thumbnail", i8.VideoThumbnailComponent, View_VideoThumbnailComponent_Host_0, { currentMedia: "currentMedia", showThumbnail: "showThumbnail" }, {}, []);
export { VideoThumbnailComponentNgFactory as VideoThumbnailComponentNgFactory };
