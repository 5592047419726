import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { Overlay } from 'wordpress-adapter';
import { ApiService } from '../../../shared/api/api.service';

@Component({
  selector: 'amg-wp-overlay',
  templateUrl: './overlay.component.html',
  encapsulation: ViewEncapsulation.None
})
export class OverlayComponent {
  @HostBinding('attr.class') class = 'amg-overlay';
  @Input() overlay: Overlay;

  constructor(private api: ApiService) {
  }

  getElements(): any[] {
    return this.overlay.overlayFields;
  }
}
