<div class="amg-card-calendar-fixture"
     [ngClass]="{ 'amg-fixture-live': getIsFixtureLive() }">
  <div class="amg-card-calendar-fixture-info">
    <div class="amg-card-calendar-fixture-sponsor">
      <img *ngIf="isThumbnailAvailable();"
           class="amg-card-calendar-fixture-sponsor-img"
           [src]="getThumbnail()"/>
    </div>
    <amg-date class="amg-date"
              [fixtureDate]="getFixtureDate()"></amg-date>
    <div class="amg-card-calendar-fixture-stadium"
         [innerHTML]="getStadiumName()"></div>
    <span class="placeholder-1"></span>
  </div>
  <div class="amg-card-calendar-fixture-teams">
    <div class="amg-card-calendar-fixture-team amg-card-calendar-fixture-team-home">
      <img class="amg-card-calendar-fixture-team-icon amg-card-calendar-fixture-team-icon-home"
           [src]="getHomeTeamData().logo"
           [alt]="getHomeTeamData().name"/>
      <div class="amg-card-calendar-fixture-team-name amg-card-calendar-fixture-team-name-home"
           [innerHTML]="getHomeTeamData().name"></div>
    </div>
    <div class="amg-card-calendar-fixture-team amg-card-calendar-fixture-team-away">
      <img class="amg-card-calendar-fixture-team-icon amg-card-calendar-fixture-team-icon-away"
           [src]="getAwayTeamData().logo"
           [alt]="getAwayTeamData().name"/>
      <div class="amg-card-calendar-fixture-team-name amg-card-calendar-fixture-team-name-away"
           [innerHTML]="getAwayTeamData().name"></div>
    </div>
    <amg-date class="amg-date"
              [fixtureDate]="getFixtureDate()"></amg-date>
  </div>
  <div class="amg-card-calendar-fixture-meta">
    <amg-live-buttons [fixtureMediaData]="getMediaData()"
                      (triggerClick)="onMediaDataButtonClick($event)">
    </amg-live-buttons>
  </div>
</div>
