/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../components/user-auth/src/lib/subscription-packages/subscription-packages.component.ngfactory";
import * as i2 from "../../../../../../../../../components/user-auth/src/lib/subscription-packages/subscription-packages.component";
import * as i3 from "../../../../../../../../../components/user-auth/src/lib/user-auth.service";
import * as i4 from "./subscribe-packages.component";
var styles_SubscribePackagesComponent = [];
var RenderType_SubscribePackagesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubscribePackagesComponent, data: {} });
export { RenderType_SubscribePackagesComponent as RenderType_SubscribePackagesComponent };
export function View_SubscribePackagesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-subscription-packages", [], [[1, "class", 0]], null, null, i1.View_SubscriptionPackagesComponent_0, i1.RenderType_SubscriptionPackagesComponent)), i0.ɵdid(1, 114688, null, 0, i2.SubscriptionPackagesComponent, [i3.UserAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
export function View_SubscribePackagesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-subscribe-packages", [], [[1, "class", 0]], null, null, View_SubscribePackagesComponent_0, RenderType_SubscribePackagesComponent)), i0.ɵdid(1, 114688, null, 0, i4.SubscribePackagesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var SubscribePackagesComponentNgFactory = i0.ɵccf("amg-wp-subscribe-packages", i4.SubscribePackagesComponent, View_SubscribePackagesComponent_Host_0, { subscribePackages: "subscribePackages" }, {}, []);
export { SubscribePackagesComponentNgFactory as SubscribePackagesComponentNgFactory };
