/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../date/date.component.ngfactory";
import * as i2 from "../date/date.component";
import * as i3 from "./card-live-details.component";
import * as i4 from "@angular/common/http";
var styles_CardLiveDetailsComponent = [];
var RenderType_CardLiveDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CardLiveDetailsComponent, data: {} });
export { RenderType_CardLiveDetailsComponent as RenderType_CardLiveDetailsComponent };
export function View_CardLiveDetailsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-date", [["class", "amg-date"]], null, null, null, i1.View_DateComponent_0, i1.RenderType_DateComponent)), i0.ɵdid(1, 114688, null, 0, i2.DateComponent, [], { fixtureDate: [0, "fixtureDate"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "stadium"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "description"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFixtureDate(); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getStadiumName(); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.getName(); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.getDescription(); _ck(_v, 4, 0, currVal_3); }); }
export function View_CardLiveDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-card-live-details", [], [[1, "is-live", 0]], null, null, View_CardLiveDetailsComponent_0, RenderType_CardLiveDetailsComponent)), i0.ɵdid(1, 245760, null, 0, i3.CardLiveDetailsComponent, [i4.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).isLive; _ck(_v, 0, 0, currVal_0); }); }
var CardLiveDetailsComponentNgFactory = i0.ɵccf("amg-card-live-details", i3.CardLiveDetailsComponent, View_CardLiveDetailsComponent_Host_0, { fixtureData: "fixtureData" }, {}, []);
export { CardLiveDetailsComponentNgFactory as CardLiveDetailsComponentNgFactory };
