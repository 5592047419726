import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActionLink } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-action-link',
  templateUrl: './action-link.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ActionLinkComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-action-link';
  @Input() actionLink: ActionLink;

  constructor(@Inject(DOCUMENT) private document: any) {
  }

  ngOnInit(): void {
    this.setUpActionLinkCssClasses();
  }

  triggerExternalRedirect() {
    if (this.getExternalLink()) {
      this.document.location.href = this.getExternalLink();
    }
  }

  getFragment(): string {
    return this.actionLink.fragment;
  }

  getPath(): string {
    return this.actionLink.path;
  }

  getExternalLink(): string {
    return this.actionLink.externalLink;
  }

  getLabel(): string {
    return this.actionLink.label;
  }

  isInternalLink(): boolean {
    return !!this.getPath();
  }

  isTypeButton(): boolean {
    return this.actionLink.type === 'button';
  }

  isTypeLink(): boolean {
    return this.actionLink.type === 'link';
  }

  getCss(): string {
    return this.actionLink.cssClass;
  }

  private setUpActionLinkCssClasses(): void {
    this.class = this.isTypeButton()
      ? this.class.concat(' amg-action-type-button')
      : this.class.concat(' amg-action-type-link');

    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }
  }
}
