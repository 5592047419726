import { InjectionToken } from '@angular/core';

export const CLIENT_OPTIONS: InjectionToken<ClientOptions> = new InjectionToken('The client options.');

/**
 * Module configuration passed on init.
 */
export interface ClientOptions {
  wordpressApiUrl: string;
}
