import * as tslib_1 from "tslib";
import { BaseObject } from '../BaseObject';
var Sections = /** @class */ (function (_super) {
    tslib_1.__extends(Sections, _super);
    function Sections(data, objectType) {
        var _this = _super.call(this, objectType || 'Sections', data) || this;
        _this._sections = data.sections;
        return _this;
    }
    Object.defineProperty(Sections.prototype, "sections", {
        get: function () {
            return this._sections;
        },
        enumerable: true,
        configurable: true
    });
    return Sections;
}(BaseObject));
export { Sections };
