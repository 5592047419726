/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../section.component.ngfactory";
import * as i2 from "../section.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../shared/data/data.service";
import * as i5 from "../../../../../../../components/user-auth/src/lib/user-auth.service";
import * as i6 from "amg-fe-angular/library/services/user-auth/user-auth-sso.service";
import * as i7 from "@angular/common";
import * as i8 from "./sections.component";
var styles_SectionsComponent = [];
var RenderType_SectionsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SectionsComponent, data: {} });
export { RenderType_SectionsComponent as RenderType_SectionsComponent };
function View_SectionsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-section", [], [[1, "class", 0], [1, "is-logged-in", 0], [1, "is-available", 0], [1, "is-disabled", 0], [1, "is-loading", 0], [4, "background-image", null]], [[null, "triggerDisabledByDrm"], [null, "triggerChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("triggerDisabledByDrm" === en)) {
        var pd_0 = (_co.onDrmStateChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("triggerChange" === en)) {
        var pd_1 = (_co.onChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SectionComponent_0, i1.RenderType_SectionComponent)), i0.ɵdid(1, 245760, null, 0, i2.SectionComponent, [i3.DomSanitizer, i4.DataService, [2, i5.UserAuthService], [2, i6.UserAuthSsoService], i0.NgZone, i0.ChangeDetectorRef], { section: [0, "section"], videoPlayerConfiguration: [1, "videoPlayerConfiguration"], isDrmDisabled: [2, "isDrmDisabled"] }, { triggerChange: "triggerChange", triggerDisabledByDrm: "triggerDisabledByDrm" })], function (_ck, _v) { var _co = _v.component; var currVal_6 = _v.context.$implicit; var currVal_7 = _co.videoPlayerConfiguration; var currVal_8 = _co.isDrmDisabled; _ck(_v, 1, 0, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; var currVal_1 = i0.ɵnov(_v, 1).isLoggedIn; var currVal_2 = i0.ɵnov(_v, 1).isSubscriptionAvailable; var currVal_3 = i0.ɵnov(_v, 1).isDisabledForMobile; var currVal_4 = i0.ɵnov(_v, 1).isLoading; var currVal_5 = i0.ɵnov(_v, 1).backgroundImage; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_SectionsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SectionsComponent_1)), i0.ɵdid(1, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSections(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SectionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-sections", [], [[1, "class", 0]], null, null, View_SectionsComponent_0, RenderType_SectionsComponent)), i0.ɵdid(1, 114688, null, 0, i8.SectionsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var SectionsComponentNgFactory = i0.ɵccf("amg-wp-sections", i8.SectionsComponent, View_SectionsComponent_Host_0, { sections: "sections", isDrmDisabled: "isDrmDisabled", videoPlayerConfiguration: "videoPlayerConfiguration" }, { triggerChange: "triggerChange", triggerDisabledByDrm: "triggerDisabledByDrm" }, []);
export { SectionsComponentNgFactory as SectionsComponentNgFactory };
