import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var Link = /** @class */ (function (_super) {
    tslib_1.__extends(Link, _super);
    function Link(data) {
        var _this = _super.call(this, 'Link', data) || this;
        _this._label = data.label;
        _this._page = data.page;
        _this._externalLink = data.external_link;
        _this._path = data.relative_path
            ? "/" + data.relative_path
            : data.relative_path;
        return _this;
    }
    Object.defineProperty(Link.prototype, "label", {
        get: function () {
            return this._label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Link.prototype, "externalLink", {
        get: function () {
            return this._externalLink;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Link.prototype, "path", {
        get: function () {
            return this._path;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Link.prototype, "page", {
        get: function () {
            return this._page;
        },
        enumerable: true,
        configurable: true
    });
    return Link;
}(BaseObject));
export { Link };
