import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CardFixtureModule } from 'card-fixture';
import { CardImageModule } from 'card-image';
import { CardTextModule } from 'card-text';
import { CardVideoModule } from 'card-video';

import { CollapsibleComponent } from './collapsible.component';

@NgModule({
  declarations: [
    CollapsibleComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CardFixtureModule,
    CardImageModule,
    CardTextModule,
    CardVideoModule,
  ],
  exports: [
    CollapsibleComponent,
  ],
  entryComponents: [
  ],
  providers: [],
})
export class CollapsableModule { }
