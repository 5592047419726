import { EventEmitter, OnInit } from '@angular/core';
var LiveButtonsComponent = /** @class */ (function () {
    function LiveButtonsComponent() {
        this.mandatoryButtons = {
            video: null,
            audio: null
        };
        this.triggerClick = new EventEmitter();
    }
    LiveButtonsComponent.prototype.ngOnInit = function () {
        // At the moment only 2 buttons will be supported. But if you would like to generate more,
        //  Then iterate through the array.
        this.mandatoryButtons.video = this.fixtureMediaData.video.length > 0
            ? this.fixtureMediaData.video[0]
            : null;
        this.mandatoryButtons.audio = this.fixtureMediaData.audio.length > 0
            ? this.fixtureMediaData.audio[0]
            : null;
    };
    LiveButtonsComponent.prototype.onButtonClick = function (type, entry) {
        this.triggerClick.emit({ type: type, entry: entry });
    };
    LiveButtonsComponent.prototype.isButtonDisabled = function (buttonData) {
        return !(buttonData && buttonData.isEntryCurrentlyLive);
    };
    return LiveButtonsComponent;
}());
export { LiveButtonsComponent };
