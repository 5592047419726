import { OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavigationBar } from 'wordpress-adapter';
var NavigationBarComponent = /** @class */ (function () {
    function NavigationBarComponent(router) {
        this.router = router;
        this.class = 'amg-navigation-bar';
        this.isMobileNavigationBarToggled = false;
    }
    NavigationBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                if (_this.isMobileNavigationBarToggled) {
                    _this.isMobileNavigationBarToggled = false;
                }
            }
        });
    };
    NavigationBarComponent.prototype.getNavigationLinks = function () {
        return this.navigationBar.navigationLinks;
    };
    NavigationBarComponent.prototype.getCss = function () {
        return this.navigationBar.cssClass;
    };
    NavigationBarComponent.prototype.getExtraElements = function () {
        return this.navigationBar.extras;
    };
    NavigationBarComponent.prototype.getNavBarId = function () {
        return (this.navigationBar.id)
            ? this.navigationBar.id
            : null;
    };
    NavigationBarComponent.prototype.getNavBarIdWithHash = function () {
        return "#" + this.getNavBarId();
    };
    NavigationBarComponent.prototype.isCollapsible = function () {
        return this.navigationBar.isCollapsible;
    };
    NavigationBarComponent.prototype.areExtraSectionsAvailable = function () {
        return !!this.navigationBar.extras
            && this.navigationBar.extras.length > 0;
    };
    NavigationBarComponent.prototype.isTypeArray = function (data) {
        return Array.isArray(data);
    };
    NavigationBarComponent.prototype.onToggleClick = function () {
        this.isMobileNavigationBarToggled = !this.isMobileNavigationBarToggled;
    };
    NavigationBarComponent.prototype.getToggleState = function () {
        return this.isMobileNavigationBarToggled;
    };
    return NavigationBarComponent;
}());
export { NavigationBarComponent };
