import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { NgObject } from './NgObject';

export class Fixture extends NgObject {
  private readonly _fixtureStaticProperties;
  private readonly _fixtureConfiguration;
  private readonly _fixtureCardType: ('calendar'|'fixture');

  constructor(data: any) {
    super('Fixture', data);

    this._fixtureStaticProperties = data.static_properties;
    this._fixtureCardType = data.card_type;

    this._fixtureStaticProperties = CollectionUtils
      .flattenArrayOfResponsiveConfiguration<any>(data.ui_conf, Fixture.fixtureConfigurationMapping);

  }

  get fixtureCardType(): ('calendar' | 'fixture') {
    return this._fixtureCardType;
  }

  get fixtureStaticProperties() {
    return this._fixtureStaticProperties;
  }

  get fixtureConfiguration() {
    return this._fixtureConfiguration;
  }

  private static fixtureConfigurationMapping(data: any): any {
    const fixtureConfiguration: any = {
      fixtureTeamText: data.fixtureTeamsText,
      refreshRate: CollectionUtils.parseNumber(data.responsiveRefreshRate)
    };

    return fixtureConfiguration;
  }
}
