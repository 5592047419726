import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WordpressAdapterModule } from 'wordpress-adapter';
import { WordpressComponentsModule } from 'wordpress-components';

import { environment } from '../../../environments/environment';
import { FooterComponent } from './footer/footer.component';
import {CookieService} from 'ngx-cookie-service';

@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    WordpressAdapterModule.forRoot({
      wordpressApiUrl: environment.wordPressApi
    }),
    WordpressComponentsModule.forRoot({
      streamPayments: environment.paymentScriptData,
      isSso: true
    })
  ],
  exports: [
    WordpressAdapterModule,
    WordpressComponentsModule,
    FooterComponent
  ],
  providers: [
    { provide: 'Window', useValue: window },
    CookieService
  ]
})
export class SharedModule { }
