import * as tslib_1 from "tslib";
import { ApiException } from '../ApiException';
import { getObjectDictionary } from '../ObjectDictionary';
import { excludedKeys } from '../ObjectKeyExclude';
import { ClientResponse } from './ClientResponse';
var ClientRequest = /** @class */ (function () {
    function ClientRequest(data, endpoint) {
        this._requestData = data;
        this._endpoint = endpoint;
        this.componentMappings = getObjectDictionary();
    }
    ClientRequest.generateClientRequest = function (data, endpoint) {
        return new ClientRequest(data, endpoint);
    };
    ClientRequest.getObjectType = function (response) {
        return response.object_type;
    };
    ClientRequest.prototype.handleResponse = function (response) {
        if (response && Object.keys(response).length > 0) {
            // For page, confirm that we got data, else throw an error.
            if (response.hasOwnProperty('otherContent') || (response.hasOwnProperty('page') && response.page)) {
                return new ClientResponse(this.triggerParsing(response), null);
            }
            else {
                return new ClientResponse(null, 'The page content returned is empty!');
            }
        }
        return new ClientResponse(null, 'Unable to parse object returned from WP.');
    };
    ClientRequest.prototype.triggerParsing = function (response) {
        if (response.otherContent) {
            return tslib_1.__assign({}, (response.otherContent.header ? { header: this.parseResponse(response.otherContent.header) } : {}), (response.otherContent.footer ? { footer: this.parseResponse(response.otherContent.footer) } : {}), (response.otherContent.overlay ? { overlay: this.parseResponse(response.otherContent.overlay) } : {}));
        }
        else {
            return this.parseResponse(response.page);
        }
    };
    /**
     * Parses the response body and returns the respective prototype objects.
     *  Objects that doesn't contain the property 'object_type' are ignored.
     * @param response The response body or object if within a recursive process.
     */
    ClientRequest.prototype.parseResponse = function (response) {
        var _this = this;
        var responseObject = {};
        Object.keys(response).forEach(function (key) {
            var value = response[key];
            if (Array.isArray(value)) {
                responseObject[key] = _this.handleTypeArray(value);
            }
            else if (value !== null && typeof value === 'object' && excludedKeys.indexOf(key) === -1) {
                responseObject[key] = _this.handleTypeObject(value);
            }
            else {
                if (key !== 'acf_fc_layout') {
                    responseObject[key] = value;
                }
            }
        });
        return this.generateObject(responseObject);
    };
    ClientRequest.prototype.handleTypeObject = function (propertyValue) {
        var arrPropertyNames = Object.keys(propertyValue), firstPropertyValue = propertyValue[arrPropertyNames[0]];
        if (arrPropertyNames.length === 1) {
            if (Array.isArray(firstPropertyValue)) {
                return this.handleTypeArray(firstPropertyValue);
            }
            else if (typeof firstPropertyValue === 'object') {
                return this.parseResponse(firstPropertyValue);
            }
        }
        else {
            return this.parseResponse(propertyValue);
        }
        return null;
    };
    ClientRequest.prototype.handleTypeArray = function (propertyValue) {
        var _this = this;
        var array = [];
        propertyValue.forEach(function (object) {
            if (typeof object === 'object') {
                if (object.object_type) {
                    array.push(_this.parseResponse(object));
                }
                else {
                    // ACF Layout entry.
                    var generatedValue = _this.generateValueForWordPressAcfEntry(object);
                    if (generatedValue) {
                        array.push(generatedValue);
                    }
                }
            }
            else {
                array.push(object);
            }
        });
        return array;
    };
    ClientRequest.prototype.generateValueForWordPressAcfEntry = function (acfObject) {
        // We are only interested in properties which excludes acf_fc_layout
        var acf_fc_layout = acfObject.acf_fc_layout, otherProperties = tslib_1.__rest(acfObject, ["acf_fc_layout"]), arrPropertyNames = Object.keys(otherProperties), firstPropertyValue = otherProperties[arrPropertyNames[0]];
        /**
         * A specific case to cater for Wordpress 'Flexible Content' where
         *  an entry (Layout) has no object type and includes only 1 property.
         */
        if (arrPropertyNames.length === 1 && typeof firstPropertyValue === 'object') {
            if (Array.isArray(firstPropertyValue)) {
                return this.handleTypeArray(firstPropertyValue);
            }
            else if (firstPropertyValue.object_type) {
                return this.parseResponse(tslib_1.__assign({}, firstPropertyValue, { flexibleContentId: acf_fc_layout }));
            }
            else {
                /* Having an object at hand, iterate recursively until this condition is never reached.
                 * This is required due to nested objects for grouping within the CMS, and will only
                 * trigger when there is only 1 property.
                 */
                var objectKeys = Object.keys(firstPropertyValue);
                if (objectKeys.length === 1 && !!firstPropertyValue[objectKeys[0]]) {
                    return this.generateValueForWordPressAcfEntry(firstPropertyValue);
                }
            }
        }
        else {
            return otherProperties;
        }
        return null;
    };
    ClientRequest.prototype.generateObject = function (response) {
        var objectType = ClientRequest.getObjectType(response), objectClass = this.componentMappings.get(objectType);
        if (objectClass) {
            return new objectClass(response);
        }
        else {
            throw new ApiException('Unable to set the given object.\n\n'
                .concat(JSON.stringify(response)
                .concat('\n')));
        }
    };
    Object.defineProperty(ClientRequest.prototype, "requestData", {
        get: function () {
            return this._requestData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientRequest.prototype, "endpoint", {
        get: function () {
            return this._endpoint;
        },
        enumerable: true,
        configurable: true
    });
    return ClientRequest;
}());
export { ClientRequest };
