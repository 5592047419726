import { BaseObject } from '../../../BaseObject';
import { Icon } from '../html-elements/Icon';

export class SocialLinks extends BaseObject {
  private readonly _socialLinks: Icon[];

  constructor(data: any) {
    super('SocialLinks', data);

    this._socialLinks = data.social_links;
  }

  get socialLinks(): Icon[] {
    return this._socialLinks;
  }
}
