/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./navigation-link/navigation-link.component.ngfactory";
import * as i2 from "./navigation-link/navigation-link.component";
import * as i3 from "@angular/common";
import * as i4 from "../../html-elements/input-field/input-field.component.ngfactory";
import * as i5 from "../../html-elements/input-field/input-field.component";
import * as i6 from "../../../../../shared/api/api.service";
import * as i7 from "@angular/router";
import * as i8 from "../social-links/social-links.component.ngfactory";
import * as i9 from "../social-links/social-links.component";
import * as i10 from "../../html-elements/action-link/action-link.component.ngfactory";
import * as i11 from "../../html-elements/action-link/action-link.component";
import * as i12 from "../../html-elements/icon/icon.component.ngfactory";
import * as i13 from "../../html-elements/icon/icon.component";
import * as i14 from "./navigation-bar.component";
var styles_NavigationBarComponent = [];
var RenderType_NavigationBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavigationBarComponent, data: {} });
export { RenderType_NavigationBarComponent as RenderType_NavigationBarComponent };
function View_NavigationBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["aria-label", "Toggle navigation"], ["class", "navbar-toggler"], ["data-toggle", "collapse"], ["type", "button"]], [[1, "aria-expanded", 0], [1, "aria-controls", 0], [1, "data-target", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "navbar-toggler-icon"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getToggleState(); var currVal_1 = _co.getNavBarId(); var currVal_2 = _co.getNavBarIdWithHash(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_NavigationBarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-navigation-link", [], [[1, "class", 0]], null, null, i1.View_NavigationLinkComponent_0, i1.RenderType_NavigationLinkComponent)), i0.ɵdid(1, 49152, null, 0, i2.NavigationLinkComponent, [i3.DOCUMENT], { navigationLink: [0, "navigationLink"], rowNumber: [1, "rowNumber"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; var currVal_2 = _v.context.index; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
function View_NavigationBarComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_NavigationBarComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_NavigationBarComponent_5)), i0.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { $implicit: 0 }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); var currVal_1 = i0.ɵnov(_v.parent.parent, 10); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_NavigationBarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "amg-navbar-extra"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_4)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getExtraElements(); _ck(_v, 2, 0, currVal_0); }, null); }
function View_NavigationBarComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_NavigationBarComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_NavigationBarComponent_9)), i0.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { $implicit: 0 }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); var currVal_1 = i0.ɵnov(_v.parent.parent.parent, 10); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_NavigationBarComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "amg-navigation-bar-section"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_8)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NavigationBarComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-input-field", [], [[1, "class", 0]], null, null, i4.View_InputFieldComponent_0, i4.RenderType_InputFieldComponent)), i0.ɵdid(1, 245760, null, 0, i5.InputFieldComponent, [i6.ApiService, i7.Router], { inputField: [0, "inputField"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
function View_NavigationBarComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-social-links", [], [[1, "class", 0]], null, null, i8.View_SocialLinksComponent_0, i8.RenderType_SocialLinksComponent)), i0.ɵdid(1, 114688, null, 0, i9.SocialLinksComponent, [], { socialLinks: [0, "socialLinks"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
function View_NavigationBarComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-action-link", [], [[1, "class", 0]], null, null, i10.View_ActionLinkComponent_0, i10.RenderType_ActionLinkComponent)), i0.ɵdid(1, 114688, null, 0, i11.ActionLinkComponent, [i3.DOCUMENT], { actionLink: [0, "actionLink"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
function View_NavigationBarComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-icon", [], [[1, "class", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onIconClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_IconComponent_0, i12.RenderType_IconComponent)), i0.ɵdid(1, 114688, null, 0, i13.IconComponent, [i3.DOCUMENT, i7.Router], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
function View_NavigationBarComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i3.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_11)), i0.ɵdid(3, 278528, null, 0, i3.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_12)), i0.ɵdid(5, 278528, null, 0, i3.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_13)), i0.ɵdid(7, 278528, null, 0, i3.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_14)), i0.ɵdid(9, 278528, null, 0, i3.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.objectType; _ck(_v, 1, 0, currVal_0); var currVal_1 = "InputField"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "SocialLinks"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "ActionLink"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "Icon"; _ck(_v, 9, 0, currVal_4); }, null); }
function View_NavigationBarComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i3.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_7)), i0.ɵdid(3, 278528, null, 0, i3.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_10)), i0.ɵdid(5, 278528, null, 0, i3.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isTypeArray(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); var currVal_1 = true; _ck(_v, 3, 0, currVal_1); var currVal_2 = false; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_NavigationBarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [], [[1, "id", 0]], null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(4, { "collapse navbar-collapse": 0, "show": 1 }), (_l()(), i0.ɵeld(5, 0, null, null, 2, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_2)), i0.ɵdid(7, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationBarComponent_3)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, [["handleSection", 2]], null, 0, null, View_NavigationBarComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCollapsible(); _ck(_v, 1, 0, currVal_0); var currVal_2 = _ck(_v, 4, 0, _co.isCollapsible(), _co.getToggleState()); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.getNavigationLinks(); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.areExtraSectionsAvailable(); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getNavBarId(); _ck(_v, 2, 0, currVal_1); }); }
export function View_NavigationBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-navigation-bar", [], [[1, "class", 0]], null, null, View_NavigationBarComponent_0, RenderType_NavigationBarComponent)), i0.ɵdid(1, 114688, null, 0, i14.NavigationBarComponent, [i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var NavigationBarComponentNgFactory = i0.ɵccf("amg-wp-navigation-bar", i14.NavigationBarComponent, View_NavigationBarComponent_Host_0, { navigationBar: "navigationBar" }, {}, []);
export { NavigationBarComponentNgFactory as NavigationBarComponentNgFactory };
