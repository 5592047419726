<div class="amg-card-fixture"
     [ngClass]="{ 'amg-fixture-live': getIsFixtureLive() }">
  <div class="amg-card-fixture-info">
    <amg-date class="amg-date"
              [fixtureDate]="getFixtureDate()"></amg-date>
    <div class="amg-card-fixture-stadium">{{ getStadiumName() }}</div>
    <span class="placeholder-1"></span>
  </div>
  <div class="amg-card-fixture-teams">
    <div class="amg-card-fixture-team amg-card-fixture-team-home">
      <img class="amg-card-fixture-team-icon amg-card-fixture-team-icon-home"
           [src]="getHomeTeamData().logo"
           [alt]="getHomeTeamData().name"/>
      <div class="amg-card-fixture-team-name amg-card-fixture-team-name-home"
           [innerHTML]="getHomeTeamData().name"></div>
    </div>
    <div class="amg-card-fixture-team amg-card-fixture-team-away">
      <img class="amg-card-fixture-team-icon amg-card-fixture-team-icon-away"
           [src]="getAwayTeamData().logo"
           [alt]="getAwayTeamData().name"/>
      <div class="amg-card-fixture-team-name amg-card-fixture-team-name-away"
           [innerHTML]="getAwayTeamData().name"></div>
    </div>
    <div class="t">
      <div class="amg-card-fixture-sponsor">
        <img *ngIf="isThumbnailAvailable();"
             class="amg-card-calendar-fixture-sponsor-img"
             [src]="getThumbnail()"/>
      </div>
      <amg-date class="amg-date"
                [fixtureDate]="getFixtureDate()"></amg-date>
    </div>
  </div>
  <div class="amg-card-fixture-meta">
    <amg-live-buttons [fixtureMediaData]="getMediaData()"
                      (triggerClick)="onMediaDataButtonClick($event)">
    </amg-live-buttons>
  </div>
</div>
