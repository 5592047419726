import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AnywhereMediaData, FixtureMediaData} from '../../../../_data/video-player.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'amg-live-icons',
  templateUrl: './live-icons.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LiveIconsComponent implements OnInit {

  @Input()
  media: AnywhereMediaData[];

  @Output() // data from clicked item
  mediaRequest: EventEmitter<FixtureMediaData> = new EventEmitter<FixtureMediaData>();

  audioEntryData: FixtureMediaData;
  videoEntryData: FixtureMediaData;
  isVideoLiveLocalRef: boolean;
  isAudioLiveLocalRef: boolean;

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    if (this.media.length > 0) {
      this.media.forEach(media => {
        this.initiatePolling(media);
      });
    } else {
      this.initiatePolling(this.media);
    }
  }

  private initiatePolling(media) {
    if (media.mediaType === 'audio') {
      this.audioEntryData = media;
      if (media && media.isLiveUrl) {
        this.getPollResponse(media.isLiveUrl)
          .subscribe(res => {
            this.isAudioLiveLocalRef = res;
          });
      }
    } else if (media.mediaType === 'video') {
      this.videoEntryData = media;
      if (media && media.isLiveUrl) {
        this.getPollResponse(media.isLiveUrl)
          .subscribe(res => {
            this.isVideoLiveLocalRef = res;
          });
      }
    }
  }

  getPollResponse( url ): Observable<any> {
    return this.http.get(url);
  }

  changeMedia( media: FixtureMediaData ) {
    this.mediaRequest.emit(media);
    //this.videoPlayerService.setEntryAndKSBSubject(entryId, null);
    //this.videoPlayerService.changeMediaMessage(this.cardData);
  }

}
