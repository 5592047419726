import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
var FilterDropdownComponent = /** @class */ (function () {
    function FilterDropdownComponent() {
        this.class = 'amg-filter-dropdown';
        this.triggerFilter = new EventEmitter();
    }
    FilterDropdownComponent.generateFilterByDropdown = function (value, id, displayKey) {
        return tslib_1.__assign({ data: value, id: id }, (displayKey ? { displayKey: displayKey } : {}));
    };
    FilterDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.resetFilterObservable) {
            this.resetFilterSubscription = this.resetFilterObservable.subscribe(function (reset) {
                if (reset) {
                    _this.resetData();
                }
            });
        }
        this.resetFilterInternalSubscription = this.resetFilterInternalObservable.subscribe(function (reset) {
            if (reset) {
                _this.resetData();
            }
        });
        this.selectAndSetFirstItemFromDropdown();
    };
    FilterDropdownComponent.prototype.ngOnDestroy = function () {
        this.resetFilterInternalSubscription.unsubscribe();
        if (this.resetFilterSubscription) {
            this.resetFilterSubscription.unsubscribe();
        }
    };
    FilterDropdownComponent.prototype.selectAndSetFirstItemFromDropdown = function () {
        if (this.gridFilterGroup.dropdown.length > 0) {
            this.dropdownModel = tslib_1.__assign({}, this.gridFilterGroup.dropdown[0]);
        }
    };
    FilterDropdownComponent.prototype.isSearchable = function () {
        return !!this.gridFilterGroup.isSearchable;
    };
    FilterDropdownComponent.prototype.getFilterLabel = function () {
        return this.gridFilterGroup.label;
    };
    FilterDropdownComponent.prototype.getFilters = function () {
        return this.gridFilterGroup.dropdown;
    };
    FilterDropdownComponent.prototype.onFilterChange = function (value) {
        var filterByDropdown = FilterDropdownComponent.generateFilterByDropdown(value, this.gridIndexId, this.gridFilterGroup.urlDisplayKey);
        this.triggerFilter.emit({
            type: 'filter',
            filterData: filterByDropdown
        });
    };
    FilterDropdownComponent.prototype.resetData = function () {
        this.selectAndSetFirstItemFromDropdown();
    };
    return FilterDropdownComponent;
}());
export { FilterDropdownComponent };
