import { OnInit, QueryList, ViewContainerRef } from '@angular/core';
import { DeviceUtils } from 'amg-fe-utils';
import { CardVideoStaticProperties } from './card.video.static.properties';
import { CardVideoResponsiveProperties } from './card.video.responsive.properties';
var CardVideoComponent = /** @class */ (function () {
    function CardVideoComponent() {
        this.locale = DeviceUtils.userLocale;
    }
    CardVideoComponent.padValue = function (value) {
        return (value.length === 1)
            ? "0" + value
            : value;
    };
    CardVideoComponent.prototype.ngOnInit = function () {
        this._runtime = this.generateRuntime();
        this._released = this.generateReleased();
        this._thumbnail = this.generateThumbnail();
        this._entitlements = this.componentData.metaData.SysEntryEntitlements.join('-') || 'free';
    };
    CardVideoComponent.prototype.getCardWidth = function () {
        if (CardVideoComponent.cardWidth === -1) {
            CardVideoComponent.cardWidth = 350;
            if (screen.width >= 700) {
                CardVideoComponent.cardWidth = 300;
            }
            if (screen.width >= 1920) {
                CardVideoComponent.cardWidth = 900;
            }
        }
        return CardVideoComponent.cardWidth;
    };
    CardVideoComponent.prototype.getEntitlement = function () {
        return this._entitlements;
    };
    CardVideoComponent.prototype.getCategories = function () {
        return this.componentData.metaData.category;
    };
    CardVideoComponent.prototype.getReleased = function () {
        return this._released;
    };
    CardVideoComponent.prototype.getRuntime = function () {
        return this._runtime;
    };
    CardVideoComponent.prototype.getThumbnail = function () {
        return this._thumbnail;
    };
    CardVideoComponent.prototype.getTitle = function () {
        return this.componentData.metaData.title;
    };
    CardVideoComponent.prototype.generateRuntime = function () {
        var runtime = new Date(this.componentData.metaData.VideoDuration * 1000);
        return {
            seconds: { value: runtime.getUTCSeconds().toString(), paddedValue: CardVideoComponent.padValue(runtime.getUTCSeconds().toString()) },
            minutes: { value: runtime.getUTCMinutes().toString(), paddedValue: CardVideoComponent.padValue(runtime.getUTCMinutes().toString()) },
            hours: { value: runtime.getUTCHours().toString(), paddedValue: CardVideoComponent.padValue(runtime.getUTCHours().toString()) }
        };
    };
    CardVideoComponent.prototype.generateReleased = function () {
        var releaseFrom = this.componentData.publicationData.releaseFrom;
        var date = (releaseFrom.toUpperCase().endsWith('Z'))
            ? new Date(releaseFrom)
            : new Date(releaseFrom + 'Z');
        return {
            seconds: { value: date.getSeconds().toString(), paddedValue: CardVideoComponent.padValue(date.getSeconds().toString()) },
            minutes: { value: date.getMinutes().toString(), paddedValue: CardVideoComponent.padValue(date.getMinutes().toString()) },
            hours: { value: date.getHours().toString(), paddedValue: CardVideoComponent.padValue(date.getHours().toString()) },
            days: {
                value: date.getDate().toString(),
                paddedValue: CardVideoComponent.padValue(date.getDate().toString()),
                dayOfWeek: new Intl.DateTimeFormat(this.locale, { weekday: 'long' }).format(date)
            },
            months: {
                value: (date.getMonth() + 1).toString(),
                paddedValue: CardVideoComponent.padValue((date.getMonth() + 1).toString()),
                month: new Intl.DateTimeFormat(this.locale, { month: 'long' }).format(date)
            },
            years: { value: (date.getFullYear()).toString(), shortValue: date.getFullYear().toString().substring(2, 2) }
        };
    };
    CardVideoComponent.prototype.generateThumbnail = function () {
        return this.componentData.mediaData.thumbnailUrl + "/width/" + this.getCardWidth();
    };
    CardVideoComponent.cardWidth = -1;
    return CardVideoComponent;
}());
export { CardVideoComponent };
