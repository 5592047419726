/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./date.component";
var styles_DateComponent = [];
var RenderType_DateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DateComponent, data: {} });
export { RenderType_DateComponent as RenderType_DateComponent };
export function View_DateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "time"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "hour"]], [[1, "data-date-hour", 0], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "minute"]], [[1, "data-date-minute", 0], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "date"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [["class", "day"]], [[1, "data-date-day", 0], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "month"]], [[1, "data-date-month", 0], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "year"]], [[1, "data-date-year", 0], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hour; var currVal_1 = _co.hour; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.minute; var currVal_3 = _co.minute; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.day; var currVal_5 = _co.day; _ck(_v, 4, 0, currVal_4, currVal_5); var currVal_6 = _co.month; var currVal_7 = _co.month; _ck(_v, 5, 0, currVal_6, currVal_7); var currVal_8 = _co.year; var currVal_9 = _co.year; _ck(_v, 6, 0, currVal_8, currVal_9); }); }
export function View_DateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-date", [], null, null, null, View_DateComponent_0, RenderType_DateComponent)), i0.ɵdid(1, 114688, null, 0, i1.DateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DateComponentNgFactory = i0.ɵccf("amg-date", i1.DateComponent, View_DateComponent_Host_0, { fixtureDate: "fixtureDate" }, {}, []);
export { DateComponentNgFactory as DateComponentNgFactory };
