import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Ng2FlatpickrComponent } from 'ng2-flatpickr';
import dateFormat from 'dateformat';
var FilterDateComponent = /** @class */ (function () {
    function FilterDateComponent() {
        this.triggerFilter = new EventEmitter();
        this.class = '';
    }
    FilterDateComponent.formatDate = function (date) {
        return dateFormat(date, 'yyyy-mm-dd');
    };
    FilterDateComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.resetFilterObservable) {
            this.resetFilterSubscription = this.resetFilterObservable.subscribe(function (reset) {
                if (reset) {
                    _this.resetData();
                }
            });
        }
        this.resetFilterInternalSubscription = this.resetFilterInternalObservable.subscribe(function (reset) {
            if (reset) {
                _this.resetData();
            }
        });
        this.dateFilterProperties.onClose = (function (selectedDates) {
            _this.onFilterChange(selectedDates);
        });
    };
    FilterDateComponent.prototype.resetData = function () {
        this.flatpickrComponentRef.flatpickr.setDate('');
        this.class = FilterDateComponent._class;
    };
    FilterDateComponent.prototype.ngOnDestroy = function () {
        this.flatpickrComponentRef.flatpickr.destroy();
        this.resetFilterInternalSubscription.unsubscribe();
        if (this.resetFilterSubscription) {
            this.resetFilterSubscription.unsubscribe();
        }
    };
    FilterDateComponent.prototype.getFilterLabel = function () {
        return this.dateFilterProperties.label;
    };
    FilterDateComponent.prototype.getDefaultLabel = function () {
        return this.dateFilterProperties.defaultLabel;
    };
    FilterDateComponent.prototype.getDateFilterProperties = function () {
        return this.dateFilterProperties;
    };
    FilterDateComponent.prototype.onFilterChange = function (selectedDates) {
        if (selectedDates.length > 1) {
            this.class = FilterDateComponent._class + " dirty";
            var dateFrom = selectedDates[0];
            var dateTo = selectedDates[1];
            this.triggerFilter.emit({
                type: 'date',
                dateData: tslib_1.__assign({ from: FilterDateComponent.formatDate(dateFrom) }, (dateTo ? { to: FilterDateComponent.formatDate(dateTo) } : {}))
            });
        }
    };
    FilterDateComponent._class = 'app-filter-date';
    return FilterDateComponent;
}());
export { FilterDateComponent };
