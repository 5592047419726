import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SocialLinks, Icon } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-social-links',
  templateUrl: './social-links.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SocialLinksComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-social-links';
  @Input() socialLinks: SocialLinks;

  ngOnInit(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }
  }
  getSocialLinks(): Icon[] {
    return this.socialLinks.socialLinks;
  }

  getCss(): string {
    return this.socialLinks.cssClass;
  }
}
