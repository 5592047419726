import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Calendar as WpCalendar } from 'wordpress-adapter';
import { CalendarStaticProperties, CalendarResponsiveProperties } from 'calendar';
import { AmgFixture } from 'amg';
import { ApiService } from '../../../../../shared/api/api.service';
import { DataService } from '../../../../../shared/data/data.service';

@Component({
  selector: 'amg-wp-calendar',
  templateUrl: './calendar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-calendar';
  @Input() calendar: WpCalendar;

  public calendarData: AmgFixture[];
  public calendarResponsiveProperties: CalendarResponsiveProperties;
  public calendarStaticProperties: CalendarStaticProperties;

  private apiDataObservable: Subscription;

  constructor(private apiService: ApiService,
              private dataService: DataService,
              private router: Router) {
  }

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.initialiseCalendar();
    this.triggerDataRetrieval();
  }

  ngOnDestroy(): void {
    if (this.apiDataObservable) {
      this.apiDataObservable.unsubscribe();
    }
  }

  onFixtureClick(data: { type: string; entry: any; }) {
    if (this.calendar.onClickNavigate) {
      this.dataService.userInteractedObject = {
        fixtureUrl: this.calendar.restApiConfiguration.baseUrl,
        data: data.entry
      };

      this.router.navigate([this.calendar.onClickNavigate], {
        queryParams: {
          fixture: this.dataService.encodeUrl(this.calendar.restApiConfiguration.baseUrl),
          entry: data.entry.entryId
        },
        preserveQueryParams: false
      });
    }
  }

  getCss(): string {
    return this.calendar.cssClass;
  }

  private initialiseCalendar(): void {
    this.calendarResponsiveProperties = new CalendarResponsiveProperties(this.calendar.calendarConfiguration);
    this.calendarStaticProperties = {
      _tabs: undefined,
      showTabsAsText: true,
      cardFixtureStaticProperties: {
        buttonType: 'btn',
        audioButtonTitle: this.calendar.staticProperties.audioButtonTitle,
        videoButtonTitle: this.calendar.staticProperties.videoButtonTitle
      }
    };

    this.calendarData = [];
  }

  private triggerDataRetrieval(): void {
    this.apiDataObservable = this.apiService.getApiData(this.calendar.restApiConfiguration).subscribe(data => {
      if (data) {
        this.calendarData = this.getFixtureData(data);
      }
    });
  }

  private getFixtureData(data: any): any[] {
    if (data) {
      if (data.fixtures && data.fixtures.length) {
        return data.fixtures;
      }
    }

    return [];
  }
}
