import { ChangeDetectorRef, EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserAuthService } from 'user-auth';
import { Section } from 'wordpress-adapter';
import { DataService } from '../../shared/data/data.service';
import { UserState } from '@amg-fe-angular/types/payments/user-auth.type';
import { UserAuthSsoService } from '@amg-fe-angular/services/user-auth/user-auth-sso.service';
var SectionComponent = /** @class */ (function () {
    function SectionComponent(domSanitizer, dataService, userAuthService, userAuthNewService, ngZone, changeDetectorRef) {
        this.domSanitizer = domSanitizer;
        this.dataService = dataService;
        this.userAuthService = userAuthService;
        this.userAuthNewService = userAuthNewService;
        this.ngZone = ngZone;
        this.changeDetectorRef = changeDetectorRef;
        this.class = 'amg-section';
        this.isLoggedIn = false;
        this.isSubscriptionAvailable = false;
        this.isDisabledForMobile = false;
        this.isLoading = true;
        this.triggerChange = new EventEmitter();
        this.triggerDisabledByDrm = new EventEmitter();
    }
    Object.defineProperty(SectionComponent.prototype, "isDrmDisabled", {
        set: function (value) {
            var _this = this;
            this.ngZone.run(function () {
                _this.isDisabledForMobile = value;
                // If disabled due to DRM, set loading to false as the
                //  subscription call is not going to be performed.
                if (value) {
                    _this.isLoading = false;
                    _this.changeDetectorRef.detectChanges();
                }
            });
        },
        enumerable: true,
        configurable: true
    });
    SectionComponent.prototype.ngOnDestroy = function () {
        if (this.userAuthSubscription) {
            this.userAuthSubscription.unsubscribe();
        }
        if (this.userSubscriptionStateSubscription) {
            this.userSubscriptionStateSubscription.unsubscribe();
        }
    };
    SectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setUpHostStyling();
        /**
         * Screen one: Login Overlay
         * Screen two: DRM Overlay - if available
         * Screen three: Subscription Overlay (Skip if drm is disabled)
         */
        this.userAuthSubscription = this.userAuthNewService.userStateObservable.subscribe(function (userState) {
            if (userState) {
                _this.isLoggedIn = userState.state !== UserState.notLoggedIn;
            }
            else {
                _this.isLoggedIn = false;
                _this.isLoading = false;
            }
            _this.changeDetectorRef.markForCheck();
        });
        if (this.userAuthService) {
            // Is Login available Attribute
            // this.userAuthSubscription = this.userAuthService.getUserLoginDetailsObservable().subscribe(value => {
            //   if (value) {
            //     this.isLoggedIn = value.isUserLoggedIn;
            //   } else {
            //     this.isLoggedIn = false;
            //     this.isLoading = false;
            //   }
            // });
            // Is KS available Attribute
            var isInit_1 = true;
            this.userSubscriptionStateSubscription = this.userAuthService.getUserSubscriptionState().subscribe(function (value) {
                _this.isSubscriptionAvailable = value;
                if (!_this.isDisabledForMobile) {
                    if (isInit_1) { // First subscription, and the second is the check
                        _this.isLoading = true;
                        isInit_1 = false;
                    }
                    else {
                        _this.isLoading = false;
                    }
                }
            });
        }
    };
    SectionComponent.prototype.onElementChange = function (value) {
        this.triggerChange.emit(value);
    };
    SectionComponent.prototype.onDrmStateChange = function (value) {
        var _this = this;
        this.ngZone.run(function () {
            _this.isDisabledForMobile = value;
            _this.triggerDisabledByDrm.emit(value);
            // If disabled due to DRM, set loading to false as the
            //  subscription call is not going to be performed.
            if (value) {
                _this.isLoading = false;
                _this.changeDetectorRef.detectChanges();
            }
        });
    };
    SectionComponent.prototype.getChildren = function () {
        return this.section.children;
    };
    SectionComponent.prototype.getTitle = function () {
        return this.section.title;
    };
    SectionComponent.prototype.getDescription = function () {
        return this.section.description;
    };
    SectionComponent.prototype.areChildrenAvailable = function () {
        return this.section.children && this.section.children.length > 0;
    };
    SectionComponent.prototype.getCss = function () {
        return this.section.cssClass;
    };
    SectionComponent.prototype.setUpHostStyling = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        if (this.section.backgroundImage) {
            this.backgroundImage = "url(" + this.section.backgroundImage.url + ")";
            this.class += ' amg-section-bg-image';
        }
    };
    return SectionComponent;
}());
export { SectionComponent };
