import { Component, OnInit, HostBinding, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Instance as FlatpickrInstance } from 'flatpickr/dist/types/instance';
import { Observable, Subscription } from 'rxjs';

import { FlatpickrOptions, Ng2FlatpickrComponent } from 'ng2-flatpickr';

import dateFormat from 'dateformat';
import { DateFilterProperties } from '../../../types/GridStaticProperties';
import { SearchTrigger } from '../../../types/SearchTrigger';

@Component({
  selector: 'amg-filter-date',
  templateUrl: './filter-date.component.html'
})
export class FilterDateComponent implements OnInit, OnDestroy {
  private static readonly _class = 'app-filter-date';

  @HostBinding('attr.class') class;
  @Input() dateFilterProperties: DateFilterProperties;
  @Input() resetFilterObservable: Observable<boolean>;
  @Input() resetFilterInternalObservable: Observable<boolean>;
  @Output() triggerFilter: EventEmitter<SearchTrigger>;

  @ViewChild('flatpickrComponent') flatpickrComponentRef: Ng2FlatpickrComponent;

  private resetFilterSubscription: Subscription;
  private resetFilterInternalSubscription: Subscription;

  private static formatDate(date: Date): string {
    return dateFormat(date, 'yyyy-mm-dd');
  }

  constructor() {
    this.triggerFilter = new EventEmitter<SearchTrigger>();
    this.class = '';
  }

  ngOnInit() {
    if (this.resetFilterObservable) {
      this.resetFilterSubscription = this.resetFilterObservable.subscribe(reset => {
        if (reset) {
          this.resetData();
        }
      });
    }

    this.resetFilterInternalSubscription = this.resetFilterInternalObservable.subscribe(reset => {
      if (reset) {
        this.resetData();
      }
    });

    this.dateFilterProperties.onClose = ((selectedDates: Date[]) => {
      this.onFilterChange(selectedDates);
    });
  }


  private resetData(): void {
    (this.flatpickrComponentRef.flatpickr as FlatpickrInstance).setDate('');
    this.class = FilterDateComponent._class;
  }

  ngOnDestroy(): void {
    (this.flatpickrComponentRef.flatpickr as FlatpickrInstance).destroy();

    this.resetFilterInternalSubscription.unsubscribe();

    if (this.resetFilterSubscription) {
      this.resetFilterSubscription.unsubscribe();
    }
  }

  getFilterLabel(): string {
    return this.dateFilterProperties.label;
  }

  getDefaultLabel(): string {
    return this.dateFilterProperties.defaultLabel;
  }

  getDateFilterProperties(): FlatpickrOptions {
    return (this.dateFilterProperties as FlatpickrOptions);
  }

  onFilterChange(selectedDates: Array<Date>): void {
    if (selectedDates.length > 1) {
      this.class = `${FilterDateComponent._class} dirty`;

      const dateFrom = selectedDates[0];
      const dateTo = selectedDates[1];

      this.triggerFilter.emit({
        type: 'date',
        dateData: {
          from: FilterDateComponent.formatDate(dateFrom),

          ...( dateTo ? { to: FilterDateComponent.formatDate(dateTo) } : {} )
        }
      });
    }
  }


}
