/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./card-profile.component";
import * as i3 from "../../../amg/src/lib/bootstrap.viewport.service";
var styles_CardProfileComponent = [];
var RenderType_CardProfileComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CardProfileComponent, data: {} });
export { RenderType_CardProfileComponent as RenderType_CardProfileComponent };
export function View_CardProfileComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "amg-card-profile card card-profile"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "card-img-top"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { "background-image": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 8, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "position"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "number"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "name-first"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "name-last"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.getThumbnailAsBackground()); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.componentData.imageUrl; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.componentData.position; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.componentData.number; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.componentData.nameFirst; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.componentData.nameLast; _ck(_v, 13, 0, currVal_5); }); }
export function View_CardProfileComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-card-profile", [], null, null, null, View_CardProfileComponent_0, RenderType_CardProfileComponent)), i0.ɵdid(1, 245760, null, 0, i2.CardProfileComponent, [i3.BootstrapViewportService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardProfileComponentNgFactory = i0.ɵccf("amg-card-profile", i2.CardProfileComponent, View_CardProfileComponent_Host_0, { componentData: "componentData", responsiveProperties: "responsiveProperties", staticProperties: "staticProperties", componentChildren: "componentChildren" }, {}, []);
export { CardProfileComponentNgFactory as CardProfileComponentNgFactory };
