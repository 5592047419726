import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {StreamPaymentsHooks} from '../../shared/data/stream-payments.model';
import {Button} from '../../shared/data/wordpress-data.model';
import {UserAuthService} from '../../user-auth.service';

@Component({
  selector: 'amg-register-btn',
  templateUrl: './register-btn.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RegisterBtnComponent implements OnInit {
  @Input() registerBtn:Button;
  show: boolean = false;

  constructor(private userAuthService: UserAuthService) { }

  ngOnInit() {
    this.userAuthService.getUserState()
      .subscribe(res => {
        if (res) {
          this.show = false;
        } else {
          this.show = true;
        }
      });
  }

  public openPage() {
    this.userAuthService.hookStreamPayments(StreamPaymentsHooks.getSubscriptionPackageList, null)
      .then(res => {
        this.userAuthService.setPackageData(res);
      });
  }

}
