import { OnInit, QueryList, ViewContainerRef, OnDestroy } from '@angular/core';
import { BootstrapViewportService, } from 'amg';
import { CardTextStaticProperties } from './card.text.static.properties';
import { CardTextResponsiveProperties } from './card.text.responsive.properties';
var CardTextComponent = /** @class */ (function () {
    function CardTextComponent(bootstrapViewportService) {
        this.bootstrapViewportService = bootstrapViewportService;
        this.componentCurrentView = {};
    }
    CardTextComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.staticProperties === undefined) {
            this.staticProperties = new CardTextStaticProperties();
        }
        var viewportSubscriber = this.bootstrapViewportService.getViewportSubscriber();
        var viewport = this.bootstrapViewportService.getViewport();
        this.componentCurrentView = this.responsiveProperties[viewport];
        this.componentViewportSubscription = viewportSubscriber.subscribe(function (newBreakpoint) {
            if (_this.responsiveTimeout) {
                clearTimeout(_this.responsiveTimeout);
                _this.responsiveTimeout = null;
            }
            _this.responsiveTimeout = setTimeout(function () {
                _this.componentCurrentView = _this.responsiveProperties[newBreakpoint];
            }, _this.componentCurrentView.refreshRate);
        });
    };
    CardTextComponent.prototype.ngOnDestroy = function () {
        this.componentViewportSubscription.unsubscribe();
    };
    CardTextComponent.prototype.getText = function (key) {
        if (this.componentData) {
            if (this.componentData[key]) {
                return this.componentData[key];
            }
        }
        return '';
    };
    return CardTextComponent;
}());
export { CardTextComponent };
