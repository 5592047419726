/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../projects/wordpress/wordpress-components/src/lib/web-components/layout/header/header.component.ngfactory";
import * as i2 from "../../../../../projects/wordpress/wordpress-components/src/lib/web-components/layout/header/header.component";
import * as i3 from "@angular/common";
import * as i4 from "./header.component";
import * as i5 from "@angular/router";
var styles_HeaderComponent = [];
var RenderType_HeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "header", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "amg-wp-header", [], [[1, "class", 0]], null, null, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(2, 114688, null, 0, i2.HeaderComponent, [], { header: [0, "header"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.header; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); }); }
export function View_HeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i0.ɵdid(1, 49152, null, 0, i4.HeaderComponent, [i5.Router], null, null)], null, null); }
var HeaderComponentNgFactory = i0.ɵccf("app-header", i4.HeaderComponent, View_HeaderComponent_Host_0, { header: "header" }, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
