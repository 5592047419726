import * as tslib_1 from "tslib";
import { BaseObject } from '../BaseObject';
var BaseConfiguration = /** @class */ (function (_super) {
    tslib_1.__extends(BaseConfiguration, _super);
    function BaseConfiguration(data) {
        var _this = _super.call(this, 'BaseConfiguration', data) || this;
        _this._apiKey = data.api_key;
        _this._apiBaseUrl = data.api_url;
        _this._apiUserId = data.api_user_id;
        return _this;
    }
    BaseConfiguration.merge = function (parentConfig, childConfig) {
        return new BaseConfiguration({
            api_key: (childConfig && childConfig.apiKey) ? childConfig.apiKey : parentConfig.apiKey,
            api_url: (childConfig && childConfig.apiBaseUrl) ? childConfig.apiBaseUrl : parentConfig.apiBaseUrl,
            api_user_id: (childConfig && childConfig.apiUserId) ? childConfig.apiUserId : parentConfig.apiUserId
        });
    };
    Object.defineProperty(BaseConfiguration.prototype, "apiKey", {
        get: function () {
            return this._apiKey;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseConfiguration.prototype, "apiBaseUrl", {
        get: function () {
            return this._apiBaseUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseConfiguration.prototype, "apiUserId", {
        get: function () {
            return this._apiUserId;
        },
        enumerable: true,
        configurable: true
    });
    return BaseConfiguration;
}(BaseObject));
export { BaseConfiguration };
