import {decrypt} from 'caesar-shift';
import {environment} from '../../../environments/environment';
import {SsoToken} from '../service/sso-service/sso.service-old';
import {configs} from '../configs';

export class SportsAllianceUtil {
  public static readonly fulhamSsoTokenKey = 'ftoken';
  public static readonly cookieKey = '_sa_sso_upid_amg';
  private static readonly entropyLength = 22;
  private static readonly entropyIndex = 22;
  private static readonly caesarShiftAmount = 11;
  private static readonly fulhamPayloadQueryParam = 'tokenpayload';

  public static generateCookieString(payload: string, expiryDate: Date): string {

    return `${SportsAllianceUtil.cookieKey}=${encodeURIComponent(payload)}; expires=${expiryDate.toUTCString()}; path=/; domain=${environment.sso.cookieDomain}`;
  }

  public static unObfuscatePayload(payload: string): string {
    const randomEntropyRemoved = SportsAllianceUtil.removeRandomEntropy(payload,
      SportsAllianceUtil.entropyLength, SportsAllianceUtil.entropyIndex);
    const caesarShifted = SportsAllianceUtil.caesarShift(randomEntropyRemoved, SportsAllianceUtil.caesarShiftAmount);

    return atob(caesarShifted);
  }

  public static generateFullhamSignInUrl(currentUrl: string): string {
    const urlParameters = SportsAllianceUtil.generateReturnUrlParam(currentUrl);
    return `${environment.sso.baseUrl}${configs.sso.paths.login}?${urlParameters}`;
  }

  public static generateFullhamSignOutUrl(currentUrl: string): string {

    const newCurrentUrl = new URL(currentUrl);

    newCurrentUrl.searchParams.append('logout', '1');

    const urlParameters = SportsAllianceUtil.generateReturnUrlParam(newCurrentUrl.href);

    return `${environment.sso.baseUrl}${configs.sso.paths.logout}?${urlParameters}`;
  }

  public static isTokenValid(token: SsoToken): boolean {
    return token.access_token_expires_utc > new Date();
  }

  public static isRefreshTokenValid(token: SsoToken): boolean {
    return token.refresh_token_expires_utc > new Date();
  }

  public static parseSsoData(token: string): SsoToken {
    const ssoData = token
      .split('&')
      .map(data => {
        const values = data.split('=');
        const key = values[0];
        const value = key.endsWith('_utc')
          ? new Date(values[1])
          : values[1];

        return {[key]: value};
      }).reduce((previousValue, currentValue) => {
        return {
          ...previousValue,
          ...currentValue
        };
      });

    return ssoData as unknown as SsoToken;
  }

  public static getPayloadAndUrl(url: string): { payload: string; url: string } {
    const index = url.indexOf(SportsAllianceUtil.fulhamPayloadQueryParam);

    if (index !== -1) {
      let newUrl = url.substr(0, index - 1);
      let payload = url.substr((index + SportsAllianceUtil.fulhamPayloadQueryParam.length + 1), url.length);

      const fragmentIndex = payload.indexOf('#');
      if (index !== -1) {
        newUrl = newUrl + payload.substr(fragmentIndex);
        payload = payload.substr(0, fragmentIndex);
      }

      return {
        payload,
        url: newUrl
      };
    }

    return null;
  }

  private static generateReturnUrlParam(currentUrl: string): string {
    return [
      `successredirecturl=${encodeURIComponent(currentUrl)}`,
      `returnvisitorurl=${encodeURIComponent(currentUrl)}`,
      `tenantid=${environment.sso.tenantId}`,
    ].join('&');
  }

  private static removeRandomEntropy(payload: string, entropyLength: number, index: number): string {
    return payload.substr(0, index) + payload.substr(index + entropyLength);
  }

  private static caesarShift(text: string, shift: number): string {
    return decrypt(shift, text);
  }
}
