import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { AmgEvent, AmgFixture } from 'amg';
import { Subscription } from 'rxjs';
import { FixtureBaseComponent } from '../../models/FixtureBaseComponent';

@Component({
  selector: 'amg-card-live-details',
  templateUrl: './card-live-details.component.html',
})
export class CardLiveDetailsComponent extends FixtureBaseComponent implements OnInit, OnDestroy {
  @Input() fixtureData: AmgEvent | AmgFixture;
  @HostBinding('attr.is-live') isLive = false;

  private isFixtureLiveSubscription: Subscription;

  constructor(protected http: HttpClient) {
    super(http);
  }

  ngOnInit() {
    this.init();

    this.isFixtureLiveSubscription = this.getIsAnyFixtureLiveObservable().subscribe(isLive => {
      this.isLive = isLive;
    });
  }

  ngOnDestroy() {
    this.isFixtureLiveSubscription.unsubscribe();

    this.destroy();
  }
}
