import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Button} from '../../shared/data/wordpress-data.model';
import {UserAuthService} from '../../user-auth.service';

@Component({
  selector: 'amg-login-btn',
  templateUrl: './login-btn.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LoginBtnComponent implements OnInit {
  @Input()
  loginBtn: Button;

  @Input()
  showUserAccountDropdown: boolean;

  show: boolean = false;
  constructor(public userAuthService: UserAuthService) { }

  ngOnInit() {
    this.userAuthService.getUserState()
      .subscribe(res => {
        if (res) {
          this.show = false;
        } else {
          this.show = true;
        }
      });
  }

}
