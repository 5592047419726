var CarouselSlides = /** @class */ (function () {
    /**
     * Constructor that sets initial values
     * @param currentView the current viewport data
     * @param totalColumns total number of columns
     */
    function CarouselSlides(currentView, totalColumns) {
        this._currentSlide = currentView.startPosition;
        this._currentView = currentView;
        this._totalColumns = totalColumns;
    }
    /**
     * Change current viewport data
     * @param source
     * @param currentView the current viewport data
     */
    CarouselSlides.prototype.setCurrentView = function (source, currentView) {
        if (source === void 0) { source = 'unknown'; }
        this._currentView = currentView;
    };
    /**
     * Get current slide index
     */
    CarouselSlides.prototype.getCurrentSlide = function () {
        return this._currentSlide;
    };
    /**
     * Get if dot representing slide is currently active
     * @param slide
     */
    CarouselSlides.prototype.dotIsActive = function (slide) {
        return slide === this._currentSlide;
    };
    /**
     * Get if slide is visible on the screen
     * @param slide
     */
    CarouselSlides.prototype.slideIsActive = function (slide) {
        return ((slide >= (this._currentSlide)) &&
            (slide <= (this._currentSlide + this._currentView.limitViewableItems - 1)));
    };
    /**
     * Automatically go to slide #
     * @param source
     * @param n
     */
    CarouselSlides.prototype.slideTo = function (source, n) {
        if (source === void 0) { source = 'unknown'; }
        if (n >= 0) {
            if (n < this._totalColumns) {
                this._currentSlide = n;
            }
        }
    };
    /**
     * Automatically move to slide #
     * @param source
     * @param n
     */
    CarouselSlides.prototype.slideBy = function (source, n) {
        if (source === void 0) { source = 'unknown'; }
        this._currentSlide += n;
        if (this._currentView.imitateInfinityLoop) {
            if (this._currentSlide < 0) {
                this._currentSlide = this._totalColumns - 1;
            }
            if (this._currentSlide >= this._totalColumns) {
                this._currentSlide = 1;
            }
        }
        else {
            if (this._currentSlide < 0) {
                this._currentSlide = 0;
            }
            if (this._currentSlide >= this._totalColumns) {
                this._currentSlide = this._totalColumns - 1;
            }
        }
    };
    /**
     * Automatically go back 1 slide
     * @param source
     */
    CarouselSlides.prototype.slidePrevious = function (source) {
        if (source === void 0) { source = 'unknown'; }
        this._currentSlide = this._currentSlide - this._currentView.iterationLimit;
        if (this._currentSlide < 0) {
            if (this._currentView.imitateInfinityLoop) {
                this._currentSlide = this._totalColumns - 1;
            }
            else {
                // this._currentSlide = this._currentSlide + this._currentView.iterationLimit;
                this._currentSlide = 0;
            }
        }
        return this._currentSlide;
    };
    /**
     * Automatically go forward 1 slide
     * @param source
     */
    CarouselSlides.prototype.slideNext = function (source) {
        if (source === void 0) { source = 'unknown'; }
        this._currentSlide = this._currentSlide + this._currentView.iterationLimit;
        if (this._currentSlide >= this._totalColumns) {
            if (this._currentView.imitateInfinityLoop) {
                this._currentSlide = 0;
            }
            else {
                // this._currentSlide = this._currentSlide - this._currentView.iterationLimit;
                this._currentSlide = this._totalColumns - 1;
            }
        }
        return this._currentSlide;
    };
    return CarouselSlides;
}());
export { CarouselSlides };
