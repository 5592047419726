import { PipeTransform } from '@angular/core';
var RelevantFixturesPipe = /** @class */ (function () {
    function RelevantFixturesPipe() {
    }
    /**
     * Basic filtering of fixtures based on selected fixture day/month/year
     * @param fixtures an array that contains the fixture data as Array<AmgFixture>
     * @param args a CalendarTabData describing the current filter
     */
    RelevantFixturesPipe.prototype.transform = function (fixtures, args) {
        if (args === void 0) { args = {}; }
        var filteredFixtures = [];
        if (args.day !== undefined) {
            var day_1 = Number(args.day);
            var month_1 = Number(args.month);
            var year_1 = Number(args.year);
            fixtures.forEach(function (fixture) {
                if ((day_1 === fixture.fixtureDate.day) &&
                    (month_1 === fixture.fixtureDate.month) &&
                    (year_1 === fixture.fixtureDate.year)) {
                    filteredFixtures.push(fixture);
                }
            });
        }
        else if (args.month !== undefined) {
            var month_2 = Number(args.month);
            var year_2 = Number(args.year);
            fixtures.forEach(function (fixture) {
                if ((month_2 === fixture.fixtureDate.month) &&
                    (year_2 === fixture.fixtureDate.year)) {
                    filteredFixtures.push(fixture);
                }
            });
        }
        else if (args.year !== undefined) {
            var year_3 = Number(args.year);
            fixtures.forEach(function (fixture) {
                if ((year_3 === fixture.fixtureDate.year)) {
                    filteredFixtures.push(fixture);
                }
            });
        }
        else {
            filteredFixtures = fixtures;
        }
        return filteredFixtures;
    };
    return RelevantFixturesPipe;
}());
export { RelevantFixturesPipe };
