/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./input-field.component";
import * as i3 from "../../../../../shared/api/api.service";
import * as i4 from "@angular/router";
var styles_InputFieldComponent = [];
var RenderType_InputFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputFieldComponent, data: {} });
export { RenderType_InputFieldComponent as RenderType_InputFieldComponent };
function View_InputFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "label", [["class", "amg-input-field-label"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLabel(); _ck(_v, 0, 0, currVal_0); }); }
function View_InputFieldComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "option", [], [[8, "value", 0], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.key; var currVal_1 = _v.context.$implicit.value; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_InputFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "select", [["class", "amg-select-field"]], [[8, "name", 0], [8, "required", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onSelectChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputFieldComponent_3)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getValues(); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getReferenceName(); var currVal_1 = _co.isRequired(); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_InputFieldComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "textarea", [["class", "amg-text-area"]], [[8, "value", 0], [8, "name", 0], [8, "required", 0], [8, "placeholder", 0]], [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; var currVal_1 = _co.getReferenceName(); var currVal_2 = _co.isRequired(); var currVal_3 = _co.getPlaceholder(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_InputFieldComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "input", [["class", "amg-input-field"]], [[8, "type", 0], [8, "value", 0], [8, "name", 0], [8, "required", 0], [8, "placeholder", 0]], [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getType(); var currVal_1 = _co.value; var currVal_2 = _co.getReferenceName(); var currVal_3 = _co.isRequired(); var currVal_4 = _co.getPlaceholder(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_InputFieldComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputFieldComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 7, null, null, null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i1.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputFieldComponent_2)), i0.ɵdid(5, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputFieldComponent_4)), i0.ɵdid(7, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputFieldComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgSwitchDefault, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLabelAvailable(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getType(); _ck(_v, 3, 0, currVal_1); var currVal_2 = "select"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "textarea"; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_InputFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-input-field", [], [[1, "class", 0]], null, null, View_InputFieldComponent_0, RenderType_InputFieldComponent)), i0.ɵdid(1, 245760, null, 0, i2.InputFieldComponent, [i3.ApiService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var InputFieldComponentNgFactory = i0.ɵccf("amg-wp-input-field", i2.InputFieldComponent, View_InputFieldComponent_Host_0, { inputField: "inputField", inputClearObservable: "inputClearObservable" }, { triggerChange: "triggerChange" }, []);
export { InputFieldComponentNgFactory as InputFieldComponentNgFactory };
