<ng-template [ngIf]="thumbnailUrl">
  <div class="amg-left">
    <div class="amg-playlist-thumbnail-wrapper">
      <ng-template [ngIf]="cardData.metaData.VideoDuration">
        <div class="amg-video-duration-overlay">
          {{secondsToHms(cardData.metaData.VideoDuration)}}
        </div>
      </ng-template>
      <div [ngStyle]="{backgroundImage: 'url('+thumbnailUrl+')'}"
           class="amg-fixture-thumbnail"></div>
      <div class="amg-thumbnail-overlay">
        <ng-template [ngIf]="playIcon()">
          <div [ngClass]="{'video-icon': videoIcon, 'audio-icon': audioIcon}"
               class="amg-play-btn"
               alt="Play icon">
          </div>
        </ng-template>
        <span class="amg-thumbnail-overlay-text"></span>
      </div>
    </div>
  </div>
</ng-template>
<div *ngIf="!thumbnailUrl"
     [ngStyle]="{
     border: 'solid 1px',
     width: '100%',
     height: '100%'
     }"></div>
