import { isDevMode } from '@angular/core';
import { AmgFixtureMediaData } from 'amg/lib/amg.fixture/amg.fixture.mediadata';
import { AmgFixtureIsLive } from 'amg/lib/amg.fixture/amg.fixture.islive';
import { switchMap } from 'rxjs/operators';
import { DateObject } from '../components/date/date';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AmgEvent } from 'amg/lib/amg.fixture/amg.event';
import { FixtureBase } from '../types/FixtureBase';
import { AmgFixture } from 'amg/lib/amg.fixture/amg.fixture';
import { AmgFixtureTeam } from 'amg/lib/amg.fixture/amg.fixture.team';

export class FixtureBaseComponent implements FixtureBase {
  public fixtureData: (AmgEvent | AmgFixture);

  protected fixtureMediaData: { video: AmgFixtureMediaData[]; audio: AmgFixtureMediaData[]; };

  private isFixtureLive: boolean;
  private isFixtureLiveSubject: BehaviorSubject<boolean>;
  private apiSubscriptions: { [entryId: string]: Subscription };
  private apiSubjectSubscription: Subscription;
  private apiSubject: Subject<{ entry: AmgFixtureMediaData; pollTime: number; }>;
  private fixtureDate: DateObject;

  private static generateMediaObject(mediaData: AmgFixtureMediaData): AmgFixtureMediaData {
    return {
      ...mediaData,
      isEntryCurrentlyLive: false
    };
  }

  private static logPollingData(entry: AmgFixtureMediaData, isLiveData: AmgFixtureIsLive): void {
    if (isDevMode()) {
      const isLiveString = isLiveData.isLive ? 'live' : 'not live';
      const pollingDurationInSeconds = isLiveData.pollingFrequency;

      console.log(
        `Polling success. '${entry.entryId}' is currently ${isLiveString}, and will poll in ${pollingDurationInSeconds} seconds.`);
    }
  }

  constructor(protected http: HttpClient) {
    this.isFixtureLive = false;
    this.isFixtureLiveSubject = new BehaviorSubject<boolean>(false);
    this.apiSubscriptions = {};
    this.apiSubject = new BehaviorSubject<{ entry: AmgFixtureMediaData; pollTime: number; }>(null);
  }

  getIsFixtureLive(): boolean {
    return this.isFixtureLive;
  }

  getFixtureDate(): DateObject {
    return this.fixtureDate;
  }

  getName(): string {
    return this.fixtureData.name;
  }

  getDescription(): string {
    return this.fixtureData.description;
  }

  getEventType(): string {
    return (this.fixtureData as AmgEvent).eventType
      ? (this.fixtureData as AmgEvent).eventType
      : '';
  }

  getDuration(): number {
    return (this.fixtureData as AmgFixture).duration
      ? Number.parseInt((this.fixtureData as AmgFixture).duration, 10) * 1000
      : -1;
  }

  getStadiumName(): string {
    return (this.fixtureData as AmgFixture).stadium
      ? (this.fixtureData as AmgFixture).stadium.name
      : '';
  }

  getAwayTeamData(): AmgFixtureTeam {
    return (this.fixtureData as AmgFixture).awayTeam
      ? (this.fixtureData as AmgFixture).awayTeam
      : {id: null, name: null, logo: null};
  }

  getHomeTeamData(): AmgFixtureTeam {
    return (this.fixtureData as AmgFixture).homeTeam
      ? (this.fixtureData as AmgFixture).homeTeam
      : {id: null, name: null, logo: null};
  }

  isThumbnailAvailable(): boolean {
    return this.fixtureData.thumbnail && this.fixtureData.thumbnail.length > 0;
  }

  getThumbnail(): string {
    return this.fixtureData.thumbnail;
  }

  getMediaData(): { video: AmgFixtureMediaData[]; audio: AmgFixtureMediaData[]; } {
    return this.fixtureMediaData;
  }

  protected init(): void {
    this.apiSubjectSubscription = this.apiSubject.subscribe(value => {
      if (value) {
        if (this.apiSubscriptions[value.entry.entryId]) {
          this.apiSubscriptions[value.entry.entryId].unsubscribe();
        }

        this.triggerApiCall(value.entry, value.pollTime);
      }
    });

    this.fixtureDate = new DateObject(this.fixtureData.startDate, false);
    this.fixtureMediaData = this.flattenMediaData(this.fixtureData.mediaData);
    this.triggerFixturePolling();
  }

  protected destroy(): void {
    this.apiSubjectSubscription.unsubscribe();

    Object.keys(this.apiSubscriptions).forEach(key => {
      this.apiSubscriptions[key].unsubscribe();
    });
  }

  protected triggerApiCall(entry: AmgFixtureMediaData, pollTime?: number): void {
    if (entry.isLiveUrl) {
      if (pollTime) {
        this.apiSubscriptions[entry.entryId] = timer(pollTime)
          .pipe(switchMap(() => this.http.get<AmgFixtureIsLive>(entry.isLiveUrl)))
          .subscribe(data => {
            FixtureBaseComponent.logPollingData(entry, data);
            this.setDataAndTriggerPolling(entry, data);
          });
      } else {
        this.apiSubscriptions[entry.entryId] = this.http.get<AmgFixtureIsLive>(entry.isLiveUrl).subscribe(data => {
          this.setDataAndTriggerPolling(entry, data);
        });
      }
    }
  }

  protected getIsAnyFixtureLiveObservable(): Observable<boolean> {
    return this.isFixtureLiveSubject.asObservable();
  }

  protected isAnyFixtureLive(): boolean {
    /**
     * Check >video< entries first,
     *  and then finish off with >audio<.
     */
    const entries: AmgFixtureMediaData[] = this.fixtureMediaData.video.filter(value => !!value.isEntryCurrentlyLive);

    if (entries.length !== 0) {
      return true;
    } else {
      return this.fixtureMediaData.audio.filter(value => !!value.isEntryCurrentlyLive).length > 0;
    }
  }

  private triggerFixturePolling(): void {
    this.triggerVideoFixturePolling();
    this.triggerAudioFixturePolling();
  }

  private triggerAudioFixturePolling(): void {
    this.fixtureMediaData.audio.forEach((value) => {
      this.triggerApiCall(value);
    });
  }

  private triggerVideoFixturePolling(): void {
    this.fixtureMediaData.video.forEach((value) => {
      this.triggerApiCall(value);
    });
  }

  private setDataAndTriggerPolling(entry: AmgFixtureMediaData, isLiveData: AmgFixtureIsLive): void {
    entry.isEntryCurrentlyLive = isLiveData.isLive;

    if (isLiveData.pollingFrequency > 0) {
      const pollTime = isLiveData.pollingFrequency * 1000;

      this.apiSubject.next({entry, pollTime});
    }

    this.isFixtureLive = this.isAnyFixtureLive();
    this.isFixtureLiveSubject.next(this.isFixtureLive);
  }

  private flattenMediaData(
    mediaData: (AmgFixtureMediaData | AmgFixtureMediaData[])): { video: AmgFixtureMediaData[]; audio: AmgFixtureMediaData[]; } {

    const flatMediaData: { video: AmgFixtureMediaData[]; audio: AmgFixtureMediaData[]; } = {
      video: [],
      audio: []
    };

    if (mediaData) {
      if (Array.isArray(mediaData)) {
        mediaData.forEach(value => {
          flatMediaData[value.mediaType].push(FixtureBaseComponent.generateMediaObject(value));
        });
      } else {
        flatMediaData[mediaData.mediaType].push(FixtureBaseComponent.generateMediaObject(mediaData));
      }
    }

    return flatMediaData;
  }
}
