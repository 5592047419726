import {
  Component,
  Input,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnInit,
  OnDestroy,
  AfterViewInit,
  QueryList,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ElementRef,
  HostListener,
} from '@angular/core';

import {
  Observable,
  Subscription,
} from 'rxjs';

import 'scroll-behavior-polyfill';

import {
  AmgComponent,
  BootstrapViewportEnum,
  BootstrapViewportService,
} from 'amg';

import {
  CarouselColumns,
  CarouselHover,
  CarouselInit,
  CarouselSlides,
  CarouselClick,
} from './carousel.functions/carousel.functions';

import { CarouselResponsiveProperties } from './carousel.responsive.properties';
import { CarouselResponsivePropertiesView } from './carousel.responsive.properties.view';
import { CarouselStaticProperties } from './carousel.static.properties';

@Component({
  selector: 'amg-carousel',
  templateUrl: './carousel.component.html',
  styles: [],
})
export class CarouselComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges, AmgComponent {
  @Input() componentData: Array<any>;
  @Input() responsiveProperties: CarouselResponsiveProperties = new CarouselResponsiveProperties();
  @Input() staticProperties: CarouselStaticProperties = new CarouselStaticProperties();

  @ViewChildren('componentChild', { read: ViewContainerRef }) componentChildren: QueryList<ViewContainerRef>;

  componentViewportSubscription: Subscription;

  componentCurrentView: CarouselResponsivePropertiesView;

  responsiveTimeout: any;

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<HTMLElement>;
  @ViewChildren('componentChild', { read: ElementRef }) public widgetsComponentChildren: QueryList<ElementRef<HTMLElement>>;

  scrollTimeout;

  @Output() cardClicked = new EventEmitter<any>();
  @Output() cardHovered = new EventEmitter<any>();
  @Output() cardSelected = new EventEmitter<any>();

  private _click: CarouselClick;
  private _columns: CarouselColumns;
  private _hover: CarouselHover;
  private _init: CarouselInit;
  private _slides: CarouselSlides;

  Math = Math;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private bootstrapViewportService: BootstrapViewportService
  ) {
  }

  private scrollTo(newSlideIndex: number) {
    const nativeElement = this.widgetsComponentChildren.toArray()[newSlideIndex].nativeElement;
    const hasWebkitOverflowScrolling: boolean = ((nativeElement.parentElement.parentElement.parentElement.style as any).webkitOverflowScrolling !== undefined);
    if (!hasWebkitOverflowScrolling) {
      const slideByPixels = (nativeElement.parentNode.parentElement as any).offsetLeft;
      requestAnimationFrame(() => {
        this.widgetsContent.nativeElement.scrollTo({
          left: slideByPixels,
          behavior: 'smooth',
        });
      });
    }
  }

  private getTouchEndIndex($event) {
    const scrollLeft = $event.target.parentNode.parentNode.parentNode.parentElement.scrollLeft;
    const slideWidth: number = (this.widgetsComponentChildren.first.nativeElement.parentNode.parentElement as any).offsetWidth;
    let newSlideIndex: number = Math.round(scrollLeft / slideWidth);
    if (newSlideIndex >= this.componentData.length) {
      newSlideIndex = Math.max(this.componentData.length - 1, 0);
    }
    return newSlideIndex;
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart($event) {
    const className: string = $event.target.className;
    if (!(className === 'prev' || className === 'next')) {
    }
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd($event) {
    const className: string = $event.target.className;
    if (!(className === 'prev' || className === 'next')) {
      if (this.scrollTimeout !== undefined) {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = undefined;
      }
      const scrollLeft = $event.target.parentNode.parentNode.parentNode.parentElement.scrollLeft;
      const slideWidth: number = (this.widgetsComponentChildren.first.nativeElement.parentNode.parentElement as any).offsetWidth;
      let newSlideIndex: number = Math.ceil(scrollLeft / slideWidth);
      if (newSlideIndex >= this.componentData.length) {
        this.scrollTo(this.componentData.length - 1);
      }
      this.scrollTimeout = setTimeout(() => {
        const newSlideIndex: number = this.getTouchEndIndex($event);
        this._slides.slideTo('onTouchEnd', newSlideIndex);
        this.scrollTo(newSlideIndex);
        this._columns.setCurrentSlide('onTouchEnd', newSlideIndex);

        if (this._lastSelected !== newSlideIndex) {
          this._lastSelected = newSlideIndex;
          this.cardSelected.emit(this.componentData[newSlideIndex]);
        }
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = undefined;
      }, 1000);
    }
  }

  ngOnInit() {
    let viewportSubscriber: Observable<BootstrapViewportEnum> = this.bootstrapViewportService
      .getViewportSubscriber()

    let viewport: BootstrapViewportEnum = this.bootstrapViewportService.getViewport();
    this.componentCurrentView = this.responsiveProperties[viewport] as CarouselResponsivePropertiesView;

    this._columns = new CarouselColumns(this.componentCurrentView);
    this._hover = new CarouselHover(this.cardHovered);
    this._click = new CarouselClick(this.cardClicked, this.componentCurrentView.startPosition);
    this._slides = new CarouselSlides(this.componentCurrentView, this.componentData.length);

    this.componentViewportSubscription = viewportSubscriber
      .subscribe(newBreakpoint => {
        if (this.responsiveTimeout) {
          clearTimeout(this.responsiveTimeout);
          this.responsiveTimeout = null;
        }

        this.responsiveTimeout = setTimeout(() => {
          this.componentCurrentView = this.responsiveProperties[newBreakpoint] as CarouselResponsivePropertiesView;
          this._columns.setCurrentView('resize', this.componentCurrentView);
          this._slides.setCurrentView('resize', this.componentCurrentView);
        }, this.componentCurrentView.refreshRate);
      });
  }
  ngOnDestroy() {
    this.componentViewportSubscription.unsubscribe();
  }
  ngAfterViewInit() {
    this._init = new CarouselInit(
      this.componentFactoryResolver,
      this.componentData,
      this.responsiveProperties,
      this.staticProperties,
      this.componentChildren,
    );
    this._init.ngAfterViewInit();
    this._slides = new CarouselSlides(this.componentCurrentView, this.componentData.length);

    this.componentChildren.changes.subscribe((item) => {
      if (this._init !== undefined) {
        this._init.ngOnChanges(
          this.componentData,
          this.responsiveProperties,
          this.staticProperties,
          this.componentChildren,
        );
      }
      this._slides = new CarouselSlides(this.componentCurrentView, this.componentData.length);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this._init !== undefined) {
      this._init.ngOnChanges(
        this.componentData,
        this.responsiveProperties,
        this.staticProperties,
        this.componentChildren,
      );
      this._slides = new CarouselSlides(this.componentCurrentView, this.componentData.length);
    }
  }

  getCurrentView(): CarouselResponsivePropertiesView {
    return this.componentCurrentView;
  }

  recalculateColumns(source: string): any {
    return this._columns.recalculateColumns(source);
  }

  getCol(i: number): any {
    return this._columns.getColumn(i, this._hover.getCurrentSlideHover('getCol'));
  }
  getDetails(): any {
    return this._columns.getDetailsColumn();
  }
  getCurrentSlide(): any {
    return this.componentData[this._slides.getCurrentSlide()];
  }
  getCurrentSlideProperty(property: string): any {
    const hovered = this._hover.getCurrentSlideHover();
    const current = this._slides.getCurrentSlide();
    const currentSlide = this.componentData[hovered] || this.componentData[current];
    const splitProperty = property.split('.');
    let nestedProperty = currentSlide;
    splitProperty.forEach((p) => {
      nestedProperty = nestedProperty[p];
    });
    return nestedProperty;
  }
  getExtraColumns(): Array<any> {
    let extraColumns: number = 1;
    if (this.sideBarIsActive()) {
      extraColumns = this.getVisibleSlideCount();
      extraColumns -= 1;
    }
    // make sure there's at least 1 extra column
    extraColumns = Math.max(1, extraColumns);
    extraColumns = Math.ceil(extraColumns);
    const toReturn = [].constructor(extraColumns);
    return toReturn;
  }

  dotIsActive(i: number): boolean {
    return this._slides.dotIsActive(i);
  }
  slideIsActive(i: number): boolean {
    return this._slides.slideIsActive(i);
  }
  sideBarIsActive(): boolean {
    return !(
      (this.staticProperties.carouselSidebar === undefined) ||
      (this.staticProperties.carouselSidebar.length === 0)
    );
  }

  getSlideNumber(): number {
    return this._slides.getCurrentSlide();
  }
  getSlideHover(): number {
    return this._hover.getCurrentSlideHover();
  }
  getVisibleSlideCount(): number {
    return this.componentCurrentView.limitViewableItems;
  }
  getVisibleSlideHoveredCount(): number {
    return this.componentCurrentView.visibleItemsOnHover;
  }

  showPrev(): boolean {
    return this.componentCurrentView.imitateInfinityLoop || (this._slides.getCurrentSlide() > 0);
  }
  showNext(): boolean {
    return (
      this.componentCurrentView.imitateInfinityLoop ||
      // show one empty tile at the end so the user does not click on the carousel by mistake
      (this._slides.getCurrentSlide() < (this.componentData.length - Math.ceil(this.componentCurrentView.limitViewableItems) + (this.sideBarIsActive() ? Math.ceil(this.componentCurrentView.limitViewableItems - 1) : 1)))
    );
  }

  private _lastSelected: any;

  beforeSlide(source: string) {
  }
  afterSlide(source: string) {
    const currentSlide: number = this._slides.getCurrentSlide();
    const nativeElement = this.widgetsComponentChildren.toArray()[currentSlide].nativeElement;
    const slideByPixels = (nativeElement.parentNode.parentElement as any).offsetLeft;
    requestAnimationFrame(() => {
      this.widgetsContent.nativeElement.scrollTo({
        left: slideByPixels,
        behavior: 'smooth',
      });
    });
    this._columns.setCurrentSlide(source, currentSlide);

    if (this._lastSelected !== currentSlide) {
      this._lastSelected = currentSlide;
      this.cardSelected.emit(this.componentData[currentSlide]);
    }
  }

  slideTo(source: string, n: number) {
    this.beforeSlide(source);
    this._slides.slideTo(source, n);
    this.afterSlide(source);
  }
  slidePrevious(source: string) {
    this.beforeSlide(source);
    this._slides.slidePrevious(source);
    this.afterSlide(source);
  }
  slideNext(source: string) {
    this.beforeSlide(source);
    this._slides.slideNext(source);
    this.afterSlide(source);
  }

  slideHover(source: string, i: number) {
    if (this.slideIsActive(i)) {
      this._hover.slideHover(source, i, this.componentData[i]);
    }
  }
  slideHoverOut(source: string, i: number) {
    this._hover.slideHoverOut(source, i);
  }

  slideClick(source: string, slide: any, index: number) {
    this._click.slideClick(source, slide, index);
  }

  isSlideSelected(index: number): boolean {
    return this._click.getLastClickedSlideIndex() === index;
  }
  getCurrentSelectedSlide(): number {
    return this._click.getLastClickedSlideIndex();
  }

  public onClickPrevious(source: string): void {
    this.slidePrevious(source);
  }

  public onClickNext(source: string): void {
    this.slideNext(source);
  }

}
