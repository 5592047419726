/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./templates/call-to-action/call-to-action.component.ngfactory";
import * as i2 from "./templates/call-to-action/call-to-action.component";
import * as i3 from "@angular/common";
import * as i4 from "./templates/form-header/form-header.component.ngfactory";
import * as i5 from "./templates/form-header/form-header.component";
import * as i6 from "../form-builder/form-builder.component.ngfactory";
import * as i7 from "../form-builder/form-builder.component";
import * as i8 from "@angular/forms";
import * as i9 from "../../user-auth.service";
import * as i10 from "./template-builder.component";
var styles_TemplateBuilderComponent = [];
var RenderType_TemplateBuilderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TemplateBuilderComponent, data: {} });
export { RenderType_TemplateBuilderComponent as RenderType_TemplateBuilderComponent };
function View_TemplateBuilderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-call-to-action", [], null, null, null, i1.View_CallToActionComponent_0, i1.RenderType_CallToActionComponent)), i0.ɵdid(1, 114688, null, 0, i2.CallToActionComponent, [], { callToAction: [0, "callToAction"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TemplateBuilderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateBuilderComponent_3)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.links; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TemplateBuilderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "amg-template"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "amg-form-header", [], null, null, null, i4.View_FormHeaderComponent_0, i4.RenderType_FormHeaderComponent)), i0.ɵdid(2, 114688, null, 0, i5.FormHeaderComponent, [], { formHeader: [0, "formHeader"], modalRef: [1, "modalRef"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "amg-form-builder", [], null, null, null, i6.View_FormBuilderComponent_0, i6.RenderType_FormBuilderComponent)), i0.ɵdid(4, 114688, null, 0, i7.FormBuilderComponent, [i8.FormBuilder, i9.UserAuthService], { formData: [0, "formData"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "amg-call-to-action", [], null, null, null, i1.View_CallToActionComponent_0, i1.RenderType_CallToActionComponent)), i0.ɵdid(6, 114688, null, 0, i2.CallToActionComponent, [], { callToAction: [0, "callToAction"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateBuilderComponent_2)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logo; var currVal_1 = _co.modalRef; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.form; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.dataObj; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.links; _ck(_v, 8, 0, currVal_4); }, null); }
export function View_TemplateBuilderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateBuilderComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataObj; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TemplateBuilderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-template-builder", [], null, null, null, View_TemplateBuilderComponent_0, RenderType_TemplateBuilderComponent)), i0.ɵdid(1, 114688, null, 0, i10.TemplateBuilderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TemplateBuilderComponentNgFactory = i0.ɵccf("amg-template-builder", i10.TemplateBuilderComponent, View_TemplateBuilderComponent_Host_0, { dataObj: "dataObj", modalRef: "modalRef" }, {}, []);
export { TemplateBuilderComponentNgFactory as TemplateBuilderComponentNgFactory };
