import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentProjection } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-content-projection',
  templateUrl: './content-projection.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ContentProjectionComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-content-projection';
  @Input() contentProjection: ContentProjection;

  ngOnInit(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }
  }

  getCss(): string {
    return this.contentProjection.cssClass;
  }
}
