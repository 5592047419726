import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Icon } from 'wordpress-adapter';
var IconComponent = /** @class */ (function () {
    function IconComponent(document, router) {
        this.document = document;
        this.router = router;
        this.class = 'amg-icon';
    }
    IconComponent.prototype.onIconClick = function () {
        if (this.icon.link) {
            this.document.location.href = this.icon.link;
        }
        else if (this.icon.relativeLink) {
            this.router.navigate([this.icon.relativeLink], {
                preserveQueryParams: false
            });
        }
    };
    IconComponent.prototype.ngOnInit = function () {
        if (this.icon.link) {
            this.class = this.class.concat(' amg-icon-hover');
        }
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
    };
    IconComponent.prototype.getDefaultImage = function () {
        return this.icon.imageData.sizes.medium_large;
    };
    IconComponent.prototype.getRemainingImages = function () {
        var images = this.icon.imageData.sizes;
        var mediumImage = images.medium + " " + images['medium-width'] + "w", mediumLargeImage = images.medium_large + " " + images['medium_large-width'] + "w", largeImage = images.large + " " + images['large-width'] + "w", originalImage = this.icon.imageData.url + " " + this.icon.imageData.width + "w";
        return mediumImage + ", " + mediumLargeImage + ", " + largeImage + ", " + originalImage;
    };
    IconComponent.prototype.getAltText = function () {
        return this.icon.imageData.alt;
    };
    IconComponent.prototype.getCss = function () {
        return this.icon.cssClass;
    };
    return IconComponent;
}());
export { IconComponent };
