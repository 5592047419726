/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../components/cards/src/lib/modules/video-card/video-card.component.ngfactory";
import * as i2 from "../../../../../../../../../components/cards/src/lib/modules/video-card/video-card.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./anywhere-video-card.component";
import * as i6 from "@angular/common/http";
import * as i7 from "../../../../../shared/data/data.service";
import * as i8 from "@angular/router";
var styles_AnywhereVideoCardComponent = [];
var RenderType_AnywhereVideoCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AnywhereVideoCardComponent, data: {} });
export { RenderType_AnywhereVideoCardComponent as RenderType_AnywhereVideoCardComponent };
function View_AnywhereVideoCardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-video-card", [], null, [[null, "triggerClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("triggerClick" === en)) {
        var pd_0 = (_co.onCardClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_VideoCardComponent_0, i1.RenderType_VideoCardComponent)), i0.ɵdid(1, 114688, null, 0, i2.VideoCardComponent, [i3.DomSanitizer], { cardData: [0, "cardData"], cardConfiguration: [1, "cardConfiguration"] }, { triggerClick: "triggerClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardData; var currVal_1 = _co.videoCard.anywhereDisplayKeys; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AnywhereVideoCardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AnywhereVideoCardComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AnywhereVideoCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-anywhere-video-card", [], [[1, "class", 0]], null, null, View_AnywhereVideoCardComponent_0, RenderType_AnywhereVideoCardComponent)), i0.ɵdid(1, 245760, null, 0, i5.AnywhereVideoCardComponent, [i6.HttpClient, i7.DataService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var AnywhereVideoCardComponentNgFactory = i0.ɵccf("amg-wp-anywhere-video-card", i5.AnywhereVideoCardComponent, View_AnywhereVideoCardComponent_Host_0, { videoCard: "videoCard" }, {}, []);
export { AnywhereVideoCardComponentNgFactory as AnywhereVideoCardComponentNgFactory };
