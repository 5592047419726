/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../../../node_modules/ng2-flatpickr/ng2-flatpickr.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "ng2-flatpickr";
import * as i5 from "./filter-date.component";
var styles_FilterDateComponent = [];
var RenderType_FilterDateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilterDateComponent, data: {} });
export { RenderType_FilterDateComponent as RenderType_FilterDateComponent };
function View_FilterDateComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "label", [["class", "grid-filter-title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFilterLabel(); _ck(_v, 0, 0, currVal_0); }); }
export function View_FilterDateComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { flatpickrComponentRef: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterDateComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "ng2-flatpickr", [], null, null, null, i2.View_Ng2FlatpickrComponent_0, i2.RenderType_Ng2FlatpickrComponent)), i0.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.Ng2FlatpickrComponent]), i0.ɵdid(5, 4767744, [[1, 4], ["flatpickrComponent", 4]], 0, i4.Ng2FlatpickrComponent, [], { config: [0, "config"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "default-label"]], [[8, "textContent", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFilterLabel(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getDateFilterProperties(); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getDefaultLabel(); _ck(_v, 6, 0, currVal_2); }); }
export function View_FilterDateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-filter-date", [], [[1, "class", 0]], null, null, View_FilterDateComponent_0, RenderType_FilterDateComponent)), i0.ɵdid(1, 245760, null, 0, i5.FilterDateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var FilterDateComponentNgFactory = i0.ɵccf("amg-filter-date", i5.FilterDateComponent, View_FilterDateComponent_Host_0, { dateFilterProperties: "dateFilterProperties", resetFilterObservable: "resetFilterObservable", resetFilterInternalObservable: "resetFilterInternalObservable" }, { triggerFilter: "triggerFilter" }, []);
export { FilterDateComponentNgFactory as FilterDateComponentNgFactory };
