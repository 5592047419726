import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UrlUtils } from 'amg-fe-utils';
import { KalturaPlayerEvents } from '../_data/video-player.model';
import { UserAuthService } from 'user-auth';
import { VideoPlayerService } from '../video-player.service';
var VideoPlayerComponent = /** @class */ (function () {
    function VideoPlayerComponent(videoPlayerService, renderer, userAuthService, changeDetectorRef) {
        this.videoPlayerService = videoPlayerService;
        this.renderer = renderer;
        this.userAuthService = userAuthService;
        this.changeDetectorRef = changeDetectorRef;
        this.playlistDisabled = false;
        this.kalturaScriptLoaded = false;
        this.showThumbnail = true;
        this.triggerPlaylistReady = new EventEmitter();
        this.triggerEntryChange = new EventEmitter();
        this.LEEDS_DRM_ENTRY_ID = '0_nirm0trd';
        this.LEEDS_DRM_UI_CONF = '30027332';
        this.FULHAM_DRM_ENTRY_ID = '0_p0qmnyl5';
        this.PLAYER_ID = "kaltura_player_default";
        this.playerEmbeded = false;
        this._apiConfigsLoaded = false;
        this._entryId = '';
        this.currentPlaylistSubscription = null;
        //console.log('Video player initialized ');
    }
    Object.defineProperty(VideoPlayerComponent.prototype, "playerLock", {
        set: function (lock) {
            this.lockPlayer = lock;
            this.allowAutoHeight = (!lock);
            this.changeDetectorRef.detectChanges();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoPlayerComponent.prototype, "setAllowAutoHeight", {
        set: function (allow) {
            this.allowAutoHeight = allow;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoPlayerComponent.prototype, "apiConfigs", {
        /**
         * Provide Anywhere Api Configuration data and dispatch to observer;
         * Set kaltura player configuration for local use within component;
         */
        set: function (configs) {
            if (!this.playerEmbeded && !this._apiConfigsLoaded) {
                if (configs) {
                    if (configs.kalturaPlayerConfig) {
                        this.kalturaPlayerConfig = configs.kalturaPlayerConfig;
                        this.kalturaConfigClean = configs.kalturaPlayerConfig;
                        this.findEntryToPlay();
                        if (configs.kalturaPlayerConfig.entryId) {
                            this.initializePlayer();
                        }
                    }
                    this._apiConfigsLoaded = true;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoPlayerComponent.prototype, "entryId", {
        set: function (entryId) {
            var entryFromUrl = this.findEntryInURL();
            entryId = (entryId)
                // from input
                ? entryId
                : (entryFromUrl)
                    // from url
                    ? entryFromUrl
                    : this.kalturaPlayerConfig.entryId
                        // from service player config inject
                        ? this.kalturaPlayerConfig.entryId
                        : null;
            if (entryId) {
                this.videoPlayerService.setEntryAndKSBSubject(entryId);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoPlayerComponent.prototype, "liveLoginOverlay", {
        set: function (overlay) {
            if (overlay) {
                this.videoPlayerService.liveLoginOverlayBSubject.next(overlay);
                this.localOverlayData = overlay;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoPlayerComponent.prototype, "anywhereData", {
        /**
         * Fetch scripts required to run video player if not already Initialized;
         *
         */
        set: function (anywhereData) {
            if (anywhereData) {
                this.videoPlayerService.amgLogger('***set AnywhereData: ', anywhereData);
                this.findPlaylist(anywhereData);
                if (!this.playlistUrl) {
                    this.setAnywhereData(anywhereData);
                }
                else {
                    anywhereData.playlist_url = this.playlistUrl;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    VideoPlayerComponent.prototype.ngOnInit = function () {
        var _this_1 = this;
        this.collectUrlData();
        this.allowAutoHeight = true;
        this.playlistDisabled = true;
        this.playerLock = window.location.href.includes('/vod');
        this.autoplay = JSON.parse(localStorage.getItem(VideoPlayerComponent.autoplayStorageKey));
        this.videoPlayerService.userData
            .subscribe(function (userData) {
            if (userData) {
                _this_1.userDataLocalRef = userData;
            }
        });
        /**
         * Watch and dispatch Kaltura player Events;
         */
        this.videoPlayerStateBSubscription = this.videoPlayerService.getVideoPlayerStateBSubject()
            .subscribe(function (videoPlayerState) {
            var _this = _this_1;
            if (videoPlayerState) {
                switch (videoPlayerState.event) {
                    case 'onChangeMedia':
                        _this_1.videoPlayerService.allowChangeMediaBSubject.next(false);
                        _this_1.getKDP().setKDPAttribute('servicesProxy.kalturaClient', 'ks', _this_1.videoPlayerService.entryObjLocalRef.ks);
                        setTimeout(function () {
                            _this.videoPlayerService.allowChangeMediaBSubject.next(true);
                        }, 3000);
                        break;
                    case 'playerPlayEnd':
                        if (_this_1.autoplay) {
                            _this_1.setAnywhereData(_this_1.videoPlayerService.findAnywhereItems(_this_1.playlistDataRef)[_this_1.findNextToPlay()]);
                        }
                        break;
                    case 'playerReady':
                        if (_this_1.kalturaPlayerConfig.entryId
                            && ((_this_1.FULHAM_DRM_ENTRY_ID === _this_1.kalturaPlayerConfig.entryId)
                                || (_this_1.LEEDS_DRM_ENTRY_ID === _this_1.kalturaPlayerConfig.entryId))) {
                            _this_1.sendKDPNotification(_this_1.kdp, 'izsession', _this_1.getCustomerId());
                        }
                        _this_1.toggleThumbnail(false);
                        _this_1.videoPlayerService.allowChangeMediaBSubject.next(true);
                        break;
                    default:
                        break;
                }
            }
        });
        if (this.playlistUrl) {
            this.currentPlaylistSubscription = this.videoPlayerService
                .fetchPlaylistData(this.playlistUrl)
                .subscribe(function (res) {
                if (res) {
                    _this_1.playlistDataRef = res;
                    var matchedEntry = _this_1.matchEntryToPlaylist();
                    _this_1.currentPlaylistSubscription.unsubscribe();
                    if (matchedEntry) {
                        var selectedEntryId = matchedEntry.data.mediaData.entryId;
                        if (_this_1._entryId.localeCompare(selectedEntryId) !== 0) {
                            _this_1.setAnywhereData(matchedEntry.data);
                        }
                    }
                    else if (_this_1.videoPlayerService.entryObjLocalRef.entryId) {
                        var separator = _this_1.playlistUrl.indexOf('?') === -1 ? '?' : '&';
                        var url = "" + _this_1.playlistUrl + separator + "query=(mediaData.entryId:(" + _this_1.videoPlayerService.entryObjLocalRef.entryId + "))";
                        _this_1.currentPlaylistSubscription = _this_1.videoPlayerService
                            .fetchPlaylistData(url)
                            .subscribe(function (res2) {
                            if (res2) {
                                var item = _this_1.videoPlayerService.findAnywhereItems(res2);
                                _this_1.setAnywhereData(item[0]);
                                _this_1.currentPlaylistSubscription.unsubscribe();
                            }
                        });
                    }
                }
            });
        }
        else if (this.playlistData) {
            // @ts-ignore
            this.playlistDataRef = JSON.parse(this.playlistData);
        }
        this.videoPlayerService
            .autoplayBSubject
            .subscribe(function (val) {
            _this_1.autoplay = val;
        });
    };
    VideoPlayerComponent.prototype.fetchNextPlaylistPage = function () {
        var _this_1 = this;
        if (this.playlistUrl && this.playlistDataRef && this.currentPlaylistSubscription == null) {
            var currentSection_1 = this.playlistDataRef.sections[0] || this.playlistDataRef;
            var currentPagingData_1 = currentSection_1.pagingData;
            var nextPageIndex = currentPagingData_1.pageIndex + 1;
            var parameters = UrlUtils.extractUrlParamsFromExistingUrl(this.playlistUrl);
            parameters.pageIndex = nextPageIndex.toString();
            var playlistUrl = UrlUtils.removeQueryParameters(this.playlistUrl) + '?' + UrlUtils.generateUrlParameters(parameters);
            this.currentPlaylistSubscription = this.videoPlayerService
                .fetchPlaylistData(playlistUrl)
                .subscribe(function (res) {
                if (res) {
                    var newSection = res.sections[0] || res;
                    var pagingData = newSection.pagingData;
                    var pageIndex = pagingData.pageIndex;
                    var pageSize = pagingData.pageSize;
                    var base_1 = pageIndex * pageSize;
                    newSection.itemData
                        .forEach(function (item, i) {
                        currentSection_1.itemData[base_1 + i] = item;
                    });
                    currentPagingData_1.pageIndex = (currentSection_1.itemData.length / pageSize) - 1;
                    _this_1.playlistDataRef = tslib_1.__assign({}, _this_1.playlistDataRef);
                    _this_1.currentPlaylistSubscription.unsubscribe();
                    _this_1.currentPlaylistSubscription = null;
                }
            });
        }
    };
    VideoPlayerComponent.prototype.collectUrlData = function () {
        var url = window.location.href;
        var pageRegex = /\/(.*?)((#)|(\?)|(\/)|($))/g;
        var urlData = {
            pageRoute: url.match(pageRegex)
        };
        return urlData;
    };
    VideoPlayerComponent.prototype.findEntryToPlay = function () {
        var entry = this.findEntryInURL();
        if (entry) {
            if (entry === this.LEEDS_DRM_ENTRY_ID) {
                this.kalturaPlayerConfig.uiConfId = this.LEEDS_DRM_UI_CONF;
            }
            else {
                this.kalturaPlayerConfig = this.kalturaConfigClean;
            }
        }
    };
    VideoPlayerComponent.prototype.onKalturaScriptReady = function (script) {
        var _this_1 = this;
        this.entryAndKsSubscription = this.videoPlayerService
            .getEntryAndKSBSubject()
            .subscribe(function (entryAndKs) {
            if (entryAndKs && entryAndKs.entryId) {
                if (entryAndKs.ks) {
                    _this_1.videoPlayerService.amgLogger('onKalturaScriptReady -> getEntryAndKSBSubject: ', entryAndKs);
                    _this_1.entryAndKSRef = entryAndKs;
                    if (_this_1.kdp && _this_1.playerEmbeded) {
                        if (entryAndKs.entryId && entryAndKs.ks) {
                            _this_1.initiateChangeMedia(entryAndKs.entryId);
                        }
                        else if (_this_1.videoPlayerService.entryObjLocalRef && _this_1.videoPlayerService.entryObjLocalRef.entryId) {
                            _this_1.initiateChangeMedia(_this_1.videoPlayerService.entryObjLocalRef.entryId);
                        }
                    }
                    else if (script && script.loaded && !_this_1.playerEmbeded) {
                        _this_1.embedVideoPlayer();
                    }
                }
                else {
                    _this_1.toggleThumbnail(true);
                    _this_1.sendKDPNotification(_this_1.kdp, 'doPause');
                    _this_1.entryAndKSRef = { entryId: entryAndKs.entryId, ks: null };
                }
            }
        });
    };
    VideoPlayerComponent.prototype.findEntryInURL = function () {
        var url = window.location.href;
        var entryStartIndex = url.indexOf('entry=');
        var entryId = '';
        if (entryStartIndex !== -1) {
            var entry = url.slice(entryStartIndex + 6, entryStartIndex + 16);
            if (entry) {
                entryId = entry;
            }
        }
        return entryId;
    };
    VideoPlayerComponent.prototype.ngOnDestroy = function () {
        this.clearUiData();
        if (this.currentPlaylistSubscription) {
            this.currentPlaylistSubscription.unsubscribe();
        }
    };
    VideoPlayerComponent.prototype.findPlaylist = function (data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                if (key === 'playlist_url') {
                    this.playlistUrl = data[key];
                }
                else if (typeof data[key] === 'string') {
                }
                else if (data[key] !== null && data[key] !== '') {
                    this.findPlaylist(data[key]);
                }
                else {
                    return false;
                }
            }
        }
    };
    VideoPlayerComponent.prototype.matchEntryToPlaylist = function () {
        var _this_1 = this;
        var itemData = this.videoPlayerService.findAnywhereItems(this.playlistDataRef);
        var entryData = null;
        if (itemData) {
            if (this.videoPlayerService.entryObjLocalRef && !this.videoPlayerService.entryObjLocalRef.entryId) {
                entryData = { index: 0, data: itemData[0] };
            }
            itemData.forEach(function (videoItem, index) {
                var foundEntry = _this_1.videoPlayerService.findEntryInMediaData(null, videoItem.mediaData);
                if (foundEntry && _this_1.videoPlayerService.entryObjLocalRef.entryId === foundEntry.entryId) {
                    entryData = { index: index, data: videoItem };
                }
            });
        }
        return entryData;
    };
    VideoPlayerComponent.prototype.clickEntry = function (data) {
        this.triggerEntryChange.emit(data ? data.mediaData.entryId : '');
        this.setAnywhereData(data);
        this.videoPlayerService.changeMediaMessage(data);
        this.kdp.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
        });
    };
    VideoPlayerComponent.prototype.setAnywhereData = function (anywhereData) {
        if (anywhereData) {
            if (!this.playerEmbeded) {
                this.initializePlayer();
            }
            this.setUiData(anywhereData);
        }
    };
    VideoPlayerComponent.prototype.setUiData = function (anywhereData) {
        if (anywhereData) {
            this.toggleThumbnail(true);
            this.kdp = this.getKDP();
            this.videoPlayerService.setAnywhereMediaDataBSubject(anywhereData);
            this.localAnywhereMediaData = anywhereData;
        }
        else {
            console.warn('Anywhere media data is not Provided: @Input() public set anywhereData');
        }
    };
    VideoPlayerComponent.prototype.clearUiData = function () {
        this.changeDetectorRef.detach();
        if (this.entryAndKsSubscription) {
            this.entryAndKsSubscription.unsubscribe();
        }
        if (this.videoPlayerStateBSubscription) {
            this.videoPlayerStateBSubscription.unsubscribe();
        }
        this.videoPlayerService.entryObjLocalRef = { entryId: null, ks: null };
        this.localAnywhereMediaData = null;
        this.playlistDataRef = null;
        this.playerEmbeded = false;
    };
    /**
     * For not free entries:
     * click on play
     */
    VideoPlayerComponent.prototype.onClickThumbnail = function (e) {
        e.preventDefault();
        if (this.videoPlayerService.canUserPlayMedia()) {
            this.sendKDPNotification(this.kdp, 'doPlay');
        }
    };
    VideoPlayerComponent.prototype.sendKDPNotification = function (kdp, eventName, data) {
        this.videoPlayerService.amgLogger('sendKDPNotification: ', data);
        if (!kdp) {
            kdp = this.kdp;
        }
        if (kdp && kdp.sendNotification) {
            kdp.sendNotification(eventName, data);
        }
    };
    VideoPlayerComponent.prototype.getCustomerId = function () {
        var data = this.userDataLocalRef;
        if (data) {
            var session = data.CurrentCustomerSession;
            return (data && session && session.CustomerId) ? session.CustomerId : false;
        }
        return false;
    };
    VideoPlayerComponent.prototype.playlistReady = function (value) {
        this.triggerPlaylistReady.emit(value);
    };
    /**
     * Set player ID;
     * Fetch Kaltura video player entry script;
     */
    VideoPlayerComponent.prototype.initializePlayer = function () {
        var _this_1 = this;
        var _this = this;
        var playerConfig = this.kalturaPlayerConfig;
        if (!playerConfig.playerId) {
            playerConfig.playerId = this.PLAYER_ID;
        }
        this.videoPlayerService.getKalturaApi(playerConfig);
        if (!_this.kalturaScriptLoaded) {
            this.videoPlayerService.load('kalturaApi')
                .then(function (data) {
                _this_1.videoPlayerService.amgLogger('load kalturaApi: switchScreens: ', _this.kalturaScriptLoaded);
                _this.kalturaScriptLoaded = true;
                _this_1.onKalturaScriptReady(data[0]);
            })
                .catch(function (error) {
                console.log(error);
            });
        }
    };
    VideoPlayerComponent.prototype.initiateChangeMedia = function (entryId) {
        this.videoPlayerService.amgLogger('initiateChangeMedia: ', entryId);
        this.kdp = this.getKDP();
        this.sendKDPNotification(this.kdp, 'changeMedia', { entryId: entryId });
    };
    VideoPlayerComponent.prototype.embedVideoPlayer = function () {
        var mediaObject = this.buildMediaEmbedObject();
        if (this.findEntryInURL() === this.LEEDS_DRM_ENTRY_ID) {
            var customerId = this.getCustomerId();
            mediaObject.uiconf_id = this.LEEDS_DRM_UI_CONF;
            if (customerId) {
                mediaObject.flashvars = tslib_1.__assign({}, mediaObject.flashvars, { izsession: this.getCustomerId() });
            }
        }
        if (this.entryAndKSRef && this.entryAndKSRef.ks) {
            mediaObject.flashvars.ks = this.entryAndKSRef.ks;
            if (!mediaObject.entry_id) {
                delete mediaObject.entry_id;
            }
            window.kWidget.embed(mediaObject);
            this.playerReady();
            this.playerEmbeded = true;
        }
    };
    VideoPlayerComponent.prototype.buildMediaEmbedObject = function () {
        var playerConfig = this.kalturaPlayerConfig;
        return {
            targetId: playerConfig.playerId,
            wid: '_' + playerConfig.partnerId,
            uiconf_id: playerConfig.uiConfId,
            entry_id: this.entryAndKSRef.entryId || playerConfig.entryId,
            flashvars: (playerConfig.flashvars) ? playerConfig.flashvars : {}
        };
    };
    VideoPlayerComponent.prototype.playerReady = function () {
        var _this = this;
        window.kWidget.addReadyCallback(function (playerId) {
            _this.kdp = _this.getKDP();
            _this.initializePlayerEventListeners();
        });
    };
    VideoPlayerComponent.prototype.findNextToPlay = function () {
        var entryMatch = this.matchEntryToPlaylist();
        if (!entryMatch) {
            return 0;
        }
        return (entryMatch.index || entryMatch.index === 0) ? entryMatch.index + 1 : 0;
    };
    VideoPlayerComponent.prototype.getKDP = function () {
        var playerId = this.kalturaPlayerConfig.playerId;
        return document.getElementById(playerId ? playerId : this.PLAYER_ID);
    };
    VideoPlayerComponent.prototype.initializePlayerEventListeners = function () {
        var _this_1 = this;
        var _this = this;
        if (this.kdp.addJsListener) {
            _this.kdp.addJsListener('doPlay', function (res) {
                _this.toggleThumbnail(false);
            });
            var _loop_1 = function (key) {
                this_1.kdp.kBind(KalturaPlayerEvents[key], function (res) {
                    var data = { event: KalturaPlayerEvents[key], data: res };
                    _this_1.videoPlayerService.setVideoPlayerStateBSubject(data);
                });
            };
            var this_1 = this;
            for (var _i = 0, _a = Object.keys(KalturaPlayerEvents); _i < _a.length; _i++) {
                var key = _a[_i];
                _loop_1(key);
            }
        }
    };
    VideoPlayerComponent.prototype.toggleThumbnail = function (show) {
        this.showThumbnail = show;
        this.changeDetectorRef.detectChanges();
    };
    VideoPlayerComponent.autoplayStorageKey = 'AMG-AUTOPLAY';
    return VideoPlayerComponent;
}());
export { VideoPlayerComponent };
