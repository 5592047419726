import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AnywhereData, AnywherePagingData, AnywhereResponse, SectionData } from 'amg-fe-types';
import { AnywhereUtils } from 'amg-fe-utils';
import { Subscription } from 'rxjs';
import { CarouselFlickity } from 'wordpress-adapter';
import { DataService } from '../../../../../shared/data/data.service';

@Component({
  selector: 'amg-wp-carousel-flickity',
  templateUrl: './carousel-flickity.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CarouselFlickityComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-carousel-flickity';
  @Input() carousel: CarouselFlickity;

  public carouselData: any[];
  public isMoreDataAvailable: boolean;
  public apiCallTriggered: boolean;

  public isLoading: boolean;
  public isError: boolean;

  private pagingData: AnywherePagingData;
  private apiUrl: string;

  private apiDataObservable: Subscription;

  constructor(private httpClient: HttpClient,
              private dataService: DataService,
              private router: Router) {
    this.isLoading = true;
    this.isMoreDataAvailable = true;
    this.apiCallTriggered = false;
    this.isError = false;
  }

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.initialiseCarousel();
    this.triggerApiCall(this.apiUrl, true);
  }

  ngOnDestroy(): void {
    if (this.apiDataObservable) {
      this.apiDataObservable.unsubscribe();
    }
  }

  onCardClick(data: any) {
    if (this.carousel.onClickNavigate) {
      const playlistUrl = AnywhereUtils.generateAnywhereUrl(this.apiUrl, {
        ...this.pagingData,
        pageIndex: this.pagingData.pageIndex - 1
      });
      const url = this.dataService.encodeUrl(playlistUrl);
      const entryId = (data && data.mediaData && data.mediaData.entryId)
        ? data.mediaData.entryId
        : null;

      this.dataService.userInteractedObject = {
        playlistUrl,
        data
      };

      this.router.navigate([this.carousel.onClickNavigate], {
        queryParams: {
          playlist: url,
          ...(entryId ? {entry: entryId} : {})
        },
        preserveQueryParams: false
      });
    }
  }

  onTriggerLoadMore(): void {
    if (this.isMoreDataAvailable) {
      const url = AnywhereUtils.generateAnywhereUrl(this.apiUrl, this.pagingData);
      this.triggerApiCall(url);
    }
  }

  getCss(): string {
    return this.carousel.cssClass;
  }

  private initialiseCarousel(): void {
    this.carouselData = [];
    this.apiUrl = this.carousel.carousalAnywhereApiUrl;
  }

  private triggerApiCall(apiUrl: string, isInit?: boolean): void {
    if (this.apiDataObservable) {
      this.apiDataObservable.unsubscribe();
    }

    this.apiDataObservable = this.httpClient.get<AnywhereResponse<any>>(apiUrl).subscribe(data => {
      if (data) {
        const extractedData: AnywhereData<any> | SectionData<any> = AnywhereUtils.extractAnywhereData(data);
        this.carouselData = this.carouselData.length > 0
          ? [ ...this.carouselData, ...extractedData.itemData ]
          : extractedData.itemData;

        if (isInit && this.carousel.carouselStaticData.skipFirstEntry && this.carouselData.length) {
          this.carouselData = this.carouselData.slice(1);
        }

        this.pagingData = extractedData.pagingData;
        this.isMoreDataAvailable = AnywhereUtils.isMoreDataAvailable(extractedData.pagingData);
      } else {
        this.isMoreDataAvailable = false;
        this.pagingData = null;
      }

      this.apiCallTriggered = false;
      this.isLoading = false;
    }, error => {
      this.isMoreDataAvailable = false;
      this.apiCallTriggered = false;
      this.isLoading = false;
      this.isError = true;
    });

    this.apiCallTriggered = true;
  }

}
