import * as tslib_1 from "tslib";
import { NgObject } from './NgObject';
var LiveFixtureCard = /** @class */ (function (_super) {
    tslib_1.__extends(LiveFixtureCard, _super);
    function LiveFixtureCard(data, objectType) {
        if (objectType === void 0) { objectType = 'LiveFixtureCard'; }
        var _this = _super.call(this, objectType, data) || this;
        _this._fixtureApiUrl = data.fixture_api_url;
        return _this;
    }
    Object.defineProperty(LiveFixtureCard.prototype, "fixtureApiUrl", {
        get: function () {
            return this._fixtureApiUrl;
        },
        enumerable: true,
        configurable: true
    });
    return LiveFixtureCard;
}(NgObject));
export { LiveFixtureCard };
