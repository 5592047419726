import { OnInit } from '@angular/core';
import { SocialLinks } from 'wordpress-adapter';
var SocialLinksComponent = /** @class */ (function () {
    function SocialLinksComponent() {
        this.class = 'amg-social-links';
    }
    SocialLinksComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
    };
    SocialLinksComponent.prototype.getSocialLinks = function () {
        return this.socialLinks.socialLinks;
    };
    SocialLinksComponent.prototype.getCss = function () {
        return this.socialLinks.cssClass;
    };
    return SocialLinksComponent;
}());
export { SocialLinksComponent };
