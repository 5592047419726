import { AmgComponentResponsiveProperties } from 'amg';

import { CardFixtureResponsiveProperties, CardFixtureResponsivePropertiesView } from 'card-fixture';
import { CardImageResponsiveProperties, CardImageResponsivePropertiesView } from 'card-image';
import { CardProfileResponsiveProperties, CardProfileResponsivePropertiesView } from 'card-profile';
import { CardTextResponsiveProperties, CardTextResponsivePropertiesView } from 'card-text';
import { CardVideoResponsiveProperties, CardVideoResponsivePropertiesView } from 'card-video';

import { CardType } from './card.type';

export class CardResponsiveInstantiate {

  /**
   * Returns an instanciated card based on the given parameters
   * @param cardType Enum value of card type
   * @param data data associated with the card
   * @param route route that the card is supposed to follow if the default action is triggered
   * @param params additional parameters for the route
   * @param responsive responsive properties for the card
   */
  public static instantiate(
    cardType: CardType,
    params: AmgComponentResponsiveProperties,
  ): AmgComponentResponsiveProperties {
    switch (cardType) {
      case (CardType.CalendarEvent):
      case (CardType.CalendarFixture):
      case (CardType.Event):
      case (CardType.Fixture): {
        return new CardFixtureResponsiveProperties({
          xs: this.cast((params.xs as CardFixtureResponsivePropertiesView)),
          sm: this.cast((params.sm as CardFixtureResponsivePropertiesView)),
          md: this.cast((params.md as CardFixtureResponsivePropertiesView)),
          lg: this.cast((params.lg as CardFixtureResponsivePropertiesView)),
          xl: this.cast((params.xl as CardFixtureResponsivePropertiesView)),
        });
      }
      case (CardType.Image): {
        return new CardImageResponsiveProperties({
          xs: this.cast((params.xs as CardImageResponsivePropertiesView)),
          sm: this.cast((params.sm as CardImageResponsivePropertiesView)),
          md: this.cast((params.md as CardImageResponsivePropertiesView)),
          lg: this.cast((params.lg as CardImageResponsivePropertiesView)),
          xl: this.cast((params.xl as CardImageResponsivePropertiesView)),
        });
      }
      case (CardType.Profile): {
        return new CardProfileResponsiveProperties({
          xs: this.cast((params.xs as CardProfileResponsivePropertiesView)),
          sm: this.cast((params.sm as CardProfileResponsivePropertiesView)),
          md: this.cast((params.md as CardProfileResponsivePropertiesView)),
          lg: this.cast((params.lg as CardProfileResponsivePropertiesView)),
          xl: this.cast((params.xl as CardProfileResponsivePropertiesView)),
        });
      }
      case (CardType.Text): {
        return new CardTextResponsiveProperties({
          xs: this.cast((params.xs as CardTextResponsivePropertiesView)),
          sm: this.cast((params.sm as CardTextResponsivePropertiesView)),
          md: this.cast((params.md as CardTextResponsivePropertiesView)),
          lg: this.cast((params.lg as CardTextResponsivePropertiesView)),
          xl: this.cast((params.xl as CardTextResponsivePropertiesView)),
        });
      }
      case (CardType.Video): {
        return new CardVideoResponsiveProperties({
          xs: this.cast((params.xs as CardVideoResponsivePropertiesView)),
          sm: this.cast((params.sm as CardVideoResponsivePropertiesView)),
          md: this.cast((params.md as CardVideoResponsivePropertiesView)),
          lg: this.cast((params.lg as CardVideoResponsivePropertiesView)),
          xl: this.cast((params.xl as CardVideoResponsivePropertiesView)),
        });
      }
    }
  }
  
  private static cast<T>(o: T): T {
    return o;
  }
}
