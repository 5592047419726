import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var PaymentSubscribePackages = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentSubscribePackages, _super);
    function PaymentSubscribePackages(data) {
        return _super.call(this, 'PaymentSubscribePackages', data) || this;
    }
    return PaymentSubscribePackages;
}(BaseObject));
export { PaymentSubscribePackages };
