import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var ContentProjection = /** @class */ (function (_super) {
    tslib_1.__extends(ContentProjection, _super);
    function ContentProjection(data) {
        return _super.call(this, 'ContentProjection', data) || this;
    }
    return ContentProjection;
}(BaseObject));
export { ContentProjection };
