import {
  AmgComponentStaticProperties,
} from 'amg';

import { CardType } from 'card';

export class CarouselStaticProperties implements AmgComponentStaticProperties {
  carouselTile: CardType;

  carouselSidebar?: Array<string>;

  cardStaticProperties?: AmgComponentStaticProperties;
}
