<label *ngIf="getFilterLabel()"
       class="amg-grid-filter-title"
       [innerHTML]="getFilterLabel()"></label>

<ng-select class="amg-select-field dropdown"
           [items]="getFilters()"
           [ngModel]="dropdownModel"
           [searchable]="isSearchable()"
           (ngModelChange)="onFilterChange($event)">
</ng-select>
