import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AnywhereItemData } from 'amg-fe-types';
import { ImageUtils } from 'amg-fe-utils';
import { CardConfiguration, Key } from '../../types/card.type';

@Component({
  selector: 'amg-video-card',
  templateUrl: './video-card.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoCardComponent implements OnInit {
  private static readonly defaultArraySeparator = ' ';

  @Input() cardData: AnywhereItemData<any>;
  @Input() cardConfiguration: CardConfiguration;
  @Output() triggerClick: EventEmitter<any>;

  public defaultImage: string;

  constructor(private sanitizer: DomSanitizer) {
    this.defaultImage = ImageUtils.emptyImage;
    this.triggerClick = new EventEmitter<any>();
  }

  private static _getData(key: string, object: any, arraySeparator: string): string {
    const keys = key.split('.');
    let data = object;

    for (let idx = 0; idx < keys.length; idx++) {
      const currentKey = keys[idx];

      if (data[currentKey]) {
        data = data[currentKey];
      } else {
        idx = keys.length;
        data = null;
      }
    }

    if (data) {
      if (Array.isArray(data)) {
        return data.join(arraySeparator);
      } else if (typeof data === 'object') {
        // Object is not handled, usually this case relates to incorrect KEY from user.
        return '';
      } else {
        return data;
      }
    } else {
      return '';
    }
  }

  ngOnInit() {
  }

  getThumbnail(): string {
    const thumbnailKeyObject = this.cardConfiguration.displayKeys.thumbnailKey;
    const url = VideoCardComponent._getData(thumbnailKeyObject.key, this.cardData, VideoCardComponent.defaultArraySeparator);

    return (thumbnailKeyObject.transform)
      ? thumbnailKeyObject.transform(url)
      : url;
  }

  getData(keyObject: Key): string | SafeHtml {
    if (typeof keyObject === 'string') {
      return VideoCardComponent._getData(keyObject, this.cardData, VideoCardComponent.defaultArraySeparator);

    } else {
      const key = keyObject.key;
      const arraySeparator = keyObject.arraySeparator || VideoCardComponent.defaultArraySeparator;
      const data = VideoCardComponent._getData(key, this.cardData, arraySeparator);

      if (keyObject.transform) {
        return this.sanitizer.bypassSecurityTrustHtml(keyObject.transform(data));
      } else {
        return data;
      }
    }
  }

  getClassName(keyObject: Key): string {
    const key = (typeof keyObject === 'string')
      ? keyObject
      : keyObject.key;
    const keys = key.split('.');

    return keys[keys.length - 1].toLowerCase();
  }

  onCardClick(): void {
    this.triggerClick.emit(this.cardData);
  }
}
