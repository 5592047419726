export const configs = {
  cloudPay: {
    paths: {
      myAccount: '/account',
      start: '/sso/start/cmV0dXJudXJsOi9hY2NvdW50P2xhbmc9ZW4=/?lang=en&origin=login' // cmV0dXJudXJsOi9hY2NvdW50P2xhbmc9ZW4= == returnurl:/account?lang=en (encode64)
      // registration: '/account/freeregistration'
    }
  },
  sso: {
    paths: {
      login: '',
      logout: '/Dashboard/Logout',
      profile: '/Dashboard/Profile'
    }
  }
}
