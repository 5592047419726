import * as tslib_1 from "tslib";
var CollapsibleResponsivePropertiesView = /** @class */ (function () {
    function CollapsibleResponsivePropertiesView() {
    }
    CollapsibleResponsivePropertiesView.defaultValues = function (input) {
        var toReturn;
        if (input === undefined) {
            toReturn = new CollapsibleResponsivePropertiesView();
        }
        else {
            toReturn = input;
        }
        toReturn = tslib_1.__assign({}, toReturn, {
            canCollapse: true,
            showText: '+',
            hideText: '-',
            refreshRate: 200,
            cardView: {},
        });
        return toReturn;
    };
    return CollapsibleResponsivePropertiesView;
}());
export { CollapsibleResponsivePropertiesView };
