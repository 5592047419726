import {EntryNavItem} from '../video-player/entry-nav/entry-nav.model';

export interface Scripts {
  name: string;
  src: string;
}

export interface EntryAndKs {
  entryId: string,
  ks: string
}

export enum KalturaPlayerEvents {
  layoutBuildDone = 'layoutBuildDone',
  playerReady = 'playerReady',
  playing = 'playing',
  mediaLoaded = 'mediaLoaded',
  mediaError = 'mediaError',
  playerStateChange = 'playerStateChange',
  firstPlay = 'firstPlay',
  playerPlayed = 'playerPlayed',
  playerPaused = 'playerPaused',
  preSeek = 'preSeek',
  seek = 'seek',
  seeked = 'seeked',
  playerUpdatePlayhead = 'playerUpdatePlayhead',
  openFullScreen = 'openFullScreen',
  closeFullScreen = 'closeFullScreen',
  volumeChanged = 'volumeChanged',
  mute = 'mute',
  unmute = 'unmute',
  bufferChange = 'bufferChange',
  cuePointReached = 'cuePointReached',
  playerPlayEnd = 'playerPlayEnd',
  onChangeMedia = 'onChangeMedia',
  changeMedia = 'changeMedia',
  onChangeMediaDone = 'onChangeMediaDone'
}

export enum UserState {
  loggedIn = 'loggedIn',
  notLoggedIn = 'notLoggedIn'
}

interface Button {
  label: string;
  link: string;
  cssClass?: string;
}


export interface VideoPlayerConfig {
  anywhereConfig?: AnywhereConfig;
  kalturaPlayerConfig: KalturaPlayerConfig;
  navEntries?: EntryNavItem[];
}

export interface kWidgetEmbed {
  targetId: string;
  wid: string;
  uiconf_id: string;
  entry_id: string;
  flashvars?: {
    streamerType?: string;
    ks?: string;
    chromecast?: {
      applicationID: string;
    };
    pxwLhhu?: any;
    debuggerAMG?: any;
    izsession?: string;
  };
}

export interface KalturaPlayerConfig {
  apiUrl: string;
  playerId: string;
  partnerId: string;
  uiConfId: string;
  entryId?: string;
  flashvars?: {
    streamerType?: string;
    ks?: string;
    chromecast?: ChromecastPlugin;
    izsession?: string;
    pxwLhhu: {
      plugin: boolean;
    };
  };
  forcePlayerEmbed?: boolean;
}

interface ChromecastPlugin {
  applicationID: string;
}

export interface AnywhereItemData extends Fixture {
  id: string;
  itemData: AnywhereItemData;
  playlistUrl?: string;
  type?: string;
  mediaData: AnywhereMediaData | FixtureMediaData | any;
  sortData: [];
  metaData: AnywhereMetaData;
  publicationData: AnywherePublicationData,
  playlist_url?: string;
  title?: string;
  imageUrl?: string;
  [key: string]: any;
}

export interface AnywhereMetaData {
  team?: string;
  body: null | string;
  competitions: null | string;
  membership: AnywhereMembership;
  SysEntryEntitlements: string[];
  tours: string;
  VideoDuration: number;
  players: string[];
  categories: string[];
  category?: string[];
  year: null | string;
  title: string;
  tags: string[]
}

export interface AnywhereMediaData {
  mediaType: string;
  entryId: string;
  entryStatus: string;
  thumbnailUrl: string;
  isLiveUrl?: any;
}

export interface FixtureMediaData {
  entryId: string;
  ks?: string;
  isLiveUrl?: string;
  mediaType?: string;
  thumbnailUrl?: string;
  drm?: boolean;
}

export interface AnywherePublicationData {
  createdAt: string;
  updatedAt: string;
  released: boolean;
  releaseFrom: string;
  releaseTo: string
  startDate: string;
}

interface Fixture {
  name?: string;
  startDate?: string;
  thumbnail?: string;
  homeTeam?: {
    name?: string;
  }
  awayTeam?: {
    name?: string;
  };
  eventType?: string;
  description?: string;
}

export interface LiveLoginOverlay {
  title: string;
  subTitle: string;
  buttonSeparator: string;
  subscribeBtn: Button;
  logInBtn: Button;
}

export interface AnywhereData {
  metaData?: any;
  itemData: AnywhereItemData[];
  sections: AnywhereItemData[];
  pagingData: {
    totalCount: number;
    itemCount: number;
    pageCount: number;
    pageSize: number;
    pageIndex: number
  };
  fixtures?: any;
}

export interface EntryAndUiConf {
  uiconf_id?: string;
  entry_id: string;
  [key: string]: any;
}

export type AnywhereMemberships = 'free' | 'registered' | 'paid' | '';

export enum AnywhereMembership {
  Paid = 'paid',
  Free = 'free',
  Registered = 'registered'
}

export enum AnywhereMediaType {
  OnDemand = 'OnDemand',
  Live = 'live'
}

export interface AnywhereConfig {
  apiKey: string;
  apiUrl: string;
  apiUserId: string;
}

export interface VideoSignUpConfig {
  playButton: PlayButtonConfig;
}


interface PlayButtonConfig {
  audio: string;
  video: string;
}

export interface PlayerUrlData {
  playlist?: string;
  decodedPlaylist?:string;
  entry?: string;
  entryId?: string;
  pageRoute: string;
}
