import { BehaviorSubject } from 'rxjs';
import { BootstrapViewportEnum } from './bootstrap.viewport.enum';
import * as i0 from "@angular/core";
var BootstrapViewportService = /** @class */ (function () {
    function BootstrapViewportService() {
        var _this = this;
        this.viewportBehaviourSubject = new BehaviorSubject(BootstrapViewportEnum.xs);
        this.viewportObservable = this.viewportBehaviourSubject.asObservable();
        window.onresize = function (e) {
            _this.windowResized();
        };
        this.windowResized();
    }
    BootstrapViewportService.prototype.getViewport = function () {
        return this.viewportBehaviourSubject.getValue();
    };
    BootstrapViewportService.prototype.getViewportSubscriber = function () {
        return this.viewportObservable;
    };
    BootstrapViewportService.prototype.windowResized = function () {
        var currentViewportSize = this.viewportBehaviourSubject.getValue();
        var newViewportSize = this.getCurrentViewportSize();
        if (currentViewportSize !== newViewportSize) {
            this.viewportBehaviourSubject.next(newViewportSize);
        }
    };
    BootstrapViewportService.prototype.getCurrentViewportSize = function () {
        var viewPortSize = window.outerWidth;
        var toReturn = BootstrapViewportEnum.xs;
        if (viewPortSize >= 576) {
            toReturn = BootstrapViewportEnum.sm;
            if (viewPortSize >= 768) {
                toReturn = BootstrapViewportEnum.md;
                if (viewPortSize >= 992) {
                    toReturn = BootstrapViewportEnum.lg;
                    if (viewPortSize >= 1200) {
                        toReturn = BootstrapViewportEnum.xl;
                        if (viewPortSize >= 1280) {
                            toReturn = BootstrapViewportEnum.HD;
                            if (viewPortSize >= 1920) {
                                toReturn = BootstrapViewportEnum.FullHd;
                                if (viewPortSize >= 4096) {
                                    toReturn = BootstrapViewportEnum.FourK;
                                }
                            }
                        }
                    }
                }
            }
        }
        return toReturn;
    };
    BootstrapViewportService.ngInjectableDef = i0.defineInjectable({ factory: function BootstrapViewportService_Factory() { return new BootstrapViewportService(); }, token: BootstrapViewportService, providedIn: "root" });
    return BootstrapViewportService;
}());
export { BootstrapViewportService };
