import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Collapsible as WpCollapsible } from 'wordpress-adapter';
import { CollapsibleStaticProperties, CollapsibleResponsiveProperties } from 'collapsible';

@Component({
  selector: 'amg-wp-collapsible',
  templateUrl: './collapsible.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CollapsibleComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-collapsible';
  @Input() collapsible: WpCollapsible;

  public collapsibleData: any[];
  public collapsibleResponsiveProperties: CollapsibleResponsiveProperties;
  public collapsibleStaticProperties: CollapsibleStaticProperties;

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.initialiseCollapsible();
  }

  getCss(): string {
    return this.collapsible.cssClass;
  }

  private initialiseCollapsible(): void {
    this.collapsibleResponsiveProperties = new CollapsibleResponsiveProperties(this.collapsible.collapsibleConfiguration);
    this.collapsibleStaticProperties = this.collapsible.collapsibleStaticProperties;
    this.collapsibleData = this.collapsible.collapsibleData;
  }
}
