import { EventEmitter, OnInit } from '@angular/core';
import { Sections } from 'wordpress-adapter';
var SectionsComponent = /** @class */ (function () {
    function SectionsComponent() {
        this.class = 'amg-sections';
        this.triggerChange = new EventEmitter();
        this.triggerDisabledByDrm = new EventEmitter();
        this.isDrmDisabled = false;
    }
    SectionsComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
    };
    SectionsComponent.prototype.getSections = function () {
        return this.sections
            ? this.sections.sections
            : [];
    };
    SectionsComponent.prototype.getCss = function () {
        return this.sections.cssClass;
    };
    SectionsComponent.prototype.onChange = function (value) {
        this.triggerChange.emit(value);
    };
    SectionsComponent.prototype.onDrmStateChange = function (value) {
        this.isDrmDisabled = value;
        this.triggerDisabledByDrm.emit(value);
    };
    return SectionsComponent;
}());
export { SectionsComponent };
