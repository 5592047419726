import {Component, HostBinding, OnInit, ViewEncapsulation} from '@angular/core';
import { StreamPaymentsHooks } from '../shared/data/stream-payments.model';
import { SubscriptionPackageModel } from '../shared/data/subscription-package.model';
import { UserAuthService } from '../user-auth.service';

@Component({
  selector: 'amg-subscription-packages',
  templateUrl: './subscription-packages.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionPackagesComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-subscription-packages';

  public subscriptionPackages: SubscriptionPackageModel[];

  constructor(private userAuthService: UserAuthService) {
  }

  ngOnInit() {
    this.userAuthService.hookStreamPayments(StreamPaymentsHooks.getSubscriptionPackageList, null)
      .then(subscriptionPackages => {
        if (subscriptionPackages && (subscriptionPackages as any).SubscriptionPlanOptions) {
          this.subscriptionPackages = ((subscriptionPackages as any)
            .SubscriptionPlanOptions as SubscriptionPackageModel[]);
        }
      });
  }

}
