/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../components/collapsible/src/lib/collapsible.component.ngfactory";
import * as i2 from "../../../../../../../../../components/collapsible/src/lib/collapsible.component";
import * as i3 from "../../../../../../../../../components/amg/src/lib/bootstrap.viewport.service";
import * as i4 from "./collapsible.component";
var styles_CollapsibleComponent = [];
var RenderType_CollapsibleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CollapsibleComponent, data: {} });
export { RenderType_CollapsibleComponent as RenderType_CollapsibleComponent };
export function View_CollapsibleComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-collapsible", [], null, null, null, i1.View_CollapsibleComponent_0, i1.RenderType_CollapsibleComponent)), i0.ɵdid(1, 4440064, null, 0, i2.CollapsibleComponent, [i0.ComponentFactoryResolver, i3.BootstrapViewportService], { componentData: [0, "componentData"], responsiveProperties: [1, "responsiveProperties"], staticProperties: [2, "staticProperties"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collapsibleData; var currVal_1 = _co.collapsibleResponsiveProperties; var currVal_2 = _co.collapsibleStaticProperties; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CollapsibleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-collapsible", [], [[1, "class", 0]], null, null, View_CollapsibleComponent_0, RenderType_CollapsibleComponent)), i0.ɵdid(1, 114688, null, 0, i4.CollapsibleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var CollapsibleComponentNgFactory = i0.ɵccf("amg-wp-collapsible", i4.CollapsibleComponent, View_CollapsibleComponent_Host_0, { collapsible: "collapsible" }, {}, []);
export { CollapsibleComponentNgFactory as CollapsibleComponentNgFactory };
