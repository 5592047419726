import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var Text = /** @class */ (function (_super) {
    tslib_1.__extends(Text, _super);
    function Text(data) {
        var _this = _super.call(this, 'Text', data) || this;
        _this._text = data.text;
        return _this;
    }
    Object.defineProperty(Text.prototype, "text", {
        get: function () {
            return this._text;
        },
        enumerable: true,
        configurable: true
    });
    return Text;
}(BaseObject));
export { Text };
