import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiException } from '../api/ApiException';
import { ClientRequest } from '../api/client/ClientRequest';
import { ClientResponse } from '../api/client/ClientResponse';
import { PageData } from '../api/data/types/PageData';
import { ClientOptions } from '../config/client-options';

export class ClientRequestAdapter {
  constructor(private http: HttpClient) {
  }

  public transmit<D>(request: ClientRequest<D>, clientOptions: ClientOptions): Observable<ClientResponse<D>> {
    return this.http
      .get(`${clientOptions.wordpressApiUrl}/${request.endpoint}`)
      .pipe(
        catchError(error => {
          const errorMessage = error instanceof HttpErrorResponse
            ? error.message
            : error;

          throw new ApiException(errorMessage);
        }),
        map(result => {
          return this.unwrapResponse(result);
        }),
        map(result => {
          return request.handleResponse<D>(result);
        })
      );
  }

  private unwrapResponse(response: any): PageData {
    if (Array.isArray(response)) {
      const pageData: PageData = {};

      (response as any[]).forEach(pageSection => {
        if (pageSection.post_type === 'shared_section') {
          if (pageSection.post_name === 'header') {
            pageData.otherContent = {
              ...pageData.otherContent,
              header: pageSection.acf
            };
          } else if (pageSection.post_name === 'footer') {
            pageData.otherContent = {
              ...pageData.otherContent,
              footer: pageSection.acf
            };
          } else if (pageSection.post_name === 'overlay') {
            pageData.otherContent = {
              ...pageData.otherContent,
              overlay: pageSection.acf
            };
          }
        } else if (pageSection.post_type === 'page') {
          pageData.page = pageSection.acf;
        }
      });

      return pageData;
    }

    return null;
  }
}
