import { OnInit, QueryList, ViewContainerRef, OnDestroy, EventEmitter } from '@angular/core';
import { BootstrapViewportService, } from 'amg';
import { CountdownResponsiveProperties } from './countdown.responsive.properties';
import { CountdownStaticProperties } from './countdown.static.properties';
var CountdownComponent = /** @class */ (function () {
    function CountdownComponent(bootstrapViewportService) {
        this.bootstrapViewportService = bootstrapViewportService;
        this.calculatedText = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
        this.calculatedPercentage = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
        this.countdownTick = new EventEmitter();
        this.countdownFinished = new EventEmitter();
    }
    CountdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        var viewportSubscriber = this.bootstrapViewportService
            .getViewportSubscriber();
        var viewport = this.bootstrapViewportService.getViewport();
        this.componentCurrentView = this.responsiveProperties[viewport];
        this.componentViewportSubscription = viewportSubscriber
            .subscribe(function (newBreakpoint) {
            if (_this.responsiveTimeout) {
                clearTimeout(_this.responsiveTimeout);
                _this.responsiveTimeout = undefined;
            }
            _this.responsiveTimeout = setTimeout(function () {
                _this.componentCurrentView = _this.responsiveProperties[newBreakpoint];
            }, _this.componentCurrentView.refreshRate);
        });
        this._timeLeft = new Date(this.componentData[0].getTime() - new Date().getTime());
        if (this._timeLeft.getTime() > 0) {
            this._tickReference = setInterval(function () {
                _this.tick();
            }, 1000);
            this._finishReference = setTimeout(function () {
                _this.countdownFinished.emit(true);
                clearInterval(_this._tickReference);
            }, this._timeLeft.getTime());
            this.countdownFinished.emit(false);
        }
        else {
            this.countdownFinished.emit(true);
        }
    };
    CountdownComponent.prototype.ngOnDestroy = function () {
        this.componentViewportSubscription.unsubscribe();
        if (this._tickReference) {
            clearInterval(this._tickReference);
            this._tickReference = undefined;
        }
        if (this._finishReference) {
            clearTimeout(this._finishReference);
            this._finishReference = undefined;
        }
    };
    CountdownComponent.prototype.tick = function () {
        this._timeLeft.setSeconds(this._timeLeft.getSeconds() - 1);
        this.calculatedText.days = Math.floor(this._timeLeft.getTime() / (24 * 60 * 60 * 1000));
        this.calculatedText.hours = this._timeLeft.getUTCHours();
        this.calculatedText.minutes = this._timeLeft.getUTCMinutes();
        this.calculatedText.seconds = this._timeLeft.getUTCSeconds();
        this.calculatedPercentage.days = 100 - ((this._timeLeft.getUTCDate() + (this._timeLeft.getHours() / 24)) / 30 * 100);
        this.calculatedPercentage.hours = 100 - ((this._timeLeft.getUTCHours() + (this._timeLeft.getMinutes() / 60)) / 24 * 100);
        this.calculatedPercentage.minutes = 100 - ((this._timeLeft.getUTCMinutes() + (this._timeLeft.getSeconds() / 60)) / 60 * 100);
        this.calculatedPercentage.seconds = 100 - ((this._timeLeft.getUTCSeconds()) / 60 * 100);
        this.countdownTick.emit(this._timeLeft);
    };
    return CountdownComponent;
}());
export { CountdownComponent };
