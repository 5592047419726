import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { CalendarConfiguration } from '../../../types/ng-objects/CalendarConfiguration';
import { ScreenConfiguration } from '../../../types/ng-objects/ScreenConfiguration';
import { NgObject } from './NgObject';

export class Calendar extends NgObject {
  private readonly _calendarConfiguration: ScreenConfiguration<CalendarConfiguration>;
  private readonly _staticProperties: any;

  constructor(data: any) {
    super('Calendar', data);

    this._staticProperties = data.static_properties;
    this._calendarConfiguration = CollectionUtils
      .flattenArrayOfResponsiveConfiguration<CalendarConfiguration>(data.ui_conf, Calendar.calendarConfigurationMapping);
  }

  get staticProperties(): any {
    return this._staticProperties;
  }

  get calendarConfiguration(): ScreenConfiguration<CalendarConfiguration> {
    return this._calendarConfiguration;
  }

  private static calendarConfigurationMapping(data: any): CalendarConfiguration {
    const calendarConfiguration: CalendarConfiguration = {
      tab: data.tab,
      tabs: CollectionUtils.parseNumber(data.tabs),
      tabsWhenHovered: CollectionUtils.parseNumber(data.tabsWhenHov),
      refreshRate: CollectionUtils.parseNumber(data.refreshRate),

      tabNavText: [
        data.navText.after,
        data.navText.before
      ]
    };

    return calendarConfiguration;
  }
}
