import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PaymentUserSignInPage, InputField, ActionLink } from 'wordpress-adapter';
import { LoginDataJson } from 'user-auth';

@Component({
  selector: 'amg-wp-user-sign-in-page',
  templateUrl: './user-sign-in-page.component.html',
  encapsulation: ViewEncapsulation.None
})
export class UserSignInPageComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-user-authorisation-page';
  @Input() userSignInPage: PaymentUserSignInPage;

  private static generateGenericInput(field: InputField): any {
    return {
      label: field.label,
      placeholder: field.placeholder,
      values: field.values,
      value: field.value,
      input_type: field.type,
      required: field.required,
      internal_reference_name: field.referenceName
    };
  }

  private static generateTextLink(field: ActionLink): any {
    return {
      label: field.label,
      external_link: field.externalLink,
      path: field.path,
      type: field.type
    };
  }

  private static generateOtherLinks(otherLinks: { title: string; textLinkButton: ActionLink; }[]): any {
    // Lambda functions are not supported on static methods. Therefore assign the results to a constant first.
    const mappedOtherLinks = otherLinks.map(value => {
      return {
        title: value.title,
        call_to_action: UserSignInPageComponent.generateTextLink(value.textLinkButton)
      };
    });

    return mappedOtherLinks;
  }

  ngOnInit(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }
  }

  getFormData(): LoginDataJson {
    const logoData = (this.userSignInPage.logo.imageData)
      ? { url: this.userSignInPage.logo.imageData.url,
          label: this.userSignInPage.logo.label,
          link: this.userSignInPage.logo.link }
      : { url: null, label: null, link: null };

    const formData = {
      logo: logoData,

      form: {
        emailaddress: UserSignInPageComponent.generateGenericInput(this.userSignInPage.loginFormGroup.emailAddress),
        password: UserSignInPageComponent.generateGenericInput(this.userSignInPage.loginFormGroup.password),
        submit_button: { label: this.userSignInPage.loginFormGroup.loginButton.label  }
      },
      forgot_password: UserSignInPageComponent.generateTextLink(this.userSignInPage.forgotPasswordLink),
      links: UserSignInPageComponent.generateOtherLinks(this.userSignInPage.textLinks)
    };

    return formData;
  }

  getCss(): string {
    return this.userSignInPage.cssClass;
  }
}
