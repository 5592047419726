import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Text, TextGroup } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-text-group',
  templateUrl: './text-group.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TextGroupComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-text-group';
  @Input() textGroup: TextGroup;

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }
  }

  getCss(): string {
    return this.textGroup.cssClass;
  }

  getTextFields(): Text[] {
    return this.textGroup.textGroup;
  }
}
