/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../components/carousel/src/lib/carousel-flickity/carousel-flickity.component.ngfactory";
import * as i2 from "../../../../../../../../../components/carousel/src/lib/carousel-flickity/carousel-flickity.component";
import * as i3 from "./carousel-flickity.component";
import * as i4 from "@angular/common/http";
import * as i5 from "../../../../../shared/data/data.service";
import * as i6 from "@angular/router";
var styles_CarouselFlickityComponent = [];
var RenderType_CarouselFlickityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CarouselFlickityComponent, data: {} });
export { RenderType_CarouselFlickityComponent as RenderType_CarouselFlickityComponent };
export function View_CarouselFlickityComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-carousel-flickity", [], [[1, "class", 0]], [[null, "triggerCardClick"], [null, "triggerLoadMore"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("triggerCardClick" === en)) {
        var pd_0 = (_co.onCardClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("triggerLoadMore" === en)) {
        var pd_1 = (_co.onTriggerLoadMore() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CarouselFlickityComponent_0, i1.RenderType_CarouselFlickityComponent)), i0.ɵdid(1, 9158656, null, 0, i2.CarouselFlickityComponent, [], { carouselConfig: [0, "carouselConfig"], carouselSlides: [1, "carouselSlides"], isMoreDataAvailable: [2, "isMoreDataAvailable"], apiCallTriggered: [3, "apiCallTriggered"] }, { triggerLoadMore: "triggerLoadMore", triggerCardClick: "triggerCardClick" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.carousel.carouselConfiguration; var currVal_2 = _co.carouselData; var currVal_3 = _co.isMoreDataAvailable; var currVal_4 = _co.apiCallTriggered; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classCardType; _ck(_v, 0, 0, currVal_0); }); }
export function View_CarouselFlickityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-carousel-flickity", [], [[1, "class", 0]], null, null, View_CarouselFlickityComponent_0, RenderType_CarouselFlickityComponent)), i0.ɵdid(1, 245760, null, 0, i3.CarouselFlickityComponent, [i4.HttpClient, i5.DataService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var CarouselFlickityComponentNgFactory = i0.ɵccf("amg-wp-carousel-flickity", i3.CarouselFlickityComponent, View_CarouselFlickityComponent_Host_0, { carousel: "carousel" }, {}, []);
export { CarouselFlickityComponentNgFactory as CarouselFlickityComponentNgFactory };
