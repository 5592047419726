  <div class="amg-video-details">
    <div class="amg-video-meta-data">
      <ng-template [ngIf]="category">
          <span class="amg-category-wrapper">
        <span *ngFor="let cat of category"
              class="amg-video-category"
              [innerHTML]="cat"></span>
          </span>
      </ng-template>
      <span *ngIf="videoPlayerService.isFreeMembership()"
            class="amg-membership-icon free"></span>
      <span *ngIf="videoPlayerService.isPaidMembership()"
            class="amg-membership-icon paid"></span>
      <span *ngIf="videoPlayerService.isRegisteredMembership()"
            class="amg-membership-icon registered"></span>
      <span *ngIf="videoPlayerService.isLive()"
            class="amg-live-video-icon"></span>
    </div>
    <div *ngIf="title"
         [innerHTML]="title"
         class="amg-title"></div>
    <ng-template [ngIf]="titleDescription">
      <div class="amg-description"
           [innerHTML]="titleDescription"></div>
    </ng-template>

    <!-- Date and Time - BEGIN -->
    <amg-date-and-time [cardData]="localAnywhereMediaData"></amg-date-and-time>

    <div *ngIf="duration"
         class="amg-duration">

      <span class="runtime">
        <span class="hours" [attr.data-hours]="duration.hours.paddedValue">{{ duration.hours.value }}</span>
        <span class="minutes" [attr.data-minutes]="duration.minutes.paddedValue">{{ duration.minutes.value }}</span>
        <span class="seconds" [attr.data-seconds]="duration.seconds.paddedValue">{{ duration.seconds.value }}</span>
      </span>
    </div>
    <!-- Date and Time - END -->

    <div></div>
  </div>
