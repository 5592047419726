import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {LoginFormHeader, LoginDataJson, LoginFormBody} from '../../shared/data/stream-payments-scripts.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Link} from '../../shared/data/wordpress-data.model';

@Component({
  selector: 'amg-template-builder',
  templateUrl: './template-builder.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TemplateBuilderComponent implements OnInit {
  @Input() dataObj: LoginDataJson;
  @Input() modalRef: NgbActiveModal;

  logo: LoginFormHeader;
  form: LoginFormBody;
  links: Link[];

  constructor() {
  }

  ngOnInit() {
    this.logo = this.dataObj.logo;
    this.form = this.dataObj.form;
    this.links = this.dataObj.links;
  }

}
