var DateObject = /** @class */ (function () {
    function DateObject(startDate, isUtc) {
        if (isUtc) {
            this.generateUtcTime(startDate);
        }
        else {
            this.generateLocaleTime(startDate);
        }
    }
    DateObject.convertDate = function (date) {
        if (!date.toUpperCase().endsWith('Z')) {
            date += 'Z';
        }
        return new Date(date);
    };
    DateObject.prototype.generateUtcTime = function (dateString) {
        var date = DateObject.convertDate(dateString);
        this.setDateAndTime(date.getUTCMinutes(), date.getUTCHours(), date.getUTCDate(), (date.getUTCMonth() + 1), date.getUTCFullYear());
    };
    DateObject.prototype.generateLocaleTime = function (dateString) {
        var date = DateObject.convertDate(dateString);
        this.setDateAndTime(date.getMinutes(), date.getHours(), date.getDate(), (date.getMonth() + 1), date.getFullYear());
    };
    DateObject.prototype.setDateAndTime = function (minute, hour, day, month, year) {
        this.minute = minute;
        this.hour = hour;
        this.day = day;
        this.month = month;
        this.year = year;
    };
    return DateObject;
}());
export { DateObject };
