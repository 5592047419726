import { BaseObject } from '../../../BaseObject';

export class Button extends BaseObject {
  private readonly _label: string;

  constructor(data: any) {
    super('Button', data);

    this._label = data.label;
  }

  get label(): string {
    return this._label;
  }
}
