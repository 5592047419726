import { BaseObject } from '../BaseObject';

export class AnywhereApiConfiguration extends BaseObject {
  private _baseUrl: string;
  private _apiFeedId: string;
  private _searchQuery: string;
  private _targetId: string;
  private _sortOrder: string;
  private _sectionId: string;
  private _pageIndex: string;
  private _pageSize: string;
  private _sortBy: string;
  private _apiRequestMethod: string;

  /**
   * Merges the parent configuration with the child configuration. Child data always takes precedent.
   * @param parentConfig - API configuration coming from the parent.
   * @param childConfig - API configuration coming for the current object.
   */
  public static merge(parentConfig: AnywhereApiConfiguration, childConfig: AnywhereApiConfiguration): AnywhereApiConfiguration {
    return new AnywhereApiConfiguration({
      baseUrl: (childConfig && childConfig.baseUrl) ? childConfig.baseUrl : parentConfig.baseUrl,
      apiFeedId: (childConfig && childConfig.apiFeedId) ? childConfig.apiFeedId : parentConfig.apiFeedId,
      searchQuery: (childConfig && childConfig.searchQuery) ? childConfig.searchQuery : parentConfig.searchQuery,
      sortOrder: (childConfig && childConfig.sortOrder) ? childConfig.sortOrder : parentConfig.sortOrder,
      sectionId: (childConfig && childConfig.sectionId) ? childConfig.sectionId : parentConfig.sectionId,
      targetId: (childConfig && childConfig._targetId) ? childConfig._targetId : parentConfig._targetId,
      pageIndex: (childConfig && childConfig.pageIndex) ? childConfig.pageIndex : parentConfig.pageIndex,
      pageSize: (childConfig && childConfig.pageSize) ? childConfig.pageSize : parentConfig.pageSize,
      sortBy: (childConfig && childConfig.sortBy) ? childConfig.sortBy : parentConfig.sortBy,
      apiRequestMethod: (childConfig && childConfig.apiRequestMethod) ? childConfig.apiRequestMethod : parentConfig.apiRequestMethod
    }, true);
  }

  /**
   *
   * @param data - The data from the WordPress API or from the internal function 'merge'.
   * @param isDataMerge - A flag to indicate where it is coming from.
   */
  constructor(data: any, isDataMerge: boolean = false) {
    super('AnywhereApiConfiguration', data);

    if (!isDataMerge) {
      if (data.anywhere_api_configuration) {
        (data.anywhere_api_configuration as any[]).forEach(config => {
          if (config.api_feed_id) {
            this._apiFeedId = config.api_feed_id;
          } else if (config.search_query) {
            this._searchQuery = config.search_query;
          } else if (config.sort_order) {
            this._sortOrder = config.sort_order;
          } else if (config.section_id) {
            this._sectionId = config.section_id;
          } else if (config.page_index) {
            this._pageIndex = config.page_index;
          } else if (config.sort_by) {
            this._sortBy = config.sort_by;
          } else if (config.base_url) {
            this._baseUrl = config.base_url;
          } else if (config.api_request_method) {
            this._apiRequestMethod = config.api_request_method;
          } else if (config.page_size) {
            this._pageSize = config.page_size;
          } else if (config.target_id) {
            this._targetId = config.target_id;
          }
        });
      }
    } else {
      this._apiFeedId = data.apiFeedId;
      this._searchQuery = data.searchQuery;
      this._sortOrder = data.sortOrder;
      this._sectionId = data.sectionId;
      this._pageIndex = data.pageIndex;
      this._pageSize = data.pageSize;
      this._sortBy = data.sortBy;
      this._targetId = data.targetId;
      this._baseUrl = data.baseUrl;
      this._apiRequestMethod = data.apiRequestMethod;
    }
  }

  get targetId(): string {
    return this._targetId;
  }

  get pageSize(): string {
    return this._pageSize;
  }

  get apiRequestMethod(): string {
    return this._apiRequestMethod;
  }

  get apiFeedId(): string {
    return this._apiFeedId;
  }

  get searchQuery(): string {
    return this._searchQuery;
  }

  get sortOrder(): string {
    return this._sortOrder;
  }

  get sectionId(): string {
    return this._sectionId;
  }

  get pageIndex(): string {
    return this._pageIndex;
  }

  get sortBy(): string {
    return this._sortBy;
  }

  get baseUrl(): string {
    return this._baseUrl;
  }
}
