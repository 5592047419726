var NavigationUtils = /** @class */ (function () {
    function NavigationUtils() {
    }
    NavigationUtils.getUrlFragments = function (url) {
        var urlObject = {
            url: null,
            fragment: null
        };
        var hashIndex = url.indexOf('#');
        if (hashIndex !== -1) {
            urlObject.url = url.substring(0, hashIndex);
            urlObject.fragment = url.substring(hashIndex + 1);
        }
        else {
            urlObject.url = url;
        }
        return urlObject;
    };
    return NavigationUtils;
}());
export { NavigationUtils };
