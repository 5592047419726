import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {UserAuthService} from '../user-auth.service';
import {LoginDataJson} from '../shared/data/stream-payments-scripts.model';

@Component({
  selector: 'amg-sign-in',
  templateUrl: './sign-in.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SignInComponent implements OnInit {
  @Input() loginFormJson: LoginDataJson;

  constructor(private userAuthService: UserAuthService) { }

  ngOnInit() {
    if (this.loginFormJson) {
      this.userAuthService.setModalData(this.loginFormJson);
    }
  }

}
