import { OnInit } from '@angular/core';
import { Collapsible as WpCollapsible } from 'wordpress-adapter';
import { CollapsibleResponsiveProperties } from 'collapsible';
var CollapsibleComponent = /** @class */ (function () {
    function CollapsibleComponent() {
        this.class = 'amg-collapsible';
    }
    CollapsibleComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.initialiseCollapsible();
    };
    CollapsibleComponent.prototype.getCss = function () {
        return this.collapsible.cssClass;
    };
    CollapsibleComponent.prototype.initialiseCollapsible = function () {
        this.collapsibleResponsiveProperties = new CollapsibleResponsiveProperties(this.collapsible.collapsibleConfiguration);
        this.collapsibleStaticProperties = this.collapsible.collapsibleStaticProperties;
        this.collapsibleData = this.collapsible.collapsibleData;
    };
    return CollapsibleComponent;
}());
export { CollapsibleComponent };
