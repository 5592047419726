import {
  Component,
  OnInit,
  QueryList,
  ViewContainerRef,
  Input,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';

import { Subscription, Observable } from 'rxjs';

import {
  AmgComponent,
  BootstrapViewportService,
  BootstrapViewportEnum,
} from 'amg';

import { CountdownResponsiveProperties } from './countdown.responsive.properties';
import { CountdownResponsivePropertiesView } from './countdown.responsive.properties.view';
import { CountdownStaticProperties } from './countdown.static.properties';

@Component({
  selector: 'amg-countdown',
  templateUrl: 'countdown.component.html',
  styles: []
})
export class CountdownComponent implements OnInit, OnDestroy, AmgComponent {
  @Input() componentData: Array<any>;
  @Input() responsiveProperties: CountdownResponsiveProperties;
  @Input() staticProperties: CountdownStaticProperties;

  @Input() componentChildren: QueryList<ViewContainerRef>;

  componentViewportSubscription: Subscription;

  componentCurrentView: CountdownResponsivePropertiesView;

  responsiveTimeout: any;

  calculatedText: any = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  calculatedPercentage: any = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  private _timeLeft: Date;

  private _finishReference;
  private _tickReference;

  @Output() countdownTick = new EventEmitter<any>();
  @Output() countdownFinished = new EventEmitter<any>();

  constructor(
    private bootstrapViewportService: BootstrapViewportService,
  ) { }

  ngOnInit() {
    let viewportSubscriber:Observable<BootstrapViewportEnum> = this.bootstrapViewportService
      .getViewportSubscriber();

    let viewport: BootstrapViewportEnum = this.bootstrapViewportService.getViewport();
    this.componentCurrentView = this.responsiveProperties[viewport] as CountdownResponsivePropertiesView;
    
    this.componentViewportSubscription = viewportSubscriber
      .subscribe(newBreakpoint => {
        if (this.responsiveTimeout) {
          clearTimeout(this.responsiveTimeout);
          this.responsiveTimeout = undefined;
        }

        this.responsiveTimeout = setTimeout(() => {
          this.componentCurrentView = this.responsiveProperties[newBreakpoint] as CountdownResponsivePropertiesView;
        }, this.componentCurrentView.refreshRate);
      });
    
    this._timeLeft = new Date(this.componentData[0].getTime() - new Date().getTime());
    if (this._timeLeft.getTime() > 0) {
      this._tickReference = setInterval(() => {
        this.tick();
      }, 1000);

      this._finishReference = setTimeout(() => {
        this.countdownFinished.emit(true);
        clearInterval(this._tickReference);
      }, this._timeLeft.getTime());

      this.countdownFinished.emit(false);
    } else {
      this.countdownFinished.emit(true);
    }
  }

  ngOnDestroy() {
    this.componentViewportSubscription.unsubscribe();
    if (this._tickReference) {
      clearInterval(this._tickReference);
      this._tickReference = undefined;
    }
    if (this._finishReference) {
      clearTimeout(this._finishReference);
      this._finishReference = undefined;
    }
  }

  private tick() {
    this._timeLeft.setSeconds(this._timeLeft.getSeconds() - 1);

    this.calculatedText.days = Math.floor(this._timeLeft.getTime() / (24 * 60 * 60 * 1000));
    this.calculatedText.hours = this._timeLeft.getUTCHours();
    this.calculatedText.minutes = this._timeLeft.getUTCMinutes();
    this.calculatedText.seconds = this._timeLeft.getUTCSeconds();

    this.calculatedPercentage.days = 100 - (
      (this._timeLeft.getUTCDate() + (
        this._timeLeft.getHours() / 24)
      ) / 30 * 100);
    this.calculatedPercentage.hours = 100 - (
      (this._timeLeft.getUTCHours() + (
        this._timeLeft.getMinutes() / 60)
      ) / 24 * 100);
    this.calculatedPercentage.minutes = 100 - (
      (this._timeLeft.getUTCMinutes() + (
        this._timeLeft.getSeconds() / 60)
      ) / 60 * 100) ;
    this.calculatedPercentage.seconds = 100 - (
      (this._timeLeft.getUTCSeconds()
      ) / 60 * 100);

    this.countdownTick.emit(this._timeLeft);
  }

}
