import { BaseObject } from '../BaseObject';
import { Section } from './Section';

export class Sections extends BaseObject {
  private readonly _sections: Section[];

  constructor(data: any, objectType?: string) {
    super(objectType || 'Sections', data);

    this._sections = data.sections;
  }

  get sections(): Section[] {
    return this._sections;
  }
}
