import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardsModule } from 'cards';
import { CarouselFlickityComponent } from './carousel-flickity.component';

@NgModule({
  declarations: [CarouselFlickityComponent],
  imports: [
    CommonModule,
    CardsModule
  ],
  exports: [
    CarouselFlickityComponent
  ]
})
export class CarouselFlickityModule { }
