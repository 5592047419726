import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { AnywhereApiConfiguration, RestApiConfiguration } from 'wordpress-adapter';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private anywhereCache: { [key: string]: { subject: BehaviorSubject<any>; apiConfiguration: AnywhereApiConfiguration; } };

  public static generateFullAnywhereUrl(anywhereApiConfiguration: AnywhereApiConfiguration): string {
    const previousUrlParams = ApiService.extractUrlParamsFromExistingBaseUrl(anywhereApiConfiguration),
      url = ApiService.generateAnywhereUrl(anywhereApiConfiguration),
      urlParams = ApiService.generateAnywhereUrlParams(anywhereApiConfiguration, previousUrlParams);

    return `${url}?${urlParams}`;
  }

  public static removeQueryParamsFromUrl(url: string): string {
    const urlParamIndex = url.indexOf('?');

    return  urlParamIndex === -1
        ? url
        : url.substring(0, urlParamIndex);
  }

  private static getQueryParamsFromUrl(url: string): string {
    const urlParamIndex = url.indexOf('?');

    return  urlParamIndex === -1
      ? ''
      : url.substring(urlParamIndex + 1);
  }

  private static extractUrlParamsFromExistingBaseUrl(anywhereApiConfiguration: AnywhereApiConfiguration): { [key: string]: string } {
    const urlParamIndex = anywhereApiConfiguration.baseUrl.indexOf('?');

    if (urlParamIndex !== -1) {
      return anywhereApiConfiguration.baseUrl.substring(urlParamIndex + 1)
        .split('&')
        .reduce((previousValue, currentValue) => {
          const keyValue = currentValue.split('=');
          return {
            ...previousValue,
            [keyValue[0]]: keyValue[1]
          };
        }, {});
    }

    return {};
  }

  private static generateAnywhereUrl(anywhereApiConfiguration: AnywhereApiConfiguration): string {
    const url = [ ApiService.removeQueryParamsFromUrl(anywhereApiConfiguration.baseUrl) ];

    if (anywhereApiConfiguration.targetId) {
      url.push('/', anywhereApiConfiguration.targetId);
    }

    if (anywhereApiConfiguration.apiFeedId) {
      url.push('/en/feed/', anywhereApiConfiguration.apiFeedId, '/sections/');
    }

    return url.join('');
  }

  private static generateHeaderData(data: { key: string; value: string; }[]): HttpHeaders {
    const httpHeaderObject = data.reduce((previousValue, currentValue) => {
      return {
        ...previousValue,
        [currentValue.key]: currentValue.value
      };
    }, {});

    return new HttpHeaders(httpHeaderObject);
  }

  private static generateAnywhereUrlParams(
    anywhereApiConfiguration: AnywhereApiConfiguration,
    previousUrlParams: { [key: string]: string }): string {

    const data: string[] = [];

    if (anywhereApiConfiguration.sectionId || previousUrlParams.section) {
      data.push(`section=${anywhereApiConfiguration.sectionId || previousUrlParams.section}`);
    }

    const previousUrlPageIndex = previousUrlParams.pageIndex || previousUrlParams.pageindex;
    if (anywhereApiConfiguration.pageIndex || previousUrlPageIndex) {
      data.push(`pageIndex=${anywhereApiConfiguration.pageIndex || previousUrlPageIndex}`);
    }

    const previousUrlPageSize = previousUrlParams.pageSize || previousUrlParams.pagesize;
    if (anywhereApiConfiguration.pageSize || previousUrlPageSize) {
      data.push(`pageSize=${anywhereApiConfiguration.pageSize || previousUrlPageSize}`);
    }

    const previousUrlSortBy = previousUrlParams.sortBy || previousUrlParams.sortby;
    if (anywhereApiConfiguration.sortBy || previousUrlSortBy) {
      data.push(`sortBy=${anywhereApiConfiguration.sortBy || previousUrlSortBy}`);
    }

    const previousUrlSortOrder = previousUrlParams.sortOrder || previousUrlParams.sortorder;
    if (anywhereApiConfiguration.sortOrder || previousUrlSortOrder) {
      data.push(`sortOrder=${anywhereApiConfiguration.sortOrder || previousUrlSortOrder}`);
    }

    if (anywhereApiConfiguration.searchQuery || previousUrlParams.query) {
      data.push(`query=${anywhereApiConfiguration.searchQuery || previousUrlParams.query}`);
    }

    return data.join('&');
  }

  constructor(private http: HttpClient) {
    this.anywhereCache = {};
  }

  public getApiData(restApiConfiguration: RestApiConfiguration): Observable<any> {
    if (!restApiConfiguration) {
      console.error('Rest API Configuration is not set.');
      return of(null);
    }

    const headerData = ApiService.generateHeaderData(restApiConfiguration.headerData);

    return this.http.request<any>(
      restApiConfiguration.apiRequestMethod || 'GET',
      restApiConfiguration.baseUrl,
      {
        headers: headerData
      });
  }

  public getAnyWhereData(anywhereApiConfiguration: AnywhereApiConfiguration): Observable<any> {
    if (!anywhereApiConfiguration) {
      console.error('Anywhere API Configuration is not set.');
      return of(null);
    }

    const url = ApiService.generateFullAnywhereUrl(anywhereApiConfiguration);
    const urlKey = ApiService.removeQueryParamsFromUrl(anywhereApiConfiguration.baseUrl);

    this.setupCachePlaceholder(urlKey, anywhereApiConfiguration);
    this.triggerAnywhereApiRequest(urlKey, url, anywhereApiConfiguration);

    return this.anywhereCache[urlKey].subject.asObservable();
  }

  private triggerAnywhereApiRequest(urlKey: string, url: string, anywhereApiConfiguration: AnywhereApiConfiguration): void {
    this.http
      .request<any>(anywhereApiConfiguration.apiRequestMethod || 'GET', url)
      .subscribe(data => {
        this.anywhereCache[urlKey].subject.next(data);
      });
  }

  public clearAnywhereApiCache(anywhereApiConfiguration: AnywhereApiConfiguration): void {
    const url = ApiService.generateAnywhereUrl(anywhereApiConfiguration);

    if (this.anywhereCache[url]) {
      this.anywhereCache[url].subject.complete();

      const { [url]: exclude, ...others } = this.anywhereCache;
      this.anywhereCache = others;
    }
  }

  private setupCachePlaceholder(urlKey: string, anywhereApiConfiguration: AnywhereApiConfiguration): void {
    if (!this.anywhereCache[urlKey]) {
      // Generate placeholder
      this.anywhereCache = {
        ...this.anywhereCache,
        [urlKey]: {
          subject: new BehaviorSubject(null),
          apiConfiguration: anywhereApiConfiguration
        }
      };
    }
  }

  public triggerSearchOnAnywhereApiForAllExistingRequests(search: string): void {
    const searchAnywhereConfig = new AnywhereApiConfiguration({ searchQuery: search }, true);

    Object.keys(this.anywhereCache).forEach(urlKey => {
      const currentObject = this.anywhereCache[urlKey],
        newConfiguration = AnywhereApiConfiguration.merge(currentObject.apiConfiguration, searchAnywhereConfig);

      const url = ApiService.generateFullAnywhereUrl(newConfiguration);
      this.triggerAnywhereApiRequest(urlKey, url, newConfiguration);
    });
  }
}
