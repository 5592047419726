/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./navigation-link.component";
var styles_NavigationLinkComponent = [];
var RenderType_NavigationLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavigationLinkComponent, data: {} });
export { RenderType_NavigationLinkComponent as RenderType_NavigationLinkComponent };
function View_NavigationLinkComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [["class", "nav-link"], ["routerLinkActive", "active"]], [[8, "innerHTML", 1], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.internalLinkClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, [[2, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(3, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.getInternalPath(); _ck(_v, 2, 0, currVal_3); var currVal_4 = "active"; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLabel(); var currVal_1 = i0.ɵnov(_v, 2).target; var currVal_2 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_NavigationLinkComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "a", [["class", "nav-link"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerExternalRedirect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLabel(); _ck(_v, 0, 0, currVal_0); }); }
export function View_NavigationLinkComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavigationLinkComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["externalLink", 2]], null, 0, null, View_NavigationLinkComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isInternalLink(); var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_NavigationLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-navigation-link", [], [[1, "class", 0]], null, null, View_NavigationLinkComponent_0, RenderType_NavigationLinkComponent)), i0.ɵdid(1, 49152, null, 0, i3.NavigationLinkComponent, [i2.DOCUMENT], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var NavigationLinkComponentNgFactory = i0.ɵccf("amg-wp-navigation-link", i3.NavigationLinkComponent, View_NavigationLinkComponent_Host_0, { navigationLink: "navigationLink", rowNumber: "rowNumber" }, { triggerClick: "triggerClick" }, []);
export { NavigationLinkComponentNgFactory as NavigationLinkComponentNgFactory };
