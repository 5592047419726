import { EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUtils } from 'amg-fe-utils';
var VideoCardComponent = /** @class */ (function () {
    function VideoCardComponent(sanitizer) {
        this.sanitizer = sanitizer;
        this.defaultImage = ImageUtils.emptyImage;
        this.triggerClick = new EventEmitter();
    }
    VideoCardComponent._getData = function (key, object, arraySeparator) {
        var keys = key.split('.');
        var data = object;
        for (var idx = 0; idx < keys.length; idx++) {
            var currentKey = keys[idx];
            if (data[currentKey]) {
                data = data[currentKey];
            }
            else {
                idx = keys.length;
                data = null;
            }
        }
        if (data) {
            if (Array.isArray(data)) {
                return data.join(arraySeparator);
            }
            else if (typeof data === 'object') {
                // Object is not handled, usually this case relates to incorrect KEY from user.
                return '';
            }
            else {
                return data;
            }
        }
        else {
            return '';
        }
    };
    VideoCardComponent.prototype.ngOnInit = function () {
    };
    VideoCardComponent.prototype.getThumbnail = function () {
        var thumbnailKeyObject = this.cardConfiguration.displayKeys.thumbnailKey;
        var url = VideoCardComponent._getData(thumbnailKeyObject.key, this.cardData, VideoCardComponent.defaultArraySeparator);
        return (thumbnailKeyObject.transform)
            ? thumbnailKeyObject.transform(url)
            : url;
    };
    VideoCardComponent.prototype.getData = function (keyObject) {
        if (typeof keyObject === 'string') {
            return VideoCardComponent._getData(keyObject, this.cardData, VideoCardComponent.defaultArraySeparator);
        }
        else {
            var key = keyObject.key;
            var arraySeparator = keyObject.arraySeparator || VideoCardComponent.defaultArraySeparator;
            var data = VideoCardComponent._getData(key, this.cardData, arraySeparator);
            if (keyObject.transform) {
                return this.sanitizer.bypassSecurityTrustHtml(keyObject.transform(data));
            }
            else {
                return data;
            }
        }
    };
    VideoCardComponent.prototype.getClassName = function (keyObject) {
        var key = (typeof keyObject === 'string')
            ? keyObject
            : keyObject.key;
        var keys = key.split('.');
        return keys[keys.length - 1].toLowerCase();
    };
    VideoCardComponent.prototype.onCardClick = function () {
        this.triggerClick.emit(this.cardData);
    };
    VideoCardComponent.defaultArraySeparator = ' ';
    return VideoCardComponent;
}());
export { VideoCardComponent };
