import * as tslib_1 from "tslib";
import { HammerGestureConfig } from '@angular/platform-browser';
var MyHammerConfig = /** @class */ (function (_super) {
    tslib_1.__extends(MyHammerConfig, _super);
    function MyHammerConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyHammerConfig.prototype.buildHammer = function (element) {
        var mc = new Hammer(element, {
            pan: { direction: Hammer.DIRECTION_HORIZONTAL },
            swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
        });
        return mc;
    };
    return MyHammerConfig;
}(HammerGestureConfig));
export { MyHammerConfig };
