import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { GridFilter, GridFilterGroup } from '../../../types/GridStaticProperties';
import { FilterByDropdown, SearchTrigger } from '../../../types/SearchTrigger';

@Component({
  selector: 'amg-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FilterDropdownComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-filter-dropdown';
  @Input() gridFilterGroup: GridFilterGroup;
  @Input() gridIndexId: number;
  @Input() resetFilterObservable: Observable<boolean>;
  @Input() resetFilterInternalObservable: Observable<boolean>;
  @Output() triggerFilter: EventEmitter<SearchTrigger>;

  @Input() resetFilter: EventEmitter<Date>;

  public dropdownModel: GridFilter;

  private resetFilterSubscription: Subscription;
  private resetFilterInternalSubscription: Subscription;

  private static generateFilterByDropdown(value: GridFilter, id: number, displayKey?: string): FilterByDropdown {
    return {
      data: value,
      id,
      ...(displayKey ? { displayKey } : {})
    };
  }

  constructor() {
    this.triggerFilter = new EventEmitter<SearchTrigger>();
  }

  ngOnInit() {
    if (this.resetFilterObservable) {
      this.resetFilterSubscription = this.resetFilterObservable.subscribe(reset => {
        if (reset) {
          this.resetData();
        }
      });
    }

    this.resetFilterInternalSubscription = this.resetFilterInternalObservable.subscribe(reset => {
      if (reset) {
        this.resetData();
      }
    });

    this.selectAndSetFirstItemFromDropdown();
  }

  ngOnDestroy(): void {
    this.resetFilterInternalSubscription.unsubscribe();

    if (this.resetFilterSubscription) {
      this.resetFilterSubscription.unsubscribe();
    }
  }

  selectAndSetFirstItemFromDropdown(): void {
    if (this.gridFilterGroup.dropdown.length > 0) {
      this.dropdownModel = {
        ...this.gridFilterGroup.dropdown[0]
      };
    }
  }

  isSearchable(): boolean {
    return !!this.gridFilterGroup.isSearchable;
  }

  getFilterLabel(): string {
    return this.gridFilterGroup.label;
  }

  getFilters(): GridFilter[] {
    return this.gridFilterGroup.dropdown;
  }

  onFilterChange(value: GridFilter): void {
    const filterByDropdown = FilterDropdownComponent.generateFilterByDropdown(value, this.gridIndexId, this.gridFilterGroup.urlDisplayKey);
    this.triggerFilter.emit({
      type: 'filter',
      filterData: filterByDropdown
    });
  }

  private resetData(): void {
    this.selectAndSetFirstItemFromDropdown();
  }
}
