import * as tslib_1 from "tslib";
import { CollectionUtils } from '../../../util/CollectionUtils';
import { Sections } from './Sections';
var Page = /** @class */ (function (_super) {
    tslib_1.__extends(Page, _super);
    function Page(data) {
        var _this = _super.call(this, data, 'Page') || this;
        if (data.section_api_configuration) {
            var configDataset = CollectionUtils.retrieveConfigurationData(data.section_api_configuration);
            _this._baseConfiguration = configDataset.baseConfiguration;
            _this._anywhereApiConfiguration = configDataset.anywhereApiConfiguration;
            _this._kalturaConfiguration = configDataset.kalturaConfiguration;
            _this._restApiConfiguration = configDataset.restApiConfiguration;
        }
        return _this;
    }
    Object.defineProperty(Page.prototype, "anywhereApiConfiguration", {
        get: function () {
            return this._anywhereApiConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "baseConfiguration", {
        get: function () {
            return this._baseConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "restApiConfiguration", {
        get: function () {
            return this._restApiConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Page.prototype, "kalturaConfiguration", {
        get: function () {
            return this._kalturaConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    return Page;
}(Sections));
export { Page };
