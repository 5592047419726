/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./live-icons.component";
import * as i3 from "@angular/common/http";
var styles_LiveIconsComponent = [];
var RenderType_LiveIconsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LiveIconsComponent, data: {} });
export { RenderType_LiveIconsComponent as RenderType_LiveIconsComponent };
function View_LiveIconsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "amg-live-icon video"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeMedia(_co.videoEntryData) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_LiveIconsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "amg-live-icon audio"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeMedia(_co.audioEntryData) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_LiveIconsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "amg-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "amg-live-icons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LiveIconsComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LiveIconsComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoEntryData; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.audioEntryData; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_LiveIconsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LiveIconsComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isVideoLiveLocalRef || _co.isAudioLiveLocalRef); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LiveIconsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-live-icons", [], null, null, null, View_LiveIconsComponent_0, RenderType_LiveIconsComponent)), i0.ɵdid(1, 114688, null, 0, i2.LiveIconsComponent, [i3.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LiveIconsComponentNgFactory = i0.ɵccf("amg-live-icons", i2.LiveIconsComponent, View_LiveIconsComponent_Host_0, { media: "media" }, { mediaRequest: "mediaRequest" }, []);
export { LiveIconsComponentNgFactory as LiveIconsComponentNgFactory };
