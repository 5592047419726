import * as tslib_1 from "tslib";
import { GridSource } from 'grid';
import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { NgObject } from './NgObject';
import { CardType } from 'card';
var Grid = /** @class */ (function (_super) {
    tslib_1.__extends(Grid, _super);
    function Grid(data) {
        var _this = _super.call(this, 'Grid', data) || this;
        _this._gridStaticData = Grid.parseStaticData(data.static_data);
        _this._gridConfiguration = CollectionUtils
            .flattenArrayOfResponsiveConfiguration(data.ui_conf, Grid.gridConfigurationMapping);
        return _this;
    }
    Object.defineProperty(Grid.prototype, "gridConfiguration", {
        get: function () {
            return this._gridConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Grid.prototype, "gridStaticData", {
        get: function () {
            return this._gridStaticData;
        },
        enumerable: true,
        configurable: true
    });
    Grid.parseStaticData = function (data) {
        var pageSize = CollectionUtils.parseNumber(data.pageSize);
        return tslib_1.__assign({ cardType: CardType.Video, pageUrl: data.pageUrl, pageSource: Grid.getGridSource(data.pageSource), pageStart: CollectionUtils.parseNumber(data.pageStart), pageSize: pageSize, loadMore: data.enableLoadMore, loadMoreText: data.loadMoreText, pagination: data.pagination
                ? { itemsPerPage: pageSize || 25 }
                : false }, (data.enableSearchBar ? { searchBarProperties: this.generateSearchBarProperties(data.searchBar) } : {}));
    };
    Grid.generateSearchBarProperties = function (data) {
        var searchBarProperties = tslib_1.__assign({}, (data.searchProperties ? { searchField: this.generateSearchProperties(data.searchProperties) } : {}), (data.filterProperties ? { searchFilter: this.generateFilterProperties(data.filterProperties) } : {}));
        return searchBarProperties;
    };
    Grid.generateSearchProperties = function (data) {
        var searchProperties = tslib_1.__assign({}, (data.label ? { label: data.label } : {}), (data.placeholder ? { placeholder: data.placeholder } : {}), (data.key ? { key: data.key } : {}));
        return searchProperties;
    };
    Grid.generateFilterProperties = function (data) {
        var filterProperties = {
            filters: (data && data.length > 0)
                ? data.map(function (dropDown) {
                    return {
                        label: dropDown.label,
                        dropdown: dropDown.dropdown.map(function (dropdownData) {
                            return tslib_1.__assign({ value: dropdownData.value, label: dropdownData.label }, (dropdownData.key ? { key: dropdownData.key } : {}));
                        })
                    };
                })
                : []
        };
        return filterProperties;
    };
    Grid.getGridSource = function (pageSource) {
        return (pageSource === 'Anywhere')
            ? GridSource.Anywhere
            : GridSource.Wordpress;
    };
    Grid.gridConfigurationMapping = function (data) {
        var collapsibleConfiguration = {
            itemsToShow: CollectionUtils.parseNumber(data.items),
            rightMarginPerItem: CollectionUtils.parseNumber(data.margin),
            autoLoad: data.autoload,
            autoLoadDistance: CollectionUtils.parseNumber(data.autoloadDistance),
            responsiveRefreshRate: CollectionUtils.parseNumber(data.responsiveRefreshRate),
        };
        return collapsibleConfiguration;
    };
    Grid.dropdownFiltersMapping = function (data) {
        var filters = data.map(function (filter) {
            return {
                label: filter.filterLabel,
                filters: filter.filters.map(function (value) { return value.filterBy; })
            };
        });
        return filters;
    };
    return Grid;
}(NgObject));
export { Grid };
