import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PaymentSubscribePackages } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-subscribe-packages',
  templateUrl: './subscribe-packages.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SubscribePackagesComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-subscription-packages';
  @Input() subscribePackages: PaymentSubscribePackages;

  ngOnInit(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }
  }

  getCss(): string {
    return this.subscribePackages.cssClass;
  }
}
