/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./login-overlay.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../video-player.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../user-auth/src/lib/user-auth.service";
var styles_LoginOverlayComponent = [];
var RenderType_LoginOverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginOverlayComponent, data: {} });
export { RenderType_LoginOverlayComponent as RenderType_LoginOverlayComponent };
function View_LoginOverlayComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "amg-title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.liveLoginOverlayRef.title; _ck(_v, 0, 0, currVal_0); }); }
function View_LoginOverlayComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "amg-sub-title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.liveLoginOverlayRef.subTitle; _ck(_v, 0, 0, currVal_0); }); }
function View_LoginOverlayComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "button", [["class", "btn amg-subscribe-btn"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLink(_co.liveLoginOverlayRef.subscribeBtn.link) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.liveLoginOverlayRef.subscribeBtn.label; _ck(_v, 0, 0, currVal_0); }); }
function View_LoginOverlayComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "btn amg-separator-text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.liveLoginOverlayRef.buttonSeparator; _ck(_v, 0, 0, currVal_0); }); }
function View_LoginOverlayComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "button", [["class", "btn amg-log-in-btn"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.userAuthService.openModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.liveLoginOverlayRef.logInBtn.label; _ck(_v, 0, 0, currVal_0); }); }
function View_LoginOverlayComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginOverlayComponent_6)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginOverlayComponent_7)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.liveLoginOverlayRef.buttonSeparator; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.liveLoginOverlayRef.logInBtn; _ck(_v, 3, 0, currVal_1); }, null); }
function View_LoginOverlayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "amg-video-login-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginOverlayComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginOverlayComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "amg-btn-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginOverlayComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginOverlayComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.liveLoginOverlayRef.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.liveLoginOverlayRef.subTitle; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.liveLoginOverlayRef.subscribeBtn; _ck(_v, 7, 0, currVal_2); var currVal_3 = ((_co.videoPlayerService.mediaAuthResponse && (_co.videoPlayerService.mediaAuthResponse.Status === 0)) || !_co.videoPlayerService.mediaAuthResponse); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_LoginOverlayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginOverlayComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.videoPlayerService.canUserPlayMedia() && _co.videoPlayerService.mediaAuthResponse); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoginOverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-login-overlay", [], null, null, null, View_LoginOverlayComponent_0, RenderType_LoginOverlayComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoginOverlayComponent, [i3.NgbModal, i4.VideoPlayerService, i5.Router, i6.UserAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginOverlayComponentNgFactory = i0.ɵccf("amg-login-overlay", i2.LoginOverlayComponent, View_LoginOverlayComponent_Host_0, {}, {}, []);
export { LoginOverlayComponentNgFactory as LoginOverlayComponentNgFactory };
