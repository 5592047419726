import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import 'hammerjs';

import { CardFixtureModule } from 'card-fixture';
import { CardImageModule } from 'card-image';
import { CardTextModule } from 'card-text';
import { CardVideoModule } from 'card-video';

import { CarouselComponent } from './carousel.component';
import { MyHammerConfig } from './MyHammerConfig';

declare var Hammer: any;

@NgModule({
  declarations: [
    CarouselComponent
  ],
  imports: [
    CommonModule,
    CardFixtureModule,
    CardImageModule,
    CardTextModule,
    CardVideoModule,
  ],
  exports: [
    CarouselComponent,
  ],
  entryComponents: [
  ],
  providers: [
    {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig,
  }
],
})
export class CarouselModule { }
