import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { PaymentVideoPlayer, KalturaConfiguration, BaseConfiguration } from 'wordpress-adapter';
import { ApiService } from '../../../../../shared/api/api.service';
import { VideoPlayerConfig, VideoSignUpConfig, AnywhereItemData, AnywhereConfig } from 'video-player';
import { DataService } from '../../../../../shared/data/data.service';

@Component({
  selector: 'amg-wp-video-player',
  templateUrl: './video-player.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-video-player';
  @Input() videoPlayerConfiguration: { drmEntries: string[]; isDesktopDevice: boolean; };
  @Input() videoPlayer: PaymentVideoPlayer;
  @Output() triggerChange: EventEmitter<string>;
  @Output() triggerDisabledByDrm: EventEmitter<boolean>;

  public isInitialised: boolean;
  public canInitialiseVideoPlayer: boolean;

  private videoPlayerBaseConfiguration: BaseConfiguration;
  private videoPlayerKalturaConfiguration: KalturaConfiguration;

  private videoPlayerData: AnywhereItemData;
  private playlistUrl: string;
  private entryId: string;

  private entryIdObservable: Subscription;
  private routerSubscription: Subscription;

  private static generateBaseConfiguration(data: BaseConfiguration): AnywhereConfig {
    return {
      apiKey: data.apiKey,
      apiUrl: data.apiBaseUrl,
      apiUserId: data.apiUserId
    };
  }

  private static removeQueryParams(url: string): string {
    const index = url.indexOf('?');

    return index !== -1
      ? url.substring(0, index)
      : url;
  }

  private static getMediaEntryId(data: any): string {
    return (data && data.mediaData && data.mediaData.entryId)
      ? data.mediaData.entryId
      : null;
  }

  constructor(private apiService: ApiService,
              private dataService: DataService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.isInitialised = false;
    this.triggerChange = new EventEmitter<string>();
    this.triggerDisabledByDrm = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.canInitialiseVideoPlayer = ( (!this.videoPlayerConfiguration)            // Video Player without DRM (backward compatible)
      || (!this.isDrmEntry(this.videoPlayer.kalturaPlayerConfiguration.entryId))  // Video Player without DRM
      || (  this.isDrmEntry(this.videoPlayer.kalturaPlayerConfiguration.entryId)  // Video Player with DRM
            && this.videoPlayerConfiguration.isDesktopDevice) );

    this.triggerDisabledByDrm.emit(!this.canInitialiseVideoPlayer);

    if (this.canInitialiseVideoPlayer) {
      this.isInitialised = true;

      this.initialiseVideoPlayer();

      this.routerSubscription = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        if (this.videoPlayer.reloadPlayerOnSamePageNavigation) {
          this.initialiseVideoPlayer(true);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.entryIdObservable) {
      this.entryIdObservable.unsubscribe();
    }

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    this.dataService.setEntryObject(null);
  }

  getConfiguration(): VideoPlayerConfig {
    return {
      ...( this.videoPlayer.baseConfiguration
        ? { anywhereConfig: VideoPlayerComponent.generateBaseConfiguration(this.videoPlayer.baseConfiguration) }
        : {} ),
      kalturaPlayerConfig: {
        apiUrl: this.videoPlayer.kalturaPlayerConfiguration.apiUrl,
        playerId: this.videoPlayer.kalturaPlayerConfiguration.playerId,
        partnerId: this.videoPlayer.kalturaPlayerConfiguration.partnerId,
        uiConfId: this.videoPlayer.kalturaPlayerConfiguration.uiConfigId,

        ...this.generateOptionalKalturaConfiguration()
      }
    };
  }

  getPlayButtonConfig(): VideoSignUpConfig {
    return {
      playButton: {
        audio: this.videoPlayer.audioPlayButton.url,
        video: this.videoPlayer.videoPlayButton.url
      }
    };
  }

  getCss(): string {
    return this.videoPlayer.cssClass;
  }

  getVideoPlayerData(): AnywhereItemData {
    return this.videoPlayerData;
  }

  getPlaylistUrl(): string {
    return this.playlistUrl;
  }

  getEntryId(): string {
    return this.entryId;
  }

  getLiveLoginOverlay(): any {
    return this.videoPlayer.liveLoginOverlay;
  }

  getDrmMessage(): string {
    return this.videoPlayer.drmMessage;
  }

  onPlaylistReady(): void {
    this.triggerChange.emit('playlist');
  }

  onEntryChange(entryId: string): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        entry: entryId
      },
      queryParamsHandling: 'merge',
      preserveFragment: true
    });
  }

  private isDrmEntry(entry: string): boolean {
    return this.videoPlayerConfiguration.drmEntries.find(drmEntry => drmEntry === entry) !== undefined;
  }

  private generateOptionalKalturaConfiguration(): { [key: string]: any } {
    return {
      ...( this.videoPlayer.kalturaPlayerConfiguration.entryId
        ? { entryId: this.videoPlayer.kalturaPlayerConfiguration.entryId }
        : {} ),

      ...( this.videoPlayer.kalturaPlayerConfiguration.forcePlayerEmbed
        ? { forcePlayerEmbed: this.videoPlayer.kalturaPlayerConfiguration.forcePlayerEmbed }
        : {} ),

      ...( this.videoPlayer.kalturaPlayerConfiguration.flashVars
        ? { flashvars: this.videoPlayer.kalturaPlayerConfiguration.flashVars }
        : {} )
    };
  }

  private initialiseVideoPlayer(isRestartingPlayer?: boolean): void {
    const userInteractedObject = this.getInteractedObject();

    if (userInteractedObject) {
      this.videoPlayerBaseConfiguration = this.videoPlayer.baseConfiguration;
      this.videoPlayerKalturaConfiguration = this.videoPlayer.kalturaPlayerConfiguration;

      this.playlistUrl = userInteractedObject.url;
      this.videoPlayerData = userInteractedObject.data;
      this.entryId = userInteractedObject.entryId;
    } else {
      this.videoPlayerBaseConfiguration = this.videoPlayer.baseConfiguration;
      this.videoPlayerKalturaConfiguration = this.videoPlayer.kalturaPlayerConfiguration;
    }

    if (isRestartingPlayer) {
      this.isInitialised = false;

      of(null)
        .pipe( delay(1) )
        .subscribe(value => {
          this.isInitialised = true;
      });
    }
  }

  private getInteractedObject(): { url: string; data: any; entryId?: string; } {
    const interactedObject = this.dataService.userInteractedObject;

    if (interactedObject) {
      const entryId = VideoPlayerComponent.getMediaEntryId(interactedObject.data);
      const url = interactedObject.fixtureUrl
        ? VideoPlayerComponent.removeQueryParams(interactedObject.fixtureUrl)
        : interactedObject.playlistUrl;
      const data = interactedObject.data;

      return {entryId, url, data};
    } else {
      // Get data from URL params
      const snapshotEntryId = this.activatedRoute.snapshot.queryParams.entry,
        snapshotPlaylistUrl = this.activatedRoute.snapshot.queryParams.playlist,
        snapshotFixtureUrl = this.activatedRoute.snapshot.queryParams.fixture;

      if (snapshotEntryId || snapshotPlaylistUrl || snapshotFixtureUrl) {
        const entryId = snapshotEntryId || null;
        let url: string = null;

        if (snapshotPlaylistUrl) {
          url = this.dataService.decodeUrl(snapshotPlaylistUrl);
        } else if (snapshotFixtureUrl) {
          url = VideoPlayerComponent.removeQueryParams(this.dataService.decodeUrl(snapshotFixtureUrl));
        }

        return {entryId, url, data: null};
      }
    }

    return null;
  }
}
