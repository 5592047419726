import { HttpClient } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InputFormGroup } from 'wordpress-adapter';
var InputFormGroupComponent = /** @class */ (function () {
    function InputFormGroupComponent(http) {
        this.http = http;
        this.class = 'amg-form-group';
        this.data = {
            isFormSubmitted: false,
            formError: false,
            formData: {}
        };
        this.inputClearSubject = new BehaviorSubject(false);
    }
    InputFormGroupComponent.prototype.ngOnDestroy = function () {
        this.inputClearSubject.complete();
    };
    InputFormGroupComponent.prototype.triggerFieldChange = function (data) {
        this.data.formData[data.inputField.referenceName] = data.value;
    };
    InputFormGroupComponent.prototype.getInputFields = function () {
        return this.inputFormGroup.inputFields;
    };
    InputFormGroupComponent.prototype.getButtonText = function () {
        return this.inputFormGroup.submitButton
            ? this.inputFormGroup.submitButton.label
            : 'Submit';
    };
    InputFormGroupComponent.prototype.getButtonCssClass = function () {
        return this.inputFormGroup.submitButton
            ? this.inputFormGroup.submitButton.cssClass
            : '';
    };
    InputFormGroupComponent.prototype.getFormActionUrl = function () {
        return this.inputFormGroup.restApiConfiguration.baseUrl;
    };
    InputFormGroupComponent.prototype.onSubmit = function (event) {
        var _this = this;
        if (event.target.checkValidity()) {
            this.http.post(this.getFormActionUrl(), this.data.formData).subscribe(function (value) {
                if (value
                    && value.ModelErrors
                    && Object.keys(value.ModelErrors).length > 0) {
                    _this.triggerError();
                }
                else {
                    _this.data = {
                        isFormSubmitted: true,
                        formError: false,
                        formData: {}
                    };
                    event.target.reset();
                    _this.inputClearSubject.next(true);
                }
            }, function (error1) {
                _this.triggerError();
            });
        }
        else {
            this.triggerError();
        }
        return false;
    };
    InputFormGroupComponent.prototype.getInputClearAsObservable = function () {
        return this.inputClearSubject.asObservable();
    };
    InputFormGroupComponent.prototype.triggerError = function () {
        this.data = {
            isFormSubmitted: false,
            formError: true,
            formData: this.data.formData
        };
    };
    return InputFormGroupComponent;
}());
export { InputFormGroupComponent };
