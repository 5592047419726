import { HttpClient, HttpParams } from '@angular/common/http';
import { configs } from '../../../core/configs';
import { environment } from '../../../../environments/environment';
import { UserAuthSsoService } from '@amg-fe-angular/services/user-auth/user-auth-sso.service';
import { SportsAllianceUtil } from '../../utils/SportsAllianceUtil';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "amg-fe-angular/library/services/user-auth/user-auth-sso.service";
import * as i3 from "@angular/common";
var SsoService = /** @class */ (function () {
    function SsoService(httpClient, userAuthService, location) {
        this.httpClient = httpClient;
        this.userAuthService = userAuthService;
        this.location = location;
    }
    SsoService.prototype.init = function () {
        // Before bootstrapping application, sort out the token.
        if (window && window.location) {
            // If payload is present - then it must be a successful login
            var urlData = SportsAllianceUtil.getPayloadAndUrl(window.location.href);
            if (urlData) {
                // If payload is retrieved, then store it and clear it.
                var payload = SportsAllianceUtil.unObfuscatePayload(urlData.payload);
                var parsedData = SportsAllianceUtil.parseSsoData(payload);
                this.login(parsedData.access_token);
                this.location.replaceState(urlData.url);
            }
            else if (document.location.search.indexOf('logout=1') > -1) {
                this.logout();
            }
            else {
                this.userAuthService.streamPaymentsInit({
                    language: 'en'
                });
            }
        }
    };
    SsoService.prototype.login = function (jwt) {
        var _this = this;
        this.userAuthService.streamPaymentsInit({
            jwtToken: jwt,
            language: 'en'
        }, function (resp) {
            var paramsAuth = new HttpParams().set('token', jwt);
            _this.httpClient.request('get', environment.cloudPayUrl + configs.cloudPay.paths.start, { params: paramsAuth })
                .subscribe();
        });
    };
    SsoService.prototype.logout = function () {
        var currentUrl = new URL(document.location.href);
        currentUrl.searchParams.delete('logout');
        this.location.replaceState(currentUrl.href.replace(currentUrl.origin, ''));
        this.userAuthService.streamPaymentsLogOut(null, null);
    };
    SsoService.ngInjectableDef = i0.defineInjectable({ factory: function SsoService_Factory() { return new SsoService(i0.inject(i1.HttpClient), i0.inject(i2.UserAuthSsoService), i0.inject(i3.Location)); }, token: SsoService, providedIn: "root" });
    return SsoService;
}());
export { SsoService };
