import * as tslib_1 from "tslib";
import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { BaseObject } from '../../../BaseObject';
var PaymentVideoPlayer = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentVideoPlayer, _super);
    function PaymentVideoPlayer(data) {
        var _this = _super.call(this, 'PaymentVideoPlayer', data) || this;
        _this._audioPlayButton = data.amg_play_button_group.audio_play_button;
        _this._videoPlayButton = data.amg_play_button_group.video_play_button;
        _this._drmMessage = data.drm_message || '';
        _this._liveLoginOverlay = PaymentVideoPlayer.mapToLoginOverlay(data.login_overlay);
        _this._reloadPlayerOnSamePageNavigation = data.hasOwnProperty('reload_player_on_same_page_navigation')
            ? !!data.reload_player_on_same_page_navigation
            : false;
        if (data.player_configuration && data.player_configuration.length > 0) {
            var configDataset = CollectionUtils.retrieveConfigurationData(data.player_configuration);
            _this._baseConfiguration = configDataset.baseConfiguration;
            _this._kalturaPlayerConfiguration = configDataset.kalturaConfiguration;
        }
        return _this;
    }
    PaymentVideoPlayer.mapToLoginOverlay = function (data) {
        var actionLink = data.loginButtonGroup.textLinkButton, link = actionLink.path ? actionLink.path : actionLink.externalLink;
        var button = data.loginButtonGroup.button;
        return {
            title: data.title,
            subTitle: data.subtitle,
            buttonSeparator: data.loginButtonGroup.textBetween,
            subscribeBtn: {
                label: actionLink.label,
                link: link,
                cssClass: actionLink.label
            },
            logInBtn: {
                label: button.label,
                link: null,
                cssClass: button.cssClass
            }
        };
    };
    Object.defineProperty(PaymentVideoPlayer.prototype, "drmMessage", {
        get: function () {
            return this._drmMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentVideoPlayer.prototype, "reloadPlayerOnSamePageNavigation", {
        get: function () {
            return this._reloadPlayerOnSamePageNavigation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentVideoPlayer.prototype, "kalturaPlayerConfiguration", {
        get: function () {
            return this._kalturaPlayerConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentVideoPlayer.prototype, "baseConfiguration", {
        get: function () {
            return this._baseConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentVideoPlayer.prototype, "audioPlayButton", {
        get: function () {
            return this._audioPlayButton;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentVideoPlayer.prototype, "videoPlayButton", {
        get: function () {
            return this._videoPlayButton;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentVideoPlayer.prototype, "liveLoginOverlay", {
        get: function () {
            return this._liveLoginOverlay;
        },
        enumerable: true,
        configurable: true
    });
    return PaymentVideoPlayer;
}(BaseObject));
export { PaymentVideoPlayer };
