import * as tslib_1 from "tslib";
var GridResponsivePropertiesView = /** @class */ (function () {
    function GridResponsivePropertiesView() {
    }
    GridResponsivePropertiesView.defaultValues = function (input) {
        var toReturn;
        if (input === undefined) {
            toReturn = new GridResponsivePropertiesView();
        }
        else {
            toReturn = input;
        }
        toReturn = tslib_1.__assign({}, toReturn, {
            items: 4,
            margin: 0,
            lazyLoad: false,
            lazyLoadEager: 0,
            autoload: false,
            autoloadDistance: 1,
            refreshRate: 200,
            responsiveBaseElement: {},
            animateOut: '',
            animateIn: '',
            cardView: {},
        });
        return toReturn;
    };
    return GridResponsivePropertiesView;
}());
export { GridResponsivePropertiesView };
