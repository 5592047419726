<ng-container *ngIf="isInternalLink(); else externalLink">
  <a class="nav-link"
     routerLinkActive="active"
     [innerHTML]="getLabel()"
     [routerLink]="getInternalPath()"
     (click)="internalLinkClick()"></a>
</ng-container>

<ng-template #externalLink>
  <a class="nav-link"
     [innerHTML]="getLabel()"
     (click)="triggerExternalRedirect()"></a>
</ng-template>
