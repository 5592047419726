import {
  AmgComponentResponsiveProperties,
  BootstrapViewportEnum,
} from 'amg';

import { CarouselResponsivePropertiesView } from './carousel.responsive.properties.view';

export class CarouselResponsiveProperties implements AmgComponentResponsiveProperties {
  private static keys: Array<string> = Object.keys(BootstrapViewportEnum);

  xs?: CarouselResponsivePropertiesView;
  sm?: CarouselResponsivePropertiesView;
  md?: CarouselResponsivePropertiesView;
  lg?: CarouselResponsivePropertiesView;
  xl?: CarouselResponsivePropertiesView;
  HD?: CarouselResponsivePropertiesView;
  FullHd?: CarouselResponsivePropertiesView;
  FourK?: CarouselResponsivePropertiesView;

  constructor(
    params?: any,
  ) {
    if (params === undefined) {
      params = {};
    }
    this.xs = params.xs || null;
    this.sm = params.sm || null;
    this.md = params.md || null;
    this.lg = params.lg || null;
    this.xl = params.xl || null;
    this.HD = params.HD || null;
    this.FullHd = params.FullHd || null;
    this.FourK = params.FourK || null;
    this.synchroniseProperties();
  }

  private synchroniseProperties(overrideResponsive?: CarouselResponsiveProperties) {
    if (overrideResponsive === undefined) {
      overrideResponsive = {} as CarouselResponsiveProperties;
    }
    let previousView: CarouselResponsivePropertiesView = CarouselResponsivePropertiesView.defaultValues();
    CarouselResponsiveProperties.keys.forEach((viewPort) => {
      let currentView: CarouselResponsivePropertiesView = this[viewPort];
      let currentValues: CarouselResponsivePropertiesView = overrideResponsive[viewPort];
      currentView = {...previousView, ...currentView, ...currentValues};
      this[viewPort] = currentView;
      previousView = currentView;
    });
  }
}
