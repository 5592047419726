import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {VideoPlayerComponent} from './video-player/video-player.component';
import {UserAuthModule} from 'user-auth';
import {PlaylistComponent} from './video-player/playlist/playlist.component';
import { LoginOverlayComponent } from './video-player/login-overlay/login-overlay.component';
import { PlaylistCardComponent } from './video-player/playlist/playlist-card/playlist-card.component';
import { VideoDetailsComponent } from './video-player/video-details/video-details.component';
import { VideoThumbnailComponent } from './video-player/video-thumbnail/video-thumbnail.component';
import { MakeElementFullHeightDirective } from './_shared/_directives/make-element-full-height.directive';
import { LiveIconsComponent } from './video-player/playlist/playlist-card/live-icons/live-icons.component';
import { ThumbnailComponent } from './video-player/playlist/playlist-card/thumbnail/thumbnail.component';
import { DateAndTimeComponent } from './video-player/playlist/playlist-card/date-and-time/date-and-time.component';
import { FormatDatePipe } from './_shared/_pipes/format-date.pipe';
import { EntryNavComponent } from './video-player/entry-nav/entry-nav.component';
import {VideoPlayerConfig} from './_data/video-player.model';


@NgModule({
  declarations: [
    VideoPlayerComponent,
    PlaylistComponent,
    LoginOverlayComponent,
    PlaylistCardComponent,
    VideoDetailsComponent,
    VideoThumbnailComponent,
    MakeElementFullHeightDirective,
    LiveIconsComponent,
    ThumbnailComponent,
    DateAndTimeComponent,
    DateAndTimeComponent,
    FormatDatePipe,
    EntryNavComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    UserAuthModule,
    RouterModule,
    InfiniteScrollModule,
  ],
  entryComponents: [
    VideoPlayerComponent
  ],
  providers: [],
  exports: [
    VideoPlayerComponent
  ]
})

export class VideoPlayerModule {
  static forRoot(videoPlayerModuleConfig: VideoPlayerConfig): ModuleWithProviders {
    return {
      ngModule: VideoPlayerModule,
      providers: [{provide: 'videoPlayerModuleConfig', useValue: videoPlayerModuleConfig}]
    };
  }
}
