/*
 * Public API Surface of collapsible
 */

export { CollapsibleComponent } from './lib/collapsible.component';
export { CollapsableModule } from './lib/collapsible.module';

export { CollapsibleResponsiveProperties } from './lib/collapsible.responsive.properties';
export { CollapsibleResponsivePropertiesView } from './lib/collapsible.responsive.properties.view';
export { CollapsibleStaticProperties } from './lib/collapsible.static.properties';
