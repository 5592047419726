import {AmgComponentResponsiveProperties} from 'amg';

import {CardTextResponsivePropertiesView} from './card.text.responsive.properties.view';

export class CardTextResponsiveProperties implements AmgComponentResponsiveProperties {
  private static keys: Array<string> = ['xs', 'sm', 'md', 'lg', 'xl', 'HD', 'FullHd', 'FourK'];

  xs?: CardTextResponsivePropertiesView;
  sm?: CardTextResponsivePropertiesView;
  md?: CardTextResponsivePropertiesView;
  lg?: CardTextResponsivePropertiesView;
  xl?: CardTextResponsivePropertiesView;
  HD?: CardTextResponsivePropertiesView;
  FullHd?: CardTextResponsivePropertiesView;
  FourK?: CardTextResponsivePropertiesView;

  constructor(
    params?: any,
  ) {
    if (params === undefined) {
      params = {};
    }
    this.xs = params.xs || null;
    this.sm = params.sm || null;
    this.md = params.md || null;
    this.lg = params.lg || null;
    this.xl = params.xl || null;
    this.HD = params.HD || null;
    this.FullHd = params.FullHd || null;
    this.FourK = params.FourK || null;
    this.synchroniseProperties();
  }

  private synchroniseProperties(overrideResponsive?: CardTextResponsiveProperties) {
    if (overrideResponsive === undefined) {
      overrideResponsive = {} as CardTextResponsiveProperties;
    }
    let previousView: CardTextResponsivePropertiesView = CardTextResponsivePropertiesView.defaultValues();
    CardTextResponsiveProperties.keys.forEach((viewPort) => {
      let currentView: CardTextResponsivePropertiesView = this[viewPort];
      const currentValues: CardTextResponsivePropertiesView = overrideResponsive[viewPort];
      currentView = {...previousView, ...currentView, ...currentValues};
      this[viewPort] = currentView;
      previousView = currentView;
    });
  }
}
