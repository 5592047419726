/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./thumbnail.component";
var styles_ThumbnailComponent = [];
var RenderType_ThumbnailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ThumbnailComponent, data: {} });
export { RenderType_ThumbnailComponent as RenderType_ThumbnailComponent };
function View_ThumbnailComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "amg-video-duration-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.secondsToHms(_co.cardData.metaData.VideoDuration); _ck(_v, 1, 0, currVal_0); }); }
function View_ThumbnailComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["alt", "Play icon"], ["class", "amg-play-btn"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "video-icon": 0, "audio-icon": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "amg-play-btn"; var currVal_1 = _ck(_v, 2, 0, _co.videoIcon, _co.audioIcon); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ThumbnailComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "amg-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "amg-playlist-thumbnail-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ThumbnailComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "amg-fixture-thumbnail"]], null, null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(6, { backgroundImage: 0 }), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "amg-thumbnail-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ThumbnailComponent_3)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 0, "span", [["class", "amg-thumbnail-overlay-text"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardData.metaData.VideoDuration; _ck(_v, 3, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, (("url(" + _co.thumbnailUrl) + ")")); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.playIcon(); _ck(_v, 9, 0, currVal_2); }, null); }
function View_ThumbnailComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(2, { border: 0, width: 1, height: 2 })], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "solid 1px", "100%", "100%"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ThumbnailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ThumbnailComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ThumbnailComponent_4)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.thumbnailUrl; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.thumbnailUrl; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ThumbnailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-thumbnail", [], null, null, null, View_ThumbnailComponent_0, RenderType_ThumbnailComponent)), i0.ɵdid(1, 114688, null, 0, i2.ThumbnailComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThumbnailComponentNgFactory = i0.ɵccf("amg-thumbnail", i2.ThumbnailComponent, View_ThumbnailComponent_Host_0, { cardData: "cardData" }, {}, []);
export { ThumbnailComponentNgFactory as ThumbnailComponentNgFactory };
