/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./login-button-group/login-button-group.component.ngfactory";
import * as i2 from "./login-button-group/login-button-group.component";
import * as i3 from "./user-sign-in-page/user-sign-in-page.component.ngfactory";
import * as i4 from "./user-sign-in-page/user-sign-in-page.component";
import * as i5 from "./video-player/video-player.component.ngfactory";
import * as i6 from "./video-player/video-player.component";
import * as i7 from "../../../../shared/api/api.service";
import * as i8 from "../../../../shared/data/data.service";
import * as i9 from "@angular/router";
import * as i10 from "./subscribe-packages/subscribe-packages.component.ngfactory";
import * as i11 from "./subscribe-packages/subscribe-packages.component";
import * as i12 from "@angular/common";
import * as i13 from "./payment-fields-group.component";
var styles_PaymentFieldsGroupComponent = [];
var RenderType_PaymentFieldsGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaymentFieldsGroupComponent, data: {} });
export { RenderType_PaymentFieldsGroupComponent as RenderType_PaymentFieldsGroupComponent };
function View_PaymentFieldsGroupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "amg-wp-login-button-group", [], [[1, "class", 0]], null, null, i1.View_LoginButtonGroupComponent_0, i1.RenderType_LoginButtonGroupComponent)), i0.ɵdid(2, 114688, null, 0, i2.LoginButtonGroupComponent, [], { loginButtonGroup: [0, "loginButtonGroup"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getLoginButtonGroup(); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); }); }
function View_PaymentFieldsGroupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "amg-wp-user-sign-in-page", [], [[1, "class", 0]], null, null, i3.View_UserSignInPageComponent_0, i3.RenderType_UserSignInPageComponent)), i0.ɵdid(2, 114688, null, 0, i4.UserSignInPageComponent, [], { userSignInPage: [0, "userSignInPage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getUserSignInPage(); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); }); }
function View_PaymentFieldsGroupComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "amg-wp-video-player", [], [[1, "class", 0]], [[null, "triggerDisabledByDrm"], [null, "triggerChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("triggerDisabledByDrm" === en)) {
        var pd_0 = (_co.onDrmStateChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("triggerChange" === en)) {
        var pd_1 = (_co.onChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_VideoPlayerComponent_0, i5.RenderType_VideoPlayerComponent)), i0.ɵdid(2, 245760, null, 0, i6.VideoPlayerComponent, [i7.ApiService, i8.DataService, i9.ActivatedRoute, i9.Router], { videoPlayerConfiguration: [0, "videoPlayerConfiguration"], videoPlayer: [1, "videoPlayer"] }, { triggerChange: "triggerChange", triggerDisabledByDrm: "triggerDisabledByDrm" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.videoPlayerConfiguration; var currVal_2 = _co.getVideoPlayer(); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); }); }
function View_PaymentFieldsGroupComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "amg-wp-subscribe-packages", [], [[1, "class", 0]], null, null, i10.View_SubscribePackagesComponent_0, i10.RenderType_SubscribePackagesComponent)), i0.ɵdid(2, 114688, null, 0, i11.SubscribePackagesComponent, [], { subscribePackages: [0, "subscribePackages"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getSubscribePackages(); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); }); }
export function View_PaymentFieldsGroupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaymentFieldsGroupComponent_1)), i0.ɵdid(1, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaymentFieldsGroupComponent_2)), i0.ɵdid(3, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaymentFieldsGroupComponent_3)), i0.ɵdid(5, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaymentFieldsGroupComponent_4)), i0.ɵdid(7, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLoginButtonGroup(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getUserSignInPage(); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.getVideoPlayer(); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.getSubscribePackages(); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_PaymentFieldsGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-payment-fields-group", [], [[1, "class", 0]], null, null, View_PaymentFieldsGroupComponent_0, RenderType_PaymentFieldsGroupComponent)), i0.ɵdid(1, 114688, null, 0, i13.PaymentFieldsGroupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var PaymentFieldsGroupComponentNgFactory = i0.ɵccf("amg-wp-payment-fields-group", i13.PaymentFieldsGroupComponent, View_PaymentFieldsGroupComponent_Host_0, { paymentFieldsGroup: "paymentFieldsGroup", videoPlayerConfiguration: "videoPlayerConfiguration" }, { triggerChange: "triggerChange", triggerDisabledByDrm: "triggerDisabledByDrm" }, []);
export { PaymentFieldsGroupComponentNgFactory as PaymentFieldsGroupComponentNgFactory };
