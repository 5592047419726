/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../card-fixture/src/lib/components/card-calendar-fixture/card-calendar-fixture.component.ngfactory";
import * as i2 from "../../../card-fixture/src/lib/components/card-calendar-fixture/card-calendar-fixture.component";
import * as i3 from "@angular/common/http";
import * as i4 from "./relevantfixtures.pipe";
import * as i5 from "../../../carousel/src/lib/carousel/carousel.component.ngfactory";
import * as i6 from "../../../carousel/src/lib/carousel/carousel.component";
import * as i7 from "../../../amg/src/lib/bootstrap.viewport.service";
import * as i8 from "@angular/common";
import * as i9 from "./calendar.component";
var styles_CalendarComponent = [];
var RenderType_CalendarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarComponent, data: {} });
export { RenderType_CalendarComponent as RenderType_CalendarComponent };
function View_CalendarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-card-calendar-fixture", [], [[1, "is-live", 0]], [[null, "buttonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClicked" === en)) {
        var pd_0 = (_co.onFixtureClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CardCalendarFixtureComponent_0, i1.RenderType_CardCalendarFixtureComponent)), i0.ɵdid(1, 245760, null, 0, i2.CardCalendarFixtureComponent, [i3.HttpClient], { fixtureData: [0, "fixtureData"] }, { buttonClicked: "buttonClicked" })], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).isLive; _ck(_v, 0, 0, currVal_0); }); }
export function View_CalendarComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i4.RelevantFixturesPipe, []), i0.ɵqud(671088640, 1, { componentChildren: 1 }), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "amg-carousel-calendar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "amg-carousel-calendar-tabs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "amg-carousel", [], null, [[null, "cardClicked"], [null, "cardHovered"], [null, "cardSelected"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("touchstart" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onTouchStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchend" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onTouchEnd($event) !== false);
        ad = (pd_1 && ad);
    } if (("cardClicked" === en)) {
        var pd_2 = (_co.onDateClicked("bubble", $event) !== false);
        ad = (pd_2 && ad);
    } if (("cardHovered" === en)) {
        var pd_3 = (_co.onDateHovered("bubble", $event) !== false);
        ad = (pd_3 && ad);
    } if (("cardSelected" === en)) {
        var pd_4 = (_co.onDateSelected("bubble", $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i5.View_CarouselComponent_0, i5.RenderType_CarouselComponent)), i0.ɵdid(5, 4964352, null, 0, i6.CarouselComponent, [i0.ComponentFactoryResolver, i7.BootstrapViewportService], { componentData: [0, "componentData"], responsiveProperties: [1, "responsiveProperties"], staticProperties: [2, "staticProperties"] }, { cardClicked: "cardClicked", cardHovered: "cardHovered", cardSelected: "cardSelected" }), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "amg-carousel-calendar-fixtures"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CalendarComponent_1)), i0.ɵdid(8, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵppd(9, 2), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "card amg-carousel-calendar-empty"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Sorry, no fixtures found "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabComponentData; var currVal_1 = _co.tabComponentResponsive; var currVal_2 = _co.tabComponentStatic; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), _co.fixtureComponentData, _co.selectedDate)); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.fixtureComponentData.length > 0); _ck(_v, 10, 0, currVal_4); }); }
export function View_CalendarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-calendar", [], null, null, null, View_CalendarComponent_0, RenderType_CalendarComponent)), i0.ɵdid(1, 770048, null, 0, i9.CalendarComponent, [i7.BootstrapViewportService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarComponentNgFactory = i0.ɵccf("amg-calendar", i9.CalendarComponent, View_CalendarComponent_Host_0, { componentData: "componentData", responsiveProperties: "responsiveProperties", staticProperties: "staticProperties" }, { fixtureClicked: "fixtureClicked", fixtureHovered: "fixtureHovered" }, []);
export { CalendarComponentNgFactory as CalendarComponentNgFactory };
