import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {PlayerDimensions} from '../_data/player-dimensions';

declare var window: any;

@Directive({
  selector: '[amgMakeElementFullHeight]'
})
export class MakeElementFullHeightDirective implements OnInit{
  private MOBILEBREAKPOINT = 768;
  public elRefLocal: ElementRef;

  @Output()
  changeHeight: EventEmitter<any>;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.calcWindowAndPlayerDimensions();
  }

  constructor(public elRef: ElementRef,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.changeHeight = new EventEmitter<any>();
    this.calcWindowAndPlayerDimensions();
  }

  calcWindowAndPlayerDimensions( ): PlayerDimensions {
    const videoDetailsEl: any = (this.elRef.nativeElement as HTMLElement).querySelectorAll('.amg-video-details');
    const playerDimensions: PlayerDimensions = {
      windowSize: {
        x: window.innerWidth,
        y: window.innerHeight
      },
      playerElSize: {
        offsetToTop: this.elRef.nativeElement.offsetTop,
        height: this.elRef.nativeElement.offsetHeight,
        calculatedHeight: 0
      },
      videoDetailsElSize: {
        height: 0
      },
      collapse: true
    };
    const leftOverSpace = playerDimensions.windowSize.y - playerDimensions.playerElSize.offsetToTop - playerDimensions.playerElSize.height;
    const calcAspectRatio = playerDimensions.windowSize.x / 16 * 9

    playerDimensions.isMobileView = (playerDimensions.windowSize.x <= this.MOBILEBREAKPOINT) ? true : false;
    playerDimensions.videoDetailsElSize.height = ((videoDetailsEl).length > 0) ? videoDetailsEl[0].offsetHeight : 160;
    playerDimensions.playerElSize.calculatedHeight = (playerDimensions.isMobileView) ? leftOverSpace : calcAspectRatio;

    const calcH = playerDimensions.playerElSize.calculatedHeight; // shorthand name for convenience
    playerDimensions.playerElSize.calculatedHeight = (calcH && calcH > 0) ? calcH + playerDimensions.videoDetailsElSize.height : 'inherit';

    this.changeHeight.emit(playerDimensions);
    this.changeDetectorRef.detectChanges();
    return playerDimensions;
  }

}
