import { AmgComponentResponsivePropertiesView } from 'amg';

export class CardFixtureResponsivePropertiesView implements AmgComponentResponsivePropertiesView {
  fixtureTeamsText: string;
  refreshRate: number;

  public static defaultValues(input?: CardFixtureResponsivePropertiesView): CardFixtureResponsivePropertiesView {
    let toReturn: CardFixtureResponsivePropertiesView;
    if (input === undefined) {
      toReturn = new CardFixtureResponsivePropertiesView();
    } else {
      toReturn = input;
    }
    
    toReturn = {...toReturn, ...{
        fixtureTeamsText: '',
        refreshRate: 200,
      }
    };

    return toReturn;
  }
}
