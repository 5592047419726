import * as tslib_1 from "tslib";
import { BootstrapViewportEnum, } from 'amg';
import { CalendarResponsivePropertiesView } from './calendar.responsive.properties.view';
var CalendarResponsiveProperties = /** @class */ (function () {
    function CalendarResponsiveProperties(params) {
        if (params === undefined) {
            params = {};
        }
        this.xs = params.xs || null;
        this.sm = params.sm || null;
        this.md = params.md || null;
        this.lg = params.lg || null;
        this.xl = params.xl || null;
        this.HD = params.HD || null;
        this.FullHd = params.FullHd || null;
        this.FourK = params.FourK || null;
        this.synchroniseProperties();
    }
    CalendarResponsiveProperties.prototype.synchroniseProperties = function (overrideResponsive) {
        var _this = this;
        if (overrideResponsive === undefined) {
            overrideResponsive = {};
        }
        var previousView = CalendarResponsivePropertiesView.defaultValues();
        CalendarResponsiveProperties.keys.forEach(function (viewPort) {
            var currentView = _this[viewPort];
            var currentValues = overrideResponsive[viewPort];
            currentView = tslib_1.__assign({}, previousView, currentView, currentValues);
            _this[viewPort] = currentView;
            previousView = currentView;
        });
    };
    CalendarResponsiveProperties.keys = Object.keys(BootstrapViewportEnum);
    return CalendarResponsiveProperties;
}());
export { CalendarResponsiveProperties };
