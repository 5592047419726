import * as tslib_1 from "tslib";
var CarouselColumns = /** @class */ (function () {
    /**
     * Store slide widths for the view
     * @param detailsActive check if we cater for the information sidebar
     * @param currentView the current viewport data
     */
    function CarouselColumns(currentView) {
        this._columnsStyle = {};
        this._detailsStyle = {};
        this._columnsHoveredStyle = {};
        this.setCurrentSlide('constructor', currentView.startPosition);
        this.setCurrentView('constructor', currentView);
    }
    /**
     * Change current viewport data
     * @param source
     * @param currentView the current viewport data
     */
    CarouselColumns.prototype.setCurrentView = function (source, currentView) {
        if (source === void 0) { source = 'unknown'; }
        this._currentView = currentView;
        this.recalculateColumns(source);
    };
    /**
     * Change current slide
     * @param source
     * @param currentSlide the new slide number
     */
    CarouselColumns.prototype.setCurrentSlide = function (source, currentSlide) {
        if (source === void 0) { source = 'unknown'; }
        this._currentSlide = currentSlide;
    };
    /**
     * Recalculate column CSS
     * @param source
     * @param normalItems number of items to show
     * @param hoveredItems number of items to show when hovering
     */
    CarouselColumns.prototype._recalculateColumns = function (source, normalItems, hoveredItems) {
        if (source === void 0) { source = 'unknown'; }
        var flex = 100 / normalItems;
        if (hoveredItems !== undefined) {
            flex = 100 - (flex * (hoveredItems - 1));
        }
        var toReturn = {
            flex: "0 0 " + flex + "%",
            width: flex + "%",
            maxWidth: flex + "%",
            transition: "flex " + this._currentView.hoverSpeed + "ms ease-in-out",
        };
        return toReturn;
    };
    /**
     * Recalculate column width
     * @param source
     */
    CarouselColumns.prototype.recalculateColumns = function (source) {
        if (source === void 0) { source = 'unknown'; }
        this._columnsStyle = this._recalculateColumns(source, this._currentView.limitViewableItems);
        if (this._currentView.limitViewableItems !== this._currentView.visibleItemsOnHover) {
            // style hovered columns with a zoom
            this._columnsHoveredStyle = this._recalculateColumns(source, this._currentView.limitViewableItems, this._currentView.visibleItemsOnHover);
        }
        else {
            // style hovered columns like normal columns
            this._columnsHoveredStyle = tslib_1.__assign({}, this._columnsStyle);
        }
        this._detailsStyle = {
            flex: this._columnsStyle.flex,
            width: this._columnsStyle.width,
            maxWidth: this._columnsStyle.maxWidth,
        };
    };
    /**
     * Get appropriate transition speed
     * @param source
     */
    CarouselColumns.prototype._getTransitionSpeed = function (source) {
        if (source === void 0) { source = 'unknown'; }
        var speed = 0;
        switch (source) {
            case 'nav': {
                speed = this._currentView.navigationSpeed;
                break;
            }
            case 'dots': {
                speed = this._currentView.paginationSpeed;
                break;
            }
            case 'dragStop': {
                speed = this._currentView.dragEndSpeed;
                break;
            }
        }
        return speed;
    };
    /**
     * Get cached column styles normal or hovered
     * @param col requested column style
     * @param colHovered current column hovered
     */
    CarouselColumns.prototype.getColumn = function (col, colHovered) {
        if (col === colHovered) {
            return this._columnsHoveredStyle;
        }
        return this._columnsStyle;
    };
    /**
     * Get cached detals column styles
     */
    CarouselColumns.prototype.getDetailsColumn = function () {
        return this._detailsStyle;
    };
    return CarouselColumns;
}());
export { CarouselColumns };
