/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./live-icons/live-icons.component.ngfactory";
import * as i3 from "./live-icons/live-icons.component";
import * as i4 from "@angular/common/http";
import * as i5 from "./thumbnail/thumbnail.component.ngfactory";
import * as i6 from "./thumbnail/thumbnail.component";
import * as i7 from "./date-and-time/date-and-time.component.ngfactory";
import * as i8 from "./date-and-time/date-and-time.component";
import * as i9 from "./playlist-card.component";
var styles_PlaylistCardComponent = [];
var RenderType_PlaylistCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PlaylistCardComponent, data: {} });
export { RenderType_PlaylistCardComponent as RenderType_PlaylistCardComponent };
function View_PlaylistCardComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "amg-video-category"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_PlaylistCardComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "amg-category-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistCardComponent_3)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categories; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PlaylistCardComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "amg-title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); }); }
function View_PlaylistCardComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-live-icons", [], null, [[null, "mediaRequest"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mediaRequest" === en)) {
        var pd_0 = ((_co.clickedItemData = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LiveIconsComponent_0, i2.RenderType_LiveIconsComponent)), i0.ɵdid(1, 114688, null, 0, i3.LiveIconsComponent, [i4.HttpClient], { media: [0, "media"] }, { mediaRequest: "mediaRequest" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.media; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PlaylistCardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "amg-playlist-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "amg-thumbnail", [], null, null, null, i5.View_ThumbnailComponent_0, i5.RenderType_ThumbnailComponent)), i0.ɵdid(2, 114688, null, 0, i6.ThumbnailComponent, [i0.ChangeDetectorRef], { cardData: [0, "cardData"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "amg-middle"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistCardComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistCardComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "amg-date-and-time", [], null, null, null, i7.View_DateAndTimeComponent_0, i7.RenderType_DateAndTimeComponent)), i0.ɵdid(9, 49152, null, 0, i8.DateAndTimeComponent, [], { cardData: [0, "cardData"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistCardComponent_5)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardData; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.categories; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.title; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.cardData; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.showLiveIcons; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_PlaylistCardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistCardComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PlaylistCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-playlist-card", [], null, null, null, View_PlaylistCardComponent_0, RenderType_PlaylistCardComponent)), i0.ɵdid(1, 114688, null, 0, i9.PlaylistCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlaylistCardComponentNgFactory = i0.ɵccf("amg-playlist-card", i9.PlaylistCardComponent, View_PlaylistCardComponent_Host_0, { cardData: "cardData", clickedItemData: "clickedItemData" }, {}, []);
export { PlaylistCardComponentNgFactory as PlaylistCardComponentNgFactory };
