import { HttpClient } from '@angular/common/http';
import { OnDestroy, OnInit } from '@angular/core';
import { LiveFixtureCard } from 'wordpress-adapter';
var LiveFixtureCardComponent = /** @class */ (function () {
    function LiveFixtureCardComponent(httpClient) {
        this.httpClient = httpClient;
        this.class = 'amg-live-fixture-card';
        this.isDataAvailable = false;
        this.isLoading = true;
        this.isError = false;
    }
    LiveFixtureCardComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.initialiseCard();
        this.triggerApiCall(this.apiUrl);
    };
    LiveFixtureCardComponent.prototype.ngOnDestroy = function () {
        if (this.apiDataObservable) {
            this.apiDataObservable.unsubscribe();
        }
    };
    LiveFixtureCardComponent.prototype.getCss = function () {
        return this.videoCard.cssClass;
    };
    LiveFixtureCardComponent.prototype.initialiseCard = function () {
        this.cardData = null;
        this.apiUrl = this.videoCard.fixtureApiUrl;
    };
    LiveFixtureCardComponent.prototype.triggerApiCall = function (apiUrl) {
        var _this = this;
        this.apiDataObservable = this.httpClient.get(apiUrl).subscribe(function (data) {
            if (data && data.fixtures && data.fixtures.length > 0) {
                _this.cardData = data.fixtures[0];
                _this.isDataAvailable = true;
            }
            _this.isLoading = false;
        }, function (error) {
            _this.isLoading = false;
            _this.isError = true;
        });
    };
    return LiveFixtureCardComponent;
}());
export { LiveFixtureCardComponent };
