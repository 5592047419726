/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./live-buttons.component";
var styles_LiveButtonsComponent = [];
var RenderType_LiveButtonsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LiveButtonsComponent, data: {} });
export { RenderType_LiveButtonsComponent as RenderType_LiveButtonsComponent };
export function View_LiveButtonsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "button", [["class", "btn amg-audio-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick("audio", _co.mandatoryButtons.audio) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "placeholder-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "placeholder-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "span", [["class", "text-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "live"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Live"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "audio"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Audio"])), (_l()(), i0.ɵeld(8, 0, null, null, 7, "button", [["class", "btn amg-video-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick("video", _co.mandatoryButtons.video) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [["class", "placeholder-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "span", [["class", "placeholder-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 4, "span", [["class", "text-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [["class", "live"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Live"])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "span", [["class", "video"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Video"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isButtonDisabled(_co.mandatoryButtons.audio); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.isButtonDisabled(_co.mandatoryButtons.video); _ck(_v, 8, 0, currVal_1); }); }
export function View_LiveButtonsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-live-buttons", [], null, null, null, View_LiveButtonsComponent_0, RenderType_LiveButtonsComponent)), i0.ɵdid(1, 114688, null, 0, i1.LiveButtonsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LiveButtonsComponentNgFactory = i0.ɵccf("amg-live-buttons", i1.LiveButtonsComponent, View_LiveButtonsComponent_Host_0, { fixtureMediaData: "fixtureMediaData" }, { triggerClick: "triggerClick" }, []);
export { LiveButtonsComponentNgFactory as LiveButtonsComponentNgFactory };
