import { OnInit } from '@angular/core';
import { VideoPlayerService } from '../../video-player.service';
var VideoDetailsComponent = /** @class */ (function () {
    function VideoDetailsComponent(videoPlayerService) {
        this.videoPlayerService = videoPlayerService;
    }
    VideoDetailsComponent.padValue = function (value) {
        return (value.length === 1)
            ? "0" + value
            : value;
    };
    VideoDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.videoPlayerService.getAnywhereMediaDataBSubject()
            .subscribe(function (videoMediaData) {
            if (videoMediaData) {
                _this.localAnywhereMediaData = videoMediaData;
                _this.title = _this.getTitle();
                _this.category = _this.getCategory();
                _this.titleDate = _this.getDate();
                _this.titleDescription = _this.getDescription();
                _this.isLiveContent = _this.videoPlayerService.isLive();
                _this.duration = _this.generateRuntime(_this.getDuration());
            }
        });
    };
    VideoDetailsComponent.prototype.getDuration = function () {
        var data = this.localAnywhereMediaData;
        if (data) {
            return (data.metaData && data.metaData.VideoDuration)
                ? data.metaData.VideoDuration
                : 0;
        }
        return 0;
    };
    VideoDetailsComponent.prototype.getCategory = function () {
        var data = this.localAnywhereMediaData;
        if (data) {
            return (data.metaData && data.metaData.category)
                ? data.metaData.category
                : (data.metaData && data.metaData.categories)
                    ? data.metaData.categories
                    : false;
        }
        return false;
    };
    VideoDetailsComponent.prototype.getTitle = function () {
        var data = this.localAnywhereMediaData;
        if (data) {
            return (data.metaData && data.metaData.title)
                ? data.metaData.title
                : data.name
                    ? data.name
                    : false;
        }
        return false;
    };
    VideoDetailsComponent.prototype.getDate = function () {
        var data = this.localAnywhereMediaData;
        if (data) {
            var pubData = (data.publicationData) ? data.publicationData : (data) ? data : false;
            return (pubData && pubData.releaseFrom)
                ? pubData.releaseFrom
                : (pubData && pubData.startDate)
                    ? pubData.startDate
                    : false;
        }
        return false;
    };
    VideoDetailsComponent.prototype.getDescription = function () {
        var data = this.localAnywhereMediaData;
        if (data && data.metaData) {
            return (data.metaData.body)
                ? data.metaData.body
                : false;
        }
        return false;
    };
    VideoDetailsComponent.prototype.getTeam = function () {
        var data = this.localAnywhereMediaData;
        if (data) {
            return (data.metaData && data.metaData.team) ? data.metaData.team : false;
        }
        return false;
    };
    VideoDetailsComponent.prototype.generateRuntime = function (duration) {
        var runtime = new Date((duration || 0) * 1000);
        return {
            seconds: { value: runtime.getUTCSeconds().toString(), paddedValue: VideoDetailsComponent.padValue(runtime.getUTCSeconds().toString()) },
            minutes: { value: runtime.getUTCMinutes().toString(), paddedValue: VideoDetailsComponent.padValue(runtime.getUTCMinutes().toString()) },
            hours: { value: runtime.getUTCHours().toString(), paddedValue: VideoDetailsComponent.padValue(runtime.getUTCHours().toString()) }
        };
    };
    return VideoDetailsComponent;
}());
export { VideoDetailsComponent };
