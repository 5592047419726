import { Header } from './data/components/Header';
import { Overlay } from './data/components/Overlay';
import { Page } from './data/components/Page';
import { Footer } from './data/components/Footer';

import { Button } from './data/components/section-elements/html-elements/Button';
import { Link } from './data/components/section-elements/html-elements/Link';
import { ActionLink } from './data/components/section-elements/html-elements/ActionLink';
import { Icon } from './data/components/section-elements/html-elements/Icon';
import { InputField } from './data/components/section-elements/html-elements/InputField';
import { Text } from './data/components/section-elements/html-elements/Text';

import { Advertisement } from './data/components/section-elements/html-grouped-elements/Advertisement';
import { Icons } from './data/components/section-elements/html-grouped-elements/Icons';
import { InputFormGroup } from './data/components/section-elements/html-grouped-elements/InputFormGroup';
import { TextGroup } from './data/components/section-elements/html-grouped-elements/TextGroup';

import { NavigationBar } from './data/components/section-elements/html-grouped-elements/NavigationBar';
import { SocialLinks } from './data/components/section-elements/html-grouped-elements/SocialLinks';
import { ActionLinks } from './data/components/section-elements/html-grouped-elements/ActionLinks';
import { AnywhereVideoCard } from './data/components/section-elements/ng-elements/AnywhereVideoCard';
import { LiveFixtureCard } from './data/components/section-elements/ng-elements/LiveFixtureCard';

import { PaymentFieldsGroup } from './data/components/section-elements/payment-elements/PaymentFieldsGroup';
import { PaymentLoginButtonGroup } from './data/components/section-elements/payment-elements/PaymentLoginButtonGroup';
import { PaymentSubscribePackages } from './data/components/section-elements/payment-elements/PaymentSubscribePackages';
import { PaymentUserSignInPage } from './data/components/section-elements/payment-elements/PaymentUserSignInPage';
import { PaymentVideoPlayer } from './data/components/section-elements/payment-elements/PaymentVideoPlayer';

import { Carousel } from './data/components/section-elements/ng-elements/Carousel';
import { CarouselFlickity } from './data/components/section-elements/ng-elements/CarouselFlickity';
import { Calendar } from './data/components/section-elements/ng-elements/Calendar';
import { Collapsible } from './data/components/section-elements/ng-elements/Collapsible';
import { Countdown } from './data/components/section-elements/ng-elements/Countdown';
import { Grid } from './data/components/section-elements/ng-elements/Grid';
import { Fixture } from './data/components/section-elements/ng-elements/Fixture';
import { Fixtures } from './data/components/section-elements/ng-elements/Fixtures';
import { Boxset } from './data/components/section-elements/ng-elements/Boxset';
import { ContentProjection } from './data/components/section-elements/ng-elements/ContentProjection';

import { AnywhereApiConfiguration } from './data/config/AnywhereApiConfiguration';
import { BaseConfiguration } from './data/config/BaseConfiguration';
import { KalturaConfiguration } from './data/config/KalturaConfiguration';
import { Sections } from './data/components/Sections';
import { Section } from './data/components/Section';

import { RestApiConfiguration } from './data/config/RestApiConfiguration';
import { PropertyObject } from './data/PropertyObject';

// Avoid populating the window object, just state the reference to the component.
export function getObjectDictionary(): Map<string, any> {
  return new Map<string, any>([
    ['data', PropertyObject],

    ['anywhere_api_configuration', AnywhereApiConfiguration],
    ['rest_api_configuration', RestApiConfiguration],
    ['kaltura_configuration', KalturaConfiguration],
    ['base_configuration', BaseConfiguration],

    ['page', Page],
    ['overlay', Overlay],
    ['footer', Footer],
    ['header', Header],
    ['section', Section],
    ['sections', Sections],

    // Elements
    ['button', Button],
    ['icon', Icon],
    ['input', InputField],
    ['link', Link],
    ['action', ActionLink],
    ['text', Text],

    // Grouped Elements
    ['advertisement', Advertisement],
    ['icons', Icons],
    ['navigation_bar', NavigationBar],
    ['social_links', SocialLinks],
    ['text_group', TextGroup],
    ['input_form_group', InputFormGroup],
    ['text_links', ActionLinks],

    // Payment Components
    ['payment_field_group', PaymentFieldsGroup],
    ['payment_login_button_group', PaymentLoginButtonGroup],
    ['payment_video_player', PaymentVideoPlayer],
    ['payment_user_auth_page', PaymentUserSignInPage],
    ['payment_subscribe_packages', PaymentSubscribePackages],

    // Wrapper of AMG Angular Components
    ['carousel', Carousel],
    ['carousel_flickity', CarouselFlickity],
    ['anywhere_video_card', AnywhereVideoCard],
    ['live_fixture_card', LiveFixtureCard],
    ['grid', Grid],
    ['countdown', Countdown],
    ['collapsible', Collapsible],
    ['calendar', Calendar],
    ['fixture', Fixture],
    ['fixtures', Fixtures],
    ['Boxset', Boxset],
    ['content_projection', ContentProjection]
  ]);
}
