import * as tslib_1 from "tslib";
import { NavigationUtils } from '../../../../../util/NavigationUtils';
import { BaseObject } from '../../../BaseObject';
var ActionLink = /** @class */ (function (_super) {
    tslib_1.__extends(ActionLink, _super);
    function ActionLink(data) {
        var _this = _super.call(this, 'ActionLink', data) || this;
        _this._label = data.label;
        _this._externalLink = data.external_link;
        if (data.relative_path) {
            var pathData = NavigationUtils.getUrlFragments(data.relative_path);
            _this._path = (pathData.url).charAt(0) === '/'
                ? pathData.url
                : "/" + pathData.url;
            _this._fragment = pathData.fragment || null;
        }
        else {
            _this._path = data.relative_path;
            _this._fragment = null;
        }
        _this._type = Array.isArray(data.type)
            ? data.type[0]
            : data.type;
        return _this;
    }
    Object.defineProperty(ActionLink.prototype, "fragment", {
        get: function () {
            return this._fragment;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionLink.prototype, "label", {
        get: function () {
            return this._label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionLink.prototype, "externalLink", {
        get: function () {
            return this._externalLink;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionLink.prototype, "path", {
        get: function () {
            return this._path;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionLink.prototype, "type", {
        get: function () {
            return this._type;
        },
        enumerable: true,
        configurable: true
    });
    return ActionLink;
}(BaseObject));
export { ActionLink };
