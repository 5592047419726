import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Carousel as WpCarousel } from 'wordpress-adapter';
import { CarouselResponsiveProperties } from 'carousel';
import { CardType } from 'card';
import { ApiService } from '../../../../../shared/api/api.service';
import { DataService } from '../../../../../shared/data/data.service';
var CarouselComponent = /** @class */ (function () {
    function CarouselComponent(apiService, dataService, router) {
        this.apiService = apiService;
        this.dataService = dataService;
        this.router = router;
        this.class = 'amg-carousel';
    }
    CarouselComponent.getCarouselType = function (cardType) {
        switch (cardType) {
            case 'Video':
                return CardType.Video;
            case 'Image':
                return CardType.Image;
            case 'Fixture':
                return CardType.Fixture;
            default:
                return CardType.Text;
        }
    };
    CarouselComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.initialiseCarousel();
        this.triggerDataRetrieval();
    };
    CarouselComponent.prototype.ngOnDestroy = function () {
        if (this.apiDataObservable) {
            this.apiDataObservable.unsubscribe();
        }
        if (this.anywhereApiConfiguration) {
            this.apiService.clearAnywhereApiCache(this.anywhereApiConfiguration);
        }
    };
    CarouselComponent.prototype.onCardClick = function (data) {
        if (this.carousel.onClickNavigate) {
            // At this point there can only be 2 cases:
            //  Case 1: Carousel retrieving from Anywhere (entry id + url)
            //  Case 2: Carousel retrieving from WP Endpoint (url from imageUrl)
            var url = void 0, entryId = void 0;
            if (this.anywhereApiConfiguration) {
                var playlistUrl = ApiService.generateFullAnywhereUrl(this.anywhereApiConfiguration);
                url = this.dataService.encodeUrl(playlistUrl);
                entryId = (data && data.mediaData && data.mediaData.entryId)
                    ? data.mediaData.entryId
                    : null;
                this.dataService.userInteractedObject = {
                    playlistUrl: playlistUrl,
                    data: data
                };
            }
            else {
                url = this.dataService.encodeUrl(data.playlist_url);
                entryId = null;
                this.dataService.userInteractedObject = {
                    playlistUrl: data.playlist_url,
                    data: data
                };
            }
            this.router.navigate([this.carousel.onClickNavigate], {
                queryParams: tslib_1.__assign({ playlist: url }, (entryId ? { entry: entryId } : {})),
                preserveQueryParams: false
            });
        }
    };
    CarouselComponent.prototype.getCss = function () {
        return this.carousel.cssClass;
    };
    CarouselComponent.prototype.initialiseCarousel = function () {
        this.carouselData = [];
        this.carouselStaticProperties = {
            carouselTile: CarouselComponent.getCarouselType(this.carousel.carouselType),
            carouselSidebar: this.carousel.carouselSideBar
        };
        this.carouselResponsiveProperties = new CarouselResponsiveProperties(this.carousel.carouselConfiguration);
        if (this.carousel.useAnywhereApiConfiguration) {
            this.anywhereApiConfiguration = this.carousel.anywhereApiConfiguration;
        }
    };
    CarouselComponent.prototype.triggerDataRetrieval = function () {
        var _this = this;
        if (this.anywhereApiConfiguration) {
            this.apiDataObservable = this.apiService.getAnyWhereData(this.anywhereApiConfiguration).subscribe(function (data) {
                if (data) {
                    _this.carouselData = _this.getData(data);
                }
            });
        }
        else {
            this.apiDataObservable = this.apiService.getApiData(this.carousel.restApiConfiguration).subscribe(function (data) {
                if (data) {
                    _this.carouselData = data;
                }
            });
        }
    };
    CarouselComponent.prototype.getData = function (data) {
        if (data) {
            if (data.sections && data.sections.length) {
                return data.sections[0].itemData;
            }
        }
        return [];
    };
    return CarouselComponent;
}());
export { CarouselComponent };
