import { AmgComponentResponsivePropertiesView } from 'amg';

export class CollapsibleResponsivePropertiesView implements AmgComponentResponsivePropertiesView {
  canCollapse: boolean;
  showText: string;
  hideText: string;
  refreshRate: number;

  cardView: AmgComponentResponsivePropertiesView;

  public static defaultValues(input?: CollapsibleResponsivePropertiesView): CollapsibleResponsivePropertiesView {
    let toReturn: CollapsibleResponsivePropertiesView;
    if (input === undefined) {
      toReturn = new CollapsibleResponsivePropertiesView();
    } else {
      toReturn = input;
    }
    
    toReturn = {...toReturn, ...{
      canCollapse: true,
      showText: '+',
      hideText: '-',
      refreshRate: 200,

      cardView: { } as AmgComponentResponsivePropertiesView,
    }};

    return toReturn;
  }
}
