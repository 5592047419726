/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../html-elements/input-field/input-field.component.ngfactory";
import * as i2 from "../../html-elements/input-field/input-field.component";
import * as i3 from "../../../../../shared/api/api.service";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./input-form-group.component";
import * as i7 from "@angular/common/http";
var styles_InputFormGroupComponent = [];
var RenderType_InputFormGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputFormGroupComponent, data: {} });
export { RenderType_InputFormGroupComponent as RenderType_InputFormGroupComponent };
function View_InputFormGroupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "amg-form-input-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "amg-wp-input-field", [], [[1, "class", 0]], [[null, "triggerChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("triggerChange" === en)) {
        var pd_0 = (_co.triggerFieldChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_InputFieldComponent_0, i1.RenderType_InputFieldComponent)), i0.ɵdid(2, 245760, null, 0, i2.InputFieldComponent, [i3.ApiService, i4.Router], { inputField: [0, "inputField"], inputClearObservable: [1, "inputClearObservable"] }, { triggerChange: "triggerChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.getInputClearAsObservable(); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); }); }
function View_InputFormGroupComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "message-success"]], null, null, null, null, null))], null, null); }
function View_InputFormGroupComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "message-error"]], null, null, null, null, null))], null, null); }
function View_InputFormGroupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "amg-form-message"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputFormGroupComponent_3)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputFormGroupComponent_4)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data.isFormSubmitted && !_co.data.formError); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.data.formError; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InputFormGroupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "form", [["class", "amg-form"], ["method", "post"]], [[8, "action", 4]], [[null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputFormGroupComponent_1)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputFormGroupComponent_2)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "amg-form-button-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "button", [["class", "btn amg-button amg-form-button"], ["type", "submit"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵdid(7, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getInputFields(); _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.data.isFormSubmitted && !_co.data.formError) || _co.data.formError); _ck(_v, 4, 0, currVal_2); var currVal_4 = "btn amg-button amg-form-button"; var currVal_5 = _co.getButtonCssClass(); _ck(_v, 7, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFormActionUrl(); _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.getButtonText(); _ck(_v, 6, 0, currVal_3); }); }
export function View_InputFormGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-input-form-group", [], [[1, "class", 0]], null, null, View_InputFormGroupComponent_0, RenderType_InputFormGroupComponent)), i0.ɵdid(1, 180224, null, 0, i6.InputFormGroupComponent, [i7.HttpClient], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var InputFormGroupComponentNgFactory = i0.ɵccf("amg-wp-input-form-group", i6.InputFormGroupComponent, View_InputFormGroupComponent_Host_0, { inputFormGroup: "inputFormGroup" }, {}, []);
export { InputFormGroupComponentNgFactory as InputFormGroupComponentNgFactory };
