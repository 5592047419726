import { ApiException } from '../ApiException';
import { PageData } from '../data/types/PageData';
import { getObjectDictionary } from '../ObjectDictionary';
import { excludedKeys } from '../ObjectKeyExclude';
import { ClientResponse } from './ClientResponse';

export class ClientRequest<T> {
  private componentMappings: Map<string, { new(...args): T }>;
  private readonly _requestData: T;
  private readonly _endpoint: string;

  public static generateClientRequest<D>(data: D, endpoint: string): ClientRequest<D> {
    return new ClientRequest<D>(data, endpoint);
  }

  private static getObjectType(response: any): string {
    return response.object_type;
  }

  constructor(data: T, endpoint: string) {
    this._requestData = data;
    this._endpoint = endpoint;
    this.componentMappings = getObjectDictionary();
  }

  handleResponse<D>(response: PageData): ClientResponse<D> {
    if (response && Object.keys(response).length > 0) {
      // For page, confirm that we got data, else throw an error.
      if (response.hasOwnProperty('otherContent') || (response.hasOwnProperty('page') && response.page)) {
        return new ClientResponse<D>(this.triggerParsing(response), null);
      } else {
        return new ClientResponse(null, 'The page content returned is empty!');
      }
    }

    return new ClientResponse(null, 'Unable to parse object returned from WP.');
  }

  private triggerParsing(response: any): any {
    if (response.otherContent) {
      return {
        ...( response.otherContent.header ? { header: this.parseResponse(response.otherContent.header) } : {} ),
        ...( response.otherContent.footer ? { footer: this.parseResponse(response.otherContent.footer) } : {} ),
        ...( response.otherContent.overlay ? { overlay: this.parseResponse(response.otherContent.overlay) } : {} )
      };
    } else {
      return this.parseResponse(response.page);
    }
  }

  /**
   * Parses the response body and returns the respective prototype objects.
   *  Objects that doesn't contain the property 'object_type' are ignored.
   * @param response The response body or object if within a recursive process.
   */
  private parseResponse(response: any): any {
    const responseObject = {};

    Object.keys(response).forEach(key => {
      const value = response[key];

      if (Array.isArray(value)) {
        responseObject[key] = this.handleTypeArray(value);
      } else if (value !== null && typeof value === 'object' && excludedKeys.indexOf(key) === -1) {
        responseObject[key] = this.handleTypeObject(value);
      } else {
        if (key !== 'acf_fc_layout') {
          responseObject[key] = value;
        }
      }
    });

    return this.generateObject(responseObject);
  }

  private handleTypeObject(propertyValue: any): any {
    const arrPropertyNames = Object.keys(propertyValue),
      firstPropertyValue = propertyValue[arrPropertyNames[0]];

    if (arrPropertyNames.length === 1) {
      if (Array.isArray(firstPropertyValue)) {
        return this.handleTypeArray(firstPropertyValue);
      } else if (typeof firstPropertyValue === 'object') {
        return this.parseResponse(firstPropertyValue);
      }
    } else {
      return this.parseResponse(propertyValue);
    }

    return null;
  }

  private handleTypeArray<D>(propertyValue: any[]): any[] {
    const array = [];

    propertyValue.forEach(object => {
      if (typeof object === 'object') {
        if (object.object_type) {
          array.push(this.parseResponse(object));
        } else {
          // ACF Layout entry.
          const generatedValue = this.generateValueForWordPressAcfEntry(object);

          if (generatedValue) {
            array.push(generatedValue);
          }
        }
      } else {
        array.push(object);
      }
    });

    return array;
  }

  private generateValueForWordPressAcfEntry(acfObject: any): any {
    // We are only interested in properties which excludes acf_fc_layout
    const {acf_fc_layout, ...otherProperties} = acfObject,
      arrPropertyNames = Object.keys(otherProperties),
      firstPropertyValue = otherProperties[arrPropertyNames[0]];

    /**
     * A specific case to cater for Wordpress 'Flexible Content' where
     *  an entry (Layout) has no object type and includes only 1 property.
     */
    if (arrPropertyNames.length === 1 && typeof firstPropertyValue === 'object') {
      if (Array.isArray(firstPropertyValue)) {
        return this.handleTypeArray(firstPropertyValue);
      } else if (firstPropertyValue.object_type) {
        return this.parseResponse({
          ...firstPropertyValue,
          flexibleContentId: acf_fc_layout
        });
      } else {
        /* Having an object at hand, iterate recursively until this condition is never reached.
         * This is required due to nested objects for grouping within the CMS, and will only
         * trigger when there is only 1 property.
         */
        const objectKeys = Object.keys(firstPropertyValue);
        if (objectKeys.length === 1 && !!firstPropertyValue[objectKeys[0]]) {
          return this.generateValueForWordPressAcfEntry(firstPropertyValue);
        }
      }
    } else {
      return otherProperties;
    }

    return null;
  }

  private generateObject(response: any): any {
    const objectType = ClientRequest.getObjectType(response),
      objectClass = this.componentMappings.get(objectType);

    if (objectClass) {
      return new objectClass(response);
    } else {
      throw new ApiException('Unable to set the given object.\n\n'
        .concat(JSON.stringify(response)
          .concat('\n')));
    }
  }

  get requestData(): T {
    return this._requestData;
  }

  get endpoint(): string {
    return this._endpoint;
  }
}
