import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { NavigationBar, Link } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NavigationBarComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-navigation-bar';
  @Input() navigationBar: NavigationBar;

  private isMobileNavigationBarToggled: boolean;

  constructor(private router: Router) {
    this.isMobileNavigationBarToggled = false;
  }

  ngOnInit(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this.isMobileNavigationBarToggled) {
          this.isMobileNavigationBarToggled = false;
        }
      }
    });
  }

  getNavigationLinks(): Link[] {
    return this.navigationBar.navigationLinks;
  }

  getCss(): string {
    return this.navigationBar.cssClass;
  }

  getExtraElements(): any[] {
    return this.navigationBar.extras;
  }

  getNavBarId(): string {
    return (this.navigationBar.id)
      ? this.navigationBar.id
      : null;
  }

  getNavBarIdWithHash(): string {
    return `#${this.getNavBarId()}`;
  }

  isCollapsible(): boolean {
    return this.navigationBar.isCollapsible;
  }

  areExtraSectionsAvailable(): boolean {
    return !!this.navigationBar.extras
      && this.navigationBar.extras.length > 0;
  }

  isTypeArray(data: any): boolean {
    return Array.isArray(data);
  }

  onToggleClick(): void {
    this.isMobileNavigationBarToggled = !this.isMobileNavigationBarToggled;
  }

  getToggleState(): boolean {
    return this.isMobileNavigationBarToggled;
  }
}
