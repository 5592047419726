import { CardFixtureResponsiveProperties } from 'card-fixture';
import { CardImageResponsiveProperties } from 'card-image';
import { CardProfileResponsiveProperties } from 'card-profile';
import { CardTextResponsiveProperties } from 'card-text';
import { CardVideoResponsiveProperties } from 'card-video';

export class CardResponsiveRegistry {
  public static CardResponsivePropertiesRegistry: Map<string, any> = new Map<string,any>([
    ['CardFixtureResponsiveProperties', CardFixtureResponsiveProperties],
    ['CardImageResponsiveProperties', CardImageResponsiveProperties],
    ['CardProfileResponsiveProperties', CardProfileResponsiveProperties],
    ['CardTextResponsiveProperties', CardTextResponsiveProperties],
    ['CardVideoResponsiveProperties', CardVideoResponsiveProperties],
  ]);
}
