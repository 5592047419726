import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { InputField } from 'wordpress-adapter';
import { ApiService } from '../../../../../shared/api/api.service';
var InputFieldComponent = /** @class */ (function () {
    function InputFieldComponent(apiService, router) {
        this.apiService = apiService;
        this.router = router;
        this.class = 'amg-input-field-container';
        this.triggerChange = new EventEmitter();
        this.value = '';
    }
    InputFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        if (this.isTypeSelect()) {
            var firstValue = this.getFirstValue();
            if (firstValue) {
                this.triggerChange.emit({
                    inputField: this.inputField,
                    value: firstValue.key
                });
                this.value = firstValue.key;
            }
        }
        else {
            this.value = this.getValue();
            this.triggerChange.emit({
                inputField: this.inputField,
                value: this.value
            });
        }
        if (this.inputClearObservable) {
            this.inputClearSubscription = this.inputClearObservable.subscribe(function (value) {
                _this.triggerChange.emit({
                    inputField: _this.inputField,
                    value: _this.value
                });
            });
        }
    };
    InputFieldComponent.prototype.ngOnDestroy = function () {
        if (this.inputClearSubscription) {
            this.inputClearSubscription.unsubscribe();
        }
    };
    InputFieldComponent.prototype.onKeyUp = function (event) {
        var isEnter = event.key === 'Enter';
        if (isEnter && this.inputField.onEnterNavigateTo) {
            var inputValue = event.target.value;
            this.router.navigate([this.inputField.onEnterNavigateTo], {
                queryParams: {
                    search: inputValue
                }
            });
        }
        else {
            this.triggerChange.emit({
                inputField: this.inputField,
                value: event.target.value
            });
        }
    };
    InputFieldComponent.prototype.onSelectChange = function (event) {
        this.triggerChange.emit({
            inputField: this.inputField,
            value: event.target.value
        });
    };
    InputFieldComponent.prototype.isTypeSelect = function () {
        return InputFieldComponent.INPUT_TYPE_SELECT === this.getType();
    };
    InputFieldComponent.prototype.getType = function () {
        return this.inputField.type;
    };
    InputFieldComponent.prototype.getReferenceName = function () {
        return this.inputField.referenceName;
    };
    InputFieldComponent.prototype.getLabel = function () {
        return this.inputField.label;
    };
    InputFieldComponent.prototype.getValue = function () {
        return this.inputField.value
            ? this.inputField.value
            : '';
    };
    InputFieldComponent.prototype.getValues = function () {
        return this.inputField.values;
    };
    InputFieldComponent.prototype.isRequired = function () {
        return this.inputField.required;
    };
    InputFieldComponent.prototype.isLabelAvailable = function () {
        return !!this.inputField.label;
    };
    InputFieldComponent.prototype.getPlaceholder = function () {
        return this.inputField.placeholder
            ? this.inputField.placeholder
            : '';
    };
    InputFieldComponent.prototype.getCss = function () {
        return this.inputField.cssClass;
    };
    InputFieldComponent.prototype.getFirstValue = function () {
        var values = this.getValues();
        return values.length > 0
            ? values[0]
            : null;
    };
    InputFieldComponent.INPUT_TYPE_SELECT = 'select';
    return InputFieldComponent;
}());
export { InputFieldComponent };
