import { OnInit } from '@angular/core';
// import {SsoServiceOld} from '../../../../../../../src/app/core/service/sso-service/sso.service';
var SubscriptionPackageComponent = /** @class */ (function () {
    function SubscriptionPackageComponent(document) {
        this.document = document;
        this.class = 'amg-subscription-package';
    }
    SubscriptionPackageComponent.prototype.ngOnInit = function () {
        this.intervalData = SubscriptionPackageComponent.parseIntervalDate(this.package.Interval);
        this.formatPrice(this.package);
    };
    SubscriptionPackageComponent.prototype.onButtonClick = function () {
        this.document.location.href = this.package.PurchaseUrl;
        /*  // if (this.ssoService && this.ssoService.tokenData && this.ssoService.tokenData.access_token) {
          //
          //   jwt = this.ssoService.tokenData.access_token;
          // }
          const packageUrl = new URL(this.package.PurchaseUrl);
          if (window.localStorage.getItem('stream-token')) {
            packageUrl.searchParams.append('token', window.localStorage.getItem('stream-token'));
          }
      
          this.document.location.href = packageUrl.href;*/
    };
    SubscriptionPackageComponent.prototype.formatPrice = function (pkg) {
        // ToDo: remove when fixed on backend
        if (pkg.Amount < 1) {
            switch (pkg.Currency) {
                case 'GBP': {
                    this.formattedPrice = (pkg.Amount * 100) + 'p';
                    break;
                }
                default: {
                    this.formattedPrice = pkg.AmountFormatted;
                    break;
                }
            }
        }
        else {
            this.formattedPrice = pkg.AmountFormatted;
        }
    };
    SubscriptionPackageComponent.parseIntervalDate = function (value) {
        var data = value.match(/[\d.]+|\D+/g);
        return data.length > 1
            ? {
                number: data[0],
                text: data[1]
            }
            : null;
    };
    return SubscriptionPackageComponent;
}());
export { SubscriptionPackageComponent };
