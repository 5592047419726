<div class="amg-card-profile card card-profile">
  <div class="card-img-top" [ngStyle]="{ 'background-image': getThumbnailAsBackground() }">
    <img [src]="componentData.imageUrl" />
  </div>
  <div class="card-body">
    <div class="position">{{ componentData.position }}</div>
    <div class="number">{{ componentData.number }}</div>
    <div class="name-first">{{ componentData.nameFirst }}</div>
    <div class="name-last">{{ componentData.nameLast }}</div>
  </div>
</div>
