import { BaseObject } from '../../../BaseObject';
import { Text } from '../html-elements/Text';

export class TextGroup extends BaseObject {
  private readonly _textGroup: Text[];

  constructor(data: any) {
    super('TextGroup', data);

    this._textGroup = data.text_group;
  }

  get textGroup(): Text[] {
    return this._textGroup;
  }
}
