import {Component, Input, ViewEncapsulation} from '@angular/core';
import {AnywhereItemData} from '../../../../_data/video-player.model';

@Component({
  selector: 'amg-date-and-time',
  templateUrl: './date-and-time.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DateAndTimeComponent {
  date: string;

  @Input()
  set cardData(cardData: AnywhereItemData) {
    if (cardData) {
      this.setDate(cardData);
    }
  }
  constructor() {}

  setDate(cardData: AnywhereItemData) {
    const fixtureDate = cardData.startDate;
    const vodData = (cardData.publicationData)
      ? cardData.publicationData.releaseFrom
      : null;
    this.date = (fixtureDate)
      ? fixtureDate
      : vodData
        ? vodData
        : null;
  }

}
