import { BaseObject } from '../BaseObject';

export class RestApiConfiguration extends BaseObject {
  private readonly _baseUrl: string;
  private readonly _apiRequestMethod: string;
  private readonly _headerData: { key: string; value: string; }[];

  public static merge(parentConfig: RestApiConfiguration, childConfig: RestApiConfiguration): RestApiConfiguration {
    return new RestApiConfiguration({
      base_url: (childConfig && childConfig.baseUrl) ? childConfig.baseUrl : parentConfig.baseUrl,
      request_method: (childConfig && childConfig.apiRequestMethod) ? childConfig.apiRequestMethod : parentConfig.apiRequestMethod,
      header_config: (childConfig && childConfig.headerData) ? childConfig.headerData : parentConfig.headerData
    });
  }

  constructor(data: any) {
    super('RestApiConfiguration', data);

    this._headerData = data.header_config || [];
    this._baseUrl = data.base_url;
    this._apiRequestMethod = data.request_method;
  }

  get baseUrl(): string {
    return this._baseUrl;
  }

  get apiRequestMethod(): string {
    return this._apiRequestMethod;
  }

  get headerData(): { key: string; value: string }[] {
    return this._headerData;
  }
}
