/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./components/filter-bar/filter-bar.component.ngfactory";
import * as i2 from "./components/filter-bar/filter-bar.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/ngx-pagination/dist/ngx-pagination.ngfactory";
import * as i5 from "ngx-pagination";
import * as i6 from "../../../card-profile/src/lib/card-profile.component.ngfactory";
import * as i7 from "../../../card-profile/src/lib/card-profile.component";
import * as i8 from "../../../amg/src/lib/bootstrap.viewport.service";
import * as i9 from "./grid.component";
import * as i10 from "@angular/common/http";
import * as i11 from "@angular/router";
var styles_GridComponent = [];
var RenderType_GridComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GridComponent, data: {} });
export { RenderType_GridComponent as RenderType_GridComponent };
function View_GridComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-filter-bar", [], [[1, "class", 0]], [[null, "resetFilterTriggerEmitter"], [null, "triggerSearch"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resetFilterTriggerEmitter" === en)) {
        var pd_0 = (_co.onFilterReset() !== false);
        ad = (pd_0 && ad);
    } if (("triggerSearch" === en)) {
        var pd_1 = (_co.triggerSearchFilter($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_FilterBarComponent_0, i1.RenderType_FilterBarComponent)), i0.ɵdid(1, 245760, null, 0, i2.FilterBarComponent, [], { searchBarProperties: [0, "searchBarProperties"], searchObservable: [1, "searchObservable"], resetFilterBarObservable: [2, "resetFilterBarObservable"], searchValue: [3, "searchValue"] }, { triggerSearch: "triggerSearch", resetFilterTriggerEmitter: "resetFilterTriggerEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getSearchBarProperties(); var currVal_2 = _co.getSearchObservable(); var currVal_3 = _co.getTriggerFilterResetAsObservable(); var currVal_4 = _co.defaultSearchValue; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
function View_GridComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_GridComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_4)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.componentData[0].key === undefined); var currVal_1 = i0.ɵnov(_v.parent.parent, 8); var currVal_2 = i0.ɵnov(_v.parent.parent, 9); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_GridComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-outline-dark btn-load-more"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickLoadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridStaticProperties.loadMoreText; _ck(_v, 1, 0, currVal_0); }); }
function View_GridComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "pagination-controls", [], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = (_co.onPageChangeClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_PaginationControlsComponent_0, i4.RenderType_PaginationControlsComponent)), i0.ɵdid(2, 49152, null, 0, i5.PaginationControlsComponent, [], null, { pageChange: "pageChange" })], null, null); }
function View_GridComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_3)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "card amg-grid-card-empty"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "card-body"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_5)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_6)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.componentData.length > 0); _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.isLoadMoreEnabled(); _ck(_v, 6, 0, currVal_3); var currVal_4 = (_co.isPaginationEnabled() && ((_co.componentData.length > 0) && !_co.isLoadingData())); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.componentData.length > 0) || _co.isLoadingData()); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.gridStaticProperties.messageOnEmptyDataset; _ck(_v, 4, 0, currVal_2); }); }
function View_GridComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_GridComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i3.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [], null, [[null, "click"], [null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slideClick("click", _v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (_co.slideHover("hover", _v.context.index) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.slideHoverOut("hover", _v.context.index) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, [[2, 3], ["componentChild", 2]], null, 0, null, View_GridComponent_10))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getCol(_v.context.index); _ck(_v, 1, 0, currVal_0); }, null); }
function View_GridComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_GridComponent_9)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i5.PaginatePipe, [i5.PaginationService]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.componentData, _co.getPaginationConfig())); _ck(_v, 2, 0, currVal_0); }, null); }
function View_GridComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_GridComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i3.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [], null, [[null, "click"], [null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slideClick("click", _v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (_co.slideHover("hover", _v.context.index) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.slideHoverOut("hover", _v.context.index) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, [[2, 3], ["componentChild", 2]], null, 0, null, View_GridComponent_13))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getCol(_v.context.index); _ck(_v, 1, 0, currVal_0); }, null); }
function View_GridComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_12)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.componentData; _ck(_v, 2, 0, currVal_0); }, null); }
function View_GridComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "row results-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_8)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_11)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isPaginationEnabled(); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isPaginationEnabled(); _ck(_v, 4, 0, currVal_1); }, null); }
function View_GridComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i3.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [], null, [[null, "click"], [null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slideClick("click", _v.parent.context.index, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (_co.slideHover("hover", _v.parent.context.index, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.slideHoverOut("hover", _v.parent.context.index, _v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "amg-card-profile", [], null, null, null, i6.View_CardProfileComponent_0, i6.RenderType_CardProfileComponent)), i0.ɵdid(4, 245760, null, 0, i7.CardProfileComponent, [i8.BootstrapViewportService], { componentData: [0, "componentData"], staticProperties: [1, "staticProperties"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getCol(_v.parent.context.index); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = undefined; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_GridComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "row results-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_GridComponent_17)), i0.ɵdid(5, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i5.PaginatePipe, [i5.PaginationService])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_v.context.$implicit.value, _co.getPaginationConfig())); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.key; _ck(_v, 2, 0, currVal_0); }); }
function View_GridComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_16)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.componentData; _ck(_v, 2, 0, currVal_0); }, null); }
function View_GridComponent_20(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i3.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [], null, [[null, "click"], [null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slideClick("click", _v.parent.context.index, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (_co.slideHover("hover", _v.parent.context.index, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.slideHoverOut("hover", _v.parent.context.index, _v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "amg-card-profile", [], null, null, null, i6.View_CardProfileComponent_0, i6.RenderType_CardProfileComponent)), i0.ɵdid(4, 245760, null, 0, i7.CardProfileComponent, [i8.BootstrapViewportService], { componentData: [0, "componentData"], staticProperties: [1, "staticProperties"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getCol(_v.parent.context.index); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = undefined; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_GridComponent_19(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "row results-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_20)), i0.ɵdid(5, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.value; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.key; _ck(_v, 2, 0, currVal_0); }); }
function View_GridComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_19)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.componentData; _ck(_v, 2, 0, currVal_0); }, null); }
function View_GridComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_15)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_18)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isPaginationEnabled(); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isPaginationEnabled(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GridComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { gridElement: 0 }), i0.ɵqud(671088640, 2, { componentChildren: 1 }), (_l()(), i0.ɵeld(2, 0, [[1, 0], ["gridElement", 1]], null, 5, "div", [["class", "amg-grid"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridComponent_1)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_GridComponent_2)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["sectionBlock", 2]], null, 0, null, View_GridComponent_7)), (_l()(), i0.ɵand(0, [["sectionsBlock", 2]], null, 0, null, View_GridComponent_14))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSearchEnabled(); _ck(_v, 4, 0, currVal_0); var currVal_1 = !i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.getLoadingStateAsObservable())); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_GridComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-grid", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).preloadPages($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GridComponent_0, RenderType_GridComponent)), i0.ɵdid(1, 4440064, null, 0, i9.GridComponent, [[2, "videoPlayerModuleConfig"], i0.ComponentFactoryResolver, i8.BootstrapViewportService, i10.HttpClient, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GridComponentNgFactory = i0.ɵccf("amg-grid", i9.GridComponent, View_GridComponent_Host_0, { gridStaticProperties: "gridStaticProperties", componentData: "componentData", responsiveProperties: "responsiveProperties", searchObservable: "searchObservable", defaultSearchValue: "defaultSearchValue" }, { cardClicked: "cardClicked", cardHovered: "cardHovered", triggerSearch: "triggerSearch", updateSearchUrl: "updateSearchUrl", triggerGridReRender: "triggerGridReRender" }, []);
export { GridComponentNgFactory as GridComponentNgFactory };
