import * as tslib_1 from "tslib";
import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { NgObject } from './NgObject';
var Fixtures = /** @class */ (function (_super) {
    tslib_1.__extends(Fixtures, _super);
    function Fixtures(data) {
        var _this = _super.call(this, 'Fixtures', data) || this;
        _this._isPaginationEnabled = !!data.enable_pagination;
        var limitedNumberOfItemsDefaultValue = _this.isPaginationEnabled ? 5 : 0;
        // If pagination is enabled, default to 5 items not 0.
        _this._limitNumberOfItems = data.number_of_items
            ? Number.parseInt(data.number_of_items, 10)
            : limitedNumberOfItemsDefaultValue;
        _this._fixtureCardType = data.card_type;
        _this._fixtureStaticProperties = data.static_data;
        _this._fixtureConfiguration = CollectionUtils
            .flattenArrayOfResponsiveConfiguration(data.ui_conf, Fixtures.fixturesConfigurationMapping);
        return _this;
    }
    Object.defineProperty(Fixtures.prototype, "isPaginationEnabled", {
        get: function () {
            return this._isPaginationEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Fixtures.prototype, "fixtureStaticProperties", {
        get: function () {
            return this._fixtureStaticProperties;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Fixtures.prototype, "fixtureConfiguration", {
        get: function () {
            return this._fixtureConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Fixtures.prototype, "limitNumberOfItems", {
        get: function () {
            return this._limitNumberOfItems;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Fixtures.prototype, "fixtureCardType", {
        get: function () {
            return this._fixtureCardType;
        },
        enumerable: true,
        configurable: true
    });
    Fixtures.fixturesConfigurationMapping = function (data) {
        var fixturesConfiguration = {
            fixtureTeamText: data.fixtureTeamsText,
            responsiveRefreshRate: CollectionUtils.parseNumber(data.responsiveRefreshRate)
        };
        return fixturesConfiguration;
    };
    return Fixtures;
}(NgObject));
export { Fixtures };
