import { Routes } from '@angular/router';
var routes = [
    { path: '', loadChildren: './main-page/main-page.module#MainPageModule' },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
