import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {LiveLoginOverlay} from '../../_data/video-player.model';
import {VideoPlayerService} from '../../video-player.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {UserAuthService} from 'user-auth';

@Component({
  selector: 'amg-login-overlay',
  templateUrl: './login-overlay.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LoginOverlayComponent implements OnInit {
  public liveLoginOverlayRef: LiveLoginOverlay;

  constructor(private modalService: NgbModal,
              public videoPlayerService: VideoPlayerService,
              private router: Router,
              public userAuthService: UserAuthService) {
  }

  ngOnInit() {
    this.videoPlayerService.userData.subscribe(); // initiate overlay logic

    this.videoPlayerService.liveLoginOverlayBSubject
      .subscribe((overlay) => {
        this.liveLoginOverlayRef = overlay;
      });

  }

  public openLink(link) {
    if (link) {
      this.router.navigateByUrl(link);
    } else {
      console.warn('Subscribe Button link is not configured!');
    }
  }

}
