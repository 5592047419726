import { AmgComponentResponsivePropertiesView } from 'amg';

export class CarouselResponsivePropertiesView implements AmgComponentResponsivePropertiesView {
  limitViewableItems: number;
  visibleItemsOnHover: number;
  rightMarginOnItems: number;
  imitateInfinityLoop: boolean;
  enableMouseDrag: boolean;
  enableMouseTouchDrag: boolean;
  pullDrag: boolean;
  freeDrag: boolean;
  merge: boolean;
  mergeFit: boolean;
  autoWidth: boolean;
  startPosition: number;
  URLhashListener: boolean;
  showButtons: boolean;
  rewind: boolean;
  navText: Array<any>;
  navElement: string;
  iterationLimit: number;
  slideTransition: string;
  showDots: boolean;
  dotsEach: number;
  lazyLoad: boolean;
  lazyLoadEager: number;
  autoplay: boolean;
  autoplayTimeout: number;
  autoplayHoverPause: boolean;
  // smartSpeed: number;
  // fluidSpeed: number;
  autoplaySpeed: number;
  hoverSpeed: number;
  navigationSpeed: number;
  paginationSpeed: number;
  dragEndSpeed: number;
  callbacks: boolean;
  refreshRate: number;
  responsiveBaseElement: any;
  video: boolean;
  videoHeight: number;
  videoWidth: number;
  animateOut: string;
  animateIn: string;
  info: any;
  nestedItemSelector: string;
  itemElement: string = 'div'
  stageElement: string;
  navContainer: string;
  dotsContainer: string;
  checkVisible: boolean;

  cardView: AmgComponentResponsivePropertiesView;

  public static defaultValues(input?: CarouselResponsivePropertiesView):CarouselResponsivePropertiesView {
    let toReturn: CarouselResponsivePropertiesView;
    if (input === undefined) {
      toReturn = new CarouselResponsivePropertiesView();
    } else {
      toReturn = input;
    }
    
    toReturn = {...toReturn, ...{
      limitViewableItems: 3,
      visibleItemsOnHover: 3,
      rightMarginOnItems: 0,
      imitateInfinityLoop: false,
      enableMouseDrag: true,
      enableMouseTouchDrag: true,
      pullDrag: true,
      freeDrag: true,
      merge: false,
      mergeFit: true,
      autoWidth: false,
      startPosition: 0,
      URLhashListener: false,
      showButtons: false,
      rewind: true,
      navText: ['next','prev'],
      navElement: 'div',
      iterationLimit: 1,
      slideTransition: '',
      showDots: true,
      dotsEach: 0,
      lazyLoad: false,
      lazyLoadEager: 0,
      autoplay: false,
      autoplayTimeout: 5000,
      autoplayHoverPause: false,
      // smartSpeed: 250,
      // fluidSpeed: 0,
      autoplaySpeed: 0,
      hoverSpeed: 0,
      navigationSpeed: 0,
      paginationSpeed: 0,
      dragEndSpeed: 0,
      callbacks: true,
      refreshRate: 200,
      responsiveBaseElement: {},
      video: false,
      videoHeight: 0,
      videoWidth: 0,
      animateOut: '',
      animateIn: '',
      info: {},
      nestedItemSelector: '',
      itemElement: 'div',
      stageElement: 'div',
      navContainer: '',
      dotsContainer: '',
      checkVisible: true,

      cardView: { } as AmgComponentResponsivePropertiesView,
    }};

    return toReturn;
  }
}
