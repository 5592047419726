import { NgModule } from '@angular/core';
import { BoxsetCardModule } from './modules/boxset-card/boxset-card.module';
import { VideoCardModule } from './modules/video-card/video-card.module';

@NgModule({
  declarations: [],
  imports: [
    VideoCardModule,
    BoxsetCardModule
  ],
  exports: [
    VideoCardModule,
    BoxsetCardModule
  ]
})
export class CardsModule { }
