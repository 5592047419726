import { ModuleWithProviders } from '@angular/core';
var VideoPlayerModule = /** @class */ (function () {
    function VideoPlayerModule() {
    }
    VideoPlayerModule.forRoot = function (videoPlayerModuleConfig) {
        return {
            ngModule: VideoPlayerModule,
            providers: [{ provide: 'videoPlayerModuleConfig', useValue: videoPlayerModuleConfig }]
        };
    };
    return VideoPlayerModule;
}());
export { VideoPlayerModule };
