import { ElementRef, EventEmitter, QueryList, ViewContainerRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AnywhereUtils, UrlUtils } from 'amg-fe-utils';

import { BehaviorSubject, Subscription } from 'rxjs';

import { AmgAnywhereVideo } from 'amg';

import { GridResponsiveProperties } from '../grid.responsive.properties';
import { GridResponsivePropertiesView } from '../grid.responsive.properties.view';
import { GridFilter, GridStaticProperties } from '../types/GridStaticProperties';

import { GridData } from './grid.data';

export class GridDataAnywhere implements GridData {
  private static readonly elasticQueryKey = 'query';

  private currentView: GridResponsivePropertiesView;
  private isLimitReached: boolean;
  private dataSubscription: Subscription;
  private currentPageIndex: number;
  private itemsPerPage: number;
  private totalCount: number;
  private isLoading: boolean;

  private readonly extractedUrlParams: { [key: string]: string };
  private readonly anywhereUrl: string;

  private static generateQueryFilters( filters: { search: { value: string; key?: string; }; dropdown: { [id: number]: GridFilter } })
      : { [key: string]: string } {

    const queryParameters: { [key: string]: string } = {};

    if (filters.search.value) {
      queryParameters[filters.search.key] = `(${filters.search.value}*)`;
    }

    const filterDropdownKeys = Object.keys(filters.dropdown);
    if (filterDropdownKeys.length > 0) {
      filterDropdownKeys.forEach(id => {
        const gridFilter: GridFilter = filters.dropdown[id];

        if (queryParameters[gridFilter.key]) {
          queryParameters[gridFilter.key] = `(${gridFilter.value}) AND ${queryParameters[gridFilter.key]}`;
        } else {
          queryParameters[gridFilter.key] = `(${gridFilter.value})`;
        }
      });
    }

    return queryParameters;
  }

  private static mergeQueryFiltersIntoParameters(queryFilters: { [key: string]: string }, queryParameters: { [key: string]: string })
      : { [key: string]: string } {

    const queryFiltersKeys = Object.keys(queryFilters);

    const currentQueryParams: { [key: string]: string } = {
      ...queryParameters,
      ...( (!queryParameters[GridDataAnywhere.elasticQueryKey] && queryFiltersKeys.length > 0) ? { query: '' } : {} )
    };

    queryFiltersKeys.forEach(key => {
      const value = queryFilters[key];

      currentQueryParams[GridDataAnywhere.elasticQueryKey] = currentQueryParams[GridDataAnywhere.elasticQueryKey]
        ? `${currentQueryParams[GridDataAnywhere.elasticQueryKey]} AND ${key}:${value}`
        : `${key}:${value}`;
    });

    if (currentQueryParams[GridDataAnywhere.elasticQueryKey]) {
      currentQueryParams[GridDataAnywhere.elasticQueryKey] = `(${currentQueryParams[GridDataAnywhere.elasticQueryKey]})`;
    }

    return currentQueryParams;
  }

  private static generateQueryParameters(
    filters: { search: { value: string; key?: string; }; dropdown: { [id: number]: GridFilter } },
    urlParameters: { [key: string]: string },
    pageIndex: string,
    pageSize: string
  ): { [key: string]: string } {

    const queryFilters = GridDataAnywhere.generateQueryFilters(filters);
    const mergedUrlParameters = {
      ...urlParameters,

      sortBy: (urlParameters.sortBy)
        ? urlParameters.sortBy
        : 'publicationData.releaseFrom',
      sortOrder: (urlParameters.sortOrder)
        ? urlParameters.sortOrder
        : 'desc',

      pageIndex,
      pageSize
    };

    return GridDataAnywhere.mergeQueryFiltersIntoParameters(queryFilters, mergedUrlParameters);
  }

  constructor(private http: HttpClient,
              private componentData: Array<any>,
              private responsiveProperties: GridResponsiveProperties,
              private staticProperties: GridStaticProperties,
              private componentChildren: QueryList<ViewContainerRef>,
              private selectedFilters: { search: { value: string; key?: string; }; dropdown: { [id: number]: GridFilter } },
              private rawData: Array<any>,
              private loadingState: BehaviorSubject<boolean>,
              private gridViewReRender: EventEmitter<boolean>) {
    this.currentPageIndex = staticProperties.pageStart;
    this.itemsPerPage = staticProperties.pagination
      ? staticProperties.pagination.itemsPerPage
      : 10;
    this.totalCount = null;
    this.isLimitReached = false;
    this.isLoading = true;
    this.extractedUrlParams = UrlUtils.extractUrlParamsFromExistingUrl(staticProperties.pageUrl);
    this.anywhereUrl = AnywhereUtils.addSearchEndpointToAnywhereUrl(
      UrlUtils.removeQueryParameters(staticProperties.pageUrl)
    );
  }

  fetchData(filters: { search: { value: string; key?: string; }; dropdown: { [id: number]: GridFilter } },
            clearData: boolean,
            isPaginationEnabled: boolean,
            pageIndex?: number) {
    if (clearData || !this.isLimitReached) {
      return this.triggerCall(clearData, isPaginationEnabled, pageIndex);
    }
  }

  setCurrentView(source: string = 'unknown', currentView: GridResponsivePropertiesView) {
    this.currentView = currentView;
  }

  getIsLimitReached(): boolean {
    return this.isLimitReached;
  }

  onDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  getPagingData(): { itemsPerPage: number; currentPage: number; totalItems?: number; } {
    return {
      itemsPerPage: this.itemsPerPage,
      currentPage: (this.currentPageIndex + 1),
      ...( this.totalCount ?  { totalItems: this.totalCount } : {} )
    };
  }

  getIsLoading(): boolean {
    return this.isLoading;
  }

  private triggerCall(clearData: boolean, isPaginationEnabled: boolean, pageIndex?: number): any {
    this.isLoading = true;

    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }

    if (clearData) {
      this.currentPageIndex = this.staticProperties.pageStart;
    } else if (isPaginationEnabled) {
      this.currentPageIndex = pageIndex;
    }

    const queryParameters: any = GridDataAnywhere.generateQueryParameters(
      this.selectedFilters,
      this.extractedUrlParams,
      this.currentPageIndex.toString(),
      this.staticProperties.pageSize.toString());

    this.dataSubscription = this.http.get(this.anywhereUrl, { params: queryParameters }).subscribe((feed: any) => {
      this.isLoading = false;

      if (clearData) {
        this.resetData();
        this.currentPageIndex = this.staticProperties.pageStart;
        this.totalCount = null;
      } else if (isPaginationEnabled) {
        this.resetData();
      }

      this.setData(feed);

      if (!isPaginationEnabled) {
        this.isLimitReached = this.calculateIfLimitReached(this.currentPageIndex,
          this.itemsPerPage,
          this.totalCount);

        this.currentPageIndex++;
      }

      this.loadingState.next(false);
      this.gridViewReRender.emit(true);
    });

    const urlWithQueryString: string = this.anywhereUrl + '?' + Object.entries(queryParameters)
      .map(([key, value]) => {
        return `${key}=${value}`;
      })
      .join('&');

    return {
      pageIndex: this.currentPageIndex,
      pageSize: this.staticProperties.pageSize,
      filters: this.selectedFilters,
      url: urlWithQueryString,
    };
  }

  private setData(feed: any) {
    let sectionData: any = null;

    if (feed.sections) {
      sectionData = {
        feedMetaData: feed.feedMetaData,
        id: feed.sections[0].id,
        itemData: feed.sections[0].itemData,
        name: feed.sections[0].name,
        pagingData: feed.sections[0].pagingData
      };
    } else {
      sectionData = feed;
    }

    this.currentPageIndex = sectionData.pagingData.pageIndex;
    this.itemsPerPage = sectionData.pagingData.pageSize;
    this.totalCount = sectionData.pagingData.totalCount;

    const thumbnailWidth = screen.width / this.currentView.items;

    sectionData.itemData.forEach((video: AmgAnywhereVideo) => {
      video.mediaData.thumbnailUrl = `${video.mediaData.thumbnailUrl}/width/${thumbnailWidth}`;

      this.componentData.push(video);
      this.rawData.push(video);
    });
  }

  private calculateIfLimitReached(pageIndex: number, pageSize: number, totalCount: number): boolean {
    const incrementedPageIndex = pageIndex + 1; // Page index starts from 0.

    return incrementedPageIndex * pageSize >= totalCount;
  }

  private resetData(): void {
    this.componentData.length = 0;
    this.rawData.length = 0;
    this.componentChildren.reset([]);
    this.isLimitReached = false;
  }
}
