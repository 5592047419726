import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { isDevMode } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { StreamPaymentsHooks } from './shared/data/stream-payments.model';
import { UserState } from './shared/data/user-auth.model';
import { TemplateBuilderComponent } from './shared/template-builder/template-builder.component';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@angular/common/http";
export var ScriptStore = [];
var UserAuthService = /** @class */ (function () {
    function UserAuthService(config, modalService, http) {
        var _this_1 = this;
        this.config = config;
        this.modalService = modalService;
        this.http = http;
        // Vars
        this.streamPaymentsInitialized = false;
        // private observables
        this._userDataBSubject = new BehaviorSubject(null);
        this.scripts = {};
        // Observables
        this.streamPaymentsSessionState = new BehaviorSubject(null);
        this.userLoginState = new BehaviorSubject(null);
        this.userSubscriptionState = new BehaviorSubject(false);
        this.modalData = new BehaviorSubject(null);
        this.streamPaymentsSubscriptionPackage = new BehaviorSubject(null);
        this.formSubmissionResponse = new BehaviorSubject(null);
        this.userLoginDetailsSubject = new BehaviorSubject(null);
        this.paymentsData = this.getConfig();
        var sessionDataRaw = localStorage.getItem('StreamPaymentSessionData');
        if (sessionDataRaw) {
            var sessionData = JSON.parse(sessionDataRaw);
            if (sessionData && sessionData.UpdatedAt) {
                // set auth cookie
                if (sessionData.AuthenticationToken) {
                    document.cookie = (sessionData.AuthenticationToken)
                        ? '.auth = ' + sessionData.AuthenticationToken + ("/domain=" + this.getStreamPaymentsUrl())
                        : '';
                }
                var dateNow = new Date();
                var updatedAt = new Date(sessionData.UpdatedAt);
                var diffInMins = (dateNow.getUTCHours() * 60 - updatedAt.getUTCHours() * 60
                    + dateNow.getUTCDay() * 60 * 24 - updatedAt.getUTCDay() * 60 * 24
                    + dateNow.getUTCMinutes() - updatedAt.getUTCMinutes());
                if (diffInMins >= 24 * 60) {
                    this.hookStreamPayments(StreamPaymentsHooks.doLogout, null);
                }
                else {
                    this.setUserSessionState(sessionData);
                }
            }
        }
        this.getPaymentsSession().subscribe(function (res) {
            _this_1.customerSessionData = res;
        });
        this.initStreamPayments(this.paymentsData, function () {
            if (_this_1.config && _this_1.config.loginFormJson) {
                _this_1.setModalData(_this_1.config.loginFormJson);
            }
            if (config.isSso) {
                _this_1.triggerPaymentsOnTokenUpdate();
            }
        });
        localStorage.removeItem('StreamPaymentLoginCallback');
    }
    UserAuthService.prototype.getJwtToken = function () {
        var token = window.localStorage.getItem(UserAuthService.jwtTokenKey);
        return token
            ? token
            : '';
    };
    UserAuthService.prototype.getConfig = function () {
        var language = 'en';
        if (this.config.isSso) {
            var jwtToken = this.getJwtToken();
            return { jwtToken: jwtToken, language: language };
        }
        return { language: language };
    };
    UserAuthService.prototype.triggerPaymentsOnTokenUpdate = function () {
        var _this_1 = this;
        var config = this.getConfig();
        this.checkForChangesAndInitPayments(config);
        if (config.jwtToken) {
            streamPayments.getSessionState(null, function (sessionData) {
                if (sessionData) {
                    _this_1.setUserSessionState(sessionData);
                }
                if (isDevMode()) {
                    console.log('[SSO Stream Payments Session check]', sessionData);
                }
            });
        }
    };
    Object.defineProperty(UserAuthService.prototype, "customerSessionData", {
        get: function () {
            return this._userDataBSubject.asObservable();
        },
        set: function (userData) {
            if (userData && userData.CurrentCustomerSession !== null) {
                this.setUserState(UserState.loggedIn);
                this._userDataBSubject.next(userData);
            }
        },
        enumerable: true,
        configurable: true
    });
    UserAuthService.prototype.initStreamPayments = function (data, callback) {
        var _this_1 = this;
        if (!this.streamPaymentsInitialized) {
            return this.loadDependencies(this.config.streamPayments, function (res) {
                if (res) {
                    streamPayments.init(data);
                    if (callback) {
                        _this_1.streamPaymentsInitialized = true;
                        callback(res);
                    }
                }
                else {
                    _this_1.streamPaymentsInitialized = false;
                    console.warn('Stream Payments script could not be successfully loaded');
                }
            });
        }
        else {
            this.checkForChangesAndInitPayments(data);
            callback(true);
        }
    };
    UserAuthService.prototype.checkForChangesAndInitPayments = function (data) {
        if (this.config.isSso
            && data.jwtToken !== this.paymentsData.jwtToken) {
            // re-init with token
            streamPayments.init(data);
            this.paymentsData = data;
        }
    };
    UserAuthService.prototype.getFormSubmissionResponse = function () {
        return this.formSubmissionResponse.asObservable();
    };
    UserAuthService.prototype.setFormSubmissionResponse = function (data) {
        this.amgLogger('setFormSubmissionResponse', data);
        this.formSubmissionResponse.next(data);
        if (!data.ModelErrors) {
            this.modalRef.close();
        }
    };
    UserAuthService.prototype.getUserSessionState = function () {
        return this.streamPaymentsSessionState.asObservable();
    };
    UserAuthService.prototype.setUserSessionState = function (userData) {
        if (userData) {
            this.streamPaymentsSessionState.next(userData);
            if (userData.JwtTokenCacheKey) {
                this.paymentsData = tslib_1.__assign({}, this.paymentsData, { jwtToken: userData.JwtTokenCacheKey });
            }
            if (userData.CurrentCustomerSession && userData.CurrentCustomerSession.Id) {
                this.authTokenRef = userData.CurrentCustomerSession.Id;
            }
            if (userData.CurrentCustomerSession !== null) {
                this.customerSessionData = userData;
                this.setUserLoginDetails({
                    name: userData.CurrentCustomerSession.CustomerFirstName || '',
                    lastName: userData.CurrentCustomerSession.CustomerLastName || '',
                    isUserLoggedIn: true
                });
            }
        }
    };
    UserAuthService.prototype.getUserLoginDetailsObservable = function () {
        return this.userLoginDetailsSubject.asObservable();
    };
    UserAuthService.prototype.setUserSubscriptionState = function (state) {
        this.userSubscriptionState.next(state);
    };
    UserAuthService.prototype.getUserSubscriptionState = function () {
        return this.userSubscriptionState.asObservable();
    };
    UserAuthService.prototype.setUserState = function (state) {
        this.userLoginState.next(state);
    };
    UserAuthService.prototype.getUserState = function () {
        return this.userLoginState.asObservable();
    };
    UserAuthService.prototype.setPackageData = function (data) {
        this.streamPaymentsSubscriptionPackage.next(data);
    };
    UserAuthService.prototype.setModalData = function (data) {
        this.modalData.next(data);
    };
    UserAuthService.prototype.getModalData = function () {
        return this.modalData.asObservable();
    };
    /**
     * Stream Payment script wrapper;
     * @param functionName
     * @param data
     */
    UserAuthService.prototype.hookStreamPayments = function (functionName, data) {
        var _this_1 = this;
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this_1.initStreamPayments(_this_1.getConfig(), function () {
                if (data && _this_1.authTokenRef && functionName !== 'doLogin' && _this_1.isSafariBrowser()) {
                    data['apisessionid'] = _this_1.authTokenRef;
                }
                streamPayments[functionName](data, function (res) {
                    _this.handleResponse(res, resolve, reject, functionName);
                    if (functionName === 'doLogout') {
                        _this_1.clearUserData();
                        location.reload();
                    }
                });
            });
        });
    };
    // StreamPayments specific - BEGIN
    UserAuthService.prototype.loadExternalApiScript = function (scriptConf, callback) {
        this.enqueueScript(scriptConf)
            .then(function (res) {
            callback(res);
        });
    };
    UserAuthService.prototype.openModal = function () {
        var _this_1 = this;
        if (this.modalRef) {
            this.modalRef.close();
        }
        this.modalRef = this.modalService.open(TemplateBuilderComponent, {
            keyboard: true,
            backdrop: 'static'
        });
        if (!this.modalRef.componentInstance.dataObj) {
            this.getModalData()
                .subscribe(function (modalData) {
                if (modalData) {
                    if (_this_1.modalRef.componentInstance) {
                        _this_1.modalRef.componentInstance.dataObj = modalData;
                        _this_1.modalRef.componentInstance.modalRef = _this_1.modalRef;
                    }
                }
            });
        }
    };
    UserAuthService.prototype.amgLogger = function (functionName, data) {
        // console.log('~~~~ Function Name: ', functionName);
        // console.log('~~~~ Data: ', data);
    };
    UserAuthService.prototype.getPaymentsSession = function () {
        return this.http.get(this.getStreamPaymentsUrl(true) + "/api/v1/session/prescence");
    };
    UserAuthService.prototype.getStreamPaymentsUrl = function (generateProtocol) {
        var pathArray = this.config.streamPayments.src.split('/');
        var protocol = pathArray[0];
        var host = pathArray[2];
        return generateProtocol
            ? protocol + '//' + host
            : host;
    };
    UserAuthService.prototype.setUserLoginDetails = function (data) {
        this.userLoginDetailsSubject.next(data);
    };
    UserAuthService.prototype.isSafariBrowser = function () {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') != -1) {
            if (ua.indexOf('chrome') > -1) {
                return false;
            }
            else {
                return true;
            }
        }
    };
    /**
     * Intercept response to update session and user states when necessary;
     * @param res - response from stream payments;
     * @param resolve - resolves promise with data retrieved from stream payments;
     * @param reject - reject promise in case stream payments returns error;
     * @param functionName - stream payments function name as os in stream-payments.model;
     */
    UserAuthService.prototype.handleResponse = function (res, resolve, reject, functionName) {
        var errors = this.outputError(res.ModelErrors);
        if (errors) {
            reject(errors);
        }
        else {
            if (res) {
                if (functionName === 'doLogin' ||
                    functionName === 'getUserSessionState') {
                    this.setUserSessionState(res);
                    if (res.PageUrl) {
                        window.location.href = res.PageUrl;
                    }
                }
            }
            resolve(res);
        }
    };
    // StreamPayments specific - END
    UserAuthService.prototype.outputError = function (err) {
        var error = false;
        if (err) {
            if (err.emailaddress) {
                error = err.emailaddress;
            }
            if (err.password) {
                error = err.password;
            }
        }
        return error;
    };
    UserAuthService.prototype.clearUserData = function () {
        this.customerSessionData = null;
        this.userLoginDetailsSubject.next(null);
        localStorage.removeItem('StreamPaymentSessionData');
        localStorage.removeItem(UserAuthService.jwtTokenKey);
        this.clearAllCookies();
    };
    UserAuthService.prototype.clearAllCookies = function () {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    };
    /**
     * Load required external Apis;
     * @param scriptConfig
     * @param callback
     */
    UserAuthService.prototype.loadDependencies = function (scriptConfig, callback) {
        var _this_1 = this;
        // load dependencies first
        if (scriptConfig.dependencies) {
            scriptConfig.dependencies.forEach(function (dep, index) {
                _this_1.enqueueScript(dep)
                    .then(function (depRes) {
                    if (depRes) {
                        if (scriptConfig.dependencies.length - 1 === index) {
                            _this_1.loadExternalApiScript(scriptConfig, function (res) {
                                callback(res);
                            });
                        }
                    }
                });
            });
        }
        else {
            this.loadExternalApiScript(scriptConfig, function (res) {
                callback(res);
            });
        }
    };
    UserAuthService.prototype.load = function () {
        var _this_1 = this;
        var scripts = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            scripts[_i] = arguments[_i];
        }
        var promises = [];
        scripts.forEach(function (script) { return promises.push(_this_1.loadScript(script)); });
        return Promise.all(promises);
    };
    UserAuthService.prototype.loadScript = function (name) {
        var _this_1 = this;
        return new Promise(function (resolve, reject) {
            if (!_this_1.scripts[name].loaded) {
                //load script
                var script_1 = document.createElement('script');
                script_1.type = 'text/javascript';
                script_1.src = _this_1.scripts[name].src;
                if (script_1.readyState) { //IE
                    script_1.onreadystatechange = function () {
                        if (script_1.readyState === 'loaded' || script_1.readyState === 'complete') {
                            script_1.onreadystatechange = null;
                            _this_1.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                }
                else { //Others
                    script_1.onload = function () {
                        _this_1.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script_1.onerror = function (error) { return resolve({ script: name, loaded: false, status: 'Loaded' }); };
                document.getElementsByTagName('head')[0].appendChild(script_1);
            }
            else {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            }
        });
    };
    /**
     * Load external dependencies
     * @param script
     */
    UserAuthService.prototype.enqueueScript = function (script) {
        var _this_1 = this;
        ScriptStore.push(script);
        ScriptStore.forEach(function (script) {
            _this_1.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
        return this.load(script.name);
    };
    UserAuthService.jwtTokenKey = 'stream-token';
    UserAuthService.ngInjectableDef = i0.defineInjectable({ factory: function UserAuthService_Factory() { return new UserAuthService(i0.inject("videoPlayerModuleConfig"), i0.inject(i1.NgbModal), i0.inject(i2.HttpClient)); }, token: UserAuthService, providedIn: "root" });
    return UserAuthService;
}());
export { UserAuthService };
