import { CardFixtureResponsiveProperties } from 'card-fixture';
import { CardImageResponsiveProperties } from 'card-image';
import { CardProfileResponsiveProperties } from 'card-profile';
import { CardTextResponsiveProperties } from 'card-text';
import { CardVideoResponsiveProperties } from 'card-video';
import { CardType } from './card.type';
var CardResponsiveInstantiate = /** @class */ (function () {
    function CardResponsiveInstantiate() {
    }
    /**
     * Returns an instanciated card based on the given parameters
     * @param cardType Enum value of card type
     * @param data data associated with the card
     * @param route route that the card is supposed to follow if the default action is triggered
     * @param params additional parameters for the route
     * @param responsive responsive properties for the card
     */
    CardResponsiveInstantiate.instantiate = function (cardType, params) {
        switch (cardType) {
            case (CardType.CalendarEvent):
            case (CardType.CalendarFixture):
            case (CardType.Event):
            case (CardType.Fixture): {
                return new CardFixtureResponsiveProperties({
                    xs: this.cast(params.xs),
                    sm: this.cast(params.sm),
                    md: this.cast(params.md),
                    lg: this.cast(params.lg),
                    xl: this.cast(params.xl),
                });
            }
            case (CardType.Image): {
                return new CardImageResponsiveProperties({
                    xs: this.cast(params.xs),
                    sm: this.cast(params.sm),
                    md: this.cast(params.md),
                    lg: this.cast(params.lg),
                    xl: this.cast(params.xl),
                });
            }
            case (CardType.Profile): {
                return new CardProfileResponsiveProperties({
                    xs: this.cast(params.xs),
                    sm: this.cast(params.sm),
                    md: this.cast(params.md),
                    lg: this.cast(params.lg),
                    xl: this.cast(params.xl),
                });
            }
            case (CardType.Text): {
                return new CardTextResponsiveProperties({
                    xs: this.cast(params.xs),
                    sm: this.cast(params.sm),
                    md: this.cast(params.md),
                    lg: this.cast(params.lg),
                    xl: this.cast(params.xl),
                });
            }
            case (CardType.Video): {
                return new CardVideoResponsiveProperties({
                    xs: this.cast(params.xs),
                    sm: this.cast(params.sm),
                    md: this.cast(params.md),
                    lg: this.cast(params.lg),
                    xl: this.cast(params.xl),
                });
            }
        }
    };
    CardResponsiveInstantiate.cast = function (o) {
        return o;
    };
    return CardResponsiveInstantiate;
}());
export { CardResponsiveInstantiate };
