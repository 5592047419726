import { ModuleWithProviders } from '@angular/core';
import { CLIENT_OPTIONS } from './config/client-options';
var WordpressAdapterModule = /** @class */ (function () {
    function WordpressAdapterModule() {
    }
    WordpressAdapterModule.forRoot = function (clientOptions) {
        return {
            ngModule: WordpressAdapterModule,
            providers: [
                { provide: CLIENT_OPTIONS, useValue: clientOptions }
            ]
        };
    };
    return WordpressAdapterModule;
}());
export { WordpressAdapterModule };
