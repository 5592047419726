/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./calendar-fixture/calendar-fixture.component.ngfactory";
import * as i2 from "./calendar-fixture/calendar-fixture.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../../../shared/data/data.service";
import * as i5 from "./game-fixture/game-fixture.component.ngfactory";
import * as i6 from "./game-fixture/game-fixture.component";
import * as i7 from "./event-fixture/event-fixture.component.ngfactory";
import * as i8 from "./event-fixture/event-fixture.component";
import * as i9 from "@angular/common";
import * as i10 from "./fixture.component";
import * as i11 from "../../../../../shared/api/api.service";
var styles_FixtureComponent = [];
var RenderType_FixtureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FixtureComponent, data: {} });
export { RenderType_FixtureComponent as RenderType_FixtureComponent };
function View_FixtureComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "amg-loading-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "loading"]], null, null, null, null, null))], null, null); }
function View_FixtureComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "amg-wp-calendar-fixture", [], [[1, "class", 0]], null, null, i1.View_CalendarFixtureComponent_0, i1.RenderType_CalendarFixtureComponent)), i0.ɵdid(2, 114688, null, 0, i2.CalendarFixtureComponent, [i3.Router, i4.DataService], { data: [0, "data"], onClickNavRoute: [1, "onClickNavRoute"], sendEntryDataOnNavigation: [2, "sendEntryDataOnNavigation"], apiUrl: [3, "apiUrl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.fixtureData; var currVal_2 = _co.fixtureOnClickNavRoute; var currVal_3 = _co.fixtureSendEntryDataOnNavigation; var currVal_4 = _co.fixtureApiUrl; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); }); }
function View_FixtureComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-game-fixture", [], [[1, "class", 0]], null, null, i5.View_GameFixtureComponent_0, i5.RenderType_GameFixtureComponent)), i0.ɵdid(1, 114688, null, 0, i6.GameFixtureComponent, [i3.Router, i4.DataService], { data: [0, "data"], onClickNavRoute: [1, "onClickNavRoute"], sendEntryDataOnNavigation: [2, "sendEntryDataOnNavigation"], apiUrl: [3, "apiUrl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.fixtureData; var currVal_2 = _co.fixtureOnClickNavRoute; var currVal_3 = _co.fixtureSendEntryDataOnNavigation; var currVal_4 = _co.fixtureApiUrl; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
function View_FixtureComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-event-fixture", [], [[1, "class", 0]], null, null, i7.View_EventFixtureComponent_0, i7.RenderType_EventFixtureComponent)), i0.ɵdid(1, 114688, null, 0, i8.EventFixtureComponent, [i3.Router, i4.DataService], { data: [0, "data"], onClickNavRoute: [1, "onClickNavRoute"], sendEntryDataOnNavigation: [2, "sendEntryDataOnNavigation"], apiUrl: [3, "apiUrl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.fixtureData; var currVal_2 = _co.fixtureOnClickNavRoute; var currVal_3 = _co.fixtureSendEntryDataOnNavigation; var currVal_4 = _co.fixtureApiUrl; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
function View_FixtureComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i9.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FixtureComponent_5)), i0.ɵdid(3, 278528, null, 0, i9.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i9.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FixtureComponent_6)), i0.ɵdid(5, 278528, null, 0, i9.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i9.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isOfTypeFixture(_co.fixtureData); _ck(_v, 1, 0, currVal_0); var currVal_1 = true; _ck(_v, 3, 0, currVal_1); var currVal_2 = false; _ck(_v, 5, 0, currVal_2); }, null); }
function View_FixtureComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "amg-message-container no-fixture"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "message"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sorry, no fixtures found"]))], null, null); }
function View_FixtureComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FixtureComponent_3)), i0.ɵdid(1, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FixtureComponent_4)), i0.ɵdid(3, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FixtureComponent_7)), i0.ɵdid(5, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isFixtureCalendar() && _co.fixtureData); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.isFixtureCalendar() && _co.fixtureData); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.isIndividualFixture && !_co.fixtureData); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_FixtureComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FixtureComponent_1)), i0.ɵdid(1, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["loadedDataView", 2]], null, 0, null, View_FixtureComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoadingData; var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FixtureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-fixture", [], [[1, "class", 0], [1, "data-is-data-available", 0]], null, null, View_FixtureComponent_0, RenderType_FixtureComponent)), i0.ɵdid(1, 245760, null, 0, i10.FixtureComponent, [i11.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; var currVal_1 = i0.ɵnov(_v, 1).isDataAvailable; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var FixtureComponentNgFactory = i0.ɵccf("amg-wp-fixture", i10.FixtureComponent, View_FixtureComponent_Host_0, { fixture: "fixture", apiFixtureData: "apiFixtureData" }, {}, []);
export { FixtureComponentNgFactory as FixtureComponentNgFactory };
