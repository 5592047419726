import * as tslib_1 from "tslib";
import { CalendarTab } from './calendar.tab.enum';
var CalendarResponsivePropertiesView = /** @class */ (function () {
    function CalendarResponsivePropertiesView() {
    }
    CalendarResponsivePropertiesView.defaultValues = function (input) {
        var toReturn;
        if (input === undefined) {
            toReturn = new CalendarResponsivePropertiesView();
        }
        else {
            toReturn = input;
        }
        toReturn = tslib_1.__assign({}, toReturn, {
            tab: CalendarTab.Month,
            tabs: 3,
            tabsWhenHovered: 3,
            tabNavText: ['after', 'before'],
            refreshRate: 200,
            fixtureView: {},
        });
        return toReturn;
    };
    return CalendarResponsivePropertiesView;
}());
export { CalendarResponsivePropertiesView };
