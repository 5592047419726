import {
  AmgComponentResponsiveProperties,
  BootstrapViewportEnum,
} from 'amg';

import { CardVideoResponsivePropertiesView } from './card.video.responsive.properties.view';

export class CardVideoResponsiveProperties implements AmgComponentResponsiveProperties {
  private static keys: Array<string> = Object.keys(BootstrapViewportEnum);

  xs?: CardVideoResponsivePropertiesView;
  sm?: CardVideoResponsivePropertiesView;
  md?: CardVideoResponsivePropertiesView;
  lg?: CardVideoResponsivePropertiesView;
  xl?: CardVideoResponsivePropertiesView;
  HD?: CardVideoResponsivePropertiesView;
  FullHd?: CardVideoResponsivePropertiesView;
  FourK?: CardVideoResponsivePropertiesView;

  constructor(
    params?: any,
  ) {
    if (params === undefined) {
      params = {};
    }
    this.xs = params.xs || null;
    this.sm = params.sm || null;
    this.md = params.md || null;
    this.lg = params.lg || null;
    this.xl = params.xl || null;
    this.HD = params.HD || null;
    this.FullHd = params.FullHd || null;
    this.FourK = params.FourK || null;
    this.synchroniseProperties();
  }

  private synchroniseProperties(overrideResponsive?: CardVideoResponsiveProperties) {
    if (overrideResponsive === undefined) {
      overrideResponsive = {} as CardVideoResponsiveProperties;
    }
    let previousView: CardVideoResponsivePropertiesView = CardVideoResponsivePropertiesView.defaultValues();
    CardVideoResponsiveProperties.keys.forEach((viewPort) => {
      let currentView: CardVideoResponsivePropertiesView = this[viewPort];
      let currentValues: CardVideoResponsivePropertiesView = overrideResponsive[viewPort];
      currentView = {...previousView, ...currentView, ...currentValues};
      this[viewPort] = currentView;
      previousView = currentView;
    });
  }
}
