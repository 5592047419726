import { OnInit } from '@angular/core';
import { StreamPaymentsHooks } from '../../shared/data/stream-payments.model';
import { FormBuilder, FormControl } from '@angular/forms';
import { UserAuthService } from '../../user-auth.service';
var FormBuilderComponent = /** @class */ (function () {
    function FormBuilderComponent(fb, userAuthService) {
        this.fb = fb;
        this.userAuthService = userAuthService;
        this.formRef = this.fb.group({});
        this.localFormRef = [];
        this.formSubmissionMessageResponse = {
            success: '',
            error: ''
        };
    }
    FormBuilderComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.formData) {
            if (Array.isArray(this.formData)) {
                this.loopFormControlArray(this.formData);
            }
            else {
                this.loopFormControlObjects(this.formData);
            }
        }
        this.userAuthService.getFormSubmissionResponse()
            .subscribe(function (formResponse) {
            if (formResponse && formResponse.ModelErrors) {
                var formError = formResponse.ModelErrors[Object.keys(formResponse.ModelErrors)[0]];
                _this.formSubmissionMessageResponse.error = formError ? formError : 'The details provided are incorrect, please try again.';
            }
        });
    };
    FormBuilderComponent.prototype.isTextInput = function (formField) {
        return !formField.input_type || (formField.input_type === 'text') || (formField.input_type === 'password');
    };
    FormBuilderComponent.prototype.isPasswordInput = function (formField) {
        return formField.input_type === 'password';
    };
    FormBuilderComponent.prototype.isSelectInput = function (formField) {
        return formField.input_type === 'select';
    };
    FormBuilderComponent.prototype.isSubmitButton = function (formField) {
        return formField.input_type === 'button' || formField.input_type === 'submit' || formField.input_type === 'submit_button';
    };
    FormBuilderComponent.prototype.getPlaceholder = function (formField) {
        return (formField.placeholder) ? formField.placeholder : '';
    };
    FormBuilderComponent.prototype.getInputType = function (formField) {
        return formField.input_type;
    };
    FormBuilderComponent.prototype.getFormControlName = function (formField) {
        return formField.controlName;
    };
    FormBuilderComponent.prototype.formSubmitType = function () {
        var _this = this;
        var loginFormFields = this.formTemplates();
        var submitType;
        var matchingResults = [];
        loginFormFields
            .forEach(function (loginField) {
            Object.keys(_this.formRef.controls)
                .forEach(function (formKey) {
                if (formKey === loginField) {
                    matchingResults.push(true);
                }
            });
        });
        if (this.reduceBooleanArray(matchingResults) && matchingResults.length === loginFormFields.length) {
            submitType = StreamPaymentsHooks.doLogin;
        }
        else {
            submitType = StreamPaymentsHooks.doContactSubmission;
        }
        return submitType;
    };
    FormBuilderComponent.prototype.formTemplates = function () {
        return ['emailaddress', 'password'];
    };
    FormBuilderComponent.prototype.reduceBooleanArray = function (array) {
        var reducer = function (accumulator, currentValue) {
            return accumulator || currentValue;
        };
        return array.reduce(reducer);
    };
    FormBuilderComponent.prototype.loopFormControlObjects = function (formObj) {
        var _this = this;
        var form = (formObj.form) ? formObj.form : formObj;
        if (form) {
            Object.keys(form)
                .forEach(function (objKey) {
                var currentObj = form[objKey];
                var controlVal = (currentObj.value) ? currentObj.value : (currentObj.values) ? currentObj.values : '';
                if (!currentObj.input_type) {
                    currentObj.input_type = objKey;
                }
                _this.createControl("" + currentObj.internal_reference_name, controlVal, currentObj);
            });
        }
    };
    FormBuilderComponent.prototype.loopFormControlArray = function (formArray) {
        var _this = this;
        formArray.forEach(function (formObj, index) {
            _this.createControl("" + formObj.internal_reference_name, formObj.value, formObj);
        });
    };
    FormBuilderComponent.prototype.createControl = function (controlName, controlValues, localRefData) {
        localRefData.controlName = controlName;
        if (controlName) {
            this.formRef.addControl(controlName, new FormControl((controlValues) ? controlValues : ''));
        }
        this.localFormRef.push(localRefData);
    };
    FormBuilderComponent.prototype.formatFormOutput = function (streamPaymentsHook) {
        if (streamPaymentsHook === StreamPaymentsHooks.doContactSubmission) {
            return {
                body: this.formRef.value.body,
                emailaddress: this.formRef.value.emailaddress,
                name: this.formRef.value.name,
                subject: this.formRef.value.subject
            };
        }
        if (streamPaymentsHook === StreamPaymentsHooks.doLogin) {
            return {
                emailaddress: this.formRef.value.emailaddress,
                password: this.formRef.value.password
            };
        }
    };
    FormBuilderComponent.prototype.onSubmit = function (e) {
        var _this = this;
        e.preventDefault();
        this.formOutput = this.formSubmitType();
        var dataOutput = this.formatFormOutput(this.formOutput);
        var doSubmit = this.userAuthService.hookStreamPayments(this.formOutput, dataOutput);
        doSubmit.then(function (res) {
            _this.userAuthService.setFormSubmissionResponse(res);
        }).catch(function (err) {
            console.log('catch: ', err);
            _this.formSubmissionMessageResponse.error = err;
        });
    };
    return FormBuilderComponent;
}());
export { FormBuilderComponent };
