/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./sign-in.component";
import * as i2 from "../user-auth.service";
var styles_SignInComponent = [];
var RenderType_SignInComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SignInComponent, data: {} });
export { RenderType_SignInComponent as RenderType_SignInComponent };
export function View_SignInComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_SignInComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-sign-in", [], null, null, null, View_SignInComponent_0, RenderType_SignInComponent)), i0.ɵdid(1, 114688, null, 0, i1.SignInComponent, [i2.UserAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignInComponentNgFactory = i0.ɵccf("amg-sign-in", i1.SignInComponent, View_SignInComponent_Host_0, { loginFormJson: "loginFormJson" }, {}, []);
export { SignInComponentNgFactory as SignInComponentNgFactory };
