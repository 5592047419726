/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../date/date.component.ngfactory";
import * as i3 from "../date/date.component";
import * as i4 from "../live-buttons/live-buttons.component.ngfactory";
import * as i5 from "../live-buttons/live-buttons.component";
import * as i6 from "./card-event.component";
import * as i7 from "@angular/common/http";
var styles_CardEventComponent = [];
var RenderType_CardEventComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CardEventComponent, data: {} });
export { RenderType_CardEventComponent as RenderType_CardEventComponent };
export function View_CardEventComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "amg-card-event"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "amg-fixture-live": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "amg-card-event-info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "amg-date", [["class", "amg-date"]], null, null, null, i2.View_DateComponent_0, i2.RenderType_DateComponent)), i0.ɵdid(5, 114688, null, 0, i3.DateComponent, [], { fixtureDate: [0, "fixtureDate"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "amg-date", [["class", "amg-date"]], null, null, null, i2.View_DateComponent_0, i2.RenderType_DateComponent)), i0.ɵdid(7, 114688, null, 0, i3.DateComponent, [], { fixtureDate: [0, "fixtureDate"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [["class", "amg-card-event-info-type"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "amg-card-event-details"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "h2", [["class", "amg-card-event-details-title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "div", [["class", "amg-card-event-details-description"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "amg-card-event-meta"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "amg-live-buttons", [], null, [[null, "triggerClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("triggerClick" === en)) {
        var pd_0 = (_co.onMediaDataButtonClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_LiveButtonsComponent_0, i4.RenderType_LiveButtonsComponent)), i0.ɵdid(14, 114688, null, 0, i5.LiveButtonsComponent, [], { fixtureMediaData: [0, "fixtureMediaData"] }, { triggerClick: "triggerClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "amg-card-event"; var currVal_1 = _ck(_v, 2, 0, _co.getIsFixtureLive()); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.getFixtureDate(); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.getFixtureDate(); _ck(_v, 7, 0, currVal_3); var currVal_7 = _co.getMediaData(); _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.getEventType(); _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.getName(); _ck(_v, 10, 0, currVal_5); var currVal_6 = _co.getDescription(); _ck(_v, 11, 0, currVal_6); }); }
export function View_CardEventComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-card-event", [], [[1, "is-live", 0]], null, null, View_CardEventComponent_0, RenderType_CardEventComponent)), i0.ɵdid(1, 245760, null, 0, i6.CardEventComponent, [i7.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).isLive; _ck(_v, 0, 0, currVal_0); }); }
var CardEventComponentNgFactory = i0.ɵccf("amg-card-event", i6.CardEventComponent, View_CardEventComponent_Host_0, { fixtureData: "fixtureData" }, { buttonClicked: "buttonClicked" }, []);
export { CardEventComponentNgFactory as CardEventComponentNgFactory };
