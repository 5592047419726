import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { AnywhereApiConfiguration } from 'wordpress-adapter';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.anywhereCache = {};
    }
    ApiService.generateFullAnywhereUrl = function (anywhereApiConfiguration) {
        var previousUrlParams = ApiService.extractUrlParamsFromExistingBaseUrl(anywhereApiConfiguration), url = ApiService.generateAnywhereUrl(anywhereApiConfiguration), urlParams = ApiService.generateAnywhereUrlParams(anywhereApiConfiguration, previousUrlParams);
        return url + "?" + urlParams;
    };
    ApiService.removeQueryParamsFromUrl = function (url) {
        var urlParamIndex = url.indexOf('?');
        return urlParamIndex === -1
            ? url
            : url.substring(0, urlParamIndex);
    };
    ApiService.getQueryParamsFromUrl = function (url) {
        var urlParamIndex = url.indexOf('?');
        return urlParamIndex === -1
            ? ''
            : url.substring(urlParamIndex + 1);
    };
    ApiService.extractUrlParamsFromExistingBaseUrl = function (anywhereApiConfiguration) {
        var urlParamIndex = anywhereApiConfiguration.baseUrl.indexOf('?');
        if (urlParamIndex !== -1) {
            return anywhereApiConfiguration.baseUrl.substring(urlParamIndex + 1)
                .split('&')
                .reduce(function (previousValue, currentValue) {
                var _a;
                var keyValue = currentValue.split('=');
                return tslib_1.__assign({}, previousValue, (_a = {}, _a[keyValue[0]] = keyValue[1], _a));
            }, {});
        }
        return {};
    };
    ApiService.generateAnywhereUrl = function (anywhereApiConfiguration) {
        var url = [ApiService.removeQueryParamsFromUrl(anywhereApiConfiguration.baseUrl)];
        if (anywhereApiConfiguration.targetId) {
            url.push('/', anywhereApiConfiguration.targetId);
        }
        if (anywhereApiConfiguration.apiFeedId) {
            url.push('/en/feed/', anywhereApiConfiguration.apiFeedId, '/sections/');
        }
        return url.join('');
    };
    ApiService.generateHeaderData = function (data) {
        var httpHeaderObject = data.reduce(function (previousValue, currentValue) {
            var _a;
            return tslib_1.__assign({}, previousValue, (_a = {}, _a[currentValue.key] = currentValue.value, _a));
        }, {});
        return new HttpHeaders(httpHeaderObject);
    };
    ApiService.generateAnywhereUrlParams = function (anywhereApiConfiguration, previousUrlParams) {
        var data = [];
        if (anywhereApiConfiguration.sectionId || previousUrlParams.section) {
            data.push("section=" + (anywhereApiConfiguration.sectionId || previousUrlParams.section));
        }
        var previousUrlPageIndex = previousUrlParams.pageIndex || previousUrlParams.pageindex;
        if (anywhereApiConfiguration.pageIndex || previousUrlPageIndex) {
            data.push("pageIndex=" + (anywhereApiConfiguration.pageIndex || previousUrlPageIndex));
        }
        var previousUrlPageSize = previousUrlParams.pageSize || previousUrlParams.pagesize;
        if (anywhereApiConfiguration.pageSize || previousUrlPageSize) {
            data.push("pageSize=" + (anywhereApiConfiguration.pageSize || previousUrlPageSize));
        }
        var previousUrlSortBy = previousUrlParams.sortBy || previousUrlParams.sortby;
        if (anywhereApiConfiguration.sortBy || previousUrlSortBy) {
            data.push("sortBy=" + (anywhereApiConfiguration.sortBy || previousUrlSortBy));
        }
        var previousUrlSortOrder = previousUrlParams.sortOrder || previousUrlParams.sortorder;
        if (anywhereApiConfiguration.sortOrder || previousUrlSortOrder) {
            data.push("sortOrder=" + (anywhereApiConfiguration.sortOrder || previousUrlSortOrder));
        }
        if (anywhereApiConfiguration.searchQuery || previousUrlParams.query) {
            data.push("query=" + (anywhereApiConfiguration.searchQuery || previousUrlParams.query));
        }
        return data.join('&');
    };
    ApiService.prototype.getApiData = function (restApiConfiguration) {
        if (!restApiConfiguration) {
            console.error('Rest API Configuration is not set.');
            return of(null);
        }
        var headerData = ApiService.generateHeaderData(restApiConfiguration.headerData);
        return this.http.request(restApiConfiguration.apiRequestMethod || 'GET', restApiConfiguration.baseUrl, {
            headers: headerData
        });
    };
    ApiService.prototype.getAnyWhereData = function (anywhereApiConfiguration) {
        if (!anywhereApiConfiguration) {
            console.error('Anywhere API Configuration is not set.');
            return of(null);
        }
        var url = ApiService.generateFullAnywhereUrl(anywhereApiConfiguration);
        var urlKey = ApiService.removeQueryParamsFromUrl(anywhereApiConfiguration.baseUrl);
        this.setupCachePlaceholder(urlKey, anywhereApiConfiguration);
        this.triggerAnywhereApiRequest(urlKey, url, anywhereApiConfiguration);
        return this.anywhereCache[urlKey].subject.asObservable();
    };
    ApiService.prototype.triggerAnywhereApiRequest = function (urlKey, url, anywhereApiConfiguration) {
        var _this = this;
        this.http
            .request(anywhereApiConfiguration.apiRequestMethod || 'GET', url)
            .subscribe(function (data) {
            _this.anywhereCache[urlKey].subject.next(data);
        });
    };
    ApiService.prototype.clearAnywhereApiCache = function (anywhereApiConfiguration) {
        var url = ApiService.generateAnywhereUrl(anywhereApiConfiguration);
        if (this.anywhereCache[url]) {
            this.anywhereCache[url].subject.complete();
            var _a = this.anywhereCache, _b = url, exclude = _a[_b], others = tslib_1.__rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
            this.anywhereCache = others;
        }
    };
    ApiService.prototype.setupCachePlaceholder = function (urlKey, anywhereApiConfiguration) {
        var _a;
        if (!this.anywhereCache[urlKey]) {
            // Generate placeholder
            this.anywhereCache = tslib_1.__assign({}, this.anywhereCache, (_a = {}, _a[urlKey] = {
                subject: new BehaviorSubject(null),
                apiConfiguration: anywhereApiConfiguration
            }, _a));
        }
    };
    ApiService.prototype.triggerSearchOnAnywhereApiForAllExistingRequests = function (search) {
        var _this = this;
        var searchAnywhereConfig = new AnywhereApiConfiguration({ searchQuery: search }, true);
        Object.keys(this.anywhereCache).forEach(function (urlKey) {
            var currentObject = _this.anywhereCache[urlKey], newConfiguration = AnywhereApiConfiguration.merge(currentObject.apiConfiguration, searchAnywhereConfig);
            var url = ApiService.generateFullAnywhereUrl(newConfiguration);
            _this.triggerAnywhereApiRequest(urlKey, url, newConfiguration);
        });
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
