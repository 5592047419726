<ng-template [ngIf]="isVideoLiveLocalRef || isAudioLiveLocalRef">
  <div class="amg-right">
    <div class="amg-live-icons">
      <ng-template [ngIf]="videoEntryData">
        <span (click)="changeMedia(videoEntryData)"
              class="amg-live-icon video"></span>
      </ng-template>
      <ng-template [ngIf]="audioEntryData">
            <span (click)="changeMedia(audioEntryData)"
                  class="amg-live-icon audio"></span>
      </ng-template>
    </div>
  </div>
</ng-template>
