import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _footerData: Subject<any>;

  constructor() {
    this._footerData = new BehaviorSubject(null);
  }

  public setFooterData(data: any): void {
    this._footerData.next(data);
  }

  public getFooterDataAsObservable(): Observable<any> {
    return this._footerData.asObservable();
  }
}
