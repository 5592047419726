/*
 * Public API Surface of carousel
 */

// Old Carousel
export { CarouselComponent } from './lib/carousel/carousel.component';
export { CarouselModule } from './lib/carousel/carousel.module';

export { CarouselResponsiveProperties } from './lib/carousel/carousel.responsive.properties';
export { CarouselResponsivePropertiesView } from './lib/carousel/carousel.responsive.properties.view';
export { CarouselStaticProperties } from './lib/carousel/carousel.static.properties';

// New Carousel
export { CarouselFlickityModule } from './lib/carousel-flickity/carousel-flickity.module';

export { CarouselConfiguration } from './lib/carousel-flickity/type/carousel-configuration.type';
