import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
var MakeElementFullHeightDirective = /** @class */ (function () {
    function MakeElementFullHeightDirective(elRef, changeDetectorRef) {
        this.elRef = elRef;
        this.changeDetectorRef = changeDetectorRef;
        this.MOBILEBREAKPOINT = 768;
    }
    MakeElementFullHeightDirective.prototype.onResize = function (event) {
        this.calcWindowAndPlayerDimensions();
    };
    MakeElementFullHeightDirective.prototype.ngOnInit = function () {
        this.changeHeight = new EventEmitter();
        this.calcWindowAndPlayerDimensions();
    };
    MakeElementFullHeightDirective.prototype.calcWindowAndPlayerDimensions = function () {
        var videoDetailsEl = this.elRef.nativeElement.querySelectorAll('.amg-video-details');
        var playerDimensions = {
            windowSize: {
                x: window.innerWidth,
                y: window.innerHeight
            },
            playerElSize: {
                offsetToTop: this.elRef.nativeElement.offsetTop,
                height: this.elRef.nativeElement.offsetHeight,
                calculatedHeight: 0
            },
            videoDetailsElSize: {
                height: 0
            },
            collapse: true
        };
        var leftOverSpace = playerDimensions.windowSize.y - playerDimensions.playerElSize.offsetToTop - playerDimensions.playerElSize.height;
        var calcAspectRatio = playerDimensions.windowSize.x / 16 * 9;
        playerDimensions.isMobileView = (playerDimensions.windowSize.x <= this.MOBILEBREAKPOINT) ? true : false;
        playerDimensions.videoDetailsElSize.height = ((videoDetailsEl).length > 0) ? videoDetailsEl[0].offsetHeight : 160;
        playerDimensions.playerElSize.calculatedHeight = (playerDimensions.isMobileView) ? leftOverSpace : calcAspectRatio;
        var calcH = playerDimensions.playerElSize.calculatedHeight; // shorthand name for convenience
        playerDimensions.playerElSize.calculatedHeight = (calcH && calcH > 0) ? calcH + playerDimensions.videoDetailsElSize.height : 'inherit';
        this.changeHeight.emit(playerDimensions);
        this.changeDetectorRef.detectChanges();
        return playerDimensions;
    };
    return MakeElementFullHeightDirective;
}());
export { MakeElementFullHeightDirective };
