import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {UserAuthService} from 'user-auth';
import {Section} from 'wordpress-adapter';
import {DataService} from '../../shared/data/data.service';
import {UserState} from '@amg-fe-angular/types/payments/user-auth.type';
import {UserAuthSsoService} from '@amg-fe-angular/services/user-auth/user-auth-sso.service';

@Component({
  selector: 'amg-wp-section',
  templateUrl: './section.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SectionComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-section';
  @HostBinding('attr.is-logged-in') isLoggedIn = false;
  @HostBinding('attr.is-available') isSubscriptionAvailable = false;
  @HostBinding('attr.is-disabled') isDisabledForMobile = false;
  @HostBinding('attr.is-loading') isLoading = true;
  @HostBinding('style.background-image') backgroundImage: string;

  @Input() section: Section;
  @Input() videoPlayerConfiguration: { drmEntries: string[]; isDesktopDevice: boolean; };
  @Output() triggerChange: EventEmitter<string>;
  @Output() triggerDisabledByDrm: EventEmitter<boolean>;

  @Input() set isDrmDisabled(value: boolean) {
    this.ngZone.run(() => {
      this.isDisabledForMobile = value;

      // If disabled due to DRM, set loading to false as the
      //  subscription call is not going to be performed.
      if (value) {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  private userAuthSubscription: Subscription;
  private userSubscriptionStateSubscription: Subscription;

  constructor(private domSanitizer: DomSanitizer,
              private dataService: DataService,
              @Optional() private userAuthService: UserAuthService,
              @Optional() private userAuthNewService: UserAuthSsoService,
              private ngZone: NgZone,
              private changeDetectorRef: ChangeDetectorRef) {
    this.triggerChange = new EventEmitter<string>();
    this.triggerDisabledByDrm = new EventEmitter<boolean>();
  }

  ngOnDestroy(): void {
    if (this.userAuthSubscription) {
      this.userAuthSubscription.unsubscribe();
    }
    if (this.userSubscriptionStateSubscription) {
      this.userSubscriptionStateSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.setUpHostStyling();

    /**
     * Screen one: Login Overlay
     * Screen two: DRM Overlay - if available
     * Screen three: Subscription Overlay (Skip if drm is disabled)
     */

    this.userAuthSubscription = this.userAuthNewService.userStateObservable.subscribe(userState => {
      if (userState) {
        this.isLoggedIn = userState.state !== UserState.notLoggedIn;

      } else {
        this.isLoggedIn = false;
        this.isLoading = false;
      }
      this.changeDetectorRef.markForCheck();
    });

    if (this.userAuthService) {




      // Is Login available Attribute
      // this.userAuthSubscription = this.userAuthService.getUserLoginDetailsObservable().subscribe(value => {
      //   if (value) {
      //     this.isLoggedIn = value.isUserLoggedIn;
      //   } else {
      //     this.isLoggedIn = false;
      //     this.isLoading = false;
      //   }
      // });

      // Is KS available Attribute
      let isInit = true;
      this.userSubscriptionStateSubscription = this.userAuthService.getUserSubscriptionState().subscribe(value => {
        this.isSubscriptionAvailable = value;

        if (!this.isDisabledForMobile) {
          if (isInit) { // First subscription, and the second is the check
            this.isLoading = true;
            isInit = false;
          } else {
            this.isLoading = false;
          }
        }
      });
    }
  }

  onElementChange(value: string) {
    this.triggerChange.emit(value);
  }

  onDrmStateChange(value: boolean) {
    this.ngZone.run(() => {
      this.isDisabledForMobile = value;
      this.triggerDisabledByDrm.emit(value);

      // If disabled due to DRM, set loading to false as the
      //  subscription call is not going to be performed.
      if (value) {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getChildren(): any[] {
    return this.section.children;
  }

  getTitle(): string {
    return this.section.title;
  }

  getDescription(): string {
    return this.section.description;
  }

  areChildrenAvailable(): boolean {
    return this.section.children && this.section.children.length > 0;
  }

  getCss(): string {
    return this.section.cssClass;
  }

  private setUpHostStyling(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    if (this.section.backgroundImage) {
      this.backgroundImage = `url(${this.section.backgroundImage.url})`;
      this.class += ' amg-section-bg-image';
    }
  }
}
