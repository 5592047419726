/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../components/grid/src/lib/grid.component.ngfactory";
import * as i2 from "../../../../../../../../../components/grid/src/lib/grid.component";
import * as i3 from "../../../../../../../../../components/amg/src/lib/bootstrap.viewport.service";
import * as i4 from "@angular/common/http";
import * as i5 from "@angular/router";
import * as i6 from "./grid.component";
import * as i7 from "../../../../../shared/data/data.service";
var styles_GridComponent = [];
var RenderType_GridComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GridComponent, data: {} });
export { RenderType_GridComponent as RenderType_GridComponent };
export function View_GridComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-grid", [], null, [[null, "cardClicked"], [null, "triggerSearch"], [null, "updateSearchUrl"], [null, "triggerGridReRender"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:scroll" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).preloadPages($event) !== false);
        ad = (pd_0 && ad);
    } if (("cardClicked" === en)) {
        var pd_1 = (_co.onCardClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("triggerSearch" === en)) {
        var pd_2 = (_co.onGridSearch($event) !== false);
        ad = (pd_2 && ad);
    } if (("updateSearchUrl" === en)) {
        var pd_3 = (_co.onUpdateSearchUrl($event) !== false);
        ad = (pd_3 && ad);
    } if (("triggerGridReRender" === en)) {
        var pd_4 = (_co.onGridReRender() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_GridComponent_0, i1.RenderType_GridComponent)), i0.ɵdid(1, 4440064, null, 0, i2.GridComponent, [[2, "videoPlayerModuleConfig"], i0.ComponentFactoryResolver, i3.BootstrapViewportService, i4.HttpClient, i5.Router], { gridStaticProperties: [0, "gridStaticProperties"], componentData: [1, "componentData"], responsiveProperties: [2, "responsiveProperties"], searchObservable: [3, "searchObservable"], defaultSearchValue: [4, "defaultSearchValue"] }, { cardClicked: "cardClicked", triggerSearch: "triggerSearch", updateSearchUrl: "updateSearchUrl", triggerGridReRender: "triggerGridReRender" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridStaticProperties; var currVal_1 = _co.gridData; var currVal_2 = _co.gridResponsiveProperties; var currVal_3 = _co.getSearchInputAsObservable(); var currVal_4 = _co.getCurrentSearchValue(); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_GridComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-grid", [], [[1, "class", 0]], null, null, View_GridComponent_0, RenderType_GridComponent)), i0.ɵdid(1, 245760, null, 0, i6.GridComponent, [i7.DataService, i5.Router, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var GridComponentNgFactory = i0.ɵccf("amg-wp-grid", i6.GridComponent, View_GridComponent_Host_0, { grid: "grid" }, { triggerChange: "triggerChange" }, []);
export { GridComponentNgFactory as GridComponentNgFactory };
