var GridColumns = /** @class */ (function () {
    /**
     * Store slide widths for the view
     * @param detailsActive check if we cater for the information sidebar
     * @param currentView the current viewport data
     */
    function GridColumns(currentView) {
        this._columnsStyle = {};
        this.setCurrentView('constructor', currentView);
    }
    /**
     * Change current viewport data
     * @param source
     * @param currentView the current viewport data
     */
    GridColumns.prototype.setCurrentView = function (source, currentView) {
        if (source === void 0) { source = 'unknown'; }
        this._currentView = currentView;
        // TODO quick fix, to be defined in the final project css, not in the component...
        //this.recalculateColumns(source);
    };
    /**
     * Recalculate column CSS
     * @param source
     * @param normalItems number of items to show
     */
    GridColumns.prototype._recalculateColumns = function (source, normalItems) {
        if (source === void 0) { source = 'unknown'; }
        var flex = 100 / normalItems;
        var toReturn = {
            flex: "0 0 " + flex + "%",
            width: flex + "%",
            maxWidth: flex + "%",
            paddingRight: this._currentView.margin + "px",
        };
        return toReturn;
    };
    /**
     * Recalculate column width
     * @param source
     */
    GridColumns.prototype.recalculateColumns = function (source) {
        if (source === void 0) { source = 'unknown'; }
        this._columnsStyle = this._recalculateColumns(source, this._currentView.items);
    };
    /**
     * Get cached column styles
     */
    GridColumns.prototype.getColumn = function () {
        return this._columnsStyle;
    };
    return GridColumns;
}());
export { GridColumns };
