/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../components/video-player/src/lib/video-player/video-player.component.ngfactory";
import * as i2 from "../../../../../../../../../components/video-player/src/lib/video-player/video-player.component";
import * as i3 from "../../../../../../../../../components/video-player/src/lib/video-player.service";
import * as i4 from "../../../../../../../../../components/user-auth/src/lib/user-auth.service";
import * as i5 from "@angular/common";
import * as i6 from "./video-player.component";
import * as i7 from "../../../../../shared/api/api.service";
import * as i8 from "../../../../../shared/data/data.service";
import * as i9 from "@angular/router";
var styles_VideoPlayerComponent = [];
var RenderType_VideoPlayerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VideoPlayerComponent, data: {} });
export { RenderType_VideoPlayerComponent as RenderType_VideoPlayerComponent };
function View_VideoPlayerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-video-player", [], null, [[null, "triggerPlaylistReady"], [null, "triggerEntryChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("triggerPlaylistReady" === en)) {
        var pd_0 = (_co.onPlaylistReady() !== false);
        ad = (pd_0 && ad);
    } if (("triggerEntryChange" === en)) {
        var pd_1 = (_co.onEntryChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_VideoPlayerComponent_0, i1.RenderType_VideoPlayerComponent)), i0.ɵdid(1, 245760, null, 0, i2.VideoPlayerComponent, [i3.VideoPlayerService, i0.Renderer2, i4.UserAuthService, i0.ChangeDetectorRef], { videoSignUpConfig: [0, "videoSignUpConfig"], playlistUrl: [1, "playlistUrl"], apiConfigs: [2, "apiConfigs"], entryId: [3, "entryId"], liveLoginOverlay: [4, "liveLoginOverlay"], anywhereData: [5, "anywhereData"] }, { triggerPlaylistReady: "triggerPlaylistReady", triggerEntryChange: "triggerEntryChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPlayButtonConfig(); var currVal_1 = _co.getPlaylistUrl(); var currVal_2 = _co.getConfiguration(); var currVal_3 = _co.getEntryId(); var currVal_4 = _co.getLiveLoginOverlay(); var currVal_5 = _co.getVideoPlayerData(); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_VideoPlayerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoPlayerComponent_2)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isInitialised; _ck(_v, 2, 0, currVal_0); }, null); }
function View_VideoPlayerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "amg-message-container drm-message"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "message"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDrmMessage(); _ck(_v, 2, 0, currVal_0); }); }
export function View_VideoPlayerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoPlayerComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["disabledView", 2]], null, 0, null, View_VideoPlayerComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canInitialiseVideoPlayer; var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_VideoPlayerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-video-player", [], [[1, "class", 0]], null, null, View_VideoPlayerComponent_0, RenderType_VideoPlayerComponent)), i0.ɵdid(1, 245760, null, 0, i6.VideoPlayerComponent, [i7.ApiService, i8.DataService, i9.ActivatedRoute, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var VideoPlayerComponentNgFactory = i0.ɵccf("amg-wp-video-player", i6.VideoPlayerComponent, View_VideoPlayerComponent_Host_0, { videoPlayerConfiguration: "videoPlayerConfiguration", videoPlayer: "videoPlayer" }, { triggerChange: "triggerChange", triggerDisabledByDrm: "triggerDisabledByDrm" }, []);
export { VideoPlayerComponentNgFactory as VideoPlayerComponentNgFactory };
