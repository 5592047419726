/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./logout-btn.component";
import * as i3 from "../../user-auth.service";
var styles_LogoutBtnComponent = [];
var RenderType_LogoutBtnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoutBtnComponent, data: {} });
export { RenderType_LogoutBtnComponent as RenderType_LogoutBtnComponent };
function View_LogoutBtnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn amg-logout-btn"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn amg-logout-btn"; var currVal_2 = _co.logoutBtn.cssClass; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logoutBtn.label; _ck(_v, 0, 0, currVal_0); }); }
export function View_LogoutBtnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LogoutBtnComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.logoutBtn && _co.show); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LogoutBtnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-logout-btn", [], null, null, null, View_LogoutBtnComponent_0, RenderType_LogoutBtnComponent)), i0.ɵdid(1, 114688, null, 0, i2.LogoutBtnComponent, [i3.UserAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutBtnComponentNgFactory = i0.ɵccf("amg-logout-btn", i2.LogoutBtnComponent, View_LogoutBtnComponent_Host_0, { logoutBtn: "logoutBtn" }, {}, []);
export { LogoutBtnComponentNgFactory as LogoutBtnComponentNgFactory };
