import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { CollapsibleConfiguration } from '../../../types/ng-objects/CollapsibleConfiguration';
import { CardType } from 'card';
import { CollapsibleStaticProperties, CollapsibleResponsiveProperties } from 'collapsible';
import { NgObject } from './NgObject';

export class Collapsible extends NgObject {
  private readonly _collapsibleStaticProperties: CollapsibleStaticProperties;
  private readonly _collapsibleData: any[];
  private readonly _collapsibleConfiguration: CollapsibleResponsiveProperties;

  constructor(data: any) {
    super('Collapsible', data);

    this._collapsibleData = data.answers || [];
    this._collapsibleStaticProperties = Collapsible.collapsibleStaticPropertiesMapping(data.question);

    const uiConfiguration = CollectionUtils
      .flattenArrayOfResponsiveConfiguration<CollapsibleConfiguration>(data.ui_conf, Collapsible.collapsibleConfigurationMapping);
    this._collapsibleConfiguration = new CollapsibleResponsiveProperties(uiConfiguration);
  }

  get collapsibleConfiguration(): CollapsibleResponsiveProperties {
    return this._collapsibleConfiguration;
  }

  get collapsibleData(): any[] {
    return this._collapsibleData;
  }

  get collapsibleStaticProperties(): CollapsibleStaticProperties {
    return this._collapsibleStaticProperties;
  }

  private static collapsibleConfigurationMapping(data: any): CollapsibleConfiguration {
    const collapsibleConfiguration: CollapsibleConfiguration = {
      canCollapse: data.canCollapse,
      expandButtonText: data.showText,
      hideButtonText: data.hideText,
      refreshRate: CollectionUtils.parseNumber(data.responsiveRefreshRate)
    };

    return collapsibleConfiguration;
  }

  private static collapsibleStaticPropertiesMapping(question: string): CollapsibleStaticProperties {
    return Collapsible.parsePropertiesOfCardTypeText(question);
  }

  private static parsePropertiesOfCardTypeText(question: string): CollapsibleStaticProperties {
    return {
      collapsibleTile: CardType.Text,
      title: question,
      cardStaticProperties: {
        keys: [ 'text' ]
      }
    };
  }
}
