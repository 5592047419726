/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./card-image.component";
import * as i3 from "../../../amg/src/lib/bootstrap.viewport.service";
var styles_CardImageComponent = [];
var RenderType_CardImageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CardImageComponent, data: {} });
export { RenderType_CardImageComponent as RenderType_CardImageComponent };
export function View_CardImageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "amg-card-image card card-image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "card-img-top"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { "background-image": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.getThumbnailAsBackground()); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getThumbnail(); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.getTitle(); _ck(_v, 7, 0, currVal_2); }); }
export function View_CardImageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-card-image", [], null, null, null, View_CardImageComponent_0, RenderType_CardImageComponent)), i0.ɵdid(1, 245760, null, 0, i2.CardImageComponent, [i3.BootstrapViewportService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardImageComponentNgFactory = i0.ɵccf("amg-card-image", i2.CardImageComponent, View_CardImageComponent_Host_0, { componentData: "componentData", responsiveProperties: "responsiveProperties", staticProperties: "staticProperties", componentChildren: "componentChildren" }, {}, []);
export { CardImageComponentNgFactory as CardImageComponentNgFactory };
