import { CardType } from 'card';
var GridDataWordpress = /** @class */ (function () {
    function GridDataWordpress(http, componentData, responsiveProperties, staticProperties, componentChildren, selectedFilters, rawData, loadingState) {
        this.http = http;
        this.componentData = componentData;
        this.responsiveProperties = responsiveProperties;
        this.staticProperties = staticProperties;
        this.componentChildren = componentChildren;
        this.selectedFilters = selectedFilters;
        this.rawData = rawData;
        this.loadingState = loadingState;
        this.isLimitReached = false;
        this.isLoading = true;
    }
    GridDataWordpress.prototype.fetchData = function (filters, clearData) {
        if (clearData || !this.isLimitReached) {
            this.triggerCall(clearData);
        }
    };
    GridDataWordpress.prototype.setCurrentView = function (source, currentView) {
        if (source === void 0) { source = 'unknown'; }
        this.currentView = currentView;
    };
    GridDataWordpress.prototype.getIsLimitReached = function () {
        return this.isLimitReached;
    };
    GridDataWordpress.prototype.onDestroy = function () {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    };
    GridDataWordpress.prototype.getPagingData = function () {
        return null;
    };
    GridDataWordpress.prototype.getIsLoading = function () {
        return this.isLoading;
    };
    GridDataWordpress.prototype.triggerCall = function (clearData) {
        var _this = this;
        this.isLoading = true;
        var url = this.staticProperties.pageUrl;
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
        this.dataSubscription = this.http.get(url)
            .subscribe(function (feed) {
            _this.isLoading = false;
            if (clearData) {
                _this.resetData();
            }
            _this.setData(feed);
            _this.isLimitReached = true;
            _this.loadingState.next(false);
        });
    };
    GridDataWordpress.prototype.resetData = function () {
        this.componentData.length = 0;
        this.rawData.length = 0;
        this.componentChildren.reset([]);
        this.isLimitReached = false;
    };
    GridDataWordpress.prototype.setData = function (feed) {
        if (feed.length > 0) {
            if (this.isDataOfTypePlayerProfile()) {
                this.setPlayerProfileData(feed);
            }
            // Boxsets seems redundant as data is coming from Anywhere not Wordpress.
            if (this.isDataOfTypeBoxset()) {
                this.setBoxsetData(feed);
            }
            if (this.isDataOfTypePlaylist()) {
                this.setPlaylistData(feed);
            }
        }
    };
    GridDataWordpress.prototype.isDataOfTypePlayerProfile = function () {
        return this.staticProperties.pageUrl.indexOf('player') !== -1;
    };
    GridDataWordpress.prototype.isDataOfTypeBoxset = function () {
        return this.staticProperties.pageUrl.indexOf('boxset') !== -1;
    };
    GridDataWordpress.prototype.isDataOfTypePlaylist = function () {
        return this.staticProperties.pageUrl.indexOf('playlist') !== -1;
    };
    GridDataWordpress.prototype.setPlayerProfileData = function (feed) {
        var _this = this;
        this.staticProperties.cardType = CardType.Profile;
        var segregatedPlayersByPosition = {};
        feed.forEach(function (player) {
            _this.rawData.push(player);
            var cardPlayer = {
                imageUrl: player.imageUrl,
                iconUrl: player.icon.icon,
                nameFirst: player.first_name,
                nameLast: player.last_name,
                number: player.shirt_number,
                position: player.position.toLocaleLowerCase(),
                raw: player
            };
            if (segregatedPlayersByPosition[cardPlayer.position]) {
                segregatedPlayersByPosition[cardPlayer.position].push(cardPlayer);
            }
            else {
                segregatedPlayersByPosition[cardPlayer.position] = [cardPlayer];
            }
        });
        Object.keys(segregatedPlayersByPosition).forEach(function (playerPosition) {
            var playerList = segregatedPlayersByPosition[playerPosition];
            _this.componentData.push({
                key: playerPosition,
                value: playerList
            });
        });
    };
    GridDataWordpress.prototype.setBoxsetData = function (feed) {
        var _this = this;
        this.staticProperties.cardType = CardType.Image;
        feed.forEach(function (boxset) {
            var cardImage = {
                imageUrl: boxset.imageUrl,
                title: boxset.title,
                raw: boxset
            };
            _this.componentData.push(cardImage);
            _this.rawData.push(boxset);
        });
    };
    GridDataWordpress.prototype.setPlaylistData = function (feed) {
        var _this = this;
        this.staticProperties.cardType = CardType.Image;
        feed.forEach(function (playlist) {
            var cardImage = {
                imageUrl: playlist.imageUrl,
                title: playlist.title,
                raw: playlist
            };
            _this.componentData.push(cardImage);
            _this.rawData.push(playlist);
        });
    };
    return GridDataWordpress;
}());
export { GridDataWordpress };
