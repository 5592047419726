import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Icon, Icons } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-icons',
  templateUrl: './icons.component.html',
  encapsulation: ViewEncapsulation.None
})
export class IconsComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-icon-group';
  @Input() icons: Icons;

  ngOnInit(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }
  }

  getIcons(): Icon[] {
    return this.icons.icons;
  }

  getCss(): string {
    return this.icons.cssClass;
  }
}
