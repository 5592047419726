import {
  Component,
  Input,
  ViewChildren,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  QueryList,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import {Subscription, Observable} from 'rxjs';

import {
  AmgComponent,
  AmgFixture,
  BootstrapViewportEnum,
  BootstrapViewportService,
} from 'amg';

import {CardFixture, CardFixtureStaticProperties} from 'card-fixture';

import {
  CarouselStaticProperties,
  CarouselResponsiveProperties
} from 'carousel';

import {
  CalendarBuild,
  CalendarClick,
  CalendarHover,
} from './calendar.functions/calendar.functions';

import {CalendarTabData} from './calendar.tab.data';
import {CalendarResponsiveProperties} from './calendar.responsive.properties';
import {CalendarResponsivePropertiesView} from './calendar.responsive.properties.view';
import {CalendarStaticProperties} from './calendar.static.properties';

@Component({
  selector: 'amg-calendar',
  templateUrl: 'calendar.component.html',
  styles: []
})
export class CalendarComponent implements OnInit, OnDestroy, OnChanges, AmgComponent {
  @Input() componentData: Array<AmgFixture>;
  @Input() responsiveProperties: CalendarResponsiveProperties = new CalendarResponsiveProperties();
  @Input() staticProperties: CalendarStaticProperties = new CalendarStaticProperties();

  @ViewChildren('componentChild', {read: ViewContainerRef}) componentChildren: QueryList<ViewContainerRef>;

  componentViewportSubscription: Subscription;

  componentCurrentView: CalendarResponsivePropertiesView;

  responsiveTimeout: any;

  @Output() fixtureClicked = new EventEmitter<any>();
  @Output() fixtureHovered = new EventEmitter<any>();

  currentSlideNumber: EventEmitter<number>;
  selectedDate: CalendarTabData = {} as CalendarTabData;
  tabs: AmgComponent;

  tabComponentData: Array<CalendarTabData> = [];
  tabComponentResponsive: CarouselResponsiveProperties;
  tabComponentStatic: CarouselStaticProperties;

  fixtureComponentData: Array<CardFixture> = [];

  private _build: CalendarBuild;
  private _click: CalendarClick;
  private _hover: CalendarHover;

  private _calendarData: any;

  constructor(
    private bootstrapViewportService: BootstrapViewportService
  ) {
  }

  ngOnInit() {
    let viewportSubscriber: Observable<BootstrapViewportEnum> = this.bootstrapViewportService
      .getViewportSubscriber();

    let viewport: BootstrapViewportEnum = this.bootstrapViewportService.getViewport();
    this.componentCurrentView = this.responsiveProperties[viewport] as CalendarResponsivePropertiesView;

    this._build = new CalendarBuild();
    this._click = new CalendarClick(this.fixtureClicked);
    this._hover = new CalendarHover(this.fixtureHovered);

    this.regenerateResponsiveViews();
    this.regenerateStaticViews();

    this._calendarData = this._build
      .buildCalendar(this.componentData, this.responsiveProperties);

    this.fixtureComponentData = this._calendarData.fixtureData;
    this.tabComponentData = this._calendarData.tabsResponsive[viewport];
    this.selectedDate = this.tabComponentData[0];

    this.componentViewportSubscription = viewportSubscriber
      .subscribe(newBreakpoint => {
        if (this.responsiveTimeout) {
          clearTimeout(this.responsiveTimeout);
          this.responsiveTimeout = null;
        }

        this.responsiveTimeout = setTimeout(() => {
          this.componentCurrentView = this.responsiveProperties[newBreakpoint] as CalendarResponsivePropertiesView;
          this.tabComponentData = this._calendarData.tabsResponsive[newBreakpoint];
          this.selectedDate = this.tabComponentData[0];
        }, this.componentCurrentView.refreshRate);
      });
  }

  ngOnDestroy() {
    this.componentViewportSubscription.unsubscribe();
    if (this.responsiveTimeout) {
      clearTimeout(this.responsiveTimeout);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this._build !== undefined) {

      if (changes.responsiveProperties) {
        this.regenerateResponsiveViews();
      }
      if (changes.staticProperties) {
        this.regenerateStaticViews();
      }
      if (changes.componentData) {
        const viewport: BootstrapViewportEnum = this.bootstrapViewportService.getViewport();

        this._calendarData = this._build
          .buildCalendar(this.componentData, this.responsiveProperties);

        this.fixtureComponentData = this._calendarData.fixtureData;
        this.tabComponentData = this._calendarData.tabsResponsive[viewport];
        this.selectedDate = this.tabComponentData[0];
      }

    }
  }

  regenerateResponsiveViews() {
    if (this._build !== undefined) {
      if (this.responsiveProperties) {
        const responsive = this._build.generateResponsiveViews(this.responsiveProperties);
        this.tabComponentResponsive = responsive.tabResponsive;
      }
    }
  }

  regenerateStaticViews() {
    if (this._build !== undefined) {
      if (this.staticProperties) {
        const s = this._build.generateStaticViews(this.staticProperties);
        this.tabComponentStatic = s.tabStatic;
      }
    }
  }

  onDateClicked(source: string = 'unknown', $event: any) {
    this.selectedDate = $event;
  }

  onDateHovered(source: string = 'unknown', $event: any) {
    // do nothing
  }

  onDateSelected(source: string = 'unknown', $event: any) {
    this.selectedDate = $event;
  }

  onFixtureClicked(data: any) {
    this._click.slideClick(null, data);
  }

  onFixtureHover(source: string = 'unknown', i: number) {
    this._hover.slideHover(source, i, this.componentData[i]);
  }

  onFixtureHoverOut(source: string, i: number) {
    this._hover.slideHoverOut(source, i);
  }
}
