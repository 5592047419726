<div class="amg-icon-placeholder"
      [attr.has-free-trail]="package.HasFreeTrail"></div>

<div class="amg-subscription-package-inner">
  <span *ngIf="package.Title"
        class="amg-title"
        [innerHTML]="package.Title"></span>

  <span *ngIf="package.Body"
        class="amg-body"
        [innerHTML]="package.Body"></span>

  <span *ngIf="formattedPrice"
        class="amg-amount"
        [innerHTML]="formattedPrice"></span>

  <span *ngIf="intervalData"
        class="amg-interval-group">
    <span *ngIf="intervalData.number"
          class="amg-interval-number"
          [innerHTML]="intervalData.number"></span>
    <span *ngIf="intervalData.text"
          class="amg-interval-text"
          [innerHTML]="intervalData.text"></span>
  </span>

  <button *ngIf="package.PurchaseUrl"
          class="btn amg-button"
          (click)="onButtonClick()">JOIN NOW</button>
</div>
