import * as tslib_1 from "tslib";
var CollectionUtils = /** @class */ (function () {
    function CollectionUtils() {
    }
    CollectionUtils.retrieveConfigurationData = function (data) {
        var configurationDataSet = {};
        data.forEach(function (config) {
            switch (config.objectType) {
                case 'BaseConfiguration':
                    configurationDataSet = tslib_1.__assign({}, configurationDataSet, { baseConfiguration: config });
                    break;
                case 'AnywhereApiConfiguration':
                    configurationDataSet = tslib_1.__assign({}, configurationDataSet, { anywhereApiConfiguration: config });
                    break;
                case 'KalturaConfiguration':
                    configurationDataSet = tslib_1.__assign({}, configurationDataSet, { kalturaConfiguration: config });
                    break;
                case 'RestApiConfiguration':
                    configurationDataSet = tslib_1.__assign({}, configurationDataSet, { restApiConfiguration: config });
                    break;
            }
        });
        return configurationDataSet;
    };
    CollectionUtils.flattenArrayOfSection = function (elements) {
        var flatArray = [];
        elements.forEach(function (element) {
            if (Array.isArray(element)) {
                flatArray.push.apply(flatArray, element);
            }
            else {
                flatArray.push(element);
            }
        });
        return flatArray;
    };
    CollectionUtils.flattenArrayOfResponsiveConfiguration = function (screenResponsiveConfig, mappingFunction) {
        var screenConfiguration = {};
        if (screenResponsiveConfig && screenResponsiveConfig.length > 0) {
            screenResponsiveConfig.forEach(function (screenProperty) {
                var _a;
                var screen = screenProperty.screen, configuration = screenProperty.uiConf;
                screenConfiguration = tslib_1.__assign({}, screenConfiguration, (_a = {}, _a[screen] = mappingFunction(configuration), _a));
            });
        }
        if (screenResponsiveConfig.length !== CollectionUtils.SCREEN_SIZES.length) {
            screenConfiguration = CollectionUtils.generateMissingScreenSizes(screenConfiguration);
        }
        return screenConfiguration;
    };
    CollectionUtils.parseNumber = function (data) {
        if (data) {
            var parsedData = Number.parseFloat(data);
            return Number.isNaN(parsedData)
                ? 0
                : parsedData;
        }
        return 0;
    };
    CollectionUtils.generateMissingScreenSizes = function (screenConfiguration) {
        CollectionUtils.SCREEN_SIZES.forEach(function (screen) {
            var _a;
            if (!screenConfiguration[screen]) {
                screenConfiguration = tslib_1.__assign({}, screenConfiguration, (_a = {}, _a[screen] = {}, _a));
            }
        });
        return screenConfiguration;
    };
    CollectionUtils.SCREEN_SIZES = ['xs', 'sm', 'md', 'lg', 'xl', 'HD', 'FullHd', 'FourK'];
    return CollectionUtils;
}());
export { CollectionUtils };
