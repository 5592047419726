import * as tslib_1 from "tslib";
import { Sections } from './Sections';
var Footer = /** @class */ (function (_super) {
    tslib_1.__extends(Footer, _super);
    function Footer(data) {
        return _super.call(this, data, 'Footer') || this;
    }
    return Footer;
}(Sections));
export { Footer };
