import { OnInit } from '@angular/core';
import { UserAuthService } from '../user-auth.service';
var SignInComponent = /** @class */ (function () {
    function SignInComponent(userAuthService) {
        this.userAuthService = userAuthService;
    }
    SignInComponent.prototype.ngOnInit = function () {
        if (this.loginFormJson) {
            this.userAuthService.setModalData(this.loginFormJson);
        }
    };
    return SignInComponent;
}());
export { SignInComponent };
