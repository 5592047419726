/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../html-elements/text/text.component.ngfactory";
import * as i2 from "../../html-elements/text/text.component";
import * as i3 from "@angular/common";
import * as i4 from "./text-group.component";
var styles_TextGroupComponent = [];
var RenderType_TextGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextGroupComponent, data: {} });
export { RenderType_TextGroupComponent as RenderType_TextGroupComponent };
function View_TextGroupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-text", [], [[1, "class", 0], [8, "innerHTML", 1]], null, null, i1.View_TextComponent_0, i1.RenderType_TextComponent)), i0.ɵdid(1, 114688, null, 0, i2.TextComponent, [], { text: [0, "text"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; var currVal_1 = i0.ɵnov(_v, 1).htmlText; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_TextGroupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TextGroupComponent_1)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTextFields(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TextGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-text-group", [], [[1, "class", 0]], null, null, View_TextGroupComponent_0, RenderType_TextGroupComponent)), i0.ɵdid(1, 114688, null, 0, i4.TextGroupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var TextGroupComponentNgFactory = i0.ɵccf("amg-wp-text-group", i4.TextGroupComponent, View_TextGroupComponent_Host_0, { textGroup: "textGroup" }, {}, []);
export { TextGroupComponentNgFactory as TextGroupComponentNgFactory };
