import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardCalendarFixtureComponent } from './components/card-calendar-fixture/card-calendar-fixture.component';
import { CardEventComponent } from './components/card-event/card-event.component';
import { CardFixtureComponent } from './components/card-fixture/card-fixture.component';

import { DateComponent } from './components/date/date.component';
import { LiveButtonsComponent } from './components/live-buttons/live-buttons.component';
import { CardLiveDetailsComponent } from './components/card-live-details/card-live-details.component';

@NgModule({
  declarations: [
    CardCalendarFixtureComponent,
    CardEventComponent,
    CardFixtureComponent,
    DateComponent,
    LiveButtonsComponent,
    CardLiveDetailsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CardCalendarFixtureComponent,
    CardEventComponent,
    CardFixtureComponent,
    DateComponent,
    CardLiveDetailsComponent
  ]
})
export class CardFixtureModule { }
