/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./action-link.component";
var styles_ActionLinkComponent = [];
var RenderType_ActionLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionLinkComponent, data: {} });
export { RenderType_ActionLinkComponent as RenderType_ActionLinkComponent };
function View_ActionLinkComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [["class", "nav-link amg-nav-link"], ["routerLinkActive", "active"]], [[8, "innerHTML", 1], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, [[2, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { fragment: [0, "fragment"], routerLink: [1, "routerLink"] }, null), i0.ɵdid(3, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.getFragment(); var currVal_4 = _co.getPath(); _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_5 = "active"; _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLabel(); var currVal_1 = i0.ɵnov(_v, 2).target; var currVal_2 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ActionLinkComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "amg-link-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionLinkComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isInternalLink(); var currVal_1 = i0.ɵnov(_v.parent, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ActionLinkComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "button", [["class", "btn amg-button"], ["routerLinkActive", "active"], ["type", "button"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, [[3, 4]], 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { fragment: [0, "fragment"], routerLink: [1, "routerLink"] }, null), i0.ɵdid(3, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 3, { links: 1 }), i0.ɵqud(603979776, 4, { linksWithHrefs: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getFragment(); var currVal_2 = _co.getPath(); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = "active"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLabel(); _ck(_v, 1, 0, currVal_0); }); }
function View_ActionLinkComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "amg-button-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionLinkComponent_4)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isInternalLink(); var currVal_1 = i0.ɵnov(_v.parent, 5); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ActionLinkComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "a", [["class", "nav-link amg-nav-link"], ["tabindex", "0"]], [[8, "innerHTML", 1], [8, "href", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLabel(); var currVal_1 = _co.getExternalLink(); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ActionLinkComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "button", [["class", "btn amg-button"], ["type", "button"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerExternalRedirect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLabel(); _ck(_v, 0, 0, currVal_0); }); }
export function View_ActionLinkComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionLinkComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionLinkComponent_3)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, [["externalLink", 2]], null, 0, null, View_ActionLinkComponent_5)), (_l()(), i0.ɵand(0, [["externalButton", 2]], null, 0, null, View_ActionLinkComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isTypeLink(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isTypeButton(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ActionLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-action-link", [], [[1, "class", 0]], null, null, View_ActionLinkComponent_0, RenderType_ActionLinkComponent)), i0.ɵdid(1, 114688, null, 0, i3.ActionLinkComponent, [i2.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var ActionLinkComponentNgFactory = i0.ɵccf("amg-wp-action-link", i3.ActionLinkComponent, View_ActionLinkComponent_Host_0, { actionLink: "actionLink" }, {}, []);
export { ActionLinkComponentNgFactory as ActionLinkComponentNgFactory };
