import { AfterViewChecked, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FlickityUtils } from 'amg-fe-utils';
import Flickity from 'flickity';
var CarouselFlickityComponent = /** @class */ (function () {
    function CarouselFlickityComponent() {
        this.carouselSlides = null;
        this.triggerFlickityInit = true;
        this.triggerFlickityUpdate = false;
        this.isMoreDataAvailable = true;
        this.apiCallTriggered = false;
        this.isWaitingForData = false;
        this.triggerLoadMore = new EventEmitter();
        this.triggerCardClick = new EventEmitter();
    }
    CarouselFlickityComponent.prototype.ngOnInit = function () {
        this.classCardType = this.carouselConfig.carouselType;
        this.cardConfiguration = {
            displayKeys: this.carouselConfig.cardDisplayKeys
        };
    };
    CarouselFlickityComponent.prototype.ngOnDestroy = function () {
        if (this.carousel) {
            this.carousel.destroy();
        }
    };
    CarouselFlickityComponent.prototype.ngAfterViewChecked = function () {
        if (this.triggerFlickityInit) {
            this.carousel = new Flickity(this.carouselContainerRef.nativeElement, {
                groupCells: true,
                contain: false,
                cellAlign: 'left',
                pageDots: false,
                friction: 0.10,
                selectedAttraction: 0.005,
                prevNextButtons: this.carouselConfig.showNextPrevButtons
            });
            if (!!this.carouselConfig.triggerLoadMoreOnEndOfCarousel) {
                this.initializeScrollEventListener();
            }
            FlickityUtils.flickityBugFix(this.carousel, this.carouselContainerRef.nativeElement, 'page-container', 'div.video-card');
            this.triggerFlickityInit = false;
        }
        if (this.triggerFlickityUpdate) {
            var container = this.carousel.handles;
            var viewPort = container[0];
            var slider = viewPort.children[0];
            var flickitySlides = this.carousel.cells;
            var slides = [].slice.call(slider.children);
            var slidesToAdd = slides.splice(flickitySlides.length);
            this.carousel.append(slidesToAdd);
            this.triggerFlickityUpdate = false;
        }
    };
    CarouselFlickityComponent.prototype.ngOnChanges = function (changes) {
        if (changes.carouselSlides
            && changes.carouselSlides.currentValue
            && changes.carouselSlides.previousValue) {
            if (!changes.carouselSlides.previousValue || changes.carouselSlides.previousValue.length === 0) {
                this.carousel.destroy();
                this.triggerFlickityInit = true;
            }
            else if (changes.carouselSlides.currentValue.length !== changes.carouselSlides.previousValue.length) {
                this.triggerFlickityUpdate = true;
                this.isWaitingForData = false;
            }
        }
    };
    CarouselFlickityComponent.prototype.onCardClick = function (data) {
        this.triggerCardClick.emit(data);
    };
    CarouselFlickityComponent.prototype.initializeScrollEventListener = function () {
        var _this = this;
        this.carousel.on('scroll', function (progress) {
            if (!_this.apiCallTriggered && !_this.isWaitingForData) {
                if (_this.isMoreDataAvailable) {
                    var currentProgress = Math.max(0, Math.min(1, progress)) * 100;
                    if (currentProgress >= _this.carouselConfig.triggerLoadMoreOnEndOfCarousel) {
                        _this.isWaitingForData = true;
                        _this.triggerLoadMore.emit(true);
                    }
                }
                else {
                    // Stop listening
                    _this.carousel.off('scroll', function () {
                    });
                }
            }
        });
    };
    return CarouselFlickityComponent;
}());
export { CarouselFlickityComponent };
