/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../components/user-auth/src/lib/sign-in/sign-in.component.ngfactory";
import * as i2 from "../../../../../../../../../components/user-auth/src/lib/sign-in/sign-in.component";
import * as i3 from "../../../../../../../../../components/user-auth/src/lib/user-auth.service";
import * as i4 from "./user-sign-in-page.component";
var styles_UserSignInPageComponent = [];
var RenderType_UserSignInPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserSignInPageComponent, data: {} });
export { RenderType_UserSignInPageComponent as RenderType_UserSignInPageComponent };
export function View_UserSignInPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-sign-in", [], null, null, null, i1.View_SignInComponent_0, i1.RenderType_SignInComponent)), i0.ɵdid(1, 114688, null, 0, i2.SignInComponent, [i3.UserAuthService], { loginFormJson: [0, "loginFormJson"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFormData(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UserSignInPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-user-sign-in-page", [], [[1, "class", 0]], null, null, View_UserSignInPageComponent_0, RenderType_UserSignInPageComponent)), i0.ɵdid(1, 114688, null, 0, i4.UserSignInPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var UserSignInPageComponentNgFactory = i0.ɵccf("amg-wp-user-sign-in-page", i4.UserSignInPageComponent, View_UserSignInPageComponent_Host_0, { userSignInPage: "userSignInPage" }, {}, []);
export { UserSignInPageComponentNgFactory as UserSignInPageComponentNgFactory };
