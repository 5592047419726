/*
 * Public API Surface of card-text
 */

export { CardTextComponent } from './lib/card-text.component';
export { CardTextModule } from './lib/card-text.module';

export { CardTextResponsiveProperties } from './lib/card.text.responsive.properties';
export { CardTextResponsivePropertiesView } from './lib/card.text.responsive.properties.view';
export { CardTextStaticProperties } from './lib/card.text.static.properties';
