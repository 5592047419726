import {
  CardCalendarFixtureComponent,
  CardEventComponent,
  CardFixtureComponent,
} from 'card-fixture';
import { CardImageComponent } from 'card-image';
import { CardTextComponent } from 'card-text';
import { CardProfileComponent } from 'card-profile';
import { CardVideoComponent } from 'card-video';

export class CardRegistry {
  public static CardComponentRegistry: Map<string, any> = new Map<string,any>([
    ['CardCalendarFixtureComponent', CardCalendarFixtureComponent],
    ['CardEventComponent', CardEventComponent],
    ['CardFixtureComponent', CardFixtureComponent],
    ['CardImageComponent', CardImageComponent],
    ['CardProfileComponent', CardProfileComponent],
    ['CardTextComponent', CardTextComponent],
    ['CardVideoComponent', CardVideoComponent],
  ]);
}
