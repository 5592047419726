<button *ngIf="isCollapsible()"
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        [attr.aria-expanded]="getToggleState()"
        [attr.aria-controls]="getNavBarId()"
        [attr.data-target]="getNavBarIdWithHash()"
        aria-label="Toggle navigation"
        (click)="onToggleClick()">
  <span class="navbar-toggler-icon"></span>
</button>

<div [attr.id]="getNavBarId()"
     [ngClass]="{
      'collapse navbar-collapse': isCollapsible(),
      'show': getToggleState()
    }">
  <ul class="navbar-nav">
    <amg-wp-navigation-link *ngFor="let navigationLink of getNavigationLinks(); let rowNumber = index"
                            [navigationLink]="navigationLink"
                            [rowNumber]="rowNumber"></amg-wp-navigation-link>
  </ul>

  <div *ngIf="areExtraSectionsAvailable()"
       class="amg-navbar-extra">
    <ng-container *ngFor="let element of getExtraElements()">
      <ng-container *ngTemplateOutlet="handleSection; context:{ $implicit: element }">
      </ng-container>
    </ng-container>
  </div>
</div>


<ng-template #handleSection let-element>
  <ng-container [ngSwitch]="isTypeArray(element)">
    <div class="amg-navigation-bar-section" *ngSwitchCase="true">
      <ng-container *ngFor="let childElement of element">
        <ng-container *ngTemplateOutlet="handleSection; context:{ $implicit: childElement }">
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngSwitchCase="false" [ngSwitch]="element.objectType">
      <amg-wp-input-field *ngSwitchCase="'InputField'" [inputField]="element"></amg-wp-input-field>
      <amg-wp-social-links *ngSwitchCase="'SocialLinks'" [socialLinks]="element"></amg-wp-social-links>
      <amg-wp-action-link *ngSwitchCase="'ActionLink'" [actionLink]="element"></amg-wp-action-link>
      <amg-wp-icon *ngSwitchCase="'Icon'" [icon]="element"></amg-wp-icon>
    </ng-container>
  </ng-container>
</ng-template>
