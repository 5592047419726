import { BaseObject } from '../../../BaseObject';
import { KeyValue } from '../../../types/Generic';

export class InputField extends BaseObject {
  private static readonly INPUT_TYPE_SELECT: string = 'select';

  private readonly _type: string;
  private readonly _referenceName: string;
  private readonly _label: string;
  private readonly _placeholder: string;
  private readonly _required: boolean;
  private readonly _onEnterNavigateTo: string;

  private readonly _value: string;
  private readonly _values: KeyValue[];

  constructor(data: any) {
    super('InputField', data);

    this._type = data.input_type;
    this._referenceName = data.internal_reference_name;
    this._label = data.label;
    this._placeholder = data.placeholder;
    this._required = data.required;
    this._onEnterNavigateTo = data.onClickNav;

    if (data.input_type === InputField.INPUT_TYPE_SELECT) {
      this._value = null;
      this._values = data.values;
    } else {
      this._values = null;
      this._value = data.value;
    }
  }

  get onEnterNavigateTo(): string {
    return this._onEnterNavigateTo;
  }

  get type(): string {
    return this._type;
  }

  get referenceName(): string {
    return this._referenceName;
  }

  get label(): string {
    return this._label;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  get value(): string {
    return this._value;
  }

  get required(): boolean {
    return this._required;
  }

  get values(): KeyValue[] {
    return this._values;
  }
}
