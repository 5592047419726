import * as tslib_1 from "tslib";
import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { CardType } from 'card';
import { CollapsibleResponsiveProperties } from 'collapsible';
import { NgObject } from './NgObject';
var Collapsible = /** @class */ (function (_super) {
    tslib_1.__extends(Collapsible, _super);
    function Collapsible(data) {
        var _this = _super.call(this, 'Collapsible', data) || this;
        _this._collapsibleData = data.answers || [];
        _this._collapsibleStaticProperties = Collapsible.collapsibleStaticPropertiesMapping(data.question);
        var uiConfiguration = CollectionUtils
            .flattenArrayOfResponsiveConfiguration(data.ui_conf, Collapsible.collapsibleConfigurationMapping);
        _this._collapsibleConfiguration = new CollapsibleResponsiveProperties(uiConfiguration);
        return _this;
    }
    Object.defineProperty(Collapsible.prototype, "collapsibleConfiguration", {
        get: function () {
            return this._collapsibleConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Collapsible.prototype, "collapsibleData", {
        get: function () {
            return this._collapsibleData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Collapsible.prototype, "collapsibleStaticProperties", {
        get: function () {
            return this._collapsibleStaticProperties;
        },
        enumerable: true,
        configurable: true
    });
    Collapsible.collapsibleConfigurationMapping = function (data) {
        var collapsibleConfiguration = {
            canCollapse: data.canCollapse,
            expandButtonText: data.showText,
            hideButtonText: data.hideText,
            refreshRate: CollectionUtils.parseNumber(data.responsiveRefreshRate)
        };
        return collapsibleConfiguration;
    };
    Collapsible.collapsibleStaticPropertiesMapping = function (question) {
        return Collapsible.parsePropertiesOfCardTypeText(question);
    };
    Collapsible.parsePropertiesOfCardTypeText = function (question) {
        return {
            collapsibleTile: CardType.Text,
            title: question,
            cardStaticProperties: {
                keys: ['text']
            }
        };
    };
    return Collapsible;
}(NgObject));
export { Collapsible };
