/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../components/card-fixture/src/lib/components/card-live-details/card-live-details.component.ngfactory";
import * as i2 from "../../../../../../../../../components/card-fixture/src/lib/components/card-live-details/card-live-details.component";
import * as i3 from "@angular/common/http";
import * as i4 from "@angular/common";
import * as i5 from "./live-fixture-card.component";
var styles_LiveFixtureCardComponent = [];
var RenderType_LiveFixtureCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LiveFixtureCardComponent, data: {} });
export { RenderType_LiveFixtureCardComponent as RenderType_LiveFixtureCardComponent };
function View_LiveFixtureCardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-card-live-details", [], [[1, "is-live", 0]], null, null, i1.View_CardLiveDetailsComponent_0, i1.RenderType_CardLiveDetailsComponent)), i0.ɵdid(1, 245760, null, 0, i2.CardLiveDetailsComponent, [i3.HttpClient], { fixtureData: [0, "fixtureData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.cardData; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).isLive; _ck(_v, 0, 0, currVal_0); }); }
export function View_LiveFixtureCardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LiveFixtureCardComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LiveFixtureCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-live-fixture-card", [], [[1, "class", 0], [1, "data-is-data-available", 0]], null, null, View_LiveFixtureCardComponent_0, RenderType_LiveFixtureCardComponent)), i0.ɵdid(1, 245760, null, 0, i5.LiveFixtureCardComponent, [i3.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; var currVal_1 = i0.ɵnov(_v, 1).isDataAvailable; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var LiveFixtureCardComponentNgFactory = i0.ɵccf("amg-wp-live-fixture-card", i5.LiveFixtureCardComponent, View_LiveFixtureCardComponent_Host_0, { videoCard: "videoCard" }, {}, []);
export { LiveFixtureCardComponentNgFactory as LiveFixtureCardComponentNgFactory };
