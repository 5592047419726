import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WordpressAdapterService, ClientResponse, Page, OtherContent } from 'wordpress-adapter';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {

  constructor(private wordpressAdapter: WordpressAdapterService) {
    // TODO page caching. and possibly buffering data when idle to prepare the other 2 sub-pages (players).
    //  This shall improve the user experience.
  }

  getOtherContent(): Observable<OtherContent> {
    return this.wordpressAdapter
      .request<OtherContent>(null, this.getOtherContentEndpoint())
      .pipe(
        map<ClientResponse<OtherContent>, OtherContent>((clientResponse) => {
          return this.handleResponse<OtherContent>(clientResponse);
        })
      );
  }

  getMainPage(): Observable<Page> {
    return this.getPage('home-page');
  }

  getAudioCommentaryPage(): Observable<Page> {
    return this.getPage('audio-commentary-page');
  }

  getVodPage(): Observable<Page> {
    return this.getPage('vod-page');
  }

  getFfctvLivePage(): Observable<Page> {
    return this.getPage('ffctv-live-page');
  }

  getLiveEventsPage(): Observable<Page> {
    return this.getPage('live-events-page');
  }

  private getPage(pageName: string, isJsonFile?: boolean): Observable<Page> {
    return this.wordpressAdapter
      .request<Page>(null, this.getPageEndpoint(pageName, isJsonFile))
      .pipe(
        map<ClientResponse<Page>, Page>((clientResponse) => {
          return this.handleResponse<Page>(clientResponse);
        })
      );
  }

  private handleResponse<T>(clientResponse: ClientResponse<T>): T {
    if (clientResponse.error) {
      return null;
    } else {
      return clientResponse.result;
    }
  }

  private getPageEndpoint(pageName: string, isJsonFile?: boolean): string {
    return isJsonFile
      ? `page/?slug=${pageName}&json=true`
      : `page/?slug=${pageName}`;
  }

  private getOtherContentEndpoint(): string {
    return 'shared_section';
  }
}
