import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var PaymentUserSignInPage = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentUserSignInPage, _super);
    function PaymentUserSignInPage(data) {
        var _this = _super.call(this, 'PaymentUserSignInPage', data) || this;
        _this._logo = data.logo;
        _this._loginFormGroup = {
            emailAddress: data.login_form_group.emailAddress,
            password: data.login_form_group.password,
            loginButton: data.login_form_group.loginButton
        };
        _this._forgotPasswordLink = data.forgot_password;
        if (data.other_links) {
            _this._textLinks = data.other_links.map(function (value) {
                return {
                    title: value.title,
                    textLinkButton: value.textLinkButton
                };
            });
        }
        else {
            _this._textLinks = [];
        }
        return _this;
    }
    Object.defineProperty(PaymentUserSignInPage.prototype, "logo", {
        get: function () {
            return this._logo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentUserSignInPage.prototype, "loginFormGroup", {
        get: function () {
            return this._loginFormGroup;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentUserSignInPage.prototype, "forgotPasswordLink", {
        get: function () {
            return this._forgotPasswordLink;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentUserSignInPage.prototype, "textLinks", {
        get: function () {
            return this._textLinks;
        },
        enumerable: true,
        configurable: true
    });
    return PaymentUserSignInPage;
}(BaseObject));
export { PaymentUserSignInPage };
