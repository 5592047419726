export const environment = {
  production: false,

  wordPressApi: 'https://wp-devfulham.streamamg.com/wp-json/wpa/v1',

  paymentScriptData: {
    name: 'streamPayments',
    src: 'https://devfulhampayments.streamamg.com/_resx/js/stream-payments.js',
    dependencies: [
      { name: 'jQuery', src: 'https://devfulhampayments.streamamg.com/Assets/Account/js/jquery.min.1.9.1.js' }
    ]
  },

  sso: {
    baseUrl: 'https://login.staging.fulhamfc.com',
    // baseUrl: 'https://sso-staging.sportsalliance.com',
    tenantId: 'RDJrCnholUaygSS1yniXsg',
    cookieDomain: ''
  },

  cloudPayUrl: 'https://devfulhampayments.streamamg.com',

  google: {
    analyticsId: ''
  },

  // base Url where the website is deployed, used for Payment integration template
  baseUrl: 'https://devfulham.streamamg.com',

  drmEntries: ['0_55gavccf']
};
