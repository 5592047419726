import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Header, Section } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-header';
  @Input() header: Header;
  @Output() triggerChange: EventEmitter<boolean>;

  constructor() {
    this.triggerChange = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    if (this.header) {
      if (this.getCss()) {
        this.class = this.class
          .concat(' ')
          .concat(this.getCss());
      }
    }
  }

  onElementChange(value: boolean) {
    this.triggerChange.emit(value);
  }

  getSections(): Section[] {
    return this.header
      ? this.header.sections
      : [];
  }

  getCss(): string {
    return this.header.cssClass;
  }
}
