import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CallToAction} from '../../../../shared/data/stream-payments-scripts.model';
import {TextLink} from '../../../../shared/data/wordpress-data.model';

@Component({
  selector: 'amg-call-to-action',
  templateUrl: './call-to-action.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CallToActionComponent implements OnInit {

  @Input()  callToAction: CallToAction;

  title: string;
  forgotPassword: TextLink;
  linkUrl: string;
  label: string;

  constructor() {
  }

  ngOnInit() {
    this.title = this.getTitle();
    if ((this.callToAction as any).call_to_action) {
      this.callToAction = (this.callToAction as any).call_to_action;
    }
    this.forgotPassword = this.getForgotPassword();
    this.linkUrl = this.getLinkUrl();
    this.label = this.getLabel();
  }


  getLinkUrl() {
    return (this.callToAction.path)
      ? this.callToAction.path
      : (this.callToAction.external_link)
        ? this.callToAction.external_link
        : null;
  }

  getForgotPassword() {
    return (this.callToAction.forgot_password)
      ? this.callToAction.forgot_password :
      null;
  }

  getLabel() {
    return (this.callToAction.label)
      ? this.callToAction.label
      : null;
  }

  getTitle() {
    return (this.callToAction.title)
      ? this.callToAction.title
      : null;
  }

}
