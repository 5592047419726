export class PropertyObject {
  constructor(data: {[key: string]: any}) {
    const keys = Object.keys(data);

    keys.forEach(key => {
      if (key !== 'object_type') {
        const value = data[key];
        const updatedPropertyName = key.replace(
          /[^a-zA-Z]([a-z])/g,
          substring => {
            return substring[1].toUpperCase();
          }).replace(/[^a-zA-Z\d]+/g, '');

        Object.assign(this, {
          [updatedPropertyName]: value
        });
      }
    });
  }
}
