import { Carousel } from './Carousel';

export class Boxset extends Carousel {
  constructor(data: any) {
    super({
      ...data,
      type: 'Image'
    }, 'Boxset');
  }
}
