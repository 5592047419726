import { AfterViewChecked, ChangeDetectorRef, EventEmitter, OnInit, OnChanges, SimpleChanges, } from '@angular/core';
import { VideoPlayerService } from '../../video-player.service';
var PlaylistComponent = /** @class */ (function () {
    function PlaylistComponent(videoPlayerService, cd) {
        this.videoPlayerService = videoPlayerService;
        this.cd = cd;
        this.collapsePlaylist = false;
        this.sectionedData = false;
        this.isMobile = false;
        this.firstLoad = true;
        this.clickedEntry = new EventEmitter();
        this.triggerPlaylistReady = new EventEmitter();
        this.endOfList = new EventEmitter();
    }
    Object.defineProperty(PlaylistComponent.prototype, "playlistItems", {
        get: function () {
            return this._playlistItems;
        },
        set: function (items) {
            if (items) {
                this._playlistItems = items;
                this.playlistData = this.videoPlayerService.findAnywhereItems(items);
                this.setSidebarUI();
                this.hasDataChanged = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlaylistComponent.prototype, "allowChangeMedia", {
        get: function () {
            return this._allowChangeMedia;
        },
        set: function (allow) {
            this._allowChangeMedia = allow;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlaylistComponent.prototype, "activeVideoEntry", {
        get: function () {
            return this._activeVideoEntry;
        },
        set: function (entry) {
            this._activeVideoEntry = entry;
        },
        enumerable: true,
        configurable: true
    });
    PlaylistComponent.prototype.ngOnInit = function () {
        this.autoplayHandle = PlaylistComponent.autoplayStorageKey;
        this.autoplay = JSON.parse(localStorage.getItem(this.autoplayHandle));
    };
    PlaylistComponent.prototype.ngOnChanges = function (changes) {
        if (changes.playlistData) {
            this.cd.detectChanges();
        }
    };
    PlaylistComponent.prototype.ngAfterViewChecked = function () {
        if (this.hasDataChanged) {
            this.triggerPlaylistReady.emit(this.hasDataChanged);
            this.hasDataChanged = false;
        }
    };
    PlaylistComponent.prototype.setPlaylistHeaders = function () {
        if (this.playlistData) {
            this.title = this.getPlaylistTitle();
            this.subTitle = this.getPlaylistSubTitle();
            this.sectionedData = true;
        }
    };
    PlaylistComponent.prototype.isLive = function () {
        return this._playlistItems.fixtures !== undefined;
    };
    PlaylistComponent.prototype.changeMedia = function (videoItem) {
        this.videoPlayerService.amgLogger('playlist.component: changeMedia: ', videoItem);
        if (!videoItem.type) {
            this.clickedEntry.emit(videoItem);
        }
    };
    PlaylistComponent.prototype.toggleAutoplay = function () {
        this.autoplay = !this.autoplay;
        this.videoPlayerService.autoplayBSubject.next(this.autoplay);
        if (this.autoplay) {
            localStorage.setItem(this.autoplayHandle, 'true');
        }
        else {
            localStorage.setItem(this.autoplayHandle, 'false');
        }
    };
    PlaylistComponent.prototype.getPlaylistSubTitle = function () {
        return (this.playlistData
            && this.playlistData.metaData
            && this.playlistData.metaData.subTitle)
            ? this.playlistData.metaData.subTitle
            : '';
    };
    PlaylistComponent.prototype.getPlaylistTitle = function () {
        return (this.playlistData
            && this.playlistData.metaData
            && this.playlistData.metaData.title)
            ? this.playlistData.metaData.title
            : 'Watch Next';
    };
    PlaylistComponent.prototype.getLiveItem = function () {
        var _this = this;
        return this.playlistItems.fixtures
            .find(function (fixtureItem) {
            return -1 < fixtureItem.mediaData
                .findIndex(function (mediaData) {
                return mediaData.entryId === _this.videoPlayerService.entryObjLocalRef.entryId;
            });
        });
    };
    PlaylistComponent.prototype.toggleCollapse = function () {
        this.videoPlayerService.amgLogger('toggleCollapse', !this.collapsePlaylist);
        this.collapsePlaylist = !this.collapsePlaylist;
    };
    PlaylistComponent.prototype.setSidebarUI = function () {
        if (window.innerWidth > 768) {
            this.collapsePlaylist = false;
            this.isMobile = false;
        }
        else if (this.firstLoad) {
            this.isMobile = true;
            this.collapsePlaylist = true;
            this.firstLoad = false;
        }
        this.setPlaylistHeaders();
    };
    PlaylistComponent.prototype.isActiveEntry = function (item) {
        var data = this._activeVideoEntry;
        if (data) {
            var media = data.mediaData;
            var foundEntry = this.videoPlayerService.findEntryInMediaData(null, media);
            return foundEntry && data && (item.mediaData.entryId === foundEntry.entryId);
        }
        else {
            return false;
        }
    };
    PlaylistComponent.prototype.hasMoreItems = function () {
        if (this.playlistItems) {
            var pagingData = this.playlistItems.pagingData || this.playlistItems.sections[0].pagingData;
            return pagingData.pageIndex < pagingData.pageCount;
        }
        return false;
    };
    PlaylistComponent.prototype.onScroll = function (e) {
        if (!this.isLive() && this.playlistItems) {
            var pagingData = this.playlistItems.pagingData || this.playlistItems.sections[0].pagingData;
            this.endOfList.emit(pagingData);
        }
    };
    PlaylistComponent.autoplayStorageKey = 'AMG-AUTOPLAY';
    return PlaylistComponent;
}());
export { PlaylistComponent };
