export class ClientResponse<T> {
  private readonly _error: string;
  private readonly _result: T;

  constructor(data: T, error: string) {
    this._result = data;
    this._error = error;
  }

  get error(): string {
    return this._error;
  }

  get result(): T {
    return this._result;
  }
}
