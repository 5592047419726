import { OnInit } from '@angular/core';
import { Icons } from 'wordpress-adapter';
var IconsComponent = /** @class */ (function () {
    function IconsComponent() {
        this.class = 'amg-icon-group';
    }
    IconsComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
    };
    IconsComponent.prototype.getIcons = function () {
        return this.icons.icons;
    };
    IconsComponent.prototype.getCss = function () {
        return this.icons.cssClass;
    };
    return IconsComponent;
}());
export { IconsComponent };
