<div *ngIf="isSearchEnabled()"
     class="amg-input-search-field-container form-control-container">
  <form [formGroup]="form" (ngSubmit)="submitSearch($event)">
    <div class="search-content">
      <label class="input-search-label"
             [htmlFor]="filterBarId"
             [textContent]="getSearchLabel()"></label>
      <div class="field-container">
        <input #searchInput
               [id]="filterBarId"
               formControlName="search"
               type="text"
               [placeholder]="getSearchPlaceholder()"/>
        <div *ngIf="form.get('search').value.length"
             class="close"
             tabindex="0"
             (keyup)="clearInputField(searchInput, $event)"
             (click)="clearInputField(searchInput)"></div>
        <input type="submit" value="&nbsp;"/>
      </div>
    </div>
  </form>
</div>

<div *ngIf="isFilteringDropdownEnabled()"
     class="amg-filter-dropdown-container filters-container">
  <amg-filter-dropdown *ngFor="let filterGroup of getFilterGroups(); let id = index"
                       [gridFilterGroup]="filterGroup"
                       [gridIndexId]="id"
                       [resetFilterObservable]="getFilterSubjectAsObservable()"
                       [resetFilterInternalObservable]="getFilterInternalEmitterAsObservable()"
                       (triggerFilter)="onFilterChange($event)">
  </amg-filter-dropdown>
</div>

<div *ngIf="isFilteringDateEnabled()"
     class="amg-filter-date-container date-container">
  <amg-filter-date [dateFilterProperties]="getDateFilterProperties()"
                   [resetFilterObservable]="getFilterSubjectAsObservable()"
                   [resetFilterInternalObservable]="getFilterInternalEmitterAsObservable()"
                   (triggerFilter)="onFilterChange($event)">
  </amg-filter-date>
</div>

<div *ngIf="isResetAllEnabled()"
     class="filter-reset-container reset-container">
  <button
    (click)="resetFilters()"
  >Reset Filters
  </button>
</div>
