import { OnInit } from '@angular/core';
import { ActionLink } from 'wordpress-adapter';
var ActionLinkComponent = /** @class */ (function () {
    function ActionLinkComponent(document) {
        this.document = document;
        this.class = 'amg-action-link';
    }
    ActionLinkComponent.prototype.ngOnInit = function () {
        this.setUpActionLinkCssClasses();
    };
    ActionLinkComponent.prototype.triggerExternalRedirect = function () {
        if (this.getExternalLink()) {
            this.document.location.href = this.getExternalLink();
        }
    };
    ActionLinkComponent.prototype.getFragment = function () {
        return this.actionLink.fragment;
    };
    ActionLinkComponent.prototype.getPath = function () {
        return this.actionLink.path;
    };
    ActionLinkComponent.prototype.getExternalLink = function () {
        return this.actionLink.externalLink;
    };
    ActionLinkComponent.prototype.getLabel = function () {
        return this.actionLink.label;
    };
    ActionLinkComponent.prototype.isInternalLink = function () {
        return !!this.getPath();
    };
    ActionLinkComponent.prototype.isTypeButton = function () {
        return this.actionLink.type === 'button';
    };
    ActionLinkComponent.prototype.isTypeLink = function () {
        return this.actionLink.type === 'link';
    };
    ActionLinkComponent.prototype.getCss = function () {
        return this.actionLink.cssClass;
    };
    ActionLinkComponent.prototype.setUpActionLinkCssClasses = function () {
        this.class = this.isTypeButton()
            ? this.class.concat(' amg-action-type-button')
            : this.class.concat(' amg-action-type-link');
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
    };
    return ActionLinkComponent;
}());
export { ActionLinkComponent };
