import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ComponentFactoryResolver, ElementRef, EventEmitter, OnDestroy, OnInit, QueryList, ViewContainerRef } from '@angular/core';
import { BootstrapViewportService } from 'amg';
import { CardType } from 'card';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridColumns, GridDataAnywhere, GridDataWordpress, GridHover, GridOnChildrenAdded } from './grid.functions/grid.functions';
import { GridResponsiveProperties } from './grid.responsive.properties';
import { GridSource } from './grid.source.enum';
import { Router } from '@angular/router';
var GridComponent = /** @class */ (function () {
    function GridComponent(config, componentFactoryResolver, bootstrapViewportService, http, router) {
        this.config = config;
        this.componentFactoryResolver = componentFactoryResolver;
        this.bootstrapViewportService = bootstrapViewportService;
        this.http = http;
        this.router = router;
        this.cardClicked = new EventEmitter();
        this.cardHovered = new EventEmitter();
        this.triggerSearch = new EventEmitter();
        this.updateSearchUrl = new EventEmitter();
        this.rawData = [];
        this.cachedUrlForClick = null;
        this.filters = {
            search: { value: '' },
            dropdown: {}
        };
        this.loadingState = new BehaviorSubject(false);
        this.searchTextSubject = new BehaviorSubject('');
        this.triggerFilterResetEmitter = new EventEmitter();
        this.triggerGridReRender = new EventEmitter();
    }
    GridComponent.generateGridStaticProperties = function (gridStaticProperties) {
        return tslib_1.__assign({ cardType: gridStaticProperties.cardType, pageSource: gridStaticProperties.pageSource, pageUrl: gridStaticProperties.pageUrl, pageStart: gridStaticProperties.pageStart || 0, pageSize: gridStaticProperties.pageSize || 12, pagination: gridStaticProperties.pagination || false, loadMore: !!gridStaticProperties.loadMore, loadMoreText: gridStaticProperties.loadMoreText || 'More...', messageOnEmptyDataset: gridStaticProperties.messageOnEmptyDataset || 'Sorry, no results found' }, ((gridStaticProperties.searchBarProperties)
            ? { searchBarProperties: gridStaticProperties.searchBarProperties }
            : {}));
    };
    GridComponent.prototype.preloadPages = function (event) {
        if (this.componentCurrentView.autoload) {
            if (this.componentChildren.last) {
                var bounding = this.componentChildren.last.element.nativeElement.parentElement.getBoundingClientRect();
                var target = (this.componentChildren.last.element.nativeElement.parentElement.offsetHeight * (this.componentCurrentView.autoloadDistance + 1));
                if (bounding.top <= target) {
                    var fetchData = this.gridData.fetchData(this.filters, false, this.isPaginationEnabled());
                    if (fetchData && fetchData.url) {
                        this.cachedUrlForClick = fetchData.url;
                    }
                    else {
                        this.cachedUrlForClick = null;
                    }
                }
            }
        }
    };
    GridComponent.prototype.ngOnInit = function () {
        this.cachedUrlForClick = null;
        var viewport = this.bootstrapViewportService.getViewport();
        this.componentCurrentView = this.responsiveProperties[viewport];
        if (this.defaultSearchValue
            && this.gridStaticProperties.searchBarProperties
            && this.gridStaticProperties.searchBarProperties.searchField) {
            this.filters.search = tslib_1.__assign({ value: this.defaultSearchValue }, (this.gridStaticProperties.searchBarProperties.searchField.key
                ? { key: this.gridStaticProperties.searchBarProperties.searchField.key }
                : {}));
        }
        this.gridStaticProperties = GridComponent.generateGridStaticProperties(this.gridStaticProperties);
        this.gridColumns = new GridColumns(this.componentCurrentView);
        this.gridHover = new GridHover(this.cardHovered);
    };
    GridComponent.prototype.ngOnDestroy = function () {
        this.componentChildrenSubscription.unsubscribe();
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
        this.gridData.onDestroy();
        this.cachedUrlForClick = null;
    };
    GridComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.gridOnChildrenAdded = new GridOnChildrenAdded(this.componentFactoryResolver, this.componentData, this.responsiveProperties, this.gridStaticProperties, this.componentChildren);
        this.componentChildrenSubscription = this.componentChildren.changes
            .subscribe(function () {
            _this.gridOnChildrenAdded.onChildrenAdded();
        });
        if (this.gridStaticProperties.pageSource === GridSource.Wordpress) {
            this.gridData = new GridDataWordpress(this.http, this.componentData, this.responsiveProperties, this.gridStaticProperties, this.componentChildren, this.filters, this.rawData, this.loadingState);
        }
        else {
            this.gridStaticProperties.cardType = CardType.Video;
            this.gridData = new GridDataAnywhere(this.http, this.componentData, this.responsiveProperties, this.gridStaticProperties, this.componentChildren, this.filters, this.rawData, this.loadingState, this.triggerGridReRender);
        }
        this.gridData.setCurrentView('resize', this.componentCurrentView);
        if (this.searchObservable) {
            this.searchSubscription = this.searchObservable.subscribe(function (value) {
                _this.filters.search.value = value;
                _this.searchTextSubject.next(value);
                var fetchData = _this.gridData.fetchData(_this.filters, true, _this.isPaginationEnabled());
                if (fetchData && fetchData.url) {
                    _this.cachedUrlForClick = fetchData.url;
                }
                else {
                    _this.cachedUrlForClick = null;
                }
            });
        }
        else {
            var fetchData = this.gridData.fetchData(this.filters, true, this.isPaginationEnabled());
            if (fetchData && fetchData.url) {
                this.cachedUrlForClick = fetchData.url;
            }
            else {
                this.cachedUrlForClick = null;
            }
        }
    };
    GridComponent.prototype.triggerSearchFilter = function (value) {
        if (value.type === 'search') {
            this.filters.search = tslib_1.__assign({ value: value.searchData.value }, (value.searchData.key ? { key: value.searchData.key } : {}));
        }
        else {
            if (value.type === 'filter') {
                this.setDropdownSearchFilter(value.filterData);
            }
            else {
                this.setDateSearchFilter(value.dateData);
            }
        }
        var searchObject = this.triggerApiCall();
        if (searchObject) {
            this.updateSearchUrl.emit(searchObject);
        }
        else {
            this.updateSearchUrl.emit({});
        }
    };
    GridComponent.prototype.triggerApiCall = function () {
        var fetchData = this.gridData.fetchData(this.filters, true, this.isPaginationEnabled());
        if (fetchData && fetchData.url) {
            this.cachedUrlForClick = fetchData.url;
        }
        else {
            this.cachedUrlForClick = null;
        }
        return fetchData;
    };
    GridComponent.prototype.getSearchBarProperties = function () {
        return this.gridStaticProperties.searchBarProperties;
    };
    GridComponent.prototype.isSearchEnabled = function () {
        return this.gridStaticProperties.searchBarProperties
            && Object.keys(this.gridStaticProperties.searchBarProperties).length > 0;
    };
    GridComponent.prototype.isLoadMoreEnabled = function () {
        return ((!this.componentCurrentView.autoload)
            && (this.gridStaticProperties.loadMore)
            && (this.componentData.length > 0)
            && (!this.gridData.getIsLimitReached())
            && (!this.gridStaticProperties.pagination));
    };
    GridComponent.prototype.isPaginationEnabled = function () {
        return !!this.gridStaticProperties.pagination;
    };
    GridComponent.prototype.onPageChangeClick = function (pageNumber) {
        var fetchData = this.gridData.fetchData(this.filters, false, this.isPaginationEnabled(), pageNumber - 1);
        if (fetchData && fetchData.url) {
            this.cachedUrlForClick = fetchData.url;
        }
        else {
            this.cachedUrlForClick = null;
        }
        if (fetchData) {
            this.updateSearchUrl.emit(fetchData);
        }
        else {
            this.updateSearchUrl.emit({});
        }
    };
    GridComponent.prototype.getPaginationConfig = function () {
        return this.gridData.getPagingData();
    };
    GridComponent.prototype.getCol = function (i) {
        return this.gridColumns.getColumn();
    };
    GridComponent.prototype.slideHover = function (source, i, item) {
        var slide = item ? (item.raw || this.componentData[i]) : this.componentData[i];
        this.gridHover.slideHover(source, i, slide);
    };
    GridComponent.prototype.slideHoverOut = function (source, i, item) {
        this.gridHover.slideHoverOut(source, i);
    };
    GridComponent.prototype.getSlideItem = function (i, item) {
        if (!item && this.componentData[i]) {
            item = this.componentData[i];
        }
        if (item.raw) {
            item = item.raw;
        }
        return item;
    };
    GridComponent.prototype.slideClick = function (source, i, item) {
        var slide = this.getSlideItem(i, item);
        if (slide.mediaData && this.cachedUrlForClick && this.config && this.config.useCacheOnGridClick) {
            this.router.navigate(['/video'], {
                queryParams: {
                    playlist: btoa(this.cachedUrlForClick),
                    entry: slide.mediaData.entryId
                }
            });
        }
        else {
            this.cardClicked.emit(slide);
        }
    };
    GridComponent.prototype.onClickLoadMore = function () {
        var fetchData = this.gridData.fetchData(this.filters, false, this.isPaginationEnabled());
        if (fetchData && fetchData.url) {
            this.cachedUrlForClick = fetchData.url;
        }
        else {
            this.cachedUrlForClick = null;
        }
    };
    GridComponent.prototype.isLoadingData = function () {
        return this.gridData
            ? this.gridData.getIsLoading()
            : true;
    };
    GridComponent.prototype.onFilterReset = function () {
        // Trigger only when something is set.
        if (this.filters.search.value || Object.keys(this.filters.dropdown).length > 0) {
            this.filters.search.value = '';
            this.searchTextSubject.next('');
            this.filters.dropdown = {};
            var fetchData = this.gridData.fetchData(this.filters, true, this.isPaginationEnabled());
            if (fetchData && fetchData.url) {
                this.cachedUrlForClick = fetchData.url;
            }
            else {
                this.cachedUrlForClick = null;
            }
        }
    };
    GridComponent.prototype.getTriggerFilterResetAsObservable = function () {
        return this.triggerFilterResetEmitter.asObservable();
    };
    GridComponent.prototype.getSearchObservable = function () {
        return this.searchTextSubject.asObservable();
    };
    GridComponent.prototype.getLoadingStateAsObservable = function () {
        return this.loadingState.asObservable();
    };
    GridComponent.prototype.setDropdownSearchFilter = function (filterData) {
        if (filterData.data.value) {
            this.filters.dropdown[filterData.id] = filterData.data;
        }
        else {
            var _a = this.filters.dropdown, _b = filterData.id, removedFilter = _a[_b], remainingFilters = tslib_1.__rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
            this.filters.dropdown = remainingFilters;
        }
    };
    GridComponent.prototype.setDateSearchFilter = function (dateData) {
        // TODO:
    };
    return GridComponent;
}());
export { GridComponent };
