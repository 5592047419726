import { BaseObject } from '../../../BaseObject';
import { AnywhereApiConfiguration } from '../../../config/AnywhereApiConfiguration';
import { BaseConfiguration } from '../../../config/BaseConfiguration';
import { KalturaConfiguration } from '../../../config/KalturaConfiguration';
import { RestApiConfiguration } from '../../../config/RestApiConfiguration';
import { KeyValue } from '../../../types/Generic';

export class NgObject extends BaseObject {
  private readonly _baseConfiguration: BaseConfiguration;
  private readonly _anywhereApiConfiguration: AnywhereApiConfiguration;
  private readonly _restApiConfiguration: RestApiConfiguration;
  private readonly _kalturaConfiguration: KalturaConfiguration;

  private readonly _navigationParameters: KeyValue[];
  private readonly _onClickNavigate: string;
  private readonly _sendEntryDataOnNavigation: boolean;

  constructor(objectType: string, data: any) {
    super(objectType, data);

    if (data) {
      if (data.interaction) {
        this._navigationParameters = data.interaction.navParam || [];
        this._onClickNavigate = data.interaction.onClickNav ? `/${data.interaction.onClickNav}` : '';
        this._sendEntryDataOnNavigation = data.interaction.hasOwnProperty('sendEntryDataOnNavigation')
          ? !!data.interaction.sendEntryDataOnNavigation
          : true;
      } else {
        this._navigationParameters = [];
        this._onClickNavigate = '';
        this._sendEntryDataOnNavigation = true;
      }

      this._baseConfiguration = data.api_base_configuration || null;
      this._anywhereApiConfiguration = data.anywhere_api_configuration || null;
      this._kalturaConfiguration = data.kaltura_player_configuration || null;
      this._restApiConfiguration = data.rest_api_configuration || null;
    }
  }

  get sendEntryDataOnNavigation(): boolean {
    return this._sendEntryDataOnNavigation;
  }

  get baseConfiguration(): BaseConfiguration {
    return this._baseConfiguration;
  }

  get anywhereApiConfiguration(): AnywhereApiConfiguration {
    return this._anywhereApiConfiguration;
  }

  get restApiConfiguration(): RestApiConfiguration {
    return this._restApiConfiguration;
  }

  get kalturaConfiguration(): KalturaConfiguration {
    return this._kalturaConfiguration;
  }

  get navigationParameters(): KeyValue[] {
    return this._navigationParameters;
  }

  get onClickNavigate(): string {
    return this._onClickNavigate;
  }
}
