import { AmgComponentResponsivePropertiesView } from 'amg';

import { GridSource } from './grid.source.enum';

export class GridResponsivePropertiesView implements AmgComponentResponsivePropertiesView {
  items: number;
  margin: number;
  lazyLoad: boolean;
  lazyLoadEager: number;
  autoload: boolean;
  autoloadDistance: number;
  refreshRate: number;
  responsiveBaseElement: any;
  animateOut: string;
  animateIn: string;

  cardView: AmgComponentResponsivePropertiesView;

  public static defaultValues(input?: GridResponsivePropertiesView): GridResponsivePropertiesView {
    let toReturn: GridResponsivePropertiesView;
    if (input === undefined) {
     toReturn = new GridResponsivePropertiesView();
    } else {
     toReturn = input;
    }

    toReturn = {...toReturn, ... {
      items: 4,
      margin: 0,
      lazyLoad: false,
      lazyLoadEager: 0,
      autoload: false,
      autoloadDistance: 1,
      refreshRate: 200,
      responsiveBaseElement: {},
      animateOut: '',
      animateIn: '',

      cardView: {},
    }};

    return toReturn;
  }
}
