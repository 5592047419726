import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Carousel as WpCarousel, AnywhereApiConfiguration } from 'wordpress-adapter';
import { CarouselStaticProperties, CarouselResponsiveProperties } from 'carousel';
import { CardType } from 'card';
import { ApiService } from '../../../../../shared/api/api.service';
import { DataService } from '../../../../../shared/data/data.service';

@Component({
  selector: 'amg-wp-carousel',
  templateUrl: './carousel.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CarouselComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-carousel';
  @Input() carousel: WpCarousel;

  public carouselData: any[];
  public carouselResponsiveProperties: any;
  public carouselStaticProperties: CarouselStaticProperties;

  private anywhereApiConfiguration: AnywhereApiConfiguration;

  private apiDataObservable: Subscription;

  private static getCarouselType(cardType: string): CardType {
    switch (cardType) {
      case 'Video':
        return CardType.Video;
      case 'Image':
        return CardType.Image;
      case 'Fixture':
        return CardType.Fixture;
      default:
        return CardType.Text;
    }
  }

  constructor(private apiService: ApiService,
              private dataService: DataService,
              private router: Router) {
  }

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.initialiseCarousel();
    this.triggerDataRetrieval();
  }

  ngOnDestroy(): void {
    if (this.apiDataObservable) {
      this.apiDataObservable.unsubscribe();
    }

    if (this.anywhereApiConfiguration) {
      this.apiService.clearAnywhereApiCache(this.anywhereApiConfiguration);
    }
  }

  onCardClick(data: any) {
    if (this.carousel.onClickNavigate) {
      // At this point there can only be 2 cases:
      //  Case 1: Carousel retrieving from Anywhere (entry id + url)
      //  Case 2: Carousel retrieving from WP Endpoint (url from imageUrl)
      let url: string,
        entryId: string;

      if (this.anywhereApiConfiguration) {
        const playlistUrl = ApiService.generateFullAnywhereUrl(this.anywhereApiConfiguration);

        url = this.dataService.encodeUrl(playlistUrl);
        entryId = (data && data.mediaData && data.mediaData.entryId)
          ? data.mediaData.entryId
          : null;

        this.dataService.userInteractedObject = {
          playlistUrl,
          data
        };
      } else {
        url = this.dataService.encodeUrl(data.playlist_url);
        entryId = null;

        this.dataService.userInteractedObject = {
          playlistUrl: data.playlist_url,
          data
        };
      }

      this.router.navigate([this.carousel.onClickNavigate], {
        queryParams: {
          playlist: url,
          ...(entryId ? {entry: entryId} : {})
        },
        preserveQueryParams: false
      });
    }
  }

  getCss(): string {
    return this.carousel.cssClass;
  }

  private initialiseCarousel(): void {
    this.carouselData = [];

    this.carouselStaticProperties = {
      carouselTile: CarouselComponent.getCarouselType(this.carousel.carouselType),
      carouselSidebar: this.carousel.carouselSideBar
    };
    this.carouselResponsiveProperties = new CarouselResponsiveProperties(this.carousel.carouselConfiguration);

    if (this.carousel.useAnywhereApiConfiguration) {
      this.anywhereApiConfiguration = this.carousel.anywhereApiConfiguration;
    }
  }

  private triggerDataRetrieval(): void {
    if (this.anywhereApiConfiguration) {
      this.apiDataObservable = this.apiService.getAnyWhereData(this.anywhereApiConfiguration).subscribe(data => {
        if (data) {
          this.carouselData = this.getData(data);
        }
      });
    } else {
      this.apiDataObservable = this.apiService.getApiData(this.carousel.restApiConfiguration).subscribe(data => {
        if (data) {
          this.carouselData = data;
        }
      });
    }
  }

  private getData(data: any): any[] {
    if (data) {
      if (data.sections && data.sections.length) {
        return data.sections[0].itemData;
      }
    }

    return [];
  }

}
