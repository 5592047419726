import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnywhereUtils } from 'amg-fe-utils';
import { CarouselFlickity } from 'wordpress-adapter';
import { DataService } from '../../../../../shared/data/data.service';
var CarouselFlickityComponent = /** @class */ (function () {
    function CarouselFlickityComponent(httpClient, dataService, router) {
        this.httpClient = httpClient;
        this.dataService = dataService;
        this.router = router;
        this.class = 'amg-carousel-flickity';
        this.isLoading = true;
        this.isMoreDataAvailable = true;
        this.apiCallTriggered = false;
        this.isError = false;
    }
    CarouselFlickityComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.initialiseCarousel();
        this.triggerApiCall(this.apiUrl, true);
    };
    CarouselFlickityComponent.prototype.ngOnDestroy = function () {
        if (this.apiDataObservable) {
            this.apiDataObservable.unsubscribe();
        }
    };
    CarouselFlickityComponent.prototype.onCardClick = function (data) {
        if (this.carousel.onClickNavigate) {
            var playlistUrl = AnywhereUtils.generateAnywhereUrl(this.apiUrl, tslib_1.__assign({}, this.pagingData, { pageIndex: this.pagingData.pageIndex - 1 }));
            var url = this.dataService.encodeUrl(playlistUrl);
            var entryId = (data && data.mediaData && data.mediaData.entryId)
                ? data.mediaData.entryId
                : null;
            this.dataService.userInteractedObject = {
                playlistUrl: playlistUrl,
                data: data
            };
            this.router.navigate([this.carousel.onClickNavigate], {
                queryParams: tslib_1.__assign({ playlist: url }, (entryId ? { entry: entryId } : {})),
                preserveQueryParams: false
            });
        }
    };
    CarouselFlickityComponent.prototype.onTriggerLoadMore = function () {
        if (this.isMoreDataAvailable) {
            var url = AnywhereUtils.generateAnywhereUrl(this.apiUrl, this.pagingData);
            this.triggerApiCall(url);
        }
    };
    CarouselFlickityComponent.prototype.getCss = function () {
        return this.carousel.cssClass;
    };
    CarouselFlickityComponent.prototype.initialiseCarousel = function () {
        this.carouselData = [];
        this.apiUrl = this.carousel.carousalAnywhereApiUrl;
    };
    CarouselFlickityComponent.prototype.triggerApiCall = function (apiUrl, isInit) {
        var _this = this;
        if (this.apiDataObservable) {
            this.apiDataObservable.unsubscribe();
        }
        this.apiDataObservable = this.httpClient.get(apiUrl).subscribe(function (data) {
            if (data) {
                var extractedData = AnywhereUtils.extractAnywhereData(data);
                _this.carouselData = _this.carouselData.length > 0
                    ? _this.carouselData.concat(extractedData.itemData) : extractedData.itemData;
                if (isInit && _this.carousel.carouselStaticData.skipFirstEntry && _this.carouselData.length) {
                    _this.carouselData = _this.carouselData.slice(1);
                }
                _this.pagingData = extractedData.pagingData;
                _this.isMoreDataAvailable = AnywhereUtils.isMoreDataAvailable(extractedData.pagingData);
            }
            else {
                _this.isMoreDataAvailable = false;
                _this.pagingData = null;
            }
            _this.apiCallTriggered = false;
            _this.isLoading = false;
        }, function (error) {
            _this.isMoreDataAvailable = false;
            _this.apiCallTriggered = false;
            _this.isLoading = false;
            _this.isError = true;
        });
        this.apiCallTriggered = true;
    };
    return CarouselFlickityComponent;
}());
export { CarouselFlickityComponent };
