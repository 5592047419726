import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var PaymentFieldsGroup = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentFieldsGroup, _super);
    function PaymentFieldsGroup(data) {
        var _this = _super.call(this, 'PaymentFieldsGroup', data) || this;
        if (data.payment_fields_group && data.payment_fields_group.length > 0) {
            data.payment_fields_group.forEach(function (paymentField) {
                if (paymentField.objectType === 'PaymentLoginButtonGroup') {
                    _this._loginButtonGroup = paymentField;
                }
                else if (paymentField.objectType === 'PaymentUserSignInPage') {
                    _this._userAuthorizationPage = paymentField;
                }
                else if (paymentField.objectType === 'PaymentVideoPlayer') {
                    _this._videoPlayer = paymentField;
                }
                else if (paymentField.objectType === 'PaymentSubscribePackages') {
                    _this._subscribePackages = paymentField;
                }
            });
        }
        return _this;
    }
    Object.defineProperty(PaymentFieldsGroup.prototype, "loginButtonGroup", {
        get: function () {
            return this._loginButtonGroup;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentFieldsGroup.prototype, "userAuthorizationPage", {
        get: function () {
            return this._userAuthorizationPage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentFieldsGroup.prototype, "videoPlayer", {
        get: function () {
            return this._videoPlayer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentFieldsGroup.prototype, "subscribePackages", {
        get: function () {
            return this._subscribePackages;
        },
        enumerable: true,
        configurable: true
    });
    return PaymentFieldsGroup;
}(BaseObject));
export { PaymentFieldsGroup };
