import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../../../../shared/data/data.service';
import { NavigationUtils } from '../../../../../../utils/NavigationUtils';
var GameFixtureComponent = /** @class */ (function () {
    function GameFixtureComponent(router, dataService) {
        this.router = router;
        this.dataService = dataService;
        this.class = 'amg-fixture-game';
    }
    GameFixtureComponent.prototype.ngOnInit = function () {
    };
    GameFixtureComponent.prototype.buttonClicked = function (data) {
        if (this.onClickNavRoute) {
            var urlFragment = NavigationUtils.getUrlFragments(this.onClickNavRoute);
            if (this.sendEntryDataOnNavigation) {
                this.dataService.userInteractedObject = {
                    fixtureUrl: this.apiUrl,
                    data: data.entry
                };
                this.router.navigate([urlFragment.url], tslib_1.__assign({ queryParams: {
                        fixture: this.dataService.encodeUrl(this.apiUrl),
                        entry: data.entry.entryId
                    }, preserveQueryParams: false }, (urlFragment.fragment ? { fragment: urlFragment.fragment } : {})));
            }
            else {
                this.router.navigate([urlFragment.url], tslib_1.__assign({}, (urlFragment.fragment ? { fragment: urlFragment.fragment } : {})));
            }
        }
    };
    return GameFixtureComponent;
}());
export { GameFixtureComponent };
