import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var NavigationBar = /** @class */ (function (_super) {
    tslib_1.__extends(NavigationBar, _super);
    function NavigationBar(data) {
        var _this = _super.call(this, 'NavigationBar', data) || this;
        _this._id = data.element_id;
        _this._isCollapsible = data.is_collapsible;
        _this._extras = (data.navigation_extras)
            ? _this.convertNavigationExtras(data.navigation_extras)
            : [];
        _this._navigationLinks = (data.navigation_links)
            ? data.navigation_links
            : [];
        return _this;
    }
    Object.defineProperty(NavigationBar.prototype, "navigationLinks", {
        get: function () {
            return this._navigationLinks;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBar.prototype, "extras", {
        get: function () {
            return this._extras;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBar.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBar.prototype, "isCollapsible", {
        get: function () {
            return this._isCollapsible;
        },
        enumerable: true,
        configurable: true
    });
    NavigationBar.prototype.convertNavigationExtras = function (data) {
        var _this = this;
        var navigationExtras = [];
        Object.keys(data).forEach(function (key) {
            var value = data[key];
            if (key === 'section') {
                // Recursive for every section
                navigationExtras.push(_this.convertNavigationExtras(value));
            }
            else {
                // Push objects which are not section
                navigationExtras.push(value);
            }
        });
        return navigationExtras;
    };
    return NavigationBar;
}(BaseObject));
export { NavigationBar };
