import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { CardProfileModule } from 'card-profile';

import { CardVideoModule } from 'card-video';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterDateComponent } from './components/filter-bar/filter-date/filter-date.component';

import { GridComponent } from './grid.component';
import { PlayerPipe } from './player.pipe';

import { FilterDropdownComponent } from './components/filter-bar/filter-dropdown/filter-dropdown.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';

@NgModule({
  declarations: [
    GridComponent,
    PlayerPipe,
    FilterDropdownComponent,
    FilterDateComponent,
    FilterBarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2FlatpickrModule,
    CardProfileModule,
    CardVideoModule,
    NgSelectModule,
    NgxPaginationModule
  ],
  exports: [
    GridComponent,
    PlayerPipe
  ],
})
export class GridModule { }
