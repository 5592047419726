import {
  ViewContainerRef,
  ComponentFactoryResolver,
  QueryList,
} from '@angular/core';

import {
  AmgComponent,
  AmgComponentResponsiveProperties,
} from 'amg';

import {
  CardRegistry,
  CardResponsiveInstantiate,
} from 'card';

import { CarouselResponsiveProperties } from '../carousel.responsive.properties';
import { CarouselStaticProperties } from '../carousel.static.properties';

export class CarouselInit {
  private cardResponsive: AmgComponentResponsiveProperties;
  private componentFactory;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private componentData: Array<any>,
    private responsiveProperties: CarouselResponsiveProperties,
    private staticProperties: CarouselStaticProperties,
    private componentChildren: QueryList<ViewContainerRef>,
  ) {
    const component = CardRegistry.CardComponentRegistry.get(`Card${this.staticProperties.carouselTile}Component`);
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    this.cardResponsive = CardResponsiveInstantiate.instantiate(
      this.staticProperties.carouselTile,
      {
        xs: this.responsiveProperties.xs.cardView,
        sm: this.responsiveProperties.sm.cardView,
        md: this.responsiveProperties.md.cardView,
        lg: this.responsiveProperties.lg.cardView,
        xl: this.responsiveProperties.xl.cardView,
      },
    );
  }

  private runUpdate() {
    this.componentChildren.forEach((viewContainerRef: ViewContainerRef, i: number) => {
      if (viewContainerRef.length === 0) {
        const componentRef = viewContainerRef.createComponent(this.componentFactory);

        const componentRefInstance = (<  AmgComponent>componentRef.instance);
        componentRefInstance.componentData = this.componentData[i];
        componentRefInstance.responsiveProperties = this.cardResponsive;
        componentRefInstance.staticProperties = this.staticProperties.cardStaticProperties;
        
        componentRef.changeDetectorRef.detectChanges();
      }
    });
  }
  
  ngAfterViewInit(
  ) {
    this.runUpdate();
  }
  
  ngOnChanges(
    componentData: Array<any>,
    responsiveProperties: CarouselResponsiveProperties,
    staticProperties: CarouselStaticProperties,
    componentChildren: QueryList<ViewContainerRef>,
  ) {
    this.componentData = componentData;
    this.responsiveProperties = responsiveProperties;
    this.staticProperties = staticProperties;
    this.componentChildren = componentChildren;

    this.runUpdate();
  }
}
