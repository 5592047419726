import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {AnywhereItemData} from '../../_data/video-player.model';
import {VideoPlayerService} from '../../video-player.service';
import { AmgFixture, AmgFixtureMediaData } from 'amg-fe-types';

declare var window: any;

@Component({
  selector: 'amg-playlist',
  templateUrl: './playlist.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PlaylistComponent implements OnInit, OnChanges, AfterViewChecked {
  private static readonly autoplayStorageKey = 'AMG-AUTOPLAY';
  autoplayHandle: string;
  collapsePlaylist = false;
  autoplay: boolean;
  title: string;
  subTitle: string;
  sectionedData = false;
  playlistData: any;
  isMobile = false;
  firstLoad = true;
  @Output()
  clickedEntry: EventEmitter<AnywhereItemData> = new EventEmitter<AnywhereItemData>();
  @Output() triggerPlaylistReady: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() endOfList: EventEmitter<number> = new EventEmitter<number>();
  private hasDataChanged: boolean;

  constructor(public videoPlayerService: VideoPlayerService, private cd: ChangeDetectorRef) {
  }

  private _playlistItems: Array<AnywhereItemData> | any;

  get playlistItems(): Array<AnywhereItemData> | any {
    return this._playlistItems;
  }

  @Input()
  set playlistItems(items: Array<AnywhereItemData> | any) {
    if (items) {
      this._playlistItems = items;
      this.playlistData = this.videoPlayerService.findAnywhereItems(items);
      this.setSidebarUI();
      this.hasDataChanged = true;
    }
  }

  private _allowChangeMedia: boolean;

  get allowChangeMedia(): boolean {
    return this._allowChangeMedia;
  }

  @Input()
  set allowChangeMedia(allow: boolean) {
    this._allowChangeMedia = allow;
  }

  private _activeVideoEntry: AnywhereItemData;

  get activeVideoEntry(): AnywhereItemData {
    return this._activeVideoEntry;
  }

  @Input()
  set activeVideoEntry(entry: AnywhereItemData) {
    this._activeVideoEntry = entry;
  }

  ngOnInit() {
    this.autoplayHandle = PlaylistComponent.autoplayStorageKey;
    this.autoplay = JSON.parse(localStorage.getItem(this.autoplayHandle));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.playlistData) {
      this.cd.detectChanges();
    }
  }

  ngAfterViewChecked(): void {
    if (this.hasDataChanged) {
      this.triggerPlaylistReady.emit(this.hasDataChanged);
      this.hasDataChanged = false;
    }
  }

  setPlaylistHeaders() {
    if (this.playlistData) {
      this.title = this.getPlaylistTitle();
      this.subTitle = this.getPlaylistSubTitle();
      this.sectionedData = true;
    }
  }

  isLive() {
    return this._playlistItems.fixtures !== undefined;
  }

  changeMedia(videoItem: AnywhereItemData) {
    this.videoPlayerService.amgLogger('playlist.component: changeMedia: ', videoItem);
    if (!videoItem.type) {
      this.clickedEntry.emit(videoItem);
    }
  }

  toggleAutoplay() {
    this.autoplay = !this.autoplay;
    this.videoPlayerService.autoplayBSubject.next(this.autoplay);
    if (this.autoplay) {
      localStorage.setItem(this.autoplayHandle, 'true');
    } else {
      localStorage.setItem(this.autoplayHandle, 'false');
    }
  }

  getPlaylistSubTitle() {
    return (this.playlistData
      && this.playlistData.metaData
      && this.playlistData.metaData.subTitle)
      ? this.playlistData.metaData.subTitle
      : '';
  }

  getPlaylistTitle() {
    return (this.playlistData
      && this.playlistData.metaData
      && this.playlistData.metaData.title)
      ? this.playlistData.metaData.title
      : 'Watch Next';
  }

  getLiveItem(): AmgFixture {
    return (this.playlistItems.fixtures as Array<AmgFixture>)
      .find((fixtureItem: AmgFixture) => {
        return -1 < (fixtureItem.mediaData as Array<AmgFixtureMediaData>)
          .findIndex((mediaData: AmgFixtureMediaData) => {
            return mediaData.entryId === this.videoPlayerService.entryObjLocalRef.entryId;
          });
      });
  }

  toggleCollapse() {
    this.videoPlayerService.amgLogger('toggleCollapse', !this.collapsePlaylist);
    this.collapsePlaylist = !this.collapsePlaylist;
  }

  setSidebarUI() {
    if (window.innerWidth > 768) {
      this.collapsePlaylist = false;
      this.isMobile = false;
    } else if (this.firstLoad) {
      this.isMobile = true;
      this.collapsePlaylist = true;
      this.firstLoad = false;
    }
    this.setPlaylistHeaders();
  }

  isActiveEntry(item: AnywhereItemData) {
    const data = this._activeVideoEntry;
    if (data) {
      const media = data.mediaData;
      const foundEntry = this.videoPlayerService.findEntryInMediaData(null, media);
      return foundEntry && data && (item.mediaData.entryId === foundEntry.entryId);
    } else {
      return false;
    }
  }

  hasMoreItems(): boolean {
    if (this.playlistItems) {
      const pagingData = this.playlistItems.pagingData || this.playlistItems.sections[0].pagingData;
      return pagingData.pageIndex < pagingData.pageCount;
    }
    return false;
  }

  onScroll(e?) {
    if (!this.isLive() && this.playlistItems) {
      const pagingData = this.playlistItems.pagingData || this.playlistItems.sections[0].pagingData;
      this.endOfList.emit(pagingData);
    }
  }

}
