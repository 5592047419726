import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var Icon = /** @class */ (function (_super) {
    tslib_1.__extends(Icon, _super);
    function Icon(data) {
        var _this = _super.call(this, 'Icon', data) || this;
        _this._imageData = data.image;
        _this._label = data.label;
        _this._link = data.link;
        _this._relativeLink = data.relative_link
            ? "/" + data.relative_link
            : '';
        return _this;
    }
    Object.defineProperty(Icon.prototype, "imageData", {
        get: function () {
            return this._imageData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Icon.prototype, "label", {
        get: function () {
            return this._label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Icon.prototype, "link", {
        get: function () {
            return this._link;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Icon.prototype, "relativeLink", {
        get: function () {
            return this._relativeLink;
        },
        enumerable: true,
        configurable: true
    });
    return Icon;
}(BaseObject));
export { Icon };
