export class CardTextResponsivePropertiesView {
  refreshRate: number;

  public static defaultValues(input ?: CardTextResponsivePropertiesView): CardTextResponsivePropertiesView {
    let toReturn: CardTextResponsivePropertiesView;
    if (input === undefined) {
      toReturn = new CardTextResponsivePropertiesView();
    } else {
      toReturn = input;
    }

    toReturn = {
      ...toReturn,
      ...{
        refreshRate: 200,
      }
    };

    return toReturn;
  }
}
