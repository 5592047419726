import * as tslib_1 from "tslib";
var ClientException = /** @class */ (function (_super) {
    tslib_1.__extends(ClientException, _super);
    function ClientException(code, message, args) {
        var _this = _super.call(this, message) || this;
        _this.code = code;
        _this.message = message;
        _this.args = args;
        return _this;
    }
    return ClientException;
}(Error));
export { ClientException };
