export class DateObject {
  minute: number;
  hour: number;
  day: number;
  month: number;
  year: number;

  private static convertDate(date: string): Date {
    if (!date.toUpperCase().endsWith('Z')) {
      date += 'Z';
    }

    return new Date(date);
  }

  constructor(startDate: string, isUtc: boolean) {
    if (isUtc) {
      this.generateUtcTime(startDate);
    } else {
      this.generateLocaleTime(startDate);
    }
  }

  private generateUtcTime(dateString: string): void {
    const date = DateObject.convertDate(dateString);

    this.setDateAndTime(
      date.getUTCMinutes(),
      date.getUTCHours(),
      date.getUTCDate(),
      (date.getUTCMonth() + 1),
      date.getUTCFullYear()
    );
  }

  private generateLocaleTime(dateString: string): void {
    const date = DateObject.convertDate(dateString);

    this.setDateAndTime(
      date.getMinutes(),
      date.getHours(),
      date.getDate(),
      (date.getMonth() + 1),
      date.getFullYear()
    );
  }

  private setDateAndTime(minute: number, hour: number, day: number, month: number, year: number) {
    this.minute = minute;
    this.hour = hour;
    this.day = day;
    this.month = month;
    this.year = year;
  }
}
