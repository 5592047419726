import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {
  AnywhereItemData,
  AnywhereMediaData,
  AnywhereMetaData,
  AnywherePublicationData, FixtureMediaData
} from '../../../_data/video-player.model';
import {VideoPlayerService} from '../../../video-player.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'amg-playlist-card',
  templateUrl: './playlist-card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PlaylistCardComponent implements OnInit {
  data: AnywhereItemData;
  media: AnywhereMediaData[];
  meta: AnywhereMetaData;
  publication: AnywherePublicationData;

  competitions: string;
  type: string;
  title: string;
  teamVsTeam: string;
  description: string;
  date: string;
  categories: string[];
  mediaType: string | boolean;
  showLiveIcons: boolean;

  @Input()
  cardData: AnywhereItemData;

  @Input()
  clickedItemData: FixtureMediaData;

  constructor() {
    this.showLiveIcons = false;
  }

  ngOnInit() {
    if (this.cardData) {
      this.data = this.cardData;
      this.media = this.data.mediaData;
      this.meta = this.data.metaData;
      this.publication = this.data.publicationData;
      if (this.cardData.type === 'fixture' || this.cardData.type === 'event') {
        this.showLiveIcons = true;
        this.formatFixtureData();
      } else {
        this.formatAnywhereData();
      }

    }
  }



  formatAnywhereData() {
    this.title = (this.meta.title)
      ? this.meta.title
      : null;

    this.description = (this.meta.body)
      ? this.meta.body
      : null;

    this.date = (this.publication.releaseFrom)
      ? this.publication.releaseFrom
      : null;

    this.categories = (this.meta.category)
      ? this.meta.category
      : (this.meta.categories)
        ? this.meta.categories
        : null;
  }

  formatFixtureData() {
    this.type = this.cardData.type;
    this.title = this.cardData.name;

    this.teamVsTeam = `${(this.cardData.homeTeam)
      ? this.cardData.homeTeam.name
      : ''} vs ${(this.cardData.awayTeam)
      ? this.cardData.awayTeam.name
      : ''}`;

    this.description = (this.cardData.description)
      ? this.cardData.description
      : null;

    this.date = (this.cardData.startDate)
      ? this.cardData.startDate
      : null;
  }



}
