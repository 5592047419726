import * as tslib_1 from "tslib";
import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { NgObject } from './NgObject';
var Countdown = /** @class */ (function (_super) {
    tslib_1.__extends(Countdown, _super);
    function Countdown(data) {
        var _this = _super.call(this, 'Countdown', data) || this;
        _this._endTime = Countdown.parseInvariantCultureDateTime(data.end);
        _this._countdownConfiguration = CollectionUtils
            .flattenArrayOfResponsiveConfiguration(data.ui_conf, Countdown.countdownConfigurationMapping);
        return _this;
    }
    Object.defineProperty(Countdown.prototype, "countdownConfiguration", {
        get: function () {
            return this._countdownConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Countdown.prototype, "endTime", {
        get: function () {
            return this._endTime;
        },
        enumerable: true,
        configurable: true
    });
    Countdown.parseInvariantCultureDateTime = function (date) {
        // Date Format: DD-MM-YYYY HH:MM XX
        // XX is equivalent to 'am' or 'pm'.
        if (date) {
            var dateTimeParts = date.split(' '), dateParts = dateTimeParts[0].split('/'), time = dateTimeParts[1].split(':'), timeIdentifier = dateTimeParts[2];
            var hours = timeIdentifier === 'pm'
                ? Number.parseInt(time[0], 10) + 12
                : Number.parseInt(time[0], 10);
            var minutes = Number.parseInt(time[1], 10), days = Number.parseInt(dateParts[0], 10), months = Number.parseInt(dateParts[1], 10), years = Number.parseInt(dateParts[2], 10);
            return new Date(years, months, days, hours, minutes);
        }
        return new Date();
    };
    Countdown.countdownConfigurationMapping = function (data) {
        var countdownConfiguration = {
            captions: {
                daysText: data.days,
                hoursText: data.hours,
                minutesText: data.minutes,
                secondsText: data.seconds,
                buttonText: data.ctaText,
                titleText: data.title,
                units: data.units
            },
            circleRadius: CollectionUtils.parseNumber(data.radiusOfCircle),
            isClockwiseRotation: data.clockwise,
            startFromZero: data.startFromZero,
            isCircleResponsive: data.responsive,
            showUnits: data.showUnits,
            showImage: data.showImage,
            refreshRate: CollectionUtils.parseNumber(data.responsiveRefreshRate),
            spaceBetweenCircles: CollectionUtils.parseNumber(data.space),
            circleStrokeWidth: CollectionUtils.parseNumber(data.outerStrokeWidth),
            outerStrokeGradient: data.outerStrokeGradient,
            outerStrokeColor: data.outerStrokeColor,
            outerStrokeGradientStopColor: data.outerStrokeGradientStopColor,
            innerStrokeColor: data.innerStrokeColor,
            innerStrokeWidth: CollectionUtils.parseNumber(data.innerStrokeWidth),
            animateTitle: data.animateTitle,
            animationDuration: CollectionUtils.parseNumber(data.animationDuration),
            percent: CollectionUtils.parseNumber(data.percent),
            maxPercent: CollectionUtils.parseNumber(data.maxPercent),
            showZeroOuterStroke: data.showZeroOuterStroke,
            showTitle: data.showTitle,
            showSubtitle: data.showSubtitle,
            showBackground: data.showBackground,
            showInnerStroke: data.showInnerStroke,
            backgroundStroke: data.backgroundStroke,
            backgroundStrokeWidth: CollectionUtils.parseNumber(data.backgroundStrokeWidth),
            backgroundPadding: CollectionUtils.parseNumber(data.backgroundPadding),
            isBackgroundGradient: data.backgroundGradient,
            backgroundColor: data.backgroundColor,
            backgroundGradientStopColor: data.backgroundGradientStopColor,
            backgroundOpacity: CollectionUtils.parseNumber(data.backgroundOpacity),
            toFixed: CollectionUtils.parseNumber(data.toFixed),
            renderOnClick: data.renderOnClick,
            unitsFontSize: CollectionUtils.parseNumber(data.unitsFontSize)
        };
        return countdownConfiguration;
    };
    return Countdown;
}(NgObject));
export { Countdown };
