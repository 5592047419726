import { ViewContainerRef, ComponentFactoryResolver, OnInit, OnDestroy, AfterViewInit, QueryList, EventEmitter, } from '@angular/core';
import { BootstrapViewportService, } from 'amg';
import { CollapsibleAfterViewInit, CollapsibleClick, } from './collapsible.functions/collapsible.functions';
import { CollapsibleResponsiveProperties } from './collapsible.responsive.properties';
import { CollapsibleStaticProperties } from './collapsible.static.properties';
var CollapsibleComponent = /** @class */ (function () {
    function CollapsibleComponent(componentFactoryResolver, bootstrapViewportService) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.bootstrapViewportService = bootstrapViewportService;
        this.cardClicked = new EventEmitter();
    }
    CollapsibleComponent.prototype.ngOnInit = function () {
        var _this = this;
        var viewportSubscriber = this.bootstrapViewportService
            .getViewportSubscriber();
        var viewport = this.bootstrapViewportService.getViewport();
        this.componentCurrentView = this.responsiveProperties[viewport];
        this._click = new CollapsibleClick(this.cardClicked);
        this._collapsed = true;
        this.componentViewportSubscription = viewportSubscriber
            .subscribe(function (newBreakpoint) {
            if (_this.responsiveTimeout) {
                clearTimeout(_this.responsiveTimeout);
                _this.responsiveTimeout = undefined;
            }
            _this.responsiveTimeout = setTimeout(function () {
                _this.componentCurrentView = _this.responsiveProperties[newBreakpoint];
            }, _this.componentCurrentView.refreshRate);
        });
    };
    CollapsibleComponent.prototype.ngOnDestroy = function () {
        this.componentViewportSubscription.unsubscribe();
        if (this.responsiveTimeout) {
            clearTimeout(this.responsiveTimeout);
            this.responsiveTimeout = undefined;
        }
    };
    CollapsibleComponent.prototype.ngAfterViewInit = function () {
        new CollapsibleAfterViewInit(this.componentFactoryResolver, this.componentData, this.responsiveProperties, this.staticProperties, this.componentChildren)
            .ngAfterViewInit();
    };
    CollapsibleComponent.prototype.toggleCollapse = function () {
        this._collapsed = !this._collapsed;
    };
    CollapsibleComponent.prototype.isCollapsed = function () {
        return this._collapsed && this.componentCurrentView.canCollapse;
    };
    CollapsibleComponent.prototype.collapseClass = function () {
        return this.isCollapsed() ? 'closed' : 'opened';
    };
    CollapsibleComponent.prototype.showToggle = function () {
        return ((this.componentCurrentView.canCollapse));
    };
    CollapsibleComponent.prototype.getTitleText = function () {
        return this.staticProperties.title || '';
    };
    CollapsibleComponent.prototype.getSubTitleText = function () {
        return this.staticProperties.subtitle || '';
    };
    CollapsibleComponent.prototype.getToggleText = function () {
        return this._collapsed ? this.componentCurrentView.showText : this.componentCurrentView.hideText;
    };
    CollapsibleComponent.prototype.onSlideClicked = function (source, slide) {
        this._click.slideClick(source, slide);
    };
    return CollapsibleComponent;
}());
export { CollapsibleComponent };
