import { BaseObject } from '../../../BaseObject';
import { Link } from '../html-elements/Link';

export class NavigationBar extends BaseObject {
  private readonly _id: string;
  private readonly _isCollapsible: boolean;
  private readonly _navigationLinks: Link[];
  private readonly _extras: any[];

  constructor(data: any) {
    super('NavigationBar', data);

    this._id = data.element_id;
    this._isCollapsible = data.is_collapsible;

    this._extras = (data.navigation_extras)
      ? this.convertNavigationExtras(data.navigation_extras)
      : [];

    this._navigationLinks = (data.navigation_links)
      ? data.navigation_links
      : [];
  }

  get navigationLinks(): Link[] {
    return this._navigationLinks;
  }

  get extras(): any[] {
    return this._extras;
  }

  get id(): string {
    return this._id;
  }

  get isCollapsible(): boolean {
    return this._isCollapsible;
  }

  private convertNavigationExtras(data: { [key: string]: any }): any[] {
    const navigationExtras = [];

    Object.keys(data).forEach(key => {
      const value = data[key];

      if (key === 'section') {
        // Recursive for every section
        navigationExtras.push(this.convertNavigationExtras(value));
      } else {
        // Push objects which are not section
        navigationExtras.push(value);
      }
    });

    return navigationExtras;
  }
}
