/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./form-header.component";
var styles_FormHeaderComponent = [];
var RenderType_FormHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormHeaderComponent, data: {} });
export { RenderType_FormHeaderComponent as RenderType_FormHeaderComponent };
export function View_FormHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "amg-logo-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", "logo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "amg-logo-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "amg-logo-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formHeader.url; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.formHeader.label; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.formHeader["link"]; _ck(_v, 5, 0, currVal_2); }); }
export function View_FormHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-form-header", [], null, null, null, View_FormHeaderComponent_0, RenderType_FormHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i1.FormHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormHeaderComponentNgFactory = i0.ɵccf("amg-form-header", i1.FormHeaderComponent, View_FormHeaderComponent_Host_0, { formHeader: "formHeader", modalRef: "modalRef" }, {}, []);
export { FormHeaderComponentNgFactory as FormHeaderComponentNgFactory };
