import { PipeTransform } from '@angular/core';
var FormatDatePipe = /** @class */ (function () {
    function FormatDatePipe() {
    }
    FormatDatePipe.prototype.leftPadSingleDigit = function (digit) {
        return digit < 10
            ? "0" + digit.toString()
            : digit.toString();
    };
    FormatDatePipe.prototype.transform = function (dateTime, arg) {
        var released = null;
        var monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        if (!dateTime.toUpperCase().endsWith('Z')) {
            dateTime += 'Z';
        }
        var date = new Date(dateTime);
        released = {
            hour: this.leftPadSingleDigit(date.getHours()),
            minute: this.leftPadSingleDigit(date.getMinutes()),
            day: this.leftPadSingleDigit(date.getDate()),
            month: monthNames[(date.getMonth())],
            year: date.getFullYear().toString()
        };
        return released[arg];
    };
    return FormatDatePipe;
}());
export { FormatDatePipe };
