export enum CardType {
  CalendarEvent = 'CalendarEvent',
  CalendarFixture = 'CalendarFixture',
  Event = 'Event',
  Fixture = 'Fixture',
  Image = 'Image',
  Profile = 'Profile',
  Text = 'Text',
  Video = 'Video',
}
