import { BaseObject } from '../BaseObject';

export class BaseConfiguration extends BaseObject {
  private readonly _apiKey: string;
  private readonly _apiBaseUrl: string;
  private readonly _apiUserId: string;

  public static merge(parentConfig: BaseConfiguration, childConfig: BaseConfiguration): BaseConfiguration {
    return new BaseConfiguration({
      api_key: (childConfig && childConfig.apiKey) ? childConfig.apiKey : parentConfig.apiKey,
      api_url: (childConfig && childConfig.apiBaseUrl) ? childConfig.apiBaseUrl : parentConfig.apiBaseUrl,
      api_user_id: (childConfig && childConfig.apiUserId) ? childConfig.apiUserId : parentConfig.apiUserId
    });
  }

  constructor(data: any) {
    super('BaseConfiguration', data);

    this._apiKey = data.api_key;
    this._apiBaseUrl = data.api_url;
    this._apiUserId = data.api_user_id;
  }

  get apiKey(): string {
    return this._apiKey;
  }

  get apiBaseUrl(): string {
    return this._apiBaseUrl;
  }

  get apiUserId(): string {
    return this._apiUserId;
  }
}
