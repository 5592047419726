import { isDevMode } from '@angular/core';
var BaseObject = /** @class */ (function () {
    function BaseObject(objectType, data) {
        this.data = isDevMode()
            ? data
            : null;
        this._objectType = objectType;
        this._flexibleContentId = data.flexibleContentId || null;
        this._cssClass = (data.amg_style && data.amg_style.class_name_css)
            ? data.amg_style.class_name_css
            : null;
        if (data.amg_payments) {
            this._showWhenUserIsLoggedIn = !!data.amg_payments.show_when_logged_in;
            this._showWhenUserIsLoggedOut = !!data.amg_payments.show_when_logged_out;
        }
        else {
            this._showWhenUserIsLoggedIn = true;
            this._showWhenUserIsLoggedOut = true;
        }
    }
    Object.defineProperty(BaseObject.prototype, "objectType", {
        get: function () {
            return this._objectType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseObject.prototype, "cssClass", {
        get: function () {
            return this._cssClass;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseObject.prototype, "flexibleContentId", {
        get: function () {
            return this._flexibleContentId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseObject.prototype, "showWhenUserIsLoggedIn", {
        get: function () {
            return this._showWhenUserIsLoggedIn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseObject.prototype, "showWhenUserIsLoggedOut", {
        get: function () {
            return this._showWhenUserIsLoggedOut;
        },
        enumerable: true,
        configurable: true
    });
    return BaseObject;
}());
export { BaseObject };
