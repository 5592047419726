import { CardFixtureResponsivePropertiesView } from 'card-fixture';
import { CardImageResponsivePropertiesView } from 'card-image';
import { CardProfileResponsivePropertiesView } from 'card-profile';
import { CardTextResponsivePropertiesView } from 'card-text';
import { CardVideoResponsivePropertiesView } from 'card-video';

export class CardResponsiveViewRegistry {
  public static CardResponsivePropertiesViewRegistry: Map<string, any> = new Map<string,any>([
    ['CardFixtureResponsivePropertiesView', CardFixtureResponsivePropertiesView],
    ['CardImageResponsivePropertiesView', CardImageResponsivePropertiesView],
    ['CardProfileResponsivePropertiesView', CardProfileResponsivePropertiesView],
    ['CardTextResponsivePropertiesView', CardTextResponsivePropertiesView],
    ['CardVideoResponsivePropertiesView', CardVideoResponsivePropertiesView],
  ]);
}
