<ng-container *ngIf="isLoadingData; else loadedDataView">
  <div class="amg-loading-container">
    <span class="loading">
    </span>
  </div>
</ng-container>

<ng-template #loadedDataView>
  <ng-container *ngIf="isFixtureCalendar() && fixtureData">
    <amg-wp-calendar-fixture [data]="fixtureData"
                             [onClickNavRoute]="fixtureOnClickNavRoute"
                             [sendEntryDataOnNavigation]="fixtureSendEntryDataOnNavigation"
                             [apiUrl]="fixtureApiUrl"></amg-wp-calendar-fixture>
  </ng-container>

  <ng-container *ngIf="!isFixtureCalendar() && fixtureData"
                [ngSwitch]="isOfTypeFixture(fixtureData)">
    <amg-wp-game-fixture *ngSwitchCase="true"
                         [data]="fixtureData"
                         [onClickNavRoute]="fixtureOnClickNavRoute"
                         [sendEntryDataOnNavigation]="fixtureSendEntryDataOnNavigation"
                         [apiUrl]="fixtureApiUrl"></amg-wp-game-fixture>
    <amg-wp-event-fixture *ngSwitchCase="false"
                          [data]="fixtureData"
                          [onClickNavRoute]="fixtureOnClickNavRoute"
                          [sendEntryDataOnNavigation]="fixtureSendEntryDataOnNavigation"
                          [apiUrl]="fixtureApiUrl"></amg-wp-event-fixture>
  </ng-container>

  <ng-container *ngIf="isIndividualFixture && !fixtureData">
    <div class="amg-message-container no-fixture">
      <span class="message">
        <span class="text">Sorry, no fixtures found</span>
      </span>
    </div>
  </ng-container>
</ng-template>
