import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CardModule } from 'card';
import { CardsModule } from 'cards';
import { CarouselModule, CarouselFlickityModule } from 'carousel';
import { CalendarModule } from 'calendar';
import { CollapsableModule } from 'collapsible';
import { CountdownModule } from 'countdown';
import { GridModule } from 'grid';
import { CardFixtureModule } from 'card-fixture';

import { UserAuthModule, StreamPaymentScriptsConfig } from 'user-auth';
import { VideoPlayerModule } from 'video-player';

import { HeaderComponent } from './web-components/layout/header/header.component';
import { PageComponent } from './web-components/layout/page/page.component';
import { FooterComponent } from './web-components/layout/footer/footer.component';
import { OverlayComponent } from './web-components/layout/overlay/overlay.component';

import { SectionComponent } from './web-components/section/section.component';
import { SectionsComponent } from './web-components/section/sections/sections.component';

import { ActionLinkComponent } from './web-components/section/elements/html-elements/action-link/action-link.component';
import { IconComponent } from './web-components/section/elements/html-elements/icon/icon.component';
import { InputFieldComponent } from './web-components/section/elements/html-elements/input-field/input-field.component';

import { NavigationLinkComponent } from './web-components/section/elements/html-grouped-elements/navigation-bar/navigation-link/navigation-link.component';
import { AdvertisementComponent } from './web-components/section/elements/html-grouped-elements/advertisement/advertisement.component';
import { NavigationBarComponent } from './web-components/section/elements/html-grouped-elements/navigation-bar/navigation-bar.component';
import { SocialLinksComponent } from './web-components/section/elements/html-grouped-elements/social-links/social-links.component';
import { IconsComponent } from './web-components/section/elements/html-grouped-elements/icons/icons.component';
import { InputFormGroupComponent } from './web-components/section/elements/html-grouped-elements/input-form-group/input-form-group.component';

import { CarouselComponent } from './web-components/section/elements/ng-elements/carousel/carousel.component';
import { CarouselFlickityComponent } from './web-components/section/elements/ng-elements/carousel-flickity/carousel-flickity.component';
import { CalendarComponent } from './web-components/section/elements/ng-elements/calendar/calendar.component';
import { CollapsibleComponent } from './web-components/section/elements/ng-elements/collapsible/collapsible.component';
import { CountdownComponent } from './web-components/section/elements/ng-elements/countdown/countdown.component';
import { GridComponent } from './web-components/section/elements/ng-elements/grid/grid.component';
import { AnywhereVideoCardComponent } from './web-components/section/elements/ng-elements/anywhere-video-card/anywhere-video-card.component';
import { LiveFixtureCardComponent } from './web-components/section/elements/ng-elements/live-fixture-card/live-fixture-card.component';

import { PaymentFieldsGroupComponent } from './web-components/section/elements/payment-elements/payment-fields-group.component';
import { LoginButtonGroupComponent } from './web-components/section/elements/payment-elements/login-button-group/login-button-group.component';
import { UserSignInPageComponent } from './web-components/section/elements/payment-elements/user-sign-in-page/user-sign-in-page.component';
import { VideoPlayerComponent } from './web-components/section/elements/payment-elements/video-player/video-player.component';
import { TextLinksComponent } from './web-components/section/elements/html-grouped-elements/text-links/text-links.component';
import { ContentProjectionComponent } from './web-components/section/elements/ng-elements/content-projection/content-projection.component';
import { TextComponent } from './web-components/section/elements/html-elements/text/text.component';
import { TextGroupComponent } from './web-components/section/elements/html-grouped-elements/text-group/text-group.component';
import { FixtureComponent } from './web-components/section/elements/ng-elements/fixture/fixture.component';
import { FixturesComponent } from './web-components/section/elements/ng-elements/fixtures/fixtures.component';
import { EventFixtureComponent } from './web-components/section/elements/ng-elements/fixture/event-fixture/event-fixture.component';
import { GameFixtureComponent } from './web-components/section/elements/ng-elements/fixture/game-fixture/game-fixture.component';
import { BoxsetComponent } from './web-components/section/elements/ng-elements/boxset/boxset.component';
import { CalendarFixtureComponent } from './web-components/section/elements/ng-elements/fixture/calendar-fixture/calendar-fixture.component';
import { SubscribePackagesComponent } from './web-components/section/elements/payment-elements/subscribe-packages/subscribe-packages.component';


@NgModule({
  declarations: [
    PageComponent,
    SectionComponent,
    ActionLinkComponent,
    IconComponent,
    FooterComponent,
    HeaderComponent,
    CarouselComponent,
    CalendarComponent,
    CollapsibleComponent,
    CountdownComponent,
    GridComponent,
    AdvertisementComponent,
    NavigationBarComponent,
    NavigationLinkComponent,
    InputFieldComponent,
    SocialLinksComponent,
    IconsComponent,
    SectionsComponent,
    PaymentFieldsGroupComponent,
    LoginButtonGroupComponent,
    UserSignInPageComponent,
    VideoPlayerComponent,
    InputFormGroupComponent,
    OverlayComponent,
    TextLinksComponent,
    ContentProjectionComponent,
    TextComponent,
    TextGroupComponent,
    FixtureComponent,
    FixturesComponent,
    EventFixtureComponent,
    GameFixtureComponent,
    BoxsetComponent,
    CalendarFixtureComponent,
    SubscribePackagesComponent,
    CarouselFlickityComponent,
    AnywhereVideoCardComponent,
    LiveFixtureCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CardModule,
    CarouselModule,
    CollapsableModule,
    GridModule,
    CalendarModule,
    CountdownModule,
    UserAuthModule,
    VideoPlayerModule,
    CardFixtureModule,
    CardsModule,
    CarouselFlickityModule
  ],
  exports: [
    PageComponent,
    SectionComponent,
    ActionLinkComponent,
    IconComponent,
    FooterComponent,
    HeaderComponent,
    CarouselComponent,
    CalendarComponent,
    CollapsibleComponent,
    CountdownComponent,
    GridComponent,
    AdvertisementComponent,
    NavigationBarComponent,
    NavigationLinkComponent,
    InputFieldComponent,
    SocialLinksComponent,
    IconsComponent,
    SectionsComponent,
    OverlayComponent,
    ContentProjectionComponent,
    TextComponent,
    TextGroupComponent,
    FixtureComponent,
    FixturesComponent,
    EventFixtureComponent,
    GameFixtureComponent,
    BoxsetComponent,
    SubscribePackagesComponent,
    InputFormGroupComponent,
    CarouselFlickityComponent,
    AnywhereVideoCardComponent,
    LiveFixtureCardComponent
  ]
})
export class WordpressComponentsModule {
  static forRoot(clientOptions: StreamPaymentScriptsConfig): ModuleWithProviders {
    return {
      ngModule: WordpressComponentsModule,
      providers: [
        {
          provide: 'videoPlayerModuleConfig',
          useValue: (clientOptions.streamPayments)
            ? clientOptions
            : null
        }
      ]
    };
  }
}
