import { CardRegistry, CardResponsiveInstantiate, } from 'card';
var GridOnChildrenAdded = /** @class */ (function () {
    function GridOnChildrenAdded(componentFactoryResolver, componentData, responsiveProperties, staticProperties, componentChildren) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.componentData = componentData;
        this.responsiveProperties = responsiveProperties;
        this.staticProperties = staticProperties;
        this.componentChildren = componentChildren;
        this.updateFactory();
    }
    GridOnChildrenAdded.prototype.updateFactory = function () {
        var component = CardRegistry.CardComponentRegistry.get("Card" + this.staticProperties.cardType + "Component");
        this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.cardResponsive = CardResponsiveInstantiate.instantiate(this.staticProperties.cardType, {
            xs: this.responsiveProperties.xs.cardView,
            sm: this.responsiveProperties.sm.cardView,
            md: this.responsiveProperties.md.cardView,
            lg: this.responsiveProperties.lg.cardView,
            xl: this.responsiveProperties.xl.cardView,
        });
    };
    GridOnChildrenAdded.prototype.cast = function (o) {
        return o;
    };
    GridOnChildrenAdded.prototype.runUpdate = function () {
        var _this = this;
        this.updateFactory();
        this.componentChildren.forEach(function (viewContainerRef, i) {
            if (viewContainerRef.length === 0) {
                var componentRef = viewContainerRef.createComponent(_this.componentFactory);
                var componentRefInstance = componentRef.instance;
                componentRefInstance.componentData = _this.componentData[i];
                componentRefInstance.responsiveProperties = _this.cardResponsive;
                // componentRefInstance.staticProperties = this.staticProperties.cardStatic;
                componentRefInstance.staticProperties = undefined;
                componentRef.changeDetectorRef.detectChanges();
            }
        });
    };
    GridOnChildrenAdded.prototype.ngAfterViewInit = function () {
        this.runUpdate();
    };
    GridOnChildrenAdded.prototype.onChildrenAdded = function () {
        this.runUpdate();
    };
    GridOnChildrenAdded.prototype.ngOnChanges = function (componentData, responsiveProperties, staticProperties, componentChildren) {
        this.componentData = componentData;
        this.responsiveProperties = responsiveProperties;
        this.staticProperties = staticProperties;
        this.componentChildren = componentChildren;
        this.runUpdate();
    };
    return GridOnChildrenAdded;
}());
export { GridOnChildrenAdded };
