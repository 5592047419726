/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./collapsible.component";
import * as i3 from "../../../amg/src/lib/bootstrap.viewport.service";
var styles_CollapsibleComponent = [];
var RenderType_CollapsibleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CollapsibleComponent, data: {} });
export { RenderType_CollapsibleComponent as RenderType_CollapsibleComponent };
function View_CollapsibleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_CollapsibleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSlideClicked("click", _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, [[1, 3], ["componentChild", 2]], null, 0, null, View_CollapsibleComponent_2))], null, null); }
export function View_CollapsibleComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { componentChildren: 1 }), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "card amg-collapsible"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 8, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 7, "div", [["class", "d-flex flex-row justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "d-flex flex-column amg-collapsible-titles"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "h2", [["class", "amg-collapsible-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "h3", [["class", "amg-collapsible-subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "btn"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCollapse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), (_l()(), i0.ɵeld(12, 0, null, null, 4, "div", [["class", "card-body collapse"]], null, null, null, null, null)), i0.ɵdid(13, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(14, { show: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapsibleComponent_1)), i0.ɵdid(16, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "card amg-collapsible"; var currVal_1 = _co.collapseClass(); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_6 = "card-body collapse"; var currVal_7 = _ck(_v, 14, 0, !_co.isCollapsed()); _ck(_v, 13, 0, currVal_6, currVal_7); var currVal_8 = _co.componentData; _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getTitleText(); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.getSubTitleText(); _ck(_v, 9, 0, currVal_3); var currVal_4 = !_co.showToggle(); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.getToggleText(); _ck(_v, 11, 0, currVal_5); }); }
export function View_CollapsibleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-collapsible", [], null, null, null, View_CollapsibleComponent_0, RenderType_CollapsibleComponent)), i0.ɵdid(1, 4440064, null, 0, i2.CollapsibleComponent, [i0.ComponentFactoryResolver, i3.BootstrapViewportService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CollapsibleComponentNgFactory = i0.ɵccf("amg-collapsible", i2.CollapsibleComponent, View_CollapsibleComponent_Host_0, { componentData: "componentData", responsiveProperties: "responsiveProperties", staticProperties: "staticProperties" }, { cardClicked: "cardClicked" }, []);
export { CollapsibleComponentNgFactory as CollapsibleComponentNgFactory };
