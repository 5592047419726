import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { of } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { PaymentVideoPlayer } from 'wordpress-adapter';
import { ApiService } from '../../../../../shared/api/api.service';
import { DataService } from '../../../../../shared/data/data.service';
var VideoPlayerComponent = /** @class */ (function () {
    function VideoPlayerComponent(apiService, dataService, activatedRoute, router) {
        this.apiService = apiService;
        this.dataService = dataService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.class = 'amg-video-player';
        this.isInitialised = false;
        this.triggerChange = new EventEmitter();
        this.triggerDisabledByDrm = new EventEmitter();
    }
    VideoPlayerComponent.generateBaseConfiguration = function (data) {
        return {
            apiKey: data.apiKey,
            apiUrl: data.apiBaseUrl,
            apiUserId: data.apiUserId
        };
    };
    VideoPlayerComponent.removeQueryParams = function (url) {
        var index = url.indexOf('?');
        return index !== -1
            ? url.substring(0, index)
            : url;
    };
    VideoPlayerComponent.getMediaEntryId = function (data) {
        return (data && data.mediaData && data.mediaData.entryId)
            ? data.mediaData.entryId
            : null;
    };
    VideoPlayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.canInitialiseVideoPlayer = ((!this.videoPlayerConfiguration) // Video Player without DRM (backward compatible)
            || (!this.isDrmEntry(this.videoPlayer.kalturaPlayerConfiguration.entryId)) // Video Player without DRM
            || (this.isDrmEntry(this.videoPlayer.kalturaPlayerConfiguration.entryId) // Video Player with DRM
                && this.videoPlayerConfiguration.isDesktopDevice));
        this.triggerDisabledByDrm.emit(!this.canInitialiseVideoPlayer);
        if (this.canInitialiseVideoPlayer) {
            this.isInitialised = true;
            this.initialiseVideoPlayer();
            this.routerSubscription = this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function (event) {
                if (_this.videoPlayer.reloadPlayerOnSamePageNavigation) {
                    _this.initialiseVideoPlayer(true);
                }
            });
        }
    };
    VideoPlayerComponent.prototype.ngOnDestroy = function () {
        if (this.entryIdObservable) {
            this.entryIdObservable.unsubscribe();
        }
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
        this.dataService.setEntryObject(null);
    };
    VideoPlayerComponent.prototype.getConfiguration = function () {
        return tslib_1.__assign({}, (this.videoPlayer.baseConfiguration
            ? { anywhereConfig: VideoPlayerComponent.generateBaseConfiguration(this.videoPlayer.baseConfiguration) }
            : {}), { kalturaPlayerConfig: tslib_1.__assign({ apiUrl: this.videoPlayer.kalturaPlayerConfiguration.apiUrl, playerId: this.videoPlayer.kalturaPlayerConfiguration.playerId, partnerId: this.videoPlayer.kalturaPlayerConfiguration.partnerId, uiConfId: this.videoPlayer.kalturaPlayerConfiguration.uiConfigId }, this.generateOptionalKalturaConfiguration()) });
    };
    VideoPlayerComponent.prototype.getPlayButtonConfig = function () {
        return {
            playButton: {
                audio: this.videoPlayer.audioPlayButton.url,
                video: this.videoPlayer.videoPlayButton.url
            }
        };
    };
    VideoPlayerComponent.prototype.getCss = function () {
        return this.videoPlayer.cssClass;
    };
    VideoPlayerComponent.prototype.getVideoPlayerData = function () {
        return this.videoPlayerData;
    };
    VideoPlayerComponent.prototype.getPlaylistUrl = function () {
        return this.playlistUrl;
    };
    VideoPlayerComponent.prototype.getEntryId = function () {
        return this.entryId;
    };
    VideoPlayerComponent.prototype.getLiveLoginOverlay = function () {
        return this.videoPlayer.liveLoginOverlay;
    };
    VideoPlayerComponent.prototype.getDrmMessage = function () {
        return this.videoPlayer.drmMessage;
    };
    VideoPlayerComponent.prototype.onPlaylistReady = function () {
        this.triggerChange.emit('playlist');
    };
    VideoPlayerComponent.prototype.onEntryChange = function (entryId) {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                entry: entryId
            },
            queryParamsHandling: 'merge',
            preserveFragment: true
        });
    };
    VideoPlayerComponent.prototype.isDrmEntry = function (entry) {
        return this.videoPlayerConfiguration.drmEntries.find(function (drmEntry) { return drmEntry === entry; }) !== undefined;
    };
    VideoPlayerComponent.prototype.generateOptionalKalturaConfiguration = function () {
        return tslib_1.__assign({}, (this.videoPlayer.kalturaPlayerConfiguration.entryId
            ? { entryId: this.videoPlayer.kalturaPlayerConfiguration.entryId }
            : {}), (this.videoPlayer.kalturaPlayerConfiguration.forcePlayerEmbed
            ? { forcePlayerEmbed: this.videoPlayer.kalturaPlayerConfiguration.forcePlayerEmbed }
            : {}), (this.videoPlayer.kalturaPlayerConfiguration.flashVars
            ? { flashvars: this.videoPlayer.kalturaPlayerConfiguration.flashVars }
            : {}));
    };
    VideoPlayerComponent.prototype.initialiseVideoPlayer = function (isRestartingPlayer) {
        var _this = this;
        var userInteractedObject = this.getInteractedObject();
        if (userInteractedObject) {
            this.videoPlayerBaseConfiguration = this.videoPlayer.baseConfiguration;
            this.videoPlayerKalturaConfiguration = this.videoPlayer.kalturaPlayerConfiguration;
            this.playlistUrl = userInteractedObject.url;
            this.videoPlayerData = userInteractedObject.data;
            this.entryId = userInteractedObject.entryId;
        }
        else {
            this.videoPlayerBaseConfiguration = this.videoPlayer.baseConfiguration;
            this.videoPlayerKalturaConfiguration = this.videoPlayer.kalturaPlayerConfiguration;
        }
        if (isRestartingPlayer) {
            this.isInitialised = false;
            of(null)
                .pipe(delay(1))
                .subscribe(function (value) {
                _this.isInitialised = true;
            });
        }
    };
    VideoPlayerComponent.prototype.getInteractedObject = function () {
        var interactedObject = this.dataService.userInteractedObject;
        if (interactedObject) {
            var entryId = VideoPlayerComponent.getMediaEntryId(interactedObject.data);
            var url = interactedObject.fixtureUrl
                ? VideoPlayerComponent.removeQueryParams(interactedObject.fixtureUrl)
                : interactedObject.playlistUrl;
            var data = interactedObject.data;
            return { entryId: entryId, url: url, data: data };
        }
        else {
            // Get data from URL params
            var snapshotEntryId = this.activatedRoute.snapshot.queryParams.entry, snapshotPlaylistUrl = this.activatedRoute.snapshot.queryParams.playlist, snapshotFixtureUrl = this.activatedRoute.snapshot.queryParams.fixture;
            if (snapshotEntryId || snapshotPlaylistUrl || snapshotFixtureUrl) {
                var entryId = snapshotEntryId || null;
                var url = null;
                if (snapshotPlaylistUrl) {
                    url = this.dataService.decodeUrl(snapshotPlaylistUrl);
                }
                else if (snapshotFixtureUrl) {
                    url = VideoPlayerComponent.removeQueryParams(this.dataService.decodeUrl(snapshotFixtureUrl));
                }
                return { entryId: entryId, url: url, data: null };
            }
        }
        return null;
    };
    return VideoPlayerComponent;
}());
export { VideoPlayerComponent };
