import * as tslib_1 from "tslib";
import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { NgObject } from './NgObject';
var Calendar = /** @class */ (function (_super) {
    tslib_1.__extends(Calendar, _super);
    function Calendar(data) {
        var _this = _super.call(this, 'Calendar', data) || this;
        _this._staticProperties = data.static_properties;
        _this._calendarConfiguration = CollectionUtils
            .flattenArrayOfResponsiveConfiguration(data.ui_conf, Calendar.calendarConfigurationMapping);
        return _this;
    }
    Object.defineProperty(Calendar.prototype, "staticProperties", {
        get: function () {
            return this._staticProperties;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Calendar.prototype, "calendarConfiguration", {
        get: function () {
            return this._calendarConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Calendar.calendarConfigurationMapping = function (data) {
        var calendarConfiguration = {
            tab: data.tab,
            tabs: CollectionUtils.parseNumber(data.tabs),
            tabsWhenHovered: CollectionUtils.parseNumber(data.tabsWhenHov),
            refreshRate: CollectionUtils.parseNumber(data.refreshRate),
            tabNavText: [
                data.navText.after,
                data.navText.before
            ]
        };
        return calendarConfiguration;
    };
    return Calendar;
}(NgObject));
export { Calendar };
