import * as tslib_1 from "tslib";
import { BaseObject } from '../BaseObject';
var KalturaConfiguration = /** @class */ (function (_super) {
    tslib_1.__extends(KalturaConfiguration, _super);
    function KalturaConfiguration(data) {
        var _this = _super.call(this, 'KalturaConfiguration', data) || this;
        if (data.kaltura_player_configuration) {
            data.kaltura_player_configuration.forEach(function (config) {
                if (config.api_url) {
                    _this._apiUrl = config.api_url;
                }
                else if (config.player_id) {
                    _this._playerId = config.player_id;
                }
                else if (config.partner_id) {
                    _this._partnerId = config.partner_id;
                }
                else if (config.ui_config_id) {
                    _this._uiConfigId = config.ui_config_id;
                }
                else if (config.entry_id) {
                    _this._entryId = config.entry_id;
                }
                else if (config.key_session) {
                    _this._ks = config.key_session;
                }
                else if (config.force_player_embed) {
                    _this._forcePlayerEmbed = config.force_player_embed;
                }
                else if (config.flashVars) {
                    _this._flashVars = KalturaConfiguration.convertFlashVars(config.flashVars);
                }
            });
        }
        return _this;
    }
    KalturaConfiguration.convertFlashVars = function (data) {
        var flashVars = {};
        data.forEach(function (flashVar) {
            var key = flashVar.flashVar, valueData = flashVar.data;
            var flashVarValue = null;
            if (Array.isArray(valueData)) {
                flashVarValue = {};
                valueData.forEach(function (dataObject) {
                    var dataObjectKeys = Object.keys(dataObject);
                    if (dataObjectKeys.length > 0) {
                        var dataObjectKey = dataObjectKeys[0];
                        flashVarValue[dataObjectKey] = dataObject[dataObjectKey];
                    }
                });
            }
            else if (valueData && typeof valueData === 'object') {
                flashVarValue = {};
                console.error('Object is not yet supported!');
            }
            else {
                flashVarValue = valueData;
            }
            flashVars[key] = flashVarValue;
        });
        return flashVars;
    };
    Object.defineProperty(KalturaConfiguration.prototype, "forcePlayerEmbed", {
        get: function () {
            return this._forcePlayerEmbed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KalturaConfiguration.prototype, "flashVars", {
        get: function () {
            return this._flashVars;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KalturaConfiguration.prototype, "apiUrl", {
        get: function () {
            return this._apiUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KalturaConfiguration.prototype, "playerId", {
        get: function () {
            return this._playerId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KalturaConfiguration.prototype, "partnerId", {
        get: function () {
            return this._partnerId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KalturaConfiguration.prototype, "uiConfigId", {
        get: function () {
            return this._uiConfigId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KalturaConfiguration.prototype, "entryId", {
        get: function () {
            return this._entryId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KalturaConfiguration.prototype, "ks", {
        get: function () {
            return this._ks;
        },
        enumerable: true,
        configurable: true
    });
    return KalturaConfiguration;
}(BaseObject));
export { KalturaConfiguration };
