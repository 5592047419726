import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Self } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientRequestAdapter } from './adapter/ClientRequestAdapter';
import { ClientRequest } from './api/client/ClientRequest';
import { ClientResponse } from './api/client/ClientResponse';
import { CLIENT_OPTIONS, ClientOptions } from './config/client-options';

@Injectable({
  providedIn: 'root'
})
export class WordpressAdapterService {

  constructor(@Inject(CLIENT_OPTIONS) @Self() private clientOptions: ClientOptions,
              private http: HttpClient) {

  }

  request<D>(request: any | ClientRequest<D>, endpoint?: string): Observable<ClientResponse<D>> {
    if (request instanceof ClientRequest) {
      return new ClientRequestAdapter(this.http).transmit<D>(request, this.clientOptions);
    } else {
      return new ClientRequestAdapter(this.http).transmit<D>(ClientRequest.generateClientRequest<D>(request, endpoint), this.clientOptions);
    }
  }
}
