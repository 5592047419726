<div #carouselContainer class="carousel-container">
  <ng-container *ngIf="carouselConfig.carouselType === 'card'">
    <amg-video-card *ngFor="let slide of carouselSlides;"
                    [cardData]="slide"
                    [cardConfiguration]="cardConfiguration"
                    (triggerClick)="onCardClick($event)">

    </amg-video-card>
  </ng-container>
  <ng-container *ngIf="carouselConfig.carouselType === 'boxset'">

  </ng-container>
</div>
