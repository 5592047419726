import { OnInit } from '@angular/core';
import { StreamPaymentsHooks } from '../shared/data/stream-payments.model';
import { UserAuthService } from '../user-auth.service';
var SubscriptionPackagesComponent = /** @class */ (function () {
    function SubscriptionPackagesComponent(userAuthService) {
        this.userAuthService = userAuthService;
        this.class = 'amg-subscription-packages';
    }
    SubscriptionPackagesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userAuthService.hookStreamPayments(StreamPaymentsHooks.getSubscriptionPackageList, null)
            .then(function (subscriptionPackages) {
            if (subscriptionPackages && subscriptionPackages.SubscriptionPlanOptions) {
                _this.subscriptionPackages = subscriptionPackages
                    .SubscriptionPlanOptions;
            }
        });
    };
    return SubscriptionPackagesComponent;
}());
export { SubscriptionPackagesComponent };
