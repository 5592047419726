import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { LiveFixtureCard } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-live-fixture-card',
  templateUrl: './live-fixture-card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LiveFixtureCardComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-live-fixture-card';
  @HostBinding('attr.data-is-data-available') isDataAvailable = false;
  @Input() videoCard: LiveFixtureCard;

  public cardData: any;

  public isLoading: boolean;
  public isError: boolean;

  private apiUrl: string;
  private apiDataObservable: Subscription;

  constructor(private httpClient: HttpClient) {
    this.isLoading = true;
    this.isError = false;
  }

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.initialiseCard();
    this.triggerApiCall(this.apiUrl);
  }

  ngOnDestroy(): void {
    if (this.apiDataObservable) {
      this.apiDataObservable.unsubscribe();
    }
  }

  getCss(): string {
    return this.videoCard.cssClass;
  }

  private initialiseCard(): void {
    this.cardData = null;
    this.apiUrl = this.videoCard.fixtureApiUrl;
  }

  private triggerApiCall(apiUrl: string): void {
    this.apiDataObservable = this.httpClient.get(apiUrl).subscribe((data: any) => {
      if (data && data.fixtures && data.fixtures.length > 0) {
        this.cardData = data.fixtures[0];
        this.isDataAvailable = true;
      }

      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.isError = true;
    });
  }
}
