import { OnInit } from '@angular/core';
import { TextGroup } from 'wordpress-adapter';
var TextGroupComponent = /** @class */ (function () {
    function TextGroupComponent() {
        this.class = 'amg-text-group';
    }
    TextGroupComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
    };
    TextGroupComponent.prototype.getCss = function () {
        return this.textGroup.cssClass;
    };
    TextGroupComponent.prototype.getTextFields = function () {
        return this.textGroup.textGroup;
    };
    return TextGroupComponent;
}());
export { TextGroupComponent };
