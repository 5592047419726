import { OnInit } from '@angular/core';
var CallToActionComponent = /** @class */ (function () {
    function CallToActionComponent() {
    }
    CallToActionComponent.prototype.ngOnInit = function () {
        this.title = this.getTitle();
        if (this.callToAction.call_to_action) {
            this.callToAction = this.callToAction.call_to_action;
        }
        this.forgotPassword = this.getForgotPassword();
        this.linkUrl = this.getLinkUrl();
        this.label = this.getLabel();
    };
    CallToActionComponent.prototype.getLinkUrl = function () {
        return (this.callToAction.path)
            ? this.callToAction.path
            : (this.callToAction.external_link)
                ? this.callToAction.external_link
                : null;
    };
    CallToActionComponent.prototype.getForgotPassword = function () {
        return (this.callToAction.forgot_password)
            ? this.callToAction.forgot_password :
            null;
    };
    CallToActionComponent.prototype.getLabel = function () {
        return (this.callToAction.label)
            ? this.callToAction.label
            : null;
    };
    CallToActionComponent.prototype.getTitle = function () {
        return (this.callToAction.title)
            ? this.callToAction.title
            : null;
    };
    return CallToActionComponent;
}());
export { CallToActionComponent };
