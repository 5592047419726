import {HttpClient} from '@angular/common/http';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ComponentFactoryResolver, ElementRef,
  EventEmitter,
  HostListener, Inject,
  Input,
  OnDestroy,
  OnInit, Optional,
  Output,
  QueryList, ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';

import {BootstrapViewportEnum, BootstrapViewportService} from 'amg';

import {CardType} from 'card';

import {BehaviorSubject, Observable, Subscription} from 'rxjs';

import {
  GridColumns,
  GridData,
  GridDataAnywhere,
  GridDataWordpress,
  GridHover,
  GridOnChildrenAdded
} from './grid.functions/grid.functions';

import {GridResponsiveProperties} from './grid.responsive.properties';
import {GridResponsivePropertiesView} from './grid.responsive.properties.view';

import {GridSource} from './grid.source.enum';
import {GridFilter, GridStaticProperties, SearchBarProperties} from './types/GridStaticProperties';
import {FilterByDate, FilterByDropdown, SearchTrigger} from './types/SearchTrigger';
import {NavigationStart, Router} from '@angular/router';
import {AnywhereUtils, UrlUtils} from 'amg-fe-utils';

export interface GridConfig {
  [key: string]: any;

  useCacheOnGridClick?: boolean;
}

@Component({
  selector: 'amg-grid',
  templateUrl: './grid.component.html',
  styles: []
})
export class GridComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() gridStaticProperties: GridStaticProperties;
  @Input() componentData: Array<any>;
  @Input() responsiveProperties: GridResponsiveProperties;
  @Input() searchObservable: Observable<string>;
  @Input() defaultSearchValue: string;
  @ViewChild('gridElement') gridElement: ElementRef;
  @ViewChildren('componentChild', {read: ViewContainerRef}) componentChildren: QueryList<ViewContainerRef>;
  componentChildrenSubscription: Subscription;
  dataSubscription: Subscription;
  componentCurrentView: GridResponsivePropertiesView;
  @Output() cardClicked = new EventEmitter<any>();
  @Output() cardHovered = new EventEmitter<any>();
  @Output() triggerSearch = new EventEmitter<SearchTrigger>();
  @Output() updateSearchUrl = new EventEmitter<any>();
  @Output() triggerGridReRender: EventEmitter<boolean>;

  public searchTextSubject: BehaviorSubject<string>;
  private triggerFilterResetEmitter: EventEmitter<boolean>;

  private rawData: Array<any> = [];
  private loadingState: BehaviorSubject<boolean>;

  private gridColumns: GridColumns;
  private gridData: GridData;
  private gridHover: GridHover;
  private gridOnChildrenAdded: GridOnChildrenAdded;

  private searchSubscription: Subscription;
  private filters: { search: { value: string; key?: string; }; dropdown: { [id: number]: GridFilter } };

  private cachedUrlForClick: string = null;

  constructor(@Optional() @Inject('videoPlayerModuleConfig') private config: GridConfig,
              private componentFactoryResolver: ComponentFactoryResolver,
              private bootstrapViewportService: BootstrapViewportService,
              private http: HttpClient,
              private router: Router) {
    this.filters = {
      search: {value: ''},
      dropdown: {}
    };
    this.loadingState = new BehaviorSubject<boolean>(false);
    this.searchTextSubject = new BehaviorSubject('');
    this.triggerFilterResetEmitter = new EventEmitter<boolean>();
    this.triggerGridReRender = new EventEmitter<boolean>();
  }

  private static generateGridStaticProperties(gridStaticProperties: GridStaticProperties): GridStaticProperties {
    return {
      cardType: gridStaticProperties.cardType,
      pageSource: gridStaticProperties.pageSource,
      pageUrl: gridStaticProperties.pageUrl,

      pageStart: gridStaticProperties.pageStart || 0,
      pageSize: gridStaticProperties.pageSize || 12,

      pagination: gridStaticProperties.pagination || false,
      loadMore: !!gridStaticProperties.loadMore,
      loadMoreText: gridStaticProperties.loadMoreText || 'More...',

      messageOnEmptyDataset: gridStaticProperties.messageOnEmptyDataset || 'Sorry, no results found',

      ...((gridStaticProperties.searchBarProperties)
        ? {searchBarProperties: gridStaticProperties.searchBarProperties}
        : {})
    };
  }

  @HostListener('window:scroll', ['$event'])
  preloadPages(event) {
    if (this.componentCurrentView.autoload) {
      if (this.componentChildren.last) {
        const bounding = this.componentChildren.last.element.nativeElement.parentElement.getBoundingClientRect();
        const target = (
          this.componentChildren.last.element.nativeElement.parentElement.offsetHeight * (this.componentCurrentView.autoloadDistance + 1)
        );
        if (bounding.top <= target) {
          const fetchData = this.gridData.fetchData(this.filters, false, this.isPaginationEnabled());
          if (fetchData && fetchData.url) {
            this.cachedUrlForClick = fetchData.url;
          } else {
            this.cachedUrlForClick = null;
          }
        }
      }
    }
  }

  ngOnInit() {
    this.cachedUrlForClick = null;
    const viewport: BootstrapViewportEnum = this.bootstrapViewportService.getViewport();
    this.componentCurrentView = this.responsiveProperties[viewport] as GridResponsivePropertiesView;

    if (this.defaultSearchValue
      && this.gridStaticProperties.searchBarProperties
      && this.gridStaticProperties.searchBarProperties.searchField) {
      this.filters.search = {
        value: this.defaultSearchValue,

        ...(this.gridStaticProperties.searchBarProperties.searchField.key
          ? {key: this.gridStaticProperties.searchBarProperties.searchField.key}
          : {})
      };
    }

    this.gridStaticProperties = GridComponent.generateGridStaticProperties(this.gridStaticProperties);
    this.gridColumns = new GridColumns(this.componentCurrentView);
    this.gridHover = new GridHover(this.cardHovered);
  }

  ngOnDestroy() {
    this.componentChildrenSubscription.unsubscribe();

    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    this.gridData.onDestroy();
    this.cachedUrlForClick = null;
  }

  ngAfterViewInit() {
    this.gridOnChildrenAdded = new GridOnChildrenAdded(
      this.componentFactoryResolver,
      this.componentData,
      this.responsiveProperties,
      this.gridStaticProperties,
      this.componentChildren
    );

    this.componentChildrenSubscription = this.componentChildren.changes
      .subscribe(() => {
        this.gridOnChildrenAdded.onChildrenAdded();
      });

    if (this.gridStaticProperties.pageSource === GridSource.Wordpress) {
      this.gridData = new GridDataWordpress(
        this.http,
        this.componentData,
        this.responsiveProperties,
        this.gridStaticProperties,
        this.componentChildren,
        this.filters,
        this.rawData,
        this.loadingState
      );
    } else {
      this.gridStaticProperties.cardType = CardType.Video;

      this.gridData = new GridDataAnywhere(
        this.http,
        this.componentData,
        this.responsiveProperties,
        this.gridStaticProperties,
        this.componentChildren,
        this.filters,
        this.rawData,
        this.loadingState,
        this.triggerGridReRender
      );
    }

    this.gridData.setCurrentView('resize', this.componentCurrentView);

    if (this.searchObservable) {
      this.searchSubscription = this.searchObservable.subscribe(value => {
        this.filters.search.value = value;
        this.searchTextSubject.next(value);
        const fetchData = this.gridData.fetchData(this.filters, true, this.isPaginationEnabled());
        if (fetchData && fetchData.url) {
          this.cachedUrlForClick = fetchData.url;
        } else {
          this.cachedUrlForClick = null;
        }
      });
    } else {
      const fetchData = this.gridData.fetchData(this.filters, true, this.isPaginationEnabled());
      if (fetchData && fetchData.url) {
        this.cachedUrlForClick = fetchData.url;
      } else {
        this.cachedUrlForClick = null;
      }
    }
  }

  triggerSearchFilter(value: SearchTrigger): void {
    if (value.type === 'search') {
      this.filters.search = {
        value: value.searchData.value,

        ...(value.searchData.key ? {key: value.searchData.key} : {})
      };
    } else {
      if (value.type === 'filter') {
        this.setDropdownSearchFilter(value.filterData);
      } else {
        this.setDateSearchFilter(value.dateData);
      }
    }

    const searchObject = this.triggerApiCall();

    if (searchObject) {
      this.updateSearchUrl.emit(searchObject);
    } else {
      this.updateSearchUrl.emit({});
    }
  }

  triggerApiCall(): any {
    const fetchData = this.gridData.fetchData(this.filters, true, this.isPaginationEnabled());
    if (fetchData && fetchData.url) {
      this.cachedUrlForClick = fetchData.url;
    } else {
      this.cachedUrlForClick = null;
    }
    return fetchData;
  }

  getSearchBarProperties(): SearchBarProperties {
    return this.gridStaticProperties.searchBarProperties;
  }

  isSearchEnabled(): boolean {
    return this.gridStaticProperties.searchBarProperties
      && Object.keys(this.gridStaticProperties.searchBarProperties).length > 0;
  }

  isLoadMoreEnabled(): boolean {
    return (
      (!this.componentCurrentView.autoload)
      && (this.gridStaticProperties.loadMore)
      && (this.componentData.length > 0)
      && (!this.gridData.getIsLimitReached())
      && (!this.gridStaticProperties.pagination)
    );
  }

  isPaginationEnabled(): boolean {
    return !!this.gridStaticProperties.pagination;
  }

  onPageChangeClick(pageNumber: number): void {
    const fetchData = this.gridData.fetchData(this.filters, false, this.isPaginationEnabled(), pageNumber - 1);
    if (fetchData && fetchData.url) {
      this.cachedUrlForClick = fetchData.url;
    } else {
      this.cachedUrlForClick = null;
    }

    if (fetchData) {
      this.updateSearchUrl.emit(fetchData);
    } else {
      this.updateSearchUrl.emit({});
    }
  }

  getPaginationConfig(): { itemsPerPage: number; currentPage: number; totalItems?: number; } {
    return this.gridData.getPagingData();
  }

  getCol(i: number): any {
    return this.gridColumns.getColumn();
  }

  slideHover(source: string, i: number, item ?: any) {
    const slide = item ? (item.raw || this.componentData[i]) : this.componentData[i];
    this.gridHover.slideHover(source, i, slide);
  }

  slideHoverOut(source: string, i: number, item ?: any) {
    this.gridHover.slideHoverOut(source, i);
  }

  private getSlideItem(i: number, item?: any): any {
    if (!item && this.componentData[i]) {
      item = this.componentData[i];
    }

    if (item.raw) {
      item = item.raw;
    }

    return item;
  }

  slideClick(source: string, i: number, item ?: any) {
    const slide = this.getSlideItem(i, item);

    if (slide.mediaData && this.cachedUrlForClick && this.config && this.config.useCacheOnGridClick) {
      this.router.navigate(['/video'], {
        queryParams: {
          playlist: btoa(this.cachedUrlForClick),
          entry: slide.mediaData.entryId
        }
      });
    } else {
      this.cardClicked.emit(slide);
    }
  }

  onClickLoadMore() {
    const fetchData = this.gridData.fetchData(this.filters, false, this.isPaginationEnabled());
    if (fetchData && fetchData.url) {
      this.cachedUrlForClick = fetchData.url;
    } else {
      this.cachedUrlForClick = null;
    }
  }

  isLoadingData(): boolean {
    return this.gridData
      ? this.gridData.getIsLoading()
      : true;
  }

  public onFilterReset() {
    // Trigger only when something is set.
    if (this.filters.search.value || Object.keys(this.filters.dropdown).length > 0) {
      this.filters.search.value = '';
      this.searchTextSubject.next('');
      this.filters.dropdown = {};

      const fetchData = this.gridData.fetchData(this.filters, true, this.isPaginationEnabled());
      if (fetchData && fetchData.url) {
        this.cachedUrlForClick = fetchData.url;
      } else {
        this.cachedUrlForClick = null;
      }
    }
  }

  getTriggerFilterResetAsObservable(): Observable<boolean> {
    return this.triggerFilterResetEmitter.asObservable();
  }

  getSearchObservable(): Observable<string> {
    return this.searchTextSubject.asObservable();
  }

  public getLoadingStateAsObservable(): Observable<boolean> {
    return this.loadingState.asObservable();
  }

  private setDropdownSearchFilter(filterData: FilterByDropdown): void {
    if (filterData.data.value) {
      this.filters.dropdown[filterData.id] = filterData.data;
    } else {
      const {[filterData.id]: removedFilter, ...remainingFilters} = this.filters.dropdown;

      this.filters.dropdown = remainingFilters;
    }
  }

  private setDateSearchFilter(dateData: FilterByDate): void {
    // TODO:
  }
}
