export enum StreamPaymentsHooks {
  init = 'init',
  doContactSubmission = 'doContactSubmission',
  doLogin = 'doLogin',
  doLoginPost = 'doLoginPost',
  doLogout = 'doLogout',
  getKSession = 'getKSession',
  getSessionState = 'getSessionState',
  getSubscriptionPackageList = 'getSubscriptionPackageList',
  isEmailAddressRegistered = 'isEmailAddressRegistered',
  isEmailAddressValid = 'isEmailAddressValid',
  isVoucherCodeValid = 'isVoucherCodeValid'
}

export type StreamPaymentsData = {apisessionid?: string} | LoginSubmissionForm | Init | KSessionRequest | ContactSubmissionForm ;

export interface StreamPayments {
  init?(data: Init): any;

  doContactSubmission?(data: ContactSubmissionData, callback?: object): UserAuthentificationData;

  doLogin?(data: LoginSubmissionForm, callback?: object): UserAuthentificationData;

  doLoginPost?(data: object, callback?: object): any;

  doLogout?(data: object, callback?: object): any;

  getKSession?(data: KSessionRequest, callback?: object): UserAuthentificationData;

  getSessionState?(data: null, callback?: object): UserAuthentificationData;

  getSubscriptionPackageList?(data: null, callback?: object): SubscribtionPackageListResponse;

  isEmailAddressRegistered?(data: object, callback?: object): any;

  isEmailAddressValid?(data: object, callback?: object): any;

  isVoucherCodeValid?(data: object, callback?: object): any;
}

export interface SubscribtionPackageListResponse extends SubscriptionPlan {
  SubscriptionPlanOptions?: SubscriptionPlan[]
}

export interface SubscriptionPlan {
  Id: string;
  Title: string;
  Body: string;
  Style: string;
  Amount: number;
  Currency: string;
  AmountFormatted: string;
  HasFreeTrial: boolean;
  TrialDuration: number;
  PurchaseUrl: any;
  Interval: string;
  Duration: number;
}

export interface Init {
  jwtToken?: string;
  language?: string | 'en';
}

export interface ContactSubmissionData {
  form: ContactSubmissionForm
}

export interface ContactSubmissionForm {
  body: GenericInput | string;
  emailaddress: GenericInput | string;
  name: GenericInput | string;
  subject: GenericInput | string;
  submit_button?: SubmitBtn | string;
}

export interface LoginSubmissionForm {
  emailaddress: GenericInput | string;
  password: GenericInput | string;
}

interface KSessionRequest {
  entryId: string;
}

export interface UserAuthentificationData {
  AuthenticationToken?: string;
  CurrentCustomerSession?: CustomerSessionResponse;
  CurrentCustomerSessionStatus?: number;
  KSession?: string;
  LocationFromIp?: { string }
  ModelErrors?: { string };
  PageUrl?: string;
  UpdatedAt?: string;
  UtcNow?: string;
}

interface CustomerSessionResponse {
  Id: string;
  CustomerId: string;
  CustomerDeleted: boolean;
  CustomerFirstName: string;
  CustomerLastName: string;
  CustomerEmailAddress: string;
  CustomerSubscriptionCount: number;
  CustomerNonExpiringSubscriptionCount: number;
  CustomerEntitlements: any;
  CustomerFullAccessUntil: any;
  CustomerBillingProfileProvider: any;
  CustomerBillingProfileReference: any;
  CustomerBillingProfileExpiresAt: any;
  CustomerBillingProfileCreatedAt: any;
  CustomerBillingProfileLastFailedAt: any;
}

export interface GenericInput {
  label?: string;
  placeholder?: string;
  values?: string[];
  value?: string;
  input_type?: string;
  required?: boolean;
  internal_reference_name?: string;
}

export interface SubmitBtn extends GenericInput{
  submit_to?: StreamPaymentsHooks | string;
}

