/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./carousel.component";
import * as i3 from "../../../../amg/src/lib/bootstrap.viewport.service";
var styles_CarouselComponent = [];
var RenderType_CarouselComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CarouselComponent, data: {} });
export { RenderType_CarouselComponent as RenderType_CarouselComponent };
function View_CarouselComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getCurrentSlideProperty(_v.context.$implicit); _ck(_v, 4, 0, currVal_2); }); }
function View_CarouselComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "col amg-side-details"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselComponent_2)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDetails(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.staticProperties.carouselSidebar; _ck(_v, 4, 0, currVal_1); }, null); }
function View_CarouselComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_CarouselComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "col"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slideClick("click", _v.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { active: 0, selected: 1, "first-slide": 2, "last-slide": 3 }), i0.ɵdid(3, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.slideHover("hover", _v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.slideHoverOut("hover", _v.context.index) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, [[1, 3], [3, 0], ["componentChild", 2]], null, 0, null, View_CarouselComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "col"; var currVal_1 = _ck(_v, 2, 0, _co.slideIsActive(_v.context.index), _co.isSlideSelected(_v.context.index), (_v.context.index === 0), (_v.context.index === (_co.componentData.length - 1))); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.getCol(_v.context.index); _ck(_v, 3, 0, currVal_2); }, null); }
function View_CarouselComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "col"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDetails(); _ck(_v, 1, 0, currVal_0); }, null); }
function View_CarouselComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(2, { active: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slideTo("dot", _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2022"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.dotIsActive(_v.context.index)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_CarouselComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "amg-carousel-dots-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "ul", [["class", "amg-carousel-dots"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselComponent_8)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.componentData; _ck(_v, 3, 0, currVal_0); }, null); }
function View_CarouselComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "amg-carousel-nav-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "amg-carousel-nav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "button", [["class", "prev"]], [[8, "innerHTML", 1], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickPrevious("nav") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "button", [["class", "next"]], [[8, "innerHTML", 1], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickNext("nav") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getCurrentView().navText[1]; var currVal_1 = !_co.showPrev(); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.getCurrentView().navText[0]; var currVal_3 = !_co.showNext(); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_CarouselComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "amg-carousel"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [[2, 0], ["widgetsContent", 1]], null, 4, "div", [["class", "amg-carousel-container"]], [[1, "data-slide-current", 0], [1, "data-slide-hovered", 0], [1, "data-slide-visible", 0], [1, "data-slide-visible-hovered", 0], [1, "data-slide-selected", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselComponent_4)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselComponent_6)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselComponent_7)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselComponent_9)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.componentData; _ck(_v, 3, 0, currVal_5); var currVal_6 = _co.getExtraColumns(); _ck(_v, 5, 0, currVal_6); var currVal_7 = _co.getCurrentView().showDots; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.getCurrentView().showButtons; _ck(_v, 9, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSlideNumber(); var currVal_1 = (_co.getSlideHover() - _co.getSlideNumber()); var currVal_2 = _co.getVisibleSlideCount(); var currVal_3 = _co.getVisibleSlideHoveredCount(); var currVal_4 = _co.getCurrentSelectedSlide(); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_CarouselComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { componentChildren: 1 }), i0.ɵqud(671088640, 2, { widgetsContent: 0 }), i0.ɵqud(671088640, 3, { widgetsComponentChildren: 1 }), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "amg-carousel-outer"]], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselComponent_3)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "amg-carousel-outer"; var currVal_1 = ((_co.getVisibleSlideCount() < 2) ? "vertical" : "horizontal"); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = (_co.sideBarIsActive() && (_co.componentData.length > 0)); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.componentData.length > 0); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_CarouselComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-carousel", [], null, [[null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("touchstart" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onTouchStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchend" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouchEnd($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_CarouselComponent_0, RenderType_CarouselComponent)), i0.ɵdid(1, 4964352, null, 0, i2.CarouselComponent, [i0.ComponentFactoryResolver, i3.BootstrapViewportService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarouselComponentNgFactory = i0.ɵccf("amg-carousel", i2.CarouselComponent, View_CarouselComponent_Host_0, { componentData: "componentData", responsiveProperties: "responsiveProperties", staticProperties: "staticProperties" }, { cardClicked: "cardClicked", cardHovered: "cardHovered", cardSelected: "cardSelected" }, []);
export { CarouselComponentNgFactory as CarouselComponentNgFactory };
