import {
  EventEmitter
} from '@angular/core';
export class CalendarHover {

  private _enableSlideHover = true;
  private _currentSlideHovered: number;

  /**
   * Hover handler class for calendar
   */
  constructor(
    private _hoverEvent: EventEmitter < any > ,
  ) {}

  /**
   * Get index of slide being hovered
   * @param source 
   */
  getCurrentSlideHover(source: string = 'unknown'): number {
    if (this._enableSlideHover) {
      return this._currentSlideHovered;
    }
    return -1;
  }

  /**
   * Enable hovering behaviour
   * @param source 
   */
  enableSlideHover(source: string = 'unknown') {
    this._enableSlideHover = true;
  }
  /**
   * Disable hovering behaviour
   */
  disableSlideHover(source: string = 'unknown') {
    this._enableSlideHover = false;
  }

  /**
   * Set slide as being hovered
   * @param source 
   * @param slide
   * @param slide data
   */
  slideHover(source: string = 'unknown', slide: number, item: any) {
    if (this._currentSlideHovered !== slide) {
      if (this._enableSlideHover) {
        this._currentSlideHovered = slide;
        this._hoverEvent.emit(item);
      }
    }
  }
  /**
   * Set slide as not being hovered
   * @param source 
   * @param slide 
   */
  slideHoverOut(source: string = 'unknown', slide: number) {
    if (this._currentSlideHovered === slide) {
      if (this._enableSlideHover) {
        this._currentSlideHovered = -1;
        this._hoverEvent.emit(undefined);
      }
    }
  }
}
