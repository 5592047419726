import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component, EventEmitter,
  HostBinding,
  Input, OnChanges, OnDestroy,
  OnInit, Output, SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AnywhereItemData } from 'amg-fe-types';
import { FlickityUtils } from 'amg-fe-utils';
import { CardConfiguration } from 'cards';
import { CarouselConfiguration } from './type/carousel-configuration.type';
import Flickity from 'flickity';

@Component({
  selector: 'amg-carousel-flickity',
  templateUrl: './carousel-flickity.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselFlickityComponent implements OnInit, OnDestroy, AfterViewChecked, OnChanges {
  @Input() carouselConfig: CarouselConfiguration;
  @Input() carouselSlides: AnywhereItemData<any>[];
  @Input() isMoreDataAvailable: boolean;
  @Input() apiCallTriggered: boolean;
  @Output() triggerLoadMore: EventEmitter<boolean>;
  @Output() triggerCardClick: EventEmitter<any>;
  @HostBinding('attr.class') classCardType: string;
  @ViewChild('carouselContainer') carouselContainerRef;

  public cardConfiguration: CardConfiguration;

  private triggerFlickityInit: boolean;
  private triggerFlickityUpdate: boolean;
  private isWaitingForData: boolean;
  private carousel: Flickity;

  constructor() {
    this.carouselSlides = null;
    this.triggerFlickityInit = true;
    this.triggerFlickityUpdate = false;
    this.isMoreDataAvailable = true;
    this.apiCallTriggered = false;
    this.isWaitingForData = false;
    this.triggerLoadMore = new EventEmitter<boolean>();
    this.triggerCardClick = new EventEmitter<any>();
  }

  ngOnInit() {
    this.classCardType = this.carouselConfig.carouselType;
    this.cardConfiguration = {
      displayKeys: this.carouselConfig.cardDisplayKeys
    };
  }

  ngOnDestroy(): void {
    if (this.carousel) {
      this.carousel.destroy();
    }
  }

  ngAfterViewChecked(): void {
    if (this.triggerFlickityInit) {
      this.carousel = new Flickity(this.carouselContainerRef.nativeElement, {
        groupCells: true,
        contain: false,
        cellAlign: 'left',
        pageDots: false,
        friction: 0.10, // default: 0.28. Higher friction = stickier and less bouncy
        selectedAttraction: 0.005, // default: 0.025. Higher attraction makes the slider move faster
        prevNextButtons: this.carouselConfig.showNextPrevButtons
      });

      if (!!this.carouselConfig.triggerLoadMoreOnEndOfCarousel) {
        this.initializeScrollEventListener();
      }

      FlickityUtils.flickityBugFix(
        this.carousel,
        this.carouselContainerRef.nativeElement,
        'page-container',
        'div.video-card');

      this.triggerFlickityInit = false;
    }

    if (this.triggerFlickityUpdate) {
      const container = (this.carousel as any).handles as HTMLDivElement;
      const viewPort = container[0] as HTMLDivElement;
      const slider = viewPort.children[0] as HTMLDivElement;

      const flickitySlides = this.carousel.cells;
      const slides: HTMLElement[] = [].slice.call(slider.children);

      const slidesToAdd = slides.splice(flickitySlides.length);

      this.carousel.append(slidesToAdd);

      this.triggerFlickityUpdate = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.carouselSlides
        && changes.carouselSlides.currentValue
        && changes.carouselSlides.previousValue) {
      if (!changes.carouselSlides.previousValue || changes.carouselSlides.previousValue.length === 0) {
        this.carousel.destroy();
        this.triggerFlickityInit = true;
      } else if (changes.carouselSlides.currentValue.length !== changes.carouselSlides.previousValue.length) {
        this.triggerFlickityUpdate = true;
        this.isWaitingForData = false;
      }
    }
  }

  onCardClick(data: any): void {
    this.triggerCardClick.emit(data);
  }

  private initializeScrollEventListener(): void {
    this.carousel.on('scroll', (progress: number) => {
      if (!this.apiCallTriggered && !this.isWaitingForData) {
        if (this.isMoreDataAvailable) {
          const currentProgress = Math.max(0, Math.min(1, progress)) * 100;

          if (currentProgress >= this.carouselConfig.triggerLoadMoreOnEndOfCarousel) {
            this.isWaitingForData = true;
            this.triggerLoadMore.emit(true);
          }
        } else {
          // Stop listening
          this.carousel.off('scroll', () => {
          });
        }
      }
    });
  }
}
