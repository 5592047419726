import { WordpressAdapterService } from 'wordpress-adapter';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../projects/wordpress/wordpress-adapter/src/lib/wordpress-adapter.service";
var WordpressService = /** @class */ (function () {
    function WordpressService(wordpressAdapter) {
        this.wordpressAdapter = wordpressAdapter;
        // TODO page caching. and possibly buffering data when idle to prepare the other 2 sub-pages (players).
        //  This shall improve the user experience.
    }
    WordpressService.prototype.getOtherContent = function () {
        var _this = this;
        return this.wordpressAdapter
            .request(null, this.getOtherContentEndpoint())
            .pipe(map(function (clientResponse) {
            return _this.handleResponse(clientResponse);
        }));
    };
    WordpressService.prototype.getMainPage = function () {
        return this.getPage('home-page');
    };
    WordpressService.prototype.getAudioCommentaryPage = function () {
        return this.getPage('audio-commentary-page');
    };
    WordpressService.prototype.getVodPage = function () {
        return this.getPage('vod-page');
    };
    WordpressService.prototype.getFfctvLivePage = function () {
        return this.getPage('ffctv-live-page');
    };
    WordpressService.prototype.getLiveEventsPage = function () {
        return this.getPage('live-events-page');
    };
    WordpressService.prototype.getPage = function (pageName, isJsonFile) {
        var _this = this;
        return this.wordpressAdapter
            .request(null, this.getPageEndpoint(pageName, isJsonFile))
            .pipe(map(function (clientResponse) {
            return _this.handleResponse(clientResponse);
        }));
    };
    WordpressService.prototype.handleResponse = function (clientResponse) {
        if (clientResponse.error) {
            return null;
        }
        else {
            return clientResponse.result;
        }
    };
    WordpressService.prototype.getPageEndpoint = function (pageName, isJsonFile) {
        return isJsonFile
            ? "page/?slug=" + pageName + "&json=true"
            : "page/?slug=" + pageName;
    };
    WordpressService.prototype.getOtherContentEndpoint = function () {
        return 'shared_section';
    };
    WordpressService.ngInjectableDef = i0.defineInjectable({ factory: function WordpressService_Factory() { return new WordpressService(i0.inject(i1.WordpressAdapterService)); }, token: WordpressService, providedIn: "root" });
    return WordpressService;
}());
export { WordpressService };
