import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostBinding, Inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { Link } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-navigation-link',
  templateUrl: './navigation-link.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NavigationLinkComponent {
  @HostBinding('attr.class') class = 'nav-item';
  @Input() navigationLink: Link;
  @Input() rowNumber: number;
  @Output() triggerClick: EventEmitter<boolean>;

  private static getBaseUrlLength(fullUrlPath: string): number {
    const baseUrlRegex = /^(https?:\/\/|www\.)?[^\/]+/i,
      matchingData = fullUrlPath.match(baseUrlRegex);

    return matchingData.length
      ? matchingData[0].length
      : 0;
  }

  constructor(@Inject(DOCUMENT) private document: any) {
    this.triggerClick = new EventEmitter();
  }

  internalLinkClick(): void {
    this.triggerClick.emit(true);
  }

  triggerExternalRedirect() {
    this.triggerClick.emit(true);
    this.document.location.href = this.getExternalPath();
  }

  isInternalLink(): boolean {
    return !this.navigationLink.externalLink;
  }

  getLabel(): string {
    return this.navigationLink.label;
  }

  getInternalPath(): string {
    if (this.navigationLink.page) {
      const basePathLength = NavigationLinkComponent.getBaseUrlLength(this.navigationLink.page);

      return this.navigationLink.page.substring(basePathLength);
    }

    return this.navigationLink.path;
  }

  getExternalPath(): string {
    return this.navigationLink.externalLink;
  }
}
