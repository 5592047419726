import { BaseObject } from '../../../BaseObject';
import { PaymentLoginButtonGroup } from './PaymentLoginButtonGroup';
import { PaymentSubscribePackages } from './PaymentSubscribePackages';
import { PaymentUserSignInPage } from './PaymentUserSignInPage';
import { PaymentVideoPlayer } from './PaymentVideoPlayer';

export class PaymentFieldsGroup extends BaseObject {
  private _loginButtonGroup: PaymentLoginButtonGroup;
  private _userAuthorizationPage: PaymentUserSignInPage;
  private _videoPlayer: PaymentVideoPlayer;
  private _subscribePackages: PaymentSubscribePackages;

  constructor(data: any) {
    super('PaymentFieldsGroup', data);

    if (data.payment_fields_group && data.payment_fields_group.length > 0) {
      (data.payment_fields_group as BaseObject[]).forEach(paymentField => {
        if (paymentField.objectType === 'PaymentLoginButtonGroup') {
          this._loginButtonGroup = (paymentField as PaymentLoginButtonGroup);
        } else if (paymentField.objectType === 'PaymentUserSignInPage') {
          this._userAuthorizationPage = (paymentField as PaymentUserSignInPage);
        } else if (paymentField.objectType === 'PaymentVideoPlayer') {
          this._videoPlayer = (paymentField as PaymentVideoPlayer);
        } else if (paymentField.objectType === 'PaymentSubscribePackages') {
          this._subscribePackages = (paymentField as PaymentSubscribePackages);
        }
      });
    }
  }

  get loginButtonGroup(): PaymentLoginButtonGroup {
    return this._loginButtonGroup;
  }

  get userAuthorizationPage(): PaymentUserSignInPage {
    return this._userAuthorizationPage;
  }

  get videoPlayer(): PaymentVideoPlayer {
    return this._videoPlayer;
  }

  get subscribePackages(): PaymentSubscribePackages {
    return this._subscribePackages;
  }
}
