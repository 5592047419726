import { QueryList, ViewContainerRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Subscription } from 'rxjs';

import { AmgWordpressBoxset, AmgWordpressPlayerAcf, AmgWordpressPlaylist } from 'amg';

import { CardProfile, CardType } from 'card';

import { GridResponsiveProperties } from '../grid.responsive.properties';
import { GridResponsivePropertiesView } from '../grid.responsive.properties.view';
import { GridFilter, GridStaticProperties } from '../types/GridStaticProperties';

import { GridData } from './grid.data';

export class GridDataWordpress implements GridData {
  private currentView: GridResponsivePropertiesView;
  private isLimitReached: boolean;
  private dataSubscription: Subscription;
  private isLoading: boolean;

  constructor(private http: HttpClient,
              private componentData: Array<any>,
              private responsiveProperties: GridResponsiveProperties,
              private staticProperties: GridStaticProperties,
              private componentChildren: QueryList<ViewContainerRef>,
              private selectedFilters: { search: { value: string; key?: string; }; dropdown: { [id: number]: GridFilter } },
              private rawData: any,
              private loadingState: BehaviorSubject<boolean>) {
    this.isLimitReached = false;
    this.isLoading = true;
  }

  fetchData(filters: { search: { value: string; key?: string; }; dropdown: { [id: number]: GridFilter } }, clearData: boolean) {
    if (clearData || !this.isLimitReached) {
      this.triggerCall(clearData);
    }
  }

  setCurrentView(source: string = 'unknown', currentView: GridResponsivePropertiesView) {
    this.currentView = currentView;
  }

  getIsLimitReached(): boolean {
    return this.isLimitReached;
  }

  onDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  getPagingData(): { itemsPerPage: number; currentPage: number; totalItems: number; } {
    return null;
  }

  getIsLoading(): boolean {
    return this.isLoading;
  }

  private triggerCall(clearData: boolean) {
    this.isLoading = true;
    const url = this.staticProperties.pageUrl;

    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }

    this.dataSubscription = this.http.get(url)
      .subscribe((feed: any[]) => {
        this.isLoading = false;
        if (clearData) {
          this.resetData();
        }

        this.setData(feed);
        this.isLimitReached = true;
        this.loadingState.next(false);
      });
  }

  private resetData(): void {
    this.componentData.length = 0;
    this.rawData.length = 0;
    this.componentChildren.reset([]);
    this.isLimitReached = false;
  }

  private setData(feed: any[]) {
    if (feed.length > 0) {
      if (this.isDataOfTypePlayerProfile()) {
        this.setPlayerProfileData(feed);
      }

      // Boxsets seems redundant as data is coming from Anywhere not Wordpress.
      if (this.isDataOfTypeBoxset()) {
        this.setBoxsetData(feed);
      }

      if (this.isDataOfTypePlaylist()) {
        this.setPlaylistData(feed);
      }
    }
  }

  private isDataOfTypePlayerProfile(): boolean {
    return this.staticProperties.pageUrl.indexOf('player') !== -1;
  }

  private isDataOfTypeBoxset(): boolean {
    return this.staticProperties.pageUrl.indexOf('boxset') !== -1;
  }

  private isDataOfTypePlaylist(): boolean {
    return this.staticProperties.pageUrl.indexOf('playlist') !== -1;
  }

  private setPlayerProfileData(feed: any[]) {
    this.staticProperties.cardType = CardType.Profile;

    const segregatedPlayersByPosition: { [playerPosition: string]: CardProfile[] } = {};
    feed.forEach((player: AmgWordpressPlayerAcf) => {

      this.rawData.push(player);

      const cardPlayer: CardProfile = {
        imageUrl: player.imageUrl,
        iconUrl: player.icon.icon,
        nameFirst: player.first_name,
        nameLast: player.last_name,
        number: player.shirt_number,
        position: player.position.toLocaleLowerCase(),
        raw: player
      };

      if (segregatedPlayersByPosition[cardPlayer.position]) {
        segregatedPlayersByPosition[cardPlayer.position].push(cardPlayer);
      } else {
        segregatedPlayersByPosition[cardPlayer.position] = [cardPlayer];
      }
    });

    Object.keys(segregatedPlayersByPosition).forEach((playerPosition) => {
      const playerList = segregatedPlayersByPosition[playerPosition];

      this.componentData.push({
        key: playerPosition,
        value: playerList
      });
    });
  }

  private setBoxsetData(feed: any[]) {
    this.staticProperties.cardType = CardType.Image;

    feed.forEach((boxset: AmgWordpressBoxset) => {
      const cardImage = {
        imageUrl: boxset.imageUrl,
        title: boxset.title,
        raw: boxset
      };

      this.componentData.push(cardImage);
      this.rawData.push(boxset);
    });
  }

  private setPlaylistData(feed: any[]) {
    this.staticProperties.cardType = CardType.Image;

    feed.forEach((playlist: AmgWordpressPlaylist) => {
      const cardImage = {
        imageUrl: playlist.imageUrl,
        title: playlist.title,
        raw: playlist
      };

      this.componentData.push(cardImage);
      this.rawData.push(playlist);
    });
  }
}
