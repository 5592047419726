/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../_shared/_pipes/format-date.pipe";
import * as i2 from "@angular/common";
import * as i3 from "./date-and-time.component";
var styles_DateAndTimeComponent = [];
var RenderType_DateAndTimeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DateAndTimeComponent, data: {} });
export { RenderType_DateAndTimeComponent as RenderType_DateAndTimeComponent };
function View_DateAndTimeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "amg-date"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(2, 2), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(4, 2), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(6, 2), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "amg-inline-separator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 5, "span", [["class", "amg-time-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(10, 2), (_l()(), i0.ɵted(-1, null, [":"])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(13, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.date, "day")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), _co.date, "month")); _ck(_v, 3, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 0), _co.date, "year")); _ck(_v, 5, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent, 0), _co.date, "hour")); _ck(_v, 9, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v.parent, 0), _co.date, "minute")); _ck(_v, 12, 0, currVal_4); }); }
export function View_DateAndTimeComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.FormatDatePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DateAndTimeComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.date; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DateAndTimeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-date-and-time", [], null, null, null, View_DateAndTimeComponent_0, RenderType_DateAndTimeComponent)), i0.ɵdid(1, 49152, null, 0, i3.DateAndTimeComponent, [], null, null)], null, null); }
var DateAndTimeComponentNgFactory = i0.ɵccf("amg-date-and-time", i3.DateAndTimeComponent, View_DateAndTimeComponent_Host_0, { cardData: "cardData" }, {}, []);
export { DateAndTimeComponentNgFactory as DateAndTimeComponentNgFactory };
