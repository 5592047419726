import { EventEmitter, OnInit } from '@angular/core';
import { Header } from 'wordpress-adapter';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent() {
        this.class = 'amg-header';
        this.triggerChange = new EventEmitter();
    }
    HeaderComponent.prototype.ngOnInit = function () {
        if (this.header) {
            if (this.getCss()) {
                this.class = this.class
                    .concat(' ')
                    .concat(this.getCss());
            }
        }
    };
    HeaderComponent.prototype.onElementChange = function (value) {
        this.triggerChange.emit(value);
    };
    HeaderComponent.prototype.getSections = function () {
        return this.header
            ? this.header.sections
            : [];
    };
    HeaderComponent.prototype.getCss = function () {
        return this.header.cssClass;
    };
    return HeaderComponent;
}());
export { HeaderComponent };
