import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, HostBinding } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AmgEvent} from 'amg';
import { Subscription } from 'rxjs';
import {LiveButtonClick} from '../../types/LiveButtonClick';
import {FixtureBaseComponent} from '../../models/FixtureBaseComponent';

@Component({
  selector: 'amg-card-event',
  templateUrl: './card-event.component.html'
})
export class CardEventComponent extends FixtureBaseComponent implements OnInit, OnDestroy {
  @Input() fixtureData: AmgEvent;
  @Output() buttonClicked: EventEmitter<LiveButtonClick>;
  @HostBinding('attr.is-live') isLive = false;

  private isFixtureLiveSubscription: Subscription;

  constructor(protected http: HttpClient) {
    super(http);

    this.buttonClicked = new EventEmitter<LiveButtonClick>();
  }

  ngOnInit() {
    this.init();

    this.isFixtureLiveSubscription = this.getIsAnyFixtureLiveObservable().subscribe(isLive => {
      this.isLive = isLive;
    });
  }

  ngOnDestroy() {
    this.isFixtureLiveSubscription.unsubscribe();

    this.destroy();
  }

  onMediaDataButtonClick(data: LiveButtonClick) {
    this.buttonClicked.emit(data);
  }
}

