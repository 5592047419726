/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../../components/card-fixture/src/lib/components/card-fixture/card-fixture.component.ngfactory";
import * as i2 from "../../../../../../../../../../components/card-fixture/src/lib/components/card-fixture/card-fixture.component";
import * as i3 from "@angular/common/http";
import * as i4 from "./game-fixture.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../../shared/data/data.service";
var styles_GameFixtureComponent = [];
var RenderType_GameFixtureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GameFixtureComponent, data: {} });
export { RenderType_GameFixtureComponent as RenderType_GameFixtureComponent };
export function View_GameFixtureComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-card-fixture", [], [[1, "is-live", 0]], [[null, "buttonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClicked" === en)) {
        var pd_0 = (_co.buttonClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CardFixtureComponent_0, i1.RenderType_CardFixtureComponent)), i0.ɵdid(1, 245760, null, 0, i2.CardFixtureComponent, [i3.HttpClient], { fixtureData: [0, "fixtureData"] }, { buttonClicked: "buttonClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).isLive; _ck(_v, 0, 0, currVal_0); }); }
export function View_GameFixtureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-game-fixture", [], [[1, "class", 0]], null, null, View_GameFixtureComponent_0, RenderType_GameFixtureComponent)), i0.ɵdid(1, 114688, null, 0, i4.GameFixtureComponent, [i5.Router, i6.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var GameFixtureComponentNgFactory = i0.ɵccf("amg-wp-game-fixture", i4.GameFixtureComponent, View_GameFixtureComponent_Host_0, { data: "data", onClickNavRoute: "onClickNavRoute", sendEntryDataOnNavigation: "sendEntryDataOnNavigation", apiUrl: "apiUrl" }, {}, []);
export { GameFixtureComponentNgFactory as GameFixtureComponentNgFactory };
