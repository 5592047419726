import * as tslib_1 from "tslib";
import { CardProfileResponsivePropertiesView } from './card.profile.responsive.properties.view';
var CardProfileResponsiveProperties = /** @class */ (function () {
    function CardProfileResponsiveProperties(params) {
        if (params === undefined) {
            params = {};
        }
        this.xs = params.xs || null;
        this.sm = params.sm || null;
        this.md = params.md || null;
        this.lg = params.lg || null;
        this.xl = params.xl || null;
        this.HD = params.HD || null;
        this.FullHd = params.FullHd || null;
        this.FourK = params.FourK || null;
        this.synchroniseProperties();
    }
    CardProfileResponsiveProperties.prototype.synchroniseProperties = function (overrideResponsive) {
        var _this = this;
        if (overrideResponsive === undefined) {
            overrideResponsive = {};
        }
        var previousView = CardProfileResponsivePropertiesView.defaultValues();
        CardProfileResponsiveProperties.keys.forEach(function (viewPort) {
            var currentView = _this[viewPort];
            var currentValues = overrideResponsive[viewPort];
            currentView = tslib_1.__assign({}, previousView, currentView, currentValues);
            _this[viewPort] = currentView;
            previousView = currentView;
        });
    };
    CardProfileResponsiveProperties.keys = ['xs', 'sm', 'md', 'lg', 'xl', 'HD', 'FullHd', 'FourK'];
    return CardProfileResponsiveProperties;
}());
export { CardProfileResponsiveProperties };
