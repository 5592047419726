import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnywhereUtils } from 'amg-fe-utils';
import { AnywhereVideoCard } from 'wordpress-adapter';
import { DataService } from '../../../../../shared/data/data.service';
var AnywhereVideoCardComponent = /** @class */ (function () {
    function AnywhereVideoCardComponent(httpClient, dataService, router) {
        this.httpClient = httpClient;
        this.dataService = dataService;
        this.router = router;
        this.class = 'amg-video-card';
        this.isLoading = true;
        this.isError = false;
    }
    AnywhereVideoCardComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.initialiseCard();
        this.triggerApiCall(this.apiUrl);
    };
    AnywhereVideoCardComponent.prototype.ngOnDestroy = function () {
        if (this.apiDataObservable) {
            this.apiDataObservable.unsubscribe();
        }
    };
    AnywhereVideoCardComponent.prototype.onCardClick = function (data) {
        if (this.videoCard.onClickNavigate) {
            var playlistUrl = AnywhereUtils.generateInitAnywhereUrl(this.apiUrl);
            var url = this.dataService.encodeUrl(playlistUrl);
            var entryId = (data && data.mediaData && data.mediaData.entryId)
                ? data.mediaData.entryId
                : null;
            this.dataService.userInteractedObject = {
                playlistUrl: playlistUrl,
                data: data
            };
            this.router.navigate([this.videoCard.onClickNavigate], {
                queryParams: tslib_1.__assign({ playlist: url }, (entryId ? { entry: entryId } : {})),
                preserveQueryParams: false
            });
        }
    };
    AnywhereVideoCardComponent.prototype.getCss = function () {
        return this.videoCard.cssClass;
    };
    AnywhereVideoCardComponent.prototype.initialiseCard = function () {
        this.cardData = null;
        this.apiUrl = this.videoCard.anywhereApiUrl;
    };
    AnywhereVideoCardComponent.prototype.triggerApiCall = function (apiUrl) {
        var _this = this;
        this.apiDataObservable = this.httpClient.get(apiUrl).subscribe(function (data) {
            if (data) {
                var extractedData = AnywhereUtils.extractAnywhereData(data);
                if (extractedData) {
                    _this.cardData = extractedData.itemData.length > 0
                        ? extractedData.itemData[0]
                        : null;
                }
            }
            _this.isLoading = false;
        }, function (error) {
            _this.isLoading = false;
            _this.isError = true;
        });
    };
    return AnywhereVideoCardComponent;
}());
export { AnywhereVideoCardComponent };
