<ng-template [ngIf]="date">
  <div class="amg-date">
    <span [innerHTML]="date | formatDate:'day'"></span>
    <span [innerHTML]="date | formatDate:'month'"></span>
    <span [innerHTML]="date | formatDate:'year'"></span>
    <span class="amg-inline-separator"></span>
    <span class="amg-time-wrapper">
        <span [innerHTML]="date | formatDate:'hour'"></span>:<span [innerHTML]="date | formatDate:'minute'"></span>
        </span>
  </div>
</ng-template>
