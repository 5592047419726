/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../components/calendar/src/lib/calendar.component.ngfactory";
import * as i2 from "../../../../../../../../../components/calendar/src/lib/calendar.component";
import * as i3 from "../../../../../../../../../components/amg/src/lib/bootstrap.viewport.service";
import * as i4 from "./calendar.component";
import * as i5 from "../../../../../shared/api/api.service";
import * as i6 from "../../../../../shared/data/data.service";
import * as i7 from "@angular/router";
var styles_CalendarComponent = [];
var RenderType_CalendarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarComponent, data: {} });
export { RenderType_CalendarComponent as RenderType_CalendarComponent };
export function View_CalendarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-calendar", [], null, [[null, "fixtureClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fixtureClicked" === en)) {
        var pd_0 = (_co.onFixtureClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CalendarComponent_0, i1.RenderType_CalendarComponent)), i0.ɵdid(1, 770048, null, 0, i2.CalendarComponent, [i3.BootstrapViewportService], { componentData: [0, "componentData"], responsiveProperties: [1, "responsiveProperties"], staticProperties: [2, "staticProperties"] }, { fixtureClicked: "fixtureClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarData; var currVal_1 = _co.calendarResponsiveProperties; var currVal_2 = _co.calendarStaticProperties; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CalendarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-calendar", [], [[1, "class", 0]], null, null, View_CalendarComponent_0, RenderType_CalendarComponent)), i0.ɵdid(1, 245760, null, 0, i4.CalendarComponent, [i5.ApiService, i6.DataService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var CalendarComponentNgFactory = i0.ɵccf("amg-wp-calendar", i4.CalendarComponent, View_CalendarComponent_Host_0, { calendar: "calendar" }, {}, []);
export { CalendarComponentNgFactory as CalendarComponentNgFactory };
