import { Overlay } from 'wordpress-adapter';
import { ApiService } from '../../../shared/api/api.service';
var OverlayComponent = /** @class */ (function () {
    function OverlayComponent(api) {
        this.api = api;
        this.class = 'amg-overlay';
    }
    OverlayComponent.prototype.getElements = function () {
        return this.overlay.overlayFields;
    };
    return OverlayComponent;
}());
export { OverlayComponent };
