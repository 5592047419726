import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
var FilterBarComponent = /** @class */ (function () {
    function FilterBarComponent() {
        this.class = 'amg-filter-bar search-container';
        this.form = new FormGroup({
            search: new FormControl(),
        });
        this.filterBarId = uuid();
        this.triggerSearch = new EventEmitter();
        this.resetFilterTriggerEmitter = new EventEmitter();
        this.resetFilterTriggerEmitterInternal = new EventEmitter();
    }
    FilterBarComponent.generateFilterByText = function (inputValue, key) {
        return tslib_1.__assign({ value: inputValue }, (key ? { key: key } : {}));
    };
    FilterBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.searchObservable) {
            this.searchSubscription = this.searchObservable.subscribe(function (value) {
                if (typeof value === 'string') {
                    _this.form.get('search').setValue(value || _this.searchValue || '');
                }
            });
        }
        if (this.resetFilterBarObservable) {
            this.resetFilterBarSubscription = this.resetFilterBarObservable.subscribe(function (value) {
                if (value) {
                    _this.resetFilterTriggerEmitterInternal.emit(value);
                }
            });
        }
    };
    FilterBarComponent.prototype.ngOnDestroy = function () {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
        if (this.resetFilterBarSubscription) {
            this.resetFilterBarSubscription.unsubscribe();
        }
    };
    FilterBarComponent.prototype.isFilterBarEnabled = function () {
        return !!this.searchBarProperties;
    };
    FilterBarComponent.prototype.isSearchEnabled = function () {
        return this.isFilterBarEnabled() && !!this.searchBarProperties.searchField;
    };
    FilterBarComponent.prototype.isFilteringDropdownEnabled = function () {
        return this.isFilterBarEnabled() && !!this.searchBarProperties.searchFilter;
    };
    FilterBarComponent.prototype.isFilteringDateEnabled = function () {
        return this.isFilterBarEnabled() && !!this.searchBarProperties.dateFilter;
    };
    FilterBarComponent.prototype.isResetAllEnabled = function () {
        return this.searchBarProperties.resetAll || false;
    };
    FilterBarComponent.prototype.getFilterGroups = function () {
        return this.searchBarProperties.searchFilter.filters;
    };
    FilterBarComponent.prototype.getDateFilterProperties = function () {
        return this.searchBarProperties.dateFilter;
    };
    FilterBarComponent.prototype.getSearchLabel = function () {
        return this.searchBarProperties.searchField.label;
    };
    FilterBarComponent.prototype.getSearchPlaceholder = function () {
        return this.searchBarProperties.searchField.placeholder;
    };
    FilterBarComponent.prototype.clearInputField = function (searchInput, event) {
        if (!event || event.key === 'Enter') {
            this.form.get('search').setValue('');
            this.submitSearch();
            searchInput.focus();
        }
    };
    FilterBarComponent.prototype.onFilterChange = function (value) {
        this.triggerSearch.emit(value);
    };
    FilterBarComponent.prototype.resetFilters = function () {
        this.form.get('search').setValue('');
        this.resetFilterTriggerEmitter.emit(true);
    };
    FilterBarComponent.prototype.getFilterSubjectAsObservable = function () {
        return this.resetFilterTriggerEmitter.asObservable();
    };
    FilterBarComponent.prototype.getFilterInternalEmitterAsObservable = function () {
        return this.resetFilterTriggerEmitterInternal.asObservable();
    };
    FilterBarComponent.prototype.submitSearch = function (event) {
        if (event) {
            event.preventDefault();
        }
        var search = this.form.get('search').value;
        this.triggerSearch.emit({
            type: 'search',
            searchData: FilterBarComponent.generateFilterByText(search, this.searchBarProperties.searchField.key)
        });
    };
    return FilterBarComponent;
}());
export { FilterBarComponent };
