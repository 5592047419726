/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./text.component";
var styles_TextComponent = [];
var RenderType_TextComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextComponent, data: {} });
export { RenderType_TextComponent as RenderType_TextComponent };
export function View_TextComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_TextComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-text", [], [[1, "class", 0], [8, "innerHTML", 1]], null, null, View_TextComponent_0, RenderType_TextComponent)), i0.ɵdid(1, 114688, null, 0, i1.TextComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; var currVal_1 = i0.ɵnov(_v, 1).htmlText; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var TextComponentNgFactory = i0.ɵccf("amg-wp-text", i1.TextComponent, View_TextComponent_Host_0, { text: "text" }, {}, []);
export { TextComponentNgFactory as TextComponentNgFactory };
