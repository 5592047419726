import { isDevMode } from '@angular/core';

export class BaseObject {
  protected readonly data: any;
  private readonly _objectType: string;
  private readonly _cssClass: string;

  /**
   * A property only generated for objects contained within Wordpress 'Flexible Content'.
   */
  private readonly _flexibleContentId: string;

  /**
   * A property used to show elements when user is logged.
   */
  private readonly _showWhenUserIsLoggedIn: boolean;

  /**
   * A property used to show elements when user is logged out.
   */
  private readonly _showWhenUserIsLoggedOut: boolean;

  constructor(objectType: string, data?: any) {
    this.data = isDevMode()
      ? data
      : null;

    this._objectType = objectType;
    this._flexibleContentId = data.flexibleContentId || null;

    this._cssClass = (data.amg_style && data.amg_style.class_name_css)
      ? data.amg_style.class_name_css
      : null;

    if (data.amg_payments) {
      this._showWhenUserIsLoggedIn = !!data.amg_payments.show_when_logged_in;
      this._showWhenUserIsLoggedOut = !!data.amg_payments.show_when_logged_out;
    } else {
      this._showWhenUserIsLoggedIn = true;
      this._showWhenUserIsLoggedOut = true;
    }
  }

  get objectType(): string {
    return this._objectType;
  }

  get cssClass(): string {
    return this._cssClass;
  }

  get flexibleContentId(): string {
    return this._flexibleContentId;
  }

  get showWhenUserIsLoggedIn(): boolean {
    return this._showWhenUserIsLoggedIn;
  }

  get showWhenUserIsLoggedOut(): boolean {
    return this._showWhenUserIsLoggedOut;
  }
}
