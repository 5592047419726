export enum BootstrapViewportEnum {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  HD = 'HD',
  FullHd = 'FullHd',
  FourK = 'FourK',
}
