<ng-template [ngIf]="title">
  <div [innerHTML]="title"
       class="amg-cta-description"></div>
</ng-template>

<ng-template [ngIf]="forgotPassword">
  <div class="amg-cta-btn-wrapper">
    <a [href]="forgotPassword.external_link"
       [innerHTML]="forgotPassword.label"
       class="amg-cta-btn"></a>
  </div>
</ng-template>
<ng-template [ngIf]="linkUrl">
  <div class="amg-cta-btn-wrapper">
    <a [href]="linkUrl"
       [innerHTML]="label"
       class="amg-cta-btn"></a>
  </div>
</ng-template>

