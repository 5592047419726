import * as tslib_1 from "tslib";
import { Carousel } from './Carousel';
var Boxset = /** @class */ (function (_super) {
    tslib_1.__extends(Boxset, _super);
    function Boxset(data) {
        return _super.call(this, tslib_1.__assign({}, data, { type: 'Image' }), 'Boxset') || this;
    }
    return Boxset;
}(Carousel));
export { Boxset };
