import { OnInit } from '@angular/core';
var PlaylistCardComponent = /** @class */ (function () {
    function PlaylistCardComponent() {
        this.showLiveIcons = false;
    }
    PlaylistCardComponent.prototype.ngOnInit = function () {
        if (this.cardData) {
            this.data = this.cardData;
            this.media = this.data.mediaData;
            this.meta = this.data.metaData;
            this.publication = this.data.publicationData;
            if (this.cardData.type === 'fixture' || this.cardData.type === 'event') {
                this.showLiveIcons = true;
                this.formatFixtureData();
            }
            else {
                this.formatAnywhereData();
            }
        }
    };
    PlaylistCardComponent.prototype.formatAnywhereData = function () {
        this.title = (this.meta.title)
            ? this.meta.title
            : null;
        this.description = (this.meta.body)
            ? this.meta.body
            : null;
        this.date = (this.publication.releaseFrom)
            ? this.publication.releaseFrom
            : null;
        this.categories = (this.meta.category)
            ? this.meta.category
            : (this.meta.categories)
                ? this.meta.categories
                : null;
    };
    PlaylistCardComponent.prototype.formatFixtureData = function () {
        this.type = this.cardData.type;
        this.title = this.cardData.name;
        this.teamVsTeam = ((this.cardData.homeTeam)
            ? this.cardData.homeTeam.name
            : '') + " vs " + ((this.cardData.awayTeam)
            ? this.cardData.awayTeam.name
            : '');
        this.description = (this.cardData.description)
            ? this.cardData.description
            : null;
        this.date = (this.cardData.startDate)
            ? this.cardData.startDate
            : null;
    };
    return PlaylistCardComponent;
}());
export { PlaylistCardComponent };
