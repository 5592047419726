<div class="amg-card-text card card-text">
  <div class="card-body">
    <div
      *ngFor="let key of staticProperties.keys"
      [ngClass]="key"
      >
      {{ getText(key) }}
    </div>
  </div>
</div>
