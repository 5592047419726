import * as tslib_1 from "tslib";
import { CardType } from 'card';
import { CardFixtureResponsiveProperties, DateObject, } from 'card-fixture';
import { CarouselResponsiveProperties, } from 'carousel';
import { CalendarTab } from '../calendar.tab.enum';
var CalendarBuild = /** @class */ (function () {
    function CalendarBuild() {
    }
    CalendarBuild.convertMonthsToText = function (month) {
        switch (month) {
            case 1:
                return 'January';
            case 2:
                return 'February';
            case 3:
                return 'March';
            case 4:
                return 'April';
            case 5:
                return 'May';
            case 6:
                return 'June';
            case 7:
                return 'July';
            case 8:
                return 'August';
            case 9:
                return 'September';
            case 10:
                return 'October';
            case 11:
                return 'November';
            case 12:
                return 'December';
        }
        return 'N/A';
    };
    CalendarBuild.prototype.generateResponsiveViews = function (responsiveProperties) {
        var tabResponsive = {};
        var fixtureResponsive = {};
        CalendarBuild.keys.forEach(function (viewPort) {
            var componentObjectResponsive = responsiveProperties[viewPort];
            tabResponsive[viewPort] = {
                limitViewableItems: componentObjectResponsive.tabs,
                visibleItemsOnHover: componentObjectResponsive.tabsWhenHovered,
                buttonText: componentObjectResponsive.tabNavText,
                refreshRate: componentObjectResponsive.refreshRate,
                showDots: false,
                showButtons: false,
                mouseDrag: false,
            };
            fixtureResponsive[viewPort] = componentObjectResponsive.fixtureView;
        });
        var responsiveViews = {
            tabResponsive: new CarouselResponsiveProperties({
                xs: tabResponsive.xs,
                sm: tabResponsive.sm,
                md: tabResponsive.md,
                lg: tabResponsive.lg,
                xl: tabResponsive.xl,
                HD: tabResponsive.HD,
                FullHd: tabResponsive.FullHd,
                FourK: tabResponsive.FourK,
            }),
            fixtureResponsive: new CardFixtureResponsiveProperties({
                xs: fixtureResponsive.xs,
                sm: fixtureResponsive.sm,
                md: fixtureResponsive.md,
                lg: fixtureResponsive.lg,
                xl: fixtureResponsive.xl,
                HD: tabResponsive.HD,
                FullHd: tabResponsive.FullHd,
                FourK: tabResponsive.FourK,
            }),
        };
        return responsiveViews;
    };
    CalendarBuild.prototype.generateStaticViews = function (staticProperties) {
        var tabStatic = {
            carouselTile: CardType.Text,
            carouselSidebar: undefined,
            cardStaticProperties: {
                keys: [
                    'day',
                    (staticProperties.showTabsAsText ? 'monthText' : 'month'),
                    'year',
                ]
            }
        };
        var fixtureStatic = staticProperties.cardFixtureStaticProperties || {};
        return {
            fixtureStatic: fixtureStatic,
            tabStatic: tabStatic,
        };
    };
    CalendarBuild.prototype.keepTrackOfInterestingDates = function (startDate, interestingDates) {
        var fixtureDate = new Date(startDate);
        var tmpYear = "" + fixtureDate.getFullYear();
        var tmpMonthYear = tmpYear + "-" + (fixtureDate.getMonth() + 1);
        var tmpDayMonthYear = tmpMonthYear + "-" + fixtureDate.getDate();
        interestingDates.daysMonthsYear[tmpDayMonthYear] = fixtureDate;
        interestingDates.monthsYear[tmpMonthYear] = fixtureDate;
        interestingDates.year[tmpYear] = fixtureDate;
        return interestingDates;
    };
    // private generateButtonsForFixture(componentData: Calendar): Array<CardFixtureResponsivePropertiesViewBtn> {
    //   let newCardFixtureBtns: Array<CardFixtureResponsivePropertiesViewBtn> = [];
    //   return newCardFixtureBtns;
    // }
    CalendarBuild.prototype.generateCalendarTabYear = function (tabYears) {
        var calendarTabData = [];
        Object.keys(tabYears)
            .forEach(function (year) {
            var date = tabYears[year];
            var filter = {
                year: date.getFullYear().toString(),
            };
            calendarTabData.push(filter);
        });
        return calendarTabData;
    };
    CalendarBuild.prototype.generateCalendarTabMonthYear = function (tabMonthsYears) {
        var calendarTabData = [];
        Object.keys(tabMonthsYears)
            .forEach(function (monthYear) {
            var date = tabMonthsYears[monthYear];
            var filter = {
                monthText: CalendarBuild.convertMonthsToText(date.getMonth() + 1),
                month: (date.getMonth() + 1).toString(),
                year: date.getFullYear().toString(),
            };
            calendarTabData.push(filter);
        });
        return calendarTabData;
    };
    CalendarBuild.prototype.generateCalendarTabDayMonthYear = function (tabDaysMonthsYears) {
        var calendarTabData = [];
        Object.keys(tabDaysMonthsYears)
            .forEach(function (dayMonthYear) {
            var date = tabDaysMonthsYears[dayMonthYear];
            var filter = {
                day: date.getDate().toString(),
                month: (date.getMonth() + 1).toString(),
                year: date.getFullYear().toString(),
            };
            calendarTabData.push(filter);
        });
        return calendarTabData;
    };
    CalendarBuild.prototype.selectCalendarTabDataForView = function (calendarTab, tabYear, tabMonthYear, tabDayMonthYear) {
        var calendarTabData = [];
        switch (calendarTab) {
            case CalendarTab.Day: {
                calendarTabData = tabDayMonthYear;
                break;
            }
            case CalendarTab.Month: {
                calendarTabData = tabMonthYear;
                break;
            }
            case CalendarTab.Year: {
                calendarTabData = tabYear;
                break;
            }
        }
        return calendarTabData;
    };
    CalendarBuild.prototype.buildCalendar = function (componentData, responsiveProperties) {
        var _this = this;
        var fixtureData = [];
        var tabData = [];
        var tabsResponsive = {};
        var interestingDates = {
            daysMonthsYear: {},
            monthsYear: {},
            year: {},
        };
        componentData.forEach(function (amgFixture) {
            interestingDates = _this.keepTrackOfInterestingDates(amgFixture.startDate, interestingDates);
            var newFixture = tslib_1.__assign({}, amgFixture, { fixtureDate: new DateObject(amgFixture.startDate, false) });
            fixtureData.push(newFixture);
        });
        // Start convert fixture dates to tabs
        var tabYear = this.generateCalendarTabYear(interestingDates.year);
        var tabMonthYear = this.generateCalendarTabMonthYear(interestingDates.monthsYear);
        var tabDayMonthYear = this.generateCalendarTabDayMonthYear(interestingDates.daysMonthsYear);
        CalendarBuild.keys.forEach(function (viewPort) {
            var componentObjectResponsive = responsiveProperties[viewPort];
            tabsResponsive[viewPort] = _this.selectCalendarTabDataForView(componentObjectResponsive.tab, tabYear, tabMonthYear, tabDayMonthYear);
        });
        // end convert fixture dates to tabs
        var build = {
            fixtureData: fixtureData,
            tabData: tabData,
            tabsResponsive: tabsResponsive
        };
        return build;
    };
    CalendarBuild.keys = ['xs', 'sm', 'md', 'lg', 'xl', 'HD', 'FullHd', 'FourK'];
    return CalendarBuild;
}());
export { CalendarBuild };
