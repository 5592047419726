/*
 * Public API Surface of countdown
 */

export { CountdownComponent } from './lib/countdown.component';
export { CountdownModule } from './lib/countdown.module';

export { CountdownResponsiveProperties } from './lib/countdown.responsive.properties';
export { CountdownResponsivePropertiesView } from './lib/countdown.responsive.properties.view';
export { CountdownStaticProperties } from './lib/countdown.static.properties';
