/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../components/user-auth/src/lib/sign-up-buttons/login-btn/login-btn.component.ngfactory";
import * as i2 from "../../../../../../../../../components/user-auth/src/lib/sign-up-buttons/login-btn/login-btn.component";
import * as i3 from "../../../../../../../../../components/user-auth/src/lib/user-auth.service";
import * as i4 from "../../../../../../../../../components/user-auth/src/lib/sign-up-buttons/logout-btn/logout-btn.component.ngfactory";
import * as i5 from "../../../../../../../../../components/user-auth/src/lib/sign-up-buttons/logout-btn/logout-btn.component";
import * as i6 from "../../../../../../../../../components/user-auth/src/lib/sign-up-buttons/register-btn/register-btn.component.ngfactory";
import * as i7 from "../../../../../../../../../components/user-auth/src/lib/sign-up-buttons/register-btn/register-btn.component";
import * as i8 from "../../../../../../../../../components/user-auth/src/lib/sign-up-buttons/subscribe-btn/subscribe-btn.component.ngfactory";
import * as i9 from "../../../../../../../../../components/user-auth/src/lib/sign-up-buttons/subscribe-btn/subscribe-btn.component";
import * as i10 from "@angular/common";
import * as i11 from "./login-button-group.component";
var styles_LoginButtonGroupComponent = [];
var RenderType_LoginButtonGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginButtonGroupComponent, data: {} });
export { RenderType_LoginButtonGroupComponent as RenderType_LoginButtonGroupComponent };
function View_LoginButtonGroupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-login-btn", [], null, null, null, i1.View_LoginBtnComponent_0, i1.RenderType_LoginBtnComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoginBtnComponent, [i3.UserAuthService], { loginBtn: [0, "loginBtn"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLoginButton(); _ck(_v, 1, 0, currVal_0); }, null); }
function View_LoginButtonGroupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-logout-btn", [], null, null, null, i4.View_LogoutBtnComponent_0, i4.RenderType_LogoutBtnComponent)), i0.ɵdid(1, 114688, null, 0, i5.LogoutBtnComponent, [i3.UserAuthService], { logoutBtn: [0, "logoutBtn"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLogoutButton(); _ck(_v, 1, 0, currVal_0); }, null); }
function View_LoginButtonGroupComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-register-btn", [], null, null, null, i6.View_RegisterBtnComponent_0, i6.RenderType_RegisterBtnComponent)), i0.ɵdid(1, 114688, null, 0, i7.RegisterBtnComponent, [i3.UserAuthService], { registerBtn: [0, "registerBtn"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getRegisterButton(); _ck(_v, 1, 0, currVal_0); }, null); }
function View_LoginButtonGroupComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-subscribe-btn", [], null, null, null, i8.View_SubscribeBtnComponent_0, i8.RenderType_SubscribeBtnComponent)), i0.ɵdid(1, 114688, null, 0, i9.SubscribeBtnComponent, [i3.UserAuthService], { subscribeBtn: [0, "subscribeBtn"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSubscribeButton(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoginButtonGroupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginButtonGroupComponent_1)), i0.ɵdid(1, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginButtonGroupComponent_2)), i0.ɵdid(3, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginButtonGroupComponent_3)), i0.ɵdid(5, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginButtonGroupComponent_4)), i0.ɵdid(7, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLoginButton(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getLogoutButton(); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.getRegisterButton(); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.getSubscribeButton(); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_LoginButtonGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-login-button-group", [], [[1, "class", 0]], null, null, View_LoginButtonGroupComponent_0, RenderType_LoginButtonGroupComponent)), i0.ɵdid(1, 114688, null, 0, i11.LoginButtonGroupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var LoginButtonGroupComponentNgFactory = i0.ɵccf("amg-wp-login-button-group", i11.LoginButtonGroupComponent, View_LoginButtonGroupComponent_Host_0, { loginButtonGroup: "loginButtonGroup" }, {}, []);
export { LoginButtonGroupComponentNgFactory as LoginButtonGroupComponentNgFactory };
