import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { CardFixtureResponsiveProperties, CardFixtureStaticProperties } from 'card-fixture';
import { Fixture as WpFixture } from 'wordpress-adapter';
import { ApiService } from '../../../../../shared/api/api.service';

@Component({
  selector: 'amg-wp-fixture',
  templateUrl: './fixture.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FixtureComponent implements OnInit, OnDestroy {
  private static readonly CARD_TYPE_CALENDAR = 'calendar';

  @HostBinding('attr.class') class = 'amg-fixture';
  @HostBinding('attr.data-is-data-available') isDataAvailable;
  @Input() fixture: WpFixture;

  @Input() apiFixtureData: {
    data: any;
    onClickNavRoute: string;
    sendEntryDataOnNavigation: boolean;
    fixtureApiUrl: string;
    fixtureCardType: ('calendar' | 'fixture');
  };

  fixtureData: any;
  fixtureOnClickNavRoute: string;
  fixtureSendEntryDataOnNavigation: boolean;
  fixtureApiUrl: string;

  isIndividualFixture: boolean;
  isLoadingData: boolean;

  private apiDataObservable: Subscription;

  private static getFirstRecordFromData(data: any): any {
    return (data && data.fixtures && data.fixtures.length)
      ? data.fixtures[0]
      : null;
  }

  constructor(private api: ApiService) {
    this.isLoadingData = false;
    this.isIndividualFixture = false;
  }

  ngOnInit() {
    this.initializeFixture();
  }

  ngOnDestroy(): void {
    if (this.apiDataObservable) {
      this.apiDataObservable.unsubscribe();
    }
  }

  getCss(): string {
    return this.fixture
      ? this.fixture.cssClass
      : '';
  }

  isOfTypeFixture(fixture: any): boolean {
    return fixture.type === 'fixture';
  }

  isFixtureCalendar(): boolean {
    return this.isIndividualFixture
      ? (this.fixture && this.fixture.fixtureCardType === FixtureComponent.CARD_TYPE_CALENDAR)
      : (this.apiFixtureData && this.apiFixtureData.fixtureCardType === FixtureComponent.CARD_TYPE_CALENDAR);
  }

  private initializeFixture(): void {
    if (this.fixture) {
      this.initializeIndividualFixture();
    } else {
      this.initializeFixtureComingFromFixtures();
    }

    this.isDataAvailable = !!this.fixtureData;
  }

  private initializeFixtureComingFromFixtures(): void {
    this.fixtureData = Array.isArray(this.apiFixtureData.data)
      ? this.apiFixtureData.data[0]
      : this.apiFixtureData.data;
    this.fixtureOnClickNavRoute = this.apiFixtureData.onClickNavRoute;
    this.fixtureApiUrl = this.apiFixtureData.fixtureApiUrl;
    this.fixtureSendEntryDataOnNavigation = this.apiFixtureData.sendEntryDataOnNavigation;
  }

  private initializeIndividualFixture(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.isIndividualFixture = true;
    this.isLoadingData = true;
    this.fixtureOnClickNavRoute = this.fixture.onClickNavigate;
    this.fixtureSendEntryDataOnNavigation = this.fixture.sendEntryDataOnNavigation;
    this.fixtureApiUrl = this.fixture.restApiConfiguration.baseUrl;

    if (this.fixture.restApiConfiguration) {
      this.apiDataObservable = this.api.getApiData(this.fixture.restApiConfiguration).subscribe(data => {
        if (data) {
          this.fixtureData = FixtureComponent.getFirstRecordFromData(data);
        }

        this.isLoadingData = false;
      });
    }
  }
}
