<!--<amg-entry-nav (clickedEntry)="videoPlayerService.changeMediaMessage($event)"></amg-entry-nav>-->
<div [ngClass]="{
              'amg-live-player': videoPlayerService.isLive(),
              'amg-vod-player': !videoPlayerService.isOnDemand(),
              'amg-mobile-view': playerDimensionsRef?.isMobileView,
              'player-locked': lockPlayer
              }"
     amgMakeElementFullHeight
     class="amg-video-player-component">
  <div class="amg-player-content-wrapper">
    <div class="amg-video-player video-player">
      <div #kdp [id]="kalturaPlayerConfig.playerId"></div>
      <amg-video-thumbnail *ngIf="showThumbnail"
                           (click)="onClickThumbnail($event)"
                           [currentMedia]="localAnywhereMediaData"
                           [showThumbnail]="showThumbnail">
      </amg-video-thumbnail>
    </div>
    <amg-video-details></amg-video-details>

  </div>
  <amg-playlist (clickedEntry)="clickEntry($event)"
                (triggerPlaylistReady)="playlistReady($event)"
                (endOfList)="fetchNextPlaylistPage()"
                *ngIf="playlistDataRef"
                [activeVideoEntry]="entryAndKSRef"
                [allowChangeMedia]="autoplay"
                [playlistItems]="playlistDataRef"
  ></amg-playlist>

</div>
