import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Sections, Section } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-sections',
  templateUrl: './sections.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SectionsComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-sections';
  @Input() sections: Sections;
  @Input() isDrmDisabled: boolean;
  @Input() videoPlayerConfiguration: { drmEntries: string[]; isDesktopDevice: boolean; };
  @Output() triggerChange: EventEmitter<string>;
  @Output() triggerDisabledByDrm: EventEmitter<boolean>;

  constructor() {
    this.triggerChange = new EventEmitter<string>();
    this.triggerDisabledByDrm = new EventEmitter<boolean>();
    this.isDrmDisabled = false;
  }

  ngOnInit(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }
  }

  getSections(): Section[] {
    return this.sections
      ? this.sections.sections
      : [];
  }

  getCss(): string {
    return this.sections.cssClass;
  }

  onChange(value: string): void {
    this.triggerChange.emit(value);
  }

  onDrmStateChange(value: boolean): void {
    this.isDrmDisabled = value;
    this.triggerDisabledByDrm.emit(value);
  }
}
