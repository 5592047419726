import * as tslib_1 from "tslib";
import { AnywhereUtils, UrlUtils } from 'amg-fe-utils';
var GridDataAnywhere = /** @class */ (function () {
    function GridDataAnywhere(http, componentData, responsiveProperties, staticProperties, componentChildren, selectedFilters, rawData, loadingState, gridViewReRender) {
        this.http = http;
        this.componentData = componentData;
        this.responsiveProperties = responsiveProperties;
        this.staticProperties = staticProperties;
        this.componentChildren = componentChildren;
        this.selectedFilters = selectedFilters;
        this.rawData = rawData;
        this.loadingState = loadingState;
        this.gridViewReRender = gridViewReRender;
        this.currentPageIndex = staticProperties.pageStart;
        this.itemsPerPage = staticProperties.pagination
            ? staticProperties.pagination.itemsPerPage
            : 10;
        this.totalCount = null;
        this.isLimitReached = false;
        this.isLoading = true;
        this.extractedUrlParams = UrlUtils.extractUrlParamsFromExistingUrl(staticProperties.pageUrl);
        this.anywhereUrl = AnywhereUtils.addSearchEndpointToAnywhereUrl(UrlUtils.removeQueryParameters(staticProperties.pageUrl));
    }
    GridDataAnywhere.generateQueryFilters = function (filters) {
        var queryParameters = {};
        if (filters.search.value) {
            queryParameters[filters.search.key] = "(" + filters.search.value + "*)";
        }
        var filterDropdownKeys = Object.keys(filters.dropdown);
        if (filterDropdownKeys.length > 0) {
            filterDropdownKeys.forEach(function (id) {
                var gridFilter = filters.dropdown[id];
                if (queryParameters[gridFilter.key]) {
                    queryParameters[gridFilter.key] = "(" + gridFilter.value + ") AND " + queryParameters[gridFilter.key];
                }
                else {
                    queryParameters[gridFilter.key] = "(" + gridFilter.value + ")";
                }
            });
        }
        return queryParameters;
    };
    GridDataAnywhere.mergeQueryFiltersIntoParameters = function (queryFilters, queryParameters) {
        var queryFiltersKeys = Object.keys(queryFilters);
        var currentQueryParams = tslib_1.__assign({}, queryParameters, ((!queryParameters[GridDataAnywhere.elasticQueryKey] && queryFiltersKeys.length > 0) ? { query: '' } : {}));
        queryFiltersKeys.forEach(function (key) {
            var value = queryFilters[key];
            currentQueryParams[GridDataAnywhere.elasticQueryKey] = currentQueryParams[GridDataAnywhere.elasticQueryKey]
                ? currentQueryParams[GridDataAnywhere.elasticQueryKey] + " AND " + key + ":" + value
                : key + ":" + value;
        });
        if (currentQueryParams[GridDataAnywhere.elasticQueryKey]) {
            currentQueryParams[GridDataAnywhere.elasticQueryKey] = "(" + currentQueryParams[GridDataAnywhere.elasticQueryKey] + ")";
        }
        return currentQueryParams;
    };
    GridDataAnywhere.generateQueryParameters = function (filters, urlParameters, pageIndex, pageSize) {
        var queryFilters = GridDataAnywhere.generateQueryFilters(filters);
        var mergedUrlParameters = tslib_1.__assign({}, urlParameters, { sortBy: (urlParameters.sortBy)
                ? urlParameters.sortBy
                : 'publicationData.releaseFrom', sortOrder: (urlParameters.sortOrder)
                ? urlParameters.sortOrder
                : 'desc', pageIndex: pageIndex,
            pageSize: pageSize });
        return GridDataAnywhere.mergeQueryFiltersIntoParameters(queryFilters, mergedUrlParameters);
    };
    GridDataAnywhere.prototype.fetchData = function (filters, clearData, isPaginationEnabled, pageIndex) {
        if (clearData || !this.isLimitReached) {
            return this.triggerCall(clearData, isPaginationEnabled, pageIndex);
        }
    };
    GridDataAnywhere.prototype.setCurrentView = function (source, currentView) {
        if (source === void 0) { source = 'unknown'; }
        this.currentView = currentView;
    };
    GridDataAnywhere.prototype.getIsLimitReached = function () {
        return this.isLimitReached;
    };
    GridDataAnywhere.prototype.onDestroy = function () {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    };
    GridDataAnywhere.prototype.getPagingData = function () {
        return tslib_1.__assign({ itemsPerPage: this.itemsPerPage, currentPage: (this.currentPageIndex + 1) }, (this.totalCount ? { totalItems: this.totalCount } : {}));
    };
    GridDataAnywhere.prototype.getIsLoading = function () {
        return this.isLoading;
    };
    GridDataAnywhere.prototype.triggerCall = function (clearData, isPaginationEnabled, pageIndex) {
        var _this = this;
        this.isLoading = true;
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
        if (clearData) {
            this.currentPageIndex = this.staticProperties.pageStart;
        }
        else if (isPaginationEnabled) {
            this.currentPageIndex = pageIndex;
        }
        var queryParameters = GridDataAnywhere.generateQueryParameters(this.selectedFilters, this.extractedUrlParams, this.currentPageIndex.toString(), this.staticProperties.pageSize.toString());
        this.dataSubscription = this.http.get(this.anywhereUrl, { params: queryParameters }).subscribe(function (feed) {
            _this.isLoading = false;
            if (clearData) {
                _this.resetData();
                _this.currentPageIndex = _this.staticProperties.pageStart;
                _this.totalCount = null;
            }
            else if (isPaginationEnabled) {
                _this.resetData();
            }
            _this.setData(feed);
            if (!isPaginationEnabled) {
                _this.isLimitReached = _this.calculateIfLimitReached(_this.currentPageIndex, _this.itemsPerPage, _this.totalCount);
                _this.currentPageIndex++;
            }
            _this.loadingState.next(false);
            _this.gridViewReRender.emit(true);
        });
        var urlWithQueryString = this.anywhereUrl + '?' + Object.entries(queryParameters)
            .map(function (_a) {
            var key = _a[0], value = _a[1];
            return key + "=" + value;
        })
            .join('&');
        return {
            pageIndex: this.currentPageIndex,
            pageSize: this.staticProperties.pageSize,
            filters: this.selectedFilters,
            url: urlWithQueryString,
        };
    };
    GridDataAnywhere.prototype.setData = function (feed) {
        var _this = this;
        var sectionData = null;
        if (feed.sections) {
            sectionData = {
                feedMetaData: feed.feedMetaData,
                id: feed.sections[0].id,
                itemData: feed.sections[0].itemData,
                name: feed.sections[0].name,
                pagingData: feed.sections[0].pagingData
            };
        }
        else {
            sectionData = feed;
        }
        this.currentPageIndex = sectionData.pagingData.pageIndex;
        this.itemsPerPage = sectionData.pagingData.pageSize;
        this.totalCount = sectionData.pagingData.totalCount;
        var thumbnailWidth = screen.width / this.currentView.items;
        sectionData.itemData.forEach(function (video) {
            video.mediaData.thumbnailUrl = video.mediaData.thumbnailUrl + "/width/" + thumbnailWidth;
            _this.componentData.push(video);
            _this.rawData.push(video);
        });
    };
    GridDataAnywhere.prototype.calculateIfLimitReached = function (pageIndex, pageSize, totalCount) {
        var incrementedPageIndex = pageIndex + 1; // Page index starts from 0.
        return incrementedPageIndex * pageSize >= totalCount;
    };
    GridDataAnywhere.prototype.resetData = function () {
        this.componentData.length = 0;
        this.rawData.length = 0;
        this.componentChildren.reset([]);
        this.isLimitReached = false;
    };
    GridDataAnywhere.elasticQueryKey = 'query';
    return GridDataAnywhere;
}());
export { GridDataAnywhere };
