<div class="amg-grid" #gridElement>
  <amg-filter-bar *ngIf="isSearchEnabled()"
                  [searchBarProperties]="getSearchBarProperties()"
                  [resetFilterBarObservable]="getTriggerFilterResetAsObservable()"
                  [searchObservable]="getSearchObservable()"
                  [searchValue]="defaultSearchValue"
                  (resetFilterTriggerEmitter)="onFilterReset()"
                  (triggerSearch)="triggerSearchFilter($event)">
  </amg-filter-bar>

  <ng-container *ngIf="!(getLoadingStateAsObservable() | async)">
    <div *ngIf="componentData.length > 0">
      <div *ngIf="componentData[0].key === undefined; then sectionBlock else sectionsBlock"></div>
    </div>

    <div class="card amg-grid-card-empty"
         [hidden]="(componentData.length > 0 || isLoadingData())">
      <div class="card-body" [innerHTML]="gridStaticProperties.messageOnEmptyDataset">
      </div>
    </div>
    <button *ngIf="isLoadMoreEnabled()"
            class="btn btn-outline-dark btn-load-more"
            (click)="onClickLoadMore()">
      {{ gridStaticProperties.loadMoreText }}
    </button>
    <div *ngIf="isPaginationEnabled() && (componentData.length > 0 && !isLoadingData())">
      <pagination-controls (pageChange)="onPageChangeClick($event)">
      </pagination-controls>
    </div>
  </ng-container>
</div>


<ng-template #sectionBlock>
  <div class="row results-container">
    <ng-container *ngIf="isPaginationEnabled()">
      <div class="col"
           *ngFor="let item of componentData | paginate: getPaginationConfig(); index as i"
           [ngStyle]="getCol(i)">
        <div (click)="slideClick('click', i)"
             (mouseover)="slideHover('hover', i)"
             (mouseleave)="slideHoverOut('hover', i)">
          <ng-template #componentChild></ng-template>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!isPaginationEnabled()">
      <div class="col"
           *ngFor="let item of componentData; index as i;"
           [ngStyle]="getCol(i)">
        <div (click)="slideClick('click', i)"
             (mouseover)="slideHover('hover', i)"
             (mouseleave)="slideHoverOut('hover', i)">
          <ng-template #componentChild></ng-template>
        </div>
      </div>
    </ng-container>

  </div>
</ng-template>

<ng-template #sectionsBlock>
  <ng-container *ngIf="isPaginationEnabled()">
    <div *ngFor="let section of componentData; index as i;">
      <h2>{{ section.key }}</h2>
      <div class="row results-container">
        <div class="col"
             *ngFor="let item of section.value | paginate: getPaginationConfig(); index as j;"
             [ngStyle]="getCol(i)">
          <div (click)="slideClick('click', i, item)"
               (mouseover)="slideHover('hover', i, item)"
               (mouseleave)="slideHoverOut('hover', i, item)">
            <amg-card-profile [componentData]="item"
                              [staticProperties]="undefined">
            </amg-card-profile>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isPaginationEnabled()">
    <div *ngFor="let section of componentData; index as i;">
      <h2>{{ section.key }}</h2>
      <div class="row results-container">
        <div class="col"
             *ngFor="let item of section.value; index as j;"
             [ngStyle]="getCol(i)">
          <div (click)="slideClick('click', i, item)"
               (mouseover)="slideHover('hover', i, item)"
               (mouseleave)="slideHoverOut('hover', i, item)">
            <amg-card-profile [componentData]="item"
                              [staticProperties]="undefined">
            </amg-card-profile>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
