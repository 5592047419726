import { OnInit } from '@angular/core';
import { PaymentSubscribePackages } from 'wordpress-adapter';
var SubscribePackagesComponent = /** @class */ (function () {
    function SubscribePackagesComponent() {
        this.class = 'amg-subscription-packages';
    }
    SubscribePackagesComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
    };
    SubscribePackagesComponent.prototype.getCss = function () {
        return this.subscribePackages.cssClass;
    };
    return SubscribePackagesComponent;
}());
export { SubscribePackagesComponent };
