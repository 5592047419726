import {
  AmgComponent,
  AmgComponentStaticProperties,
} from 'amg';

import { CardFixtureStaticProperties } from 'card';

export class CalendarStaticProperties implements AmgComponentStaticProperties {
  _tabs: AmgComponent;

  showTabsAsText?: boolean;
  cardFixtureStaticProperties?: CardFixtureStaticProperties;
}
