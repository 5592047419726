import { OnInit, QueryList, ViewContainerRef, OnDestroy } from '@angular/core';
import { BootstrapViewportService, } from 'amg';
import { CardImageResponsiveProperties } from './card.image.responsive.properties';
import { CardImageStaticProperties } from './card.image.static.properties';
var CardImageComponent = /** @class */ (function () {
    function CardImageComponent(bootstrapViewportService) {
        this.bootstrapViewportService = bootstrapViewportService;
        this.responsiveProperties = new CardImageResponsiveProperties();
        this.staticProperties = new CardImageStaticProperties();
    }
    CardImageComponent.prototype.ngOnInit = function () {
        var _this = this;
        var viewportSubscriber = this.bootstrapViewportService
            .getViewportSubscriber();
        var viewport = this.bootstrapViewportService.getViewport();
        this.componentCurrentView = this.responsiveProperties[viewport];
        this.componentViewportSubscription = viewportSubscriber
            .subscribe(function (newBreakpoint) {
            if (_this.responsiveTimeout) {
                clearTimeout(_this.responsiveTimeout);
                _this.responsiveTimeout = null;
            }
            _this.responsiveTimeout = setTimeout(function () {
                _this.componentCurrentView = _this.responsiveProperties[newBreakpoint];
            }, _this.componentCurrentView.refreshRate);
        });
    };
    CardImageComponent.prototype.ngOnDestroy = function () {
        this.componentViewportSubscription.unsubscribe();
    };
    CardImageComponent.prototype.getTitle = function () {
        return this.componentData.title;
    };
    CardImageComponent.prototype.getThumbnail = function () {
        return this.componentData.imageUrl;
    };
    CardImageComponent.prototype.getThumbnailAsBackground = function () {
        return "url(" + this.getThumbnail() + ")";
    };
    return CardImageComponent;
}());
export { CardImageComponent };
