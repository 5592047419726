import { AmgFixture } from 'amg';

import { CardType } from 'card';

import {
  CardFixture,
  CardFixtureResponsiveProperties,
  CardFixtureResponsivePropertiesView,
  CardFixtureStaticProperties,
  DateObject,
} from 'card-fixture';

import {
  CarouselResponsiveProperties,
  CarouselResponsivePropertiesView,
  CarouselStaticProperties,
} from 'carousel';

import { CalendarTab } from '../calendar.tab.enum';
import { CalendarTabData } from '../calendar.tab.data';

import { CalendarResponsiveProperties } from '../calendar.responsive.properties';
import { CalendarResponsivePropertiesView } from '../calendar.responsive.properties.view';
import { CalendarStaticProperties } from '../calendar.static.properties';

export class CalendarBuild {
  private static keys: Array<string> = ['xs', 'sm', 'md', 'lg', 'xl', 'HD', 'FullHd', 'FourK'];

  private static convertMonthsToText(month: number): string {
    switch (month) {
      case 1:
        return 'January';
      case 2:
        return 'February';
      case 3:
        return 'March';
      case 4:
        return 'April';
      case 5:
        return 'May';
      case 6:
        return 'June';
      case 7:
        return 'July';
      case 8:
        return 'August';
      case 9:
        return 'September';
      case 10:
        return 'October';
      case 11:
        return 'November';
      case 12:
        return 'December';
    }

    return 'N/A';
  }

  public generateResponsiveViews(
    responsiveProperties: CalendarResponsiveProperties,
  ): any {
    let tabResponsive:any = {};
    let fixtureResponsive: any = {};

    CalendarBuild.keys.forEach((viewPort: string) => {
      let componentObjectResponsive = responsiveProperties[viewPort] as CalendarResponsivePropertiesView;

      tabResponsive[viewPort] = {
        limitViewableItems: componentObjectResponsive.tabs,
        visibleItemsOnHover: componentObjectResponsive.tabsWhenHovered,
        buttonText: componentObjectResponsive.tabNavText,
        refreshRate: componentObjectResponsive.refreshRate,
        showDots: false,
        showButtons: false,
        mouseDrag: false,
      } as unknown as CarouselResponsivePropertiesView;

      fixtureResponsive[viewPort] = componentObjectResponsive.fixtureView;

    });

    const responsiveViews = {
      tabResponsive: new CarouselResponsiveProperties({
        xs: tabResponsive.xs as CarouselResponsivePropertiesView,
        sm: tabResponsive.sm as CarouselResponsivePropertiesView,
        md: tabResponsive.md as CarouselResponsivePropertiesView,
        lg: tabResponsive.lg as CarouselResponsivePropertiesView,
        xl: tabResponsive.xl as CarouselResponsivePropertiesView,
        HD: tabResponsive.HD as CarouselResponsivePropertiesView,
        FullHd: tabResponsive.FullHd as CarouselResponsivePropertiesView,
        FourK: tabResponsive.FourK as CarouselResponsivePropertiesView,
      }),
      fixtureResponsive: new CardFixtureResponsiveProperties({
        xs: fixtureResponsive.xs as CardFixtureResponsivePropertiesView,
        sm: fixtureResponsive.sm as CardFixtureResponsivePropertiesView,
        md: fixtureResponsive.md as CardFixtureResponsivePropertiesView,
        lg: fixtureResponsive.lg as CardFixtureResponsivePropertiesView,
        xl: fixtureResponsive.xl as CardFixtureResponsivePropertiesView,
        HD: tabResponsive.HD as CarouselResponsivePropertiesView,
        FullHd: tabResponsive.FullHd as CarouselResponsivePropertiesView,
        FourK: tabResponsive.FourK as CarouselResponsivePropertiesView,
      }),
    };

    return responsiveViews;
  }

  public generateStaticViews(
    staticProperties: CalendarStaticProperties,
  ): any {
    const tabStatic = {
      carouselTile: CardType.Text,
      carouselSidebar: undefined,
      cardStaticProperties: {
        keys: [
          'day',
          ( staticProperties.showTabsAsText ? 'monthText' : 'month'),
          'year',
        ]
      }
    } as unknown as CarouselStaticProperties;

    const fixtureStatic = staticProperties.cardFixtureStaticProperties || {
    } as CardFixtureStaticProperties;

    return {
      fixtureStatic,
      tabStatic,
    };
  }

  private keepTrackOfInterestingDates(startDate: string, interestingDates: any): any {
    const fixtureDate = new Date(startDate);

    const tmpYear = `${fixtureDate.getFullYear()}`;
    const tmpMonthYear = `${tmpYear}-${fixtureDate.getMonth() + 1}`;
    const tmpDayMonthYear = `${tmpMonthYear}-${fixtureDate.getDate()}`;

    interestingDates.daysMonthsYear[tmpDayMonthYear] = fixtureDate;
    interestingDates.monthsYear[tmpMonthYear] = fixtureDate;
    interestingDates.year[tmpYear] = fixtureDate;

    return interestingDates;
  }
  // private generateButtonsForFixture(componentData: Calendar): Array<CardFixtureResponsivePropertiesViewBtn> {
  //   let newCardFixtureBtns: Array<CardFixtureResponsivePropertiesViewBtn> = [];

  //   return newCardFixtureBtns;
  // }

  private generateCalendarTabYear(tabYears: any): Array<CalendarTabData> {
    let calendarTabData: Array<CalendarTabData> = [];

    Object.keys(tabYears)
      .forEach((year: string) => {
        let date: Date = tabYears[year];
        let filter: CalendarTabData = {
          year: date.getFullYear().toString(),
        };
        calendarTabData.push(filter);
      });

    return calendarTabData;
  }
  private generateCalendarTabMonthYear(tabMonthsYears: any): Array<CalendarTabData> {
    const calendarTabData: Array<CalendarTabData> = [];

    Object.keys(tabMonthsYears)
      .forEach((monthYear: string) => {
        const date: Date = tabMonthsYears[monthYear];
        const filter: CalendarTabData = {
          monthText: CalendarBuild.convertMonthsToText(date.getMonth() + 1),
          month: (date.getMonth() + 1).toString(),
          year: date.getFullYear().toString(),
        };
        calendarTabData.push(filter);
      });

    return calendarTabData;
  }

  private generateCalendarTabDayMonthYear(tabDaysMonthsYears: any): Array<CalendarTabData> {
    let calendarTabData: Array<CalendarTabData> = [];

    Object.keys(tabDaysMonthsYears)
      .forEach((dayMonthYear: string) => {
        let date: Date = tabDaysMonthsYears[dayMonthYear];
        let filter: CalendarTabData = {
          day: date.getDate().toString(),
          month: (date.getMonth() + 1).toString(),
          year: date.getFullYear().toString(),
        };
        calendarTabData.push(filter);
      });

    return calendarTabData;
  }

  private selectCalendarTabDataForView(
    calendarTab: CalendarTab,
    tabYear: Array<CalendarTabData>,
    tabMonthYear: Array<CalendarTabData>,
    tabDayMonthYear: Array<CalendarTabData>,
  ): Array<CalendarTabData> {
    let calendarTabData: Array<CalendarTabData> = [];

    switch (calendarTab) {
      case CalendarTab.Day: {
        calendarTabData = tabDayMonthYear;
        break;
      }
      case CalendarTab.Month: {
        calendarTabData = tabMonthYear;
        break;
      }
      case CalendarTab.Year: {
        calendarTabData = tabYear;
        break;
      }
    }

    return calendarTabData;
  }

  buildCalendar(
    componentData: Array<AmgFixture>,
    responsiveProperties: CalendarResponsiveProperties): any {

    const fixtureData: Array<CardFixture> = [];
    const tabData: Array<CalendarTabData> = [];
    const tabsResponsive = {};

    let interestingDates = {
      daysMonthsYear: {},
      monthsYear: {},
      year: {},
    };
    componentData.forEach((amgFixture: AmgFixture) => {
      interestingDates = this.keepTrackOfInterestingDates(amgFixture.startDate, interestingDates);

      const newFixture: CardFixture = {
        ...amgFixture,
        fixtureDate: new DateObject(amgFixture.startDate, false),
      };
      fixtureData.push(newFixture);
    });

    // Start convert fixture dates to tabs
    const tabYear: Array<CalendarTabData> = this.generateCalendarTabYear(interestingDates.year);
    const tabMonthYear: Array<CalendarTabData> = this.generateCalendarTabMonthYear(interestingDates.monthsYear);
    const tabDayMonthYear: Array<CalendarTabData> = this.generateCalendarTabDayMonthYear(interestingDates.daysMonthsYear);

    CalendarBuild.keys.forEach((viewPort: string) => {
      const componentObjectResponsive = responsiveProperties[viewPort] as CalendarResponsivePropertiesView;
      tabsResponsive[viewPort] = this.selectCalendarTabDataForView(
        componentObjectResponsive.tab,
        tabYear,
        tabMonthYear,
        tabDayMonthYear,
      );
    });
    // end convert fixture dates to tabs

    const build: any = {
      fixtureData,
      tabData,
      tabsResponsive
    };

    return build;
  }
}
