import { OnInit } from '@angular/core';
import { Advertisement } from 'wordpress-adapter';
var AdvertisementComponent = /** @class */ (function () {
    function AdvertisementComponent() {
        this.class = 'amg-advertisement';
    }
    AdvertisementComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
    };
    AdvertisementComponent.prototype.getCss = function () {
        return this.advertisement.cssClass;
    };
    return AdvertisementComponent;
}());
export { AdvertisementComponent };
