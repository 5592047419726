import { CardFixtureResponsivePropertiesView } from 'card-fixture';

import { CalendarTab } from './calendar.tab.enum';

export class CalendarResponsivePropertiesView {
  tab: CalendarTab;
  tabs: number;
  tabsWhenHovered: number;
  tabNavText: Array<string>;
  refreshRate: number;

  fixtureView: CardFixtureResponsivePropertiesView;

  public static defaultValues(input?: CalendarResponsivePropertiesView):CalendarResponsivePropertiesView {
    let toReturn: CalendarResponsivePropertiesView;
    if (input === undefined) {
      toReturn = new CalendarResponsivePropertiesView();
    } else {
      toReturn = input;
    }
    
    toReturn = {...toReturn, ...{
      tab: CalendarTab.Month,
      tabs: 3,
      tabsWhenHovered: 3,
      tabNavText: ['after','before'],
      refreshRate: 200,

      fixtureView: { } as CardFixtureResponsivePropertiesView,
    }};

    return toReturn;
  }
}
