import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AnywhereData, AnywhereResponse, SectionData } from 'amg-fe-types';
import { AnywhereUtils } from 'amg-fe-utils';
import { Subscription } from 'rxjs';
import { AnywhereVideoCard } from 'wordpress-adapter';
import { DataService } from '../../../../../shared/data/data.service';

@Component({
  selector: 'amg-wp-anywhere-video-card',
  templateUrl: './anywhere-video-card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AnywhereVideoCardComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-video-card';
  @Input() videoCard: AnywhereVideoCard;

  public cardData: any;

  public isLoading: boolean;
  public isError: boolean;

  private apiUrl: string;
  private apiDataObservable: Subscription;

  constructor(private httpClient: HttpClient,
              private dataService: DataService,
              private router: Router) {
    this.isLoading = true;
    this.isError = false;
  }

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.initialiseCard();
    this.triggerApiCall(this.apiUrl);
  }

  ngOnDestroy(): void {
    if (this.apiDataObservable) {
      this.apiDataObservable.unsubscribe();
    }
  }

  onCardClick(data: any) {
    if (this.videoCard.onClickNavigate) {
      const playlistUrl = AnywhereUtils.generateInitAnywhereUrl(this.apiUrl);
      const url = this.dataService.encodeUrl(playlistUrl);
      const entryId = (data && data.mediaData && data.mediaData.entryId)
        ? data.mediaData.entryId
        : null;

      this.dataService.userInteractedObject = {
        playlistUrl,
        data
      };

      this.router.navigate([this.videoCard.onClickNavigate], {
        queryParams: {
          playlist: url,
          ...(entryId ? {entry: entryId} : {})
        },
        preserveQueryParams: false
      });
    }
  }

  getCss(): string {
    return this.videoCard.cssClass;
  }

  private initialiseCard(): void {
    this.cardData = null;
    this.apiUrl = this.videoCard.anywhereApiUrl;
  }

  private triggerApiCall(apiUrl: string): void {
    this.apiDataObservable = this.httpClient.get<AnywhereResponse<any>>(apiUrl).subscribe(data => {
      if (data) {
        const extractedData: AnywhereData<any> | SectionData<any> = AnywhereUtils.extractAnywhereData(data);

        if (extractedData) {
          this.cardData = extractedData.itemData.length > 0
            ? extractedData.itemData[0]
            : null;
        }
      }

      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.isError = true;
    });
  }
}
