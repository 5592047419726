import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var Icons = /** @class */ (function (_super) {
    tslib_1.__extends(Icons, _super);
    function Icons(data) {
        var _this = _super.call(this, 'Icons', data) || this;
        _this._icons = data.icons;
        return _this;
    }
    Object.defineProperty(Icons.prototype, "icons", {
        get: function () {
            return this._icons;
        },
        enumerable: true,
        configurable: true
    });
    return Icons;
}(BaseObject));
export { Icons };
