import {Inject, Injectable} from '@angular/core';
import {DeviceUtils} from 'amg-fe-utils';
import {
  AnywhereItemData,
  AnywhereMediaData,
  AnywhereMediaType,
  AnywhereMembership,
  EntryAndKs,
  KalturaPlayerConfig,
  LiveLoginOverlay,
  Scripts,
  UserState,
  VideoPlayerConfig
} from './_data/video-player.model';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {StreamPaymentsHooks, UserAuthService} from 'user-auth';
import {GenericPaymentResponse} from './_data/user-auth.model';
import {EntryNavItem} from './video-player/entry-nav/entry-nav.model';
import {UserAuthSsoService} from '@amg-fe-angular/services/user-auth/user-auth-sso.service';

declare var document: any;
export const ScriptStore: Scripts[] = [];

@Injectable({
  providedIn: 'root'
})

export class VideoPlayerService {
  public readonly autoPlayStorageHandle = 'AMG-AUTOPLAY';
  entryObjBSubject: BehaviorSubject<EntryAndKs> = new BehaviorSubject<EntryAndKs>(null);
  autoplayBSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  liveLoginOverlayBSubject: BehaviorSubject<LiveLoginOverlay> = new BehaviorSubject<LiveLoginOverlay>(null);
  allowChangeMediaBSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  // entry-nav.component observable
  entryNavBSubject: BehaviorSubject<EntryNavItem[]> = new BehaviorSubject<EntryNavItem[]>(null);
  entryObjLocalRef: EntryAndKs;
  localAnywhereMediaData: AnywhereItemData;
  userState: UserState;
  mediaAuthResponse: any;

  private _videoPlayerModuleConfig: VideoPlayerConfig;
// Observables
  private anywhereMediaDataBSubject: BehaviorSubject<AnywhereItemData> = new BehaviorSubject<AnywhereItemData>(null);
  private videoPlayerStateBSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private userDataStateBSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
// Vars
  private scripts: any = {};


  constructor(@Inject('videoPlayerModuleConfig')
              private videoPlayerModuleConfig: VideoPlayerConfig,
              public http: HttpClient,
              private userAuthService: UserAuthService,
              private userAuthServiceNew: UserAuthSsoService,
              ) {
    this.entryObjLocalRef = {
      entryId: '',
      ks: '',
    };

    if (videoPlayerModuleConfig) {
      this.videoPlayerConfig = videoPlayerModuleConfig;
      if (videoPlayerModuleConfig.navEntries) {
        this.entryNavBSubject.next(videoPlayerModuleConfig.navEntries);
      }
    }

    this.userAuthService.customerSessionData.subscribe(userData => {
      this.setUserState(userData);
    });

    /**
     * Subscribe to entryID and KS;
     * Assign entryID and KS to local variable;
     * Request KSession from streamPayments;
     * On KSession received - dispatch Object {entryId, ks};
     */
    this.getEntryAndKSBSubject()
      .subscribe(res => {
        if (res && !res.ks) {
          this.entryObjLocalRef = res;
          this.amgLogger('getEntryAndKSBSubject', res);
          this.userAuthServiceNew.streamPaymentsGetKSession({entryId: res.entryId}, (ksResponse) => {
            this.mediaAuthResponse = ksResponse;
            if (ksResponse.KSession) {
              this.setEntryAndKSBSubject(res.entryId, ksResponse);
            }

            this.userAuthService.setUserSubscriptionState(!!ksResponse.KSession);
          });
            // .
            // .hookStreamPayments(StreamPaymentsHooks.getKSession, {
            //   entryId: res.entryId,
            //   ...(DeviceUtils.userAgent.IE
            //     ? {
            //       a: Date.now().toString() // IE fix to invalidate the cache.
            //     } : {})
            // })
            // .then(ksResponse => {
            //   this.mediaAuthResponse = ksResponse;
            //   if (ksResponse.KSession) {
            //     this.setEntryAndKSBSubject(res.entryId, ksResponse);
            //   }
            //
            //   this.userAuthService.setUserSubscriptionState(!!ksResponse.KSession);
            // });
        }
      });
  }

  set videoPlayerConfig(videoPlayerConfiguration: VideoPlayerConfig) {
    if (videoPlayerConfiguration) {
      this._videoPlayerModuleConfig = {
        ...videoPlayerConfiguration,
        ...(this.autoPlayStorageHandle
          ? {}
          : {autoPlayStorageHandle: this.autoPlayStorageHandle})
      };
    } else {
      console.error('No configuration provided to player @');
    }
  }

  get userData(): Observable<GenericPaymentResponse> {
    return this.userDataStateBSubject.asObservable();
  }

  set userData(data) {
    this.userDataStateBSubject.next(data);
  }

  getEntryAndKSBSubject(): Observable<EntryAndKs> {
    return this.entryObjBSubject;
  }

  setEntryAndKSBSubject(entryId, ks?: { KSession?: string }) {
    if (entryId) {
      const kSession = (ks && ks.KSession) ? ks.KSession : '';

      const entryIdCmp = entryId === this.entryObjLocalRef.entryId;
      const kSessionCmp = kSession === this.entryObjLocalRef.ks;


      const ksNull = (kSession.length === 0);

      const updateEntryObj: boolean = (
        ((!entryIdCmp)) ||                       // if new entry id
        ((entryIdCmp) && (!kSessionCmp) && (!ksNull)) // if same entry id and new ks and ks is not null
      );

      if (updateEntryObj) {
        this.entryObjLocalRef = {entryId, ks: kSession};
        this.entryObjBSubject.next({...{entryId, ks: kSession}});
      }
    }
  }

  changeMediaMessage(videoItem) {
    if (videoItem) {
      const entryId = (videoItem.mediaData && videoItem.mediaData.entryId)
        ? videoItem.mediaData.entryId
        : videoItem.entryId;
      this.setEntryAndKSBSubject(entryId, null);
    }
  }

  setVideoPlayerStateBSubject(eventData: { event: any, data: any }) {
    if (eventData.event !== 'playerUpdatePlayhead') {
      this.amgLogger('setVideoPlayerStateBSubject', eventData);
    }
    this.videoPlayerStateBSubject.next(eventData);
  }

  getVideoPlayerStateBSubject(): Observable<any> {
    return this.videoPlayerStateBSubject.asObservable();
  }

  getAnywhereMediaDataBSubject(): Observable<AnywhereItemData> {
    return this.anywhereMediaDataBSubject.asObservable();
  }

  setAnywhereMediaDataBSubject(anywhereMediaData: AnywhereItemData) {
    if (anywhereMediaData) {
      const media = anywhereMediaData.mediaData;
      if (media.entryId) {
        this.setEntryAndKSBSubject(media.entryId);
      } else {
        const matchedMedia: AnywhereMediaData = this.findEntryInMediaData(anywhereMediaData.mediaData.entryId, media);
        if (matchedMedia) {
          this.setEntryAndKSBSubject(matchedMedia.entryId);
        }
      }

      this.anywhereMediaDataBSubject.next(anywhereMediaData);
      this.localAnywhereMediaData = anywhereMediaData;
    }
    this.amgLogger('setAnywhereMediaDataBSubject', anywhereMediaData);
  }

  setUserState(userData) {
    if (userData) {
      this.userData = userData;
      if (userData.CurrentCustomerSession !== null) {
        this.userState = UserState.loggedIn;
      } else {
        this.userState = UserState.notLoggedIn;
      }
    }
  }

  fetchPlaylistData(url): any {
    return this.http.get(url);
  }

  getKalturaApi(playerConfig: KalturaPlayerConfig) {
    const url = this.buildPlayerUrl(playerConfig);
    ScriptStore.push({name: 'kalturaApi', src: url});

    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      }
    });
  }

  getMediaType(): AnywhereMediaType {
    if (!this.isLive() && !this.isOnDemand()) {
      console.error('Could not determine MediaType');
    }
    if (this.isLive()) {
      return AnywhereMediaType.Live;
    } else if (this.isOnDemand()) {
      return AnywhereMediaType.OnDemand;
    }
  }

  /**
   * Extract required membership from anywhere json object;
   * Assign extracted value to active(currently playing) video;
   */
  getMediaMembership() {
    const data = this.localAnywhereMediaData;
    if (data) {
      const meta = data.metaData;
      const metaMembership = (meta && meta.membership) ? meta.membership : null;

      const media = data.mediaData;

      let membership: AnywhereMembership = metaMembership
        ? metaMembership
        : this.getMediaType() === AnywhereMediaType.Live
          ? AnywhereMembership.Paid
          : AnywhereMembership.Free;

      if (this.findEntryInMediaData(this.entryObjLocalRef.entryId, media) && !media.entryId) {
        membership = AnywhereMembership.Paid;
      }

      if (membership) {
        return membership;
      }

      if (meta && meta.SysEntryEntitlements) {
        return data.metaData.SysEntryEntitlements[0];
      }
      return false;
    }
  }

  findEntryInMediaData(entryId, mediaData): AnywhereMediaData {
    let matchedMedia = null;
    if (Array.isArray(mediaData) && mediaData.length > 0) {
      mediaData.forEach((media) => {
        if ((media.entryId === entryId) || (media.entryId === this.entryObjLocalRef.entryId)) {
          matchedMedia = media;
        }
      });
    } else if (!Array.isArray(mediaData)) {
      return mediaData;
    }
    return matchedMedia;
  }

  isFreeMembership() {
    const membership = this.getMediaMembership();
    return membership === AnywhereMembership.Free
      || membership === false
      || membership === ''
      || !membership;
  }

  isPaidMembership() {
    return this.getMediaMembership() === AnywhereMembership.Paid;
  }

  isRegisteredMembership() {
    return this.getMediaMembership() === AnywhereMembership.Registered;
  }

  amgLogger(functionName, data) {
    // console.log(' Function Name: ', functionName);
    // console.log(' Data: ', data);
    // console.log(' --- ');
  }

  canUserPlayMedia() {
    if (this.mediaAuthResponse) {
      const status = this.mediaAuthResponse.Status;
      if (status === 0) {
        return false;
      } else if (status === 1) {
        return false;
      } else if (status === -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  findAnywhereItems(playlistData): Array<AnywhereItemData> {
    let itemData = null;
    if (playlistData) {
      if (playlistData.itemData) {
        itemData = playlistData.itemData;
      } else if (playlistData.sections && playlistData.sections[0]) {
        if (playlistData.sections[0].itemData) {
          itemData = playlistData.sections[0].itemData;
        }
      } else if (playlistData.fixtures) {
        itemData = playlistData.fixtures;
      }
    }
    return itemData;
  }

  isLive() {
    const data = this.localAnywhereMediaData;
    if (data) {
      const media = data.mediaData;
      const isFixture = (media.length > 0) && (media[0].isLiveUrl || media[1].isLiveUrl);
      const foundEntry = this.findEntryInMediaData(null, media);

      if (media && media.mediaType === AnywhereMediaType.Live
        || foundEntry && foundEntry.isLiveUrl
        || isFixture) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  isOnDemand() {
    const data = this.localAnywhereMediaData;
    if (data) {
      const media = data.mediaData;
      const foundEntry = this.findEntryInMediaData(null, media);
      return foundEntry && data && foundEntry.mediaType === AnywhereMediaType.OnDemand;
    } else {
      return false;
    }
  }

  private buildPlayerUrl(playerConfig: KalturaPlayerConfig): string {
    return `${playerConfig.apiUrl}/p/${playerConfig.partnerId}/sp/${playerConfig.partnerId}00/embedIframeJs/uiconf_id/${playerConfig.uiConfId}/partner_id/${playerConfig.partnerId}`;
  }

}
