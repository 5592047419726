/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../section/elements/payment-elements/user-sign-in-page/user-sign-in-page.component.ngfactory";
import * as i2 from "../../section/elements/payment-elements/user-sign-in-page/user-sign-in-page.component";
import * as i3 from "@angular/common";
import * as i4 from "./overlay.component";
import * as i5 from "../../../shared/api/api.service";
var styles_OverlayComponent = [];
var RenderType_OverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OverlayComponent, data: {} });
export { RenderType_OverlayComponent as RenderType_OverlayComponent };
function View_OverlayComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-user-sign-in-page", [], [[1, "class", 0]], null, null, i1.View_UserSignInPageComponent_0, i1.RenderType_UserSignInPageComponent)), i0.ɵdid(1, 114688, null, 0, i2.UserSignInPageComponent, [], { userSignInPage: [0, "userSignInPage"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
function View_OverlayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, null, null, null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i3.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverlayComponent_2)), i0.ɵdid(4, 278528, null, 0, i3.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.objectType; _ck(_v, 2, 0, currVal_0); var currVal_1 = "PaymentUserSignInPage"; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_OverlayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverlayComponent_1)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getElements(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-overlay", [], [[1, "class", 0]], null, null, View_OverlayComponent_0, RenderType_OverlayComponent)), i0.ɵdid(1, 49152, null, 0, i4.OverlayComponent, [i5.ApiService], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var OverlayComponentNgFactory = i0.ɵccf("amg-wp-overlay", i4.OverlayComponent, View_OverlayComponent_Host_0, { overlay: "overlay" }, {}, []);
export { OverlayComponentNgFactory as OverlayComponentNgFactory };
