import { GridResponsivePropertiesView } from '../grid.responsive.properties.view';

export class GridColumns {

  private _columnsStyle: any = {};

  private _currentView: GridResponsivePropertiesView;

  /**
   * Store slide widths for the view
   * @param detailsActive check if we cater for the information sidebar
   * @param currentView the current viewport data
   */
  constructor(currentView: GridResponsivePropertiesView) {
    this.setCurrentView('constructor', currentView);
  }

  /**
   * Change current viewport data
   * @param source
   * @param currentView the current viewport data
   */
  setCurrentView(source: string = 'unknown', currentView: GridResponsivePropertiesView) {
    this._currentView = currentView;
    // TODO quick fix, to be defined in the final project css, not in the component...
    //this.recalculateColumns(source);
  }

  /**
   * Recalculate column CSS
   * @param source
   * @param normalItems number of items to show
   */
  private _recalculateColumns(source: string = 'unknown', normalItems: number) {
    let flex = 100 / normalItems;

    let toReturn = {
      flex: `0 0 ${flex}%`,
      width: `${flex}%`,
      maxWidth: `${flex}%`,
      paddingRight: `${this._currentView.margin}px`,
    };

    return toReturn;
  }
  /**
   * Recalculate column width
   * @param source
   */
  recalculateColumns(source: string = 'unknown'): any {
    this._columnsStyle = this._recalculateColumns(source, this._currentView.items);
  }

  /**
   * Get cached column styles
   */
  getColumn(): any {
    return this._columnsStyle;
  }
}
