import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardProfileComponent } from './card-profile.component';

@NgModule({
  declarations: [
    CardProfileComponent,
  ],
  imports: [
    CommonModule,
  ],
  entryComponents: [
    CardProfileComponent,
  ],
  exports: [
    CardProfileComponent,
  ]
})
export class CardProfileModule { }
