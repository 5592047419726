import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PaymentLoginButtonGroup, Button } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-login-button-group',
  templateUrl: './login-button-group.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LoginButtonGroupComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-login-button-group';
  @Input() loginButtonGroup: PaymentLoginButtonGroup;

  ngOnInit(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }
  }

  getCss(): string {
    return this.loginButtonGroup.cssClass;
  }

  getLoginButton(): Button {
    return this.loginButtonGroup.loginButton;
  }

  getLogoutButton(): Button {
    return this.loginButtonGroup.logOutButton;
  }

  getRegisterButton(): Button {
    return this.loginButtonGroup.registerButton;
  }

  getSubscribeButton(): Button {
    return this.loginButtonGroup.subscribeButton;
  }
}
