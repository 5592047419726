import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Grid as WpGrid } from 'wordpress-adapter';
import { GridStaticProperties, GridResponsiveProperties, GridSource, SearchTrigger } from 'grid';
import { CardType } from 'card';
import { DataService } from '../../../../../shared/data/data.service';

@Component({
  selector: 'amg-wp-grid',
  templateUrl: './grid.component.html',
  encapsulation: ViewEncapsulation.None
})
export class GridComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-grid';
  @Output() triggerChange: EventEmitter<string>;
  @Input() grid: WpGrid;

  public gridData: any[];
  public gridResponsiveProperties: any;
  public gridStaticProperties: GridStaticProperties;

  private currentSearchValue: string;
  private searchSubject: BehaviorSubject<string>;
  private routerObservable: Subscription;

  private gridUrl: string;

  private static getGridCardType(cardType: string): CardType {
    switch (cardType) {
      case 'Video':
        return CardType.Video;
      case 'Image':
        return CardType.Image;
      case 'Fixture':
        return CardType.Fixture;
      default:
        return CardType.Text;
    }
  }

  constructor(private dataService: DataService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.triggerChange = new EventEmitter<string>();
    this.searchSubject = new BehaviorSubject('');
  }

  ngOnDestroy(): void {
    this.searchSubject.complete();
  }

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.currentSearchValue = this.activatedRoute.snapshot.queryParams.search || '';
    this.searchSubject.next(this.currentSearchValue);
    this.gridData = [];
    this.gridResponsiveProperties = new GridResponsiveProperties(this.grid.gridConfiguration);
    this.gridStaticProperties = this.grid.gridStaticData;

    this.routerObservable = this.router.events.pipe(
      filter(event => event instanceof ActivationEnd)
    ).subscribe((event: ActivationEnd) => {
      const search = event.snapshot.queryParams.search;

      if (search && (this.currentSearchValue !== search)) {
        this.currentSearchValue = search;
        this.searchSubject.next(search);
      }
    });
  }

  onGridReRender(): void {
    this.triggerChange.emit('grid');
  }

  getSearchInputAsObservable(): Observable<string> {
    return this.searchSubject.asObservable();
  }

  getCss(): string {
    return this.grid.cssClass;
  }

  onGridSearch(searchValue: SearchTrigger): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        search: searchValue
      }
    });
  }

  onUpdateSearchUrl(update: any): void {
    if ((update) && (update.url)) {
        this.gridUrl = update.url;
    } else {
      delete this.gridUrl;
    }
  }

  onCardClick(data: any): void {
    if (this.grid.onClickNavigate) {
      let url: string,
        entryId: string;

      if (this.grid.gridStaticData.pageSource === GridSource.Wordpress) {
        url = data.playlist_url || data.raw.playlist_url;
        entryId = null;
      } else {
        url = this.gridUrl || this.grid.gridStaticData.pageUrl;
        entryId = (data && data.mediaData && data.mediaData.entryId)
          ? data.mediaData.entryId
          : null;
      }

      this.dataService.userInteractedObject = this.dataService.userInteractedObject = {
        playlistUrl: url,
        data
      };

      this.router.navigate([this.grid.onClickNavigate], {
        queryParams: {
          playlist: this.dataService.encodeUrl(url),
          ...(entryId ? {entry: entryId} : {})
        },
        preserveQueryParams: false
      });
    }
  }

  getCurrentSearchValue(): string {
    return this.currentSearchValue;
  }
}
