/**
 * Certain objects from Wordpress cannot be modified to include the object_type
 *  and therefore we exclude them from being mapped to objects.
 */
export const excludedKeys = [
  /* AMG Properties */
  'amg_style',
  'amg_payments',
  'amg_options',
  'amg_background_image',
  'amg_play_button_group',

  /* Wordpress Properties */
  'sizes',
  'image'
];
