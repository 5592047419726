import * as tslib_1 from "tslib";
import { BaseObject } from '../BaseObject';
var AnywhereApiConfiguration = /** @class */ (function (_super) {
    tslib_1.__extends(AnywhereApiConfiguration, _super);
    /**
     *
     * @param data - The data from the WordPress API or from the internal function 'merge'.
     * @param isDataMerge - A flag to indicate where it is coming from.
     */
    function AnywhereApiConfiguration(data, isDataMerge) {
        if (isDataMerge === void 0) { isDataMerge = false; }
        var _this = _super.call(this, 'AnywhereApiConfiguration', data) || this;
        if (!isDataMerge) {
            if (data.anywhere_api_configuration) {
                data.anywhere_api_configuration.forEach(function (config) {
                    if (config.api_feed_id) {
                        _this._apiFeedId = config.api_feed_id;
                    }
                    else if (config.search_query) {
                        _this._searchQuery = config.search_query;
                    }
                    else if (config.sort_order) {
                        _this._sortOrder = config.sort_order;
                    }
                    else if (config.section_id) {
                        _this._sectionId = config.section_id;
                    }
                    else if (config.page_index) {
                        _this._pageIndex = config.page_index;
                    }
                    else if (config.sort_by) {
                        _this._sortBy = config.sort_by;
                    }
                    else if (config.base_url) {
                        _this._baseUrl = config.base_url;
                    }
                    else if (config.api_request_method) {
                        _this._apiRequestMethod = config.api_request_method;
                    }
                    else if (config.page_size) {
                        _this._pageSize = config.page_size;
                    }
                    else if (config.target_id) {
                        _this._targetId = config.target_id;
                    }
                });
            }
        }
        else {
            _this._apiFeedId = data.apiFeedId;
            _this._searchQuery = data.searchQuery;
            _this._sortOrder = data.sortOrder;
            _this._sectionId = data.sectionId;
            _this._pageIndex = data.pageIndex;
            _this._pageSize = data.pageSize;
            _this._sortBy = data.sortBy;
            _this._targetId = data.targetId;
            _this._baseUrl = data.baseUrl;
            _this._apiRequestMethod = data.apiRequestMethod;
        }
        return _this;
    }
    /**
     * Merges the parent configuration with the child configuration. Child data always takes precedent.
     * @param parentConfig - API configuration coming from the parent.
     * @param childConfig - API configuration coming for the current object.
     */
    AnywhereApiConfiguration.merge = function (parentConfig, childConfig) {
        return new AnywhereApiConfiguration({
            baseUrl: (childConfig && childConfig.baseUrl) ? childConfig.baseUrl : parentConfig.baseUrl,
            apiFeedId: (childConfig && childConfig.apiFeedId) ? childConfig.apiFeedId : parentConfig.apiFeedId,
            searchQuery: (childConfig && childConfig.searchQuery) ? childConfig.searchQuery : parentConfig.searchQuery,
            sortOrder: (childConfig && childConfig.sortOrder) ? childConfig.sortOrder : parentConfig.sortOrder,
            sectionId: (childConfig && childConfig.sectionId) ? childConfig.sectionId : parentConfig.sectionId,
            targetId: (childConfig && childConfig._targetId) ? childConfig._targetId : parentConfig._targetId,
            pageIndex: (childConfig && childConfig.pageIndex) ? childConfig.pageIndex : parentConfig.pageIndex,
            pageSize: (childConfig && childConfig.pageSize) ? childConfig.pageSize : parentConfig.pageSize,
            sortBy: (childConfig && childConfig.sortBy) ? childConfig.sortBy : parentConfig.sortBy,
            apiRequestMethod: (childConfig && childConfig.apiRequestMethod) ? childConfig.apiRequestMethod : parentConfig.apiRequestMethod
        }, true);
    };
    Object.defineProperty(AnywhereApiConfiguration.prototype, "targetId", {
        get: function () {
            return this._targetId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnywhereApiConfiguration.prototype, "pageSize", {
        get: function () {
            return this._pageSize;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnywhereApiConfiguration.prototype, "apiRequestMethod", {
        get: function () {
            return this._apiRequestMethod;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnywhereApiConfiguration.prototype, "apiFeedId", {
        get: function () {
            return this._apiFeedId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnywhereApiConfiguration.prototype, "searchQuery", {
        get: function () {
            return this._searchQuery;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnywhereApiConfiguration.prototype, "sortOrder", {
        get: function () {
            return this._sortOrder;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnywhereApiConfiguration.prototype, "sectionId", {
        get: function () {
            return this._sectionId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnywhereApiConfiguration.prototype, "pageIndex", {
        get: function () {
            return this._pageIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnywhereApiConfiguration.prototype, "sortBy", {
        get: function () {
            return this._sortBy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnywhereApiConfiguration.prototype, "baseUrl", {
        get: function () {
            return this._baseUrl;
        },
        enumerable: true,
        configurable: true
    });
    return AnywhereApiConfiguration;
}(BaseObject));
export { AnywhereApiConfiguration };
