import { CardUtils } from '../../../../../util/CardUtils';
import { NgObject } from './NgObject';

export class AnywhereVideoCard extends NgObject {
  private readonly _anywhereDisplayKeys: any;
  private readonly _anywhereApiUrl: string;

  constructor(data: any, objectType: string = 'AnywhereVideoCard') {
    super(objectType, data);

    this._anywhereApiUrl = data.anywhere_api_url;

    this._anywhereDisplayKeys = {
      displayKeys: CardUtils.generateCardDisplayKeys(data.cardDisplayKeys)
    };
  }

  get anywhereDisplayKeys(): any {
    return this._anywhereDisplayKeys;
  }

  get anywhereApiUrl(): string {
    return this._anywhereApiUrl;
  }
}
