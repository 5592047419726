import { EventEmitter } from '@angular/core';

export class CollapsibleClick {
  private _enableSlideClick = true;
  private _lastClicked: any;

  /**
   * Click handler class for collapsible
   */
  constructor(
    private _clickEvent: EventEmitter<any>,
  ) {
  }

  /**
   * Enable clicking
   * @param source
   */
  enableSlideClick(source: string = 'unknown') {
    this._enableSlideClick = true;
  }
  /**
   * Disable clicking
   * @param source
   */
  disableSlideClick(source: string = 'unknown') {
    this._enableSlideClick = false;
  }

  /**
   * Click on slide
   * @param source 
   * @param item item clicked on
   * @param type type of slide
   */
  slideClick(source: string = 'unknown', item: any) {
    if (this._enableSlideClick) {
      if (this._lastClicked !== item) {
        this._lastClicked = item;
        this._clickEvent.emit(item);
      }
    }
  }
}
