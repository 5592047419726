import {
  Component,
  Input,
  ViewChildren,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnInit,
  OnDestroy,
  AfterViewInit,
  QueryList,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  AmgComponent,
  BootstrapViewportEnum,
  BootstrapViewportService,
} from 'amg';

import {
  CollapsibleAfterViewInit,
  CollapsibleClick,
} from './collapsible.functions/collapsible.functions';

import {CollapsibleResponsiveProperties} from './collapsible.responsive.properties';
import {CollapsibleResponsivePropertiesView} from './collapsible.responsive.properties.view';
import {CollapsibleStaticProperties} from './collapsible.static.properties';

@Component({
  selector: 'amg-collapsible',
  templateUrl: 'collapsible.component.html',
  styles: [],
})
export class CollapsibleComponent implements OnInit, OnDestroy, AfterViewInit, AmgComponent {
  @Input() componentData: Array<any>;
  @Input() responsiveProperties: CollapsibleResponsiveProperties;
  @Input() staticProperties: CollapsibleStaticProperties;

  @ViewChildren('componentChild', {read: ViewContainerRef}) componentChildren: QueryList<ViewContainerRef>;

  componentViewportSubscription: Subscription;

  componentCurrentView: CollapsibleResponsivePropertiesView;

  responsiveTimeout: any;

  @Output() cardClicked = new EventEmitter<any>();

  private _click: CollapsibleClick;

  private _collapsed: boolean;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private bootstrapViewportService: BootstrapViewportService,
  ) {
  }

  ngOnInit() {
    const viewportSubscriber: Observable<BootstrapViewportEnum> = this.bootstrapViewportService
      .getViewportSubscriber();

    const viewport: BootstrapViewportEnum = this.bootstrapViewportService.getViewport();
    this.componentCurrentView = this.responsiveProperties[viewport] as CollapsibleResponsivePropertiesView;

    this._click = new CollapsibleClick(this.cardClicked);

    this._collapsed = true;

    this.componentViewportSubscription = viewportSubscriber
      .subscribe(newBreakpoint => {
        if (this.responsiveTimeout) {
          clearTimeout(this.responsiveTimeout);
          this.responsiveTimeout = undefined;
        }

        this.responsiveTimeout = setTimeout(() => {
          this.componentCurrentView = this.responsiveProperties[newBreakpoint] as CollapsibleResponsivePropertiesView;
        }, this.componentCurrentView.refreshRate);
      });
  }

  ngOnDestroy() {
    this.componentViewportSubscription.unsubscribe();
    if (this.responsiveTimeout) {
      clearTimeout(this.responsiveTimeout);
      this.responsiveTimeout = undefined;
    }
  }

  ngAfterViewInit() {
    new CollapsibleAfterViewInit(
      this.componentFactoryResolver,
      this.componentData,
      this.responsiveProperties,
      this.staticProperties,
      this.componentChildren,
    )
      .ngAfterViewInit();
  }

  toggleCollapse() {
    this._collapsed = !this._collapsed;
  }

  isCollapsed() {
    return this._collapsed && this.componentCurrentView.canCollapse;
  }

  collapseClass() {
    return this.isCollapsed() ? 'closed' : 'opened';
  }

  showToggle() {
    return (
      (this.componentCurrentView.canCollapse)
    );
  }

  getTitleText(): string {
    return this.staticProperties.title || '';
  }

  getSubTitleText(): string {
    return this.staticProperties.subtitle || '';
  }

  getToggleText(): string {
    return this._collapsed ? this.componentCurrentView.showText : this.componentCurrentView.hideText;
  }

  onSlideClicked(source: string, slide: any) {
    this._click.slideClick(source, slide);
  }

}
