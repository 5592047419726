import { BaseObject } from '../../../BaseObject';
import { Icon } from '../html-elements/Icon';

export class Icons extends BaseObject {
  private readonly _icons: Icon[];

  constructor(data: any) {
    super('Icons', data);

    this._icons = data.icons;
  }

  get icons(): Icon[] {
    return this._icons;
  }
}
