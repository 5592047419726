import dateFormat from 'dateformat';
import { DateTimeUtils } from 'amg-fe-utils';

export class CardUtils {
  public static generateCardDisplayKeys(
    data: { thumbnailKey: string; cardInformationKeys: any[]; cardBodyKeys: any[]; }): any {

    const displayKeys: any = {};

    if (data) {
      if (data.thumbnailKey) {
        displayKeys.thumbnailKey = {
          key: data.thumbnailKey,
          transform: CardUtils.formatAnywhereUrl
        };
      }
      if (data.cardInformationKeys) {
        displayKeys.cardInformationKeys = CardUtils.mapCardKeys(data.cardInformationKeys);
      }
      if (data.cardBodyKeys) {
        displayKeys.cardBodyKeys = CardUtils.mapCardKeys(data.cardBodyKeys);
      }
    }

    return displayKeys;
  }

  public static mapCardKeys(data: any[]): any[] {
    if (data) {
      return data.map(value => {
        const transformFunction: { transform?: (data: any) => string; } = {};

        if (value.isDuration) {
          transformFunction.transform = CardUtils.formatDuration;
        } else if (value.dateFormat) {
          transformFunction.transform = (innerData: any) => {
            return dateFormat(innerData, value.dateFormat);
          };
        }

        return {
          key: value.key,
          arraySeparator: value.arraySeparator,
          ...transformFunction
        };
      });
    }

    return [];
  }

  public static formatDuration(data: any): string {
    const object = DateTimeUtils.generateRuntimeObjectBySeconds(data);
    const hours = CardUtils.padSingleDigit(object.hours);
    const minutes = CardUtils.padSingleDigit(object.minutes);
    const seconds = CardUtils.padSingleDigit(object.seconds);

    return `<span class="hours" data-hours="${hours}">${object.hours}</span>`
      + `<span class="minutes" data-minutes="${minutes}">${object.minutes}</span>`
      + `<span class="seconds" data-seconds="${seconds}">${object.seconds}</span>`;
  }

  public static formatAnywhereUrl(data: string): string {
    return `${data}/width/550`;
  }

  public static padSingleDigit(data: number): string {
    const dataString = data.toString();

    return dataString.length === 1
      ? `0${dataString}`
      : dataString;
  }
}
