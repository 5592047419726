import { OnInit } from '@angular/core';
import { PaymentUserSignInPage } from 'wordpress-adapter';
var UserSignInPageComponent = /** @class */ (function () {
    function UserSignInPageComponent() {
        this.class = 'amg-user-authorisation-page';
    }
    UserSignInPageComponent.generateGenericInput = function (field) {
        return {
            label: field.label,
            placeholder: field.placeholder,
            values: field.values,
            value: field.value,
            input_type: field.type,
            required: field.required,
            internal_reference_name: field.referenceName
        };
    };
    UserSignInPageComponent.generateTextLink = function (field) {
        return {
            label: field.label,
            external_link: field.externalLink,
            path: field.path,
            type: field.type
        };
    };
    UserSignInPageComponent.generateOtherLinks = function (otherLinks) {
        // Lambda functions are not supported on static methods. Therefore assign the results to a constant first.
        var mappedOtherLinks = otherLinks.map(function (value) {
            return {
                title: value.title,
                call_to_action: UserSignInPageComponent.generateTextLink(value.textLinkButton)
            };
        });
        return mappedOtherLinks;
    };
    UserSignInPageComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
    };
    UserSignInPageComponent.prototype.getFormData = function () {
        var logoData = (this.userSignInPage.logo.imageData)
            ? { url: this.userSignInPage.logo.imageData.url,
                label: this.userSignInPage.logo.label,
                link: this.userSignInPage.logo.link }
            : { url: null, label: null, link: null };
        var formData = {
            logo: logoData,
            form: {
                emailaddress: UserSignInPageComponent.generateGenericInput(this.userSignInPage.loginFormGroup.emailAddress),
                password: UserSignInPageComponent.generateGenericInput(this.userSignInPage.loginFormGroup.password),
                submit_button: { label: this.userSignInPage.loginFormGroup.loginButton.label }
            },
            forgot_password: UserSignInPageComponent.generateTextLink(this.userSignInPage.forgotPasswordLink),
            links: UserSignInPageComponent.generateOtherLinks(this.userSignInPage.textLinks)
        };
        return formData;
    };
    UserSignInPageComponent.prototype.getCss = function () {
        return this.userSignInPage.cssClass;
    };
    return UserSignInPageComponent;
}());
export { UserSignInPageComponent };
