import { ViewContainerRef, ComponentFactoryResolver, OnInit, OnDestroy, AfterViewInit, QueryList, EventEmitter, OnChanges, SimpleChanges, ElementRef, } from '@angular/core';
import 'scroll-behavior-polyfill';
import { BootstrapViewportService, } from 'amg';
import { CarouselColumns, CarouselHover, CarouselInit, CarouselSlides, CarouselClick, } from './carousel.functions/carousel.functions';
import { CarouselResponsiveProperties } from './carousel.responsive.properties';
import { CarouselStaticProperties } from './carousel.static.properties';
var CarouselComponent = /** @class */ (function () {
    function CarouselComponent(componentFactoryResolver, bootstrapViewportService) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.bootstrapViewportService = bootstrapViewportService;
        this.responsiveProperties = new CarouselResponsiveProperties();
        this.staticProperties = new CarouselStaticProperties();
        this.cardClicked = new EventEmitter();
        this.cardHovered = new EventEmitter();
        this.cardSelected = new EventEmitter();
        this.Math = Math;
    }
    CarouselComponent.prototype.scrollTo = function (newSlideIndex) {
        var _this = this;
        var nativeElement = this.widgetsComponentChildren.toArray()[newSlideIndex].nativeElement;
        var hasWebkitOverflowScrolling = (nativeElement.parentElement.parentElement.parentElement.style.webkitOverflowScrolling !== undefined);
        if (!hasWebkitOverflowScrolling) {
            var slideByPixels_1 = nativeElement.parentNode.parentElement.offsetLeft;
            requestAnimationFrame(function () {
                _this.widgetsContent.nativeElement.scrollTo({
                    left: slideByPixels_1,
                    behavior: 'smooth',
                });
            });
        }
    };
    CarouselComponent.prototype.getTouchEndIndex = function ($event) {
        var scrollLeft = $event.target.parentNode.parentNode.parentNode.parentElement.scrollLeft;
        var slideWidth = this.widgetsComponentChildren.first.nativeElement.parentNode.parentElement.offsetWidth;
        var newSlideIndex = Math.round(scrollLeft / slideWidth);
        if (newSlideIndex >= this.componentData.length) {
            newSlideIndex = Math.max(this.componentData.length - 1, 0);
        }
        return newSlideIndex;
    };
    CarouselComponent.prototype.onTouchStart = function ($event) {
        var className = $event.target.className;
        if (!(className === 'prev' || className === 'next')) {
        }
    };
    CarouselComponent.prototype.onTouchEnd = function ($event) {
        var _this = this;
        var className = $event.target.className;
        if (!(className === 'prev' || className === 'next')) {
            if (this.scrollTimeout !== undefined) {
                clearTimeout(this.scrollTimeout);
                this.scrollTimeout = undefined;
            }
            var scrollLeft = $event.target.parentNode.parentNode.parentNode.parentElement.scrollLeft;
            var slideWidth = this.widgetsComponentChildren.first.nativeElement.parentNode.parentElement.offsetWidth;
            var newSlideIndex = Math.ceil(scrollLeft / slideWidth);
            if (newSlideIndex >= this.componentData.length) {
                this.scrollTo(this.componentData.length - 1);
            }
            this.scrollTimeout = setTimeout(function () {
                var newSlideIndex = _this.getTouchEndIndex($event);
                _this._slides.slideTo('onTouchEnd', newSlideIndex);
                _this.scrollTo(newSlideIndex);
                _this._columns.setCurrentSlide('onTouchEnd', newSlideIndex);
                if (_this._lastSelected !== newSlideIndex) {
                    _this._lastSelected = newSlideIndex;
                    _this.cardSelected.emit(_this.componentData[newSlideIndex]);
                }
                clearTimeout(_this.scrollTimeout);
                _this.scrollTimeout = undefined;
            }, 1000);
        }
    };
    CarouselComponent.prototype.ngOnInit = function () {
        var _this = this;
        var viewportSubscriber = this.bootstrapViewportService
            .getViewportSubscriber();
        var viewport = this.bootstrapViewportService.getViewport();
        this.componentCurrentView = this.responsiveProperties[viewport];
        this._columns = new CarouselColumns(this.componentCurrentView);
        this._hover = new CarouselHover(this.cardHovered);
        this._click = new CarouselClick(this.cardClicked, this.componentCurrentView.startPosition);
        this._slides = new CarouselSlides(this.componentCurrentView, this.componentData.length);
        this.componentViewportSubscription = viewportSubscriber
            .subscribe(function (newBreakpoint) {
            if (_this.responsiveTimeout) {
                clearTimeout(_this.responsiveTimeout);
                _this.responsiveTimeout = null;
            }
            _this.responsiveTimeout = setTimeout(function () {
                _this.componentCurrentView = _this.responsiveProperties[newBreakpoint];
                _this._columns.setCurrentView('resize', _this.componentCurrentView);
                _this._slides.setCurrentView('resize', _this.componentCurrentView);
            }, _this.componentCurrentView.refreshRate);
        });
    };
    CarouselComponent.prototype.ngOnDestroy = function () {
        this.componentViewportSubscription.unsubscribe();
    };
    CarouselComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this._init = new CarouselInit(this.componentFactoryResolver, this.componentData, this.responsiveProperties, this.staticProperties, this.componentChildren);
        this._init.ngAfterViewInit();
        this._slides = new CarouselSlides(this.componentCurrentView, this.componentData.length);
        this.componentChildren.changes.subscribe(function (item) {
            if (_this._init !== undefined) {
                _this._init.ngOnChanges(_this.componentData, _this.responsiveProperties, _this.staticProperties, _this.componentChildren);
            }
            _this._slides = new CarouselSlides(_this.componentCurrentView, _this.componentData.length);
        });
    };
    CarouselComponent.prototype.ngOnChanges = function (changes) {
        if (this._init !== undefined) {
            this._init.ngOnChanges(this.componentData, this.responsiveProperties, this.staticProperties, this.componentChildren);
            this._slides = new CarouselSlides(this.componentCurrentView, this.componentData.length);
        }
    };
    CarouselComponent.prototype.getCurrentView = function () {
        return this.componentCurrentView;
    };
    CarouselComponent.prototype.recalculateColumns = function (source) {
        return this._columns.recalculateColumns(source);
    };
    CarouselComponent.prototype.getCol = function (i) {
        return this._columns.getColumn(i, this._hover.getCurrentSlideHover('getCol'));
    };
    CarouselComponent.prototype.getDetails = function () {
        return this._columns.getDetailsColumn();
    };
    CarouselComponent.prototype.getCurrentSlide = function () {
        return this.componentData[this._slides.getCurrentSlide()];
    };
    CarouselComponent.prototype.getCurrentSlideProperty = function (property) {
        var hovered = this._hover.getCurrentSlideHover();
        var current = this._slides.getCurrentSlide();
        var currentSlide = this.componentData[hovered] || this.componentData[current];
        var splitProperty = property.split('.');
        var nestedProperty = currentSlide;
        splitProperty.forEach(function (p) {
            nestedProperty = nestedProperty[p];
        });
        return nestedProperty;
    };
    CarouselComponent.prototype.getExtraColumns = function () {
        var extraColumns = 1;
        if (this.sideBarIsActive()) {
            extraColumns = this.getVisibleSlideCount();
            extraColumns -= 1;
        }
        // make sure there's at least 1 extra column
        extraColumns = Math.max(1, extraColumns);
        extraColumns = Math.ceil(extraColumns);
        var toReturn = [].constructor(extraColumns);
        return toReturn;
    };
    CarouselComponent.prototype.dotIsActive = function (i) {
        return this._slides.dotIsActive(i);
    };
    CarouselComponent.prototype.slideIsActive = function (i) {
        return this._slides.slideIsActive(i);
    };
    CarouselComponent.prototype.sideBarIsActive = function () {
        return !((this.staticProperties.carouselSidebar === undefined) ||
            (this.staticProperties.carouselSidebar.length === 0));
    };
    CarouselComponent.prototype.getSlideNumber = function () {
        return this._slides.getCurrentSlide();
    };
    CarouselComponent.prototype.getSlideHover = function () {
        return this._hover.getCurrentSlideHover();
    };
    CarouselComponent.prototype.getVisibleSlideCount = function () {
        return this.componentCurrentView.limitViewableItems;
    };
    CarouselComponent.prototype.getVisibleSlideHoveredCount = function () {
        return this.componentCurrentView.visibleItemsOnHover;
    };
    CarouselComponent.prototype.showPrev = function () {
        return this.componentCurrentView.imitateInfinityLoop || (this._slides.getCurrentSlide() > 0);
    };
    CarouselComponent.prototype.showNext = function () {
        return (this.componentCurrentView.imitateInfinityLoop ||
            // show one empty tile at the end so the user does not click on the carousel by mistake
            (this._slides.getCurrentSlide() < (this.componentData.length - Math.ceil(this.componentCurrentView.limitViewableItems) + (this.sideBarIsActive() ? Math.ceil(this.componentCurrentView.limitViewableItems - 1) : 1))));
    };
    CarouselComponent.prototype.beforeSlide = function (source) {
    };
    CarouselComponent.prototype.afterSlide = function (source) {
        var _this = this;
        var currentSlide = this._slides.getCurrentSlide();
        var nativeElement = this.widgetsComponentChildren.toArray()[currentSlide].nativeElement;
        var slideByPixels = nativeElement.parentNode.parentElement.offsetLeft;
        requestAnimationFrame(function () {
            _this.widgetsContent.nativeElement.scrollTo({
                left: slideByPixels,
                behavior: 'smooth',
            });
        });
        this._columns.setCurrentSlide(source, currentSlide);
        if (this._lastSelected !== currentSlide) {
            this._lastSelected = currentSlide;
            this.cardSelected.emit(this.componentData[currentSlide]);
        }
    };
    CarouselComponent.prototype.slideTo = function (source, n) {
        this.beforeSlide(source);
        this._slides.slideTo(source, n);
        this.afterSlide(source);
    };
    CarouselComponent.prototype.slidePrevious = function (source) {
        this.beforeSlide(source);
        this._slides.slidePrevious(source);
        this.afterSlide(source);
    };
    CarouselComponent.prototype.slideNext = function (source) {
        this.beforeSlide(source);
        this._slides.slideNext(source);
        this.afterSlide(source);
    };
    CarouselComponent.prototype.slideHover = function (source, i) {
        if (this.slideIsActive(i)) {
            this._hover.slideHover(source, i, this.componentData[i]);
        }
    };
    CarouselComponent.prototype.slideHoverOut = function (source, i) {
        this._hover.slideHoverOut(source, i);
    };
    CarouselComponent.prototype.slideClick = function (source, slide, index) {
        this._click.slideClick(source, slide, index);
    };
    CarouselComponent.prototype.isSlideSelected = function (index) {
        return this._click.getLastClickedSlideIndex() === index;
    };
    CarouselComponent.prototype.getCurrentSelectedSlide = function () {
        return this._click.getLastClickedSlideIndex();
    };
    CarouselComponent.prototype.onClickPrevious = function (source) {
        this.slidePrevious(source);
    };
    CarouselComponent.prototype.onClickNext = function (source) {
        this.slideNext(source);
    };
    return CarouselComponent;
}());
export { CarouselComponent };
