import { CardFixtureResponsivePropertiesView } from 'card-fixture';
import { CardImageResponsivePropertiesView } from 'card-image';
import { CardProfileResponsivePropertiesView } from 'card-profile';
import { CardTextResponsivePropertiesView } from 'card-text';
import { CardVideoResponsivePropertiesView } from 'card-video';
var CardResponsiveViewRegistry = /** @class */ (function () {
    function CardResponsiveViewRegistry() {
    }
    CardResponsiveViewRegistry.CardResponsivePropertiesViewRegistry = new Map([
        ['CardFixtureResponsivePropertiesView', CardFixtureResponsivePropertiesView],
        ['CardImageResponsivePropertiesView', CardImageResponsivePropertiesView],
        ['CardProfileResponsivePropertiesView', CardProfileResponsivePropertiesView],
        ['CardTextResponsivePropertiesView', CardTextResponsivePropertiesView],
        ['CardVideoResponsivePropertiesView', CardVideoResponsivePropertiesView],
    ]);
    return CardResponsiveViewRegistry;
}());
export { CardResponsiveViewRegistry };
