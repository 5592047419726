import { BaseObject } from '../../../BaseObject';
import { ActionLink } from '../html-elements/ActionLink';

export class ActionLinks extends BaseObject {
  private readonly _actionLinks: ActionLink[];

  constructor(data: any) {
    super('ActionLinks', data);

    this._actionLinks = data.text_links;
  }

  get actionLinks(): ActionLink[] {
    return this._actionLinks;
  }
}
