import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var InputFormGroup = /** @class */ (function (_super) {
    tslib_1.__extends(InputFormGroup, _super);
    function InputFormGroup(data) {
        var _this = _super.call(this, 'InputFormGroup', data) || this;
        _this._inputFields = data.input_fields || [];
        _this._submitButton = data.submit_button;
        _this._restApiConfiguration = data.rest_api_configuration;
        return _this;
    }
    Object.defineProperty(InputFormGroup.prototype, "inputFields", {
        get: function () {
            return this._inputFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputFormGroup.prototype, "restApiConfiguration", {
        get: function () {
            return this._restApiConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputFormGroup.prototype, "submitButton", {
        get: function () {
            return this._submitButton;
        },
        enumerable: true,
        configurable: true
    });
    return InputFormGroup;
}(BaseObject));
export { InputFormGroup };
