import { EventEmitter } from '@angular/core';
import { Link } from 'wordpress-adapter';
var NavigationLinkComponent = /** @class */ (function () {
    function NavigationLinkComponent(document) {
        this.document = document;
        this.class = 'nav-item';
        this.triggerClick = new EventEmitter();
    }
    NavigationLinkComponent.getBaseUrlLength = function (fullUrlPath) {
        var baseUrlRegex = /^(https?:\/\/|www\.)?[^\/]+/i, matchingData = fullUrlPath.match(baseUrlRegex);
        return matchingData.length
            ? matchingData[0].length
            : 0;
    };
    NavigationLinkComponent.prototype.internalLinkClick = function () {
        this.triggerClick.emit(true);
    };
    NavigationLinkComponent.prototype.triggerExternalRedirect = function () {
        this.triggerClick.emit(true);
        this.document.location.href = this.getExternalPath();
    };
    NavigationLinkComponent.prototype.isInternalLink = function () {
        return !this.navigationLink.externalLink;
    };
    NavigationLinkComponent.prototype.getLabel = function () {
        return this.navigationLink.label;
    };
    NavigationLinkComponent.prototype.getInternalPath = function () {
        if (this.navigationLink.page) {
            var basePathLength = NavigationLinkComponent.getBaseUrlLength(this.navigationLink.page);
            return this.navigationLink.page.substring(basePathLength);
        }
        return this.navigationLink.path;
    };
    NavigationLinkComponent.prototype.getExternalPath = function () {
        return this.navigationLink.externalLink;
    };
    return NavigationLinkComponent;
}());
export { NavigationLinkComponent };
