import {
  AmgComponentResponsiveProperties,
  BootstrapViewportEnum,
} from 'amg';

import { CardImageResponsivePropertiesView } from './card.image.responsive.properties.view';

export class CardImageResponsiveProperties implements AmgComponentResponsiveProperties {
  private static keys: Array<string> = Object.keys(BootstrapViewportEnum);

    xs?: CardImageResponsivePropertiesView;
    sm?: CardImageResponsivePropertiesView;
    md?: CardImageResponsivePropertiesView;
    lg?: CardImageResponsivePropertiesView;
    xl?: CardImageResponsivePropertiesView;
    HD?: CardImageResponsivePropertiesView;
    FullHd?: CardImageResponsivePropertiesView;
    FourK?: CardImageResponsivePropertiesView;

    constructor(
      params?: any,
    ) {
      if (params === undefined) {
        params = {};
      }
      this.xs = params.xs || null;
      this.sm = params.sm || null;
      this.md = params.md || null;
      this.lg = params.lg || null;
      this.xl = params.xl || null;
      this.HD = params.HD || null;
      this.FullHd = params.FullHd || null;
      this.FourK = params.FourK || null;
      this.synchroniseProperties();
    }
  
    private synchroniseProperties(overrideResponsive?: CardImageResponsiveProperties) {
      if (overrideResponsive === undefined) {
        overrideResponsive = {} as CardImageResponsiveProperties;
      }
      let previousView: CardImageResponsivePropertiesView = CardImageResponsivePropertiesView.defaultValues();
      CardImageResponsiveProperties.keys.forEach((viewPort) => {
        let currentView: CardImageResponsivePropertiesView = this[viewPort];
        let currentValues: CardImageResponsivePropertiesView = overrideResponsive[viewPort];
        currentView = {...previousView, ...currentView, ...currentValues};
        this[viewPort] = currentView;
        previousView = currentView;
      });
    }
}
