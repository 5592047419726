<ng-template [ngIf]="cardData">
  <div class="amg-playlist-row">



    <!-- Thumbnail - BEGIN -->
    <amg-thumbnail [cardData]="cardData"></amg-thumbnail>
    <!-- Thumbnail - END -->

    <div class="amg-middle">

      <ng-template [ngIf]="categories">
        <div class="amg-category-wrapper">
                  <span *ngFor="let cat of categories"
                        class="amg-video-category"
                        [innerHTML]="cat"></span>
        </div>
      </ng-template>

      <ng-template [ngIf]="title">
        <div class="amg-title"
             [innerHTML]="title"></div>
      </ng-template>

      <!-- Date and Time - BEGIN -->
      <amg-date-and-time [cardData]="cardData"></amg-date-and-time>
      <!-- Date and Time  - END -->

    </div>

    <!-- Display Live Indicator Icons - BEGIN TODO: output value to playlist parent -->
    <amg-live-icons *ngIf="showLiveIcons"
                    [media]="media"
                    (mediaRequest)="clickedItemData = $event"></amg-live-icons>
    <!-- Display Live Indicator Icons - END -->

  </div>
</ng-template>

