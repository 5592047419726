import { CarouselResponsivePropertiesView } from '../carousel.responsive.properties.view';

export class CarouselSlides {

  private _currentSlide: number;
  private _currentView: CarouselResponsivePropertiesView;

  private _totalColumns: number;

  /**
   * Constructor that sets initial values
   * @param currentView the current viewport data
   * @param totalColumns total number of columns
   */
  constructor(currentView: CarouselResponsivePropertiesView, totalColumns: number) {
    this._currentSlide = currentView.startPosition;
    this._currentView = currentView;
    this._totalColumns = totalColumns;
  }

  /**
   * Change current viewport data
   * @param source 
   * @param currentView the current viewport data
   */
  setCurrentView(source: string = 'unknown', currentView: CarouselResponsivePropertiesView) {
    this._currentView = currentView;
  }

  /**
   * Get current slide index
   */
  getCurrentSlide(): number {
    return this._currentSlide;
  }

  /**
   * Get if dot representing slide is currently active
   * @param slide 
   */
  dotIsActive(slide: number): boolean {
    return slide === this._currentSlide;
  }

  /**
   * Get if slide is visible on the screen
   * @param slide 
   */
  slideIsActive(slide: number): boolean {
    return (
      (slide >= (this._currentSlide)) &&
      (slide <= (this._currentSlide + this._currentView.limitViewableItems - 1))
    );
  }

  /**
   * Automatically go to slide #
   * @param source 
   * @param n 
   */
  slideTo(source: string = 'unknown', n: number) {
    if (n >= 0) {
      if (n < this._totalColumns) {
        this._currentSlide = n;
      }
    }
  }
  /**
   * Automatically move to slide #
   * @param source 
   * @param n 
   */
  slideBy(source: string = 'unknown', n: number) {
    this._currentSlide += n;
    if (this._currentView.imitateInfinityLoop) {
      if (this._currentSlide < 0) {
        this._currentSlide = this._totalColumns - 1;
      }
      if (this._currentSlide >= this._totalColumns) {
        this._currentSlide = 1;
      }
    } else {
      if (this._currentSlide < 0) {
        this._currentSlide = 0;
      }
      if (this._currentSlide >= this._totalColumns) {
        this._currentSlide = this._totalColumns - 1;
      }
    }
  }

  /**
   * Automatically go back 1 slide
   * @param source 
   */
  slidePrevious(source: string = 'unknown'): number {
    this._currentSlide = this._currentSlide - this._currentView.iterationLimit;
    if (this._currentSlide < 0) {
      if (this._currentView.imitateInfinityLoop) {
        this._currentSlide = this._totalColumns - 1;
      } else {
        // this._currentSlide = this._currentSlide + this._currentView.iterationLimit;
        this._currentSlide = 0;
      }
    }

    return this._currentSlide;
  }

  /**
   * Automatically go forward 1 slide
   * @param source 
   */
  slideNext(source: string = 'unknown'): number {
    this._currentSlide = this._currentSlide + this._currentView.iterationLimit;
    if (this._currentSlide >= this._totalColumns) {
      if (this._currentView.imitateInfinityLoop) {
        this._currentSlide = 0;
      } else {
        // this._currentSlide = this._currentSlide - this._currentView.iterationLimit;
        this._currentSlide = this._totalColumns - 1;
      }
    }

    return this._currentSlide;
  }
}
