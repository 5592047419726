import { CarouselResponsivePropertiesView } from '../carousel.responsive.properties.view';

export class CarouselColumns {

  private _columnsStyle: any = {};
  private _detailsStyle: any = {};
  private _columnsHoveredStyle: any = {};

  private _currentView: CarouselResponsivePropertiesView;
  private _currentSlide: number;

  /**
   * Store slide widths for the view
   * @param detailsActive check if we cater for the information sidebar
   * @param currentView the current viewport data
   */
  constructor(currentView: CarouselResponsivePropertiesView) {
    this.setCurrentSlide('constructor', currentView.startPosition);
    this.setCurrentView('constructor', currentView);
  }

  /**
   * Change current viewport data
   * @param source 
   * @param currentView the current viewport data
   */
  setCurrentView(source: string = 'unknown', currentView: CarouselResponsivePropertiesView) {
    this._currentView = currentView;
    this.recalculateColumns(source);
  }
  /**
   * Change current slide
   * @param source 
   * @param currentSlide the new slide number
   */
  setCurrentSlide(source: string = 'unknown', currentSlide: number) {
    this._currentSlide = currentSlide;
  }

  /**
   * Recalculate column CSS
   * @param source 
   * @param normalItems number of items to show
   * @param hoveredItems number of items to show when hovering
   */
  private _recalculateColumns(source: string = 'unknown', normalItems: number, hoveredItems?: number) {
    let flex = 100 / normalItems;
    
    if (hoveredItems !== undefined) {
      flex = 100 - (flex * (hoveredItems - 1));
    }

    let toReturn = {
      flex: `0 0 ${flex}%`,
      width: `${flex}%`,
      maxWidth: `${flex}%`,
      transition: `flex ${this._currentView.hoverSpeed}ms ease-in-out`,
    };
    
    return toReturn;
  }
  /**
   * Recalculate column width
   * @param source 
   */
  recalculateColumns(source: string = 'unknown'): any {
    this._columnsStyle = this._recalculateColumns(source, this._currentView.limitViewableItems);

    if (this._currentView.limitViewableItems !== this._currentView.visibleItemsOnHover) {
      // style hovered columns with a zoom
      this._columnsHoveredStyle = this._recalculateColumns(source, this._currentView.limitViewableItems, this._currentView.visibleItemsOnHover);
    } else {
      // style hovered columns like normal columns
      this._columnsHoveredStyle = {...this._columnsStyle};
    }

    this._detailsStyle = {
      flex: this._columnsStyle.flex,
      width: this._columnsStyle.width,
      maxWidth: this._columnsStyle.maxWidth,
    };
  }

  /**
   * Get appropriate transition speed
   * @param source 
   */
  private _getTransitionSpeed(source: string = 'unknown'): number {
    let speed: number = 0;
    switch (source) {
      case 'nav': {
        speed = this._currentView.navigationSpeed;
        break;
      }
      case 'dots': {
        speed = this._currentView.paginationSpeed;
        break;
      }
      case 'dragStop': {
        speed = this._currentView.dragEndSpeed;
        break;
      }
    }
    return speed;
  }

  /**
   * Get cached column styles normal or hovered
   * @param col requested column style
   * @param colHovered current column hovered
   */
  getColumn(col: number, colHovered: number): any {
    if (col === colHovered) {
      return this._columnsHoveredStyle;
    }
    return this._columnsStyle;
  }

  /**
   * Get cached detals column styles
   */
  getDetailsColumn(): any {
    return this._detailsStyle;
  }
}
