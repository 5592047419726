import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardFixtureModule } from 'card-fixture';

import { CarouselModule } from 'carousel';

import { CalendarComponent } from './calendar.component';
import { RelevantFixturesPipe } from './relevantfixtures.pipe';

@NgModule({
   declarations: [
      CalendarComponent,
      RelevantFixturesPipe,
   ],
   imports: [
      CommonModule,
      CardFixtureModule,
      CarouselModule
   ],
   exports: [
      CalendarComponent
   ]
})
export class CalendarModule { }
