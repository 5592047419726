import { OnInit } from '@angular/core';
import { ContentProjection } from 'wordpress-adapter';
var ContentProjectionComponent = /** @class */ (function () {
    function ContentProjectionComponent() {
        this.class = 'amg-content-projection';
    }
    ContentProjectionComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
    };
    ContentProjectionComponent.prototype.getCss = function () {
        return this.contentProjection.cssClass;
    };
    return ContentProjectionComponent;
}());
export { ContentProjectionComponent };
