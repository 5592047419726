import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { InputField, KeyValue } from 'wordpress-adapter';
import { ApiService } from '../../../../../shared/api/api.service';

@Component({
  selector: 'amg-wp-input-field',
  templateUrl: './input-field.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InputFieldComponent implements OnInit, OnDestroy {
  private static readonly INPUT_TYPE_SELECT: string = 'select';

  @HostBinding('attr.class') class = 'amg-input-field-container';
  @Input() inputField: InputField;
  @Input() inputClearObservable: Observable<boolean>;
  @Output() triggerChange: EventEmitter<{
    inputField: InputField;
    value: string;
  }>;

  public value: string;

  private inputClearSubscription: Subscription;

  constructor(private apiService: ApiService,
              private router: Router) {
    this.triggerChange = new EventEmitter();
    this.value = '';
  }

  ngOnInit(): void {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    if (this.isTypeSelect()) {
      const firstValue = this.getFirstValue();

      if (firstValue) {
        this.triggerChange.emit({
          inputField: this.inputField,
          value: firstValue.key
        });

        this.value = firstValue.key;
      }
    } else {
      this.value = this.getValue();

      this.triggerChange.emit({
        inputField: this.inputField,
        value: this.value
      });
    }

    if (this.inputClearObservable) {
      this.inputClearSubscription = this.inputClearObservable.subscribe(value => {
        this.triggerChange.emit({
          inputField: this.inputField,
          value: this.value
        });
      });
    }
  }

  ngOnDestroy(): void {
    if (this.inputClearSubscription) {
      this.inputClearSubscription.unsubscribe();
    }
  }

  onKeyUp(event: any): void {
    const isEnter = event.key === 'Enter';

    if (isEnter && this.inputField.onEnterNavigateTo) {
      const inputValue = event.target.value;

      this.router.navigate([this.inputField.onEnterNavigateTo], {
        queryParams: {
          search: inputValue
        }
      });
    } else {
      this.triggerChange.emit({
        inputField: this.inputField,
        value: event.target.value
      });
    }
  }

  onSelectChange(event: any): void {
    this.triggerChange.emit({
      inputField: this.inputField,
      value: event.target.value
    });
  }

  isTypeSelect(): boolean {
    return InputFieldComponent.INPUT_TYPE_SELECT === this.getType();
  }

  getType(): string {
    return this.inputField.type;
  }

  getReferenceName(): string {
    return this.inputField.referenceName;
  }

  getLabel(): string {
    return this.inputField.label;
  }

  getValue(): string {
    return this.inputField.value
      ? this.inputField.value
      : '';
  }

  getValues(): KeyValue[] {
    return this.inputField.values;
  }

  isRequired(): boolean {
    return this.inputField.required;
  }

  isLabelAvailable(): boolean {
    return !!this.inputField.label;
  }

  getPlaceholder(): string {
    return this.inputField.placeholder
      ? this.inputField.placeholder
      : '';
  }

  getCss(): string {
    return this.inputField.cssClass;
  }

  private getFirstValue(): any {
    const values = this.getValues();

    return values.length > 0
      ? values[0]
      : null;
  }
}
