import { ModuleWithProviders } from '@angular/core';
import { StreamPaymentScriptsConfig } from 'user-auth';
var WordpressComponentsModule = /** @class */ (function () {
    function WordpressComponentsModule() {
    }
    WordpressComponentsModule.forRoot = function (clientOptions) {
        return {
            ngModule: WordpressComponentsModule,
            providers: [
                {
                    provide: 'videoPlayerModuleConfig',
                    useValue: (clientOptions.streamPayments)
                        ? clientOptions
                        : null
                }
            ]
        };
    };
    return WordpressComponentsModule;
}());
export { WordpressComponentsModule };
