import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {Fixtures as WpFixture, RestApiConfiguration} from 'wordpress-adapter';
import {CardFixtureResponsiveProperties} from 'card-fixture';
import {ApiService} from '../../../../../shared/api/api.service';

@Component({
  selector: 'amg-wp-fixtures',
  templateUrl: './fixtures.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FixturesComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-fixtures';
  @HostBinding('attr.data-is-data-available') isDataAvailable;
  @Input() fixtures: WpFixture;
  @Output() triggerChange: EventEmitter<string>;

  apiData: {
    data: any[];
    isLoaded: boolean;
    isEndOfPaginationReached: boolean;
    otherStaticData: { total: number; limit: number; offset: number; };
  };

  private apiDataObservable: Subscription;

  // TODO: duplicate code in api service
  private static extractUrlParamsFromUrl(url: string): { [key: string]: string } {
    const urlParamIndex = url.indexOf('?');

    if (urlParamIndex !== -1) {
      return url.substring(urlParamIndex + 1)
        .split('&')
        .reduce((previousValue, currentValue) => {
          const keyValue = currentValue.split('=');
          return {
            ...previousValue,
            [keyValue[0]]: keyValue[1]
          };
        }, {});
    }

    return {};
  }

  constructor(private apiService: ApiService) {
    this.apiData = {
      data: null,
      otherStaticData: null,
      isEndOfPaginationReached: false,
      isLoaded: false
    };
    this.triggerChange = new EventEmitter<string>();
  }

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    // Set static data for pagination if enabled.
    this.apiData.otherStaticData = {
      total: null,
      limit: this.fixtures.limitNumberOfItems,
      offset: -this.fixtures.limitNumberOfItems
    };

    this.loadData();
  }

  ngOnDestroy(): void {
    if (this.apiDataObservable) {
      this.apiDataObservable.unsubscribe();
    }
  }

  getCss(): string {
    return this.fixtures.cssClass;
  }

  loadNextPage(): void {
    this.loadData();
  }

  getFixtures(): any[] {
    return this.apiData.data;
  }

  getFixtureData(fixture: any): {
    data: any;
    onClickNavRoute: string;
    sendEntryDataOnNavigation: boolean,
    fixtureApiUrl: string;
    fixtureCardType: ('calendar' | 'fixture');
  } {
    return {
      data: fixture,
      onClickNavRoute: this.fixtures.onClickNavigate,
      sendEntryDataOnNavigation: this.fixtures.sendEntryDataOnNavigation,
      fixtureApiUrl: this.fixtures.restApiConfiguration.baseUrl,
      fixtureCardType: this.fixtures.fixtureCardType
    };
  }

  isDataLoading(): boolean {
    return this.apiData
      && !this.apiData.isLoaded;
  }

  areFixturesPopulated(): boolean {
    return this.apiData.data
      && this.apiData.data.length > 0;
  }

  isPaginationEnabled(): boolean {
    return this.fixtures.isPaginationEnabled;
  }

  isMoreDataAvailable(): boolean {
    return !this.apiData.isEndOfPaginationReached;
  }

  private loadData(): void {
    if (this.fixtures.restApiConfiguration) {
      if (this.apiDataObservable) {
        this.apiDataObservable.unsubscribe();
      }

      const restApiConfiguration = this.generateRestApiConfiguration();

      this.apiDataObservable = this.apiService.getApiData(restApiConfiguration).subscribe(fixtureData => {
        if (fixtureData && fixtureData.fixtures && Array.isArray(fixtureData.fixtures)) {
          this.setLoadedData(fixtureData);
        } else {
          this.setLoadedData(null);
        }

        this.isDataAvailable = this.apiData.data.length > 0;

        if (this.apiData.otherStaticData.offset + this.apiData.otherStaticData.limit >= this.apiData.otherStaticData.total) {
          this.apiData.isEndOfPaginationReached = true;
        }

        this.triggerChange.emit('fixtures');
      });
    }

  }

  private generateRestApiConfiguration(): RestApiConfiguration {
    const staticData = this.apiData.otherStaticData;

    const url = this.fixtures.restApiConfiguration.baseUrl,
      urlWithoutParams = ApiService.removeQueryParamsFromUrl(url);

    // Url Params shall overwrite the params of the first call.
    const urlParamsObject = {
      limit: staticData.limit,
      offset: staticData.offset + staticData.limit,
      ...FixturesComponent.extractUrlParamsFromUrl(url)
    };
    const urlParams = this.convertObjectToUrlParams(urlParamsObject);
    const newUrl = `${urlWithoutParams}?${urlParams}`;

    return this.generateNewRestApiConfiguration(newUrl);

  }

  private generateNewRestApiConfiguration(url: string): RestApiConfiguration {
    return RestApiConfiguration.merge(
      this.fixtures.restApiConfiguration,
      new RestApiConfiguration({
        base_url: url
      })
    );
  }

  private convertObjectToUrlParams(data: { [key: string]: string | number }): string {
    const params = [];

    Object.keys(data).forEach(key => {
      const value = data[key];

      params.push(`${key}=${value}`);
    });

    return params.join('&');
  }

  private setLoadedData(data: any): void {
    if (data) {
      const {fixtures, ...staticData} = data;

      if (this.isPaginationEnabled()) {
        const currentFixtures = this.apiData.data || [];

        this.apiData = {
          isLoaded: true,
          isEndOfPaginationReached: this.apiData.isEndOfPaginationReached,
          data: [...currentFixtures, ...fixtures],
          otherStaticData: staticData
        };
      } else {
        const newFixtures = (this.fixtures.limitNumberOfItems)
          ? fixtures.splice(0, this.fixtures.limitNumberOfItems)
          : fixtures;

        this.apiData = {
          isLoaded: true,
          isEndOfPaginationReached: this.apiData.isEndOfPaginationReached,
          data: newFixtures,
          otherStaticData: staticData
        };
      }
    } else {
      this.apiData = {
        isLoaded: true,
        isEndOfPaginationReached: true,
        data: [],
        otherStaticData: null
      };
    }
  }
}
