/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ng-lazyload-image";
import * as i2 from "@angular/common";
import * as i3 from "./video-card.component";
import * as i4 from "@angular/platform-browser";
var styles_VideoCardComponent = [];
var RenderType_VideoCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VideoCardComponent, data: {} });
export { RenderType_VideoCardComponent as RenderType_VideoCardComponent };
function View_VideoCardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "card-img"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "img", [], null, null, null, null, null)), i0.ɵdid(2, 1720320, null, 0, i1.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"], defaultImage: [1, "defaultImage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getThumbnail(); var currVal_1 = _co.defaultImage; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_VideoCardComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getClassName(_v.context.$implicit); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getData(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
function View_VideoCardComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "card-info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoCardComponent_3)), i0.ɵdid(3, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardConfiguration.displayKeys.cardInformationKeys; _ck(_v, 3, 0, currVal_0); }, null); }
function View_VideoCardComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getClassName(_v.context.$implicit); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getData(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
function View_VideoCardComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoCardComponent_5)), i0.ɵdid(3, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardConfiguration.displayKeys.cardBodyKeys; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_VideoCardComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "video-card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCardClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoCardComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoCardComponent_2)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoCardComponent_4)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardConfiguration.displayKeys.thumbnailKey; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.cardConfiguration.displayKeys.cardInformationKeys; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.cardConfiguration.displayKeys.cardBodyKeys; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_VideoCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-video-card", [], null, null, null, View_VideoCardComponent_0, RenderType_VideoCardComponent)), i0.ɵdid(1, 114688, null, 0, i3.VideoCardComponent, [i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoCardComponentNgFactory = i0.ɵccf("amg-video-card", i3.VideoCardComponent, View_VideoCardComponent_Host_0, { cardData: "cardData", cardConfiguration: "cardConfiguration" }, { triggerClick: "triggerClick" }, []);
export { VideoCardComponentNgFactory as VideoCardComponentNgFactory };
