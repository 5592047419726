import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var Button = /** @class */ (function (_super) {
    tslib_1.__extends(Button, _super);
    function Button(data) {
        var _this = _super.call(this, 'Button', data) || this;
        _this._label = data.label;
        return _this;
    }
    Object.defineProperty(Button.prototype, "label", {
        get: function () {
            return this._label;
        },
        enumerable: true,
        configurable: true
    });
    return Button;
}(BaseObject));
export { Button };
