import { CardRegistry, CardResponsiveInstantiate, } from 'card';
var CollapsibleAfterViewInit = /** @class */ (function () {
    function CollapsibleAfterViewInit(componentFactoryResolver, componentData, responsiveProperties, staticProperties, componentChildren) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.componentData = componentData;
        this.responsiveProperties = responsiveProperties;
        this.staticProperties = staticProperties;
        this.componentChildren = componentChildren;
        var component = CardRegistry.CardComponentRegistry.get("Card" + this.staticProperties.collapsibleTile + "Component");
        this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.cardResponsive = CardResponsiveInstantiate.instantiate(this.staticProperties.collapsibleTile, {
            xs: responsiveProperties.xs.cardView,
            sm: responsiveProperties.sm.cardView,
            md: responsiveProperties.md.cardView,
            lg: responsiveProperties.lg.cardView,
            xl: responsiveProperties.xl.cardView,
        });
    }
    CollapsibleAfterViewInit.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.componentChildren.forEach(function (viewContainerRef, i) {
            var componentRef = viewContainerRef.createComponent(_this.componentFactory);
            var componentRefInstance = componentRef.instance;
            componentRefInstance.componentData = _this.componentData[i];
            componentRefInstance.responsiveProperties = _this.cardResponsive;
            componentRefInstance.staticProperties = _this.staticProperties.cardStaticProperties;
            componentRef.changeDetectorRef.detectChanges();
        });
    };
    return CollapsibleAfterViewInit;
}());
export { CollapsibleAfterViewInit };
