import {
  ViewContainerRef,
  ComponentFactoryResolver,
  QueryList,
} from '@angular/core';

import {
  AmgComponent,
  AmgComponentResponsiveProperties,
} from 'amg';

import {
  CardRegistry,
  CardResponsiveInstantiate,
} from 'card';

import { CollapsibleResponsiveProperties } from '../collapsible.responsive.properties';
import { CollapsibleStaticProperties } from '../collapsible.static.properties';

export class CollapsibleAfterViewInit {
  private cardResponsive: AmgComponentResponsiveProperties;
  private componentFactory;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private componentData: Array<any>,
    private responsiveProperties: CollapsibleResponsiveProperties,
    private staticProperties: CollapsibleStaticProperties,
    private componentChildren: QueryList<ViewContainerRef>,
  ) {
    const component = CardRegistry.CardComponentRegistry.get(`Card${this.staticProperties.collapsibleTile}Component`);
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    
    this.cardResponsive = CardResponsiveInstantiate.instantiate(
      this.staticProperties.collapsibleTile,
      {
        xs: responsiveProperties.xs.cardView,
        sm: responsiveProperties.sm.cardView,
        md: responsiveProperties.md.cardView,
        lg: responsiveProperties.lg.cardView,
        xl: responsiveProperties.xl.cardView,
      }
    );
  }

  ngAfterViewInit() {
    this.componentChildren.forEach((viewContainerRef: ViewContainerRef, i: number) => {
      const componentRef = viewContainerRef.createComponent(this.componentFactory);

      const componentRefInstance = (<  AmgComponent>componentRef.instance);
      componentRefInstance.componentData = this.componentData[i];
      componentRefInstance.responsiveProperties = this.cardResponsive;
      componentRefInstance.staticProperties = this.staticProperties.cardStaticProperties;
      
      componentRef.changeDetectorRef.detectChanges();
    });
  }
}
