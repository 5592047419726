import { CollectionUtils } from '../../../util/CollectionUtils';
import { BaseObject } from '../BaseObject';
import { AnywhereApiConfiguration } from '../config/AnywhereApiConfiguration';
import { BaseConfiguration } from '../config/BaseConfiguration';
import { KalturaConfiguration } from '../config/KalturaConfiguration';
import { RestApiConfiguration } from '../config/RestApiConfiguration';
import { WpImageData } from '../types/wordpress/WpImageData';

export class Section extends BaseObject {
  private readonly _baseConfiguration: BaseConfiguration;
  private readonly _anywhereApiConfiguration: AnywhereApiConfiguration;
  private readonly _restApiConfiguration: RestApiConfiguration;
  private readonly _kalturaConfiguration: KalturaConfiguration;

  private readonly _title: string;
  private readonly _description: string;
  private readonly _backgroundImage: WpImageData;
  private readonly _children: any[];

  constructor(data: any) {
    super('Section', data);

    this._backgroundImage = data.amg_background_image ? data.amg_background_image : null;
    this._description = data.description ? data.description : null;
    this._title = (data.title) ? data.title : null;
    this._children = data.elements
      ? CollectionUtils.flattenArrayOfSection(data.elements)
      : [];

    if (data.section_api_configuration) {
      const configDataset = CollectionUtils.retrieveConfigurationData(data.section_api_configuration);

      this._baseConfiguration = configDataset.baseConfiguration;
      this._anywhereApiConfiguration = configDataset.anywhereApiConfiguration;
      this._kalturaConfiguration = configDataset.kalturaConfiguration;
      this._restApiConfiguration = configDataset.restApiConfiguration;
    }
  }

  get title(): string {
    return this._title;
  }

  get description(): string {
    return this._description;
  }

  get backgroundImage(): WpImageData {
    return this._backgroundImage;
  }

  get anywhereApiConfiguration(): AnywhereApiConfiguration {
    return this._anywhereApiConfiguration;
  }

  get restApiConfiguration(): RestApiConfiguration {
    return this._restApiConfiguration;
  }

  get baseConfiguration(): BaseConfiguration {
    return this._baseConfiguration;
  }

  get kalturaConfiguration(): KalturaConfiguration {
    return this._kalturaConfiguration;
  }

  get children(): any[] {
    return this._children;
  }
}
