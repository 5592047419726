import {
  AmgComponentResponsiveProperties,
  BootstrapViewportEnum,
} from 'amg';

import { CollapsibleResponsivePropertiesView } from './collapsible.responsive.properties.view';

export class CollapsibleResponsiveProperties implements AmgComponentResponsiveProperties {
  private static keys: Array<string> = Object.keys(BootstrapViewportEnum);

  xs?: CollapsibleResponsivePropertiesView;
  sm?: CollapsibleResponsivePropertiesView;
  md?: CollapsibleResponsivePropertiesView;
  lg?: CollapsibleResponsivePropertiesView;
  xl?: CollapsibleResponsivePropertiesView;
  HD?: CollapsibleResponsivePropertiesView;
  FullHd?: CollapsibleResponsivePropertiesView;
  FourK?: CollapsibleResponsivePropertiesView;

  constructor(
    params?: any,
  ) {
    if (params === undefined) {
      params = {};
    }
    this.xs = params.xs || null;
    this.sm = params.sm || null;
    this.md = params.md || null;
    this.lg = params.lg || null;
    this.xl = params.xl || null;
    this.HD = params.HD || null;
    this.FullHd = params.FullHd || null;
    this.FourK = params.FourK || null;
    this.synchroniseProperties();
  }

  private synchroniseProperties(overrideResponsive?: CollapsibleResponsiveProperties) {
    if (overrideResponsive === undefined) {
      overrideResponsive = {} as CollapsibleResponsiveProperties;
    }
    let previousView: CollapsibleResponsivePropertiesView = CollapsibleResponsivePropertiesView.defaultValues();
    CollapsibleResponsiveProperties.keys.forEach((viewPort) => {
      let currentView: CollapsibleResponsivePropertiesView = this[viewPort];
      let currentValues: CollapsibleResponsivePropertiesView = overrideResponsive[viewPort];
      currentView = {...previousView, ...currentView, ...currentValues};
      this[viewPort] = currentView;
      previousView = currentView;
    });
  }
}
