import {
  Component,
  OnInit,
  Input,
  QueryList,
  ViewContainerRef,
  OnDestroy
} from '@angular/core';

import { Subscription, Observable } from 'rxjs';

import {
  AmgComponent,
  BootstrapViewportEnum,
  BootstrapViewportService,
} from 'amg';

import { CardImage } from './card.image';
import { CardImageResponsiveProperties } from './card.image.responsive.properties';
import { CardImageResponsivePropertiesView } from './card.image.responsive.properties.view';
import { CardImageStaticProperties } from './card.image.static.properties';

@Component({
  selector: 'amg-card-image',
  templateUrl: 'card-image.component.html',
  styles: [],
})
export class CardImageComponent implements OnInit, OnDestroy, AmgComponent {
  @Input() componentData: CardImage;
  @Input() responsiveProperties: CardImageResponsiveProperties = new CardImageResponsiveProperties();
  @Input() staticProperties: CardImageStaticProperties = new CardImageStaticProperties();
  
  @Input() componentChildren: QueryList<ViewContainerRef>;

  componentViewportSubscription: Subscription;

  componentCurrentView: CardImageResponsivePropertiesView;
  
  responsiveTimeout: any;

  constructor(
    private bootstrapViewportService: BootstrapViewportService
  ) { }

  ngOnInit() {
    let viewportSubscriber:Observable<BootstrapViewportEnum> = this.bootstrapViewportService
      .getViewportSubscriber();

    let viewport: BootstrapViewportEnum = this.bootstrapViewportService.getViewport();
    this.componentCurrentView = this.responsiveProperties[viewport] as CardImageResponsivePropertiesView;
    
    this.componentViewportSubscription = viewportSubscriber
      .subscribe(newBreakpoint => {
        if (this.responsiveTimeout) {
          clearTimeout(this.responsiveTimeout);
          this.responsiveTimeout = null;
        }

        this.responsiveTimeout = setTimeout(() => {
          this.componentCurrentView = this.responsiveProperties[newBreakpoint] as CardImageResponsivePropertiesView;
        }, this.componentCurrentView.refreshRate);
      });
  }
  ngOnDestroy() {
    this.componentViewportSubscription.unsubscribe();
  }

  getTitle() {
    return this.componentData.title;
  }
  getThumbnail() {
    return this.componentData.imageUrl;
  }
  getThumbnailAsBackground() {
    return `url(${this.getThumbnail()})`;
  }

}
