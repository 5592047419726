<div *ngIf="!videoPlayerService.canUserPlayMedia() && videoPlayerService.mediaAuthResponse"
     class="amg-video-login-overlay">

  <ng-template [ngIf]="liveLoginOverlayRef.title">
    <div class="amg-title"
         [innerHTML]="liveLoginOverlayRef.title"></div>
  </ng-template>

  <ng-template [ngIf]="liveLoginOverlayRef.subTitle">
    <div class="amg-sub-title"
         [innerHTML]="liveLoginOverlayRef.subTitle"></div>
  </ng-template>

  <div class="amg-btn-wrapper">
    <ng-template [ngIf]="liveLoginOverlayRef.subscribeBtn">
      <button class="btn amg-subscribe-btn"
              (click)="openLink(liveLoginOverlayRef.subscribeBtn.link)"
              [innerHTML]="liveLoginOverlayRef.subscribeBtn.label"></button>
    </ng-template>
    <ng-template [ngIf]="(videoPlayerService.mediaAuthResponse && videoPlayerService.mediaAuthResponse.Status === 0) || !videoPlayerService.mediaAuthResponse">
      <ng-template [ngIf]="liveLoginOverlayRef.buttonSeparator">
      <span class="btn amg-separator-text"
            [innerHTML]="liveLoginOverlayRef.buttonSeparator"></span>
      </ng-template>

      <ng-template [ngIf]="liveLoginOverlayRef.logInBtn">
        <button class="btn amg-log-in-btn"
                (click)="userAuthService.openModal()"
                [innerHTML]="liveLoginOverlayRef.logInBtn.label"></button>
      </ng-template>
    </ng-template>
  </div>

</div>
