import * as tslib_1 from "tslib";
import { AnywhereMediaType, AnywhereMembership, UserState } from './_data/video-player.model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { UserAuthService } from 'user-auth';
import { UserAuthSsoService } from '@amg-fe-angular/services/user-auth/user-auth-sso.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../user-auth/src/lib/user-auth.service";
import * as i3 from "amg-fe-angular/library/services/user-auth/user-auth-sso.service";
export var ScriptStore = [];
var VideoPlayerService = /** @class */ (function () {
    function VideoPlayerService(videoPlayerModuleConfig, http, userAuthService, userAuthServiceNew) {
        var _this = this;
        this.videoPlayerModuleConfig = videoPlayerModuleConfig;
        this.http = http;
        this.userAuthService = userAuthService;
        this.userAuthServiceNew = userAuthServiceNew;
        this.autoPlayStorageHandle = 'AMG-AUTOPLAY';
        this.entryObjBSubject = new BehaviorSubject(null);
        this.autoplayBSubject = new BehaviorSubject(false);
        this.liveLoginOverlayBSubject = new BehaviorSubject(null);
        this.allowChangeMediaBSubject = new BehaviorSubject(true);
        // entry-nav.component observable
        this.entryNavBSubject = new BehaviorSubject(null);
        // Observables
        this.anywhereMediaDataBSubject = new BehaviorSubject(null);
        this.videoPlayerStateBSubject = new BehaviorSubject(null);
        this.userDataStateBSubject = new BehaviorSubject(null);
        // Vars
        this.scripts = {};
        this.entryObjLocalRef = {
            entryId: '',
            ks: '',
        };
        if (videoPlayerModuleConfig) {
            this.videoPlayerConfig = videoPlayerModuleConfig;
            if (videoPlayerModuleConfig.navEntries) {
                this.entryNavBSubject.next(videoPlayerModuleConfig.navEntries);
            }
        }
        this.userAuthService.customerSessionData.subscribe(function (userData) {
            _this.setUserState(userData);
        });
        /**
         * Subscribe to entryID and KS;
         * Assign entryID and KS to local variable;
         * Request KSession from streamPayments;
         * On KSession received - dispatch Object {entryId, ks};
         */
        this.getEntryAndKSBSubject()
            .subscribe(function (res) {
            if (res && !res.ks) {
                _this.entryObjLocalRef = res;
                _this.amgLogger('getEntryAndKSBSubject', res);
                _this.userAuthServiceNew.streamPaymentsGetKSession({ entryId: res.entryId }, function (ksResponse) {
                    _this.mediaAuthResponse = ksResponse;
                    if (ksResponse.KSession) {
                        _this.setEntryAndKSBSubject(res.entryId, ksResponse);
                    }
                    _this.userAuthService.setUserSubscriptionState(!!ksResponse.KSession);
                });
                // .
                // .hookStreamPayments(StreamPaymentsHooks.getKSession, {
                //   entryId: res.entryId,
                //   ...(DeviceUtils.userAgent.IE
                //     ? {
                //       a: Date.now().toString() // IE fix to invalidate the cache.
                //     } : {})
                // })
                // .then(ksResponse => {
                //   this.mediaAuthResponse = ksResponse;
                //   if (ksResponse.KSession) {
                //     this.setEntryAndKSBSubject(res.entryId, ksResponse);
                //   }
                //
                //   this.userAuthService.setUserSubscriptionState(!!ksResponse.KSession);
                // });
            }
        });
    }
    Object.defineProperty(VideoPlayerService.prototype, "videoPlayerConfig", {
        set: function (videoPlayerConfiguration) {
            if (videoPlayerConfiguration) {
                this._videoPlayerModuleConfig = tslib_1.__assign({}, videoPlayerConfiguration, (this.autoPlayStorageHandle
                    ? {}
                    : { autoPlayStorageHandle: this.autoPlayStorageHandle }));
            }
            else {
                console.error('No configuration provided to player @');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoPlayerService.prototype, "userData", {
        get: function () {
            return this.userDataStateBSubject.asObservable();
        },
        set: function (data) {
            this.userDataStateBSubject.next(data);
        },
        enumerable: true,
        configurable: true
    });
    VideoPlayerService.prototype.getEntryAndKSBSubject = function () {
        return this.entryObjBSubject;
    };
    VideoPlayerService.prototype.setEntryAndKSBSubject = function (entryId, ks) {
        if (entryId) {
            var kSession = (ks && ks.KSession) ? ks.KSession : '';
            var entryIdCmp = entryId === this.entryObjLocalRef.entryId;
            var kSessionCmp = kSession === this.entryObjLocalRef.ks;
            var ksNull = (kSession.length === 0);
            var updateEntryObj = (((!entryIdCmp)) || // if new entry id
                ((entryIdCmp) && (!kSessionCmp) && (!ksNull)) // if same entry id and new ks and ks is not null
            );
            if (updateEntryObj) {
                this.entryObjLocalRef = { entryId: entryId, ks: kSession };
                this.entryObjBSubject.next(tslib_1.__assign({ entryId: entryId, ks: kSession }));
            }
        }
    };
    VideoPlayerService.prototype.changeMediaMessage = function (videoItem) {
        if (videoItem) {
            var entryId = (videoItem.mediaData && videoItem.mediaData.entryId)
                ? videoItem.mediaData.entryId
                : videoItem.entryId;
            this.setEntryAndKSBSubject(entryId, null);
        }
    };
    VideoPlayerService.prototype.setVideoPlayerStateBSubject = function (eventData) {
        if (eventData.event !== 'playerUpdatePlayhead') {
            this.amgLogger('setVideoPlayerStateBSubject', eventData);
        }
        this.videoPlayerStateBSubject.next(eventData);
    };
    VideoPlayerService.prototype.getVideoPlayerStateBSubject = function () {
        return this.videoPlayerStateBSubject.asObservable();
    };
    VideoPlayerService.prototype.getAnywhereMediaDataBSubject = function () {
        return this.anywhereMediaDataBSubject.asObservable();
    };
    VideoPlayerService.prototype.setAnywhereMediaDataBSubject = function (anywhereMediaData) {
        if (anywhereMediaData) {
            var media = anywhereMediaData.mediaData;
            if (media.entryId) {
                this.setEntryAndKSBSubject(media.entryId);
            }
            else {
                var matchedMedia = this.findEntryInMediaData(anywhereMediaData.mediaData.entryId, media);
                if (matchedMedia) {
                    this.setEntryAndKSBSubject(matchedMedia.entryId);
                }
            }
            this.anywhereMediaDataBSubject.next(anywhereMediaData);
            this.localAnywhereMediaData = anywhereMediaData;
        }
        this.amgLogger('setAnywhereMediaDataBSubject', anywhereMediaData);
    };
    VideoPlayerService.prototype.setUserState = function (userData) {
        if (userData) {
            this.userData = userData;
            if (userData.CurrentCustomerSession !== null) {
                this.userState = UserState.loggedIn;
            }
            else {
                this.userState = UserState.notLoggedIn;
            }
        }
    };
    VideoPlayerService.prototype.fetchPlaylistData = function (url) {
        return this.http.get(url);
    };
    VideoPlayerService.prototype.getKalturaApi = function (playerConfig) {
        var _this = this;
        var url = this.buildPlayerUrl(playerConfig);
        ScriptStore.push({ name: 'kalturaApi', src: url });
        ScriptStore.forEach(function (script) {
            _this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
    };
    VideoPlayerService.prototype.load = function () {
        var _this = this;
        var scripts = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            scripts[_i] = arguments[_i];
        }
        var promises = [];
        scripts.forEach(function (script) { return promises.push(_this.loadScript(script)); });
        return Promise.all(promises);
    };
    VideoPlayerService.prototype.loadScript = function (name) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!_this.scripts[name].loaded) {
                //load script
                var script_1 = document.createElement('script');
                script_1.type = 'text/javascript';
                script_1.src = _this.scripts[name].src;
                if (script_1.readyState) { //IE
                    script_1.onreadystatechange = function () {
                        if (script_1.readyState === 'loaded' || script_1.readyState === 'complete') {
                            script_1.onreadystatechange = null;
                            _this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                }
                else { //Others
                    script_1.onload = function () {
                        _this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script_1.onerror = function (error) { return resolve({ script: name, loaded: false, status: 'Loaded' }); };
                document.getElementsByTagName('head')[0].appendChild(script_1);
            }
            else {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            }
        });
    };
    VideoPlayerService.prototype.getMediaType = function () {
        if (!this.isLive() && !this.isOnDemand()) {
            console.error('Could not determine MediaType');
        }
        if (this.isLive()) {
            return AnywhereMediaType.Live;
        }
        else if (this.isOnDemand()) {
            return AnywhereMediaType.OnDemand;
        }
    };
    /**
     * Extract required membership from anywhere json object;
     * Assign extracted value to active(currently playing) video;
     */
    VideoPlayerService.prototype.getMediaMembership = function () {
        var data = this.localAnywhereMediaData;
        if (data) {
            var meta = data.metaData;
            var metaMembership = (meta && meta.membership) ? meta.membership : null;
            var media = data.mediaData;
            var membership = metaMembership
                ? metaMembership
                : this.getMediaType() === AnywhereMediaType.Live
                    ? AnywhereMembership.Paid
                    : AnywhereMembership.Free;
            if (this.findEntryInMediaData(this.entryObjLocalRef.entryId, media) && !media.entryId) {
                membership = AnywhereMembership.Paid;
            }
            if (membership) {
                return membership;
            }
            if (meta && meta.SysEntryEntitlements) {
                return data.metaData.SysEntryEntitlements[0];
            }
            return false;
        }
    };
    VideoPlayerService.prototype.findEntryInMediaData = function (entryId, mediaData) {
        var _this = this;
        var matchedMedia = null;
        if (Array.isArray(mediaData) && mediaData.length > 0) {
            mediaData.forEach(function (media) {
                if ((media.entryId === entryId) || (media.entryId === _this.entryObjLocalRef.entryId)) {
                    matchedMedia = media;
                }
            });
        }
        else if (!Array.isArray(mediaData)) {
            return mediaData;
        }
        return matchedMedia;
    };
    VideoPlayerService.prototype.isFreeMembership = function () {
        var membership = this.getMediaMembership();
        return membership === AnywhereMembership.Free
            || membership === false
            || membership === ''
            || !membership;
    };
    VideoPlayerService.prototype.isPaidMembership = function () {
        return this.getMediaMembership() === AnywhereMembership.Paid;
    };
    VideoPlayerService.prototype.isRegisteredMembership = function () {
        return this.getMediaMembership() === AnywhereMembership.Registered;
    };
    VideoPlayerService.prototype.amgLogger = function (functionName, data) {
        // console.log(' Function Name: ', functionName);
        // console.log(' Data: ', data);
        // console.log(' --- ');
    };
    VideoPlayerService.prototype.canUserPlayMedia = function () {
        if (this.mediaAuthResponse) {
            var status_1 = this.mediaAuthResponse.Status;
            if (status_1 === 0) {
                return false;
            }
            else if (status_1 === 1) {
                return false;
            }
            else if (status_1 === -1) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    VideoPlayerService.prototype.findAnywhereItems = function (playlistData) {
        var itemData = null;
        if (playlistData) {
            if (playlistData.itemData) {
                itemData = playlistData.itemData;
            }
            else if (playlistData.sections && playlistData.sections[0]) {
                if (playlistData.sections[0].itemData) {
                    itemData = playlistData.sections[0].itemData;
                }
            }
            else if (playlistData.fixtures) {
                itemData = playlistData.fixtures;
            }
        }
        return itemData;
    };
    VideoPlayerService.prototype.isLive = function () {
        var data = this.localAnywhereMediaData;
        if (data) {
            var media = data.mediaData;
            var isFixture = (media.length > 0) && (media[0].isLiveUrl || media[1].isLiveUrl);
            var foundEntry = this.findEntryInMediaData(null, media);
            if (media && media.mediaType === AnywhereMediaType.Live
                || foundEntry && foundEntry.isLiveUrl
                || isFixture) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    VideoPlayerService.prototype.isOnDemand = function () {
        var data = this.localAnywhereMediaData;
        if (data) {
            var media = data.mediaData;
            var foundEntry = this.findEntryInMediaData(null, media);
            return foundEntry && data && foundEntry.mediaType === AnywhereMediaType.OnDemand;
        }
        else {
            return false;
        }
    };
    VideoPlayerService.prototype.buildPlayerUrl = function (playerConfig) {
        return playerConfig.apiUrl + "/p/" + playerConfig.partnerId + "/sp/" + playerConfig.partnerId + "00/embedIframeJs/uiconf_id/" + playerConfig.uiConfId + "/partner_id/" + playerConfig.partnerId;
    };
    VideoPlayerService.ngInjectableDef = i0.defineInjectable({ factory: function VideoPlayerService_Factory() { return new VideoPlayerService(i0.inject("videoPlayerModuleConfig"), i0.inject(i1.HttpClient), i0.inject(i2.UserAuthService), i0.inject(i3.UserAuthSsoService)); }, token: VideoPlayerService, providedIn: "root" });
    return VideoPlayerService;
}());
export { VideoPlayerService };
