import { OnInit } from '@angular/core';
import { UserAuthService } from '../../user-auth.service';
var LoginBtnComponent = /** @class */ (function () {
    function LoginBtnComponent(userAuthService) {
        this.userAuthService = userAuthService;
        this.show = false;
    }
    LoginBtnComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userAuthService.getUserState()
            .subscribe(function (res) {
            if (res) {
                _this.show = false;
            }
            else {
                _this.show = true;
            }
        });
    };
    return LoginBtnComponent;
}());
export { LoginBtnComponent };
