<div
  class="amg-countdown"
  >
  <div
    class="amg-countdown-progress"
  >
    <circle-progress
      [percent]="calculatedPercentage.days"
      [title]="calculatedText.days"
      [subtitle]="componentCurrentView.days"
      [class]="componentCurrentView.class"
      [backgroundGradient]="componentCurrentView.backgroundGradient"
      [backgroundColor]="componentCurrentView.backgroundColor"
      [backgroundGradientStopColor]="componentCurrentView.backgroundGradientStopColor"
      [backgroundOpacity]="componentCurrentView.backgroundOpacity"
      [backgroundStroke]="componentCurrentView.backgroundStroke"
      [backgroundStrokeWidth]="componentCurrentView.backgroundStrokeWidth"
      [backgroundPadding]="componentCurrentView.backgroundPadding"
      [radius]="componentCurrentView.radius"
      [space]="componentCurrentView.space"
      [toFixed]="componentCurrentView.toFixed"
      [maxPercent]="componentCurrentView.maxPercent"
      [renderOnClick]="componentCurrentView.renderOnClick"
      [units]="componentCurrentView.units"
      [unitsFontSize]="componentCurrentView.unitsFontSize"
      [unitsFontWeight]="componentCurrentView.unitsFontWeight"
      [unitsColor]="componentCurrentView.unitsColor"
      [outerStrokeGradient]="componentCurrentView.outerStrokeGradient"
      [outerStrokeWidth]="componentCurrentView.outerStrokeWidth"
      [outerStrokeColor]="componentCurrentView.outerStrokeColor"
      [outerStrokeGradientStopColor]="componentCurrentView.outerStrokeGradientStopColor"
      [outerStrokeLinecap]="componentCurrentView.outerStrokeLinecap"
      [innerStrokeColor]="componentCurrentView.innerStrokeColor"
      [innerStrokeWidth]="componentCurrentView.innerStrokeWidth"
      [titleFormat]="componentCurrentView.titleFormat"
      [titleColor]="componentCurrentView.titleColor"
      [titleFontSize]="componentCurrentView.titleFontSize"
      [titleFontWeight]="componentCurrentView.titleFontWeight"
      [subtitleFormat]="componentCurrentView.subtitleFormat"
      [subtitleColor]="componentCurrentView.subtitleColor"
      [subtitleFontSize]="componentCurrentView.subtitleFontSize"
      [subtitleFontWeight]="componentCurrentView.subtitleFontWeight"
      [imageSrc]="componentCurrentView.imageSrc"
      [imageHeight]="componentCurrentView.imageHeight"
      [imageWidth]="componentCurrentView.imageWidth"
      [animation]="componentCurrentView.animation"
      [animateTitle]="componentCurrentView.animateTitle"
      [animateSubtitle]="componentCurrentView.animateSubtitle"
      [animationDuration]="componentCurrentView.animationDuration"
      [showTitle]="componentCurrentView.showTitle"
      [showSubtitle]="componentCurrentView.showSubtitle"
      [showUnits]="componentCurrentView.showUnits"
      [showImage]="componentCurrentView.showImage"
      [showBackground]="componentCurrentView.showBackground"
      [showInnerStroke]="componentCurrentView.showInnerStroke"
      [clockwise]="componentCurrentView.clockwise"
      [responsive]="componentCurrentView.responsive"
      [startFromZero]="componentCurrentView.startFromZero"
      [showZeroOuterStroke]="componentCurrentView.showZeroOuterStroke"
    ></circle-progress>
    <circle-progress
      [percent]="calculatedPercentage.hours"
      [title]="calculatedText.hours"
      [subtitle]="componentCurrentView.hours"
      [class]="componentCurrentView.class"
      [backgroundGradient]="componentCurrentView.backgroundGradient"
      [backgroundColor]="componentCurrentView.backgroundColor"
      [backgroundGradientStopColor]="componentCurrentView.backgroundGradientStopColor"
      [backgroundOpacity]="componentCurrentView.backgroundOpacity"
      [backgroundStroke]="componentCurrentView.backgroundStroke"
      [backgroundStrokeWidth]="componentCurrentView.backgroundStrokeWidth"
      [backgroundPadding]="componentCurrentView.backgroundPadding"
      [radius]="componentCurrentView.radius"
      [space]="componentCurrentView.space"
      [toFixed]="componentCurrentView.toFixed"
      [maxPercent]="componentCurrentView.maxPercent"
      [renderOnClick]="componentCurrentView.renderOnClick"
      [units]="componentCurrentView.units"
      [unitsFontSize]="componentCurrentView.unitsFontSize"
      [unitsFontWeight]="componentCurrentView.unitsFontWeight"
      [unitsColor]="componentCurrentView.unitsColor"
      [outerStrokeGradient]="componentCurrentView.outerStrokeGradient"
      [outerStrokeWidth]="componentCurrentView.outerStrokeWidth"
      [outerStrokeColor]="componentCurrentView.outerStrokeColor"
      [outerStrokeGradientStopColor]="componentCurrentView.outerStrokeGradientStopColor"
      [outerStrokeLinecap]="componentCurrentView.outerStrokeLinecap"
      [innerStrokeColor]="componentCurrentView.innerStrokeColor"
      [innerStrokeWidth]="componentCurrentView.innerStrokeWidth"
      [titleFormat]="componentCurrentView.titleFormat"
      [titleColor]="componentCurrentView.titleColor"
      [titleFontSize]="componentCurrentView.titleFontSize"
      [titleFontWeight]="componentCurrentView.titleFontWeight"
      [subtitleFormat]="componentCurrentView.subtitleFormat"
      [subtitleColor]="componentCurrentView.subtitleColor"
      [subtitleFontSize]="componentCurrentView.subtitleFontSize"
      [subtitleFontWeight]="componentCurrentView.subtitleFontWeight"
      [imageSrc]="componentCurrentView.imageSrc"
      [imageHeight]="componentCurrentView.imageHeight"
      [imageWidth]="componentCurrentView.imageWidth"
      [animation]="componentCurrentView.animation"
      [animateTitle]="componentCurrentView.animateTitle"
      [animateSubtitle]="componentCurrentView.animateSubtitle"
      [animationDuration]="componentCurrentView.animationDuration"
      [showTitle]="componentCurrentView.showTitle"
      [showSubtitle]="componentCurrentView.showSubtitle"
      [showUnits]="componentCurrentView.showUnits"
      [showImage]="componentCurrentView.showImage"
      [showBackground]="componentCurrentView.showBackground"
      [showInnerStroke]="componentCurrentView.showInnerStroke"
      [clockwise]="componentCurrentView.clockwise"
      [responsive]="componentCurrentView.responsive"
      [startFromZero]="componentCurrentView.startFromZero"
      [showZeroOuterStroke]="componentCurrentView.showZeroOuterStroke"
    ></circle-progress>
    <circle-progress
      [percent]="calculatedPercentage.minutes"
      [title]="calculatedText.minutes"
      [subtitle]="componentCurrentView.minutes"
      [class]="componentCurrentView.class"
      [backgroundGradient]="componentCurrentView.backgroundGradient"
      [backgroundColor]="componentCurrentView.backgroundColor"
      [backgroundGradientStopColor]="componentCurrentView.backgroundGradientStopColor"
      [backgroundOpacity]="componentCurrentView.backgroundOpacity"
      [backgroundStroke]="componentCurrentView.backgroundStroke"
      [backgroundStrokeWidth]="componentCurrentView.backgroundStrokeWidth"
      [backgroundPadding]="componentCurrentView.backgroundPadding"
      [radius]="componentCurrentView.radius"
      [space]="componentCurrentView.space"
      [toFixed]="componentCurrentView.toFixed"
      [maxPercent]="componentCurrentView.maxPercent"
      [renderOnClick]="componentCurrentView.renderOnClick"
      [units]="componentCurrentView.units"
      [unitsFontSize]="componentCurrentView.unitsFontSize"
      [unitsFontWeight]="componentCurrentView.unitsFontWeight"
      [unitsColor]="componentCurrentView.unitsColor"
      [outerStrokeGradient]="componentCurrentView.outerStrokeGradient"
      [outerStrokeWidth]="componentCurrentView.outerStrokeWidth"
      [outerStrokeColor]="componentCurrentView.outerStrokeColor"
      [outerStrokeGradientStopColor]="componentCurrentView.outerStrokeGradientStopColor"
      [outerStrokeLinecap]="componentCurrentView.outerStrokeLinecap"
      [innerStrokeColor]="componentCurrentView.innerStrokeColor"
      [innerStrokeWidth]="componentCurrentView.innerStrokeWidth"
      [titleFormat]="componentCurrentView.titleFormat"
      [titleColor]="componentCurrentView.titleColor"
      [titleFontSize]="componentCurrentView.titleFontSize"
      [titleFontWeight]="componentCurrentView.titleFontWeight"
      [subtitleFormat]="componentCurrentView.subtitleFormat"
      [subtitleColor]="componentCurrentView.subtitleColor"
      [subtitleFontSize]="componentCurrentView.subtitleFontSize"
      [subtitleFontWeight]="componentCurrentView.subtitleFontWeight"
      [imageSrc]="componentCurrentView.imageSrc"
      [imageHeight]="componentCurrentView.imageHeight"
      [imageWidth]="componentCurrentView.imageWidth"
      [animation]="componentCurrentView.animation"
      [animateTitle]="componentCurrentView.animateTitle"
      [animateSubtitle]="componentCurrentView.animateSubtitle"
      [animationDuration]="componentCurrentView.animationDuration"
      [showTitle]="componentCurrentView.showTitle"
      [showSubtitle]="componentCurrentView.showSubtitle"
      [showUnits]="componentCurrentView.showUnits"
      [showImage]="componentCurrentView.showImage"
      [showBackground]="componentCurrentView.showBackground"
      [showInnerStroke]="componentCurrentView.showInnerStroke"
      [clockwise]="componentCurrentView.clockwise"
      [responsive]="componentCurrentView.responsive"
      [startFromZero]="componentCurrentView.startFromZero"
      [showZeroOuterStroke]="componentCurrentView.showZeroOuterStroke"
    ></circle-progress>
    <circle-progress
      [percent]="calculatedPercentage.seconds"
      [title]="calculatedText.seconds"
      [subtitle]="componentCurrentView.seconds"
      [class]="componentCurrentView.class"
      [backgroundGradient]="componentCurrentView.backgroundGradient"
      [backgroundColor]="componentCurrentView.backgroundColor"
      [backgroundGradientStopColor]="componentCurrentView.backgroundGradientStopColor"
      [backgroundOpacity]="componentCurrentView.backgroundOpacity"
      [backgroundStroke]="componentCurrentView.backgroundStroke"
      [backgroundStrokeWidth]="componentCurrentView.backgroundStrokeWidth"
      [backgroundPadding]="componentCurrentView.backgroundPadding"
      [radius]="componentCurrentView.radius"
      [space]="componentCurrentView.space"
      [toFixed]="componentCurrentView.toFixed"
      [maxPercent]="componentCurrentView.maxPercent"
      [renderOnClick]="componentCurrentView.renderOnClick"
      [units]="componentCurrentView.units"
      [unitsFontSize]="componentCurrentView.unitsFontSize"
      [unitsFontWeight]="componentCurrentView.unitsFontWeight"
      [unitsColor]="componentCurrentView.unitsColor"
      [outerStrokeGradient]="componentCurrentView.outerStrokeGradient"
      [outerStrokeWidth]="componentCurrentView.outerStrokeWidth"
      [outerStrokeColor]="componentCurrentView.outerStrokeColor"
      [outerStrokeGradientStopColor]="componentCurrentView.outerStrokeGradientStopColor"
      [outerStrokeLinecap]="componentCurrentView.outerStrokeLinecap"
      [innerStrokeColor]="componentCurrentView.innerStrokeColor"
      [innerStrokeWidth]="componentCurrentView.innerStrokeWidth"
      [titleFormat]="componentCurrentView.titleFormat"
      [titleColor]="componentCurrentView.titleColor"
      [titleFontSize]="componentCurrentView.titleFontSize"
      [titleFontWeight]="componentCurrentView.titleFontWeight"
      [subtitleFormat]="componentCurrentView.subtitleFormat"
      [subtitleColor]="componentCurrentView.subtitleColor"
      [subtitleFontSize]="componentCurrentView.subtitleFontSize"
      [subtitleFontWeight]="componentCurrentView.subtitleFontWeight"
      [imageSrc]="componentCurrentView.imageSrc"
      [imageHeight]="componentCurrentView.imageHeight"
      [imageWidth]="componentCurrentView.imageWidth"
      [animation]="componentCurrentView.animation"
      [animateTitle]="componentCurrentView.animateTitle"
      [animateSubtitle]="componentCurrentView.animateSubtitle"
      [animationDuration]="componentCurrentView.animationDuration"
      [showTitle]="componentCurrentView.showTitle"
      [showSubtitle]="componentCurrentView.showSubtitle"
      [showUnits]="componentCurrentView.showUnits"
      [showImage]="componentCurrentView.showImage"
      [showBackground]="componentCurrentView.showBackground"
      [showInnerStroke]="componentCurrentView.showInnerStroke"
      [clockwise]="componentCurrentView.clockwise"
      [responsive]="componentCurrentView.responsive"
      [startFromZero]="componentCurrentView.startFromZero"
      [showZeroOuterStroke]="componentCurrentView.showZeroOuterStroke"
    ></circle-progress>
  </div>
</div>
