import { EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
var LiveIconsComponent = /** @class */ (function () {
    function LiveIconsComponent(http) {
        this.http = http;
        this.mediaRequest = new EventEmitter();
    }
    LiveIconsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.media.length > 0) {
            this.media.forEach(function (media) {
                _this.initiatePolling(media);
            });
        }
        else {
            this.initiatePolling(this.media);
        }
    };
    LiveIconsComponent.prototype.initiatePolling = function (media) {
        var _this = this;
        if (media.mediaType === 'audio') {
            this.audioEntryData = media;
            if (media && media.isLiveUrl) {
                this.getPollResponse(media.isLiveUrl)
                    .subscribe(function (res) {
                    _this.isAudioLiveLocalRef = res;
                });
            }
        }
        else if (media.mediaType === 'video') {
            this.videoEntryData = media;
            if (media && media.isLiveUrl) {
                this.getPollResponse(media.isLiveUrl)
                    .subscribe(function (res) {
                    _this.isVideoLiveLocalRef = res;
                });
            }
        }
    };
    LiveIconsComponent.prototype.getPollResponse = function (url) {
        return this.http.get(url);
    };
    LiveIconsComponent.prototype.changeMedia = function (media) {
        this.mediaRequest.emit(media);
        //this.videoPlayerService.setEntryAndKSBSubject(entryId, null);
        //this.videoPlayerService.changeMediaMessage(this.cardData);
    };
    return LiveIconsComponent;
}());
export { LiveIconsComponent };
