<ng-container>
  <div *ngIf="getTitle()"
       class="amg-section-title"
       [innerHTML]="getTitle()"></div>

  <div *ngIf="getDescription()"
       class="amg-section-description"
       [innerHTML]="getDescription()"></div>

  <div class="amg-section-children" *ngIf="areChildrenAvailable()">
    <div *ngFor="let child of getChildren()"
      class="amg-section-child">

      <ng-container [ngSwitch]="child.objectType">
        <amg-wp-section *ngSwitchCase="'Section'"
                        [section]="child"
                        [videoPlayerConfiguration]="videoPlayerConfiguration"
                        (triggerDisabledByDrm)="onDrmStateChange($event)"
                        (triggerChange)="onElementChange($event)"></amg-wp-section>
        <amg-wp-sections *ngSwitchCase="'Sections'" [sections]="child"
                         [videoPlayerConfiguration]="videoPlayerConfiguration"
                         (triggerDisabledByDrm)="onDrmStateChange($event)"
                         (triggerChange)="onElementChange($event)"></amg-wp-sections>

        <!-- HTML Elements -->
        <amg-wp-input-field *ngSwitchCase="'InputField'" [inputField]="child"></amg-wp-input-field>
        <amg-wp-icon *ngSwitchCase="'Icon'" [icon]="child"></amg-wp-icon>
        <amg-wp-action-link *ngSwitchCase="'ActionLink'" [actionLink]="child"></amg-wp-action-link>
        <amg-wp-text *ngSwitchCase="'Text'" [text]="child"></amg-wp-text>

        <!-- HTML Grouped Elements -->
        <amg-wp-advertisement *ngSwitchCase="'Advertisement'" [advertisement]="child"></amg-wp-advertisement>
        <amg-wp-icons *ngSwitchCase="'Icons'" [icons]="child"></amg-wp-icons>
        <amg-wp-social-links *ngSwitchCase="'SocialLinks'" [socialLinks]="child"></amg-wp-social-links>
        <amg-wp-input-form-group *ngSwitchCase="'InputFormGroup'" [inputFormGroup]="child"></amg-wp-input-form-group>
        <amg-wp-navigation-bar *ngSwitchCase="'NavigationBar'" [navigationBar]="child"></amg-wp-navigation-bar>
        <amg-wp-text-links *ngSwitchCase="'ActionLinks'" [actionLinks]="child"></amg-wp-text-links>
        <amg-wp-text-group *ngSwitchCase="'TextGroup'" [textGroup]="child"></amg-wp-text-group>

        <!-- Payment Elements -->
        <amg-wp-payment-fields-group *ngSwitchCase="'PaymentFieldsGroup'"
                                     [videoPlayerConfiguration]="videoPlayerConfiguration"
                                     [paymentFieldsGroup]="child"
                                     (triggerDisabledByDrm)="onDrmStateChange($event)"
                                     (triggerChange)="onElementChange($event)"></amg-wp-payment-fields-group>

        <!-- Angular Components -->
        <amg-wp-anywhere-video-card *ngSwitchCase="'AnywhereVideoCard'" [videoCard]="child"></amg-wp-anywhere-video-card>
        <amg-wp-live-fixture-card *ngSwitchCase="'LiveFixtureCard'" [videoCard]="child"></amg-wp-live-fixture-card>
        <amg-wp-calendar *ngSwitchCase="'Calendar'" [calendar]="child"></amg-wp-calendar>
        <amg-wp-carousel *ngSwitchCase="'Carousel'" [carousel]="child"></amg-wp-carousel>
        <amg-wp-carousel-flickity *ngSwitchCase="'CarouselFlickity'" [carousel]="child"></amg-wp-carousel-flickity>
        <amg-wp-collapsible *ngSwitchCase="'Collapsible'" [collapsible]="child"></amg-wp-collapsible>
        <amg-wp-countdown *ngSwitchCase="'Countdown'" [countdown]="child"></amg-wp-countdown>
        <amg-wp-grid *ngSwitchCase="'Grid'"
                     [grid]="child"
                     (triggerChange)="onElementChange($event)"></amg-wp-grid>
        <amg-wp-content-projection *ngSwitchCase="'ContentProjection'" [contentProjection]="child"></amg-wp-content-projection>
        <amg-wp-fixture *ngSwitchCase="'Fixture'" [fixture]="child"></amg-wp-fixture>
        <amg-wp-fixtures *ngSwitchCase="'Fixtures'"
                         [fixtures]="child"
                         (triggerChange)="onElementChange($event)"></amg-wp-fixtures>
        <amg-wp-boxset *ngSwitchCase="'Boxset'" ></amg-wp-boxset>
      </ng-container>

    </div>
  </div>
</ng-container>
