import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import {FixtureBaseComponent} from '../../models/FixtureBaseComponent';
import {AmgEvent} from 'amg';
import {LiveButtonClick} from '../../types/LiveButtonClick';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'amg-card-calendar-fixture',
  templateUrl: './card-calendar-fixture.component.html'
})
export class CardCalendarFixtureComponent extends FixtureBaseComponent implements OnInit, OnDestroy {
  @Input() fixtureData: AmgEvent;
  @Output() buttonClicked: EventEmitter<LiveButtonClick>;
  @HostBinding('attr.is-live') isLive = false;

  private isFixtureLiveSubscription: Subscription;

  constructor(protected http: HttpClient) {
    super(http);

    this.buttonClicked = new EventEmitter<LiveButtonClick>();
  }

  ngOnInit() {
    this.init();

    this.isFixtureLiveSubscription = this.getIsAnyFixtureLiveObservable().subscribe(isLive => {
      this.isLive = isLive;
    });
  }

  ngOnDestroy() {
    this.isFixtureLiveSubscription.unsubscribe();

    this.destroy();
  }

  onMediaDataButtonClick(data: LiveButtonClick) {
    this.buttonClicked.emit(data);
  }
}
