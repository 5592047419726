import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {LoginFormHeader} from '../../../../shared/data/stream-payments-scripts.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'amg-form-header',
  templateUrl: './form-header.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FormHeaderComponent implements OnInit {
  @Input() formHeader: LoginFormHeader;
  @Input() modalRef: NgbActiveModal;

  constructor() {
  }

  ngOnInit() {

  }

}
