export class CardProfileResponsivePropertiesView {
  refreshRate: number;

  public static defaultValues(input ? : CardProfileResponsivePropertiesView): CardProfileResponsivePropertiesView {
    let toReturn: CardProfileResponsivePropertiesView;
    if (input === undefined) {
      toReturn = new CardProfileResponsivePropertiesView();
    } else {
      toReturn = input;
    }

    toReturn = {
      ...toReturn,
      ...{
        refreshRate: 200,
      }
    };

    return toReturn;
  }
}
