/*
 * Public API Surface of video-tile
 */

export { CardVideoComponent } from './lib/card-video.component';
export { CardVideoModule } from './lib/card-video.module';

export { CardVideoResponsiveProperties } from './lib/card.video.responsive.properties';
export { CardVideoResponsivePropertiesView } from './lib/card.video.responsive.properties.view';
export { CardVideoStaticProperties } from './lib/card.video.static.properties';
