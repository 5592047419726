/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../cards/src/lib/modules/video-card/video-card.component.ngfactory";
import * as i2 from "../../../../cards/src/lib/modules/video-card/video-card.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./carousel-flickity.component";
var styles_CarouselFlickityComponent = [];
var RenderType_CarouselFlickityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CarouselFlickityComponent, data: {} });
export { RenderType_CarouselFlickityComponent as RenderType_CarouselFlickityComponent };
function View_CarouselFlickityComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-video-card", [], null, [[null, "triggerClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("triggerClick" === en)) {
        var pd_0 = (_co.onCardClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_VideoCardComponent_0, i1.RenderType_VideoCardComponent)), i0.ɵdid(1, 114688, null, 0, i2.VideoCardComponent, [i3.DomSanitizer], { cardData: [0, "cardData"], cardConfiguration: [1, "cardConfiguration"] }, { triggerClick: "triggerClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.cardConfiguration; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CarouselFlickityComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselFlickityComponent_2)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.carouselSlides; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CarouselFlickityComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_CarouselFlickityComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { carouselContainerRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["carouselContainer", 1]], null, 4, "div", [["class", "carousel-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselFlickityComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselFlickityComponent_3)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.carouselConfig.carouselType === "card"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.carouselConfig.carouselType === "boxset"); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_CarouselFlickityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-carousel-flickity", [], [[1, "class", 0]], null, null, View_CarouselFlickityComponent_0, RenderType_CarouselFlickityComponent)), i0.ɵdid(1, 9158656, null, 0, i5.CarouselFlickityComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classCardType; _ck(_v, 0, 0, currVal_0); }); }
var CarouselFlickityComponentNgFactory = i0.ɵccf("amg-carousel-flickity", i5.CarouselFlickityComponent, View_CarouselFlickityComponent_Host_0, { carouselConfig: "carouselConfig", carouselSlides: "carouselSlides", isMoreDataAvailable: "isMoreDataAvailable", apiCallTriggered: "apiCallTriggered" }, { triggerLoadMore: "triggerLoadMore", triggerCardClick: "triggerCardClick" }, []);
export { CarouselFlickityComponentNgFactory as CarouselFlickityComponentNgFactory };
