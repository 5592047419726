var ClientResponse = /** @class */ (function () {
    function ClientResponse(data, error) {
        this._result = data;
        this._error = error;
    }
    Object.defineProperty(ClientResponse.prototype, "error", {
        get: function () {
            return this._error;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientResponse.prototype, "result", {
        get: function () {
            return this._result;
        },
        enumerable: true,
        configurable: true
    });
    return ClientResponse;
}());
export { ClientResponse };
