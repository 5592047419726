import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { InputFormGroup, InputField } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-input-form-group',
  templateUrl: './input-form-group.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InputFormGroupComponent implements OnDestroy {
  @HostBinding('attr.class') class = 'amg-form-group';
  @Input() inputFormGroup: InputFormGroup;

  private inputClearSubject: BehaviorSubject<boolean>;

  public data: {
    isFormSubmitted: boolean;
    formError: boolean;
    formData: { [key: string]: any };
  };

  constructor(private http: HttpClient) {
    this.data = {
      isFormSubmitted: false,
      formError: false,
      formData: {}
    };
    this.inputClearSubject = new BehaviorSubject<boolean>(false);
  }

  ngOnDestroy(): void {
    this.inputClearSubject.complete();
  }

  triggerFieldChange(data: { inputField: InputField; value: string; }) {
    this.data.formData[data.inputField.referenceName] = data.value;
  }

  getInputFields(): InputField[] {
    return this.inputFormGroup.inputFields;
  }

  getButtonText(): string {
    return this.inputFormGroup.submitButton
      ? this.inputFormGroup.submitButton.label
      : 'Submit';
  }

  getButtonCssClass(): string {
    return this.inputFormGroup.submitButton
      ? this.inputFormGroup.submitButton.cssClass
      : '';
  }

  getFormActionUrl(): string {
    return this.inputFormGroup.restApiConfiguration.baseUrl;
  }

  onSubmit(event: any) {
    if (event.target.checkValidity()) {
      this.http.post(
        this.getFormActionUrl(),
        this.data.formData
      ).subscribe(value => {
        if (value
          && (value as any).ModelErrors
          && Object.keys((value as any).ModelErrors).length > 0 ) {

          this.triggerError();
        } else {
          this.data = {
            isFormSubmitted: true,
            formError: false,
            formData: {}
          };
          event.target.reset();
          this.inputClearSubject.next(true);
        }
      }, error1 => {
        this.triggerError();
      });
    } else {
      this.triggerError();
    }

    return false;
  }

  getInputClearAsObservable(): Observable<boolean> {
    return this.inputClearSubject.asObservable();
  }

  private triggerError(): void {
    this.data = {
      isFormSubmitted: false,
      formError: true,
      formData: this.data.formData
    };
  }
}
