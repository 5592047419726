import { BaseObject } from '../../../BaseObject';
import { WpImageData } from '../../../types/wordpress/WpImageData';

export class Icon extends BaseObject {
  private readonly _imageData: WpImageData;
  private readonly _label: string;
  private readonly _link: string;
  private readonly _relativeLink: string;

  constructor(data: any) {
    super('Icon', data);

    this._imageData = data.image;
    this._label = data.label;
    this._link = data.link;
    this._relativeLink = data.relative_link
      ? `/${data.relative_link}`
      : '';
  }

  get imageData(): WpImageData {
    return this._imageData;
  }

  get label(): string {
    return this._label;
  }

  get link(): string {
    return this._link;
  }

  get relativeLink(): string {
    return this._relativeLink;
  }
}
