/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./content-projection.component";
var styles_ContentProjectionComponent = [];
var RenderType_ContentProjectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContentProjectionComponent, data: {} });
export { RenderType_ContentProjectionComponent as RenderType_ContentProjectionComponent };
export function View_ContentProjectionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ContentProjectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-content-projection", [], [[1, "class", 0]], null, null, View_ContentProjectionComponent_0, RenderType_ContentProjectionComponent)), i0.ɵdid(1, 114688, null, 0, i2.ContentProjectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var ContentProjectionComponentNgFactory = i0.ɵccf("amg-wp-content-projection", i2.ContentProjectionComponent, View_ContentProjectionComponent_Host_0, { contentProjection: "contentProjection" }, {}, []);
export { ContentProjectionComponentNgFactory as ContentProjectionComponentNgFactory };
