var CarouselClick = /** @class */ (function () {
    /**
     * Click handler class for carousel
     */
    function CarouselClick(_clickEvent, selectedElementIndex) {
        this._clickEvent = _clickEvent;
        this.selectedElementIndex = selectedElementIndex;
        this._enableSlideClick = true;
        this._lastClickedIndex = selectedElementIndex;
    }
    /**
     * Enable clicking
     * @param source
     */
    CarouselClick.prototype.enableSlideClick = function (source) {
        if (source === void 0) { source = 'unknown'; }
        this._enableSlideClick = true;
    };
    /**
     * Disable clicking
     * @param source
     */
    CarouselClick.prototype.disableSlideClick = function (source) {
        if (source === void 0) { source = 'unknown'; }
        this._enableSlideClick = false;
    };
    /**
     * Click on slide
     * @param source
     * @param item item clicked on
     * @param type type of slide
     * @param index the index of the clicked slide
     */
    CarouselClick.prototype.slideClick = function (source, item, index) {
        if (source === void 0) { source = 'unknown'; }
        if (this._enableSlideClick) {
            if (this._lastClicked !== item) {
                this._lastClickedIndex = index;
                this._lastClicked = item;
                this._clickEvent.emit(item);
            }
        }
    };
    CarouselClick.prototype.updateSlide = function (slideIndex) {
        // TODO: pass on the current source and set it as the last item clicked
        this._lastClickedIndex = slideIndex;
    };
    CarouselClick.prototype.getLastClickedSlideIndex = function () {
        return this._lastClickedIndex;
    };
    return CarouselClick;
}());
export { CarouselClick };
