import { CarouselConfiguration } from 'carousel';
import { CardUtils } from '../../../../../util/CardUtils';
import { NgObject } from './NgObject';

export class CarouselFlickity extends NgObject {
  private readonly _carouselConfiguration: CarouselConfiguration;
  private readonly _carousalAnywhereApiUrl: string;
  private readonly _carouselStaticData: { skipFirstEntry: boolean; };

  constructor(data: any, objectType: string = 'CarouselFlickity') {
    super(objectType, data);

    this._carousalAnywhereApiUrl = data.anywhere_api_url;

    this._carouselConfiguration = {
      carouselType: data.carouselType,
      showNextPrevButtons: data.showNextPrevButtons,

      cardDisplayKeys: CardUtils.generateCardDisplayKeys(data.cardDisplayKeys),

      ...(data.triggerLoadMoreOnEndOfCarousel && data.triggerLoadMoreOnEndOfCarousel !== '0'
        ? { triggerLoadMoreOnEndOfCarousel: data.triggerLoadMoreOnEndOfCarousel }
        : {})
    };

    this._carouselStaticData = {
      skipFirstEntry: data.skipFirstEntry || false
    };
  }

  get carouselStaticData(): { skipFirstEntry: boolean } {
    return this._carouselStaticData;
  }

  get carouselConfiguration(): CarouselConfiguration {
    return this._carouselConfiguration;
  }

  get carousalAnywhereApiUrl(): string {
    return this._carousalAnywhereApiUrl;
  }
}
