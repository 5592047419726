<div class="video-card" (click)="onCardClick()">
  <div *ngIf="cardConfiguration.displayKeys.thumbnailKey"
       class="card-img">
    <img [defaultImage]="defaultImage"
         [lazyLoad]="getThumbnail()" />
  </div>

  <div *ngIf="cardConfiguration.displayKeys.cardInformationKeys"
       class="card-info">
    <div>
      <div *ngFor="let key of cardConfiguration.displayKeys.cardInformationKeys"
           [ngClass]="getClassName(key)"
           [innerHTML]="getData(key)">
      </div>
    </div>
  </div>

  <div *ngIf="cardConfiguration.displayKeys.cardBodyKeys"
       class="card-body">
    <div>
      <div *ngFor="let key of cardConfiguration.displayKeys.cardBodyKeys"
           [ngClass]="getClassName(key)"
           [innerHTML]="getData(key)">
      </div>
    </div>
  </div>
</div>
