import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { BaseObject } from '../../../BaseObject';
import { BaseConfiguration } from '../../../config/BaseConfiguration';
import { KalturaConfiguration } from '../../../config/KalturaConfiguration';
import { WpImageData } from '../../../types/wordpress/WpImageData';
import { ActionLink } from '../html-elements/ActionLink';
import { Button } from '../html-elements/Button';
import { LiveLoginOverlay } from 'video-player';

export class PaymentVideoPlayer extends BaseObject {
  private readonly _audioPlayButton: WpImageData;
  private readonly _videoPlayButton: WpImageData;
  private readonly _baseConfiguration: BaseConfiguration;
  private readonly _kalturaPlayerConfiguration: KalturaConfiguration;
  private readonly _liveLoginOverlay: LiveLoginOverlay;
  private readonly _reloadPlayerOnSamePageNavigation: boolean;
  private readonly _drmMessage: string;

  private static mapToLoginOverlay(data: any): LiveLoginOverlay {
    const actionLink: ActionLink = data.loginButtonGroup.textLinkButton,
      link = actionLink.path ? actionLink.path : actionLink.externalLink;

    const button: Button = data.loginButtonGroup.button;

    return {
      title: data.title,
      subTitle: data.subtitle,
      buttonSeparator: data.loginButtonGroup.textBetween,
      subscribeBtn: {
        label: actionLink.label,
        link,
        cssClass: actionLink.label
      },
      logInBtn: {
        label: button.label,
        link: null,
        cssClass: button.cssClass
      }
    };
  }

  constructor(data: any) {
    super('PaymentVideoPlayer', data);

    this._audioPlayButton = data.amg_play_button_group.audio_play_button;
    this._videoPlayButton = data.amg_play_button_group.video_play_button;
    this._drmMessage = data.drm_message || '';
    this._liveLoginOverlay = PaymentVideoPlayer.mapToLoginOverlay(data.login_overlay);
    this._reloadPlayerOnSamePageNavigation = data.hasOwnProperty('reload_player_on_same_page_navigation')
      ? !!data.reload_player_on_same_page_navigation
      : false;

    if (data.player_configuration && data.player_configuration.length > 0) {
      const configDataset = CollectionUtils.retrieveConfigurationData(data.player_configuration);

      this._baseConfiguration = configDataset.baseConfiguration;
      this._kalturaPlayerConfiguration = configDataset.kalturaConfiguration;
    }
  }

  get drmMessage(): string {
    return this._drmMessage;
  }

  get reloadPlayerOnSamePageNavigation(): boolean {
    return this._reloadPlayerOnSamePageNavigation;
  }

  get kalturaPlayerConfiguration(): KalturaConfiguration {
    return this._kalturaPlayerConfiguration;
  }

  get baseConfiguration(): BaseConfiguration {
    return this._baseConfiguration;
  }

  get audioPlayButton(): WpImageData {
    return this._audioPlayButton;
  }

  get videoPlayButton(): WpImageData {
    return this._videoPlayButton;
  }

  get liveLoginOverlay(): LiveLoginOverlay {
    return this._liveLoginOverlay;
  }
}
