import { AnywhereApiConfiguration } from '../api/data/config/AnywhereApiConfiguration';
import { BaseConfiguration } from '../api/data/config/BaseConfiguration';
import { KalturaConfiguration } from '../api/data/config/KalturaConfiguration';
import { RestApiConfiguration } from '../api/data/config/RestApiConfiguration';
import { ConfigurationDataSet } from '../api/data/types/Configuration';
import { ScreenConfiguration } from '../api/data/types/ng-objects/ScreenConfiguration';

export class CollectionUtils {
  private static readonly SCREEN_SIZES = ['xs', 'sm', 'md', 'lg', 'xl', 'HD', 'FullHd', 'FourK'];

  public static retrieveConfigurationData(
    data: (BaseConfiguration|AnywhereApiConfiguration|KalturaConfiguration|RestApiConfiguration)[] ): ConfigurationDataSet {

    let configurationDataSet = {};

    data.forEach(config => {
      switch (config.objectType) {
        case 'BaseConfiguration':
          configurationDataSet = {
            ...configurationDataSet,
            baseConfiguration: config
          };
          break;
        case 'AnywhereApiConfiguration':
          configurationDataSet = {
            ...configurationDataSet,
            anywhereApiConfiguration: config
          };
          break;
        case 'KalturaConfiguration':
          configurationDataSet = {
            ...configurationDataSet,
            kalturaConfiguration: config
          };
          break;
        case 'RestApiConfiguration':
          configurationDataSet = {
            ...configurationDataSet,
            restApiConfiguration: config
          };
          break;
      }
    });

    return configurationDataSet;
  }

  public static flattenArrayOfSection(elements: any[]): any[] {
    const flatArray = [];

    elements.forEach(element => {
      if (Array.isArray(element)) {
        flatArray.push(...element);
      } else {
        flatArray.push(element);
      }
    });

    return flatArray;
  }

  public static flattenArrayOfResponsiveConfiguration<D>(
    screenResponsiveConfig: any[],
    mappingFunction: (data: any) => D ): ScreenConfiguration<D> {

    let screenConfiguration: ScreenConfiguration<D> = {};
    if (screenResponsiveConfig && screenResponsiveConfig.length > 0) {
      screenResponsiveConfig.forEach(screenProperty => {
        const screen = screenProperty.screen,
          configuration = screenProperty.uiConf;

        screenConfiguration = {
          ...screenConfiguration,
          [screen]: mappingFunction(configuration)
        };
      });
    }

    if (screenResponsiveConfig.length !== CollectionUtils.SCREEN_SIZES.length) {
      screenConfiguration = CollectionUtils.generateMissingScreenSizes<D>(screenConfiguration);
    }

    return screenConfiguration;
  }

  public static parseNumber(data: string) {
    if (data) {
      const parsedData = Number.parseFloat(data);

      return Number.isNaN(parsedData)
        ? 0
        : parsedData;
    }

    return 0;
  }

  private static generateMissingScreenSizes<D>(screenConfiguration: ScreenConfiguration<D>): ScreenConfiguration<D> {
    CollectionUtils.SCREEN_SIZES.forEach(screen => {
      if (!screenConfiguration[screen]) {
        screenConfiguration = {
          ...screenConfiguration,
          [screen]: {}
        };
      }
    });

    return screenConfiguration;
  }
}
