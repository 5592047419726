import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { BootstrapViewportEnum } from './bootstrap.viewport.enum';

@Injectable({
  providedIn: 'root'
})
export class BootstrapViewportService {
  viewportBehaviourSubject: BehaviorSubject<BootstrapViewportEnum> = new BehaviorSubject<BootstrapViewportEnum>(BootstrapViewportEnum.xs);
  viewportObservable = this.viewportBehaviourSubject.asObservable();

  constructor() {
    window.onresize = (e) => {
      this.windowResized();
    }
    this.windowResized();
  }

  public getViewport():BootstrapViewportEnum {
    return this.viewportBehaviourSubject.getValue();
  }

  public getViewportSubscriber(): Observable<BootstrapViewportEnum> {
    return this.viewportObservable;
  }

  private windowResized() {
    const currentViewportSize =  this.viewportBehaviourSubject.getValue();
    const newViewportSize =  this.getCurrentViewportSize();
    if (currentViewportSize !== newViewportSize) {
      this.viewportBehaviourSubject.next(newViewportSize);
    }
  }

  private getCurrentViewportSize(): BootstrapViewportEnum {
    const viewPortSize: number = window.outerWidth;
    
    let toReturn: BootstrapViewportEnum = BootstrapViewportEnum.xs;
    if (viewPortSize >= 576) {
      toReturn = BootstrapViewportEnum.sm;
      if (viewPortSize >= 768) {
        toReturn = BootstrapViewportEnum.md;
        if (viewPortSize >= 992) {
          toReturn = BootstrapViewportEnum.lg;
          if (viewPortSize >= 1200) {
            toReturn = BootstrapViewportEnum.xl;
            if (viewPortSize >= 1280) {
              toReturn = BootstrapViewportEnum.HD;
              if (viewPortSize >= 1920) {
                toReturn = BootstrapViewportEnum.FullHd;
                if (viewPortSize >= 4096) {
                  toReturn = BootstrapViewportEnum.FourK;
                }
              }
            }
          }
        }
      }
    }

    return toReturn;
  }
}
