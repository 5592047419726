import { CircleProgressOptions } from 'ng-circle-progress';

import { AmgComponentResponsivePropertiesView } from 'amg';

export class CountdownResponsivePropertiesView extends CircleProgressOptions implements AmgComponentResponsivePropertiesView {
  public refreshRate: number = 200;
  public days: string = 'days';
  public hours: string = 'hours';
  public minutes: string = 'minutes';
  public seconds: string = 'seconds';

  radius = 60;
  space = -10;
  outerStrokeGradient = true;
  outerStrokeWidth = 10;
  outerStrokeColor = "#4882c2";
  outerStrokeGradientStopColor = "#53a9ff";
  innerStrokeColor = "#e7e8ea";
  innerStrokeWidth = 10;
  title = "UI";
  animateTitle = false;
  animationDuration = 1000;
  showUnits = false;
  showBackground = false;
  clockwise = false;
  responsive = true;
  startFromZero = false;

  public static defaultValues(input?: CountdownResponsivePropertiesView): CountdownResponsivePropertiesView {
    let toReturn: CountdownResponsivePropertiesView;
    if (input === undefined) {
      toReturn = new CountdownResponsivePropertiesView();
    } else {
      toReturn = input;
    }
    
    toReturn = {...toReturn, ...new CountdownResponsivePropertiesView()};

    return toReturn;
  }
}
