export class CardImageResponsivePropertiesView {
  refreshRate: number;

  public static defaultValues(input ? : CardImageResponsivePropertiesView): CardImageResponsivePropertiesView {
    let toReturn: CardImageResponsivePropertiesView;
    if (input === undefined) {
      toReturn = new CardImageResponsivePropertiesView();
    } else {
      toReturn = input;
    }

    toReturn = {
      ...toReturn,
      ...{
        refreshRate: 200,
      }
    };

    return toReturn;
  }
}
