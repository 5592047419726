import { EventEmitter, OnInit } from '@angular/core';
import { PaymentFieldsGroup } from 'wordpress-adapter';
var PaymentFieldsGroupComponent = /** @class */ (function () {
    function PaymentFieldsGroupComponent() {
        this.class = 'amg-payment-fields-group';
        this.triggerChange = new EventEmitter();
        this.triggerDisabledByDrm = new EventEmitter();
    }
    PaymentFieldsGroupComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
    };
    PaymentFieldsGroupComponent.prototype.getCss = function () {
        return this.paymentFieldsGroup.cssClass;
    };
    PaymentFieldsGroupComponent.prototype.getLoginButtonGroup = function () {
        return this.paymentFieldsGroup.loginButtonGroup;
    };
    PaymentFieldsGroupComponent.prototype.getUserSignInPage = function () {
        return this.paymentFieldsGroup.userAuthorizationPage;
    };
    PaymentFieldsGroupComponent.prototype.getVideoPlayer = function () {
        return this.paymentFieldsGroup.videoPlayer;
    };
    PaymentFieldsGroupComponent.prototype.getSubscribePackages = function () {
        return this.paymentFieldsGroup.subscribePackages;
    };
    PaymentFieldsGroupComponent.prototype.onChange = function (value) {
        this.triggerChange.emit(value);
    };
    PaymentFieldsGroupComponent.prototype.onDrmStateChange = function (value) {
        this.triggerDisabledByDrm.emit(value);
    };
    return PaymentFieldsGroupComponent;
}());
export { PaymentFieldsGroupComponent };
