import {DOCUMENT} from '@angular/common';
import {Component, HostBinding, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {SubscriptionPackageModel} from '../../shared/data/subscription-package.model';

// import {SsoServiceOld} from '../../../../../../../src/app/core/service/sso-service/sso.service';

@Component({
  selector: 'amg-subscription-package',
  templateUrl: './subscription-package.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionPackageComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-subscription-package';
  @Input() package: SubscriptionPackageModel;

  formattedPrice: string;

  public intervalData: { number: string; text: string; };

  constructor(
    @Inject(DOCUMENT) private document: any,
    // private ssoService: SsoServiceOld
  ) {
  }

  ngOnInit() {
    this.intervalData = SubscriptionPackageComponent.parseIntervalDate(this.package.Interval);
    this.formatPrice(this.package);
  }

  onButtonClick() {
    this.document.location.href = this.package.PurchaseUrl;
  /*  // if (this.ssoService && this.ssoService.tokenData && this.ssoService.tokenData.access_token) {
    //
    //   jwt = this.ssoService.tokenData.access_token;
    // }
    const packageUrl = new URL(this.package.PurchaseUrl);
    if (window.localStorage.getItem('stream-token')) {
      packageUrl.searchParams.append('token', window.localStorage.getItem('stream-token'));
    }

    this.document.location.href = packageUrl.href;*/
  }

  formatPrice(pkg: SubscriptionPackageModel) {
    // ToDo: remove when fixed on backend
    if (pkg.Amount < 1) {
      switch (pkg.Currency) {
        case 'GBP': {
          this.formattedPrice = (pkg.Amount * 100) + 'p';
          break;
        }
        default: {
          this.formattedPrice = pkg.AmountFormatted;
          break;
        }
      }
    } else {
      this.formattedPrice = pkg.AmountFormatted;
    }
  }

  private static parseIntervalDate(value: string): { number: string; text: string; } {
    const data = value.match(/[\d.]+|\D+/g);

    return data.length > 1
      ? {
        number: data[0],
        text: data[1]
      }
      : null;
  }

}
