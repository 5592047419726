import {DOCUMENT} from '@angular/common';
import {Component, HostBinding, HostListener, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Icon} from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-icon',
  templateUrl: './icon.component.html',
  encapsulation: ViewEncapsulation.None
})
export class IconComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-icon';
  @Input() icon: Icon;

  @HostListener('click')
  onIconClick(): void {
    if (this.icon.link) {
      this.document.location.href = this.icon.link;
    } else if (this.icon.relativeLink) {
      this.router.navigate([this.icon.relativeLink], {
        preserveQueryParams: false
      });
    }
  }

  constructor(@Inject(DOCUMENT) private document: any,
              private router: Router) {
  }

  ngOnInit(): void {
    if (this.icon.link) {
      this.class = this.class.concat(' amg-icon-hover');
    }

    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }
  }

  getDefaultImage(): string {
    return this.icon.imageData.sizes.medium_large;
  }

  getRemainingImages(): string {
    const images = this.icon.imageData.sizes;

    const
      mediumImage = `${images.medium} ${images['medium-width']}w`,
      mediumLargeImage = `${images.medium_large} ${images['medium_large-width']}w`,
      largeImage = `${images.large} ${images['large-width']}w`,
      originalImage = `${this.icon.imageData.url} ${this.icon.imageData.width}w`;

    return `${mediumImage}, ${mediumLargeImage}, ${largeImage}, ${originalImage}`;
  }

  getAltText(): string {
    return this.icon.imageData.alt;
  }

  getCss(): string {
    return this.icon.cssClass;
  }
}
