<button class="btn amg-audio-button"
        [disabled]="isButtonDisabled(mandatoryButtons.audio)"
        (click)="onButtonClick('audio', mandatoryButtons.audio)">
  <span class="placeholder-1"></span>
  <span class="placeholder-2"></span>
  <span class="text-group">
    <span class="live">Live</span>
    <span class="audio">Audio</span>
  </span>
</button>

<button class="btn amg-video-button"
        [disabled]="isButtonDisabled(mandatoryButtons.video)"
        (click)="onButtonClick('video', mandatoryButtons.video)">
  <span class="placeholder-1"></span>
  <span class="placeholder-2"></span>
  <span class="text-group">
    <span class="live">Live</span>
    <span class="video">Video</span>
  </span>
</button>
