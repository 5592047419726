import {
  AmgComponentResponsiveProperties,
  BootstrapViewportEnum,
} from 'amg';

import { CountdownResponsivePropertiesView } from './countdown.responsive.properties.view';

export class CountdownResponsiveProperties implements AmgComponentResponsiveProperties {
  private static keys: Array<string> = Object.keys(BootstrapViewportEnum);

  xs?: CountdownResponsivePropertiesView;
  sm?: CountdownResponsivePropertiesView;
  md?: CountdownResponsivePropertiesView;
  lg?: CountdownResponsivePropertiesView;
  xl?: CountdownResponsivePropertiesView;
  HD?: CountdownResponsivePropertiesView;
  FullHd?: CountdownResponsivePropertiesView;
  FourK?: CountdownResponsivePropertiesView;

  constructor(
    params?: any,
  ) {
    if (params === undefined) {
      params = {};
    }
    this.xs = params.xs || null;
    this.sm = params.sm || null;
    this.md = params.md || null;
    this.lg = params.lg || null;
    this.xl = params.xl || null;
    this.HD = params.HD || null;
    this.FullHd = params.FullHd || null;
    this.FourK = params.FourK || null;
    this.synchroniseProperties();
  }

  private synchroniseProperties(overrideResponsive?: CountdownResponsiveProperties) {
    if (overrideResponsive === undefined) {
      overrideResponsive = {} as CountdownResponsiveProperties;
    }
    let previousView: CountdownResponsivePropertiesView = CountdownResponsivePropertiesView.defaultValues();
    CountdownResponsiveProperties.keys.forEach((viewPort) => {
      let currentView: CountdownResponsivePropertiesView = this[viewPort];
      let currentValues: CountdownResponsivePropertiesView = overrideResponsive[viewPort];
      currentView = {...previousView, ...currentView, ...currentValues};
      this[viewPort] = currentView;
      previousView = currentView;
    });
  }
}
