import { Component, OnInit, Input } from '@angular/core';

import { DateObject } from './date';

@Component({
  selector: 'amg-date',
  templateUrl: './date.component.html',
  styles: []
})
export class DateComponent implements OnInit {
  @Input() fixtureDate: DateObject;

  private static leftPadSingleDigit(digit: number): string {
    return digit < 10
      ? `0${digit.toString()}`
      : digit.toString();
  }

  constructor() { }

  ngOnInit() {
  }

  get hour(): string {
    return DateComponent.leftPadSingleDigit(this.fixtureDate.hour);
  }

  get minute(): string {
    return DateComponent.leftPadSingleDigit(this.fixtureDate.minute);
  }

  get day(): string {
    return DateComponent.leftPadSingleDigit(this.fixtureDate.day);
  }

  get month(): string {
    return DateComponent.leftPadSingleDigit(this.fixtureDate.month);
  }

  get year(): string {
    return this.fixtureDate.year.toString();
  }
}
