import { GridSource, GridStaticProperties } from 'grid';
import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { DropdownFilters, GridConfiguration } from '../../../types/ng-objects/GridConfiguration';
import { ScreenConfiguration } from '../../../types/ng-objects/ScreenConfiguration';
import { NgObject } from './NgObject';
import { CardType } from 'card';

export class Grid extends NgObject {
  private readonly _gridConfiguration: ScreenConfiguration<GridConfiguration>;
  private readonly _gridStaticData: GridStaticProperties;

  constructor(data: any) {
    super('Grid', data);

    this._gridStaticData = Grid.parseStaticData(data.static_data);
    this._gridConfiguration = CollectionUtils
      .flattenArrayOfResponsiveConfiguration<GridConfiguration>(data.ui_conf, Grid.gridConfigurationMapping);
  }

  get gridConfiguration(): ScreenConfiguration<GridConfiguration> {
    return this._gridConfiguration;
  }

  get gridStaticData(): GridStaticProperties {
    return this._gridStaticData;
  }

  private static parseStaticData(data: any): any {
    const pageSize = CollectionUtils.parseNumber(data.pageSize);

    return {
      cardType: CardType.Video,
      pageUrl: data.pageUrl,
      pageSource: Grid.getGridSource(data.pageSource),
      pageStart: CollectionUtils.parseNumber(data.pageStart),
      pageSize,
      loadMore: data.enableLoadMore,
      loadMoreText: data.loadMoreText,
      pagination: data.pagination
        ? { itemsPerPage: pageSize || 25 }
        : false,

      ...( data.enableSearchBar ? { searchBarProperties: this.generateSearchBarProperties(data.searchBar) } : {} )
    };
  }

  private static generateSearchBarProperties(data: any): { searchField?: any; searchFilter?: any; } {
    const searchBarProperties = {
      ...( data.searchProperties ? { searchField: this.generateSearchProperties(data.searchProperties) } : {} ),
      ...( data.filterProperties ? { searchFilter: this.generateFilterProperties(data.filterProperties) } : {} )
    };

    return searchBarProperties;
  }

  private static generateSearchProperties(data: any): { label?: string; placeholder?: string; key?: string; } {

    const searchProperties = {
      ...( data.label ? { label: data.label } : {} ),
      ...( data.placeholder ? { placeholder: data.placeholder } : {} ),
      ...( data.key ? { key: data.key } : {} )
    };

    return searchProperties;
  }

  private static generateFilterProperties(data: any[]): {
      filters: {
        label: string;
        dropdown: { key?: string; value: string; label: string; }[];
        isSearchable?: boolean;
      }[]
    } {

    const filterProperties = {
      filters: (data && data.length > 0)
        ? data.map(dropDown => {
          return {
            label: dropDown.label,
            dropdown: (dropDown.dropdown as any[]).map(dropdownData => {
              return {
                value: dropdownData.value,
                label: dropdownData.label,

                ...( dropdownData.key ? { key: dropdownData.key } : {} )
              };
            })
          };
        })
        : []
    };

    return filterProperties;
  }


  private static getGridSource(pageSource: any): GridSource {
    return (pageSource === 'Anywhere')
      ? GridSource.Anywhere
      : GridSource.Wordpress;
  }

  private static gridConfigurationMapping(data: any): GridConfiguration {
    const collapsibleConfiguration: GridConfiguration = {
      itemsToShow: CollectionUtils.parseNumber(data.items),
      rightMarginPerItem: CollectionUtils.parseNumber(data.margin),
      autoLoad: data.autoload,
      autoLoadDistance: CollectionUtils.parseNumber(data.autoloadDistance),
      responsiveRefreshRate: CollectionUtils.parseNumber(data.responsiveRefreshRate),
    };

    return collapsibleConfiguration;
  }

  private static dropdownFiltersMapping(data: any[]): DropdownFilters[] {
    const filters = data.map(filter => {
      return {
        label: filter.filterLabel,
        filters: filter.filters.map(value => value.filterBy)
      };
    });

    return filters;
  }
}
