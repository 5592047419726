import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Header } from 'wordpress-adapter';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  @Input() header: Header;

  constructor(private router: Router) {
  }
}
