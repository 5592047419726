<label *ngIf="isLabelAvailable()"
       class="amg-input-field-label"
       [innerHTML]="getLabel()"></label>

<ng-container [ngSwitch]="this.getType()">
  <select *ngSwitchCase="'select'"
          class="amg-select-field"
          [name]="getReferenceName()"
          [required]="isRequired()"
          (change)="onSelectChange($event)">
    <option *ngFor="let option of getValues()"
            [value]="option.key"
            [innerHTML]="option.value">
    </option>
  </select>

  <textarea *ngSwitchCase="'textarea'"
            class="amg-text-area"
            [value]="value"
            [name]="getReferenceName()"
            [required]="isRequired()"
            [placeholder]="getPlaceholder()"
            (keyup)="onKeyUp($event)">
  </textarea>

  <input *ngSwitchDefault
         [type]="getType()"
         class="amg-input-field"
         [value]="value"
         [name]="getReferenceName()"
         [required]="isRequired()"
         [placeholder]="getPlaceholder()"
         (keyup)="onKeyUp($event)">
</ng-container>
