import * as tslib_1 from "tslib";
import { CollectionUtils } from '../../../util/CollectionUtils';
import { Sections } from './Sections';
var Header = /** @class */ (function (_super) {
    tslib_1.__extends(Header, _super);
    function Header(data) {
        var _this = _super.call(this, data, 'Header') || this;
        if (data.api_configuration_group) {
            var configDataset = CollectionUtils.retrieveConfigurationData(data.api_configuration_group);
            _this._baseConfiguration = configDataset.baseConfiguration;
            _this._anywhereApiConfiguration = configDataset.anywhereApiConfiguration;
            _this._kalturaConfiguration = configDataset.kalturaConfiguration;
            _this._restApiConfiguration = configDataset.restApiConfiguration;
        }
        return _this;
    }
    Object.defineProperty(Header.prototype, "baseConfiguration", {
        get: function () {
            return this._baseConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Header.prototype, "anywhereApiConfiguration", {
        get: function () {
            return this._anywhereApiConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Header.prototype, "kalturaConfiguration", {
        get: function () {
            return this._kalturaConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Header.prototype, "restApiConfiguration", {
        get: function () {
            return this._restApiConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    return Header;
}(Sections));
export { Header };
