import { EventEmitter } from '@angular/core';

export class CarouselClick {
  private _enableSlideClick = true;
  private _lastClicked: any;
  private _lastClickedIndex: number;

  /**
   * Click handler class for carousel
   */
  constructor(
    private _clickEvent: EventEmitter<any>,
    private selectedElementIndex: number
  ) {
    this._lastClickedIndex = selectedElementIndex;
  }

  /**
   * Enable clicking
   * @param source
   */
  enableSlideClick(source: string = 'unknown') {
    this._enableSlideClick = true;
  }
  /**
   * Disable clicking
   * @param source
   */
  disableSlideClick(source: string = 'unknown') {
    this._enableSlideClick = false;
  }

  /**
   * Click on slide
   * @param source 
   * @param item item clicked on
   * @param type type of slide
   * @param index the index of the clicked slide
   */
  slideClick(source: string = 'unknown', item: any, index: number) {
    if (this._enableSlideClick) {
      if (this._lastClicked !== item) {
        this._lastClickedIndex = index;
        this._lastClicked = item;
        this._clickEvent.emit(item);
      }
    }
  }

  updateSlide(slideIndex: number): void {
    // TODO: pass on the current source and set it as the last item clicked
    this._lastClickedIndex = slideIndex;
  }

  getLastClickedSlideIndex(): number {
    return this._lastClickedIndex;
  }
}
