import * as tslib_1 from "tslib";
import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { NgObject } from './NgObject';
var Carousel = /** @class */ (function (_super) {
    tslib_1.__extends(Carousel, _super);
    function Carousel(data, objectType) {
        if (objectType === void 0) { objectType = 'Carousel'; }
        var _this = _super.call(this, objectType, data) || this;
        _this._carouselType = data.type;
        _this._useAnywhereApiConfiguration = !!data.use_anywhere_configuration;
        if (data.interaction && data.interaction.carouselSideBar) {
            _this._carouselSideBar = data.interaction.carouselSideBar
                .map(function (object) { return object.object_property; });
        }
        else {
            _this._carouselSideBar = [];
        }
        _this._carouselConfiguration = CollectionUtils
            .flattenArrayOfResponsiveConfiguration(data.ui_conf, Carousel.carouselConfigurationMapping);
        return _this;
    }
    Object.defineProperty(Carousel.prototype, "useAnywhereApiConfiguration", {
        get: function () {
            return this._useAnywhereApiConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Carousel.prototype, "carouselType", {
        get: function () {
            return this._carouselType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Carousel.prototype, "carouselSideBar", {
        get: function () {
            return this._carouselSideBar;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Carousel.prototype, "carouselConfiguration", {
        get: function () {
            return this._carouselConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Carousel.carouselConfigurationMapping = function (data) {
        var carouselConfiguration = {
            limitViewableItems: CollectionUtils.parseNumber(data.items),
            visibleItemsOnHover: CollectionUtils.parseNumber(data.itemsWhenHovered),
            rightMarginOnItems: CollectionUtils.parseNumber(data.margin),
            imitateInfinityLoop: data.loop,
            centerItems: data.center,
            enableMouseDrag: data.mouseDrag,
            enableMouseTouchDrag: data.touchDrag,
            startPosition: CollectionUtils.parseNumber(data.startPosition),
            showButtons: data.nav,
            iterationLimit: CollectionUtils.parseNumber(data.slideBy),
            showDots: data.dots,
            hoverSpeed: CollectionUtils.parseNumber(data.hoverSpeed),
            navigationSpeed: CollectionUtils.parseNumber(data.navSpeed),
            paginationSpeed: CollectionUtils.parseNumber(data.dotSpeed),
            dragEndSpeed: CollectionUtils.parseNumber(data.dragEndSpeed),
            refreshRate: CollectionUtils.parseNumber(data.responsiveRefreshRate),
            buttonText: {
                nextButton: data.navText.next,
                previousButton: data.navText.prev
            }
        };
        return carouselConfiguration;
    };
    return Carousel;
}(NgObject));
export { Carousel };
