/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./card-text.component";
import * as i3 from "../../../amg/src/lib/bootstrap.viewport.service";
var styles_CardTextComponent = [];
var RenderType_CardTextComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CardTextComponent, data: {} });
export { RenderType_CardTextComponent as RenderType_CardTextComponent };
function View_CardTextComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getText(_v.context.$implicit); _ck(_v, 2, 0, currVal_1); }); }
export function View_CardTextComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "amg-card-text card card-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardTextComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.staticProperties.keys; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CardTextComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-card-text", [], null, null, null, View_CardTextComponent_0, RenderType_CardTextComponent)), i0.ɵdid(1, 245760, null, 0, i2.CardTextComponent, [i3.BootstrapViewportService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardTextComponentNgFactory = i0.ɵccf("amg-card-text", i2.CardTextComponent, View_CardTextComponent_Host_0, { componentData: "componentData", responsiveProperties: "responsiveProperties", staticProperties: "staticProperties", componentChildren: "componentChildren" }, {}, []);
export { CardTextComponentNgFactory as CardTextComponentNgFactory };
