import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Fixtures as WpFixture, RestApiConfiguration } from 'wordpress-adapter';
import { ApiService } from '../../../../../shared/api/api.service';
var FixturesComponent = /** @class */ (function () {
    function FixturesComponent(apiService) {
        this.apiService = apiService;
        this.class = 'amg-fixtures';
        this.apiData = {
            data: null,
            otherStaticData: null,
            isEndOfPaginationReached: false,
            isLoaded: false
        };
        this.triggerChange = new EventEmitter();
    }
    // TODO: duplicate code in api service
    FixturesComponent.extractUrlParamsFromUrl = function (url) {
        var urlParamIndex = url.indexOf('?');
        if (urlParamIndex !== -1) {
            return url.substring(urlParamIndex + 1)
                .split('&')
                .reduce(function (previousValue, currentValue) {
                var _a;
                var keyValue = currentValue.split('=');
                return tslib_1.__assign({}, previousValue, (_a = {}, _a[keyValue[0]] = keyValue[1], _a));
            }, {});
        }
        return {};
    };
    FixturesComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        // Set static data for pagination if enabled.
        this.apiData.otherStaticData = {
            total: null,
            limit: this.fixtures.limitNumberOfItems,
            offset: -this.fixtures.limitNumberOfItems
        };
        this.loadData();
    };
    FixturesComponent.prototype.ngOnDestroy = function () {
        if (this.apiDataObservable) {
            this.apiDataObservable.unsubscribe();
        }
    };
    FixturesComponent.prototype.getCss = function () {
        return this.fixtures.cssClass;
    };
    FixturesComponent.prototype.loadNextPage = function () {
        this.loadData();
    };
    FixturesComponent.prototype.getFixtures = function () {
        return this.apiData.data;
    };
    FixturesComponent.prototype.getFixtureData = function (fixture) {
        return {
            data: fixture,
            onClickNavRoute: this.fixtures.onClickNavigate,
            sendEntryDataOnNavigation: this.fixtures.sendEntryDataOnNavigation,
            fixtureApiUrl: this.fixtures.restApiConfiguration.baseUrl,
            fixtureCardType: this.fixtures.fixtureCardType
        };
    };
    FixturesComponent.prototype.isDataLoading = function () {
        return this.apiData
            && !this.apiData.isLoaded;
    };
    FixturesComponent.prototype.areFixturesPopulated = function () {
        return this.apiData.data
            && this.apiData.data.length > 0;
    };
    FixturesComponent.prototype.isPaginationEnabled = function () {
        return this.fixtures.isPaginationEnabled;
    };
    FixturesComponent.prototype.isMoreDataAvailable = function () {
        return !this.apiData.isEndOfPaginationReached;
    };
    FixturesComponent.prototype.loadData = function () {
        var _this = this;
        if (this.fixtures.restApiConfiguration) {
            if (this.apiDataObservable) {
                this.apiDataObservable.unsubscribe();
            }
            var restApiConfiguration = this.generateRestApiConfiguration();
            this.apiDataObservable = this.apiService.getApiData(restApiConfiguration).subscribe(function (fixtureData) {
                if (fixtureData && fixtureData.fixtures && Array.isArray(fixtureData.fixtures)) {
                    _this.setLoadedData(fixtureData);
                }
                else {
                    _this.setLoadedData(null);
                }
                _this.isDataAvailable = _this.apiData.data.length > 0;
                if (_this.apiData.otherStaticData.offset + _this.apiData.otherStaticData.limit >= _this.apiData.otherStaticData.total) {
                    _this.apiData.isEndOfPaginationReached = true;
                }
                _this.triggerChange.emit('fixtures');
            });
        }
    };
    FixturesComponent.prototype.generateRestApiConfiguration = function () {
        var staticData = this.apiData.otherStaticData;
        var url = this.fixtures.restApiConfiguration.baseUrl, urlWithoutParams = ApiService.removeQueryParamsFromUrl(url);
        // Url Params shall overwrite the params of the first call.
        var urlParamsObject = tslib_1.__assign({ limit: staticData.limit, offset: staticData.offset + staticData.limit }, FixturesComponent.extractUrlParamsFromUrl(url));
        var urlParams = this.convertObjectToUrlParams(urlParamsObject);
        var newUrl = urlWithoutParams + "?" + urlParams;
        return this.generateNewRestApiConfiguration(newUrl);
    };
    FixturesComponent.prototype.generateNewRestApiConfiguration = function (url) {
        return RestApiConfiguration.merge(this.fixtures.restApiConfiguration, new RestApiConfiguration({
            base_url: url
        }));
    };
    FixturesComponent.prototype.convertObjectToUrlParams = function (data) {
        var params = [];
        Object.keys(data).forEach(function (key) {
            var value = data[key];
            params.push(key + "=" + value);
        });
        return params.join('&');
    };
    FixturesComponent.prototype.setLoadedData = function (data) {
        if (data) {
            var fixtures = data.fixtures, staticData = tslib_1.__rest(data, ["fixtures"]);
            if (this.isPaginationEnabled()) {
                var currentFixtures = this.apiData.data || [];
                this.apiData = {
                    isLoaded: true,
                    isEndOfPaginationReached: this.apiData.isEndOfPaginationReached,
                    data: currentFixtures.concat(fixtures),
                    otherStaticData: staticData
                };
            }
            else {
                var newFixtures = (this.fixtures.limitNumberOfItems)
                    ? fixtures.splice(0, this.fixtures.limitNumberOfItems)
                    : fixtures;
                this.apiData = {
                    isLoaded: true,
                    isEndOfPaginationReached: this.apiData.isEndOfPaginationReached,
                    data: newFixtures,
                    otherStaticData: staticData
                };
            }
        }
        else {
            this.apiData = {
                isLoaded: true,
                isEndOfPaginationReached: true,
                data: [],
                otherStaticData: null
            };
        }
    };
    return FixturesComponent;
}());
export { FixturesComponent };
