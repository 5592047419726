import {
  AmgComponentResponsiveProperties,
  BootstrapViewportEnum,
} from 'amg';

import { CardFixtureResponsivePropertiesView } from './card.fixture.responsive.properties.view';

export class CardFixtureResponsiveProperties implements AmgComponentResponsiveProperties {
  private static keys: Array<string> = Object.keys(BootstrapViewportEnum);

    xs?: CardFixtureResponsivePropertiesView;
    sm?: CardFixtureResponsivePropertiesView;
    md?: CardFixtureResponsivePropertiesView;
    lg?: CardFixtureResponsivePropertiesView;
    xl?: CardFixtureResponsivePropertiesView;
    HD?: CardFixtureResponsivePropertiesView;
    FullHd?: CardFixtureResponsivePropertiesView;
    FourK?: CardFixtureResponsivePropertiesView;

    constructor(
      params?: any,
    ) {
      if (params === undefined) {
        params = {};
      }
      this.xs = params.xs || null;
      this.sm = params.sm || null;
      this.md = params.md || null;
      this.lg = params.lg || null;
      this.xl = params.xl || null;
      this.HD = params.HD || null;
      this.FullHd = params.FullHd || null;
      this.FourK = params.FourK || null;
      this.synchroniseProperties();
    }

    private synchroniseProperties(overrideResponsive?: CardFixtureResponsiveProperties) {
      if (overrideResponsive === undefined) {
        overrideResponsive = {} as CardFixtureResponsiveProperties;
      }
      let previousView: CardFixtureResponsivePropertiesView = CardFixtureResponsivePropertiesView.defaultValues();
      CardFixtureResponsiveProperties.keys.forEach((viewPort) => {
        let currentView: CardFixtureResponsivePropertiesView = this[viewPort];
        const currentValues: CardFixtureResponsivePropertiesView = overrideResponsive[viewPort];
        currentView = {...previousView, ...currentView, ...currentValues};
        this[viewPort] = currentView;
        previousView = currentView;
      });
    }
}
