<ng-container *ngIf="getLoginButtonGroup()">
  <amg-wp-login-button-group [loginButtonGroup]="getLoginButtonGroup()"></amg-wp-login-button-group>
</ng-container>
<ng-container *ngIf="getUserSignInPage()">
  <amg-wp-user-sign-in-page [userSignInPage]="getUserSignInPage()"></amg-wp-user-sign-in-page>
</ng-container>
<ng-container *ngIf="getVideoPlayer()">
  <amg-wp-video-player [videoPlayer]="getVideoPlayer()"
                       [videoPlayerConfiguration]="videoPlayerConfiguration"
                       (triggerDisabledByDrm)="onDrmStateChange($event)"
                       (triggerChange)="onChange($event)"></amg-wp-video-player>
</ng-container>
<ng-container *ngIf="getSubscribePackages()">
  <amg-wp-subscribe-packages [subscribePackages]="getSubscribePackages()"></amg-wp-subscribe-packages>
</ng-container>
