import { ChangeDetectorRef, OnInit } from '@angular/core';
var ThumbnailComponent = /** @class */ (function () {
    function ThumbnailComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.STREAM_AMG_IMAGE_SRC = 'open.http.mp.streamamg';
    }
    Object.defineProperty(ThumbnailComponent.prototype, "cardData", {
        get: function () {
            return this._cardData;
        },
        set: function (cardData) {
            this._cardData = cardData;
        },
        enumerable: true,
        configurable: true
    });
    ;
    ThumbnailComponent.prototype.ngOnInit = function () {
        this.thumbnailUrl = '';
        this.audioIcon = false;
        this.videoIcon = false;
        if (this.cardData) {
            this.thumbnailUrl = this.getThumbnail(this.cardData.mediaData);
            this.changeDetectorRef.detectChanges();
        }
    };
    ThumbnailComponent.prototype.playIcon = function () {
        switch (this.cardData.mediaData.mediaType) {
            case 'OnDemand':
            case 'video':
                this.videoIcon = true;
                return true;
            case 'audio':
                this.audioIcon = true;
                return true;
            default:
                return false;
        }
    };
    ThumbnailComponent.prototype.getThumbnail = function (mediaData) {
        var thumbnail = (mediaData.thumbnail)
            ? mediaData.thumbnail
            : (mediaData.thumbnailUrl)
                ? mediaData.thumbnailUrl
                : null;
        return (thumbnail && thumbnail.includes(this.STREAM_AMG_IMAGE_SRC))
            ? thumbnail + 'width/300'
            : (thumbnail)
                ? thumbnail
                : null;
    };
    ThumbnailComponent.prototype.secondsToHms = function (d) {
        var hours = Math.floor(d / 3600), minutes = Math.floor(d % 3600 / 60), seconds = Math.floor(d % 3600 % 60);
        var hDisplay = (hours > 0)
            ? (hours >= 10)
                ? hours + ":"
                : "0" + hours + ":"
            : '00:';
        var mDisplay = (minutes > 0)
            ? (minutes >= 10)
                ? minutes + ":"
                : "0" + minutes + ":"
            : '00:';
        var sDisplay = (seconds > 0)
            ? (seconds >= 10)
                ? "" + seconds
                : "0" + seconds
            : '00';
        return hDisplay + mDisplay + sDisplay;
    };
    return ThumbnailComponent;
}());
export { ThumbnailComponent };
