import { OnInit } from '@angular/core';
import { VideoPlayerService } from '../../video-player.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserAuthService } from 'user-auth';
var LoginOverlayComponent = /** @class */ (function () {
    function LoginOverlayComponent(modalService, videoPlayerService, router, userAuthService) {
        this.modalService = modalService;
        this.videoPlayerService = videoPlayerService;
        this.router = router;
        this.userAuthService = userAuthService;
    }
    LoginOverlayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.videoPlayerService.userData.subscribe(); // initiate overlay logic
        this.videoPlayerService.liveLoginOverlayBSubject
            .subscribe(function (overlay) {
            _this.liveLoginOverlayRef = overlay;
        });
    };
    LoginOverlayComponent.prototype.openLink = function (link) {
        if (link) {
            this.router.navigateByUrl(link);
        }
        else {
            console.warn('Subscribe Button link is not configured!');
        }
    };
    return LoginOverlayComponent;
}());
export { LoginOverlayComponent };
