import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Boxset as WpBoxset } from 'wordpress-adapter';
import { ApiService } from '../../../../../shared/api/api.service';
import { DataService } from '../../../../../shared/data/data.service';
var BoxsetComponent = /** @class */ (function () {
    function BoxsetComponent(apiService, dataService, router) {
        this.apiService = apiService;
        this.dataService = dataService;
        this.router = router;
        this.class = 'amg-boxset';
    }
    BoxsetComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.initialiseBoxset();
    };
    BoxsetComponent.prototype.getCss = function () {
        return this.boxset.cssClass;
    };
    BoxsetComponent.prototype.onCardClick = function (data) {
        if (this.boxset.onClickNavigate) {
            this.dataService.userInteractedObject = data;
            this.router.navigate([this.boxset.onClickNavigate]);
        }
    };
    BoxsetComponent.prototype.initialiseBoxset = function () {
        var _this = this;
        this.boxsetData = [];
        if (this.boxset.restApiConfiguration) {
            this.apiDataObservable = this.apiService.getApiData(this.boxset.restApiConfiguration).subscribe(function (data) {
                if (data) {
                    _this.boxset = _this.getData(data);
                }
            });
        }
        this.boxsetResponsiveProperties = this.boxset.carouselConfiguration;
    };
    BoxsetComponent.prototype.getData = function (data) {
        // TODO mappings
        // https://63r7ry8te4.execute-api.eu-west-1.amazonaws.com/staging/fixtures/football/p/3000021
        return {};
    };
    return BoxsetComponent;
}());
export { BoxsetComponent };
