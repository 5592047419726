import * as tslib_1 from "tslib";
import { CircleProgressOptions } from 'ng-circle-progress';
var CountdownResponsivePropertiesView = /** @class */ (function (_super) {
    tslib_1.__extends(CountdownResponsivePropertiesView, _super);
    function CountdownResponsivePropertiesView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.refreshRate = 200;
        _this.days = 'days';
        _this.hours = 'hours';
        _this.minutes = 'minutes';
        _this.seconds = 'seconds';
        _this.radius = 60;
        _this.space = -10;
        _this.outerStrokeGradient = true;
        _this.outerStrokeWidth = 10;
        _this.outerStrokeColor = "#4882c2";
        _this.outerStrokeGradientStopColor = "#53a9ff";
        _this.innerStrokeColor = "#e7e8ea";
        _this.innerStrokeWidth = 10;
        _this.title = "UI";
        _this.animateTitle = false;
        _this.animationDuration = 1000;
        _this.showUnits = false;
        _this.showBackground = false;
        _this.clockwise = false;
        _this.responsive = true;
        _this.startFromZero = false;
        return _this;
    }
    CountdownResponsivePropertiesView.defaultValues = function (input) {
        var toReturn;
        if (input === undefined) {
            toReturn = new CountdownResponsivePropertiesView();
        }
        else {
            toReturn = input;
        }
        toReturn = tslib_1.__assign({}, toReturn, new CountdownResponsivePropertiesView());
        return toReturn;
    };
    return CountdownResponsivePropertiesView;
}(CircleProgressOptions));
export { CountdownResponsivePropertiesView };
