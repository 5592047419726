import { CollectionUtils } from '../../../util/CollectionUtils';
import { AnywhereApiConfiguration } from '../config/AnywhereApiConfiguration';
import { BaseConfiguration } from '../config/BaseConfiguration';
import { KalturaConfiguration } from '../config/KalturaConfiguration';
import { RestApiConfiguration } from '../config/RestApiConfiguration';
import { Sections } from './Sections';

export class Page extends Sections {
  private readonly _baseConfiguration: BaseConfiguration;
  private readonly _anywhereApiConfiguration: AnywhereApiConfiguration;
  private readonly _restApiConfiguration: RestApiConfiguration;
  private readonly _kalturaConfiguration: KalturaConfiguration;

  constructor(data: any) {
    super(data, 'Page');

    if (data.section_api_configuration) {
      const configDataset = CollectionUtils.retrieveConfigurationData(data.section_api_configuration);

      this._baseConfiguration = configDataset.baseConfiguration;
      this._anywhereApiConfiguration = configDataset.anywhereApiConfiguration;
      this._kalturaConfiguration = configDataset.kalturaConfiguration;
      this._restApiConfiguration = configDataset.restApiConfiguration;
    }
  }

  get anywhereApiConfiguration(): AnywhereApiConfiguration {
    return this._anywhereApiConfiguration;
  }

  get baseConfiguration(): BaseConfiguration {
    return this._baseConfiguration;
  }

  get restApiConfiguration(): RestApiConfiguration {
    return this._restApiConfiguration;
  }

  get kalturaConfiguration(): KalturaConfiguration {
    return this._kalturaConfiguration;
  }
}
