var PropertyObject = /** @class */ (function () {
    function PropertyObject(data) {
        var _this = this;
        var keys = Object.keys(data);
        keys.forEach(function (key) {
            var _a;
            if (key !== 'object_type') {
                var value = data[key];
                var updatedPropertyName = key.replace(/[^a-zA-Z]([a-z])/g, function (substring) {
                    return substring[1].toUpperCase();
                }).replace(/[^a-zA-Z\d]+/g, '');
                Object.assign(_this, (_a = {},
                    _a[updatedPropertyName] = value,
                    _a));
            }
        });
    }
    return PropertyObject;
}());
export { PropertyObject };
