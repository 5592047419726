import * as tslib_1 from "tslib";
import { isDevMode } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { DateObject } from '../components/date/date';
import { BehaviorSubject, timer } from 'rxjs';
var FixtureBaseComponent = /** @class */ (function () {
    function FixtureBaseComponent(http) {
        this.http = http;
        this.isFixtureLive = false;
        this.isFixtureLiveSubject = new BehaviorSubject(false);
        this.apiSubscriptions = {};
        this.apiSubject = new BehaviorSubject(null);
    }
    FixtureBaseComponent.generateMediaObject = function (mediaData) {
        return tslib_1.__assign({}, mediaData, { isEntryCurrentlyLive: false });
    };
    FixtureBaseComponent.logPollingData = function (entry, isLiveData) {
        if (isDevMode()) {
            var isLiveString = isLiveData.isLive ? 'live' : 'not live';
            var pollingDurationInSeconds = isLiveData.pollingFrequency;
            console.log("Polling success. '" + entry.entryId + "' is currently " + isLiveString + ", and will poll in " + pollingDurationInSeconds + " seconds.");
        }
    };
    FixtureBaseComponent.prototype.getIsFixtureLive = function () {
        return this.isFixtureLive;
    };
    FixtureBaseComponent.prototype.getFixtureDate = function () {
        return this.fixtureDate;
    };
    FixtureBaseComponent.prototype.getName = function () {
        return this.fixtureData.name;
    };
    FixtureBaseComponent.prototype.getDescription = function () {
        return this.fixtureData.description;
    };
    FixtureBaseComponent.prototype.getEventType = function () {
        return this.fixtureData.eventType
            ? this.fixtureData.eventType
            : '';
    };
    FixtureBaseComponent.prototype.getDuration = function () {
        return this.fixtureData.duration
            ? Number.parseInt(this.fixtureData.duration, 10) * 1000
            : -1;
    };
    FixtureBaseComponent.prototype.getStadiumName = function () {
        return this.fixtureData.stadium
            ? this.fixtureData.stadium.name
            : '';
    };
    FixtureBaseComponent.prototype.getAwayTeamData = function () {
        return this.fixtureData.awayTeam
            ? this.fixtureData.awayTeam
            : { id: null, name: null, logo: null };
    };
    FixtureBaseComponent.prototype.getHomeTeamData = function () {
        return this.fixtureData.homeTeam
            ? this.fixtureData.homeTeam
            : { id: null, name: null, logo: null };
    };
    FixtureBaseComponent.prototype.isThumbnailAvailable = function () {
        return this.fixtureData.thumbnail && this.fixtureData.thumbnail.length > 0;
    };
    FixtureBaseComponent.prototype.getThumbnail = function () {
        return this.fixtureData.thumbnail;
    };
    FixtureBaseComponent.prototype.getMediaData = function () {
        return this.fixtureMediaData;
    };
    FixtureBaseComponent.prototype.init = function () {
        var _this = this;
        this.apiSubjectSubscription = this.apiSubject.subscribe(function (value) {
            if (value) {
                if (_this.apiSubscriptions[value.entry.entryId]) {
                    _this.apiSubscriptions[value.entry.entryId].unsubscribe();
                }
                _this.triggerApiCall(value.entry, value.pollTime);
            }
        });
        this.fixtureDate = new DateObject(this.fixtureData.startDate, false);
        this.fixtureMediaData = this.flattenMediaData(this.fixtureData.mediaData);
        this.triggerFixturePolling();
    };
    FixtureBaseComponent.prototype.destroy = function () {
        var _this = this;
        this.apiSubjectSubscription.unsubscribe();
        Object.keys(this.apiSubscriptions).forEach(function (key) {
            _this.apiSubscriptions[key].unsubscribe();
        });
    };
    FixtureBaseComponent.prototype.triggerApiCall = function (entry, pollTime) {
        var _this = this;
        if (entry.isLiveUrl) {
            if (pollTime) {
                this.apiSubscriptions[entry.entryId] = timer(pollTime)
                    .pipe(switchMap(function () { return _this.http.get(entry.isLiveUrl); }))
                    .subscribe(function (data) {
                    FixtureBaseComponent.logPollingData(entry, data);
                    _this.setDataAndTriggerPolling(entry, data);
                });
            }
            else {
                this.apiSubscriptions[entry.entryId] = this.http.get(entry.isLiveUrl).subscribe(function (data) {
                    _this.setDataAndTriggerPolling(entry, data);
                });
            }
        }
    };
    FixtureBaseComponent.prototype.getIsAnyFixtureLiveObservable = function () {
        return this.isFixtureLiveSubject.asObservable();
    };
    FixtureBaseComponent.prototype.isAnyFixtureLive = function () {
        /**
         * Check >video< entries first,
         *  and then finish off with >audio<.
         */
        var entries = this.fixtureMediaData.video.filter(function (value) { return !!value.isEntryCurrentlyLive; });
        if (entries.length !== 0) {
            return true;
        }
        else {
            return this.fixtureMediaData.audio.filter(function (value) { return !!value.isEntryCurrentlyLive; }).length > 0;
        }
    };
    FixtureBaseComponent.prototype.triggerFixturePolling = function () {
        this.triggerVideoFixturePolling();
        this.triggerAudioFixturePolling();
    };
    FixtureBaseComponent.prototype.triggerAudioFixturePolling = function () {
        var _this = this;
        this.fixtureMediaData.audio.forEach(function (value) {
            _this.triggerApiCall(value);
        });
    };
    FixtureBaseComponent.prototype.triggerVideoFixturePolling = function () {
        var _this = this;
        this.fixtureMediaData.video.forEach(function (value) {
            _this.triggerApiCall(value);
        });
    };
    FixtureBaseComponent.prototype.setDataAndTriggerPolling = function (entry, isLiveData) {
        entry.isEntryCurrentlyLive = isLiveData.isLive;
        if (isLiveData.pollingFrequency > 0) {
            var pollTime = isLiveData.pollingFrequency * 1000;
            this.apiSubject.next({ entry: entry, pollTime: pollTime });
        }
        this.isFixtureLive = this.isAnyFixtureLive();
        this.isFixtureLiveSubject.next(this.isFixtureLive);
    };
    FixtureBaseComponent.prototype.flattenMediaData = function (mediaData) {
        var flatMediaData = {
            video: [],
            audio: []
        };
        if (mediaData) {
            if (Array.isArray(mediaData)) {
                mediaData.forEach(function (value) {
                    flatMediaData[value.mediaType].push(FixtureBaseComponent.generateMediaObject(value));
                });
            }
            else {
                flatMediaData[mediaData.mediaType].push(FixtureBaseComponent.generateMediaObject(mediaData));
            }
        }
        return flatMediaData;
    };
    return FixtureBaseComponent;
}());
export { FixtureBaseComponent };
