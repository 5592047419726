import * as tslib_1 from "tslib";
import dateFormat from 'dateformat';
import { DateTimeUtils } from 'amg-fe-utils';
var CardUtils = /** @class */ (function () {
    function CardUtils() {
    }
    CardUtils.generateCardDisplayKeys = function (data) {
        var displayKeys = {};
        if (data) {
            if (data.thumbnailKey) {
                displayKeys.thumbnailKey = {
                    key: data.thumbnailKey,
                    transform: CardUtils.formatAnywhereUrl
                };
            }
            if (data.cardInformationKeys) {
                displayKeys.cardInformationKeys = CardUtils.mapCardKeys(data.cardInformationKeys);
            }
            if (data.cardBodyKeys) {
                displayKeys.cardBodyKeys = CardUtils.mapCardKeys(data.cardBodyKeys);
            }
        }
        return displayKeys;
    };
    CardUtils.mapCardKeys = function (data) {
        if (data) {
            return data.map(function (value) {
                var transformFunction = {};
                if (value.isDuration) {
                    transformFunction.transform = CardUtils.formatDuration;
                }
                else if (value.dateFormat) {
                    transformFunction.transform = function (innerData) {
                        return dateFormat(innerData, value.dateFormat);
                    };
                }
                return tslib_1.__assign({ key: value.key, arraySeparator: value.arraySeparator }, transformFunction);
            });
        }
        return [];
    };
    CardUtils.formatDuration = function (data) {
        var object = DateTimeUtils.generateRuntimeObjectBySeconds(data);
        var hours = CardUtils.padSingleDigit(object.hours);
        var minutes = CardUtils.padSingleDigit(object.minutes);
        var seconds = CardUtils.padSingleDigit(object.seconds);
        return "<span class=\"hours\" data-hours=\"" + hours + "\">" + object.hours + "</span>"
            + ("<span class=\"minutes\" data-minutes=\"" + minutes + "\">" + object.minutes + "</span>")
            + ("<span class=\"seconds\" data-seconds=\"" + seconds + "\">" + object.seconds + "</span>");
    };
    CardUtils.formatAnywhereUrl = function (data) {
        return data + "/width/550";
    };
    CardUtils.padSingleDigit = function (data) {
        var dataString = data.toString();
        return dataString.length === 1
            ? "0" + dataString
            : dataString;
    };
    return CardUtils;
}());
export { CardUtils };
