import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var NgObject = /** @class */ (function (_super) {
    tslib_1.__extends(NgObject, _super);
    function NgObject(objectType, data) {
        var _this = _super.call(this, objectType, data) || this;
        if (data) {
            if (data.interaction) {
                _this._navigationParameters = data.interaction.navParam || [];
                _this._onClickNavigate = data.interaction.onClickNav ? "/" + data.interaction.onClickNav : '';
                _this._sendEntryDataOnNavigation = data.interaction.hasOwnProperty('sendEntryDataOnNavigation')
                    ? !!data.interaction.sendEntryDataOnNavigation
                    : true;
            }
            else {
                _this._navigationParameters = [];
                _this._onClickNavigate = '';
                _this._sendEntryDataOnNavigation = true;
            }
            _this._baseConfiguration = data.api_base_configuration || null;
            _this._anywhereApiConfiguration = data.anywhere_api_configuration || null;
            _this._kalturaConfiguration = data.kaltura_player_configuration || null;
            _this._restApiConfiguration = data.rest_api_configuration || null;
        }
        return _this;
    }
    Object.defineProperty(NgObject.prototype, "sendEntryDataOnNavigation", {
        get: function () {
            return this._sendEntryDataOnNavigation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NgObject.prototype, "baseConfiguration", {
        get: function () {
            return this._baseConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NgObject.prototype, "anywhereApiConfiguration", {
        get: function () {
            return this._anywhereApiConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NgObject.prototype, "restApiConfiguration", {
        get: function () {
            return this._restApiConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NgObject.prototype, "kalturaConfiguration", {
        get: function () {
            return this._kalturaConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NgObject.prototype, "navigationParameters", {
        get: function () {
            return this._navigationParameters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NgObject.prototype, "onClickNavigate", {
        get: function () {
            return this._onClickNavigate;
        },
        enumerable: true,
        configurable: true
    });
    return NgObject;
}(BaseObject));
export { NgObject };
