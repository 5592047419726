import { OnInit } from '@angular/core';
import { StreamPaymentsHooks } from '../../shared/data/stream-payments.model';
import { UserAuthService } from '../../user-auth.service';
var LogoutBtnComponent = /** @class */ (function () {
    function LogoutBtnComponent(userAuthService) {
        this.userAuthService = userAuthService;
        this.show = false;
    }
    LogoutBtnComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userAuthService.getUserState()
            .subscribe(function (res) {
            if (res) {
                _this.show = true;
            }
            else {
                _this.show = false;
            }
        });
    };
    LogoutBtnComponent.prototype.doLogout = function () {
        this.userAuthService.hookStreamPayments(StreamPaymentsHooks.doLogout, null);
    };
    return LogoutBtnComponent;
}());
export { LogoutBtnComponent };
