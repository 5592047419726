import * as tslib_1 from "tslib";
import { CollectionUtils } from '../../../../../util/CollectionUtils';
import { NgObject } from './NgObject';
var Fixture = /** @class */ (function (_super) {
    tslib_1.__extends(Fixture, _super);
    function Fixture(data) {
        var _this = _super.call(this, 'Fixture', data) || this;
        _this._fixtureStaticProperties = data.static_properties;
        _this._fixtureCardType = data.card_type;
        _this._fixtureStaticProperties = CollectionUtils
            .flattenArrayOfResponsiveConfiguration(data.ui_conf, Fixture.fixtureConfigurationMapping);
        return _this;
    }
    Object.defineProperty(Fixture.prototype, "fixtureCardType", {
        get: function () {
            return this._fixtureCardType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Fixture.prototype, "fixtureStaticProperties", {
        get: function () {
            return this._fixtureStaticProperties;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Fixture.prototype, "fixtureConfiguration", {
        get: function () {
            return this._fixtureConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Fixture.fixtureConfigurationMapping = function (data) {
        var fixtureConfiguration = {
            fixtureTeamText: data.fixtureTeamsText,
            refreshRate: CollectionUtils.parseNumber(data.responsiveRefreshRate)
        };
        return fixtureConfiguration;
    };
    return Fixture;
}(NgObject));
export { Fixture };
