import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {StreamPaymentsHooks} from '../../shared/data/stream-payments.model';
import {UserAuthService} from '../../user-auth.service';
import {Button} from '../../shared/data/wordpress-data.model';

@Component({
  selector: 'amg-subscribe-btn',
  templateUrl: './subscribe-btn.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SubscribeBtnComponent implements OnInit {
  @Input() subscribeBtn: Button;
  show: boolean = false;

  constructor(private userAuthService: UserAuthService) { }

  ngOnInit() {
    this.userAuthService.getUserState()
      .subscribe(res => {
        if (res) {
          this.show = true;
        } else {
          this.show = false;
        }
      });
  }

  public openPage() {
    this.userAuthService.hookStreamPayments(StreamPaymentsHooks.getSubscriptionPackageList, null)
      .then(res => {
        this.userAuthService.setPackageData(res);
      });
  }

}
