<ng-container *ngIf="isDataLoading(); else loadedDataView">
  <div class="amg-loading-container">
    <span class="loading">
    </span>
  </div>
</ng-container>

<ng-template #loadedDataView>
  <ng-container *ngIf="areFixturesPopulated()">
    <amg-wp-fixture *ngFor="let fixture of getFixtures()"
                    [apiFixtureData]="getFixtureData(fixture)"></amg-wp-fixture>
    <ng-container *ngIf="isPaginationEnabled() && isMoreDataAvailable()">
      <button class="amg-button" (click)="loadNextPage()"></button>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!areFixturesPopulated()">
    <div class="amg-message-container no-fixtures">
    <span class="message">
      <span class="text">Sorry, no fixtures found</span>
    </span>
    </div>
  </ng-container>
</ng-template>
