import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../../../../shared/data/data.service';
import { NavigationUtils } from '../../../../../../utils/NavigationUtils';

@Component({
  selector: 'amg-wp-calendar-fixture',
  templateUrl: './calendar-fixture.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CalendarFixtureComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-calendar-fixture';
  @Input() data: any;
  @Input() onClickNavRoute: string;
  @Input() sendEntryDataOnNavigation: boolean;
  @Input() apiUrl: string;

  constructor(private router: Router,
              private dataService: DataService) {
  }

  ngOnInit() {
  }

  buttonClicked(data: { type: string; entry: any; }) {
    if (this.onClickNavRoute) {
      const urlFragment = NavigationUtils.getUrlFragments(this.onClickNavRoute);

      if (this.sendEntryDataOnNavigation) {
        this.dataService.userInteractedObject = {
          fixtureUrl: this.apiUrl,
          data: data.entry
        };

        this.router.navigate([urlFragment.url], {
          queryParams: {
            fixture: this.dataService.encodeUrl(this.apiUrl),
            entry: data.entry.entryId
          },
          preserveQueryParams: false,
          ...( urlFragment.fragment ? { fragment: urlFragment.fragment } : {} )
        });
      } else {
        this.router.navigate([urlFragment.url], {
          ...( urlFragment.fragment ? { fragment: urlFragment.fragment } : {} )
        });
      }
    }
  }
}
