import { OnInit } from '@angular/core';
import { PaymentLoginButtonGroup } from 'wordpress-adapter';
var LoginButtonGroupComponent = /** @class */ (function () {
    function LoginButtonGroupComponent() {
        this.class = 'amg-login-button-group';
    }
    LoginButtonGroupComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
    };
    LoginButtonGroupComponent.prototype.getCss = function () {
        return this.loginButtonGroup.cssClass;
    };
    LoginButtonGroupComponent.prototype.getLoginButton = function () {
        return this.loginButtonGroup.loginButton;
    };
    LoginButtonGroupComponent.prototype.getLogoutButton = function () {
        return this.loginButtonGroup.logOutButton;
    };
    LoginButtonGroupComponent.prototype.getRegisterButton = function () {
        return this.loginButtonGroup.registerButton;
    };
    LoginButtonGroupComponent.prototype.getSubscribeButton = function () {
        return this.loginButtonGroup.subscribeButton;
    };
    return LoginButtonGroupComponent;
}());
export { LoginButtonGroupComponent };
