/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./icon.component";
import * as i3 from "@angular/router";
var styles_IconComponent = [];
var RenderType_IconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconComponent, data: {} });
export { RenderType_IconComponent as RenderType_IconComponent };
function View_IconComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [["class", "amg-icon-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_IconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "amg-icon-image"]], [[8, "src", 4], [8, "srcset", 4], [8, "alt", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon.label; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getDefaultImage(); var currVal_2 = _co.getRemainingImages(); var currVal_3 = _co.getAltText(); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_IconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-icon", [], [[1, "class", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onIconClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_IconComponent_0, RenderType_IconComponent)), i0.ɵdid(1, 114688, null, 0, i2.IconComponent, [i1.DOCUMENT, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var IconComponentNgFactory = i0.ɵccf("amg-wp-icon", i2.IconComponent, View_IconComponent_Host_0, { icon: "icon" }, {}, []);
export { IconComponentNgFactory as IconComponentNgFactory };
