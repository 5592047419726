import { NgModule } from '@angular/core';

import { CardFixtureModule } from 'card-fixture';
import { CardImageModule } from 'card-image';
import { CardProfileModule } from 'card-profile';
import { CardTextModule } from 'card-text';
import { CardVideoModule } from 'card-video';

@NgModule({
  declarations: [],
  imports: [
    CardFixtureModule,
    CardImageModule,
    CardProfileModule,
    CardTextModule,
    CardVideoModule,
  ],
  exports: [
    CardFixtureModule,
    CardImageModule,
    CardProfileModule,
    CardTextModule,
    CardVideoModule
  ],
})
export class CardModule { }
