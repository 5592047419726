import * as tslib_1 from "tslib";
import { CollectionUtils } from '../../../util/CollectionUtils';
import { BaseObject } from '../BaseObject';
var Section = /** @class */ (function (_super) {
    tslib_1.__extends(Section, _super);
    function Section(data) {
        var _this = _super.call(this, 'Section', data) || this;
        _this._backgroundImage = data.amg_background_image ? data.amg_background_image : null;
        _this._description = data.description ? data.description : null;
        _this._title = (data.title) ? data.title : null;
        _this._children = data.elements
            ? CollectionUtils.flattenArrayOfSection(data.elements)
            : [];
        if (data.section_api_configuration) {
            var configDataset = CollectionUtils.retrieveConfigurationData(data.section_api_configuration);
            _this._baseConfiguration = configDataset.baseConfiguration;
            _this._anywhereApiConfiguration = configDataset.anywhereApiConfiguration;
            _this._kalturaConfiguration = configDataset.kalturaConfiguration;
            _this._restApiConfiguration = configDataset.restApiConfiguration;
        }
        return _this;
    }
    Object.defineProperty(Section.prototype, "title", {
        get: function () {
            return this._title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Section.prototype, "description", {
        get: function () {
            return this._description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Section.prototype, "backgroundImage", {
        get: function () {
            return this._backgroundImage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Section.prototype, "anywhereApiConfiguration", {
        get: function () {
            return this._anywhereApiConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Section.prototype, "restApiConfiguration", {
        get: function () {
            return this._restApiConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Section.prototype, "baseConfiguration", {
        get: function () {
            return this._baseConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Section.prototype, "kalturaConfiguration", {
        get: function () {
            return this._kalturaConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Section.prototype, "children", {
        get: function () {
            return this._children;
        },
        enumerable: true,
        configurable: true
    });
    return Section;
}(BaseObject));
export { Section };
