import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var ActionLinks = /** @class */ (function (_super) {
    tslib_1.__extends(ActionLinks, _super);
    function ActionLinks(data) {
        var _this = _super.call(this, 'ActionLinks', data) || this;
        _this._actionLinks = data.text_links;
        return _this;
    }
    Object.defineProperty(ActionLinks.prototype, "actionLinks", {
        get: function () {
            return this._actionLinks;
        },
        enumerable: true,
        configurable: true
    });
    return ActionLinks;
}(BaseObject));
export { ActionLinks };
