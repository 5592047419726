/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./subscription-package/subscription-package.component.ngfactory";
import * as i2 from "./subscription-package/subscription-package.component";
import * as i3 from "@angular/common";
import * as i4 from "./subscription-packages.component";
import * as i5 from "../user-auth.service";
var styles_SubscriptionPackagesComponent = [];
var RenderType_SubscriptionPackagesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubscriptionPackagesComponent, data: {} });
export { RenderType_SubscriptionPackagesComponent as RenderType_SubscriptionPackagesComponent };
function View_SubscriptionPackagesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-subscription-package", [], [[1, "class", 0]], null, null, i1.View_SubscriptionPackageComponent_0, i1.RenderType_SubscriptionPackageComponent)), i0.ɵdid(1, 114688, null, 0, i2.SubscriptionPackageComponent, [i3.DOCUMENT], { package: [0, "package"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
function View_SubscriptionPackagesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPackagesComponent_2)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subscriptionPackages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SubscriptionPackagesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPackagesComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subscriptionPackages; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SubscriptionPackagesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-subscription-packages", [], [[1, "class", 0]], null, null, View_SubscriptionPackagesComponent_0, RenderType_SubscriptionPackagesComponent)), i0.ɵdid(1, 114688, null, 0, i4.SubscriptionPackagesComponent, [i5.UserAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var SubscriptionPackagesComponentNgFactory = i0.ɵccf("amg-subscription-packages", i4.SubscriptionPackagesComponent, View_SubscriptionPackagesComponent_Host_0, {}, {}, []);
export { SubscriptionPackagesComponentNgFactory as SubscriptionPackagesComponentNgFactory };
