/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../html-elements/icon/icon.component.ngfactory";
import * as i2 from "../../html-elements/icon/icon.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./social-links.component";
var styles_SocialLinksComponent = [];
var RenderType_SocialLinksComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SocialLinksComponent, data: {} });
export { RenderType_SocialLinksComponent as RenderType_SocialLinksComponent };
function View_SocialLinksComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-icon", [], [[1, "class", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onIconClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(1, 114688, null, 0, i2.IconComponent, [i3.DOCUMENT, i4.Router], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
export function View_SocialLinksComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SocialLinksComponent_1)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSocialLinks(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SocialLinksComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-social-links", [], [[1, "class", 0]], null, null, View_SocialLinksComponent_0, RenderType_SocialLinksComponent)), i0.ɵdid(1, 114688, null, 0, i5.SocialLinksComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var SocialLinksComponentNgFactory = i0.ɵccf("amg-wp-social-links", i5.SocialLinksComponent, View_SocialLinksComponent_Host_0, { socialLinks: "socialLinks" }, {}, []);
export { SocialLinksComponentNgFactory as SocialLinksComponentNgFactory };
