import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { VideoCardComponent } from './video-card.component';

@NgModule({
  declarations: [VideoCardComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [VideoCardComponent]
})
export class VideoCardModule { }
