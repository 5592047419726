<div class="amg-card-event"
     [ngClass]="{ 'amg-fixture-live': getIsFixtureLive() }">
  <div class="amg-card-event-info">
    <amg-date class="amg-date"
              [fixtureDate]="getFixtureDate()"></amg-date>
    <amg-date class="amg-date"
              [fixtureDate]="getFixtureDate()"></amg-date>
    <div class="amg-card-event-info-type"
         [innerHTML]="getEventType()">
    </div>
  </div>
  <div class="amg-card-event-details">
    <h2 class="amg-card-event-details-title"
        [innerHTML]="getName()"></h2>
    <div class="amg-card-event-details-description"
         [innerHTML]="getDescription()"></div>
  </div>
  <div class="amg-card-event-meta">
    <amg-live-buttons [fixtureMediaData]="getMediaData()"
                      (triggerClick)="onMediaDataButtonClick($event)">
    </amg-live-buttons>
  </div>
</div>
