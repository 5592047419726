/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./advertisement.component";
var styles_AdvertisementComponent = [];
var RenderType_AdvertisementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdvertisementComponent, data: {} });
export { RenderType_AdvertisementComponent as RenderType_AdvertisementComponent };
export function View_AdvertisementComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["TODO: Advertisement"]))], null, null); }
export function View_AdvertisementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-advertisement", [], [[1, "class", 0]], null, null, View_AdvertisementComponent_0, RenderType_AdvertisementComponent)), i0.ɵdid(1, 114688, null, 0, i1.AdvertisementComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var AdvertisementComponentNgFactory = i0.ɵccf("amg-wp-advertisement", i1.AdvertisementComponent, View_AdvertisementComponent_Host_0, { advertisement: "advertisement" }, {}, []);
export { AdvertisementComponentNgFactory as AdvertisementComponentNgFactory };
