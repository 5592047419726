/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../fixture/fixture.component.ngfactory";
import * as i2 from "../fixture/fixture.component";
import * as i3 from "../../../../../shared/api/api.service";
import * as i4 from "@angular/common";
import * as i5 from "./fixtures.component";
var styles_FixturesComponent = [];
var RenderType_FixturesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FixturesComponent, data: {} });
export { RenderType_FixturesComponent as RenderType_FixturesComponent };
function View_FixturesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "amg-loading-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "loading"]], null, null, null, null, null))], null, null); }
function View_FixturesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-fixture", [], [[1, "class", 0], [1, "data-is-data-available", 0]], null, null, i1.View_FixtureComponent_0, i1.RenderType_FixtureComponent)), i0.ɵdid(1, 245760, null, 0, i2.FixtureComponent, [i3.ApiService], { apiFixtureData: [0, "apiFixtureData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getFixtureData(_v.context.$implicit); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; var currVal_1 = i0.ɵnov(_v, 1).isDataAvailable; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FixturesComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "button", [["class", "amg-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loadNextPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_FixturesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FixturesComponent_4)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FixturesComponent_5)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFixtures(); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.isPaginationEnabled() && _co.isMoreDataAvailable()); _ck(_v, 4, 0, currVal_1); }, null); }
function View_FixturesComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "amg-message-container no-fixtures"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "message"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sorry, no fixtures found"]))], null, null); }
function View_FixturesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FixturesComponent_3)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FixturesComponent_6)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.areFixturesPopulated(); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.areFixturesPopulated(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FixturesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FixturesComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["loadedDataView", 2]], null, 0, null, View_FixturesComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDataLoading(); var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FixturesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-fixtures", [], [[1, "class", 0], [1, "data-is-data-available", 0]], null, null, View_FixturesComponent_0, RenderType_FixturesComponent)), i0.ɵdid(1, 245760, null, 0, i5.FixturesComponent, [i3.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; var currVal_1 = i0.ɵnov(_v, 1).isDataAvailable; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var FixturesComponentNgFactory = i0.ɵccf("amg-wp-fixtures", i5.FixturesComponent, View_FixturesComponent_Host_0, { fixtures: "fixtures" }, { triggerChange: "triggerChange" }, []);
export { FixturesComponentNgFactory as FixturesComponentNgFactory };
