import { CardFixtureResponsiveProperties } from 'card-fixture';
import { CardImageResponsiveProperties } from 'card-image';
import { CardProfileResponsiveProperties } from 'card-profile';
import { CardTextResponsiveProperties } from 'card-text';
import { CardVideoResponsiveProperties } from 'card-video';
var CardResponsiveRegistry = /** @class */ (function () {
    function CardResponsiveRegistry() {
    }
    CardResponsiveRegistry.CardResponsivePropertiesRegistry = new Map([
        ['CardFixtureResponsiveProperties', CardFixtureResponsiveProperties],
        ['CardImageResponsiveProperties', CardImageResponsiveProperties],
        ['CardProfileResponsiveProperties', CardProfileResponsiveProperties],
        ['CardTextResponsiveProperties', CardTextResponsiveProperties],
        ['CardVideoResponsiveProperties', CardVideoResponsiveProperties],
    ]);
    return CardResponsiveRegistry;
}());
export { CardResponsiveRegistry };
