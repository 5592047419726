import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var DataService = /** @class */ (function () {
    function DataService() {
        this._footerData = new BehaviorSubject(null);
    }
    DataService.prototype.setFooterData = function (data) {
        this._footerData.next(data);
    };
    DataService.prototype.getFooterDataAsObservable = function () {
        return this._footerData.asObservable();
    };
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
