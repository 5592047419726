import { BaseObject } from '../BaseObject';

export class Overlay extends BaseObject {
  private readonly _overlayFields: any[];

  constructor(data: any) {
    super('Overlay', data);

    this._overlayFields = data.overlay_fields || [];
  }

  get overlayFields(): any[] {
    return this._overlayFields;
  }
}
