import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var DataService = /** @class */ (function () {
    function DataService() {
        this.mediaEntrySubject = new BehaviorSubject(null);
    }
    DataService.prototype.setEntryObject = function (mediaEntry) {
        this.mediaEntrySubject.next(mediaEntry);
    };
    DataService.prototype.getMediaEntryAsObservable = function () {
        return this.mediaEntrySubject.asObservable();
    };
    DataService.prototype.encodeUrl = function (url) {
        return btoa(url);
    };
    DataService.prototype.decodeUrl = function (url) {
        return atob(url);
    };
    Object.defineProperty(DataService.prototype, "userInteractedObject", {
        get: function () {
            return this.clearAndGetCurrentObject();
        },
        set: function (value) {
            this._userInteractedObject = value;
        },
        enumerable: true,
        configurable: true
    });
    DataService.prototype.clearAndGetCurrentObject = function () {
        var interactedObject = this._userInteractedObject;
        this._userInteractedObject = null;
        return interactedObject;
    };
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
