import { ViewContainerRef, OnInit, OnDestroy, QueryList, EventEmitter, OnChanges, SimpleChanges, } from '@angular/core';
import { BootstrapViewportService, } from 'amg';
import { CardFixture } from 'card-fixture';
import { CalendarBuild, CalendarClick, CalendarHover, } from './calendar.functions/calendar.functions';
import { CalendarResponsiveProperties } from './calendar.responsive.properties';
import { CalendarStaticProperties } from './calendar.static.properties';
var CalendarComponent = /** @class */ (function () {
    function CalendarComponent(bootstrapViewportService) {
        this.bootstrapViewportService = bootstrapViewportService;
        this.responsiveProperties = new CalendarResponsiveProperties();
        this.staticProperties = new CalendarStaticProperties();
        this.fixtureClicked = new EventEmitter();
        this.fixtureHovered = new EventEmitter();
        this.selectedDate = {};
        this.tabComponentData = [];
        this.fixtureComponentData = [];
    }
    CalendarComponent.prototype.ngOnInit = function () {
        var _this = this;
        var viewportSubscriber = this.bootstrapViewportService
            .getViewportSubscriber();
        var viewport = this.bootstrapViewportService.getViewport();
        this.componentCurrentView = this.responsiveProperties[viewport];
        this._build = new CalendarBuild();
        this._click = new CalendarClick(this.fixtureClicked);
        this._hover = new CalendarHover(this.fixtureHovered);
        this.regenerateResponsiveViews();
        this.regenerateStaticViews();
        this._calendarData = this._build
            .buildCalendar(this.componentData, this.responsiveProperties);
        this.fixtureComponentData = this._calendarData.fixtureData;
        this.tabComponentData = this._calendarData.tabsResponsive[viewport];
        this.selectedDate = this.tabComponentData[0];
        this.componentViewportSubscription = viewportSubscriber
            .subscribe(function (newBreakpoint) {
            if (_this.responsiveTimeout) {
                clearTimeout(_this.responsiveTimeout);
                _this.responsiveTimeout = null;
            }
            _this.responsiveTimeout = setTimeout(function () {
                _this.componentCurrentView = _this.responsiveProperties[newBreakpoint];
                _this.tabComponentData = _this._calendarData.tabsResponsive[newBreakpoint];
                _this.selectedDate = _this.tabComponentData[0];
            }, _this.componentCurrentView.refreshRate);
        });
    };
    CalendarComponent.prototype.ngOnDestroy = function () {
        this.componentViewportSubscription.unsubscribe();
        if (this.responsiveTimeout) {
            clearTimeout(this.responsiveTimeout);
        }
    };
    CalendarComponent.prototype.ngOnChanges = function (changes) {
        if (this._build !== undefined) {
            if (changes.responsiveProperties) {
                this.regenerateResponsiveViews();
            }
            if (changes.staticProperties) {
                this.regenerateStaticViews();
            }
            if (changes.componentData) {
                var viewport = this.bootstrapViewportService.getViewport();
                this._calendarData = this._build
                    .buildCalendar(this.componentData, this.responsiveProperties);
                this.fixtureComponentData = this._calendarData.fixtureData;
                this.tabComponentData = this._calendarData.tabsResponsive[viewport];
                this.selectedDate = this.tabComponentData[0];
            }
        }
    };
    CalendarComponent.prototype.regenerateResponsiveViews = function () {
        if (this._build !== undefined) {
            if (this.responsiveProperties) {
                var responsive = this._build.generateResponsiveViews(this.responsiveProperties);
                this.tabComponentResponsive = responsive.tabResponsive;
            }
        }
    };
    CalendarComponent.prototype.regenerateStaticViews = function () {
        if (this._build !== undefined) {
            if (this.staticProperties) {
                var s = this._build.generateStaticViews(this.staticProperties);
                this.tabComponentStatic = s.tabStatic;
            }
        }
    };
    CalendarComponent.prototype.onDateClicked = function (source, $event) {
        if (source === void 0) { source = 'unknown'; }
        this.selectedDate = $event;
    };
    CalendarComponent.prototype.onDateHovered = function (source, $event) {
        if (source === void 0) { source = 'unknown'; }
        // do nothing
    };
    CalendarComponent.prototype.onDateSelected = function (source, $event) {
        if (source === void 0) { source = 'unknown'; }
        this.selectedDate = $event;
    };
    CalendarComponent.prototype.onFixtureClicked = function (data) {
        this._click.slideClick(null, data);
    };
    CalendarComponent.prototype.onFixtureHover = function (source, i) {
        if (source === void 0) { source = 'unknown'; }
        this._hover.slideHover(source, i, this.componentData[i]);
    };
    CalendarComponent.prototype.onFixtureHoverOut = function (source, i) {
        this._hover.slideHoverOut(source, i);
    };
    return CalendarComponent;
}());
export { CalendarComponent };
