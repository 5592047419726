import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FixtureBaseComponent } from '../../models/FixtureBaseComponent';
var CardEventComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CardEventComponent, _super);
    function CardEventComponent(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        _this.isLive = false;
        _this.buttonClicked = new EventEmitter();
        return _this;
    }
    CardEventComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.init();
        this.isFixtureLiveSubscription = this.getIsAnyFixtureLiveObservable().subscribe(function (isLive) {
            _this.isLive = isLive;
        });
    };
    CardEventComponent.prototype.ngOnDestroy = function () {
        this.isFixtureLiveSubscription.unsubscribe();
        this.destroy();
    };
    CardEventComponent.prototype.onMediaDataButtonClick = function (data) {
        this.buttonClicked.emit(data);
    };
    return CardEventComponent;
}(FixtureBaseComponent));
export { CardEventComponent };
