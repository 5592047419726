import * as tslib_1 from "tslib";
import { BaseObject } from '../../../BaseObject';
var InputField = /** @class */ (function (_super) {
    tslib_1.__extends(InputField, _super);
    function InputField(data) {
        var _this = _super.call(this, 'InputField', data) || this;
        _this._type = data.input_type;
        _this._referenceName = data.internal_reference_name;
        _this._label = data.label;
        _this._placeholder = data.placeholder;
        _this._required = data.required;
        _this._onEnterNavigateTo = data.onClickNav;
        if (data.input_type === InputField.INPUT_TYPE_SELECT) {
            _this._value = null;
            _this._values = data.values;
        }
        else {
            _this._values = null;
            _this._value = data.value;
        }
        return _this;
    }
    Object.defineProperty(InputField.prototype, "onEnterNavigateTo", {
        get: function () {
            return this._onEnterNavigateTo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputField.prototype, "type", {
        get: function () {
            return this._type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputField.prototype, "referenceName", {
        get: function () {
            return this._referenceName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputField.prototype, "label", {
        get: function () {
            return this._label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputField.prototype, "placeholder", {
        get: function () {
            return this._placeholder;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputField.prototype, "value", {
        get: function () {
            return this._value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputField.prototype, "required", {
        get: function () {
            return this._required;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputField.prototype, "values", {
        get: function () {
            return this._values;
        },
        enumerable: true,
        configurable: true
    });
    InputField.INPUT_TYPE_SELECT = 'select';
    return InputField;
}(BaseObject));
export { InputField };
