import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {SportsAllianceUtil} from './app/core/utils/SportsAllianceUtil';

if (environment.production) {
  enableProdMode();
}


// if (environment.google.analyticsId) {
//   (window as any).gtag('js', new Date());
//
//   if (environment.google.analyticsId) {
//     (window as any).gtag('config', environment.google.analyticsId);
//   }
// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
