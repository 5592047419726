import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Advertisement, RestApiConfiguration } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-advertisement',
  templateUrl: './advertisement.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AdvertisementComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-advertisement';
  @Input() advertisement: Advertisement;

  constructor() {
  }

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }
  }

  getCss(): string {
    return this.advertisement.cssClass;
  }
}
