import { Component, OnInit, Input, QueryList, ViewContainerRef } from '@angular/core';

import { AmgAnywhereVideo } from 'amg';
import { DeviceUtils } from 'amg-fe-utils';
import { CardReleasedDate, CardRunTime } from './card-video.type';

import { CardVideoStaticProperties } from './card.video.static.properties';
import { CardVideoResponsiveProperties } from './card.video.responsive.properties';

@Component({
  selector: 'amg-card-video',
  templateUrl: 'card-video.component.html',
  styles: []
})
export class CardVideoComponent implements OnInit {
  static cardWidth = -1;
  @Input() componentData: AmgAnywhereVideo;
  @Input() staticProperties: CardVideoStaticProperties;
  @Input() responsiveProperties: CardVideoResponsiveProperties;
  @Input() componentChildren: QueryList<ViewContainerRef>;
  private _runtime: CardRunTime;
  private _released: CardReleasedDate;
  private _thumbnail: string;
  private _entitlements: string;
  private locale: string;

  constructor() {
    this.locale = DeviceUtils.userLocale;
  }

  private static padValue(value: string): string {
    return (value.length === 1)
      ? `0${value}`
      : value;
  }

  ngOnInit() {
    this._runtime = this.generateRuntime();
    this._released = this.generateReleased();
    this._thumbnail = this.generateThumbnail();
    this._entitlements = this.componentData.metaData.SysEntryEntitlements.join('-') || 'free';
  }

  getCardWidth() {
    if (CardVideoComponent.cardWidth === -1) {
      CardVideoComponent.cardWidth = 350;
      if (screen.width >= 700) {
        CardVideoComponent.cardWidth = 300;
      }
      if (screen.width >= 1920) {
        CardVideoComponent.cardWidth = 900;
      }
    }
    return CardVideoComponent.cardWidth;
  }

  getEntitlement() {
    return this._entitlements;
  }

  getCategories() {
    return this.componentData.metaData.category;
  }

  getReleased(): CardReleasedDate {
    return this._released;
  }

  getRuntime(): CardRunTime {
    return this._runtime;
  }

  getThumbnail(): string {
    return this._thumbnail;
  }

  getTitle() {
    return this.componentData.metaData.title;
  }

  private generateRuntime(): CardRunTime {
    const runtime = new Date(this.componentData.metaData.VideoDuration * 1000);

    return {
      seconds: {value: runtime.getUTCSeconds().toString(), paddedValue: CardVideoComponent.padValue(runtime.getUTCSeconds().toString())},
      minutes: {value: runtime.getUTCMinutes().toString(), paddedValue: CardVideoComponent.padValue(runtime.getUTCMinutes().toString())},
      hours: {value: runtime.getUTCHours().toString(), paddedValue: CardVideoComponent.padValue(runtime.getUTCHours().toString())}
    };
  }

  private generateReleased(): CardReleasedDate {
    const releaseFrom = this.componentData.publicationData.releaseFrom;
    const date = (releaseFrom.toUpperCase().endsWith('Z'))
      ? new Date(releaseFrom)
      : new Date(releaseFrom + 'Z');

    return {
      seconds: {value: date.getSeconds().toString(), paddedValue: CardVideoComponent.padValue(date.getSeconds().toString())},
      minutes: {value: date.getMinutes().toString(), paddedValue: CardVideoComponent.padValue(date.getMinutes().toString())},
      hours: {value: date.getHours().toString(), paddedValue: CardVideoComponent.padValue(date.getHours().toString())},
      days: {
        value: date.getDate().toString(),
        paddedValue: CardVideoComponent.padValue(date.getDate().toString()),
        dayOfWeek: new Intl.DateTimeFormat(this.locale, {weekday: 'long'}).format(date)
      },
      months: {
        value: (date.getMonth() + 1).toString(),
        paddedValue: CardVideoComponent.padValue((date.getMonth() + 1).toString()),
        month: new Intl.DateTimeFormat(this.locale, {month: 'long'}).format(date)
      },
      years: {value: (date.getFullYear()).toString(), shortValue: date.getFullYear().toString().substring(2, 2)}
    };
  }

  private generateThumbnail(): string {
    return `${this.componentData.mediaData.thumbnailUrl}/width/${this.getCardWidth()}`;
  }
}
