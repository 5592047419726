export class NavigationUtils {
  public static getUrlFragments(url: string): { url: string; fragment: string; } {
    const urlObject = {
      url: null,
      fragment: null
    };

    const hashIndex = url.indexOf('#');
    if (hashIndex !== -1) {
      urlObject.url = url.substring(0, hashIndex);
      urlObject.fragment = url.substring(hashIndex + 1);
    } else {
      urlObject.url = url;
    }

    return urlObject;
  }
}
