import { OnInit } from '@angular/core';
import { Text } from 'wordpress-adapter';
var TextComponent = /** @class */ (function () {
    function TextComponent() {
        this.class = 'amg-text-field';
        this.htmlText = '';
    }
    TextComponent.prototype.ngOnInit = function () {
        if (this.getCss()) {
            this.class = this.class
                .concat(' ')
                .concat(this.getCss());
        }
        this.htmlText = this.getText();
    };
    TextComponent.prototype.getText = function () {
        return this.text.text;
    };
    TextComponent.prototype.getCss = function () {
        return this.text.cssClass;
    };
    return TextComponent;
}());
export { TextComponent };
