import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
var TemplateBuilderComponent = /** @class */ (function () {
    function TemplateBuilderComponent() {
    }
    TemplateBuilderComponent.prototype.ngOnInit = function () {
        this.logo = this.dataObj.logo;
        this.form = this.dataObj.form;
        this.links = this.dataObj.links;
    };
    return TemplateBuilderComponent;
}());
export { TemplateBuilderComponent };
