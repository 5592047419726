import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {configs} from '../../../core/configs';
import {environment} from '../../../../environments/environment';
import {UserAuthSsoService} from '@amg-fe-angular/services/user-auth/user-auth-sso.service';
import {SportsAllianceUtil} from '../../utils/SportsAllianceUtil';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  constructor(
    private httpClient: HttpClient,
    private userAuthService: UserAuthSsoService,
    protected location: Location
  ) {
  }


  public init(): void {

    // Before bootstrapping application, sort out the token.
    if (window && window.location) {
      // If payload is present - then it must be a successful login
      const urlData = SportsAllianceUtil.getPayloadAndUrl(window.location.href);

      if (urlData) {
        // If payload is retrieved, then store it and clear it.
        const payload = SportsAllianceUtil.unObfuscatePayload(urlData.payload);
        const parsedData = SportsAllianceUtil.parseSsoData(payload);

        this.login(parsedData.access_token);
        this.location.replaceState(urlData.url);


      } else if (document.location.search.indexOf('logout=1') > -1) {

        this.logout();

      } else {

        this.userAuthService.streamPaymentsInit({
          language: 'en'
        });
      }
    }
  }

  public login(jwt: string): void {


    this.userAuthService.streamPaymentsInit({
      jwtToken: jwt,
      language: 'en'
    }, (resp) => {

      const paramsAuth = new HttpParams().set('token', jwt);

      this.httpClient.request('get', environment.cloudPayUrl + configs.cloudPay.paths.start, {params: paramsAuth})
        .subscribe();
    });
  }


  public logout() {

    const currentUrl = new URL(document.location.href);

    currentUrl.searchParams.delete('logout');

    this.location.replaceState(currentUrl.href.replace(currentUrl.origin, ''));

    this.userAuthService.streamPaymentsLogOut(null, null);

  }
}
