/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../components/carousel/src/lib/carousel/carousel.component.ngfactory";
import * as i2 from "../../../../../../../../../components/carousel/src/lib/carousel/carousel.component";
import * as i3 from "../../../../../../../../../components/amg/src/lib/bootstrap.viewport.service";
import * as i4 from "./carousel.component";
import * as i5 from "../../../../../shared/api/api.service";
import * as i6 from "../../../../../shared/data/data.service";
import * as i7 from "@angular/router";
var styles_CarouselComponent = [];
var RenderType_CarouselComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CarouselComponent, data: {} });
export { RenderType_CarouselComponent as RenderType_CarouselComponent };
export function View_CarouselComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-carousel", [], null, [[null, "cardClicked"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("touchstart" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onTouchStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchend" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouchEnd($event) !== false);
        ad = (pd_1 && ad);
    } if (("cardClicked" === en)) {
        var pd_2 = (_co.onCardClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_CarouselComponent_0, i1.RenderType_CarouselComponent)), i0.ɵdid(1, 4964352, null, 0, i2.CarouselComponent, [i0.ComponentFactoryResolver, i3.BootstrapViewportService], { componentData: [0, "componentData"], responsiveProperties: [1, "responsiveProperties"], staticProperties: [2, "staticProperties"] }, { cardClicked: "cardClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.carouselData; var currVal_1 = _co.carouselResponsiveProperties; var currVal_2 = _co.carouselStaticProperties; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CarouselComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "amg-wp-carousel", [], [[1, "class", 0]], null, null, View_CarouselComponent_0, RenderType_CarouselComponent)), i0.ɵdid(1, 245760, null, 0, i4.CarouselComponent, [i5.ApiService, i6.DataService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var CarouselComponentNgFactory = i0.ɵccf("amg-wp-carousel", i4.CarouselComponent, View_CarouselComponent_Host_0, { carousel: "carousel" }, {}, []);
export { CarouselComponentNgFactory as CarouselComponentNgFactory };
