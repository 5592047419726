import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CountdownResponsiveProperties, CountdownStaticProperties } from 'countdown';
import { Countdown as WpCountdown } from 'wordpress-adapter';

@Component({
  selector: 'amg-wp-countdown',
  templateUrl: './countdown.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CountdownComponent implements OnInit {
  @HostBinding('attr.class') class = 'amg-countdown';
  @Input() countdown: WpCountdown;

  public countdownData: Date[];
  public countdownResponsiveProperties: CountdownResponsiveProperties;
  public countdownStaticProperties: CountdownStaticProperties;

  ngOnInit() {
    if (this.getCss()) {
      this.class = this.class
        .concat(' ')
        .concat(this.getCss());
    }

    this.countdownData = [this.countdown.endTime];
    this.countdownResponsiveProperties = new CountdownResponsiveProperties(this.countdown.countdownConfiguration);
    this.countdownStaticProperties = new CountdownStaticProperties();
  }

  getCss(): string {
    return this.countdown.cssClass;
  }
}
